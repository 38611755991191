import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { snackbarProps } from 'interfaces/snackbar'
import sendPushNotificationsService from 'services/group/sendPushNotificationsService'

const useSendPushNotifications = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const {
    sendPushNotificationsMutation,
    error,
  } = sendPushNotificationsService()

  const sendPushNotifications = async (
    recipientIds: string[],
    messageData: {
      heading: string
      body: string
    },
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    await sendPushNotificationsMutation({
      variables: { recipientIds, messageData },
    })

    if (error) {
      setLoading(false)
      message = t('messages.something_wrong')
      variant = 'error'
      return
    }
    message = `Notifications Successfully Sent`
    variant = 'success'

    setSnackbar({ message, variant })
    setLoading(false)
    return message
  }

  return {
    loading,
    sendPushNotifications,
  }
}

export default useSendPushNotifications
