import { useMutation } from '@apollo/client'
import {
  ADD_GROUPS_TO_COURSE,
  ADD_REMOVE_GROUP_FROM_COURSE,
  REMOVE_GROUPS_FROM_COURSE,
} from 'gql/group/group.query'
// import { updateCache } from 'services/updateCache'

const addRemoveGroupFromCourseService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addRemoveGroupFromCourseMutation] = useMutation(
    ADD_REMOVE_GROUP_FROM_COURSE,
  )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addGroupsToCourseMutation] = useMutation(ADD_GROUPS_TO_COURSE)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [removeGroupsToCourseMutation] = useMutation(REMOVE_GROUPS_FROM_COURSE)

  return {
    addRemoveGroupFromCourseMutation,
    addGroupsToCourseMutation,
    removeGroupsToCourseMutation,
  }
}

export default addRemoveGroupFromCourseService
