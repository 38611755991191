import config from '../config'

const axios = require('axios').default

interface DataPayload {
  email: string
  newPassword?: string
  confirmPassword?: string
  token?: string
}

export const Api = (method: string, url: string, data: DataPayload) => {
  return axios({
    method,
    url: `${config.APIURL}/${url}`,
    data,
  })
}
