import { IGridConfig } from 'components/common/GridV2/grid.interface'
import React from 'react'
import { itemProps } from './testQuestions.interface'
import Checkbox from 'components/common/Checkbox'
import { QuestionType } from './styled-components'
import Tags from 'components/common/Tags/Tags'
import { TFunction } from 'interfaces/TFunction'

export const columnConfig = (
  selectItem: (id: string) => void,
  t: TFunction,
) => {
  let grid: IGridConfig[] = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <div>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
        </div>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'question',
      label: `${t('general.question')}`,
      render: (item: itemProps) => <div>{item.question}</div>,
      numeric: false,
      disablePadding: false,
      width: '65%',
    },
    {
      id: 'type',
      label: `${t('general.type')}`,
      numeric: false,
      render: (item: itemProps) => (
        <QuestionType>{item.type.label}</QuestionType>
      ),
      disablePadding: false,
    },
    {
      id: 'tags',
      label: `${t('general.tags')}`,
      numeric: false,
      disablePadding: false,
      render: (item: itemProps) => <Tags tags={item.tags} />,
      width: '40%',
    },
  ]

  return grid
}
