import { useQuery } from '@apollo/client'
import { GET_TESTS_NAME } from 'gql/tests/tests.query'
import {
  TestAssessmentsData,
  TestAssessmentsVars,
} from './testAssessment.inteface'

export const useTestAssessmentsSearch = (companyId: string) => {
  const { data, loading, error } = useQuery<
    TestAssessmentsData,
    TestAssessmentsVars
  >(GET_TESTS_NAME, {
    variables: {
      companyId,
      currentPage: 0,
      perPage: 0,
      filter: {},
    },
  })

  return {
    testAssessments: data?.getTestAssessmentsByCompanyId.data || [],
    loading,
    error,
  }
}
