import React from 'react'

// hooks
import { useTranslation } from 'react-i18next'

// components
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  SkillTestVideoReviewCriteria,
} from '../skillTestVideoCriteria.interface'
import {
  CheckedAt,
  ContentContainer,
  CriteriaContainer,
  CriteriaTitle,
  HeaderContainer,
  ViewSkillTestVideoCriteriasContainer,
} from './styled-components'

// icons
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { CriteriaItem } from '../styled-components'
import moment from 'moment'

const ViewSkillTestVideoCriteriasDrawer = ({
  title,
  criterias,
  onClose,
}: {
  title: string
  criterias: SkillTestVideoReviewCriteria[]
  onClose: () => void
}) => {
  // hooks
  const { t } = useTranslation()

  if (!criterias)
    return (
      <ViewSkillTestVideoCriteriasContainer>
        <DrawerHeader>
          <DrawerTitle>{title}</DrawerTitle>
          <IconButton onClick={onClose}>
            <HighlightOffRoundedIcon />
          </IconButton>
        </DrawerHeader>
        <DrawerContent headerHeight={68}>
          <div>No criterias found</div>
        </DrawerContent>
      </ViewSkillTestVideoCriteriasContainer>
    )

  return (
    <ViewSkillTestVideoCriteriasContainer>
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <IconButton onClick={onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <HeaderContainer>
          {t('skillTestVideoCriteria_layout.covered')}
        </HeaderContainer>
        <ContentContainer>
          {criterias.map((skillTestVideoCriteria, index) => {
            const {
              criteriaId: id,
              criteria,
              checked,
              checkedAt,
            } = skillTestVideoCriteria
            return (
              <CriteriaItem key={id}>
                {checked ? (
                  <img src="/img/check.svg" />
                ) : (
                  <HighlightOffRoundedIcon />
                )}
                <CriteriaContainer>
                  {checkedAt && (
                    <CheckedAt>
                      {moment(checkedAt * 1000).format('mm:ss')}
                    </CheckedAt>
                  )}
                  <CriteriaTitle checked={checked}>{criteria}</CriteriaTitle>
                </CriteriaContainer>
              </CriteriaItem>
            )
          })}
        </ContentContainer>
      </DrawerContent>
    </ViewSkillTestVideoCriteriasContainer>
  )
}

export default ViewSkillTestVideoCriteriasDrawer
