import React from 'react'

import styled from 'styled-components'

export const FormContainer = (props: any) => (
  <Container {...props}>{props.children}</Container>
)

export const FormGroupWrapper = (props: any) => (
  <GroupWrapper {...props}>{props.children}</GroupWrapper>
)

export const FormGroup = (props: any) => (
  <Form {...props}>{props.children}</Form>
)

export const FormGroupItem = (props: any) => (
  <Item style={{ ...props.style }}>{props.children}</Item>
)

export const InputGroup = (props: any) => (
  <Group {...props}>{props.children}</Group>
)

export const FormFields = (props: any) => <Fields>{props.children}</Fields>

export const FormField = (props: any) => (
  <Field {...props}>{props.children}</Field>
)

export const FormFooter = (props: any) => (
  <Footer {...props}>{props.children}</Footer>
)

export const FooterGroup = (props: any) => <Footer>{props.children}</Footer>
export const FooterGroupItem = (props: any) => (
  <StyledFooterGroupItem>{props.children}</StyledFooterGroupItem>
)

export const FormButtons = (props: any) => (
  <Buttons {...props}>{props.children}</Buttons>
)

const Container = styled.form<{ padding: string; headerHeight?: string }>`
  padding: ${({ padding }): string => padding};
  display: flex;
  flex-direction: column;
  height: ${({ headerHeight }): string =>
    headerHeight ? `calc(100% - ${headerHeight})` : '100%'};
`

const Form = styled.div<{ padding: string }>`
  padding: ${({ padding }) => padding};
`
const Item = styled.div`
  position: relative;
  margin-left: 13px;
  margin-right: 13px;
  padding: 13px 0;
  .css-26l3qy-menu {
    z-index: 3 !important;
  }

  div.error > div {
    border: 1px solid red;
  }
`

const GroupWrapper = styled.div<{ padding: number }>`
  padding: ${({ padding }) => (padding ? padding + 'px' : '')};
  flex: 2;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  overflow-y: scroll;
`

const Group = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Fields = styled.div`
  padding: 30px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Field = styled.div<{ width: string; space: number }>`
  width: ${({ width }) => (width ? width : '100%')};
  margin-bottom: ${({ space }) => (space ? space + 'px' : '30px')};
`

const Footer = styled.footer`
  width: 100%;
`

// const Footer = styled.div`
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   background: #fff;
//   right: 0;
//   box-sizing: border-box;
//   padding: 15px;
//   display: flex;
//   align-items: center;
// `

const StyledFooterGroupItem = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`

export const FormNote = styled.article`
  margin-right: 10px;
  font-size: 12px;
  opacity: 0.8;
`

const Buttons = styled.div<{ multiCols: boolean }>`
  width: 100%;
  align-self: flex-end;
  justify-content: flex-end;
  display: flex;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);

  div:first-child {
    flex: ${({ multiCols }) => (multiCols ? 2 : '')};
  }

  div:not(:last-child) {
    margin-right: 10px;
  }

  button {
    width: unset !important;
  }
`
export const FormGroupItemLabel = styled.label`
  display: inline-block;
  margin-bottom: 5px;
`
export const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
export const Label = styled.label`
  position: absolute;
  z-index: 1;
  background: #fff;
  font-size: 12px;
  top: -9px;
  left: 9px;
`
