import { useMutation } from '@apollo/client'
import { DELETE_LESSON } from 'gql/lesson/lesson.query'
import { GET_MODULE } from 'gql/modules.query'
import { DeleteLessonParams } from 'hooks/lessons/useDeleteLessonService'

const deleteLessonService = (variables: DeleteLessonParams) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteLessonMutation, { loading }] = useMutation(DELETE_LESSON, {
    refetchQueries: [
      {
        query: GET_MODULE,
        variables,
      },
    ],
  })

  return {
    deleteLessonMutation,
    loading,
  }
}

export default deleteLessonService
