import React from 'react'
import { Action } from 'components/common/GridV2'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { ITFunction } from 'components/common/Tfunction/Tfunction.interface'
import { useNavigate } from 'react-router-dom'
import { DeleteSpan } from 'components/common/Styled/styled-components'

interface Item {
  id: string
}

export const actionConfig = (
  handleEditClick?: (id: string) => void,
  handleDeleteClick?: (
    id: string | number,
    edited: boolean,
    callback?: () => void,
  ) => void,
  t: ITFunction = (key): string => key,
): { render: (item: Item) => React.JSX.Element }[] => {
  const navigate = useNavigate()
  const action = [
    {
      render: (item: Item): React.JSX.Element => {
        return (
          <Action
            onClick={(): void => handleEditClick && handleEditClick(item.id)}
          >
            <EditRoundedIcon />
            <span>{t('actions.edit')}</span>
          </Action>
        )
      },
    },
    {
      render: (item: Item): React.JSX.Element => {
        return (
          <Action
            onClick={(): void => {
              if (handleDeleteClick) {
                handleDeleteClick(item.id, false, () => {
                  navigate('/questions')
                })
              }
            }}
          >
            <DeleteIcon />
            <DeleteSpan>{t('actions.delete')}</DeleteSpan>
          </Action>
        )
      },
    },
  ]

  return action
}
