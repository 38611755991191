import React from 'react'
import Cropper from 'react-cropper'

import { getRole } from '../../AdminProfile/AdminProfile'
import { UserCourses } from '../UserCourses'
import ResetUsersPassword from '../ResetPassword'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { EditableAvatarV2 } from 'components/common/EditableAvatar.V2/EditableAvatar'
import { ReactComponent as CoachIcon } from 'assets/coach.svg'
import {
  ActionsGrid,
  ActionsItem,
  ActionsWrapper,
  AvatarContainer,
  CustomBorder,
  DetailsFlex,
  DetailsHeader,
  Half,
  InformationBackground,
  InputWrapper,
  ProfileBackground,
  StyledBorder,
  StyledFlex,
  UserNameText,
  ItemsContainer,
  UserRoleText,
  UserShortInfo,
} from './styled-components'
import { Button } from 'components/common/Button'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import useAllUserProfile from 'hooks/users/useAllUserProfile'
import { roles } from 'utils/permission'
import UserDetailsComponent, {
  StatusWrapperComponent,
  WrapperComponent,
} from './Components/Wrapper'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { ReactComponent as MoreIcon } from 'components/common/Button/icons/drop.svg'
import { ImageCropperContainer } from 'components/common/EditableAvatar.V2/styled-components'
import Loader from 'components/common/Loader'
import { UserTestAssessments } from 'components/UsersLayout/UserTestAssessments/UserTestAssessments'
import { Tooltip } from '@mui/material'

const AllUserProfile: React.FC<{}> = () => {
  const {
    profileData,
    isResetPasswordOpen,
    setResetPasswordOpen,
    hasResponseReceived,
    finishCrop,
    isCompany,
    id,
    infoItemsRow,
    actions,
    breadcrumbData,
    actionsRow,
    handleAvatarChange,
    avatarRef,
    cropperOpen,
    styles,
    setCropperOpen,
    cropper,
    file,
    cropperModalToggle,
    onSave,
    loading,
  } = useAllUserProfile()

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <StyledFlex>
        <ProfileBackground>
          <ActionsWrapper>
            {actions && actions.length > 0 && (
              <GridActionMenu
                actionConfig={actionsRow}
                row={profileData}
                button={<MoreIcon />}
              />
            )}
            <InputWrapper>
              <input
                type="file"
                name="file"
                id="file"
                ref={avatarRef}
                onChange={handleAvatarChange}
              />
            </InputWrapper>
          </ActionsWrapper>
          <AvatarContainer>
            <EditableAvatarV2
              img={profileData.avatarThumbnail}
              finishCrop={finishCrop}
              role={profileData.role || 'N/A'}
              hasResponseReceived={hasResponseReceived}
            />
            <UserShortInfo>
              <UserNameText>
                {profileData.firstName} {profileData.lastName}{' '}
                {profileData.isTutor && (
                  <Tooltip
                    title={t('users_layout.tutor_admin_indicator')}
                    arrow
                  >
                    <CoachIcon />
                  </Tooltip>
                )}
              </UserNameText>
              <UserRoleText>
                {getRole(profileData.role || 'N/A', isCompany)}
              </UserRoleText>
            </UserShortInfo>
          </AvatarContainer>
          <ActionsGrid>
            {actions.map((action, index) => (
              <React.Fragment key={index}>
                <ActionsItem onClick={action.onClick}>
                  <div className="action-content">
                    {action.icon}
                    {action.text}
                  </div>
                </ActionsItem>
                {index !== actions.length - 1 && <CustomBorder />}
              </React.Fragment>
            ))}
          </ActionsGrid>
        </ProfileBackground>
        <InformationBackground>
          <DetailsHeader>{t('general.detailed_information')} </DetailsHeader>
          <DetailsFlex>
            <Half>
              <StatusWrapperComponent />
              {infoItemsRow.slice(0, 4).map((item, index) => (
                <WrapperComponent key={index} item={item} />
              ))}
            </Half>
            <StyledBorder />
            <Half>
              {infoItemsRow.slice(4).map((item, index) => (
                <WrapperComponent key={index} item={item} />
              ))}
            </Half>
          </DetailsFlex>
        </InformationBackground>
      </StyledFlex>
      <UserDetailsComponent profileData={profileData} />
      {isResetPasswordOpen && id && (
        <ResetUsersPassword
          isModalOpen={isResetPasswordOpen}
          setModalOpen={setResetPasswordOpen}
          userId={id}
        />
      )}
      <ItemsContainer>
        {profileData?.role &&
          profileData.role === roles.student &&
          profileData?.group && (
            <UserCourses
              userId={profileData?.id}
              groups={profileData?.group}
              companyId={profileData?.companyId?.id || null}
            />
          )}
        {profileData?.role && profileData.role === roles.student && (
          <UserTestAssessments user={profileData} />
        )}
      </ItemsContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.modal}
        open={cropperOpen}
        onClose={() => setCropperOpen(false)}
        closeAfterTransition
      >
        <ImageCropperContainer>
          {loading && <Loader />}
          <Cropper
            ref={cropper}
            src={file}
            style={{ height: 500, width: 600 }}
            aspectRatio={16 / 16}
            guides={false}
          />
          <div className="actions">
            <Button
              color="primary"
              onClick={cropperModalToggle}
              background="#E0E1E2"
              textColor="#414141"
              text={t('actions.cancel')}
              type="small"
            />
            <Button
              //   variant="contained"
              color="primary"
              onClick={onSave}
              background="#06C68F"
              text={t('actions.save')}
              type="small"
            />
          </div>
        </ImageCropperContainer>
      </Modal>
    </>
  )
}

export default AllUserProfile
