import React from 'react'
import { DrawerEventEmitter } from 'helpers/drawer'
import _ from 'lodash'
import { PER_PAGE as perPage } from 'hooks/modules/useModules'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import {
  ModalRef,
  ToolbarItemWrapper,
  ContentWrapper,
  styles,
} from './styled-components'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import IconButton from '@mui/material/IconButton'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import SyncIcon from '@mui/icons-material/Sync'
import Modal from '@mui/material/Modal'
import Player from 'components/common/VideoPlayer/Player'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import Drawer from 'components/common/Drawer'
import { Grid } from 'components/common/Cards/styled-components'
import ModulesList from './ModulesList'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import PaginationV2 from 'components/common/PaginationV2'
import { SplashScreen } from 'components/common'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import useModulesLayout from 'hooks/modules/useModuleLayout'
import Breadcrumb from 'components/common/BreadCrumbV2'

const ModulesLayout = (): React.JSX.Element => {
  const {
    state,
    refetch,
    filterQuery,
    setSelectedItem,
    modules,
    companyId,
    searchText,
    t,
    setSelectAll,
    filterData,
    resetFilter,
    filterDrawer,
    onSearchSubmit,
    handleSearchChange,
    selectItem,
    handleVideoModal,
    actions,
    handlePaginationClick,
    videoModal,
    setVideoModal,
    filterIsOpen,
    selectedItem,
    currentPage,
    setFilterData,
    setFilterQuery,
  } = useModulesLayout()

  const breadCrumbData = [
    {
      label: 'Modules',
      icon: <Learning />,
    },
  ]

  const createButton = (): React.JSX.Element => {
    if (!state.userPermission.createModule) {
      return <></>
    }

    return (
      <Button
        text={t('modules_layout.create_module')}
        color="secondary"
        onClick={(): void =>
          DrawerEventEmitter.emit('openDrawer', 'addModule', true, {
            data: {
              courseId: '',
              name: '',
              onSuccess: () => {
                refetch({
                  filter: {
                    companyId: {
                      type: LowerCaseFilterableFieldType.EXACT,
                      value: state.selectedCompany?.id,
                    },
                    query: {
                      type: 'query',
                      value: JSON.stringify(filterQuery),
                    },
                  },
                  currentPage: 1,
                  perPage,
                })
                setSelectedItem({})
                setSelectAll({})
              },
            },
          })
        }
        icon={<AddCircleRoundedIcon />}
        background="#06C68F"
      />
    )
  }
  if (modules && companyId && !searchText && modules?.data.length <= 0) {
    return (
      <>
        <Breadcrumb data={breadCrumbData} />
        <SplashScreen
          title={t('modules_layout.no_modules')}
          subTitle={t('modules_layout.create_modules')}
          createButton={createButton}
        />
      </>
    )
  }

  return (
    <div>
      <ToolbarItem>
        <Breadcrumb data={breadCrumbData} />
      </ToolbarItem>

      <Toolbar style={{ justifyContent: 'space-between' }}>
        <ToolbarItemWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {state.userPermission.createModule && (
              <Button
                text={t('modules_layout.create_module')}
                color="secondary"
                onClick={(): void =>
                  DrawerEventEmitter.emit('openDrawer', 'addModule', true, {
                    data: {
                      courseId: '',
                      name: '',
                      onSuccess: () => {
                        refetch({
                          filter: {
                            companyId: {
                              type: 'exact',
                              value: state.selectedCompany?.id,
                            },
                            query: {
                              type: 'query',
                              value: JSON.stringify(filterQuery),
                            },
                          },
                          currentPage: 1,
                          perPage,
                        })
                        setSelectedItem({})
                        setSelectAll({})
                      },
                    },
                  })
                }
                icon={<AddCircleRoundedIcon />}
                background="#06C68F"
              />
            )}
          </div>

          <div className="wrapper">
            <ToolbarItem>
              <IconButton
                disabled={_.isEmpty(filterData) && !searchText}
                onClick={resetFilter}
              >
                <SyncIcon fontSize="small" />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <IconButton onClick={filterDrawer}>
                <FilterIcon />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={handleSearchChange}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                  iconInStart
                />
              </form>
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>
      </Toolbar>

      <ContentWrapper>
        <Grid className={'card'} cols={4}>
          {modules && state.selectedCompany?.id && modules.data.length <= 0 && (
            <div>No records found</div>
          )}

          {modules && (
            <ModulesList
              data={modules.data}
              // isDraggable={!searchText && _.isEmpty(filterData) ? true : false}
              gridStyle={'card'}
              selectedItem={selectedItem[currentPage] || []}
              selectItem={selectItem}
              handleVideoModal={handleVideoModal}
              actions={actions}
            />
          )}
        </Grid>
        {modules && modules.data.length > 0 && (
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PaginationV2
              currentPage={modules ? modules.currentPage : 1}
              totalPages={modules ? modules.totalPages : 0}
              handleChange={handlePaginationClick}
            />
          </div>
        )}
      </ContentWrapper>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.modal}
        open={videoModal.modal}
        onClose={(): void =>
          setVideoModal({ data: '', modal: false, subtitle: '' })
        }
        closeAfterTransition
      >
        <ModalRef>
          <Player url={videoModal.data} subtitle={videoModal.subtitle} />
        </ModalRef>
      </Modal>

      <Drawer
        opened={filterIsOpen}
        toggleDrawer={(): void => {}}
        totalWidth="700px"
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          refetchData={refetch}
          resetFilter={resetFilter}
          onClose={filterDrawer}
          companyId={state.selectedCompany?.id}
          t={t}
        />
      </Drawer>
    </div>
  )
}

export default ModulesLayout
