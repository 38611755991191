import React from 'react'
import IconButton from '@mui/material/IconButton'
import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker/DatePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Title,
} from 'components/DrawerLayout/styled-components'
import { genderOptions } from 'components/UsersLayout/staticData'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import CloseIcon from '@mui/icons-material/Close'
import { IEditCoachProps } from './editCoach.interface'
import { useCoachDrawer } from 'hooks/coaches/useCoachDrawer'
import { IOptionType } from '../AddCoach/addCoach.interface'

const EditCoach = ({ id }: IEditCoachProps): React.JSX.Element => {
  const {
    editUserLoading,
    errors,
    handleChange,
    handleSubmit,
    handleCloseDrawerEdit,
    t,
    touched,
    setFieldValue,
    values,
    companyOptions,
    editingCompanyUser,
    state,
    generalError,
    getCompanyOptions,
    setEditingCompanyUser,
    onDateChange,
  } = useCoachDrawer({ userId: id })

  return (
    <>
      {editUserLoading && <Loader />}

      <DrawerHeader>
        <DrawerTitle>
          {' '}
          {t('actions.edit')} {t('general.coach')}
        </DrawerTitle>
        <IconButton onClick={handleCloseDrawerEdit}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.first_name')}*`}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  size="small"
                  placeholder={`${t('user_details.first_name')}`}
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.last_name')}*`}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  size="small"
                  placeholder={`${t('user_details.last_name')}`}
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  title={`${t('user_details.email')}*`}
                  size="small"
                  placeholder={`${t('user_details.email')}`}
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <Title>{t('user_details.phone')}</Title>
                <PhoneCountryCode
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={null}
                  selectChange={(e: IOptionType): void =>
                    setFieldValue('phoneFields', e)
                  }
                  inputLabel="Phone"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={errors.phone || errors.phoneFields ? true : false}
                  errorMessage={
                    errors.phone || errors.phoneFields
                      ? errors.phone || errors.phoneFields
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('user_details.select_gender')}</Title>
                <SelectField
                  placeholder={t('user_details.select_gender')}
                  options={genderOptions}
                  error={errors.gender && touched.gender ? true : false}
                  errorMessage={errors.gender ? errors.gender : ''}
                  touched={touched.gender}
                  value={values.gender}
                  onChange={(e: IOptionType): void =>
                    setFieldValue('gender', e)
                  }
                  isClearable
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('user_details.birthday')}</Title>
                <DatePicker
                  name="birthDate"
                  touched={!!touched.birthDate}
                  error={errors.birthDate ? true : false}
                  errorMessage={errors.birthDate ? errors.birthDate : ''}
                  value={values.birthDate}
                  onChange={onDateChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.job_title')}`}
                  size="small"
                  placeholder={`${t('form_fields.job_title')}`}
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size="small"
                  placeholder={`${t('form_fields.location')}`}
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  title={`${t('user_details.biography')}`}
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  size="small"
                  placeholder={`${t('quiz_details.type_here')}`}
                  name="biography"
                  type="text"
                  multiline
                  rows="3"
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField>
                <TextInput
                  title={`${t('user_details.note')}`}
                  error={errors.note ? true : false}
                  errorMessage={errors.note ? errors.note : ''}
                  touched={touched.note}
                  size="small"
                  placeholder={`${t('user_details.note')}`}
                  name="note"
                  type="text"
                  multiline
                  rows="3"
                  value={values.note}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>

              {!state.currentUser?.companyId && !state.selectedCompany?.id && (
                <FormField width="100%">
                  <CustomSwitcher
                    onChange={(): void => {
                      setEditingCompanyUser(!editingCompanyUser)
                      if (!companyOptions) {
                        getCompanyOptions({
                          variables: {
                            currentPage: 0,
                            perPage: 0,
                          },
                        })
                      }
                      if (values.companyId) {
                        setFieldValue('companyId', null)
                      }
                    }}
                    label="Company Tutor"
                    name="skillTestVideoEnabled"
                    value={editingCompanyUser}
                  />
                </FormField>
              )}
              {!state.selectedCompany?.id && editingCompanyUser && (
                <FormField
                  width="100%"
                  style={{
                    height: 320,
                  }}
                >
                  <SelectField
                    error={!!errors.companyId}
                    errorMessage={errors.companyId ? errors.companyId : ''}
                    touched={touched.companyId}
                    placeholder={`${t('form_fields.select')} ${t(
                      'general.company',
                    )}`}
                    options={companyOptions?.companies?.data.map(
                      (company: { name: string; id: string }) => ({
                        label: company.name,
                        value: company.id,
                      }),
                    )}
                    value={values.companyId}
                    onChange={(e: { label: string; value: string }): void =>
                      setFieldValue('companyId', e)
                    }
                    isClearable
                  />
                </FormField>
              )}
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawerEdit}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditCoach
