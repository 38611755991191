import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useTranslation } from 'react-i18next'

import { useSwal } from 'hooks/useSwal'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'

import Drawer from 'components/common/Drawer'

import { EditGroupDrawer } from 'pages/group'
import { BadgeEditor } from 'pages/modules/BadgeEditor'
import { CompaniesEdit } from 'pages/companies'
import { AddStudent, EditStudent } from 'pages/students'
import { AddCoach, EditCoach } from 'pages/coaches'
import { AddAdmin, EditAdmin } from 'pages/admins'
import { AddModerator, EditModerator } from 'pages/moderators'
// import AddCourses from 'pages/courses/AddCourses/AddCourses'
import { AddModule } from 'components/modules'
import {
  AddCourseDrawer,
  EditCourseDrawer,
  AddCourseModuleDrawer,
  AssignTutorDrawer,
} from 'pages/courses'
import AddTestDrawer from 'pages/tests/addTestDrawer/AddTestDrawer'
import { AddTopicDrawer } from 'pages/modules'
import { EditTopicDrawer } from 'pages/topics'
// import AddCourses from 'pages/courses/AddCourses/AddCourses'
import { EditModuleDrawer } from 'pages/modules'
import { AddLessonDrawer, EditLessonDrawer } from 'pages/lessons'
import { CreateQuestionDrawer, EditQuestionDrawer } from 'pages/questions'
import {
  AddQuizDrawer,
  EditQuizDrawer,
  AddQuestionsToQuiz,
} from 'pages/quizzes'
import { NotificationLayout } from 'pages/notifications'
import {
  AddFlashCardDrawer,
  EditFlashCardDrawer,
} from 'pages/modules/FlashCards'
import {
  AddSkillTestVideoDrawer,
  EditSkillTestVideoDrawer,
} from 'pages/modules/ModuleDetail/SkillTestVideos'
import {
  AddEditSkillTestVideoCriteriaDrawer,
  ViewSkillTestVideoCriteriasDrawer,
} from 'pages/modules/SkillTestVideoCriterias'
import EditTestDrawer from 'pages/tests/editTestDrawer/EditTestDrawer'
import { useDeleteLessonAssets } from 'hooks/lessons/useDeleteLessonAssets'
import AddStudentsInNewGroup from 'pages/group/AddGroupDrawer/AddStudentsInNewGroup'
import EditBookDrawer from 'pages/books/EditBookDrawer/EditBookDrawer'
import AddBookToGroup from 'pages/group/GroupBooks/AddBookToGroupDrawer/addBookToGroup'
import AddCompanyDrawer from 'pages/companies/CompaniesAdd/AddCompanyDrawer'

const DrawerLayout = () => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const { fireSwal } = useSwal()
  const { handleDeleteLessonAssets } = useDeleteLessonAssets()
  const [args, setArgs] = useState<any>({
    id: '',
    role: '',
    data: {},
    type: '',
  })

  const [drawer, setDrawer] = useState({
    groupInformation: false,
    companyAdd: false,
    companiesAdd: false,
    companiesEdit: false,
    addModerator: false,
    addQuestions: false,
    addGroup: false,
    addAdmin: false,
    addTest: false,
    editTest: false,
    addCoach: false,
    addBookToGroup: false,
    addStudent: false,
    editStudent: false,
    editCoach: false,
    editAdmin: false,
    editModerator: false,
    filterCoach: false,
    addEditSkillTestVideoCriteria: false,
    badgeEditor: false,
    addCourses: false,
    editCourse: false,
    addModule: false,
    addCourseModule: false,
    editModuleDrawer: false,
    addTopic: false,
    editTopic: false,
    addLesson: false,
    editLesson: false,
    createQuestion: false,
    editQuestion: false,
    linkQuestionsToModule: false,
    addQuiz: false,
    editQuiz: false,
    AddQuestionsToQuiz: false,
    notification: false,
    editBook: false,
    addFlashCard: false,
    editFlashCard: false,
    rejectionDetails: false,
    addSkillTestVideo: false,
    editSkillTestVideo: false,
    viewSkillTestVideoCriterias: false,
    addStudentInGroup: false,
    assignTutor: false,
  })

  const handleDrawerEvent = (
    field: string,
    event: boolean,
    args?: any,
  ): void => {
    if (args) {
      setArgs(args)
    }
    setDrawer({
      ...drawer,
      [field]: event,
    })
  }

  useEffect(() => {
    const isEmpty = _.values(state.formData).every(_.isEmpty)
    if (!isEmpty) {
      const { type, values, compareTo, drawer } = state.formData
      const isEqual = _.isEqual(values, compareTo)
      const isEmpty = _.values(values).every(_.isEmpty)

      let lessonVideoId = ''
      if (
        (drawer === 'addLesson' || drawer === 'editLesson') &&
        state?.formData?.isVideoUpdated
      ) {
        lessonVideoId = values?.video?.id
      }

      if (type === 'add' && isEmpty) {
        dispatch({
          type: 'CLEAR_FORMDATA',
          payload: true,
        })
        handleDrawerEvent(drawer, false)
      }

      if (type === 'add' && !isEmpty) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            if (drawer === 'addLesson' && state?.formData?.isVideoUpdated) {
              handleDeleteLessonAssets(lessonVideoId)
            }
            handleDrawerEvent(drawer, false)
            toggleDrawerConfirm(false, '')
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          onClose: () => {
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          confirmText: t('popups.confirm_cancel'),
          cancelText: t('general.no'),
        })
      }

      if (type === 'edit' && isEqual) {
        dispatch({
          type: 'CLEAR_FORMDATA',
          payload: true,
        })
        handleDrawerEvent(drawer, false)
      }

      if (type === 'edit' && !isEqual) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            if (drawer === 'editLesson' && state?.formData?.isVideoUpdated) {
              handleDeleteLessonAssets(lessonVideoId)
            }
            handleDrawerEvent(drawer, false)
            toggleDrawerConfirm(false, '')
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          onClose: () => {
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          confirmText: 'Yes, Cancel!',
          cancelText: 'No',
        })
      }
    }
  }, [state.formData])

  useEffect(() => {
    DrawerEventEmitter.addListener('openDrawer', handleDrawerEvent)
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleToggleDrawer = (): void => {}

  return (
    <>
      {/* {drawers.map((drawerItem: Props) => (
        <Drawer
          opened={drawer[drawerItem.drawer]}
          toggleDrawer={() => handleDrawerEvent(drawerItem.name, false)}
          totalWidth={drawerItem.width}
        >
          {drawerItem.component}
        </Drawer>
      ))} */}
      <Drawer
        opened={drawer.groupInformation}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <EditGroupDrawer
          id={args.id}
          onClose={(): void => handleDrawerEvent('groupInformation', false)}
          companyId={args.companyId}
        />
      </Drawer>
      <Drawer
        opened={drawer.addStudentInGroup}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <AddStudentsInNewGroup
          groupData={args.groupData}
          closeDrawer={() => handleDrawerEvent('addStudentInGroup', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.assignTutor}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <AssignTutorDrawer
          groupData={args.groupData}
          courseData={args.courseData}
          closeDrawer={() => handleDrawerEvent('assignTutor', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.addBookToGroup}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <AddBookToGroup
          groupData={args.groupData}
          closeDrawer={() => handleDrawerEvent('addBookToGroup', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.badgeEditor}
        toggleDrawer={handleToggleDrawer}
        totalWidth="80vw"
      >
        <BadgeEditor
          id={args.id}
          closeDrawer={(): void => handleDrawerEvent('badgeEditor', false)}
          drawerData={args.data}
        />
      </Drawer>

      <Drawer opened={drawer.editBook} toggleDrawer={handleToggleDrawer}>
        <EditBookDrawer
          onClose={(): void => handleDrawerEvent('editBook', false)}
          companyId={args.companyId}
          bookId={args.bookId}
        />
      </Drawer>
      <Drawer
        opened={drawer.companiesEdit}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <CompaniesEdit companyId={args.id} refetchCompany={args.data} />
      </Drawer>
      <Drawer
        opened={drawer.companiesAdd}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddCompanyDrawer />
      </Drawer>
      <Drawer
        opened={drawer.addModerator}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddModerator
          title={t('users_layout.add_super_admin')}
          role="SUPER_ADMIN"
          drawerName="addModerator"
          drawerData={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.addAdmin}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddAdmin
          drawerData={args.data}
          onClose={(): void => handleDrawerEvent('addAdmin', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.addTest}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddTestDrawer
          title={t('tests_layout.new_test')}
          drawerName="addTest"
          drawerData={args.data}
          onClose={(): void => handleDrawerEvent('addTest', false)}
          handleUploadFile={handleToggleDrawer}
          onSuccess={args.onSuccess}
        />
      </Drawer>
      {/* <Drawer
        opened={drawer.addQuestions}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddQuestionsDrawer
          title="Add Questions"
          drawerName="addQuestions"
          drawerData={args.data}
          onClose={(): void => handleDrawerEvent('addQuestions', false)}
          handleUploadFile={handleToggleDrawer}
        />
      </Drawer>
      <Drawer
        opened={drawer.addQuestions}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddGroupsDrawer
          title="Add Groups"
          drawerName="addGroup"
          drawerData={args.data}
          onClose={(): void => handleDrawerEvent('addGroup', false)}
          handleUploadFile={handleToggleDrawer}
        /> */}
      {/* </Drawer> */}
      <Drawer
        opened={drawer.editTest}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <EditTestDrawer
          id={args.id}
          onClose={(): void => handleDrawerEvent('editTest', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.addStudent}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddStudent
          title={t('users_layout.add_student')}
          role="STUDENT"
          drawerName="addStudent"
          drawerData={args.data}
          onClose={(): void => handleDrawerEvent('addStudent', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.addCoach}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddCoach
          title="Add Tutor"
          role="COACH"
          drawerName="addCoach"
          drawerData={args.data}
          onClose={(): void => handleDrawerEvent('addCoach', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.editStudent}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <EditStudent id={args.id} role={args.role} />
      </Drawer>
      <Drawer
        opened={drawer.editCoach}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <EditCoach id={args.id} role={args.role} />
      </Drawer>
      <Drawer
        opened={drawer.editAdmin}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <EditAdmin id={args.id} role={args.role} />
      </Drawer>
      <Drawer
        opened={drawer.editModerator}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <EditModerator
          id={args.id}
          role={args.role}
          drawerName="editModerator"
        />
      </Drawer>
      {/* <Drawer
        opened={drawer.filterCoach}
        toggleDrawer={(): void => handleDrawerEvent('filterCoach', false)}
        totalWidth="600px"
      >
        <FilterCoaches id={args.id} role={args.role} />
      </Drawer> */}
      <Drawer
        opened={drawer.addCourses}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <AddCourseDrawer
          onClose={(): void => handleDrawerEvent('addCourses', false)}
          data={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.addEditSkillTestVideoCriteria}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddEditSkillTestVideoCriteriaDrawer
          title={t('skillTestVideoCriteria_layout.addEdit')}
          data={args.data}
          onClose={(): void =>
            handleDrawerEvent('addEditSkillTestVideoCriteria', false)
          }
        />
      </Drawer>
      <Drawer
        opened={drawer.viewSkillTestVideoCriterias}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <ViewSkillTestVideoCriteriasDrawer
          title="View Skill Test Video Criterias"
          criterias={args.data}
          onClose={(): void =>
            handleDrawerEvent('viewSkillTestVideoCriterias', false)
          }
        />
      </Drawer>
      <Drawer
        opened={drawer.editCourse}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <EditCourseDrawer id={args.id} />
      </Drawer>
      <Drawer
        opened={drawer.addModule}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddModule
          title={t('modules_layout.create_module')}
          data={args.data}
          drawerName="addModule"
        />
      </Drawer>
      <Drawer
        opened={drawer.addCourseModule}
        toggleDrawer={handleToggleDrawer}
        totalWidth="800px"
      >
        <AddCourseModuleDrawer
          title={t('actions.add_modules_to_course')}
          drawerName="addCourseModule"
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.editModuleDrawer}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <EditModuleDrawer
          onClose={(): void => handleDrawerEvent('editModuleDrawer', false)}
          id={args.id}
        />
      </Drawer>

      <Drawer
        opened={drawer.addTopic}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddTopicDrawer
          title="Add topic to module"
          id={args.id}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.editTopic}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <EditTopicDrawer
          title="Edit topic"
          drawerName="editTopic"
          id={args.id}
        />
      </Drawer>

      <Drawer
        opened={drawer.addLesson}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <AddLessonDrawer
          onClose={(): void => handleDrawerEvent('addLesson', false)}
          id={args.id}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.editLesson}
        toggleDrawer={handleToggleDrawer}
        totalWidth="600px"
      >
        <EditLessonDrawer
          onClose={(): void => handleDrawerEvent('editLesson', false)}
          moduleId={args.id}
          lesson={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.createQuestion}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <CreateQuestionDrawer
          title={t('questions_layout.create_question')}
          currentModule={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.editQuestion}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <EditQuestionDrawer
          title={t('questions_layout.edit_question')}
          currentModule={args.data}
          questionId={args.id}
        />
      </Drawer>
      <Drawer
        opened={drawer.addQuiz}
        toggleDrawer={handleToggleDrawer}
        totalWidth="592px"
      >
        <AddQuizDrawer
          onClose={(): void => handleDrawerEvent('addQuiz', false)}
          id={args.id}
          type={args.type}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.editQuiz}
        toggleDrawer={handleToggleDrawer}
        totalWidth="592px"
      >
        <EditQuizDrawer
          onClose={(): void => handleDrawerEvent('editQuiz', false)}
          id={args.id}
          type={args.type}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.AddQuestionsToQuiz}
        toggleDrawer={handleToggleDrawer}
        totalWidth="800px"
      >
        <AddQuestionsToQuiz
          onClose={(): void => handleDrawerEvent('AddQuestionsToQuiz', false)}
          id={args.id}
          type={args.type}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.notification}
        toggleDrawer={(): void => handleDrawerEvent('notification', false)}
        totalWidth="700px"
      >
        <NotificationLayout
          onClose={(): void => handleDrawerEvent('notification', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.addFlashCard}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <AddFlashCardDrawer
          onClose={(): void => handleDrawerEvent('addFlashCard', false)}
          data={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.editFlashCard}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <EditFlashCardDrawer
          onClose={(): void => handleDrawerEvent('editFlashCard', false)}
          data={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.addSkillTestVideo}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <AddSkillTestVideoDrawer
          onClose={(): void => handleDrawerEvent('addSkillTestVideo', false)}
          data={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.editSkillTestVideo}
        toggleDrawer={handleToggleDrawer}
        totalWidth="700px"
      >
        <EditSkillTestVideoDrawer
          onClose={(): void => handleDrawerEvent('editSkillTestVideo', false)}
          data={args.data}
        />
      </Drawer>
    </>
  )
}

export default DrawerLayout
