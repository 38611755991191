import { useState, useEffect, useCallback } from 'react'
import CheckSVG from 'assets/checked.svg'
import Badge from 'components/common/Badge/Badge'
import React from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useQuery, useMutation } from '@apollo/client'
import { GET_MODULE, GENERATE_BADGE_DESIGN } from 'gql/modules.query'
import useEditModuleService from './useEditModuleService'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import { useGenerateUploadVideoLinks } from 'hooks/helpers/useHelpersService'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'
import { ModuleSchema } from 'helpers/validationSchemas'
import { useTranslation } from 'react-i18next'
import { SET_FORMDATA } from 'store/types'
import {
  ICloneModuleData,
  ICurrentModuleType,
  IDrawerState,
  IEditFormDataInterface,
  IFileState,
  IVideoState,
} from 'components/modules/AddModule.interface'

const fromData: IEditFormDataInterface = {
  name: '',
  coverImage: { name: '', link: '' },
  attachment: null,
  video: null,
  subtitle: null,
  skillTestVideoEnabled: true,
  skillTestVideoCriteriaEnabled: true,
  skillTestVideoDetails: true,
  badgeEnabled: false,
  badgeData: null,
}

const defaultTips = [
  {
    icon: CheckSVG,
    text: 'Keep it concise',
  },
  {
    icon: CheckSVG,
    text: 'Use your own stories',
  },
  {
    icon: CheckSVG,
    text: 'Get the message across',
  },
]

const useEditModuleDrawer = (id: string, onClose: () => void) => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const [file, setFile] = useState<IFileState>({ name: '', file: null })
  const [introVideo, setIntroVideo] = useState<IVideoState>()
  const [cropperOpen, setCropperOpened] = useState(false)
  const [videoUploadProgress, setProgress] = useState<number>(0)
  const [currentModule, setCurrentModule] = useState<ICurrentModuleType>()
  const [drawer, setDrawer] = useState<IDrawerState>({
    badgeEditor: false,
    args: {},
  })

  const {
    data,
    loading: getModuleLoading,
    error,
  } = useQuery(GET_MODULE, {
    variables: { moduleId: id },
  })

  const { editModule, loading } = useEditModuleService({ moduleId: id })
  const { uploadImage, imageLoading } = useUploadImage()
  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()

  const [generateBadgeDesign, { loading: generatingBadge }] = useMutation(
    GENERATE_BADGE_DESIGN,
  )

  const uploadVideoService = (url: string): Promise<string> => {
    return axios.put(url, introVideo, {
      headers: {
        'Content-Type': introVideo?.type || 'application/octet-stream',
      },
      onUploadProgress: ({ total, loaded }) => {
        setProgress((loaded / total) * 100)
      },
    })
  }

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    const { files } = e.target
    const reader: FileReader = new FileReader()
    reader.onload = () => {
      const img: HTMLImageElement = new Image()
      img.src = reader.result as string
      setFile({ name: field, file: reader.result })
    }
    if (files) {
      reader.readAsDataURL(files[0])
      if (field !== 'certificateImage') {
        setCropperOpened(true)
      }
    }
  }

  const onSuccess = (): void => {
    toggleDrawerConfirm(false, '')
    onClose()
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: fromData,
    validationSchema: ModuleSchema,
    onSubmit(values) {
      const data = removeTypeNameFromObject({ ...values })
      data.skillTestVideoDetails = values.skillTestVideoDetails

      if (values.skillTestVideoEnabled) {
        data.skillTestVideoDetails = values.skillTestVideoDetails
          ? 'default'
          : 'custom'
      } else {
        data.skillTestVideoDetails = null
      }

      editModule(id, data, onSuccess)
    },
  })

  useEffect(() => {
    if (data && data.getModule) {
      const dataClone: ICloneModuleData = {} as ICloneModuleData
      for (const [key] of Object.entries(fromData)) {
        dataClone[key] = data.getModule[key]
      }
      dataClone.skillTestVideoDetails =
        dataClone.skillTestVideoDetails === 'default' ? true : false

      if (data.getModule?.badgeData?.id) {
        dataClone.badgeData = data?.getModule?.badgeData
      }
      setValues(dataClone as unknown as IEditFormDataInterface)
      setCurrentModule(dataClone)
    }
  }, [data])

  const handleGenerateUploadLink = () => {
    const videos = [{ fileName: introVideo?.name, type: introVideo?.type }]
    generateUploadVideoLinks(videos, 'modules', uploadVideoService, (args) => {
      setFieldValue('video', args[0])
      setFieldValue('video.title', args[0].name)
      setProgress(0)
    })
  }

  const handleCloseDrawer = () => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editModuleDrawer',
        values,
        compareTo: currentModule,
      },
    })
  }

  const handleBadgeEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('badgeEnabled', e.target.checked)
    if (e.target.checked && !values.badgeData?.url) {
      generateBadgeDesign().then((res) => {
        const data = res?.data?.generateBadgeDesign
        const { id, url } = data
        setFieldValue('badgeData', {
          id,
          url,
        })
      })
    }
  }

  const handleBadgeEditorOpen = () => {
    setDrawer({
      badgeEditor: true,
      args: {
        id: values?.badgeData?.id,
      },
    })
  }

  const handleBadgeEditorClose = (badgeData: { id: number; url: string }) => {
    setFieldValue('badgeData', badgeData)
    setDrawer({ badgeEditor: false, args: {} })
    setFieldValue('badgeEnabled', false)
    setTimeout(() => {
      setFieldValue('badgeEnabled', true)
    }, 500)
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (introVideo) {
      handleGenerateUploadLink()
    }
  }, [introVideo])

  const finishCrop = (field: string, croppedFile: File) => {
    uploadImage(croppedFile, `module/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: 'png',
      }),
    )
  }

  const defaultOverview = `Knowing what you know about ${values.name}, it's your turn to become the teacher! Please create a video, no longer than 3 minutes, teaching back the key learning.`

  const renderImage = useCallback(() => {
    return (
      <Badge
        src={values.badgeData?.url || ''}
        onEditClick={handleBadgeEditorOpen}
      />
    )
  }, [values.badgeData?.url])

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    handleFileChange,
    file,
    cropperOpen,
    setCropperOpened,
    renderImage,
    handleBadgeEnabled,
    handleBadgeEditorClose,
    handleBadgeEditorOpen,
    defaultOverview,
    generatingBadge,
    handleCloseDrawer,
    t,
    imageLoading,
    defaultTips,
    drawer,
    finishCrop,
  }
}

export default useEditModuleDrawer
