import { useMutation } from '@apollo/client'

import { ADD_QUESTIONS_TO_TEST } from 'gql/questions.query'
import { GET_TEST } from 'gql/tests/tests.query'

const addQuestionsToTestAssessmentService = (testId: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addQuestionsToTestAssessmentMutation, { loading, error }] =
    useMutation(ADD_QUESTIONS_TO_TEST, {
      refetchQueries: [
        {
          query: GET_TEST,
          variables: {
            testId,
          },
        },
      ],
    })

  return {
    addQuestionsToTestAssessmentMutation,
    loading,
    error,
  }
}

export default addQuestionsToTestAssessmentService
