import { useQuery } from '@apollo/client'
import { GET_ACCREDIBLE_DATA } from 'gql/certificates/certificates.query'
import React, { useEffect } from 'react'
import { DesignerIframe, IframeContainer } from '../styled-components'
import MyLoader from 'loading/loading'

const certificateMakerUrl = 'https://embed.badge.design'

const AccredibleIframe = ({
  id,
  designId,
  closeDrawer,
}: {
  id: string
  designId: number | undefined
  closeDrawer: (badgeData: { id: number; url: string }) => void
}): React.JSX.Element => {
  const { data } = useQuery(GET_ACCREDIBLE_DATA, {
    variables: {
      designId,
    },
  })

  useEffect(() => {
    window.addEventListener('message', (e: any) => {
      console.log('Message Received.')
      if (e.origin === certificateMakerUrl && typeof e.data === 'object') {
        if (e.data.completed && e.data.design.rasterized_content_url) {
          const badgeData = {
            id: e.data.design.id,
            url:
              e.data.design.rasterized_content_url +
              '&timestamp=' +
              new Date().getTime(),
          }
          closeDrawer(badgeData)
        }
      }
    })

    return () => {
      window.removeEventListener('message', () => {})
    }
  }, [])

  const { accredibleBadgeIframeUrl } = data?.getAccredibleDataWithModuleId || {}

  return (
    <IframeContainer>
      {accredibleBadgeIframeUrl ? (
        <DesignerIframe src={accredibleBadgeIframeUrl} />
      ) : (
        <MyLoader />
      )}
    </IframeContainer>
  )
}

export default AccredibleIframe
