import React, { ReactElement, useEffect, useState } from 'react'

import { useUserValue } from 'context/UserContext'
import CompanyItem from './CompanyItem/CompanyItem'
import { Container } from './styled-components'
import { CompanyListProps } from './CompanyItem/companyItem.interface'
import { SplashScreen } from 'components/common'
import { useTranslation } from 'react-i18next'

const CompanyList = ({
  data,
  selectItem,
  selectedItem,
  actions,
}: CompanyListProps): ReactElement => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('')
  const [state] = useUserValue()
  const { t } = useTranslation()

  useEffect(() => {
    if (state) {
      setSelectedCompanyId(
        state.selectedCompany ? state.selectedCompany.id : null,
      )
    }
  }, [state])

  return (
    <>
      {data.length ? (
        <Container>
          {data.map((company) => (
            <CompanyItem
              company={company}
              key={company.id}
              selectItem={selectItem}
              selectedItem={selectedItem}
              actions={actions}
              selectedCompanyId={selectedCompanyId}
              setSelectedCompanyId={setSelectedCompanyId}
            />
          ))}
        </Container>
      ) : (
        <SplashScreen subTitle={t('companies_layout.no_company_matched')} />
      )}
    </>
  )
}

export default CompanyList
