import { useFetchGroups } from 'hooks/group/useGroups'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { columnConfig } from './gridConfig'
import MyLoader from 'loading/loading'
import GridV2 from 'components/common/GridV2'
import {
  Root,
  StyledPagination,
  StyledTableContainer,
} from './styled-components'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import PaginationV2 from 'components/common/PaginationV2'

const GroupsByUsers: React.FC<{
  groupIds: string[]
  courses: string[]
  firstName?: string
  handleTabChange?: React.Dispatch<React.SetStateAction<number>>
  setGroupsByCourse?: React.Dispatch<React.SetStateAction<any>>
}> = ({ groupIds, courses, firstName, handleTabChange, setGroupsByCourse }) => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const variables = {
    _id: {
      type: 'arrayIn',
      value: groupIds,
    },
    courses: {
      type: 'nestedArrayIn',
      value: courses,
      nestedField: 'courseId',
    },
  }

  const { groups, loading, error, refetch }: any = useFetchGroups(
    currentPage,
    perPage,
    variables,
  )

  useEffect(() => {
    groups?.data &&
      setGroupsByCourse &&
      setGroupsByCourse(groups.data.map((x: any) => x.id))
  }, [groups])

  const handlePageChange = (
    event: React.FormEvent<HTMLFormElement>,
    newPage?: number,
  ): void => {
    if (newPage !== undefined) {
      refetch({
        filter: variables,
        currentPage,
        perPage,
      })
      setCurrentPage(newPage)
    }
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      filter: {},
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
  }

  const config = columnConfig(t, handleTabChange)

  if (loading)
    return (
      <div style={{ position: 'relative', height: 400 }}>
        <MyLoader width={250} speed={0.8} top={100} />
      </div>
    )

  return (
    <Root>
      <StyledTableContainer>
        {groups && (
          <GridV2
            title={firstName && `${firstName}'s Groups`}
            data={groups.data}
            config={config}
          />
        )}
        {groups?.data?.length > 0 && (
          <StyledPagination>
            <PerPageDropdown
              value={perPage}
              onChange={(value): void =>
                handleRowsPerPageChange({
                  target: { value: value.toString() } as HTMLInputElement,
                } as React.ChangeEvent<HTMLInputElement>)
              }
              options={[10, 20, 30, 40, 50]}
            />

            <PaginationV2
              totalPages={groups.totalPages}
              currentPage={currentPage}
              handleChange={handlePageChange}
            />
          </StyledPagination>
        )}
      </StyledTableContainer>
    </Root>
  )
}

export default GroupsByUsers
