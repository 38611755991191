import React, { useCallback, useEffect, useState } from 'react'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import { actionConfig, columnConfig } from 'components/UsersLayout/gridConfig'
import { PER_PAGE } from 'hooks/users/useUsers'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'
import useUpdateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import useSendPushNotifications from 'hooks/group/useSendPushNotifications'
import { Button } from 'components/common/Button'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import { useFetchCompanyTutors } from 'hooks/users/useCompanyTutors'
import { ReactComponent as Users } from 'components/common/Button/icons/user-multiple.svg'
import AddCircleIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { User } from 'interfaces/users'
import { RolesCapitalized, roles } from 'utils/permission'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import formatRoleForTranslation from 'helpers/formatRole'
import {
  IFilterOptionsType,
  IFilterQueryType,
  ISelectedItem,
} from 'components/common/FilterInterface/filter.interface'
import { useDebouncedSearch } from 'hooks/helpers/useHelperSearch/useHelperSearch'
import {
  useSelectAllItem,
  useSelectItem,
} from 'hooks/helpers/useHelperSelect/useHelperSelect'
import { useOpenDrawer } from 'hooks/helpers/useOpenDrawer/useOpenDrawer'
import { useHelperDeleteUser } from 'hooks/helpers/useHelperDeleteUser/useHelperDeleteUser'
import { useHelperModalClose } from 'hooks/helpers/useHelperModalClose/useHelperModalClose'
import { useHelperChangeUserStatus } from 'hooks/helpers/useHelperChangeUserStatus/useHelperChangeUserStatus'
import { useHelperUsersActions } from 'hooks/helpers/useHelperUsersActions/useHelperUsersActions'

const useCoachesLayout = ({
  tutorIds,
  suadaTutorsIncluded = false,
}: {
  tutorIds?: string[]
  suadaTutorsIncluded?: boolean
}): any => {
  const ROLE = 'COACH'

  const { t } = useTranslation()
  const [state] = useUserValue()
  const companyId = state.selectedCompany?.id || null
  const filteredByCompany = !suadaTutorsIncluded ? companyId : null

  const [defaultFilter, setDefaultFilter] = useState<{}>({})

  useEffect(() => {
    if (tutorIds) {
      const filter = {
        _id: {
          type: LowerCaseFilterableFieldType.ARRAY_IN,
          value: tutorIds,
        },
      }
      setDefaultFilter((prevFilter) => ({
        ...prevFilter,
        ...filter,
      }))
    }
  }, [tutorIds])

  const { users, loading, refetch }: any = useFetchCompanyTutors(
    defaultFilter,
    1,
    PER_PAGE,
    filteredByCompany,
  )

  const { deleteUserLoading } = useUserDeleteService({
    ROLE,
    defaultFilter,
  })
  const { updateUserPassword } = useResendUserService({
    ROLE,
    defaultFilter,
  })
  const { updateUserStatusLoaing } = useUpdateStatusUserService({
    ROLE,
    defaultFilter,
  })
  const { exportData } = useExportDataService()

  const { fireSwal } = useSwal()
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const [selectAll, setSelectAll] = useState<Record<number, boolean>>({})
  const [filterDrawerOpened, setFilterDrawerOpened] = useState(false)
  const [resetVisible, setResetVisible] = useState(false)
  const [isFiltered, setIsFiltered] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [filterQuery, setFilterQuery] =
    useState<IFilterQueryType>(defaultFilter)

  const [pushNotificationModal, setPushNotificationModal] = useState(false)
  const [messageData, setMessageData] = useState<{
    heading: string
    body: string
  }>({
    heading: '',
    body: '',
  })

  const { sendPushNotifications } = useSendPushNotifications()
  const handleModalClose = useHelperModalClose({
    setPushNotificationModal,
    setMessageData,
  })

  const handlePushNotificationSend = async (): Promise<false | undefined> => {
    const ids = [...selectedItem[currentPage]]
    if (ids.length <= 0) return false
    const data = await sendPushNotifications(ids, messageData)
    if (data) handleModalClose()
  }

  const selectItem = useSelectItem({
    setSelectAll,
    setSelectedItem,
    selectedItem,
    selectAll,
    currentPage,
  })

  const selectAllItem = useSelectAllItem({
    data: users,
    selectedItem,
    selectAll,
    setSelectAll,
    setSelectedItem,
    currentPage,
  })

  const { openAddDrawer } = useOpenDrawer({
    drawerName: 'addCoach',
    fetchData: refetch,
    perPage,
    defaultFilter,
    setSelectedItem,
  })

  const handleClick = (user: User): void => {
    if (state.currentUser.role !== roles.admin) navigate(`/profile/${user.id}`)
  }

  const confirmResendPassword = (id: string): void => {
    updateUserPassword(id)
  }

  const { confirmChangeStatus, confirmChangeStatusMany } =
    useHelperChangeUserStatus({
      defaultFilter,
      ROLE,
      selectedItem,
      setSelectedItem,
      currentPage,
    })

  const { confirmDeleteMany, confirmDelete } = useHelperDeleteUser({
    ROLE,
    defaultFilter,
    refetch,
    perPage,
    currentPage,
    filteredByCompany,
    selectedItem,
    setSelectedItem,
  })

  const { handleEditClick } = useOpenDrawer({
    drawerName: 'editCoach',
    fetchData: refetch,
    perPage,
    defaultFilter,
    setSelectedItem,
  })

  const handleDeleteAllClick = (): void => {
    const roleText = 'Tutors'

    fireSwal({
      title: `Are you sure you want to delete selected ${roleText}?`,
      onConfirm: () => confirmDeleteMany(),
      confirmText: 'Yes, delete!',
    })
  }

  const handleDeleteClick = (user: User): void => {
    const params = {
      title: `${t('popups.delete_single')} ${t(
        formatRoleForTranslation(ROLE),
      )}?`,
      onConfirm: (): void => {
        confirmDelete([user.id])
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleActivityClick = (userId: string): void => {
    const url = `/profile/${userId}/activity`
    window.open(url, '_blank')
  }

  const handleResendPasswordClick = (userId: string): void => {
    fireSwal({
      title: `${t('popups.resend_password')} ${t(
        formatRoleForTranslation(ROLE),
      )}?`,
      onConfirm: () => {
        confirmResendPassword(userId)
      },
      confirmText: t('popups.confirm_resend_password'),
    })
  }

  const handleChangeStatusClick = (userId: string, status: string): void => {
    if (status === 'ACTIVE') {
      fireSwal({
        title: `${t('popups.suspend_single')} ${t(
          formatRoleForTranslation(ROLE),
        )}?`,
        onConfirm: () => {
          confirmChangeStatus(userId, 'SUSPENDED')
        },
        confirmText: t('popups.confirm_suspend'),
      })
    } else {
      fireSwal({
        title: `${t('popups.activate_single')} ${t(
          formatRoleForTranslation(ROLE),
        )}?`,
        onConfirm: () => {
          confirmChangeStatus(userId, 'ACTIVE')
        },
        confirmText: t('popups.confirm_activate'),
      })
    }
  }

  const { handleSearchChange, searchValue, isSearchType } = useDebouncedSearch({
    fetchFunction: refetch,
    defaultFilter,
    currentPage,
    perPage,
    role: ROLE,
  })

  const handleExportData = (): void => {
    if (selectedItem[currentPage].length > 0) {
      exportData(ROLE, selectedItem[currentPage], (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false)

  const label = t('user_details.tutor_details')

  const config = columnConfig(ROLE, selectItem, t, label, !!tutorIds)

  const [userId, setUserId] = useState('')
  const field = RolesCapitalized.COACH

  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    handleActivityClick,
    handleResendPasswordClick,
    handleChangeStatusClick,
    state.userPermission,
    field,
    t,
    setResetPasswordOpen,
    setUserId,
  )

  const { multiActions } = useHelperUsersActions({
    handleDeleteAllClick,
    currentPage,
    selectedItem,
    field,
    state,
    ROLE,
    defaultFilter,
    setSelectedItem,
    handleExportData,
    setPushNotificationModal,
  })
  const filter = (filters: IFilterQueryType): void => {
    refetch({
      filter: { ...filters, ...defaultFilter },
      currentPage: 1,
      perPage,
    })

    if (users) {
      setFilterDrawerOpened(false)
      setResetVisible(true)
      setFilterQuery(filters)
      setSelectedItem({})
    }

    setIsFiltered(JSON.stringify(filters) !== '{}')
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    if (newPage !== undefined) {
      refetch({
        filter: { ...filterQuery, ...defaultFilter },
        currentPage: newPage,
        perPage,
      })
      setCurrentPage(newPage)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    refetch({
      filter: { ...filterQuery, ...defaultFilter },
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }

  const [filterOptions, setFilterOptions] = useState<IFilterOptionsType | null>(
    null,
  )

  const addText = 'Add Tutor'

  const createButton = (): React.JSX.Element => (
    <Button
      text={addText}
      color="secondary"
      onClick={openAddDrawer}
      icon={<AddCircleIcon />}
      background="#06C68F"
    />
  )

  const breadCrumbData = [
    {
      label: 'Users',
      icon: <Users />,
    },
    {
      label: 'Tutors',
    },
  ]

  return {
    breadCrumbData,
    t,
    filterDrawerOpened,
    setFilterDrawerOpened,
    filter,
    loading,
    setFilterOptions,
    filterOptions,
    state,
    createButton,
    selectedItem,
    currentPage,
    multiActions,
    searchValue,
    handleSearchChange,
    deleteUserLoading,
    updateUserStatusLoaing,
    resetVisible,
    handleClick,
    config,
    users,
    actions,
    selectAllItem,
    selectItem,
    perPage,
    handleChangeRowsPerPage,
    handlePaginationClick,
    isResetPasswordOpen,
    userId,
    pushNotificationModal,
    handleModalClose,
    messageData,
    setMessageData,
    handlePushNotificationSend,
    setResetPasswordOpen,
    setIsFiltered,
    isFiltered,
    companyId,
    isSearchType,
  }
}

export default useCoachesLayout
