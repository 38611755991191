import { useQuery } from '@apollo/client'

import { GET_GROUP } from 'gql/group/group.query'

export const PER_PAGE = 10

export const useFetchGroups = (
  currentPage = 1,
  perPage: number = PER_PAGE,
  filter: any = {},
) => {
  const { data, loading, error, refetch } = useQuery(GET_GROUP, {
    variables: {
      filter: { ...filter },
      currentPage,
      perPage,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    groups: data && data.getAllGroups,
    loading,
    error: errorMessage,
    refetch,
  }
}
