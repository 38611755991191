import React, { FormEvent } from 'react'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormField,
  FormFields,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { AsyncSelect } from 'components/common/SelectField'
import IconButton from '@mui/material/IconButton'

import { generateFullName } from 'utils/utils'
import CloseIcon from '@mui/icons-material/Close'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { DataOptionFieldEnums } from '../AddGroupDrawer/addGroupDrawer.interface'
import useGroupDrawer, {
  EditGroupDrawerProps,
} from 'hooks/group/useGroupDrawers/useGroupDrawer'
import useEditGroupDrawer from 'hooks/group/useGroupDrawers/useEditGroupDrawer'
import { ISelectOption } from 'pages/customNotifications/customNotification.interface'
import MyLoader from 'loading/loading'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'

const EditGroupDrawer = ({
  id,
  companyId,
  courseData,
  onClose,
  afterSave,
}: EditGroupDrawerProps) => {
  const {
    t,
    handleSelectChange,
    courses,
    coursesLoading,
    adminData,
    adminLoading,
  } = useGroupDrawer({
    companyId,
  })

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    loading,
    handleCloseDrawer,
  } = useEditGroupDrawer({
    id,
    companyId,
    courseData,
    onClose,
    afterSave,
  })
  return (
    <>
      {loading && <MyLoader width={250} />}
      <DrawerHeader>
        <DrawerTitle>
          {t('actions.edit')} {t('general.group')}
        </DrawerTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>

      <DrawerContent headerHeight={68}>
        <FormContainer
          onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()}
        >
          <FormGroupWrapper>
            <FormFields>
              <FormField width="100%">
                <TextInput
                  title={`${t('groups_layout.group_name')}`}
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  size="small"
                  placeholder={`${t('groups_layout.group_name')}`}
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <Title>{`${t('tasks_layout.select_admin')}*`}</Title>
                <AsyncSelect
                  isMulti
                  onInputChange={(e) =>
                    handleSelectChange(DataOptionFieldEnums.ADMINS, e)
                  }
                  onChange={(e: ISelectOption) => setFieldValue('admin', e)}
                  data={(adminData && adminData.usersByRole.data) || []}
                  value={values.admin}
                  label={t('form_fields.type_to_search_admin')}
                  loading={adminLoading}
                  labelDataName={generateFullName}
                  valueDataKey="id"
                  error={!!errors.admin}
                  touched={!!touched.admin}
                  errorMessage={errors.admin ? errors.admin : ''}
                />
              </FormField>

              {!courseData && (
                <FormField>
                  <Title>{t('tasks_layout.select_courses')} </Title>
                  <AsyncSelect
                    isMulti
                    onInputChange={(e) =>
                      handleSelectChange(DataOptionFieldEnums.COURSES, e)
                    }
                    onChange={(e: ISelectOption) => setFieldValue('courses', e)}
                    data={courses?.getAllCourseOptions?.data || []}
                    value={values.courses}
                    label={t('form_fields.type_to_search_courses')}
                    loading={coursesLoading}
                    valueDataKey="id"
                    labelDataKey="name"
                    error={!!errors.courses}
                    touched={!!touched.courses}
                    errorMessage={errors.courses || ''}
                  />
                </FormField>
              )}
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size="small"
                  placeholder={`${t('form_fields.location')}`}
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('user_details.note')}`}
                  placeholder={`${t('user_details.note')}`}
                  size="small"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              <FormField width="100%">
                <CustomSwitcher
                  onChange={(e) => {
                    setFieldValue('isNewsFeedAvailable', e.target.checked)
                  }}
                  label="Is News Feed Available for this Group?"
                  name="isNewsFeedAvailable"
                  value={values.isNewsFeedAvailable}
                />
              </FormField>
            </FormFields>
            {/* <Drawer
              opened={drawer.addAdmin}
              toggleDrawer={handleInnerDrawerClose}
              totalWidth="600px"
            >
              <AddAdmin
                drawerData={drawer.args}
                onClose={(): void => setDrawer({ addAdmin: false, args: {} })}
              />
            </Drawer> */}
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                color="secondary"
                type="small"
                onClick={handleSubmit}
                isDisabled={loading}
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>

      {/* <Drawer
        opened={drawer.addAdmin}
        toggleDrawer={() => setDrawer({ addAdmin: false, args: {} })}
        totalWidth="600px"
      >
        <AddAdmin
          drawerData={{ ...drawer.args, companyId: props?.data?.companyId }}
          onClose={() => setDrawer({ addAdmin: false, args: {} })}
        />
      </Drawer> */}
    </>
  )
}

export default EditGroupDrawer
