import React, { ReactElement } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Modal, ImageCropper, TextInput, Button } from 'components/common'
import { FormField, FormFields } from 'components/common/Form'
import { useBookDrawer } from 'hooks/books'
import { FormikFieldChange, Props } from './AddFileDrawer.interface'
import {
  Container,
  DrawerContent,
  DrawerHeader,
  ErrorSpan,
  FormButtons,
  FormFooter,
} from './AddFileDrawer.styles'
import { FormTitle } from 'pages/users/styled-components'
import { IconButton } from '@mui/material'
import Uploader from 'components/common/FileUploaderV2'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import { StyledUploadLabel } from 'pages/tests/editTestDrawer/styled-components'
import useFileDrawer from 'hooks/assistant/useFileDrawer'

const AddFileDrawer = ({ onClose, assistantId }: Props): ReactElement => {
  const {
    t,
    setFieldValue,
    values,
    errors,
    touched,
    handleChange,
    handleCloseDrawer,
    handleSubmit,
    handleFileChange,
    fileLoading,
    loading,
  } = useFileDrawer({
    onClose,
    assistantId,
  })

  return (
    <Container>
      <DrawerHeader>
        <FormTitle> {t('AI_assistant.add_file')} </FormTitle>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormFields>
          <FormField>
            <TextInput
              title={`${t('AI_assistant.description')} *`}
              placeholder={t('AI_assistant.type_description')}
              size="small"
              name="description"
              type="text"
              fullWidth={true}
              onChange={handleChange}
              multiline
              rows="8"
            />
          </FormField>
          <FormField>
            <Uploader
              id="contentDocument"
              type={FileTypes.DOCUMENT}
              label={
                <>
                  <StyledUploadLabel>
                    {t('actions.upload_file')}*
                  </StyledUploadLabel>
                </>
              }
              deleteFile={(): FormikFieldChange =>
                setFieldValue('contentDocument', null)
              }
              accept=".txt,.md"
              placeHolder={t('AI_assistant.supported_file_formats')}
              inputValue={
                values.contentDocument ? values.contentDocument.name : ''
              }
              onFileChange={(e): void => handleFileChange(e)}
              uploadedFile={values.contentDocument}
              loading={!!fileLoading}
            />
            {touched.contentDocument && errors.contentDocument && (
              <ErrorSpan>{errors.contentDocument}</ErrorSpan>
            )}
          </FormField>
        </FormFields>
      </DrawerContent>
      <FormFooter>
        <FormButtons>
          <Button
            text={t('actions.save')}
            type="small"
            onClick={handleSubmit}
            background="#06C68F"
            loading={loading}
          />
          <Button
            text={t('actions.cancel')}
            type="small"
            onClick={handleCloseDrawer}
            background="#E0E1E2"
            textColor="#414141"
          />
        </FormButtons>
      </FormFooter>
    </Container>
  )
}

export default AddFileDrawer
