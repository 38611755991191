import { useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import { GET_MODULE } from 'gql/modules.query'
import { GET_TOPIC, EDIT_TOPIC, CHANGE_TOPICS_ORDER } from 'gql/topics.query'
import { CHANGE_LESSONS_ORDER } from 'gql/lesson/lesson.query'

export const editTopicService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editTopicMutation, { loading, error }] = useMutation(EDIT_TOPIC, {
    update(cache, { data: { editTopic: document } }) {
      updateCache({
        objectName: 'topic',
        query: GET_TOPIC,
        cache,
        document,
        action: 'update',
        params,
      })
    },
  })

  return {
    editTopicMutation,
    loading,
    error,
  }
}

export const changeTopicsOrderService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [changeTopicsOrderMutation, { loading, error }] = useMutation(
    CHANGE_TOPICS_ORDER,
    {
      update(cache, { data: { addTopic: document } }) {
        updateCache({
          objectName: 'getModule',
          query: GET_MODULE,
          cache,
          document,
          action: 'update',
          params,
        })
      },
    },
  )

  return {
    changeTopicsOrderMutation,
    loading,
    error,
  }
}
export const changeLessonOrderService = (params: any) => {
  const [changeLessonOrderMutation, { loading, error }] = useMutation(
    CHANGE_LESSONS_ORDER,
    {
      update(cache, { data: { changeLessonOrder: document } }) {
        updateCache({
          objectName: 'lesson',
          query: GET_TOPIC,
          cache,
          document,
          action: 'update',
          params,
        })
      },
    },
  )
  return {
    changeLessonOrderMutation,
    loading,
    error,
  }
}
