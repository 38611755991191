import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { Login } from 'interfaces/auth'
import { useUserValue } from 'context/UserContext'
import { setToken } from 'helpers/localStorage'
import { useNavigate } from 'react-router-dom'
import { LOGIN } from 'gql/auth/login.query'

const useAuth = () => {
  const [state, dispatch] = useUserValue()
  const navigate = useNavigate()
  const [authenticate] = useMutation(LOGIN)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  // const { updateUserFirstLogin } = useUpdateUserFirstLoginService()

  const login = async (loginValues: Login) => {
    try {
      setLoading(true)
      const { data, errors } = await authenticate({
        variables: { input: loginValues },
      })

      if (errors) {
        const errorMessage = errors[0].message
        setError(errorMessage)
        setTimeout(() => {
          setError('')
        }, 3000)
        return false
      }
      const { accessToken, user } = data.authAdmin

      setToken(accessToken)
      dispatch({
        type: 'LOGIN',
      })
      navigate('/')
    } catch (err) {
      console.log('Error from login request', err)
    } finally {
      setLoading(false)
    }
  }

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
    })
    localStorage.clear()
    window.location.href = '/login'
  }

  return {
    loading,
    login,
    logout,
    error,
  }
}

export default useAuth
