import React, { useState, useEffect } from 'react'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'

import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import ReactSelect from 'react-select'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'

import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'

import { FormItemContainer, Label } from './styled-components'

const selectOptions = [
  { label: 'All', id: 1, value: 'all' },
  { label: 'Yes', id: 2, value: 'yes' },
  { label: 'No', id: 3, value: 'no' },
]

const filterOptions: any = {
  name: '',
  attachment: { label: 'All', value: 'all' },
  video: { label: 'All', value: 'all' },
  audio: { label: 'All', value: 'all' },
  contentDocument: { label: 'All', value: 'all' },
}

const FilterDrawer = (props: any) => {
  const [range, setRange] = useState([0, 300])
  const [filterData, setFilterData] = useState<any>(filterOptions)

  useEffect(() => {
    if (props.filterData) {
      const data: any = {}

      const { name, range, ...args } = props.filterData

      for (const key in args) {
        const property = args[key]

        data[key] = {
          label: args[key] ? 'Yes' : 'No',
          value: args[key] ? 'yes' : 'no',
        }
      }

      if (name) data.name = name

      if (range) setRange(range)

      setFilterData({
        ...filterData,
        ...data,
      })
    }
  }, [])

  const handleFieldChange = (field: string, action?: string) => {
    if (action && action === 'dropdown') {
      return (e: any) => {
        setFilterData({
          ...filterData,
          [field]: e,
        })
      }
    }

    return (e: any) => {
      setFilterData({
        ...filterData,
        [field]: e.target.value,
      })
    }
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault()

    const data: any = {}

    for (const key in filterData) {
      if (key !== 'name' && filterData[key].value !== 'all') {
        data[key] = filterData[key].value === 'yes' ? true : false
      }

      if (key === 'name' && filterData[key]) {
        data[key] = filterData[key]
      }
    }

    if (range[0] !== 0 || range[1] !== 300) {
      data.range = range
    }

    props.setFilterData(data)
    props.onClose()
  }

  const { t } = props

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('general.advanced_filters')}</DrawerTitle>
        <IconButton onClick={props.onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <br />

      <DrawerContent>
        <FormContainer onSubmit={onFormSubmit}>
          <FormGroupWrapper>
            <FormGroupItem>
              <Input
                label={t('form_fields.name')}
                name="name"
                size="small"
                type="text"
                fullWidth
                value={filterData.name}
                onChange={handleFieldChange('name')}
              />
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.video')}</Label>
                <ReactSelect
                  onChange={handleFieldChange('video', 'dropdown')}
                  options={selectOptions}
                  value={filterData.video}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.audio')}</Label>
                <ReactSelect
                  onChange={handleFieldChange('audio', 'dropdown')}
                  options={selectOptions}
                  value={filterData.audio}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.content')}</Label>
                <ReactSelect
                  onChange={handleFieldChange('contentDocument', 'dropdown')}
                  options={selectOptions}
                  value={filterData.contentDocument}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.attachment')}</Label>
                <ReactSelect
                  onChange={handleFieldChange('attachment', 'dropdown')}
                  options={selectOptions}
                  value={filterData.attachment}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <Typography id="range-slider" gutterBottom>
                {t('general.duration')}
              </Typography>
              <Slider
                value={range}
                onChange={(e: any, i: any) => setRange(i)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={300}
              />
            </FormGroupItem>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={() => {
                  props.setFilterData({})
                  props.onClose()
                }}
                background="#E0E1E2"
                textColor="#414141"
              />
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
