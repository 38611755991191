import React from 'react'
import { Action } from './styled-components'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import { TFunction } from 'interfaces/TFunction'

export const actionConfig = (
  t: TFunction,
  handleAddLessonClick?: any,
  handleEditClick?: any,
  handleDeleteClick?: any,
  handleDownloadClick?: any,
  permissions?: any,
) => {
  const action = [
    {
      hide: !permissions.createLesson,
      render: (item: any) => (
        <Action onClick={() => handleAddLessonClick(item.id, item.name)}>
          <AddCircleIcon />
          <span>{t('actions.add_lesson')}</span>
        </Action>
      ),
    },
    // {
    //   hide: !permissions.editTopic,
    //   render: (item: any) => (
    //     <Action onClick={() => handleEditClick(item.id)}>
    //       <EditRoundedIcon />
    //       <span>Edit</span>
    //     </Action>
    //   ),
    // },
    {
      hide: !permissions.deleteTopic,
      render: (item: any) => (
        <Action onClick={() => handleDeleteClick(item.id)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action
          onClick={() => handleDownloadClick(item.attachment.link)}
          className={item.attachment ? '' : 'disabled'}
        >
          <CloudDownloadIcon />
          <span>{t('actions.download_attachment')}</span>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}
