import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useFormik } from 'formik'
import AppBar from '@mui/material/AppBar'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Button } from 'components/common/Button'
import {
  activeTabClassName,
  TabNavigation,
  TabPanel,
} from 'components/common/Tabs/Tabs'
import { useUserValue } from 'context/UserContext'
import { CHECK_USER_EMAIL } from 'gql/users.query'
import { useMutation } from '@apollo/client'
import { UserSchema } from 'helpers/validationSchemas'
import useCompaniesService from 'hooks/useCompaniesService'
import useAddUserServie from 'hooks/users/useAddUserService'
import { useFetchUsers } from 'hooks/users/useUsers'

import {
  ButtonWrapper,
  Container,
  FooterWrapper,
  Wrapper,
} from '../styled-components'
import AdminList from './AdminList'
import CreateAdmin from './CreateAdmin'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const formData: any = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  gender: null,
  birthDate: '',
  note: '',
  role: 'ADMIN',
  avatar: '',
  jobTitle: '',
  location: '',
  phoneFields: null,
  phoneFieldValue: null,
}

const GroupAdmins = (props: any) => {
  const [state] = useUserValue()
  const [createAdmin, setCreateAdmin] = useState('')
  const [tabValue, setTabValue] = useState(0)
  const navigate = useNavigate()
  const { setCompanyState } = useCompaniesService()

  const { companyData, setData } = props
  const [checkEmail] = useMutation(CHECK_USER_EMAIL)
  const { addUser, addUserLoading } = useAddUserServie({
    role: 'ADMIN',
    filter: {},
  })

  const { users, loading, refetch }: any = useFetchUsers('ADMIN', {
    companyId: { type: 'exact', value: companyData.companyData.id },
  })

  useEffect(() => {
    if (users && users.data) {
      setData((prev: any) => ({
        ...prev,
        admins: users.data,
      }))
    }
  }, [users])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: formData,
    validationSchema: UserSchema,
    async onSubmit(values) {
      if (addUserLoading) return

      const data = { ...values }

      delete data.phoneFieldValue

      if (data.gender) data.gender = data.gender.value

      data.group = []

      if (data.phoneFields && data.phoneFields.label) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null
      }

      data.requestPasswordChange = state.currentUser.settings.askPassword

      const res = await checkEmail({
        variables: { email: values.email },
      })

      if (res.errors) {
        errors.email = res.errors[0].message
        return false
      }

      addUser(data, 'ADMIN', companyData.companyData.id, () => {
        refetch()
        if (createAdmin === 'reset') {
          resetForm()
        } else {
          resetForm()
          setTabValue(1)
        }
      })
    },
  })

  const onFormSubmit = (action: string) => {
    setCreateAdmin(action)
    handleSubmit()
  }

  const { t } = props

  return (
    <>
      <Container>
        <Wrapper width="74%">
          <ContentWrapper>
            <TabNavigation withBorder={true}>
              <AppBar position="static" style={styles.bar}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label={t('company_wizard_layout.create_new_admin')}
                    {...activeTabClassName(0)}
                    style={styles.btn}
                  />
                  <Tab
                    label={t('company_wizard_layout.admin_list')}
                    {...activeTabClassName(1)}
                    style={styles.btn}
                  />
                </Tabs>
              </AppBar>

              <TabPanel value={tabValue} index={0}>
                <CreateAdmin
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleSubmit={onFormSubmit}
                  addUserLoading={addUserLoading}
                  t={t}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <AdminList
                  adminList={companyData.admins}
                  refetch={refetch}
                  companyId={companyData.companyData.id}
                  t={t}
                />
              </TabPanel>
            </TabNavigation>
          </ContentWrapper>
        </Wrapper>

        <Wrapper width="calc(26% - 20px)">
          <TextContainer>
            {t('company_wizard_layout.group_admins_description')}
          </TextContainer>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.cancel')}
            type="small"
            onClick={props.onCancel}
            background="#E0E1E2"
            textColor="#414141"
          />
        </ButtonWrapper>

        <ButtonWrapper>
          <div style={{ marginRight: '15px' }}>
            <Button
              text={t('actions.previous')}
              type="small"
              onClick={() => props.changeStep(2)}
              background="#E0E1E2"
              textColor="#414141"
            />
          </div>

          <div style={{ marginRight: '15px', height: '31px' }}>
            <Button
              text={t('actions.save_as_draft')}
              type="small"
              color="secondary"
              onClick={() => {
                setCompanyState(companyData.companyData.id, 3, 'Success', () =>
                  navigate('/Companies'),
                )
              }}
              textColor="#06C68F"
              background="#f5f5f5"
              outline="1px solid #06C68F"
            />
          </div>

          <Button
            text={t('actions.save_and_next')}
            type="small"
            color="secondary"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 4, 'Success', () =>
                props.changeStep(4),
              )
            }}
            background="#06C68F"
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default GroupAdmins

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 0px 50px;
`
const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`
