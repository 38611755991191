import React from 'react'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import ReactSelect from 'react-select'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'

import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { filterDefault } from 'pages/quizzes/staticData'
import {
  FormItemContainer,
  Label,
} from 'pages/courses/FilterDrawer/styled-components'
import { FilterDropdownProps, FilterTestDrawerProps } from './filter.interface'
import useFilterTestLogic from 'hooks/tests/useFilterTestLogic'

const FilterTestDrawer = (props: FilterTestDrawerProps) => {
  const {
    handleFieldChange,
    filterDataValues,
    passRate,
    tagList,
    onFormSubmit,
    t,
    setFilterData,
    onClose,
    setPassRate,
  } = useFilterTestLogic(props)

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('general.advanced_filters')}</DrawerTitle>
        <IconButton onClick={onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <br />

      <DrawerContent>
        <FormContainer onSubmit={onFormSubmit}>
          <FormGroupWrapper>
            <FormGroupItem>
              <Input
                label={t('form_fields.name')}
                name="name"
                size="small"
                type="text"
                fullWidth
                value={filterDataValues.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleFieldChange(e, 'name')
                }
              />
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>
                  {t('general.quiz')} {t('quiz_details.participation')}
                  {t('general.type')}
                </Label>
                <ReactSelect
                  onChange={(e) =>
                    handleFieldChange(
                      e as FilterDropdownProps,
                      'quizParticipationType',
                      'dropdown',
                    )
                  }
                  options={filterDefault.quizParticipationType}
                  value={filterDataValues.quizParticipationType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('quiz_details.time_type')}</Label>
                <ReactSelect
                  onChange={(e) =>
                    handleFieldChange(
                      e as FilterDropdownProps,
                      'timeType',
                      'dropdown',
                    )
                  }
                  options={filterDefault.timeType}
                  value={filterDataValues.timeType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.tags')}</Label>
                <ReactSelect
                  isMulti
                  onChange={(e) =>
                    handleFieldChange(
                      e as FilterDropdownProps,
                      'tags',
                      'dropdown',
                    )
                  }
                  options={tagList}
                  value={filterDataValues.tags}
                />
              </FormItemContainer>
            </FormGroupItem>
            <FormGroupItem>
              <Typography id="range-slider" gutterBottom>
                {t('quiz_details.pass_rate')}
              </Typography>
              <Slider
                value={passRate}
                onChange={(e: any, i: any) => setPassRate(i)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={100}
              />
            </FormGroupItem>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={() => {
                  setFilterData({
                    filterOptions: null,
                    values: null,
                  })
                  onClose()
                }}
                background="#E0E1E2"
                textColor="#414141"
              />
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterTestDrawer
