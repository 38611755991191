import { NoValueWrapper } from 'components/common/Grid/styled-components'
import React from 'react'
import { GridITemWrapper } from './styled-components'
import strCutter from 'utils/strCutter'
import { generateFullName } from 'utils/utils'
import avatarIcon from '../../AdminProfile/icons/profile-picture.svg'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { ITFunction } from 'components/common/Tfunction/Tfunction.interface'
import { IColumnConfig } from 'pages/group/groups.interface'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'

export const columnConfig = (
  t: ITFunction,
  handleTabChange?: React.Dispatch<React.SetStateAction<number>>,
) => {
  const grid: IColumnConfig[] = [
    {
      id: 'name',
      label: `${t('general.name')}`,
      render: (item) => <GridITemWrapper>{item.name}</GridITemWrapper>,
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'admin',
      numeric: true,
      disablePadding: false,
      label: `${t('general.admin')}`,
      render: (item) => {
        return (
          <div>
            {item.admin
              ? item.admin
                  .map((i: { firstName: string }) => i.firstName)
                  .join(', ')
              : 'N/A'}
          </div>
        )
      },
    },

    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item) => (
        <StatusIndicator status={item.status as GridStatusEnums} />
      ),
    },
    {
      id: 'numberOfStudents',
      numeric: true,
      disablePadding: false,
      label: `${t('general.students')}`,
      render: (item) => (
        <GridITemWrapper
          style={{ cursor: 'pointer' }}
          onClick={() => (handleTabChange ? handleTabChange(1) : {})}
        >
          {item.numberOfStudents || <NoValueWrapper>N/A</NoValueWrapper>}
        </GridITemWrapper>
      ),
    },
    {
      id: 'coach',
      numeric: true,
      disablePadding: false,
      label: `${t('general.coach')}`,
      render: (item) => {
        return (
          <>
            {item?.coach ? (
              <GridITemWrapper style={{ cursor: 'pointer' }}>
                <img src={item?.coach?.avatar || avatarIcon} />
                {item?.coach?.name}
              </GridITemWrapper>
            ) : (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
          </>
        )
      },
    },
  ]

  return grid.filter((i: any) => !i.hide)
}
