import React, { useState } from 'react'
import { StyledInput } from './styled-components'
import Grid from 'components/common/Grid'
import { columnConfig } from 'pages/group/ImportStudents/gridConfig'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import GetAppIcon from '@mui/icons-material/GetApp'
import PublishIcon from '@mui/icons-material/Publish'
import { Button } from 'components/common/Button'
import { SelectField } from 'components/common/SelectField'
import { useTranslation } from 'react-i18next'

import useUploadFileStream from 'hooks/group/useUploadFileStream'
import useImportStudents from 'hooks/users/useImportStudents'

const ImportStudents = ({
  groupList,
  companyId,
  refetch,
  setTabValue,
}: any) => {
  const { t } = useTranslation()

  const [imported, setImported] = useState(false)
  const [importedFromFile, setImportedFromFile] = useState([])
  const [selectGroups, setSelectGroups] = useState<any>(null)

  const { uploadFileStream } = useUploadFileStream()
  const { importStudents } = useImportStudents()

  const studentList: any = []

  const handleUploadFile = (file: any) => {
    if (file) {
      uploadFileStream(file, (data: any) => {
        // console.log('file::', data)
        // setImportedFromFile(data)
        const userList =
          data && data.map(({ __typename, id, ...args }: any) => ({ ...args }))

        importStudents(
          userList,
          selectGroups.value,
          selectGroups.label,
          companyId,
          (data?: any) => {
            setImportedFromFile(data)
            setImported(true)
            refetch()
          },
        )
      })
    }
  }

  const handleFileChange = (e: any) => {
    const files: any = Array.from(e.target.files)
    handleUploadFile(files[0])
    setImported(false)
  }

  const config = columnConfig(() => {}, imported, t, true)
  const groups = groupList.map((i: any) => ({
    label: i.name,
    value: i.id,
    companyId,
  }))

  return (
    <div>
      <SelectField
        // error={!!errors.admin}
        // errorMessage={errors.admin}
        // touched={touched.admin}
        placeholder={`${t('groups_layout.select_groups')}*`}
        options={groups}
        value={selectGroups}
        onChange={(e: any) => setSelectGroups(e)}
        isClearable
      />

      <Toolbar styles={{ justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ToolbarItem>
            <Button
              text={t('actions.download_template')}
              color="primary"
              type="small"
              icon={<GetAppIcon fontSize="small" />}
              onClick={() =>
                (window.location.href =
                  'https://ib-assets-public.s3.eu-west-2.amazonaws.com/Template.xlsx')
              }
              background="#06C68F"
            />
          </ToolbarItem>

          <ToolbarItem style={{ marginLeft: '10px' }}>
            <Button
              text={t('actions.upload_file')}
              color="primary"
              type="small"
              icon={<PublishIcon fontSize="small" />}
              isDisabled={!selectGroups}
              component={
                <StyledInput type="file" onChange={handleFileChange} value="" />
              }
              background="#06C68F"
            />
          </ToolbarItem>
        </div>
      </Toolbar>

      {imported && importedFromFile.find((i: any) => i.error) && (
        <div
          style={{ fontSize: '13px', color: '#f44336', padding: '0px 0 20px' }}
        >
          A row that is indicated in red contains incorrect information. Please,
          correct them in an excel file and upload them again.
        </div>
      )}

      <div style={{ maxHeight: '600px', overflow: 'scroll' }}>
        <Grid
          data={importedFromFile}
          config={config}
          disabledSelect
          error={
            imported
              ? {
                  error: 'error',
                  errorColor: 'rgba(255, 0, 0, 0.13)',
                  successColor: 'rgba(0, 128, 0, 0.13)',
                }
              : false
          }
        />
      </div>

      {imported && importedFromFile.find((i: any) => !i.error) && (
        <div
          style={{
            marginTop: '15px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            text="Review"
            type="small"
            background="#06c68f"
            onClick={() => setTabValue(2)}
          />
        </div>
      )}
    </div>
  )
}

export default ImportStudents
