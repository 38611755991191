import React from 'react'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { Action } from './styled-components'

import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import ViewCompactIcon from '@mui/icons-material/ViewCompact'
import { TFunction } from 'interfaces/TFunction'

interface Props {
  handleClick?: () => string | object
}

interface itemProps {
  name: string
  video: string
  status: string
  isChecked?: boolean
  id: string
  level?: string
  coursePrivacy: string
  coachType: any
  coach: any
  duration: string
  studentsEnrolled: string
  rating: string
  numberOfGroups: string
  numberOfStudents: string
}

export const actionConfig = (
  actionHandler: any,
  permissions: any,
  canModify: boolean,
  t: TFunction,
) => {
  const actions = [
    {
      hide: !permissions.viewModule,
      render: (item: any) => (
        <Action onClick={() => actionHandler('view', item.id)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.editModule || canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('edit', item.id)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.editCourse || canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.remove_from_course')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action
          onClick={() => actionHandler('pdf', item)}
          className={item.attachment ? '' : 'disabled'}
        >
          <CloudDownloadIcon />
          <span>{t('actions.download_attachment')}</span>
        </Action>
      ),
    },
  ]
  return actions.filter((i: any) => !i.hide)
}

export const moduleActionsConfig = (
  t: TFunction,
  handleCreateModuleClick?: any,
  handleAddModuleClick?: any,
) => {
  const action = [
    {
      render: (item: any) => (
        <Action
          onClick={() =>
            handleCreateModuleClick(item.id, item.name, item.companyId)
          }
        >
          <AddCircleRoundedIcon />
          <span>{t('modules_layout.create_module')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action
          onClick={() =>
            handleAddModuleClick(item.id, item.name, item.companyId)
          }
        >
          <PlaylistAddIcon />
          <span>{t('course_details.add_module_to_the_course')}</span>
        </Action>
      ),
    },
  ]
  return action
}
