import { DrawerHeader } from 'components/DrawerLayout/styled-components'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { FormTitle } from 'pages/users/styled-components'
import React from 'react'
import { ReactComponent as AttachmentIcon } from 'assets/document-attachment.svg'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import TaskReplies from './TaskReplies'
import AvatarIcon from 'assets/profile.svg'
import {
  AttachmentContainer,
  StyledCommentContainer,
  StyledCommentsContainer,
  StyledRoleContainer,
  StyledUserContainer,
  StyledUserDetailsContainer,
  styles,
  FormFooter,
  StyledDrawerContainer,
  Container,
  TextWithLine,
  FormButtons,
} from './styled-component'
import { Link } from 'react-router-dom'
import { Button } from 'components/common/Button'
import { ITaskComment, ITaskCommentsDrawer } from './TaskComments.interface'
import MyLoader from 'loading/loading'
import { IAttachment } from 'interfaces/common'

const TaskCommentsDrawer = ({
  setDrawerOpened,
  data,
  taskCommentsLoading,
}: ITaskCommentsDrawer): JSX.Element => {
  const { t } = useTranslation()

  const commentsData = data?.data

  const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  const mappedCommentsData = commentsData?.map((comment: ITaskComment) => {
    const { author, createDate, description, attachments } = comment
    const { firstName, lastName, avatar, role } = author

    return (
      <StyledCommentsContainer key={comment.id}>
        <TextWithLine>
          <span style={styles.data}>
            {moment(createDate).format('dddd, MMMM, Do')}
          </span>
        </TextWithLine>
        <StyledUserContainer>
          <StyledUserDetailsContainer>
            {avatar ? (
              <img style={styles.avatar} src={avatar} alt="avatarIcon" />
            ) : (
              <img style={styles.avatar} src={AvatarIcon} alt="avatarIcon" />
            )}

            <span style={styles.name}>
              {firstName} {lastName}
            </span>
            <StyledRoleContainer>
              {capitalizeFirstLetter(role)}
            </StyledRoleContainer>
          </StyledUserDetailsContainer>{' '}
          <span style={styles.time}>{moment(createDate).format('HH:mm')}</span>
        </StyledUserContainer>
        <StyledCommentContainer>{description} </StyledCommentContainer>

        <>
          {attachments.map((attachment: IAttachment) => {
            return (
              <AttachmentContainer key={attachment._id}>
                <AttachmentIcon style={styles.fileIcon} />
                <Link
                  to={attachment.link}
                  style={styles.fileTitle}
                  target="_blank"
                  rel="noreferrer"
                >
                  {attachment.name}
                </Link>
              </AttachmentContainer>
            )
          })}
        </>

        <TaskReplies taskId={comment.taskId} id={comment.id} />
      </StyledCommentsContainer>
    )
  })

  return (
    <>
      {taskCommentsLoading && <MyLoader />}
      <Container>
        <DrawerHeader>
          <FormTitle>{t('tasks_layout.all_activity')}</FormTitle>
          <IconButton onClick={(): void => setDrawerOpened(false)}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>

        <StyledDrawerContainer>{mappedCommentsData}</StyledDrawerContainer>

        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.close')}
              type="small"
              onClick={(): void => setDrawerOpened(false)}
              background="#06C68F"
            ></Button>
          </FormButtons>
        </FormFooter>
      </Container>
    </>
  )
}

export default TaskCommentsDrawer
