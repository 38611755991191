import * as Yup from 'yup'
import i18n from 'i18n/index'

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export let UserSchema = {}

export let AdminSchema = {}

export let UserLongSchema = {}

export let StudentSchema = {}

export let CoachSchema = {}

export let LoginSchema = Yup.object({
  email: Yup.string()
    .email(i18n.t('validations.email_invalid'))
    .required(i18n.t('test')),
  password: Yup.string()
    .min(2, 'Minimum Characters - 2')
    .required('Password is Required'),
})

export const ChangePasswordSchema = Yup.object({
  email: Yup.string().email('Email is invalid').required('Email is Required'),
  password: Yup.string()
    .min(2, 'Minimum Characters - 2')
    .required('Password is Required'),
  repeatPassword: Yup.string()
    .min(2, 'Minimum Characters - 2')
    .required('Password is Required'),
  oldPassword: Yup.string()
    .min(2, 'Minimum Characters - 2')
    .required('Password is Required'),
})

export let CompaniesWizardPage1Schema = Yup.object({
  name: Yup.string().required('Company Name is Required'),
  companyEmail: Yup.string()
    .email('Email is invalid')
    .required('Email is Required'),
  keyContactName: Yup.string().required('Key Contact Name is Required'),
  phoneFields: Yup.object()
    .nullable()
    .when('phone', {
      is: (value: any) => !!value,
      then: Yup.object().required('Dial Code is Required').nullable(),
    }),
  phoneFieldValue: Yup.string().nullable(), // for phone validation
  phone: Yup.string()
    .matches(/[^A-Z]/gi, 'Phone is invalid')
    .nullable()
    .when('phoneFieldValue', {
      is: (value: any) => !!value,
      then: Yup.string().required('Phone is Required').nullable(),
    }),
})

export const CompaniesWizardPage2Schema = Yup.object({
  email: Yup.string().email('Email is invalid').required('Email is Required'),
  firstName: Yup.string().required('First Name is Required'),
  lastName: Yup.string().required('Last Name is Required'),
  phoneFields: Yup.object()
    .nullable()
    .when('phone', {
      is: (value: any) => !!value,
      then: Yup.object().required('Dial Code is Required').nullable(),
    }),
  phoneFieldValue: Yup.string().nullable(), // for phone validation
  phone: Yup.string()
    .matches(/[^A-Z]/gi, 'Phone is invalid')
    .nullable()
    .when('phoneFieldValue', {
      is: (value: any) => !!value,
      then: Yup.string().required('Phone is Required').nullable(),
    }),
})

export const CompaniesWizardManagersSchema = Yup.object().shape({
  data: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string()
        .email('Email is invalid')
        .required('Email is required'),
      // status: Yup.string(),
    }),
  ),
})

export const FilterUserSchema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string(),
  phone: Yup.string().matches(/[^A-Z]/gi, 'Phone is invalid'),
})

export const FilterCoachesSchema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string(),
  phone: Yup.string().matches(/[^A-Z]/gi, 'Phone is invalid'),
  gender: Yup.string(),
})

export const FilterStudentsSchema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string(),
  phone: Yup.string().matches(/[^A-Z]/gi, 'Phone is invalid'),
  gender: Yup.string(),
})

export const FilterCompanySchema = Yup.object({
  name: Yup.string(),
  companyEmail: Yup.string(),
  address: Yup.string(),
  phone: Yup.string().matches(/[^A-Z]/gi, 'Phone is invalid'),
  companyActivity: Yup.string(),
})

export let CourseSchema = {}

export const EditCourseSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  level: Yup.string(),
  coursePrivacy: Yup.string(),
})
export let EmailSchema = {}
export let ModuleSchema = {}
export let QuestionSchema = {}
export let QuizSchema = {}
export let IQTestSchema = {}
export let LessonSchema = {}
export let GroupSchema = {}
export let TestSchema = {}
export let BookSchema = {}

// notificationSchema
export const CustomNotificationSchema = Yup.object({
  subject: Yup.string().required('Subject is required'),
  body: Yup.string().required('Body is required'),
  companies: Yup.array().when('sendToAllCompanies', {
    is: false,
    then: Yup.array().required('Companies must be included'),
    otherwise: Yup.array(),
  }),
  roles: Yup.array().when('sendToAllRoles', {
    is: false,
    then: Yup.array().required('Roles must be included'),
    otherwise: Yup.array(),
  }),
})

// Topic Scheemas
export const AddTopicSchema = Yup.object({
  name: Yup.string().required('Name is required'),
})

export const EditTopicSchema = Yup.object({
  name: Yup.string().required('Name is required'),
})

export const FlashCardSchema = Yup.object({
  question: Yup.string()
    .required('Question is required')
    .max(50, 'Question Must be maximum 50 Characters'),
  answer: Yup.string()
    .required('Answer is required')
    .max(250, 'Question Must be minimum 250 Characters'),
  image: Yup.string().required('Cover Image is required'),
  color: Yup.string().required('Color is required'),
})

export const SkillTestVideoSchema = Yup.object({
  title: Yup.string()
    .required('Title is required')
    .max(50, 'Title Must be maximum 50 Characters'),
})

export const FileSchema = Yup.object({
  description: Yup.string().required('Description is required'),
  contentDocument: Yup.object()
    .required(
      `${i18n.t('general.attachment')} ${i18n.t('validations.required')}`,
    )
    .nullable(),
})

function translate() {
  EmailSchema = Yup.object({
    email: Yup.string()
      .email(`${i18n.t('form_fields.email')} ${i18n.t(`validations.invalid`)}`)
      .required(
        `${i18n.t('form_fields.email')} ${i18n.t(`validations.required`)}`,
      ),
  })

  LoginSchema = Yup.object({
    email: Yup.string()
      .email(`${i18n.t('form_fields.email')} ${i18n.t(`validations.invalid`)}`)
      .required(
        `${i18n.t('form_fields.email')} ${i18n.t(`validations.required`)}`,
      ),
    password: Yup.string().required(
      `${i18n.t('form_fields.password')} ${i18n.t(`validations.required`)}`,
    ),
  })

  UserSchema = Yup.object({
    firstName: Yup.string().required(
      `${i18n.t('user_details.first_name')} ${i18n.t(`validations.required`)}`,
    ),
    lastName: Yup.string().required(
      `${i18n.t('user_details.last_name')} ${i18n.t(`validations.required`)}`,
    ),
    email: Yup.string()
      .email(`${i18n.t('form_fields.email')} ${i18n.t(`validations.invalid`)}`)
      .required(
        `${i18n.t('form_fields.email')} ${i18n.t(`validations.required`)}`,
      ),
    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => {
          return !!value
        },
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              `validations.required`,
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), // for phone validation
    phone: Yup.string()
      .matches(
        /[^A-Z]/gi,
        `${i18n.t('form_fields.phone')} ${i18n.t(`validations.invalid`)}`,
      )
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => {
          return value && value.length > 1
        },
        then: Yup.string()
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.required`)}`,
          )
          .nullable(),
      }),
    birthDate: Yup.date()
      .typeError(
        `${i18n.t('user_details.birthday')} ${i18n.t(`validations.invalid`)}`,
      )
      .nullable(),
  })

  CoachSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is Required'),
    lastName: Yup.string().required('Last Name is Required'),
    email: Yup.string().required('Email is Required'),
    // phone: Yup.string()
    //   // .required('Phone is Required')
    //   .matches(phoneRegExp, 'Phone is invalid'),

    // gender: Yup.string().required('Gender is Required'),
    // birthDate: Yup.date()
    //   .required('Birth date is required')
    //   .typeError(),
    // biography: Yup.string(),
    // note: Yup.string(),

    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => !!value,
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              `validations.required`,
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), // for phone validation
    phone: Yup.string()
      .matches(
        /[^A-Z]/gi,
        `${i18n.t('form_fields.phone')} ${i18n.t(`validations.invalid`)}`,
      )
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => !!value,
        then: Yup.string()
          .matches(
            /[^A-Z]/gi,
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.invalid`)}`,
          )
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.required`)}`,
          )
          .nullable(),
      }),
    birthDate: Yup.date()
      .typeError(
        `${i18n.t('user_details.birthday')} ${i18n.t(`validations.invalid`)}`,
      )
      .nullable(),
  })

  StudentSchema = Yup.object({
    firstName: Yup.string().required(
      `${i18n.t('user_details.first_name')} ${i18n.t(`validations.required`)}`,
    ),
    lastName: Yup.string().required(
      `${i18n.t('user_details.last_name')} ${i18n.t(`validations.required`)}`,
    ),
    email: Yup.string()
      .email(`${i18n.t('form_fields.email')} ${i18n.t(`validations.invalid`)}`)
      .required(
        `${i18n.t('form_fields.email')} ${i18n.t(`validations.required`)}`,
      ),
    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => !!value,
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              `validations.required`,
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), // for phone validation
    phone: Yup.string()
      .matches(
        /[^A-Z]/gi,
        `${i18n.t('form_fields.phone')} ${i18n.t('validations.invalid')}`,
      )
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => !!value,
        then: Yup.string()
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.required`)}`,
          )
          .nullable(),
      }),
    group: Yup.array()
      .required(`${i18n.t('general.group')} ${i18n.t(`validations.required`)}`)
      .nullable(),
    birthDate: Yup.date()
      .typeError(
        `${i18n.t('user_details.birthday')} ${i18n.t(`validations.invalid`)}`,
      )
      .nullable(),
  })
  CourseSchema = {
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    avatar: Yup.object()
      .required(
        `${i18n.t('general.cover_image')} ${i18n.t('validations.required')}`,
      )
      .nullable(),
  }

  TestSchema = Yup.object({
    name: Yup.string()
      .required(`${i18n.t('general.name')} ${i18n.t('validations.required')}`)
      .nullable(),
    attachment: Yup.object()
      .required(
        `${i18n.t('general.cover_image')} ${i18n.t('validations.required')}`,
      )
      .nullable(),
  })

  BookSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    contentDocument: Yup.object()
      .required(
        `${i18n.t('general.attachment')} ${i18n.t('validations.required')}`,
      )
      .nullable(),
    coverImage: Yup.mixed().notRequired(),

    // coverImage: Yup.object()
    //   .required(
    //     `${i18n.t('general.cover_image')} ${i18n.t('validations.required')}`,
    //   )
    //   .nullable(),
  })

  ModuleSchema = Yup.object({
    name: Yup.string()
      .required(`${i18n.t('general.name')} ${i18n.t('validations.required')}`)
      .nullable(),
    coverImage: Yup.object()
      .required(
        `${i18n.t('general.cover_image')} ${i18n.t('validations.required')}`,
      )
      .nullable(),
  })

  QuestionSchema = Yup.object({
    question: Yup.string().required(
      `${i18n.t('general.question')} ${i18n.t('validations.required')}`,
    ),
  })

  QuizSchema = {
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    quizParticipationType: Yup.string().required(
      `${i18n.t('general.quiz')} ${i18n.t(
        'quiz_details.participation',
      )} ${i18n.t('general.type')} ${i18n.t('validations.required')}`,
    ),
  }

  IQTestSchema = {
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    quizParticipationType: Yup.string().required(
      `${i18n.t('general.iq_test')} ${i18n.t(
        'quiz_details.participation',
      )} ${i18n.t('general.type')} ${i18n.t('validations.required')}`,
    ),
  }

  LessonSchema = {
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    topic: Yup.object()
      .nullable()
      .shape({
        value: Yup.string().required(),
      })
      .required(`${i18n.t('general.topic')} ${i18n.t('validations.required')}`),
  }

  AdminSchema = Yup.object({
    firstName: Yup.string().required('Firstname is Required'),
    lastName: Yup.string().required('Lastname is Required'),
    email: Yup.string().email('Email is invalid').required('Email is Required'),
    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => !!value,
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              `validations.required`,
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), // for phone validation
    phone: Yup.string()
      .matches(/[^A-Z]/gi, 'Phone is invalid')
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => !!value,
        then: Yup.string()
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.required`)}`,
          )
          .nullable(),
      }),
    // group: Yup.string().required('You have to select group')
  })

  UserLongSchema = Yup.object({
    firstName: Yup.string().required('First Name is Required'),
    lastName: Yup.string().required('Last Name is Required'),
    email: Yup.string().email('invalid email').required('Email is Required'),
    biography: Yup.string().nullable(),
    note: Yup.string().nullable(),
    group: Yup.array().required('Group is Required').nullable(),
    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => !!value,
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              `validations.required`,
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), // for phone validation
    phone: Yup.string()
      .matches(/[^A-Z]/gi, 'Phone is invalid')
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => !!value,
        then: Yup.string()
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.required`)}`,
          )
          .nullable(),
      }),
  })

  CompaniesWizardPage1Schema = Yup.object({
    name: Yup.string().required(
      `${i18n.t('company_details.company_name')} ${i18n.t(
        'validations.required',
      )}`,
    ),
    companyEmail: Yup.string()
      .email(`${i18n.t('user_details.email')} ${i18n.t('validations.invalid')}`)
      .required(
        `${i18n.t('user_details.email')} ${i18n.t('validations.required')}`,
      ),
    keyContactName: Yup.string().required(
      `${i18n.t('company_details.key_contact_name')} ${i18n.t(
        'validations.required',
      )}`,
    ),
    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => !!value,
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              'validations.required',
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), // for phone validation
    phone: Yup.string()
      .matches(/[^A-Z]/gi, 'Phone is invalid')
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => !!value,
        then: Yup.string()
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t('validations.required')}`,
          )
          .nullable(),
      }),
  })

  GroupSchema = Yup.object({
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    admin: Yup.array()
      .required(`${i18n.t('general.admin')} ${i18n.t('validations.required')}`)
      .nullable(),
  })
}

i18n.on('languageChanged init', () => {
  translate()
})
