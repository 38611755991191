import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const FlashCarGrid = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
`
export const FlashCardItemContainer = styled.div<{ background: string }>`
  border-radius: 20px;
  width: 100%;
  padding: 10px;
  background: ${({ background }) =>
    background ? background : 'rgba(6, 198, 143, .3)'};
`
export const FlashCardItemContent = styled.section`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
`

export const AudioIcon = styled.div`
  transform: translateY(2px);

  &.disabled {
    pointer-events: none;
  }
`
export const FlashCardItemTitle = styled.h2`
  font-size: 20px;
  max-width: 280px;
  height: 96px;
  overflow: hidden;
  font-weight: 600;
  color: '#707070';
  text-transform: uppercase;
  text-align: center;
`
export const FlashCardItemImageBox = styled.figure`
  margin: 20px 0;
  width: 130px;
  height: 130px;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #707070;

  img {
    width: 130px;
    height: 130px;
  }

  &.noImage {
    border: 1px solid #707070;
  }
`
export const FlashCardItemAnswer = styled.article`
  text-align: left;
  width: 80%;
  max-height: 120px;
  overflow-y: auto;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

// Preview

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
`

export const PreviewBox = styled.div<{ background: string }>`
  width: 350px;
  height: 380px;
  padding: 10px;
  border-radius: 20px;
  background: ${({ background }) =>
    background ? background : 'rgba(6, 198, 143, .3)'};
`

export const Content = styled.section`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const PreviewAudioIcon = styled.div`
  transform: translateY(2px);
`

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: '#707070';
  text-transform: uppercase;
  max-width: 300px;
  text-align: center;
`

export const ImageBox = styled.figure`
  margin: 20px 0;
  width: 130px;
  height: 130px;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #707070;

  img {
    width: 130px;
    height: 130px;
  }

  &.noImage {
    border: 1px solid #707070;
  }
`

export const Answer = styled.article`
  text-align: left;
  width: 80%;
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
`
