import { SvgProps } from 'interfaces/common'
import React from 'react'

const ShowPasswordIcon: React.FC<SvgProps> = ({
  className,
  onClick,
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...props}
    >
      <path
        d="M3.92999 16.8825L5.00249 15.8175C3.82815 14.7631 2.90547 13.4585 2.30249 12C3.82499 8.1975 8.02499 5.25 12 5.25C13.0229 5.2635 14.0366 5.44596 15 5.79L16.1625 4.62C14.8445 4.06299 13.4308 3.76751 12 3.75C9.55537 3.84193 7.19128 4.64817 5.19989 6.06906C3.2085 7.48996 1.67711 9.46324 0.794986 11.745C0.735412 11.9098 0.735412 12.0902 0.794986 12.255C1.46117 14.0228 2.53531 15.6083 3.92999 16.8825Z"
        fill="#020210"
      />
      <path
        d="M8.99999 11.7975C9.05214 11.0788 9.36125 10.4029 9.8708 9.89331C10.3803 9.38377 11.0563 9.07466 11.775 9.0225L13.1325 7.6575C12.3718 7.45721 11.5719 7.45981 10.8126 7.66505C10.0532 7.87028 9.3609 8.27098 8.80468 8.8272C8.24847 9.38341 7.84777 10.0757 7.64253 10.8351C7.4373 11.5944 7.4347 12.3943 7.63499 13.155L8.99999 11.7975Z"
        fill="#020210"
      />
      <path
        d="M23.205 11.745C22.345 9.5049 20.8485 7.56527 18.9 6.165L22.5 2.5575L21.4425 1.5L1.49999 21.4425L2.55749 22.5L6.38249 18.675C8.08789 19.6755 10.023 20.2181 12 20.25C14.4446 20.1581 16.8087 19.3518 18.8001 17.9309C20.7915 16.51 22.3229 14.5368 23.205 12.255C23.2646 12.0902 23.2646 11.9098 23.205 11.745ZM15 12C14.9968 12.5251 14.8559 13.0401 14.5913 13.4937C14.3267 13.9472 13.9477 14.3234 13.4922 14.5845C13.0367 14.8457 12.5206 14.9827 11.9955 14.982C11.4704 14.9812 10.9547 14.8426 10.5 14.58L14.58 10.5C14.8496 10.9543 14.9945 11.4717 15 12ZM12 18.75C10.4265 18.7225 8.88291 18.316 7.49999 17.565L9.40499 15.66C10.2715 16.2612 11.3216 16.539 12.3721 16.4449C13.4225 16.3507 14.4065 15.8906 15.1523 15.1448C15.898 14.399 16.3582 13.415 16.4523 12.3646C16.5465 11.3142 16.2687 10.264 15.6675 9.3975L17.82 7.245C19.5409 8.42624 20.8867 10.0766 21.6975 12C20.175 15.8025 15.975 18.75 12 18.75Z"
        fill="#020210"
      />
    </svg>
  )
}

export default ShowPasswordIcon
