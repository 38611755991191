import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserValue } from 'context/UserContext'
import { useLocation, useParams } from 'react-router-dom'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import { useModuleDetailService } from 'services/modules/moduleDetailService'
import { ModuleDetail } from './useModuleDetail.interface'

const useModuleDetail = (): ModuleDetail => {
  const { t } = useTranslation()
  const { id: moduleId = '' } = useParams()

  const [value, setValue] = useState(0)
  const [state, dispatch] = useUserValue()
  const [openCreateQuizDrawer, setOpenCreateQuizDrawer] =
    useState<boolean>(false)
  const { data, loading, refetch } = useModuleDetailService(moduleId || '')

  const { state: routerState } = useLocation()

  useEffect(() => {
    if (state.refetch) {
      dispatch({ type: 'REMOVE_REFETCH' })
    }
  }, [state])

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: number,
  ): void => {
    setValue(newValue)
  }

  const moduleData = (data && data.getModule) || {}

  const currentModule = moduleData && {
    moduleId: moduleData.id,
    name: moduleData.name,
    courses: moduleData.courses,
  }

  const breadCrumbData = [
    {
      label:
        (routerState?.from &&
          `${t('general.course')} ${t('general.modules')}`) ||
        t('general.modules'),
      icon: <Learning />,
      link: routerState?.from || '/modules',
      state: routerState?.state || {},
    },
    { label: currentModule && currentModule.name, link: '' },
  ]

  const canModify =
    !state.selectedCompany?.id ||
    moduleData.companyId === (state.selectedCompany?.id || null)

  return {
    breadCrumbData,
    moduleData,
    refetch,
    canModify,
    value,
    handleTabChange,
    t,
    loading,
    routerState,
    currentModule,
    setOpenCreateQuizDrawer,
    openCreateQuizDrawer,
    moduleId,
  }
}

export default useModuleDetail
