import React, { ReactElement } from 'react'
import {
  Container,
  About,
  GridItemContentHeaderStyle,
  MoreButton,
  Row,
  PaddedContainer,
  PublishedIndicator,
  GridItemContainer,
} from './styled-components'
import { Props } from './testItem.interface'
import CheckBox from 'components/common/Checkbox'
import { useNavigate } from 'react-router-dom'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { actionConfig } from '../testLayout/gridConfig'
import { DrawerEventEmitter } from 'helpers/drawer'
import { TestAssessment } from '../testLayout/testLayout.interface'
import {
  GridItemContent,
  GridItemContentHeader,
  GridItemImage,
  GridItemTitle,
} from 'components/common/Cards/styled-components'
import TooltipHeight from 'components/common/Tooltip/TooltipHeight'

const testItem = ({
  item,
  selectItem,
  isChecked,
  handleDeleteClick,
  handlePublishClick,
  isFromUser,
  setIsSearchType,
  handleClick,
}: Props): ReactElement => {
  const {
    name,
    attachment,
    questions = [],
    timeType,
    passRate = 0,
    randomization,
    published = false,
  } = item
  const navigate = useNavigate()
  const [state] = useUserValue()
  const { t } = useTranslation()
  const handleOpenEditDrawer = (id: string) => {
    DrawerEventEmitter.emit('openDrawer', 'editTest', true, {
      id,
    })
  }

  const result = randomization ? 'Yes' : 'No'

  const actionHandler = (event: string, field: TestAssessment) => {
    switch (event) {
      case 'publish':
        handlePublishClick && handlePublishClick(field)
        break
      case 'edit':
        return handleOpenEditDrawer(field.id)
        break
      case 'delete':
        handleDeleteClick && handleDeleteClick(field.id)
        break
    }
  }
  const actions = actionConfig(
    actionHandler,
    state.userPermission,
    t,
    setIsSearchType,
  )

  return (
    <Container
      onClick={() =>
        handleClick ? handleClick(item) : navigate(`/tests/${item.id}`)
      }
      isFromUser={isFromUser}
    >
      <GridItemImage>
        <img src={attachment && attachment.link} />
      </GridItemImage>
      {!isFromUser && (
        <GridItemContainer>
          <GridItemContentHeaderStyle>
            <GridItemContainer>
              <CheckBox
                onChange={() => selectItem && selectItem(item.id)}
                checked={isChecked}
              />
              <PublishedIndicator published={published}>
                {published ? 'Published' : 'Unpublished'}
              </PublishedIndicator>
            </GridItemContainer>
            <MoreButton>
              <GridActionMenu actionConfig={actions} row={item} />
            </MoreButton>
          </GridItemContentHeaderStyle>
        </GridItemContainer>
      )}
      <GridItemContent>
        <GridItemContentHeader>
          <GridItemTitle style={{ fontWeight: 600 }}>
            <TooltipHeight text={item.name} lines={2} />
          </GridItemTitle>
        </GridItemContentHeader>
      </GridItemContent>
      <PaddedContainer>
        <Row>
          <About>{`Questions: ${questions?.length}`}</About>
          <About>{`Pass rate: ${passRate}%`}</About>
        </Row>
        <Row>
          <About>{`type: ${timeType} `}</About>
          <About>{`Randomize: ${result} `}</About>
        </Row>
      </PaddedContainer>
    </Container>
  )
}

export default testItem
