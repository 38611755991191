import styled from 'styled-components'

export const Container = styled.div`
  width: 336px;
  height: 455px;
  display: grid;
  grid-template-rows: 44px auto 46px;
`

export const HeaderContainer = styled.div`
  border-bottom: solid 0.8px #e3e6eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 14px 24px;
`
export const ItemContainer = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  ${({ disabled }) => disabled && 'opacity: 0.6; pointer-events: none;'}
`

export const MarkAllAsReadContainer = styled(ItemContainer)`
  color: #06c68f;
  text-decoration: underline;
`

export const ViewAllContainer = styled(ItemContainer)`
  color: #06c68f;
`

export const ClearAllContainer = styled(ItemContainer)`
  color: rgba(0, 0, 0, 0.55);
`

export const ContentContainer = styled.div`
  overflow-y: scroll;
`

export const FooterContainer = styled.div`
  border-top: solid 0.8px #e3e6eb;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NotificationList = styled.ul`
  margin: 0;
  padding: 0;
`
export const NotificationListItem = styled.li<{ state: string }>`
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  background: ${({ state }) =>
    state === 'read' ? '#fff' : 'rgba(236,235,235,0.7)'};
  transition: 0.2s;
  &:hover {
    background: #fff;
  }
`

export const AvatarContainer = styled.div`
  img {
    border-radius: 50%;
    width: 37px;
    height: 37px;
  }
`

export const ItemListContainer = styled.div`
  width: 100%;
  height: 68px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0;
  position: relative;
`

export const ItemContent = styled.div`
  box-sizing: border-box;
  padding-left: 15px;
`
export const NameContainer = styled.div`
  color: #06c68f;
  font-size: 13px;
  margin-left: 5px;
  white-space: nowrap;
`
export const EventContainer = styled.div`
  color: #333;
  font-size: 12px;
  margin-top: 1px;
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
  }
`

export const DateContainer = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`
export const UserRole = styled.div`
  text-transform: capitalize;
`
export const NoDataWrapper = styled.div`
  padding: 10px;
  opacity: 0.6;
`
