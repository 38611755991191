import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { DrawerEventEmitter } from 'helpers/drawer'
import { PER_PAGE as perPage, useFetchCourses } from 'hooks/course/useCourses'
import useDeleteCourse from 'hooks/course/useDeleteCourse'
import { usePublishedCourse } from 'hooks/course/useEditCourse'
import { useSwal } from 'hooks/useSwal'
import { SET_CREATED_USER } from 'store/types'
import React from 'react'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import {
  ICompanyCoursesProps,
  IField,
  IUseCompanyCoursesReturn,
  IVideoData,
  IVideoModal,
} from 'pages/companies/CompanyDetails/types'
import { filterData } from 'pages/courses/CourseLayout'
import { actionConfig } from 'pages/courses/gridConfig'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { companyCoursesService } from 'services/companies/companyCourses/companyCoursesService'
import { ICourseFilter } from 'interfaces/courses/courses.interface'

const initialValues = {
  name: '',
  state: { value: 'all', label: 'All' },
  level: { value: 'all', label: 'All' },
  coaches: null,
  group: null,
  skills: null,
  video: { value: 'all', label: 'All' },
  studentRange: [0, 300],
}

const useCompanyCourses = ({
  variables,
  addCoachIds,
  defCurrentPage,
  currentTab,
  t,
}: ICompanyCoursesProps): IUseCompanyCoursesReturn => {
  const navigate = useNavigate()
  const { groupsData } = companyCoursesService({ variables })

  const { deleteCourse } = useDeleteCourse()
  const { publishedCourse } = usePublishedCourse()
  const [state] = useUserValue()
  const [, dispatch] = useData()
  const isSuperAdmin = !state.currentUser.companyId
  const { fireSwal } = useSwal()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchText, setSearchText] = useState<string>('')
  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false)
  const [filterQuery, setFilterQuery] = useState<IFilterQueryType>({})
  const defaultFilter = variables.filter
  const [filterData, setFilterData] = useState<filterData>({
    values: initialValues,
    filterOptions: {},
  })

  const {
    courses,
    loading = false,
    refetch,
  } = useFetchCourses(
    {
      ...defaultFilter,
      ...filterQuery,
      ...filterData.filterOptions,
    } as ICourseFilter,
    currentPage,
    perPage,
  )

  useEffect(() => {
    if (currentTab === 0 && courses) {
      const purchasedCoursesCoachesIds =
        courses.data &&
        courses.data.reduce(
          (acc: string[], course: { coaches: { userId: string }[] }) => {
            const coaches =
              course.coaches &&
              course.coaches.map((coach: { userId: string }) => coach.userId)
            return [...acc, ...coaches]
          },
          [],
        )
      addCoachIds(purchasedCoursesCoachesIds)
    }
  }, [courses])

  const [videoModal, setVideoModal] = useState<IVideoModal>({
    modal: false,
    data: '',
    subtitle: '',
  })
  const [addPurchasedCourse, setAddPurchasedCourse] = useState(false)
  const [courseEditMode, setCourseEditMode] = useState('')
  const [coachIds, setCoachIds] = useState<string[]>([])

  const handleViewClick = (id: string): void => navigate(`/courses/${id}`)

  const handleDeleteCourse = (ids: string[]): void => {
    if (ids.length <= 0) return
    const purchasedCoursesTab = currentTab === 0 ? true : false
    let title = ''
    if (purchasedCoursesTab && ids.length > 1) {
      title = t('popups.remove_course_many')
    }

    if (purchasedCoursesTab && ids.length === 1) {
      title = t('popups.remove_course_single')
    }

    if (!purchasedCoursesTab && ids.length > 1) {
      title = t('popups.delete_course_many')
    }

    if (!purchasedCoursesTab && ids.length === 1) {
      title = t('popups.delete_course_single')
    }
    const params = {
      title,
      onConfirm: (): void => {
        deleteCourse(ids, refetch)
      },
      confirmText: purchasedCoursesTab
        ? t('popups.confirm_remove')
        : t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handlePublishedCourse = (courseId: string[], action: string): void => {
    let title = ''
    let confirmText = ''

    if (action === 'unpublished') {
      title = `<span style="color:red">${
        courseId.length === 1
          ? 'If you unpublish this Course it will be visible for administrative Users only. Are you sure?'
          : 'If you unpublish these Courses they will be visible for administrative Users only. Are you sure?'
      }</span>`
      confirmText = 'Yes, Unpublish'
    } else {
      title = `<span style="color: green">${
        courseId.length === 1
          ? 'If you publish this Course it will be visible for non-administrative users as well. Are you sure?'
          : 'If you publish these Courses they will be visible for non-administrative Users as well. Are you sure?'
      }</span>`
      confirmText = 'Yes, Publish'
    }

    fireSwal({
      title,
      onConfirm: () => publishedCourse(courseId, action),
      confirmText,
    })
    //
  }

  const handleEditClick = (field: IField): void => {
    if (currentTab === 0) {
      setCourseEditMode(field?.originalId || field?.id)
      setAddPurchasedCourse(true)
      return
    }
    DrawerEventEmitter.emit('openDrawer', 'editCourse', true, {
      id: field.id,
      defaultStep: field.isBought ? 1 : 0,
      hasPurchasedCourses: true,
    })
  }

  const handleClosePurchasedCourseDrawer = (): void => {
    setCourseEditMode('')
    setAddPurchasedCourse(false)
  }

  const handleVideoModal = (data: IVideoData): void => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? data.video.links[0].url : '',
      subtitle: !videoModal.modal ? data.subtitle : '',
    })
  }

  const filterDrawer = (): void => {
    setFilterIsOpen(!filterIsOpen)
  }

  const handlePaginationClick = (event: any, value: number): void => {
    if (value !== courses.currentPage) {
      setCurrentPage(value)
    }
  }

  const handleCreateCourse = (): void => {
    dispatch({
      type: SET_CREATED_USER,
      payload: { obj: null, type: '' },
    })

    DrawerEventEmitter.emit('openDrawer', 'addCourses', true, {
      data: {
        companyId: variables.filter.companyId.value,
        onSuccess: () => {
          refetch(variables)
        },
      },
    })
  }

  const resetFilter = (): void => {
    setFilterQuery({})
    setFilterData({} as filterData)
    setFilterIsOpen(false)
    setSearchText('')
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const formValue = {
      name: { type: UpperCaseFilterableFieldType.MATCH, value: searchText },
    }
    setCurrentPage(1)
    setFilterQuery({
      ...filterQuery,
      ...formValue,
      ...filterData.filterOptions,
    })
    refetch({
      currentPage: 1,
      perPage,
      filter: filterQuery,
    })
  }

  const actionHandler = (
    event: string,
    field: IField | string | IVideoData | string[],
    action: string = '',
  ): void | JSX.Element => {
    switch (event) {
      case 'viewCourse':
        return handleViewClick(field as string)
      case 'deleteCourse':
        return handleDeleteCourse([field] as string[])
      case 'publishedUnPublished':
        return handlePublishedCourse([field] as string[], action)
      case 'editCourse':
        return handleEditClick(field as IField)
      case 'playIntro':
        return handleVideoModal(field as IVideoData)
      default:
        return console.error('No action found')
    }
  }

  useEffect(() => {
    if (groupsData && groupsData.getAllGroups) {
      const coachIds = groupsData.getAllGroups.data.reduce(
        (
          ids: string[],
          group: { courses: { coach: { coachId: string } }[] },
        ) => {
          const courseCoachesIds: string[] = []
          group.courses.forEach((course) => {
            if (course.coach && course.coach.coachId) {
              courseCoachesIds.push(course.coach.coachId)
            }
          })
          ids = [...courseCoachesIds, ...ids]
          return ids
        },
        [],
      )
      setCoachIds(coachIds)
    }
  }, [groupsData])

  useEffect(() => {
    setCurrentPage(defCurrentPage)
  }, [])

  const actions = actionConfig(
    actionHandler,
    state.userPermission,

    t,
  )

  const filteredCourses =
    courses?.data &&
    courses.data.filter((course: { isBought: boolean }) =>
      currentTab === 0 ? course.isBought : !course.isBought,
    )

  const boughtCourses =
    filteredCourses &&
    filteredCourses.map((i: { originalId: string }) => i.originalId)

  return {
    actions,
    state,
    isSuperAdmin,
    currentPage,
    setCurrentPage,
    searchText,
    setSearchText,
    filterIsOpen,
    setFilterIsOpen,
    setFilterQuery,
    filterData,
    setFilterData,
    videoModal,
    setVideoModal,
    addPurchasedCourse,
    setAddPurchasedCourse,
    courseEditMode,
    setCourseEditMode,
    coachIds,
    setCoachIds,
    courses,
    loading,
    refetch,
    handleViewClick,
    handleDeleteCourse,
    handlePublishedCourse,
    handleEditClick,
    handleClosePurchasedCourseDrawer,
    handleVideoModal,
    filterDrawer,
    handlePaginationClick,
    handleCreateCourse,
    resetFilter,
    onSearchSubmit,
    actionHandler,
    boughtCourses,
    filteredCourses,
  }
}

export default useCompanyCourses
