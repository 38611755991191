import { useState } from 'react'
import createQuestionService from 'services/questions/createQuestionService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export const useCreateQuestion = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const { createQuestionMutation, loading } = createQuestionService(params)

  const createQuestion = async (
    input: any,
    companyId: string,
    successCallback: () => void,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await createQuestionMutation({
      variables: {
        input,
        companyId,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`

        setError(message)
        return false
      }
      variant = 'error'
    } else {
      successCallback()
      message = `${t('general.question')} ${t('messages.saved')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    createQuestion,
    createQuestionLoading: loading,
    error,
  }
}
