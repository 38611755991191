import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useUserValue } from 'context/UserContext'
import { useUploadFile } from 'hooks/helpers/useHelpersService'
import { useEditTopic } from 'hooks/topics/useEditTopic'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import { EditTopicSchema } from 'helpers/validationSchemas'
import { GET_TOPIC } from 'gql/topics.query'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import Loader from 'components/common/Loader/Loader'
import Uploader from 'components/common/FileUploader/Uploader'
import { formData, ITopic, Params } from './EditTopicDrawer.interface'

const EditTopicDrawer = ({
  title,
  drawerName,
  id,
}: Params): React.JSX.Element => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const [dataState, dispatchData] = useData()
  const { uploadFile, fileLoading } = useUploadFile()
  const [currentTopic, setCurrentTopic] = useState<ITopic | null>(null)
  const { data } = useQuery<{ topic: ITopic }>(GET_TOPIC, {
    variables: { topicId: id },
  })

  const { editTopic, newTopic, editTopicLoading } = useEditTopic({
    topicId: id,
  })

  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: EditTopicSchema,
    onSubmit(values) {
      editTopic(id, values)
    },
  })

  const closeDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', drawerName, false)
  }

  const handleAttachmentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { confirm } = state.closeDrawer
    if (!confirm) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    }
    const { files } = e.target
    if (!files) return
    const file = files[0]
    uploadFile(file, 'topic/attachment', (link: string) =>
      setFieldValue('attachment', {
        name: file.name.split('.')[0],
        fileType: file.name.split('.').slice(-1)[0],
        size: file.size.toString(),
        link,
      }),
    )
    // setAttachment(file)
  }

  const deleteAttachment = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const { confirm } = state.closeDrawer
    if (!confirm) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    }
    e.stopPropagation()
    setFieldValue('attachment', null)
  }

  const handleCloseDrawer = (): void => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editTopic',
        values,
        compareTo: currentTopic,
      },
    })
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (newTopic) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
      closeDrawer()
    }
  }, [newTopic])

  useEffect(() => {
    if (data) {
      const { name, attachment } = data.topic
      const formData = { name, attachment }
      setValues(formData)
      setCurrentTopic(formData)
    }
  }, [data])

  return (
    <>
      {editTopicLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <TextInput
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  label="Name*"
                  size="small"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <Uploader
                  label="Add attachment"
                  accept="*"
                  onFileChange={handleAttachmentChange}
                  deleteFile={deleteAttachment}
                  type="file"
                  inputValue={values.attachment ? values.attachment.name : ''}
                  onInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('attachment.name', e.target.value)
                  }
                  disabled={!values.attachment}
                  uploadedFile={values.attachment}
                  fileExtension={
                    values.attachment ? `(${values.attachment.fileType})` : ''
                  }
                  loading={fileLoading === 'attachment'}
                  id="attachment"
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text="Cancel"
                type="small"
                onClick={handleCloseDrawer}
              ></Button>
              <Button
                text="Save"
                type="small"
                color="secondary"
                btnType="submit"
                isDisabled={fileLoading ? true : false}
              ></Button>
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditTopicDrawer
