import React, { ChangeEvent, useState } from 'react'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { columnConfig } from '../../../pages/group/ImportStudentsInGroups/gridConfig'
import DeleteIcon from '@mui/icons-material/Delete'
import useExtractUsersWithGroups from 'hooks/group/importStudents/useExtractUsersWithGroups'
import useImportStudentsWithGroups from 'hooks/users/useImportStudentsWithGroups'
import { ImportStudentsInGroupsProps } from '../../../pages/group/ImportStudentsInGroups/importStudentsInGroups.interface'
import { User } from 'interfaces/users'

const useImportStudentsInGroupsServices = ({
  refetch,
  onClose,
}: ImportStudentsInGroupsProps) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [selectedStudents, setSelectedStudents] = useState<string[]>([])
  const [importedStudents, setImportedStudents] = useState<User[]>([])
  const [imported, setImported] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [atLeastOneSelectedError, setAtLeastOneSelectedError] = useState(false)

  const { uploadFileStream } = useExtractUsersWithGroups()
  const { importStudentsWithGroups, loading: studentsImportLoading } =
    useImportStudentsWithGroups()

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
      handleUploadFile(files[0])
    }
  }

  const handleUploadFile = (file: File) => {
    if (file) {
      uploadFileStream(file, (data: User[]) => {
        setImportedStudents(data)
        setImported(false)
      })
    }
  }

  const addStudentsToGroup = (selectedStudents: string[], students: User[]) => {
    if (selectedStudents.length <= 0) return
    const data = students.filter(
      (item) => selectedStudents.findIndex((val) => item.id === val) !== -1,
    )

    const studentsData = data.map((user) => {
      const { id, firstName, lastName, email, groups = [] } = user
      return {
        id,
        firstName,
        lastName,
        email,
        groups,
      }
    })

    importStudentsWithGroups(
      studentsData,
      state.selectedCompany?.id,
      (data?: User[]) => {
        if (data) {
          setImported(true)
          setImportedStudents(data.filter((student) => student?.error))
          setSelectedStudents([])
          setSelectAll(false)
          if (refetch) refetch()
          if (!data.find((item) => item.error)) {
            onClose()
          }
        }
      },
    )
  }

  const handleFormSubmit = () => {
    if (!imported) {
      addStudentsToGroup(selectedStudents, importedStudents)
    }

    if (
      selectedStudents.find((studentId: string) =>
        importedStudents.find(
          (student) => student.id === studentId && student.error,
        ),
      )
    ) {
      return
    }

    selectedStudents.length <= 0
      ? setAtLeastOneSelectedError(true)
      : setAtLeastOneSelectedError(false)
  }

  const handleDeleteUsers = () => {
    if (selectedStudents.length > 0) {
      const data = importedStudents.filter(
        (importedStudent) =>
          selectedStudents.findIndex(
            (selectedStudent) => importedStudent.id === selectedStudent,
          ) === -1,
      )
      setImportedStudents(data)
      setSelectedStudents([])
    }
  }

  const selectItem = (id: string) => {
    const selectedIndex = selectedStudents.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStudents, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStudents.slice(1))
    } else if (selectedIndex === selectedStudents.length - 1) {
      newSelected = newSelected.concat(selectedStudents.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStudents.slice(0, selectedIndex),
        selectedStudents.slice(selectedIndex + 1),
      )
    }

    setSelectedStudents(newSelected)
  }

  const studentList = importedStudents || []

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = studentList.map((student) => student.id)
      setSelectedStudents(newArr)
      setSelectAll(true)
      return
    }
    setSelectedStudents([])
    setSelectAll(false)
  }

  const toolbarAction = [
    {
      id: 0,
      color: 'secondary',
      tooltipText: 'Delete',
      disabled: !(selectedStudents.length > 0),
      onClick: () => handleDeleteUsers(),
      component: <DeleteIcon fontSize="small" />,
    },
  ]

  const config = columnConfig(selectItem, imported, t)
  return {
    t,
    handleFileChange,
    selectedStudents,
    toolbarAction,
    imported,
    studentList,
    atLeastOneSelectedError,
    config,
    studentsImportLoading,
    selectAllItem,
    handleFormSubmit,
  }
}

export default useImportStudentsInGroupsServices
