import React, { ReactElement } from 'react'
import { GridStatusEnums, StatusWrapperProps } from './status.interface'
import { CompanyStatusContainer, StatusWrapper } from './styled-components'
import { useTranslation } from 'react-i18next'

const StatusIndicator = ({
  status = GridStatusEnums.SUSPENDED,
}: StatusWrapperProps) => {
  return (
    <StatusWrapper status={status}>
      {status.charAt(0) + status.slice(1).toLowerCase()}
    </StatusWrapper>
  )
}

export const CompanyStatusIndicator = ({
  isActive = true,
}: {
  isActive: boolean
}): ReactElement => {
  const { t } = useTranslation()
  return (
    <CompanyStatusContainer isActive={isActive}>
      {isActive ? t('general.active') : t('general.disabled')}
    </CompanyStatusContainer>
  )
}

export default StatusIndicator
