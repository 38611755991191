import React, { ChangeEvent, useState, MouseEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from 'components/common/Cards/styled-components'
import Pagination from 'components/common/Pagination'
import { ToolbarItem } from 'components/common/Toolbar'
import { Wrapper } from './styled-components'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Input } from 'components/common/TextInput'
import TestItem from 'pages/tests/testItem/TestItem'
import { TestAssessment } from 'pages/tests/testLayout/testLayout.interface'
import { useNavigate } from 'react-router-dom'
import { Group } from 'interfaces/groups'
import { useLazyGetTestAssessmentsByCompanyId } from 'hooks/tests'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { SplashScreen } from 'components/common'

export const GroupTestAssessments: React.FC<{
  group: Group
}> = ({ group }) => {
  const { t } = useTranslation()

  if (!group.testAssessments)
    return <SplashScreen subTitle={t('tests_layout.no_tests_group')} />

  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(12)
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate()
  const defaultFilter = group.testAssessments?.length
    ? {
        _id: {
          type: UpperCaseFilterableFieldType.ARRAY_IN,
          value: group.testAssessments?.length ? group.testAssessments : null,
        },
      }
    : {}

  const { tests, getTestAssessments, loading } =
    useLazyGetTestAssessmentsByCompanyId()

  useEffect(() => {
    if (group.testAssessments?.length) {
      getTestAssessments({
        currentPage,
        perPage,
        companyId: group?.company?.id,
        filter: defaultFilter,
      })
    }
  }, [group])

  const handlePaginationClick = (
    event: MouseEvent<HTMLButtonElement> | null,
    value: number,
  ) => {
    if (value !== currentPage) {
      setCurrentPage(value)
    }
  }

  const onSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setCurrentPage(1)
    const nameFilter = {
      name: {
        type: UpperCaseFilterableFieldType.MATCH,
        value: searchText,
      },
    }
    getTestAssessments({
      currentPage: 1,
      perPage,
      companyId: group?.company?.id,
      filter: {
        ...defaultFilter,
        ...nameFilter,
      },
    })
  }

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
    if (e.target.value === '') {
      getTestAssessments({
        currentPage: 1,
        perPage,
        companyId: group?.company?.id,
        filter: defaultFilter,
      })
    }
  }

  if (!tests && !loading)
    return <SplashScreen subTitle={t('tests_layout.no_tests_group')} />

  return (
    <>
      <Wrapper>
        <ToolbarItem>
          <form onSubmit={onSearchSubmit}>
            <Input
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchText}
              onChange={onSearchChange}
              icon={
                <IconButton onClick={onSearchSubmit}>
                  <SearchRoundedIcon />
                </IconButton>
              }
            />
          </form>
        </ToolbarItem>
      </Wrapper>
      {tests && (
        <>
          {tests.data.length === 0 && (
            <SplashScreen
              marginTop={20}
              subTitle={t('tests_layout.no_tests_group_matched')}
            />
          )}
          <Grid
            className="card"
            style={{ position: 'relative', marginTop: ' 17px' }}
            cols={4}
          >
            {tests.data.map((test: TestAssessment, index: number) => (
              <TestItem
                key={index}
                item={test}
                isFromUser={true}
                handleClick={({
                  id: testAssessmentId,
                  companyId,
                }: TestAssessment) =>
                  navigate(`/`, {
                    state: { testAssessmentId, groupId: group.id, companyId },
                  })
                }
              />
            ))}
          </Grid>

          {tests.totalPages > 1 && (
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Pagination
                currentPage={tests.currentPage || 1}
                totalPages={tests.totalPages || 0}
                handleChange={handlePaginationClick}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}
