import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { Container, Header, Title } from './styled-components'
import { Button } from 'components/common/Button'
import * as Yup from 'yup'
import { useAction } from 'store/actions'
import { useData } from 'context/DataContext'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import { QuizSchema, IQTestSchema } from 'helpers/validationSchemas'
import { formData } from 'pages/quizzes/staticData'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import useCreateQuizService from 'hooks/quizzes/useCreateQuizService'
import Step1 from './Step1'
import { AddQuizDrawerProps, IValue } from './addQuizDrawer.interface'
import { Tag } from 'components/common/Tags/tags.interface'

const AddQuizDrawer = (props: AddQuizDrawerProps): React.JSX.Element => {
  const { t } = useTranslation()
  const validationSchema = props.data.IQTest ? IQTestSchema : QuizSchema
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const [validation, setValidation] = useState<any>(validationSchema)

  const { createQuiz, loading: createQuizLoading } = useCreateQuizService({
    type: props.type,
    actionId: props.id,
  })

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        ...formData,
        quizParticipationType: 'optional',
      },
      validationSchema: Yup.object(validation),
      onSubmit: (values: IValue) => {
        if (createQuizLoading) return
        const data = { ...values }
        data.time = data.time ? parseFloat(data.time as string) : 0
        if (data.quizParticipationType === 'optional') delete data.passRate
        data.tags = data.tags.map((i: Tag) => ({
          label: i.label,
        }))
        if (props.data.IQTest) {
          data.IQTestType = 'initial'
        }
        data.parentName = props.data.name
        createQuiz(data, props.type, props.id, props.data.companyId, onSuccess)
      },
    })

  const onSuccess = (): void => {
    props.data.refetch()
    props.onClose()
    dispatch({ type: CLEAR_FORMDATA })
    toggleDrawerConfirm(false, '')
  }

  const handleCloseDrawer = (): void => {
    const { ...formData } = values
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addQuiz',
        values: formData,
        compareTo: {},
      },
    })
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  let addedIQTestType = ''

  if (props.type === 'course') {
    addedIQTestType =
      props.data.quizzes.length > 0 ? props.data.quizzes[0].IQTestType : ''
  }

  const handleQuizParticipationTypeChange = (value: string): void => {
    if (value === 'mandatory') {
      setValidation((validation: IValue) => ({
        ...validation,
        passRate: Yup.number().required('Pass Rate is Required'),
      }))
    } else {
      setValidation((validation: IValue) => ({
        ...validation,
        passRate: null,
      }))
      delete errors.passRate
      delete touched.passRate
    }
    setFieldValue('quizParticipationType', value)
  }

  return (
    <Container>
      <Header>
        <Title>
          {props.data.IQTest
            ? t('quizzes_layout.create_iq_test')
            : t('quizzes_layout.create_quiz')}
        </Title>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </Header>

      <DrawerContent>
        <FormContainer
          onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
            e.preventDefault()
          }
        >
          <FormGroupWrapper>
            <Step1
              errors={errors}
              values={values}
              touched={touched}
              handleChange={handleChange}
              handleQuizParticipationTypeChange={
                handleQuizParticipationTypeChange
              }
              setFieldValue={setFieldValue}
              IQTest={props.data.IQTest}
              type={props.type}
              addedIQTestType={addedIQTestType}
              id={props.id}
              companyId={props.data.companyId}
              t={t}
            />
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                onClick={handleSubmit}
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </Container>
  )
}

export default AddQuizDrawer
