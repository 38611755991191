export interface Params {
  title: string
  drawerName: string
  id: string
}

export interface formInterface {
  name: string
  attachment:
    | {
        name: string
        fileType: string
        size: string
        link: string
      }
    | null
    | undefined
}

export interface ITopic {
  name: string
  attachment?: {
    name: string
    fileType: string
    size: string
    link: string
  }
}

export const formData: formInterface = {
  name: '',
  attachment: null,
}
