import { snackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import extractGroupsWithTests from 'services/group/uploadGroupsWithTestsService'

const useExtractGroupsWithTests = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { singleUploadStream } = extractGroupsWithTests()

  const uploadFileStream = async (file: File, cb: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors, data } = await singleUploadStream({
      variables: {
        file,
      },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = t('messages.extracted_groups')
      variant = 'success'

      cb(data.extractGroupsWithTests)
    }

    setSnackbar({ message, variant })
  }

  return {
    uploadFileStream,
  }
}

export default useExtractGroupsWithTests
