import { useMutation } from '@apollo/client'
import { ADD_TEST } from 'gql/tests/tests.query'
// import { updateCache } from 'services/updateCache'

const addTestService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addTestMutaiton, { loading, error }] = useMutation(ADD_TEST, {})

  return {
    addTestMutaiton,
    loading,
    error,
  }
}

export default addTestService
