import { useTranslation } from 'react-i18next'
import {
  IUseTutorsListResponse,
  UseTutorsListProps,
} from './tutorsList.interface'
import { useQuery } from '@apollo/client'
import { GET_AVAILABLE_TUTORS } from 'gql/users.query'
import { useEffect, useRef, useState } from 'react'
import { User } from 'interfaces/users'

const useTutorsList = ({
  courseId,
  searchValue,
}: UseTutorsListProps): IUseTutorsListResponse => {
  const { t } = useTranslation()
  const listRef = useRef<HTMLDivElement | null>(null)
  const [filteredTutors, setFilteredTutors] = useState<User[]>([])

  const { data: courseCoaches, loading: tutorsLoading } = useQuery(
    GET_AVAILABLE_TUTORS,
    {
      variables: { courseId },
    },
  )

  useEffect(() => {
    if (courseCoaches) {
      setFilteredTutors(courseCoaches?.getAvailableTutors)
    }
  }, courseCoaches)

  useEffect(() => {
    let filteredTutors = courseCoaches?.getAvailableTutors
    if (searchValue) {
      const lowerCaseSearchValue = searchValue.toLowerCase()
      filteredTutors =
        courseCoaches?.getAvailableTutors.filter(
          (tutor: User) =>
            tutor.email.toLowerCase().includes(lowerCaseSearchValue) ||
            tutor.firstName.toLowerCase().includes(lowerCaseSearchValue) ||
            tutor.lastName.toLowerCase().includes(lowerCaseSearchValue),
        ) || []
    }

    setFilteredTutors(filteredTutors)
  }, [searchValue])

  return {
    t,
    tutors: filteredTutors || [],
    tutorsLoading,
    listRef,
  }
}

export default useTutorsList
