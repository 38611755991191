import React, { CSSProperties, memo } from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import {
  GridTableHeader,
  GridTableTitle,
  GridTableWrapper,
} from './styled-components'
import DndItem from 'components/common/Grid/DndGrid/DndItem'
import Loader from 'components/common/Loader'

import _ from 'lodash'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Checkbox from 'components/common/Checkbox'
import GridActionMenu from './GridActionMenu/GridActionMenu'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import { useNavigate } from 'react-router-dom'
import MyLoader from 'loading/loading'
import { loaderStyles } from 'pages/courses/CourseQuizzes/styled.components'

const PREFIX = 'Grid'

const myClasses = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
  cursor: `${PREFIX}-cursor`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${myClasses.root}`]: {
    width: '100%',
  },

  [`& .${myClasses.paper}`]: {
    width: '100%',
    marginBottom: 0,
    borderRadius: '14px 14px 0 0',
  },

  [`& .${myClasses.table}`]: {
    minWidth: 750,
    position: 'relative',
  },

  [`& .${myClasses.visuallyHidden}`]: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  [`& .${myClasses.cursor}`]: {
    cursor: 'pointer',
  },
}))

type Order = 'asc' | 'desc'
interface Data {
  default: null
  title: number
  description: number
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === 'birthDate') {
    const val1: any = a[orderBy]
    const val2: any = b[orderBy]
    const dateA = new Date(parseInt(val1))
    const dateB = new Date(parseInt(val2))

    if (dateB < dateA) {
      return -1
    }
    if (dateB > dateA) {
      return 1
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
  }
  return 0
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface EnhancedTableProps {
  numSelected: number
  classes: any
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  order: Order
  orderBy: string
  rowCount: number
  config: any
  selectAllItem: any
  tableCellStyles?: CSSProperties
  tableRowStyles?: CSSProperties
  actionMenu?: boolean
  allSelected?: boolean
  disabledSelect?: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { t } = useTranslation()
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    allSelected,
    disabledSelect,
    tableCellStyles,
    tableRowStyles,
  } = props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow style={tableRowStyles}>
        {props.config.map((headCell: any) => {
          if (headCell.id === 'isChecked') {
            return (
              <TableCell
                padding="none"
                key={headCell.id}
                style={tableCellStyles}
              >
                <Root
                  style={{ visibility: disabledSelect ? 'hidden' : 'visible' }}
                >
                  <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    // checked={props.allSelected}
                    checked={rowCount > 0 && numSelected >= rowCount}
                    disabled={rowCount === 0}
                    onChange={() =>
                      props.selectAllItem(!(rowCount === numSelected))
                    }
                    // inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </Root>
              </TableCell>
            )
          }
          return (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={'none'}
              style={tableCellStyles}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                style={{ whiteSpace: 'nowrap' }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}

        {props.actionMenu ? (
          <TableCell component="th" scope="row" padding="none" align="center">
            <div>{t('grid.action')}</div>
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  )
}

const Grid = (props: any) => {
  const { t } = useTranslation()

  const classes = props.classes || myClasses
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('default')
  const [selected, setSelected] = React.useState<string[]>([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const navigate = useNavigate()

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.data.map((n: any) => n.title)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: any) =>
    props.selectedItems && props.selectedItems.indexOf(id) !== -1

  const redirectToProfile = (id: string): void => {
    if (props.openInExternalTab) {
      window.open(`/profile/${id}`, '_blank')
    } else {
      navigate(`/profile/${id}`)
    }
  }

  const renderRow = (itemOrigianal: any) => {
    const isItemSelected = isSelected(itemOrigianal.id)

    // duplicating the item to avoid changing the original item (original object is not extensible)
    const item = { ...itemOrigianal }
    item.isChecked = isItemSelected

    return (
      <>
        {props.config.map((i: any, index: number) => (
          <TableCell
            className={classes.cursor}
            component="th"
            scope="row"
            padding="none"
            key={index}
            style={
              props.width && props.indexOfSpecificWidth === index
                ? { width: props.width }
                : {}
            }
            onClick={() =>
              item.role
                ? redirectToProfile(item.id)
                : props.redirectToGroupeDetails
                ? props.redirectToGroupeDetails(item)
                : {}
            }
          >
            {i.render(item)}
          </TableCell>
        ))}
      </>
    )
  }

  const renderBody = (data: any, actionConfig: any, error: any) => {
    return props.isDraggable ? (
      <>
        {data.map((row: any, index: number) => {
          const styles: any = { height: '50px' }
          if (error) {
            styles.background = row[error.error]
              ? error.errorColor
              : error.successColor
          }
          return (
            <DndItem
              id={row.id}
              key={row.id}
              index={index}
              moveCard={props.moveCard}
              onEnd={props.changeOrder}
              cardIds={data.map((card: any) => card.id)}
              isTable
              isNested
              className={`row ${
                !row.error &&
                (props.disabledSelect || props.selectedItems?.includes(row.id))
                  ? 'Mui-selected'
                  : row.error
                  ? 'Mui-selected-error'
                  : ''
              }`}
            >
              {renderRow(row)}

              {actionConfig && actionConfig.length > 0 && (
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  align="center"
                >
                  <GridActionMenu actionConfig={actionConfig} row={row} />
                </TableCell>
              )}
              {/* </TableRow> */}
            </DndItem>
          )
        })}
      </>
    ) : (
      <>
        {stableSort(data, getComparator(order, orderBy)).map((row: any) => {
          const styles: any = { height: '50px' }
          if (error) {
            styles.background = row[error.error]
              ? error.errorColor
              : error.successColor
          }
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.id}
              style={styles}
              className={`row ${
                !row.error &&
                (props.disabledSelect || props.selectedItems?.includes(row.id))
                  ? 'Mui-selected'
                  : row.error
                  ? 'Mui-selected-error'
                  : ''
              }`}
            >
              {renderRow(row)}

              {actionConfig && actionConfig.length > 0 && (
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  align="center"
                >
                  <GridActionMenu actionConfig={actionConfig} row={row} />
                </TableCell>
              )}
            </TableRow>
          )
        })}
      </>
    )
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <GridTableWrapper>
          <TableContainer>
            <GridTableHeader>
              {props.title && <GridTableTitle>{props.title}</GridTableTitle>}
              <div style={{ display: 'flex' }}>
                <MultipleAction>
                  {props.resetFilters && (
                    <IconButton
                      onClick={props.resetFilters}
                      disabled={props.resetVisible ? false : true}
                    >
                      <SyncIcon fontSize="small" />
                    </IconButton>
                  )}
                  <MultipleActionTooltip>
                    {t('actions.reset')}
                  </MultipleActionTooltip>
                </MultipleAction>
                {props.onFiltersClick && (
                  <MultipleAction>
                    <IconButton onClick={props.onFiltersClick}>
                      <FilterListIcon fontSize="small" />
                    </IconButton>
                    <MultipleActionTooltip>
                      {' '}
                      {t('actions.filters')}
                    </MultipleActionTooltip>
                  </MultipleAction>
                )}
              </div>
            </GridTableHeader>

            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={props.selected}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={props.data.length}
                config={props.config}
                selectAllItem={props.selectAllItem}
                actionMenu={
                  !(!props.actionConfig || props.actionConfig.length <= 0)
                }
                allSelected={props.allSelected}
                disabledSelect={props.disabledSelect}
                tableCellStyles={props.tableCellStyles}
                tableRowStyles={props.tableRowStyles}
              />
              <TableBody>
                {props.loading && (
                  <MyLoader width={250} speed={0.8} style={loaderStyles} />
                )}
                {renderBody(props.data, props.actionConfig, props.error)}
              </TableBody>
            </Table>
            {props.data.length === 0 && !props.loading && (
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  padding: '20px 0',
                }}
              >
                No records found
              </div>
            )}
          </TableContainer>
        </GridTableWrapper>
        {props.data.length > 10 && props.paging ? (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            count={props.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
    </div>
  )
}

export default memo(Grid, (prev, next) => {
  if (!_.isEqual(prev.data, next.data)) return false
  if (prev.selectAllItem !== next.selectAllItem) return false
  if (prev.selected !== next.selected) return false

  return true
})
