import React, { ChangeEvent } from 'react'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { Button } from 'components/common/Button'
import CreatableSelectWithError from 'components/common/CreatableSelect/CreatableSelect'
import Uploader from 'components/common/FileUploader/Uploader'
import {
  FormButtons,
  FormContainer,
  FormFooter,
  FormGroupItem,
  FormGroupWrapper,
  InputGroup,
} from 'components/common/Form'
import { Input } from 'components/common/TextInput'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/DrawerLayout/styled-components'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { Title } from 'pages/tests/addTestDrawer/styled-components'
import { GapStyles, StyledColumnFlexContainer } from '../styled-components'
import { useAddLessonDrawer } from 'hooks/lessons/useAddLessonDrawer/useAddLessonDrawer'
import { IAddLessonDrawerProps } from './addLessonDrawer.interface'

const AddLessonDrawer = ({
  data,
  id,
  onClose,
}: IAddLessonDrawerProps): JSX.Element => {
  const {
    setFieldValue,
    handleChange,
    handleContentChange,
    handleFileChange,
    handleImageChange,
    handleSubmit,
    handleTopicChange,
    handleCloseDrawer,
    submitDisabled,
    t,
    topicList,
    touched,
    fileExtension,
    fileTypes,
    values,
    errors,
    deleteContentFile,
    fileLoading,
    imageLoading,
    uploadVideoProgress,
    combinedConstants,
    addTopicLoading,
  } = useAddLessonDrawer({ data, id, onClose })

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('lessons_layout.create_lesson')}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <FormGroupItem>
              <Title>{`${t('general.topic')}*`}</Title>

              <CreatableSelectWithError
                placeholder={`${t('form_fields.select')} ${t('general.topic')}`}
                options={topicList}
                errorMessage={errors.topic ? errors.topic : ''}
                touched={touched.topic}
                value={values.topic}
                onChange={handleTopicChange}
                isLoading={addTopicLoading}
                isDisabled={!!data.id}
              />
            </FormGroupItem>

            <FormGroupItem>
              <Title>{`${t('general.name')}*`}</Title>
              <Input
                error={!!errors.name}
                errorMessage={errors.name ? errors.name : ''}
                touched={touched.name}
                label={''}
                name="name"
                size="small"
                type="text"
                fullWidth
                value={values.name}
                onChange={handleChange}
              />
            </FormGroupItem>

            <FormGroupItem>
              <Title>{t('lesson_details.lesson_overview')}</Title>
              <TextEditor
                isVisible={true}
                hasControls={false}
                contentHeight="200px"
                handleChange={(editorState: string) => {
                  setFieldValue('description', editorState)
                }}
                canEdit={true}
                defaultText={t('lesson_details.lesson_overview')}
              />
            </FormGroupItem>

            <FormGroupItem>
              <Title>{t('form_fields.transcript')}</Title>
              <TextEditor
                isVisible={true}
                hasControls={false}
                handleChange={(editorState: string) => {
                  setFieldValue('transcript', editorState)
                }}
                canEdit={true}
                defaultText={t('form_fields.transcript')}
              />
            </FormGroupItem>

            <FormGroupItem>
              <StyledColumnFlexContainer>
                <Title>{`${t('actions.upload_content')}*`}</Title>
                <Uploader
                  // label={`${t('actions.upload_content')}*`}
                  // accept="video/*"
                  onFileChange={handleContentChange}
                  deleteFile={deleteContentFile}
                  type={fileTypes}
                  accept={combinedConstants}
                  inputValue={
                    fileTypes === 'video'
                      ? (values[fileTypes] && values[fileTypes]?.title) || ''
                      : (values[fileTypes] && values[fileTypes]?.name) || ''
                  }
                  onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(
                      fileTypes === 'video'
                        ? 'video.title'
                        : `${fileTypes}.name`,
                      e.target.value,
                    )
                  }
                  disabled={!values[fileTypes]}
                  uploadedFile={values[fileTypes]}
                  fileExtension={fileExtension}
                  loading={
                    ['video', 'audio'].includes(fileTypes)
                      ? uploadVideoProgress > 0
                        ? uploadVideoProgress
                        : false
                      : fileLoading === 'content'
                  }
                  id="content"
                />
              </StyledColumnFlexContainer>
            </FormGroupItem>

            <FormGroupItem>
              <InputGroup style={GapStyles}>
                {values.video && (
                  <StyledColumnFlexContainer>
                    <Title>{t('actions.add_subtitle')}</Title>
                    <Uploader
                      // label={t('actions.add_subtitle')}
                      accept=".vtt, .srt"
                      onFileChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleFileChange(e, 'subtitle')
                      }
                      deleteFile={() => {
                        setFieldValue('subtitle', null)
                      }}
                      type="file"
                      inputValue={
                        (values.subtitle && values.subtitle.name) || ''
                      }
                      onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('subtitle.name', e.target.value)
                      }
                      disabled={!values.subtitle}
                      uploadedFile={values.subtitle}
                      fileExtension={
                        (values.subtitle && values.subtitle.fileType) || ''
                      }
                      loading={fileLoading === 'subtitle'}
                      id="subtitle"
                    />
                  </StyledColumnFlexContainer>
                )}

                {(values.video || values.contentDocument) && (
                  <StyledColumnFlexContainer>
                    <Title>{t('actions.add_thumbnail')}</Title>
                    <Uploader
                      // label={t('actions.add_thumbnail')}
                      onFileChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleImageChange(e, 'thumbnail')
                      }
                      deleteFile={() => {
                        setFieldValue('thumbnail', null)
                      }}
                      type="image"
                      inputValue={
                        (values.thumbnail && values.thumbnail.name) || ''
                      }
                      onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('thumbnail', e.target.value)
                      }
                      disabled={!values.thumbnail}
                      uploadedFile={values.thumbnail}
                      fileExtension={
                        (values.thumbnail && values.thumbnail.fileType) || ''
                      }
                      loading={imageLoading}
                      id="thumbnail"
                    />
                  </StyledColumnFlexContainer>
                )}
              </InputGroup>
            </FormGroupItem>
            <FormGroupItem>
              <StyledColumnFlexContainer>
                <Title>{t('lesson_details.resource')}</Title>
                <Uploader
                  accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain"
                  onFileChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleFileChange(e, 'attachment')
                  }
                  deleteFile={() => {
                    setFieldValue('attachment', null)
                  }}
                  type="file"
                  inputValue={
                    (values.attachment && values.attachment.name) || ''
                  }
                  onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('attachment.name', e.target.value)
                  }
                  disabled={!values.attachment}
                  uploadedFile={values.attachment}
                  fileExtension={
                    (values.attachment && values.attachment.fileType) || ''
                  }
                  loading={fileLoading === 'attachment'}
                  id="attachment"
                />
              </StyledColumnFlexContainer>
            </FormGroupItem>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                textColor="#06C68F"
                background="#f5f5f5"
                outline="1px solid #06C68F"
              />

              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                onClick={handleSubmit}
                isDisabled={submitDisabled}
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddLessonDrawer
