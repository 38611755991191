import { FormikErrors, FormikTouched } from 'formik'
import { Images } from 'pages/modules/FlashCards/interfaces'
import { TFunction } from 'interfaces/TFunction'
import { FormValues } from '../AddFlashCardDrawer/useAddFlashCardDrawer.interface'

export interface UseEditFlashCardDrawerReturn {
  loading: boolean
  editFlashCardLoading: boolean
  t: TFunction<'translation', undefined>
  handleCloseDrawer: () => void
  handleSubmit: () => void
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
  values: FormValues
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  setFieldValue: (
    field: string,
    value: string | number | boolean,
    shouldValidate?: boolean,
  ) => void
  uploadedImage: string
  setOpenedModal: (value: string) => void
  imageLoading: boolean
  setImage: (value: string) => void
  setUploadedImage: (value: string) => void
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleAudioChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  setAudioProgress: (value: number | null) => void
  audioProgress: number | null
  openedModal: string
  handleModalClose: () => void
  color: string
  setColor: (value: string) => void
  currentTab: keyof Images
  images: Images
  handleTabChange: (newValue: keyof Images) => void
  image: string
  handleModalSubmit: () => void
}

export interface FlashCard {
  question: string
  answer: string
  image: string
  audio: { name: string; fileType: string; link: string }
  color: string
}
export interface test {
  question: string
  answer: string
  image: string
  audio: any
  color: string
}

export const formData: test = {
  question: '',
  answer: '',
  image: '',
  audio: null,
  color: '',
}
