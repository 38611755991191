import Typography from '@mui/material/Typography'
import { Wrapper } from './styled-components'
import React from 'react'
import strCutter from 'utils/strCutter'
import { formatDuration } from 'helpers/formatDuration'
import { Action } from 'components/common/GridV2'
import Tooltip from '@mui/material/Tooltip'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { ReactComponent as PlayButton } from 'assets/play-icon.svg'
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'
import { ReactComponent as PublicIcon } from 'assets/locked.svg'
import {
  IActionHandlerType,
  ICourseItem,
  ICourseListAction,
  ICourseListConfigReturn,
  ICourseListItem,
  IListColumnConfigItem,
} from './courseList.interface'
import { TranslationFunction } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { DeleteSpan } from 'components/common/Styled/styled-components'

export const courseListConfig = (
  t: TranslationFunction,
): ICourseListConfigReturn[] => {
  const grid = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <Wrapper
          // onClick={() => handelClick(item.id)}
          style={{ cursor: 'pointer' }}
        >
          {item.name.length > 30 ? (
            <Tooltip title={item.name}>
              <div>{strCutter(item.name, 30)}</div>
            </Tooltip>
          ) : (
            <div>{item.name}</div>
          )}
        </Wrapper>
      ),
    },
    {
      id: 'status',
      label: t('general.status'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <StatusIndicator
          status={
            GridStatusEnums[
              item.state.toUpperCase() as keyof typeof GridStatusEnums
            ]
          }
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'numOfModules',
      label: t('general.modules'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <Wrapper>{item.numberOfModules}</Wrapper>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'numberOfLessons',
      label: t('general.lessons'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <Wrapper>{item.numberOfLessons}</Wrapper>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'duration',
      label: t('general.duration'),
      render: (item: IListColumnConfigItem): JSX.Element => {
        return <Wrapper>{formatDuration(item.duration)}</Wrapper>
      },
      numeric: false,
      disablePadding: true,
    },
  ]
  return grid
}

export const courseListActions = (
  handleClick: IActionHandlerType,
  t: TranslationFunction,
  permission: {
    publishCourse: boolean
    editCourse: boolean
    deleteCourse: boolean
  },
  activeTab: number,
): ICourseListAction[] => {
  const actions = [
    {
      render: (item: ICourseListItem): JSX.Element => {
        if (!permission.publishCourse || activeTab === 0) {
          return <React.Fragment />
        }

        return (
          <Action
            disabled={item.finished !== 3}
            onClick={() => {
              if (item.finished === 3) {
                handleClick(
                  'publishedUnPublished',
                  item.id,
                  item.state === 'unpublished' ? 'published' : 'unpublished',
                )
              }
            }}
          >
            <Tooltip
              title={
                item.state === 'unpublished'
                  ? t('actions.publish')
                  : t('actions.unpublish')
              }
              arrow
            >
              <PublicIcon />
            </Tooltip>
          </Action>
        )
      },
    },
    {
      hide: !permission.editCourse,
      render: (item: ICourseListItem) => (
        <Action onClick={() => handleClick('editCourse', item)}>
          <Tooltip title={t('actions.edit')} arrow>
            <EditRoundedIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      render: (item: ICourseListItem): JSX.Element => {
        return (
          <Action>
            <DownloadIcon />
            <Typography>{t('actions.download_syllabus')}</Typography>
          </Action>
        )
      },
    },
    {
      render: (item: ICourseListItem): JSX.Element => {
        return (
          <Action
            onClick={() => handleClick('playIntro', item)}
            disabled={!item.video}
          >
            <PlayButton />
            <Typography>{t('actions.play_intro')}</Typography>
          </Action>
        )
      },
    },
    {
      hide: !permission.deleteCourse,
      render: (item: ICourseItem) => (
        <Action onClick={() => handleClick('deleteCourse', item.id)}>
          <DeleteIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]

  return actions
}

// export const columnConfig = (
//   handelClick: any,
//   selectItem: any,
//   handleVideoModal: any,
// ) => {
//   const grid = [
//     {
//       id: 'isChecked',
//       render: (item: itemProps) => (
//         <Checkbox
//           checked={item.isChecked}
//           onChange={() => selectItem(item.id)}
//         />
//       ),
//       numeric: false,
//       disablePadding: true,
//     },
//     {
//       id: 'videos',
//       numeric: false,
//       disablePadding: true,
//       label: 'Play Course',
//       render: (item: itemProps) => (
//         <Wrapper
//           onClick={() => handleVideoModal(item)}
//           style={{ cursor: 'pointer' }}
//         >
//           <IconButton>
//             <PlayCircleOutlineIcon
//               color={
//                 item.videos && item.videos.length > 0 && item.videos[0].links
//                   ? 'secondary'
//                   : 'disabled'
//               }
//             />
//           </IconButton>
//         </Wrapper>
//       ),
//     },
//     {
//       id: 'name',
//       numeric: false,
//       disablePadding: true,
//       label: 'Name',
//       render: (item: itemProps) => (
//         <Wrapper
//           onClick={() => handelClick(item.id)}
//           style={{ cursor: 'pointer' }}
//         >
//           {strCutter(item.name, 30)}
//         </Wrapper>
//       ),
//     },
//     {
//       id: 'state',
//       numeric: true,
//       disablePadding: false,
//       label: 'Status',
//       render: (item: itemProps) => <Wrapper>{item.state}</Wrapper>,
//     },
//     {
//       id: 'level',
//       numeric: true,
//       disablePadding: false,
//       label: 'Level',
//       render: (item: itemProps) => <Wrapper>{item.level}</Wrapper>,
//     },
//     {
//       id: 'coursePrivacy ',
//       numeric: true,
//       disablePadding: false,
//       label: 'Privacy',
//       render: (item: itemProps) => <Wrapper>{item.coursePrivacy}</Wrapper>,
//     },
//     // {
//     //   id: 'coachType',
//     //   numeric: true,
//     //   disablePadding: false,
//     //   label: 'Coach Type',
//     //   render: (item: itemProps) => (
//     //     <Wrapper>{item.coachType}</Wrapper>
//     //   ),
//     // },
//     {
//       id: 'coach',
//       numeric: true,
//       disablePadding: false,
//       label: 'Coach',
//       render: (item: itemProps) => {
//         if ((item.coach && item.coach.length <= 0) || !item.coach) {
//           return <NoValueWrapper>N/A</NoValueWrapper>
//         }

//         return (
//           <Tooltip
//             title={item.coach.map((i: any) => `${i.firstName} ${i.lastName}, `)}
//           >
//             <IconButton>
//               <span className="material-icons">groups</span>
//             </IconButton>
//           </Tooltip>
//         )
//       },
//     },
//     // {
//     //   id: 'duration',
//     //   numeric: true,
//     //   disablePadding: false,
//     //   label: 'Duration',
//     //   render: (item: itemProps) => (
//     //     <Wrapper>{item.duration}</Wrapper>
//     //   ),
//     // },
//     {
//       id: 'numberOfGroups',
//       numeric: true,
//       disablePadding: false,
//       label: 'Groups',
//       render: (item: itemProps) => (
//         <Wrapper>{item.groups?.length || 0}</Wrapper>
//       ),
//     },
//     {
//       id: 'numberOfStudents',
//       numeric: true,
//       disablePadding: false,
//       label: 'Students',
//       render: (item: itemProps) => (
//         <Wrapper>{item.numberOfStudents || 0}</Wrapper>
//       ),
//     },
//     // {
//     //   id: 'rating',
//     //   numeric: true,
//     //   disablePadding: false,
//     //   label: 'Rating',
//     //   render: (item: itemProps) => (
//     //     <Wrapper>{item.rating}</Wrapper>
//     //   ),
//     // },
//   ]

//   return grid
// }

export const actionConfig = (
  handleClick: IActionHandlerType,
  permission: {
    publishCourse: boolean
    editCourse: boolean
    deleteCourse: boolean
  },
  // currentUser: any,
  // companyMode?: any,
  // activeTab?: any,
  t: TranslationFunction,
) => {
  const action = [
    {
      render: (item: ICourseItem): JSX.Element => {
        return !item.isBought ? (
          <Action
            className="edit"
            onClick={() => handleClick('editCourse', item)}
          >
            <EditRoundedIcon />
            <Typography>{t('actions.edit')}</Typography>
          </Action>
        ) : (
          <React.Fragment />
        )
      },
    },
    {
      render: (item: ICourseItem): JSX.Element => {
        return (
          <Action>
            <DownloadIcon />
            <Typography>{t('actions.download_syllabus')}</Typography>
          </Action>
        )
      },
    },
    {
      render: (item: ICourseItem): JSX.Element => {
        return (
          <Action
            onClick={() => handleClick('playIntro', item)}
            disabled={!item.video}
          >
            <PlayButton />
            <Typography>{t('actions.play_intro')}</Typography>
          </Action>
        )
      },
    },
    {
      hide: !permission.deleteCourse,
      render: (item: ICourseItem): JSX.Element => (
        <Action
          className="delete"
          onClick={() => handleClick('deleteCourse', item.id)}
        >
          <DeleteIcon />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]
  return action

  // if (currentUser.companyId && !currentUser.companyId.platformService) {
  //   action.push({
  //     render: (item: any) => (
  //       <Action onClick={() => handleClick('editCourse', item.id)}>
  //         <EditRoundedIcon />
  //         <Typography>Menage Groups</Typography>
  //       </Action>
  //     ),
  //   })
  // }

  //
}
