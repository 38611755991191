import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import { PhoneCountryCode } from 'components/common/PhoneCountyCodeV2'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Title,
} from 'components/DrawerLayout/styled-components'
import { genderOptions } from 'components/UsersLayout/staticData'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { DrawerEventEmitter } from 'helpers/drawer'
import { UserSchema } from 'helpers/validationSchemas'
import useAddUserServie from 'hooks/users/useAddUserService'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AsyncSelect } from 'components/common/SelectField'

import { CLEAR_FORMDATA, SET_FORMDATA } from 'store/types'
import { useLazyQuery } from '@apollo/client'
import { GET_COMPANIES } from 'gql/companies.query'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import { NotAllowed } from 'pages/profile/AdminProfile/styled-components'
import CloseIcon from '@mui/icons-material/Close'
import { roles } from 'utils/permission'

interface Props {
  title: string
  role: string
  drawerName?: string
  closeDrawer?: () => void
  drawerData?: any
}

interface ICompany {
  id: string
  name: string
}

interface IOptionType {
  value: string
  label: string
}

const AddModerator = ({
  title,
  role,
  drawerName,
  drawerData,
}: Props): React.JSX.Element => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [dataState, dispatch] = useData()
  const [addingCompanyUser, setAddingCompanyUser] = useState(false)
  const [companyOptions, setCompanyOptions] = useState<ICompany[]>([])

  const [getCompanyOptions, { data: companyData, loading: companyLoading }] =
    useLazyQuery(GET_COMPANIES)

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])

  const { addUser, error, addUserLoading } = useAddUserServie({
    role,
    companyId: state.selectedCompany?.id,
    filter: {},
  })
  const [generalError, setGeneralError] = useState('')

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      consultantOfCompanies: [],
      jobTitle: '',
      location: '',
      gender: '',
      phoneFields: null as any,
      phoneFieldValue: null,
      birthDate: null as any,
      biography: '',
      note: '',
      avatar: '',
    },
    validationSchema: UserSchema,

    onSubmit(values) {
      if (addUserLoading) return

      const formValues: any = {
        ...values,
        requestPasswordChange: state.currentUser.settings.askPassword,
      }
      formValues.role = role

      delete formValues.phoneFieldValue

      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }

      formValues.gender = formValues.gender ? formValues.gender.value : null

      if (addingCompanyUser) {
        if (
          !values.consultantOfCompanies ||
          values.consultantOfCompanies.length === 0
        ) {
          setFieldError(
            'consultantOfCompanies',
            'Please select at least one company',
          )
          return
        }

        formValues.consultantOfCompanies = values.consultantOfCompanies.map(
          (item: { value: string }) => item.value,
        )
        formValues.role = roles.consultant
      } else formValues.role = roles.super_admin

      addUser(formValues, role, state.selectedCompany?.id, onSuccess)
    },
  })

  const closeDrawer = (): void =>
    DrawerEventEmitter.emit('openDrawer', { drawerName }, false)

  const handleCloseDrawer = (): void => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addModerator',
        values,
        compareTo: {},
      },
    })
  }

  const onSuccess = (): void => {
    closeDrawer()
    dispatch({ type: CLEAR_FORMDATA })
    drawerData.onSuccess()
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    setGeneralError('')
  }, [values.email])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  // useEffect(() => {
  //   if (data) {
  //     const allGroups = [...data.getAllGroups]
  //     allGroups.forEach(item => {
  //       item.value = item.name
  //       item.label = item.name
  //     })
  //     setGroups(allGroups)
  //   }
  // }, [data])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const [currentDate, setCurrentDate] = useState<Date | null>(null)

  const onDateChange = (date: Date | null): void => {
    // setCurrentDate(date)
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    if (values.birthDate) {
      setCurrentDate(
        new Date(
          typeof (values.birthDate === 'string')
            ? values.birthDate
            : +values.birthDate,
        ),
      )
    }
  }, [values.birthDate])

  if (
    state?.currentUser?.role === 'CONSULTANT' &&
    !state?.selectedCompany?.id
  ) {
    return (
      <>
        <DrawerHeader>
          <DrawerTitle>{title}</DrawerTitle>
          <IconButton onClick={handleCloseDrawer}>
            <HighlightOffRoundedIcon />
          </IconButton>
        </DrawerHeader>
        <DrawerContent headerHeight={68}>
          <NotAllowed>
            <p>Please switch to any company context to add Super Admin</p>
          </NotAllowed>
        </DrawerContent>
      </>
    )
  }

  return (
    <>
      {addUserLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              {/* <FormField >
                <PhoneCountryCode />
              </FormField > */}

              {/* <FormField>
                <AvatarInput
                  setFieldValue={setFieldValue}
                  initialAvatar={values.avatar}
                />
              </FormField> */}
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.first_name')}*`}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  size="small"
                  placeholder={`${t('user_details.first_name')}`}
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.last_name')}*`}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  size="small"
                  placeholder={`${t('user_details.last_name')}`}
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>

              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  title={`${t('user_details.email')}*`}
                  size="small"
                  placeholder={`${t('user_details.email')}`}
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <Title>{t('user_details.phone')}</Title>
                <PhoneCountryCode
                  // label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  // title={t('user_details.phone')}
                  selectValue={null}
                  selectChange={(e: IOptionType) =>
                    setFieldValue('phoneFields', e)
                  }
                  inputLabel="Phone"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={errors.phone || errors.phoneFields ? true : false}
                  errorMessage={
                    errors.phone || errors.phoneFields
                      ? errors.phone || errors.phoneFields
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>

              <FormField width="100%">
                <Title>{t('user_details.select_gender')}</Title>
                <SelectField
                  placeholder={t('user_details.select_gender')}
                  options={genderOptions}
                  // error={errors.gender && touched.gender ? true : false}
                  // errorMessage={errors.gender ? errors.gender : ''}
                  // touched={touched.gender}
                  value={values.gender}
                  onChange={(e: IOptionType) => setFieldValue('gender', e)}
                  isClearable
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('user_details.birthday')}</Title>
                <DatePicker
                  name="birthDate"
                  value={currentDate}
                  onChange={onDateChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.job_title')}`}
                  size="small"
                  placeholder={`${t('form_fields.job_title')}`}
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size="small"
                  placeholder={`${t('form_fields.location')}`}
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('user_details.biography')}`}
                  size="small"
                  placeholder={`${t('quiz_details.type_here')}`}
                  name="biography"
                  type="text"
                  value={values.biography}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('user_details.note')}`}
                  placeholder={`${t('user_details.note')}`}
                  size="small"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              {!state.currentUser?.companyId && !state.selectedCompany?.id && (
                <FormField width="100%">
                  <CustomSwitcher
                    onChange={(): void => {
                      setAddingCompanyUser(!addingCompanyUser)
                      if (!companyData) {
                        getCompanyOptions({
                          variables: {
                            currentPage: 0,
                            perPage: 0,
                          },
                        })
                      }
                      if (values.consultantOfCompanies) {
                        setFieldValue('consultantOfCompanies', [])
                      }
                    }}
                    label="Company Consultant"
                    name="consultantOfCompanies"
                    value={addingCompanyUser}
                  />
                </FormField>
              )}
              {addingCompanyUser && (
                <FormField
                  width="100%"
                  style={{
                    height: 320,
                  }}
                >
                  <AsyncSelect
                    error={errors.consultantOfCompanies}
                    touched={addingCompanyUser}
                    errorMessage={errors.consultantOfCompanies}
                    isMulti
                    onChange={(e: IOptionType): void => {
                      setFieldValue('consultantOfCompanies', e || [])
                    }}
                    loading={companyLoading}
                    data={companyOptions}
                    value={values.consultantOfCompanies}
                    labelDataKey="name"
                    valueDataKey="id"
                    label={t('notifications_layout.companies')}
                  />
                </FormField>
              )}
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={requestPasswordChange}
                    onChange={() => setChecked(!requestPasswordChange)}
                    value={requestPasswordChange}
                    color="primary"
                  />
                }
                label="Ask for a password change"
              /> */}
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddModerator
