import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useFetchUsers } from 'hooks/users/useUsers'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import useUpdateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'
import useSendPushNotifications from 'hooks/group/useSendPushNotifications'

import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArchiveIcon from '@mui/icons-material/Archive'

import { Wrapper } from '../styled-components'

import Drawer from 'components/common/Drawer'
import Grid from 'components/common/Grid'
// import { columnConfig } from 'components/UsersLayout/gridConfig'
import { userActionConfig, columnConfig } from './config'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import TablePagination from 'components/common/Pagination/TablePagination'
import { FilterStudents } from 'pages/students'
import { FilterCoaches } from 'pages/coaches'
import { FilterAdmins } from 'pages/admins'
import { FilterModerators } from 'pages/moderators'
import ResetUsersPassword from 'pages/profile/AllUserProfile/ResetPassword'
import Modal from 'components/common/Modal'
import SendPushNotification from 'components/SendPushNotification/SendPushNotification'
import CircleNotificationsIcon from '@mui/icons-material/Notifications'
import { TFunction } from 'interfaces/TFunction'
interface Params {
  variables: any
  currentTab: number
  t: TFunction
}

const CompanyUsers = ({ variables, currentTab, t }: Params) => {
  const { role, filter } = variables
  const defaultFilter = { ...filter }
  const navigate = useNavigate()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const { users, loading, refetch }: any = useFetchUsers(role, defaultFilter)
  const [pushNotificationModal, setPushNotificationModal] = useState(false)
  const [messageData, setMessageData] = useState<{
    heading: string
    body: string
  }>({
    heading: '',
    body: '',
  })

  const { sendPushNotifications } = useSendPushNotifications()
  const handleModalClose = () => {
    setPushNotificationModal(false)
    setTimeout(() => {
      setMessageData({
        heading: '',
        body: '',
      })
    }, 500)
  }

  const handlePushNotificationSend = async () => {
    const ids = [...selectedItem[currentPage]]
    if (ids.length <= 0) return false
    const data = await sendPushNotifications(ids, messageData)
    if (data) handleModalClose()
  }

  const { deleteUser } = useUserDeleteService({
    role,
  })

  const { updateUserStatus, updateUserStatusLoaing } =
    useUpdateStatusUserService({
      role,
    })

  const { updateUserPassword } = useResendUserService({
    role,
  })

  const { exportData } = useExportDataService()

  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [filterQuery, setFilterQuery] = useState<any>(defaultFilter)
  const [filterOptions, setFilterOptions] = useState<any>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [resetVisible, setResetVisible] = useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false)
  const [userId, setUserId] = useState('')

  let formattedRole = ''
  switch (role) {
    case 'ADMIN':
      formattedRole = 'Admin'
      break
    case 'SUPER_ADMIN':
      formattedRole = 'Super Admin'
      break
    case 'STUDENT':
      formattedRole = 'Student'
      break
    case 'COACH':
      formattedRole = 'Tutor'
      break
    default:
      formattedRole = ''
  }

  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = users.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const confirmDeleteMany = (ids: string[], role: string) => {
    deleteUser(ids, role, () =>
      refetch({
        role,

        filter: filterQuery,
        currentPage,
        perPage,
      }),
    )
    setSelectedItem([])
  }

  const confirmChangeStatusMany = (ids: string[], newStatus: string) => {
    updateUserStatus(
      ids.length > 0 ? ids : selectedItem[currentPage] || [],
      newStatus,
    )
    // setSelectedItem([])
  }

  const handleEditClick = (userId: string) => {
    let drawer = ''
    let drawerTitle = ''
    switch (role) {
      case 'ADMIN':
        drawer = 'editAdmin'
        drawerTitle = 'Edit Admin'
        break
      case 'SUPER_ADMIN':
        drawer = 'editModerator'
        drawerTitle = 'Edit Super Admin'
        break
      case 'STUDENT':
        drawer = 'editStudent'
        drawerTitle = 'Edit Student'
        break
      case 'COACH':
        drawer = 'editCoach'
        drawerTitle = 'Edit Tutor'
        break
      default:
        drawer = ''
    }
    DrawerEventEmitter.emit('openDrawer', drawer, true, {
      id: userId,
      role,
    })
  }

  const handleDeleteAllClick = () => {
    const roleText =
      role.toLowerCase().slice(-2) == 'ch'
        ? formattedRole + 'es'
        : formattedRole + 's'

    const selectedUsers = selectedItem[currentPage].map(
      (selectedItem: string) => {
        const usersToDelete = users.data.filter(
          (user: any) => user.id === selectedItem,
        )
        return usersToDelete[0]
      },
    )

    const selectedUsersWithGroup = selectedUsers.filter(
      (user: any) => user.group.length !== 0,
    )
    const params: any = {}

    if (selectedUsersWithGroup.length !== 0 && role === 'ADMIN') {
      fireSwal({
        title: `One of selected ${roleText} has group, so you cant delete it. first you have to remove group`,
        onConfirm: () => {
          navigate('/groups')
        },
        confirmText: 'Ok, go to groups!',
      })
    } else {
      fireSwal({
        title: `Are you sure you want to delete selected ${roleText}?`,
        onConfirm: () => confirmDeleteMany(selectedItem[currentPage], role),
        confirmText: 'Yes, delete!',
      })
    }
  }

  const handleDeleteClick = (user: any) => {
    let params: any = {}

    if (role === 'SUPER_ADMIN' && users.data.length <= 1) {
      params = {
        title:
          'You can`t delete this Super Admin because the company has to have at least one Super Admin in the System. ',
        onConfirm: () => {},
        confirmText: 'Ok',
      }

      fireSwal(params)

      return
    }

    if (role === 'ADMIN' && user.group.length !== 0) {
      params = {
        title: `You cant delete this ${role.toLocaleLowerCase()}, first remove group to delete this admin`,
        onConfirm: () => {
          navigate('/groups')
        },
        confirmText: 'Go to groups',
      }
    } else {
      params = {
        title: `Are you sure you want to delete this ${formattedRole}?`,
        onConfirm: () => {
          confirmDeleteMany([user.id], role)
        },
        confirmText: 'Yes, delete!',
      }
    }

    fireSwal(params)
  }

  const handleChangeStatusAllClick = (
    ids: string[],
    action: string,
    hasMany?: boolean,
  ) => {
    let title = ''
    let newStatus = ''
    let confirmText = ''
    const roleText = hasMany
      ? role.toLowerCase().slice(-2) == 'ch'
        ? formattedRole + 'es'
        : formattedRole + 's'
      : formattedRole

    if (action === 'suspend') {
      title = hasMany
        ? `Are you sure you want to Suspend selected ${roleText}?`
        : `Are you sure you want to Suspend this ${roleText}?`
      newStatus = 'SUSPENDED'
      confirmText = 'Yes, Suspend!'
    } else {
      title = hasMany
        ? `Are you sure you want to Activate selected ${roleText}?`
        : `Are you sure you want to Activate this ${roleText}?`
      newStatus = 'ACTIVE'
      confirmText = 'Yes, Activate!'
    }

    fireSwal({
      title,
      onConfirm: () => confirmChangeStatusMany(ids, newStatus),
      confirmText,
    })
  }

  const handleExportData = () => {
    if (selectedItem[currentPage].length > 0) {
      exportData(role, selectedItem[currentPage], (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const handleResendPasswordClick = (userId: string) => {
    fireSwal({
      title: `Are you sure you want to resend password for this ${formattedRole}?`,
      onConfirm: () => {
        updateUserPassword(userId)
      },
      confirmText: 'Yes, Resend Password!',
    })
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    refetch({
      role,
      filter: { ...filterQuery, ...defaultFilter },
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      role,
      filter: { ...filterQuery, ...defaultFilter },
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    let formData = { ...filterQuery }
    if (searchValue !== '') {
      formData = { ...filterQuery, ...formValue }
    } else {
      const { search, ...filters } = filterQuery
      formData = { ...filters }
    }
    refetch({
      role,
      filter: { ...formData, ...defaultFilter },
      currentPage: 1,
      perPage,
    })
    setFilterQuery(formData)
    setResetVisible(true)
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    if (currentTab) {
      setSelectedItem({})
      setSelectAll({})
      setSearchValue('')
      setFilterQuery(defaultFilter)
    }
  }, [currentTab])

  let field = ''

  switch (variables.role) {
    case 'MODERATOR':
      field = 'Moderator'
      break
    case 'STUDENT':
      field = 'Student'
      break
    case 'COACH':
      field = 'Tutor'
      break
    case 'ADMIN':
      field = 'Admin'
      break
    case 'SUPER_ADMIN':
      field = 'Super Admin'
      break
    default:
      break
  }

  const multiActions = () => {
    const actions: any = [
      {
        id: 0,
        color: 'secondary',
        tooltipText: t('actions.delete'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleDeleteAllClick(),
        component: <DeleteIcon fontSize="small" />,
      },
      {
        id: 1,
        color: 'secondary',
        tooltipText: t('actions.suspend'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleChangeStatusAllClick([], 'suspend', true),
        component: <BackspaceRoundedIcon fontSize="small" />,
      },
      {
        id: 2,
        color: 'primary',
        tooltipText: t('actions.activate'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleChangeStatusAllClick([], 'activate', true),
        component: <CheckCircleIcon fontSize="small" />,
      },
      {
        id: 10,
        color: 'primary',
        tooltipText: t('actions.export'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleExportData(),
        component: <ArchiveIcon fontSize="small" />,
      },
      {
        id: 3,
        color: 'primary',
        tooltipText: `${t('actions.send_push_notification')}`,
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => setPushNotificationModal(true),
        component: <CircleNotificationsIcon fontSize="small" />,
      },
    ].filter((i: any) => !i.admin)

    return actions.filter((i: any) => !i.hide)
  }

  if (loading) return <div>Loading...</div>

  const handleClick = () => {}
  const onGroupClick = () => {}

  const openAddDrawer = () => {
    let drawer = ''
    let drawerTitle = ''
    switch (role) {
      case 'ADMIN':
        drawer = 'addAdmin'
        drawerTitle = 'Add Admin'
        break
      case 'SUPER_ADMIN':
        drawer = 'addModerator'
        drawerTitle = 'Add Super Admin'
        break
      case 'STUDENT':
        drawer = 'addStudent'
        drawerTitle = 'Add Student'
        break
      case 'COACH':
        drawer = 'addCoach'
        drawerTitle = 'Add Tutor'
        break
      default:
        drawer = ''
    }
    DrawerEventEmitter.emit('openDrawer', drawer, true, {
      data: {
        title: drawerTitle,
        role,
        onClose: () => {
          DrawerEventEmitter.emit('openDrawer', drawer, false)
        },
        onSuccess: () => {
          refetch({
            role,
            filter: filterQuery,
            currentPage: 1,
            perPage,
          })
          setSelectedItem({})
          setSelectedItem({})
        },
      },
    })
  }
  const handleResetClick = () => {
    refetch({
      role,
      filter: defaultFilter,
      currentPage,
      perPage,
    })
    setResetVisible(false)
    setSearchValue('')
    setFilterQuery(defaultFilter)
    setFilterOptions(null)
  }

  const filterData = (filters: any) => {
    let formValue = {}
    if (searchValue !== '') {
      formValue = {
        search: {
          type: 'search',
          value: searchValue,
          fields: ['firstName', 'lastName', 'email', 'phone'],
        },
      }
    }
    refetch({
      role,
      filter: { ...filters, ...formValue, ...defaultFilter },
      currentPage: 1,
      perPage,
    })
    setResetVisible(true)

    if (users) {
      setFilterDrawerOpened(false)
      setFilterQuery({ ...filters, ...formValue, ...defaultFilter })
      setSelectedItem({})
    }
  }

  const renderFilterComponent = () => {
    if (role === 'STUDENT') {
      return (
        <FilterStudents
          role="STUDENT"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          useCompanyId
          inOnCompanyPage={true}
        />
      )
    }

    if (role === 'COACH') {
      return (
        <FilterCoaches
          role="COACH"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          useCompanyId
          forAssignCoaches={currentTab === 1 ? true : false}
        />
      )
    }

    if (role === 'SUPER_ADMIN') {
      return (
        <FilterModerators
          role={role}
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          hasSearchByCompany={false}
          useCompanyId
        />
      )
    }

    if (role === 'ADMIN') {
      return (
        <FilterAdmins
          role={role}
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          useCompanyId
        />
      )
    }
  }

  const redirectToProfile = (id: any) => {
    navigate(`/profile/${id}`)
  }

  const handleActivityClick = (id: string[]) => {
    const url = `/profile/${id}/activity`
    window.open(url, '_blank')
  }

  const groupAdminPolicy =
    state.currentUser.role !== 'ADMIN' ||
    (state.currentUser.role === 'ADMIN' && [6, 7].includes(currentTab))

  const config = columnConfig(
    variables.role,
    handleClick,
    selectItem,
    onGroupClick,
    t,
    !groupAdminPolicy || currentTab === 1,
    redirectToProfile,
  )
  const actions = userActionConfig(
    handleEditClick,
    handleDeleteClick,
    handleActivityClick,
    handleResendPasswordClick,
    handleChangeStatusAllClick,
    state.userPermission,
    t,
    field,
    state.currentUser.role,
    setResetPasswordOpen,
    setUserId,
  )

  return (
    <div style={{ paddingTop: 20 }}>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => {}}
        totalWidth="600px"
      >
        {renderFilterComponent()}
      </Drawer>
      <div style={{ marginBottom: 20 }}>
        <Wrapper justify="between" align="center">
          <Wrapper align="center">
            {groupAdminPolicy && (
              <>
                {currentTab === 4 && (
                  <Button
                    text={`Create ${field}`}
                    color="secondary"
                    onClick={openAddDrawer}
                    icon={<AddCircleRoundedIcon />}
                    background="#06C68F"
                  />
                )}
                <GridMultipleActions
                  selectedItems={
                    selectedItem[currentPage]
                      ? selectedItem[currentPage].length
                      : 0
                  }
                  actions={multiActions()}
                />
              </>
            )}
          </Wrapper>
          <form onSubmit={handleSearchSubmit}>
            <TextInput
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              disabled={loading}
              icon={
                <IconButton type="submit">
                  <SearchRoundedIcon onClick={handleSearchSubmit} />
                </IconButton>
              }
            />
          </form>
        </Wrapper>
      </div>
      <div>
        <Grid
          title={t('general.users')}
          onFiltersClick={() => setFilterDrawerOpened(true)}
          // resetVisible={resetVisible}
          resetVisible={true}
          resetFilters={handleResetClick}
          config={config}
          data={users.data}
          actionConfig={!groupAdminPolicy ? null : actions}
          selected={_.size(selectedItem[currentPage])}
          selectAllItem={selectAllItem}
          selectedItems={selectedItem[currentPage] || []}
          redirectToProfile={redirectToProfile}
        />

        {users && users.totalCount > 0 && (
          <TablePagination
            currentPage={users ? users.currentPage : 0}
            rowsPerPage={perPage}
            count={users ? users.totalCount : 0}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
      {isResetPasswordOpen && (
        <ResetUsersPassword
          isModalOpen={isResetPasswordOpen}
          setModalOpen={setResetPasswordOpen}
          userId={userId}
        />
      )}
      <Modal isOpened={pushNotificationModal} onClose={handleModalClose}>
        <SendPushNotification
          messageData={messageData}
          setMessageData={setMessageData}
          onClose={handleModalClose}
          onSend={handlePushNotificationSend}
        />
      </Modal>
    </div>
  )
}

export default CompanyUsers
