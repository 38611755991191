import styled from 'styled-components'

export const StyledTableContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
`

const PREFIX = 'IQTestsResult'

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
}

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.paper}`]: {
    width: '100%',
    marginBottom: '20px',
    padding: '0px 15px',
  },

  [`& .${classes.table}`]: {
    minWidth: 750,
    position: 'relative',
  },

  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))
