import React, { ChangeEvent, useState } from 'react'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { columnConfig } from 'pages/group/ImportGroupsToTests/gridConfig'
import DeleteIcon from '@mui/icons-material/Delete'
import { ImportStudentsInGroupsProps } from 'pages/group/ImportStudentsInGroups/importStudentsInGroups.interface'
import useExtractGroupsWithTests from './useExtractGroupsWithTestsServices'
import useImportGroupsWithTests from './useImportGroupsWithTestsServices'
import { Group, InsertGroupsWithTestsInput } from 'interfaces/groups'

const useImportGroupsServices = ({
  refetch,
  onClose,
}: ImportStudentsInGroupsProps) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [selectedGroups, setSelectedGroups] = useState<string[]>([])
  const [importedGroups, setImportedGroups] = useState<
    InsertGroupsWithTestsInput[] | Group[]
  >([])
  const [imported, setImported] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [atLeastOneSelectedError, setAtLeastOneSelectedError] = useState(false)

  const { uploadFileStream } = useExtractGroupsWithTests()
  const { importGroupsWithTests, loading: groupsImportLoading } =
    useImportGroupsWithTests()

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
      handleUploadFile(files[0])
    }
  }

  const handleUploadFile = (file: File) => {
    if (file) {
      uploadFileStream(file, (data: Group[]) => {
        setImportedGroups(data)
        setImported(false)
      })
    }
  }

  const addGroups = (
    selectedGroups: string[],
    groups: InsertGroupsWithTestsInput[],
  ) => {
    if (selectedGroups.length <= 0) return
    const data = groups.filter(
      (item) => selectedGroups.findIndex((val) => item.id === val) !== -1,
    )

    const groupsData = data.map((group) => {
      const { __typename, ...groupData } = group
      return groupData
    })

    importGroupsWithTests(
      groupsData,
      state.selectedCompany?.id,
      (data?: Group[]) => {
        if (data) {
          setImported(true)
          setImportedGroups(data.filter((group) => group?.error))
          setSelectedGroups([])
          setSelectAll(false)
          if (refetch) refetch()
          if (!data.find((item) => item.error)) {
            onClose()
          }
        }
      },
    )
  }

  const handleFormSubmit = () => {
    if (!imported) {
      addGroups(selectedGroups, importedGroups as InsertGroupsWithTestsInput[])
    }

    if (
      selectedGroups.find((groupId: string) =>
        importedGroups.find((group) => group.id === groupId && group.error),
      )
    ) {
      return
    }

    selectedGroups.length <= 0
      ? setAtLeastOneSelectedError(true)
      : setAtLeastOneSelectedError(false)
  }

  const handleDeleteGroups = () => {
    if (selectedGroups.length > 0) {
      const data = importedGroups.filter(
        (importedGroup) =>
          selectedGroups.findIndex(
            (selectedGroup) => importedGroup.id === selectedGroup,
          ) === -1,
      )
      setImportedGroups(data as InsertGroupsWithTestsInput[])
      setSelectedGroups([])
    }
  }

  const selectItem = (id: string) => {
    const selectedIndex = selectedGroups.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedGroups, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedGroups.slice(1))
    } else if (selectedIndex === selectedGroups.length - 1) {
      newSelected = newSelected.concat(selectedGroups.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedGroups.slice(0, selectedIndex),
        selectedGroups.slice(selectedIndex + 1),
      )
    }

    setSelectedGroups(newSelected)
  }

  const groupList = importedGroups || []

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = groupList.map((student) => student.id as string)
      setSelectedGroups(newArr)
      setSelectAll(true)
      return
    }
    setSelectedGroups([])
    setSelectAll(false)
  }

  const toolbarAction = [
    {
      id: 0,
      color: 'secondary',
      tooltipText: 'Delete',
      disabled: !(selectedGroups.length > 0),
      onClick: () => handleDeleteGroups(),
      component: <DeleteIcon fontSize="small" />,
    },
  ]

  const config = columnConfig(selectItem, imported, t)
  return {
    t,
    handleFileChange,
    selectedGroups,
    toolbarAction,
    imported,
    groupList,
    atLeastOneSelectedError,
    config,
    groupsImportLoading,
    selectAllItem,
    handleFormSubmit,
  }
}

export default useImportGroupsServices
