import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_GROUP } from 'gql/group/group.query'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/common/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { FormFooter, FormButtons } from 'components/common/Form'

import useAddStudentsInGroup from 'hooks/group/useAddStudentsInGroup'
import MyLoader from 'loading/loading'
import { SelectField } from 'components/common/SelectField'

const MoveToAnotherGroup = (props: any) => {
  const { t } = useTranslation()

  const [group, setGroup] = useState({
    name: '',
    value: '',
  })

  const { moveStudentsInGroup, loading: mutationLoading } =
    useAddStudentsInGroup(props.companyId)

  const { data, loading, refetch } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        _id: {
          type: 'arrayNotIn',
          value: props.studentGroups,
        },
        company: {
          type: 'exact',
          value: props.companyId,
        },
      },
      perPage: 0,
    },
  })

  const handleSelect = (e: any) => {
    setGroup(e)
  }

  const onFormSubmit = () => {
    if (!group.value) return
    moveStudentsInGroup(
      group.value,
      props.studentId,
      refetch,
      props.currentGroupId,
      props.studentListRefetch,
    )
  }

  const groupOptions = data?.getAllGroups?.data?.map((group: any) => ({
    label: group.name,
    value: group.id,
  }))

  return (
    <>
      {mutationLoading && (
        <MyLoader
          speed={1.3}
          inApp={true}
          backgroundColor={'rgb(169, 204, 194)'}
          width={400}
          height={100}
        />
      )}
      <DialogTitle id="form-dialog-title">
        {t('actions.move_to_another_group')}
      </DialogTitle>

      <DialogContent>
        {loading ? (
          t('general.loading')
        ) : (
          // <Dropdown
          //   value={group.name}
          //   label={`${t('form_fields.select')} ${t('general.group')}`}
          //   list={data.getAllGroups.data}
          //   dataKey="name"
          //   handelSelect={handleSelect}
          // />
          <SelectField
            placeholder={`${t('form_fields.select')} ${t('general.group')}`}
            options={groupOptions}
            value={group.name}
            onChange={handleSelect}
          />
        )}
      </DialogContent>

      <FormFooter>
        <FormButtons>
          <Button
            text={t('actions.cancel')}
            type="small"
            onClick={props.toggleDialog}
            background="#E0E1E2"
            textColor="#414141"
          />

          <Button
            text={t('actions.save')}
            isDisabled={!group.value || mutationLoading}
            type="small"
            onClick={onFormSubmit}
            color="secondary"
            background="#06C68F"
          />
        </FormButtons>
      </FormFooter>
    </>
  )
}

export default MoveToAnotherGroup
