import React from 'react'

const Activate = (props: any) => {
  return (
    <div>
      <h1>Activate Route</h1>TODO: Put Loader
    </div>
  )
}

export default Activate
