import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { SkilTestDetailsFieldTitle } from '../AddSkillTestVideoDrawer/styled-components'

import { SkillTestVideoSchema } from 'helpers/validationSchemas'
import useUpdateSkillTestVideoService from 'hooks/modules/useUpdateSkillTestVideo'

import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import TextEditor from 'components/common/TextEditor/TextEditor'

const initialValues = {
  title: '',
  description: '',
  tips: '',
}

const EditSkillTestVideoDrawer = ({ onClose, data }: any) => {
  const {
    updateSkillTestVideo,
    updateSkillTestVideoLoading,
  } = useUpdateSkillTestVideoService({ moduleId: data.moduleId })
  const { skillTestVideo } = data
  const {
    handleSubmit,
    setValues,
    setFieldValue,
    values,
    handleChange,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: SkillTestVideoSchema,
    onSubmit(values) {
      updateSkillTestVideo(data.skillTestVideo.id, values, () => {
        onClose()
        data.refetch()
      })
    },
  })

  useEffect(() => {
    if (skillTestVideo) {
      setValues({
        title: skillTestVideo.title,
        description: skillTestVideo.description,
        tips: skillTestVideo.tips,
      })
    }
  }, [skillTestVideo])

  return (
    <>
      {updateSkillTestVideoLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{'Edit Skill Test Video Template'}</DrawerTitle>
        <IconButton onClick={onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <TextInput
                  label="Title"
                  name="title"
                  type="text"
                  value={values.title}
                  onChange={handleChange}
                  error={errors.title ? true : false}
                  errorMessage={errors.title}
                  touched={touched.title}
                  icon={<></>}
                />
              </FormField>
              <FormField>
                <TextInput
                  label="Skill Test Video Overview"
                  name="description"
                  aria-label="minimum height"
                  multiline
                  rows="3"
                  value={values.description}
                  onChange={handleChange}
                  icon={<></>}
                />
              </FormField>
              <FormField>
                <SkilTestDetailsFieldTitle>
                  Skill Test Video Tips
                </SkilTestDetailsFieldTitle>
                <TextEditor
                  text={skillTestVideo.tips}
                  editorTitle="Skill Test Video Overview Text"
                  handleChange={(value: any) => setFieldValue('tips', value)}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text="Cancel"
                type="small"
                onClick={onClose}
                background="#E0E1E2"
                textColor="#414141"
              />
              <Button
                text="Save"
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditSkillTestVideoDrawer
