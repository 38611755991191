import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Wrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  background: #fff;
  border-radius: 14px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 7%), 0px 4px 5px 0px rgb(0 0 0 / 7%),
    0px 1px 10px 0px rgb(0 0 0 / 7%);
  height: 100%;
`
export const FooterWrapper = styled.div`
  width: 70%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const WizardContainer = styled.div`
  margin-top: 30px;
`
