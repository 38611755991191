import React, { ChangeEvent, useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { useFetchUsers, PER_PAGE } from 'hooks/users/useUsers'
import { roles } from 'utils/permission'
import { useTranslation } from 'react-i18next'

import { LowerCaseFilterableFieldType } from 'enums/filterEnum'

import useAddStudentsInGroup from 'hooks/group/useAddStudentsInGroup'
import { useSwal } from 'hooks/useSwal'
import {
  ISelectAll,
  ISelectedItem,
} from 'components/common/FilterInterface/filter.interface'
import {
  IAddStudentInGroupDrawerDefaultFilter,
  IAddStudentInGroupDrawerProps,
  IAddStudentInGroupDrawerResponse,
} from './useAddStudentInGroupDrawer.interface'
import { FilterableNestedField } from 'interfaces/common'
import { User } from 'interfaces/users'
import { columnConfig } from 'pages/group/GroupStudents/gridConfig'

const useAddStudentInGroupDrawer = ({
  groupId,
  companyId,
  groupName,
  studentInGroupRefetch,
  onClose,
}: IAddStudentInGroupDrawerProps): IAddStudentInGroupDrawerResponse => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<{
    [key: string]: FilterableNestedField
  }>({})

  const defaultFilter: IAddStudentInGroupDrawerDefaultFilter = {
    group: {
      type: LowerCaseFilterableFieldType.NESTED_ARRAY_NOT_IN,
      value: [groupId],
      nestedField: 'groupId',
    },
  }
  if (companyId !== undefined) {
    defaultFilter.companyId = {
      type: LowerCaseFilterableFieldType.EXACT,
      value: companyId,
    }
  }

  const { loading: addStudentsLoading, addStudentsInGroup } =
    useAddStudentsInGroup(companyId)

  const { users, loading, refetch } = useFetchUsers(
    roles.student,
    defaultFilter,
  )

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = users.data.map((n: User) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const onFormSubmit = (): void => {
    if (!selectedItem || !selectedItem[currentPage]?.length) {
      onClose()
      return
    }

    addStudentsInGroup(
      groupId,
      groupName,
      selectedItem[currentPage],
      () => {
        refetch()
        onClose()
      },
      studentInGroupRefetch,
    )
  }

  const handleDrawerClose = (): void => {
    if (selectedItem[currentPage] && selectedItem[currentPage].length > 0) {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          onClose()
        },

        confirmText: 'Yes, Cancel!',
        cancelText: 'No',
      })
    } else {
      onClose()
    }
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    refetch({
      role: 'STUDENT',
      filter: { ...defaultFilter, ...searchFilter },
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    refetch({
      role: 'STUDENT',
      filter: { ...defaultFilter, ...searchFilter },
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  const debouncedRefetch = useCallback(
    debounce((newSearchValue: string) => {
      const formValue = {
        search: {
          type: 'search',
          value: newSearchValue,
          fields: ['firstName', 'lastName', 'email', 'phone'],
        },
      }
      setSearchFilter(formValue)
      const filterData = { ...defaultFilter, ...formValue }

      refetch({
        role: 'STUDENT',
        filter: filterData,
        currentPage: 1,
        perPage,
      })
    }, 500),
    [],
  )

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const searchInput = e.target.value
    setSearchValue(searchInput)
    debouncedRefetch(searchInput)
  }

  const config = columnConfig(selectItem, t)

  return {
    t,
    handleDrawerClose,
    searchValue,
    handleSearchChange,
    loading,
    users,
    config,
    selectAllItem,
    selectItem,
    selectedItem,
    currentPage,
    perPage,
    handlePaginationClick,
    handleChangeRowsPerPage,
    onFormSubmit,
    addStudentsLoading,
  }
}

export default useAddStudentInGroupDrawer
