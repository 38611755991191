import styled from 'styled-components'

export const DrawerContainer = styled.div``

export const DrawerHeaderSteps = styled.header`
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid rgba(81, 246, 5, 0.1);
`

export const DrawerHeader = styled.header`
  padding: 10px 20px;
  background: #ffffff;
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 13px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`
export const DrawerTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
`

export const DrawerStepsTitle = styled.h2`
  font-size: 2em;
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
`

export const DrawerContent = styled.div<{ headerHeight?: number }>`
  position: relative;
  height: ${({ headerHeight }) =>
    headerHeight ? `calc(100% - ${headerHeight}px)` : `calc(100% - 68px)`};
`
export const Title = styled.h2`
  font-size: 16px;
  font-weight: 550;
  margin-bottom: 5px;
`
