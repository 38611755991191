import { useMutation } from '@apollo/client'

import { EXTRACT_GROUPS_WITH_COURSES } from 'gql/group/group.query'

const extractGroupsWithCoursesService = () => {
  const [singleUploadStream] = useMutation(EXTRACT_GROUPS_WITH_COURSES)

  return {
    singleUploadStream,
  }
}

export default extractGroupsWithCoursesService
