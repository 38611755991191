import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
`

export const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
`
export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`

export const CourseDetailsLeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 600px;
  height: 100%;
  width: 25%;
`

export const CourseDetailsRightPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 70px;
  width: 100%;
  height: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  border-radius: 28px;
  position: relative;
  padding: 40px 50px;
  background: #fff;
  box-shadow: 0px 0px 29px 5px rgba(0, 0, 0, 0.03) !important;
  height: 100%;
  min-height: 800px;

  @media (max-width: 1476px) {
    padding: 10px 20px;
  }
`

export const CourseDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: calc(100vh - 120px);
`

export const StyledCourseDetailsFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const StyledCourseDetailsLeft = styled.div`
  width: 100%;
  height: 100%;

  .DraftEditor-root {
    padding: 0;
    margin-top: 10px;
  }
`
export const StyledCourseDetailsLeftParent = styled.div`
  border-right: 1px solid #e7e9ed;

  width: 90%;
  height: auto;
  padding: 16px 16px;
`
export const StyledCourseDetailsBetweenParent = styled.div`
  width: 90%;
  height: 90%;
  padding: 16px 16px;
`

export const CourseContentDetailsRight = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  border-left: 1px solid #e7e9ed;
  /* padding: 16px 16px; */
  gap: 20px;
  width: 100%;
`

export const StyledCourseDetailsSkillsParent = styled.div`
  margin-bottom: 20px;
  width: 90%;
  height: 90%;
  padding: 16px 16px;
`

export const CourseContentDetailsContainer = styled.div`
  margin-top: 10px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const CourseContentDetailsContainerFlex = styled.div`
  display: flex;
  height: 100%;
`

export const CourseContentDetails = styled.ul`
  display: flex;
  height: 152px;
  width: 100%;
  gap: 100px;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  height: 100%;
`

export const StyledInformationTabContainer = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  padding: 16px;
  height: 300px;
`
export const StyledCompaniesContainer = styled.div`
  width: 100%;
  padding-left: 16px;
`
export const StyledCompanyContainer = styled.div`
  display: flex;
  padding-top: 10px;
`

export const StyledCompanySpan = styled.span`
  border-radius: 4px;
  background-color: #e7e9ed;
  height: 32px;
  padding: 12px 12px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #68707a;
`

export const StyledSkillsContainer = styled.div`
  width: 100%;
  border-right: 1px solid #e7e9ed;
`

export const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
}

export const ButtonsWrapper = styled.div`
  display: inline-flex;
  column-gap: 5px;
`

export const AI_assistantWrapper = styled.div<{ bgColor: string }>`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px 12px;
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor};
  color: #fff;
`
