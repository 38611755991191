import React from 'react'

import styled from 'styled-components'

export const Toolbar = (props: any) => {
  return (
    <ToolbarContainer style={{ ...props.styles }}>
      {props.children}
    </ToolbarContainer>
  )
}

export const ToolbarItem = (props: any) => (
  <ToolbarItemContainer ml={props.ml} {...props}>
    {props.children}
  </ToolbarItemContainer>
)

const ToolbarContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 20px 0;
`
const defs = (args: any) => {
  const styles: any = {}
  if (args.ml) {
    styles.marginLeft = args.ml
  }

  return styles
}
const ToolbarItemContainer = styled.div<{ ml: String }>`
  display: flex;
  gap: 10px;
  align-items: center;
  ${defs}
`
