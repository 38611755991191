import { useMutation } from '@apollo/client'

import { DELETE_QUESTIONS } from 'gql/questions.query'

const deleteQuestionsService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteQuestionsMutation, { loading, error }] = useMutation(
    DELETE_QUESTIONS,
  )

  return {
    deleteQuestionsMutation,
    loading,
    error,
  }
}

export default deleteQuestionsService
