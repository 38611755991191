import React, { useState } from 'react'
import _ from 'lodash'

import FilterDrawer from 'pages/questions/FilterDrawer'
import { Grid as QuestionGrid } from 'pages/questions'
import { StepHeader, Wrapper, StepTitle } from './styled-components'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import TextInput from 'components/common/TextInput/TextInput'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ViewListIcon from '@mui/icons-material/ViewList'
import SyncIcon from '@mui/icons-material/Sync'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from 'components/common/Checkbox'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Drawer from 'components/common/Drawer'

const Step2 = (props: any) => {
  const [gridStyle, setGridStyle] = useState<string>('card')
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>({})
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filteredQuestionList, setFilteredQuestionList] = useState<any>(null)

  const selectItem = (id: never, event: boolean) => {
    const selectedIndex = props.selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedItem.slice(1))
      props.setSelectAll(false)
    } else if (selectedIndex === props.selectedItem.length - 1) {
      newSelected = newSelected.concat(props.selectedItem.slice(0, -1))
      props.setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedItem.slice(0, selectedIndex),
        props.selectedItem.slice(selectedIndex + 1),
      )
      props.setSelectAll(false)
    }

    props.setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!props.selectAll) {
      const questionList = filteredQuestionList
        ? filteredQuestionList
        : props.questionList
      const newArr: string[] = questionList.map((n: any) => n.id)
      props.setSelectedItem(newArr)
      props.setSelectAll(true)
      return
    }
    props.setSelectedItem([])
    props.setSelectAll(false)
  }

  const handleResetClick = () => {
    setFilteredQuestionList(null)
    setFiltered(false)
    setFilterData({})
  }

  const list = props.questionList || []

  const { t } = props
  return (
    <div style={{ padding: '15px' }}>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => {}}
        totalWidth="600px"
      >
        <FilterDrawer
          courseId={props.type === 'course' ? props.id : null}
          closeDrawer={() => setFilterDrawerOpened(false)}
          filterData={filterData}
          setFilterData={setFilterData}
          quizModel={props.type}
          currentPage={1}
          handleFilterClick={(filterOptions: any) =>
            props.handleFilterClick(filterOptions)
          }
          setFiltered={() => setFiltered(true)}
          questions={props.questionList}
          setFilteredQuestionList={setFilteredQuestionList}
          t={t}
        />
      </Drawer>
      <StepHeader className={'withBorder'}>
        <Wrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StepTitle>{t('questions_layout.title')}</StepTitle>

            <GridMultipleActions
              selectedItems={props.selectedItem.length}
              actions={[]}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    list.length > 0 && list.length === props.selectedItem.length
                      ? true
                      : false
                  }
                  disabled={list.length === 0}
                  onChange={selectAllItem}
                />
              }
              label={
                list.length === props.selectedItem.length
                  ? t('general.diselect_all')
                  : t('general.select_all')
              }
            />
          </div>

          <div style={{ display: 'flex' }}>
            <MultipleAction>
              <IconButton
                disabled={filtered ? false : true}
                onClick={handleResetClick}
              >
                <SyncIcon fontSize="small" />
              </IconButton>
              <MultipleActionTooltip>
                {t('actions.reset')}
              </MultipleActionTooltip>
            </MultipleAction>
            <MultipleAction>
              <IconButton onClick={() => setFilterDrawerOpened(true)}>
                <FilterIcon />
              </IconButton>
              <MultipleActionTooltip>
                {t('actions.filter')}
              </MultipleActionTooltip>
            </MultipleAction>
            <form
              style={{ marginRight: '20px' }}
              onSubmit={props.handleSearchSubmit}
            >
              <TextInput
                label={t('general.search_placeholder')}
                type="text"
                size="small"
                value={props.searchValue}
                onChange={props.handleSearchChange}
                icon={
                  <IconButton type="submit">
                    <SearchRoundedIcon />
                  </IconButton>
                }
                iconInStart
              />
            </form>

            <GridSwitchButtons>
              <button
                type="button"
                className={`${gridStyle === 'card' ? 'active' : ''}`}
                onClick={() => setGridStyle('card')}
              >
                <DashboardIcon />
              </button>
              <button
                type="button"
                className={`${gridStyle === 'list' ? 'active' : ''}`}
                onClick={() => setGridStyle('list')}
              >
                <ViewListIcon />
              </button>
            </GridSwitchButtons>
          </div>
        </Wrapper>
      </StepHeader>

      <QuestionGrid
        gridStyle={gridStyle}
        cols={2}
        data={list}
        selectItem={selectItem}
        selectedItems={props.selectedItem}
        questionIds={props.filter}
        openInExternalTab={true}
        t={t}
      />
    </div>
  )
}

export default Step2
