import Modal from '@mui/material/Modal'
import { Button } from 'components/common/Button'
import 'cropperjs/dist/cropper.css'
import useOutsideClick from 'helpers/useOutsideClick'
import { CompanyImage } from 'pages/companies/CompanyDetails/styled-components'
import React, { useRef, useState, useEffect, useCallback } from 'react'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'
import {
  AvatarSettingContainer,
  AvatarMenu,
  StyledAvatarWrapper,
  AvatarMenuItem,
  ImageCropperContainer,
  EditIconContainer,
  Root,
  ModalContent,
} from './styled-components'
import Loader from '../Loader'
import EditIcon from '@mui/icons-material/Edit'
import ProfileIcon from '../../../pages/profile/AdminProfile/icons/profile-picture.svg'

const cropper: any = React.createRef() as any

export const EditableAvatar: React.FC<{
  finishCrop: (
    action: any,
    crudFunction: (isFinished: boolean) => void,
    cropper: any,
  ) => void
  img?: string
  isRectangular?: boolean
  hasResponseReceived?: boolean
  isForEverySize?: boolean
}> = ({
  finishCrop,
  img,
  isRectangular,
  hasResponseReceived,
  isForEverySize,
}) => {
  const { t } = useTranslation()

  const [avatarMenu, setAvatarMenu] = useState(false)
  const [cropperOpen, setCropperOpen] = useState(false)
  const [file, setFile] = useState<any>(null)
  const ref: React.RefObject<HTMLDivElement> =
    useRef() as React.RefObject<HTMLDivElement>

  const [loading, setLoading] = useState(false)
  const [loadingForDelete, setLoadingForDelete] = useState(false)
  const [response, setResponse] = useState(false)

  useEffect(() => {
    // loading && setLoading(!hasResponseReceived)
    loadingForDelete && setLoadingForDelete(!hasResponseReceived)
  }, [hasResponseReceived, loading, loadingForDelete])

  useOutsideClick(ref, () => {
    if (avatarMenu) setAvatarMenu(false)
  })

  const toggleAvatarMenu = () => {
    setAvatarMenu(!avatarMenu)
  }

  const handleAvatarChange = (e: any) => {
    const { files } = e.target
    const reader = new FileReader()
    setLoading(false)

    reader.onload = () => {
      const img: HTMLImageElement = new Image() as HTMLImageElement
      if (typeof reader.result === 'string') {
        img.src = reader.result
        setFile(reader.result)
      }
      setCropperOpen(true)
      setAvatarMenu(false)
    }
    reader.readAsDataURL(files[0])
  }

  const cropperModalToggle = () => {
    setCropperOpen(!cropperOpen)
  }

  const crudFunction = (isFinished: boolean) => {
    setCropperOpen(false)
    setAvatarMenu(false)
    setResponse(isFinished)
  }

  const onSave = useCallback(() => {
    setLoading(true)
    finishCrop('update', crudFunction, cropper)
    setFile(null)
  }, [])

  return (
    <Root>
      <AvatarSettingContainer ref={ref}>
        {isRectangular ? (
          <CompanyImage onClick={toggleAvatarMenu}>
            <img src={img} alt="" />
          </CompanyImage>
        ) : (
          <StyledAvatarWrapper>
            <img src={img || ProfileIcon} alt="" />
            <EditIconContainer onClick={toggleAvatarMenu}>
              <EditIcon style={{ fontSize: 18, color: '#989898' }} />
            </EditIconContainer>
          </StyledAvatarWrapper>
        )}

        {avatarMenu && (
          <AvatarMenu>
            <AvatarMenuItem>
              <input
                type="file"
                name="file"
                id="file"
                onChange={handleAvatarChange}
                multiple
              />
              <label htmlFor="file">{t('actions.upload_avatar')}</label>
            </AvatarMenuItem>

            {(!!file || (!file && !!img)) && (
              <AvatarMenuItem
                onClick={() => {
                  setLoadingForDelete(true)
                  finishCrop('delete', crudFunction, cropper)
                  setFile(null)
                }}
              >
                {t('actions.delete_avatar')}
              </AvatarMenuItem>
            )}
          </AvatarMenu>
        )}
      </AvatarSettingContainer>
      {/* {loadingForDelete && <Loader />} TODO: this is not working properly because cant update response */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={cropperOpen}
        onClose={cropperModalToggle}
        closeAfterTransition
      >
        <ModalContent>
          <ImageCropperContainer>
            {loading && <Loader />}
            <Cropper
              ref={cropper}
              src={file}
              style={{ height: 500, width: 600 }}
              aspectRatio={isForEverySize ? undefined : 16 / 16}
              guides={false}
            />
            <div className="actions">
              <Button
                color="primary"
                onClick={cropperModalToggle}
                background="#E0E1E2"
                textColor="#414141"
                text={t('actions.cancel')}
                type="small"
              />
              <Button
                //   variant="contained"
                color="primary"
                onClick={onSave}
                background="#06C68F"
                text={t('actions.save')}
                type="small"
              />
            </div>
          </ImageCropperContainer>
        </ModalContent>
      </Modal>
    </Root>
  )
}
