import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import setSkillTestVideoCriteriaService from 'services/modules/setSkillTestVideoCriteria'

const useSetSkillTestVideoCriteriaService = (params: { moduleId: string }) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const { setSkillTestVideoCriteriaMutation } =
    setSkillTestVideoCriteriaService(params)

  const setSkillTestVideoCriteria = async (
    moduleId: string,
    criterias: {
      criteria: string
      orderIndex: number
    }[],
    idsToRemain: string[],
    onSuccess?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors } = await setSkillTestVideoCriteriaMutation({
      variables: {
        moduleId,
        criterias,
        idsToRemain,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      if (onSuccess) onSuccess()
      message = `${t('general.stvcriterias')} ${t('messages.added')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    setSkillTestVideoCriteria,
    setSkillTestVideoCriteriaLoading: loading,
    error,
  }
}

export default useSetSkillTestVideoCriteriaService
