import {
  CourseCompleteCriteriaEnums,
  IRenderContentItem,
} from 'pages/courses/courseList.interface'
import {
  EditCourseDrawerProps,
  EditCourseDrawerReturn,
} from './editCourseDrawer.interface'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, useEffect, useState } from 'react'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'
import { useData } from 'context/DataContext'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { DrawerEventEmitter } from 'helpers/drawer'
import { SET_FORMDATA } from 'store/types'
import axios from 'axios'
import { string as YupString } from 'yup'

import {
  useUploadImage,
  useGenerateUploadVideoLinks,
  useUploadFile,
  useUploadCaptions,
} from 'hooks/helpers/useHelpersService'
import { useEditCourse } from 'hooks/course/useEditCourse'
import { CHECK_COURSE_NAME, GET_COURSE } from 'gql/course/course.query'
import { GET_ALL_SKILLS } from 'gql/skilss.query'
import { useUserValue } from 'context/UserContext'
import excludeKeys from 'helpers/excludeKeys'
import { Option } from 'components/common/SelectDropDown/selectDropDown.interface'
import { CourseSchema } from 'helpers/validationSchemas'
import { User } from 'interfaces/users'

const useEditCourseDrawer = ({
  id,
}: EditCourseDrawerProps): EditCourseDrawerReturn => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const [currentCourse, setCurrentCourse] = useState<IRenderContentItem | null>(
    null,
  )
  const [buttonEvent, setButtonEvent] = useState('')
  const [introVideo, setIntroVideo] = useState<File | null>(null)
  const [progress, setProgress] = useState<number>(0)
  const [videoLinks, setVideoLinks] = useState<string>('')
  const [cropperOpen, setCropperOpen] = useState(false)
  const [firstFetch, setFirstFetch] = useState(true)

  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadFile, fileLoading } = useUploadFile()
  const { uploadCaptions } = useUploadCaptions()

  const { data: course, loading } = useQuery(GET_COURSE, {
    variables: { courseId: id },
  })
  const { data: skillsData } = useQuery(GET_ALL_SKILLS)

  const { editCourse, editCourseLoading } = useEditCourse({
    courseId: id,
  })

  const [checkName] = useMutation(CHECK_COURSE_NAME)

  const [validationData, setValidationData] =
    useState<typeof CourseSchema>(CourseSchema)
  const [uploadedVideoName, setUploadedVideoName] = useState<string>('')
  const [isSubtitlesUpdated, setIsSubtitlesUpdated] = useState(false)
  const [isVideoUpdated, setIsVideoUpdated] = useState(false)
  const courseData = course && course.getCourseById

  const formData: IRenderContentItem = {
    name: '',
    description: '',
    editorText: '',
    level: null,
    coursePrivacy: '',
    coachType: '',
    coaches: [],
    skills: [],
    groups: [],
    avatar: null,
    price: '',
    currency: '',
    video: null,
    contentLocked: false,
    completeCriteria: null,
    certificateIncluded: false,
  }
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    async onSubmit(values) {
      // validation imported from validationSchema
      // because Yup is working only first time
      if (!values.name) {
        errors.name = `${t('general.name')} ${t('validations.required')}`
        return
      }
      if (!values.avatar) {
        errors.avatar = `${t('general.cover_image')} ${t(
          'validations.required',
        )}`
        return
      }

      if (buttonEvent === 'save') {
        // if it's different name, check if it already exists
        if (courseData.name !== values.name) {
          const exists = await checkName({
            variables: { name: values.name },
          })

          if (exists && exists.errors) {
            console.log('exists')
            errors.name = t('validations.courseNameExists')
            return
          }
        }
      }

      const data = removeTypeNameFromObject({ ...values })

      data.skills = data.skills.map((skill: Option) => {
        return { label: skill.label }
      })

      data.completeCriteria = data.completeCriteria?.value

      if (data.video) {
        const args = excludeKeys(data.video, ['__typename'])
        data.video = {
          id: args.id,
          title: args.title,
        }
      }

      if (!data.price) {
        data.price = null
        data.currency = ''
      } else {
        data.price = parseFloat(data.price)
      }

      if (data.level) data.level = data.level.value

      data.coaches =
        data.coaches && data.coaches.length > 0
          ? data.coaches.map((item: Option) => item.value)
          : []

      delete data.groups
      data.finished = buttonEvent === 'next' || buttonEvent === 'save' ? 3 : 2

      data.isSubtitlesUpdated = isSubtitlesUpdated
      data.isVideoUpdated = isVideoUpdated

      editCourse(id, data, (): void => {
        if (buttonEvent === 'save') {
          closeDrawer()
        }
      })
    },
  })

  useEffect((): void => {
    setValidationData({
      ...validationData,
      currency: values.price
        ? YupString().required(
            `${t('form_fields.currency')} ${t('validations.required')}`,
          )
        : YupString(),
    })
  }, [values.price])

  const uploadVideoService = (url: string): Promise<void> => {
    setUploadedVideoName(url.split('/')[4].split('.')[0])
    if (!introVideo) return Promise.resolve()
    return axios.put(url, introVideo, {
      headers: {
        'Content-Type': introVideo.type,
      },
      onUploadProgress: ({ total, loaded }) => {
        setProgress((loaded / total) * 100)
      },
    })
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: File): void => {
    uploadImage(
      croppedFile,
      field === 'avatar' ? 'course/avatar' : 'course/certificate',
      (link: string) => setFieldValue(field, link),
    )
  }

  const closeDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'editCourse', false)
  }

  const handleCloseDrawer = (): void => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editCourse',
        values,
        compareTo: currentCourse,
      },
    })
  }

  const handleGenerateUploadLink = (): void => {
    if (!introVideo) return
    const videos = [{ fileName: introVideo.name, type: introVideo.type }]
    generateUploadVideoLinks(
      videos,
      'course',
      uploadVideoService,
      (args) => {
        setFieldValue('video', args.video)
        setFieldValue('video.title', args.video.name)
        setProgress(0)
      },
      true,
    )
  }

  useEffect((): void => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect((): void => {
    if (introVideo) {
      handleGenerateUploadLink()
    }
  }, [introVideo])

  useEffect((): void => {
    if (course && course.getCourseById && firstFetch) {
      const courseData = course.getCourseById
      const dataClone: any = {}

      for (const key in formData) {
        if (courseData[key]) {
          if (key === 'skills' && courseData[key]) {
            dataClone[key] = courseData[key].map((i: Option) => ({
              label: i.label,
              value: i.label,
            }))
          }

          if (key === 'coaches' && courseData[key]) {
            dataClone[key] = courseData[key].map((i: User) => ({
              label: `${i.firstName} ${i.lastName}`,
              value: i.userId,
            }))
          }

          if (!['skills', 'coaches'].includes(key)) {
            dataClone[key] = courseData[key]
          }
        }

        dataClone.coursePrivacy = state.selectedCompany
          ? dataClone.coursePrivacy
          : 'public'
      }

      if (dataClone.level) {
        dataClone.level = {
          label: t(`courses_layout.${dataClone.level}`),
          value: dataClone.level,
        }
      }

      if (dataClone.completeCriteria) {
        dataClone.completeCriteria = {
          label: t(`course_complete_criterias.${dataClone.completeCriteria}`),
          value: dataClone.completeCriteria,
        }
      }

      setValues(dataClone)
      setCurrentCourse(dataClone)
      setFirstFetch(false)
    }
  }, [course])

  const handleVideoChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsVideoUpdated(true)
    if (e?.target?.files) setIntroVideo(e.target.files[0])
    else {
      setIntroVideo(null)
      setFieldValue('video', null)
      setProgress(0)
    }
  }

  const handleUploadFile = (
    e: ChangeEvent<HTMLInputElement>,
    field: string,
  ): void => {
    const { files } = e.target
    if (!files) return
    const file = files[0]

    if (field === 'subtitle') {
      setIsSubtitlesUpdated(true)
      uploadCaptions(file, `${uploadedVideoName}`, (link: string) => {
        setFieldValue(field, {
          name: file.name,
          link,
          fileType: file.name.split('.').pop(),
        })
      })
    } else {
      uploadFile(file, `course/${field}`, (link: string) => {
        setFieldValue(field, {
          name: file.name,
          link,
          fileType: file.name.split('.').pop(),
        })
      })
    }
  }

  const skillList =
    skillsData &&
    skillsData.getAllSkills.map((i: Option) => ({
      label: i.label,
      value: i.label,
    }))

  const handleButtonClick = (e: string): void => {
    setButtonEvent(e)
    handleSubmit()
  }

  const courseCompanyId =
    (courseData && courseData.companyId && courseData.companyId.id) || null

  return {
    values,
    errors,
    touched,
    handleChange,
    handleVideoChange,
    videoLinks,
    setVideoLinks,
    progress,
    setProgress,
    introVideo,
    setIntroVideo,
    setFieldValue,
    isCompanyMode: Boolean(state.selectedCompanyId),
    skillList,
    uploadImage,
    uploadFile,
    imageLoading,
    handleUploadFile,
    fileLoading,
    courseData,
    handleButtonClick,
    handleCloseDrawer,
    courseCompanyId,
    t,
    loading,
    editCourseLoading,
    cropperOpen,
    cropperModalToggle,
    handleCropSave,
  }
}

export default useEditCourseDrawer
