import React from 'react'
import _ from 'lodash'
import {
  GridToolbar,
  GridLayoutActions,
} from 'components/common/Grid/styled-components'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import Loader from 'components/common/Loader'
import TextInput from 'components/common/TextInput/TextInput'
import Grid from 'components/common/GridV2'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import ResetUsersPassword from 'pages/profile/AllUserProfile/ResetPassword'
import Modal from 'components/common/Modal'
import SendPushNotification from 'components/SendPushNotification/SendPushNotification'
import {
  FlexComponent,
  LayoutWrapper,
  StyledPagination,
} from './styled-components'
import { ToolbarItem } from 'components/common/Toolbar'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import Breadcrumb from 'components/common/BreadCrumbV2'
import PaginationV2 from 'components/common/PaginationV2'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import useStudentsLayout from 'hooks/students/useStudentsLayout'
import Drawer from 'components/common/Drawer'
import FilterStudents from './FilterStudents/FilterStudents'
import { SplashScreen } from 'components/common'
import { StudentsActionsContainer } from 'components/common/GridV2/styled-components'

const StudentsLayout = ({
  showBreadcrumb = true,
  groupId = '',
  courseId,
}: {
  courseId?: string
  groupId?: string
  showBreadcrumb?: boolean
}) => {
  const {
    breadCrumbData,
    t,
    createButton,
    selectedItem,
    currentPage,
    multiActions,
    setFilterDrawerOpened,
    searchValue,
    handleSearchChange,
    deleteUserLoading,
    updateUserStatusLoaing,
    users,
    resetVisible,
    handleClick,
    config,
    actions,
    selectAllItem,
    selectItem,
    perPage,
    handleChangeRowsPerPage,
    handlePaginationClick,
    isResetPasswordOpen,
    setResetPasswordOpen,
    userId,
    pushNotificationModal,
    handleModalClose,
    messageData,
    setMessageData,
    handlePushNotificationSend,
    filterDrawerOpened,
    ROLE,
    filter,
    state,
    loading,
    setFilterOptions,
    filterOptions,
    isFiltered,
    companyId,
    isSearchType,
  } = useStudentsLayout({ groupId, courseId })

  if (
    users &&
    companyId &&
    !searchValue &&
    !isSearchType &&
    !filterOptions &&
    users?.data.length <= 0
  ) {
    return (
      <>
        <Breadcrumb data={breadCrumbData} />
        <SplashScreen
          title={t('user_details.no_students')}
          subTitle={t('user_details.create_students')}
          createButton={createButton}
        />
      </>
    )
  }

  return (
    <>
      {showBreadcrumb && <Breadcrumb data={breadCrumbData} />}
      <LayoutWrapper>
        <Drawer
          opened={filterDrawerOpened}
          toggleDrawer={(): void => {}}
          totalWidth="600px"
        >
          <FilterStudents
            role={ROLE}
            closeDrawer={(): void => setFilterDrawerOpened(false)}
            filter={filter}
            filterLoading={loading}
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            hasSearchByCompany
            manualCompanyId={state?.currentUser?.companyId?.id}
            useCompanyId={
              state?.currentUser?.companyId &&
              state.currentUser.companyId !== null
            }
          />
        </Drawer>
        <GridToolbar>
          <GridLayoutActions>
            <div>{createButton()}</div>
            <GridMultipleActions
              selectedItems={
                selectedItem[currentPage] ? selectedItem[currentPage].length : 0
              }
              actions={multiActions()}
            />
          </GridLayoutActions>
          <FlexComponent>
            <ToolbarItem>
              <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                {isFiltered ? (
                  <RedFilterIcon color="#06C68F" />
                ) : (
                  <FilterIcon />
                )}
              </IconButton>
            </ToolbarItem>
            <TextInput
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type="submit">
                  <SearchRoundedIcon />
                </IconButton>
              }
              iconInStart
              name="search"
            />
          </FlexComponent>
        </GridToolbar>
        <div
          style={{
            position: 'relative',
          }}
        >
          {(deleteUserLoading || updateUserStatusLoaing) && <Loader />}
          {users && (
            <Grid
              resetVisible={resetVisible}
              redirectToDetailsHandler={handleClick}
              config={config}
              actionTilesLength={3}
              data={users.data}
              actionConfig={actions}
              selectItem={selectItem}
              selected={_.size(selectedItem[currentPage])}
              selectAllItem={selectAllItem}
              selectedItems={selectedItem[currentPage] || []}
              ActionsContainer={StudentsActionsContainer}
            />
          )}
          {users && users.data && (
            <StyledPagination>
              <PerPageDropdown
                value={perPage}
                onChange={(value): void =>
                  handleChangeRowsPerPage({
                    target: { value: value.toString() } as HTMLInputElement,
                  } as React.ChangeEvent<HTMLInputElement>)
                }
                options={[10, 20, 50]}
              />

              <PaginationV2
                currentPage={currentPage}
                totalPages={users?.totalPages}
                handleChange={handlePaginationClick}
              />
            </StyledPagination>
          )}
          {isResetPasswordOpen && (
            <ResetUsersPassword
              isModalOpen={isResetPasswordOpen}
              setModalOpen={setResetPasswordOpen}
              userId={userId}
            />
          )}
        </div>
        <Modal isOpened={pushNotificationModal} onClose={handleModalClose}>
          <SendPushNotification
            messageData={messageData}
            setMessageData={setMessageData}
            onClose={handleModalClose}
            onSend={handlePushNotificationSend}
          />
        </Modal>
      </LayoutWrapper>
    </>
  )
}

export default StudentsLayout
