import { useMutation } from '@apollo/client'

import {
  GENERATE_VIDEO_UPLOAD_LINKS,
  UPLOAD_IMAGE,
  UPLOAD_FILE,
  UPLOAD_AUDIO,
  EXPORT_DATA,
  EXTRACT_TEXT,
  UPLOAD_CAPTIONS,
} from 'gql/helper/heler.query'

export const generateUploadVideoLinksService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [generateUploadVideoLinksMutation] = useMutation(
    GENERATE_VIDEO_UPLOAD_LINKS,
  )

  return {
    generateUploadVideoLinksMutation,
  }
}

export const uploadImageService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [uploadImageMutation, { loading }] = useMutation(UPLOAD_IMAGE)

  return {
    uploadImageMutation,
    loading,
  }
}

export const uploadFileService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [uploadFileMutation, { loading }] = useMutation(UPLOAD_FILE)

  return {
    uploadFileMutation,
    loading,
  }
}

export const uploadCaptionsService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [uploadFileMutation, { loading }] = useMutation(UPLOAD_CAPTIONS)

  return {
    uploadFileMutation,
    loading,
  }
}

export const uploadAudioService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [uploadAudioMutation, { loading }] = useMutation(UPLOAD_AUDIO)

  return {
    uploadAudioMutation,
    loading,
  }
}

export const exportDataService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [exportDataMutation, { loading }] = useMutation(EXPORT_DATA)

  return {
    exportDataMutation,
    loading,
  }
}

export const extractTextService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [extractTextMutation, { loading }] = useMutation(EXTRACT_TEXT)

  return {
    extractTextMutation,
    loading,
  }
}
