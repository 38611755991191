import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import deleteLessonService from 'services/lessons/deleteLessonService'

export interface DeleteLessonParams {
  moduleId: string
}

const useDeleteLessonService = (params: DeleteLessonParams) => {
  const { t } = useTranslation()
  const { deleteLessonMutation, loading } = deleteLessonService(params)
  const { setSnackbar } = useSnackbarAlert()

  const deleteLesson = async (ids: string[], callback?: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await deleteLessonMutation({
      variables: {
        ids,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      if (callback) callback()
      message = `${
        ids.length > 1 ? t('general.lessons') : t('general.lesson')
      } ${t('messages.successfully')} ${t('general.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    loading,
    deleteLesson,
  }
}

export default useDeleteLessonService
