import { Action } from 'components/common/GridV2'
import React from 'react'

import { ReactComponent as EditIcon } from 'assets/edit.svg'
import { ReactComponent as UnlockIcon } from 'assets/unlock.svg'
import { ReactComponent as TrashCanRed } from 'assets/trash-can-red.svg'
import { DeleteSpan } from 'components/common/Styled/styled-components'

export const actionConfig = (
  actionHandler: any,
  permissions: any,
  t: any,
  setIsSearchType?: (value: boolean) => void,
) => {
  const action = [
    {
      hide: !permissions.editGroup,
      render: (item: any) => (
        <Action onClick={() => actionHandler('edit', item)} className="edit">
          <EditIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.viewGroup,
      render: (item: any) => (
        <Action
          onClick={() => actionHandler('publish', item)}
          className={item.published ? 'unpublish' : 'publish'}
        >
          <UnlockIcon />
          <span>
            {item.published ? t('actions.unpublish') : t('actions.publish')}
          </span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteGroup,
      render: (item: any) => (
        <Action
          onClick={() => {
            actionHandler('delete', item)
            if (setIsSearchType) {
              setIsSearchType(false)
            }
          }}
          className="delete"
        >
          <TrashCanRed />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}
