import React from 'react'
import { FormItemContainer, Label } from './styled-components'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import ReactSelect from 'react-select'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import TextInput from 'components/common/TextInput/TextInput'
import RangeSlider from 'components/common/RangeSlider'
import { Button } from 'components/common/Button'
import { TFunction } from 'interfaces/TFunction'

const existData = [
  { label: 'All', id: 1, value: 'all' },
  { label: 'Yes', id: 2, value: 'yes' },
  { label: 'No', id: 3, value: 'no' },
]
interface Params {
  closeDrawer: () => void
  filterData?: any
  setFilterData?: any
  filter?: any
  setFiltered?: any
  t: TFunction
}

const FilterDrawer = ({
  closeDrawer,
  filterData,
  setFilterData,
  filter,
  setFiltered,
  t,
}: Params): React.JSX.Element => {
  // const [loadData, { data, loading }] = useLazyQuery(GET_LESSONS, {
  //   variables: { filter: {} },
  // })

  // const loadDataOptions = (e: any) =>
  //   setTimeout(() => {
  //     const value = e
  //     loadData({
  //       variables: {
  //         filter: {
  //           name: {
  //             type: 'match',
  //             value,
  //           },
  //         },
  //       },
  //     })
  //   }, 300)

  // const handleSelectInputChange = (e: string) => {
  //   clearTimeout(ref.current)
  //   if (e) {
  //     ref.current = loadDataOptions(e)
  //   }
  // }

  const handleChange = (e: any): void => {
    const { name, value } = e.target

    setFilterData({
      ...filterData,
      [name]: value,
    })
  }

  const handleRangeChange = (range: number | number[], name: string): void => {
    setFilterData({
      ...filterData,
      [name]: range,
    })
  }

  const handleSelectChange = (field: string) => {
    return (e: any): void => {
      setFilterData({
        ...filterData,
        [field]: e,
      })
    }
  }

  const handleSubmit = (e: any): void => {
    e.preventDefault()
    const filterOptions: any = {}

    filterOptions.name = filterData.name

    filterOptions.numberOfLessons = `${filterData.numberOfLessons[0]}-${filterData.numberOfLessons[1]}`

    // if (filterData.duration !== [0, 0]) {
    //   filterOptions.duration = {
    //     type: 'range',
    //     value: `${filterData.duration[0]}-${filterData.duration[1]}`,
    //   }
    // }

    if (filterData.attachment && filterData.attachment.value !== 'all') {
      filterOptions.attachment =
        filterData.attachment.value === 'yes' ? 'true' : 'false'
    }

    // if (filterData.lessons.length !== 0) {
    //   filterOptions.lessons = {
    //     type: 'arrayIn',
    //     value: filterData.lessons.map((lesson: any) => lesson.value),
    //   }
    // }

    filter(filterOptions)
    closeDrawer()
    setFiltered(true)
  }

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          {t('general.advanced_filters')} - {t('actions.filter')}{' '}
          {t('general.topics')}
        </DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <TextInput
                  label={t('form_fields.name')}
                  size="small"
                  name="name"
                  type="text"
                  value={filterData.name}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <RangeSlider
                  label={`${t('general.lessons')} ${t('general.quantity')}`}
                  value={filterData.numberOfLessons}
                  onRangeChange={handleRangeChange}
                  name="numberOfLessons"
                />
              </FormField>
              <FormField>
                <RangeSlider
                  label={`${t('general.topic')} ${t('general.duration')}`}
                  value={filterData.duration}
                  onRangeChange={handleRangeChange}
                  name="duration"
                  max={1000}
                />
              </FormField>
              <FormField>
                <FormItemContainer>
                  <Label>{t('general.attachment')}</Label>
                  <ReactSelect
                    onChange={handleSelectChange('attachment')}
                    options={existData}
                    value={filterData.attachment}
                    placeholder={t('form_fields.select')}
                  />
                </FormItemContainer>
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={closeDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
