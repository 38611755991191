import { useState } from 'react'
import addSkillTestVideoService from 'services/modules/addSkillTestVideoTemplateService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'

const useAddSkillTestVideoService = (params: any) => {
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { addSkillTestVideoTemplateMutation } = addSkillTestVideoService(params)

  const addSkillTestVideoTemplate = async (
    moduleId: string,
    input: any,
    onSuccess?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    try {
      const { data, errors } = await addSkillTestVideoTemplateMutation({
        variables: {
          moduleId,
          input,
        },
      })

      if (errors) {
        message = errors[0].message
        if (message === 'User already registered') {
          message = message
          setError(message)
          return false
        }
      } else {
        message = 'Skill Test Video has been created'
        variant = 'success'

        if (onSuccess) {
          onSuccess()
        }
      }
      setSnackbar({ message, variant })
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  return {
    addSkillTestVideoTemplate,
    addSkillTestVideoTemplateLoading: loading,
    error,
  }
}

export default useAddSkillTestVideoService
