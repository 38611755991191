import { useMutation } from '@apollo/client'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import {
  ADD_STUDENTS_IN_GROUP,
  GET_GROUP,
  MOVE_STUDENTS_IN_GROUP,
} from 'gql/group/group.query'

const addStudentsService = (companyId: string) => {
  const refetchQueries = [
    {
      query: GET_GROUP,
      variables: {
        filter: {
          company: {
            type: LowerCaseFilterableFieldType.EXACT,
            value: companyId,
          },
        },
      },
    },
  ]
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addStudents] = useMutation(ADD_STUDENTS_IN_GROUP, {
    refetchQueries,
  })
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [moveStudents] = useMutation(MOVE_STUDENTS_IN_GROUP, {
    refetchQueries,
  })

  return {
    addStudents,
    moveStudents,
  }
}

export default addStudentsService

// role: 'STUDENT',
//     companyId: state.selectedCompanyId,
//     filter: {}
