import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useData } from 'context/DataContext'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import {
  useUploadImage,
  useGenerateUploadVideoLinks,
} from 'hooks/helpers/useHelpersService'
import Badge from 'components/common/Badge/Badge'
import useAddModuleService from 'hooks/modules/useAddModuleService'
import { useUserValue } from 'context/UserContext'
import { ModuleSchema } from 'helpers/validationSchemas'
import { DrawerEventEmitter } from 'helpers/drawer'
import { GENERATE_BADGE_DESIGN } from 'gql/modules.query'
import { FetchResult, useMutation } from '@apollo/client'
import {
  IFormDataInterface,
  IFileState,
  IVideoState,
  IVideoUploadResponse,
  IBadgeDesignResponse,
  ICourseData,
  ICourse,
  IVariables,
  IDrawerState,
  IModuleProps,
} from 'components/modules/AddModule.interface'
import CheckSVG from 'assets/checked.svg'

const useAddModule = ({ data, drawerName }: IModuleProps) => {
  const [state] = useUserValue()
  const [dataState, dispatchData] = useData()
  const [drawer, setDrawer] = useState<IDrawerState>({
    badgeEditor: false,
    args: {},
  })

  const courseId: string = data?.courseId || ''
  const [cropperOpen, setCropperOpened] = useState(false)
  const [file, setFile] = useState<IFileState>({ name: '', file: null })
  const [introVideo, setIntroVideo] = useState<IVideoState>()
  const [imageType, setImageType] = useState('')
  const [progress, setProgress] = useState(0)
  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()
  const [generateBadgeDesign, { loading: generatingBadge }] = useMutation(
    GENERATE_BADGE_DESIGN,
  )
  const { uploadImage, imageLoading } = useUploadImage()
  const { addModule, newModule, addModuleLoading } = useAddModuleService({
    courseId,
  })

  const variables: IVariables = data?.courseId
    ? {
        filter: {
          name: { type: 'match', value: '' },
          course: { type: 'nestedArrayIn', value: courseId },
        },
      }
    : {}

  const formData: IFormDataInterface = {
    name: '',
    courses: [],
    coverImage: null,
    attachment: null,
    subtitle: null,
    video: null,
    skillTestVideoEnabled: false,
    skillTestVideoCriteriaEnabled: false,
    skillTestVideoDetails: true,
    badgeEnabled: false,
    badgeData: null,
  }

  const defaultTips = [
    {
      icon: CheckSVG,
      text: 'Keep it concise',
    },
    {
      icon: CheckSVG,
      text: 'Use your own stories',
    },
    {
      icon: CheckSVG,
      text: 'Get the message across',
    },
  ]

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: formData,
      validationSchema: ModuleSchema,
      onSubmit(values) {
        const courseData: ICourseData = { ...values, courses: [] }

        if (addModuleLoading) return

        courseData.courses = (courseData.courses as ICourse[]).flatMap(
          (course: ICourse) => course.value as ICourse[],
        )

        if (state.selectedCompany)
          courseData.companyId = state.selectedCompany.id

        if (!state.selectedCompany && data.manualCompanyId) {
          courseData.companyId = data.manualCompanyId
        }

        if (values.skillTestVideoEnabled) {
          courseData.skillTestVideoDetails = values.skillTestVideoDetails
            ? 'default'
            : 'custom'
        } else {
          courseData.skillTestVideoDetails = null
        }

        const { manualCompanyId, ...dataParams } = data

        addModule(courseData, dataParams)
      },
    })

  const uploadVideoService = (url: string): Promise<string> => {
    return axios.put(url, introVideo, {
      headers: {
        'Content-Type': introVideo?.type || 'application/octet-stream',
      },
      onUploadProgress: ({ total, loaded }) => {
        setProgress((loaded / total) * 100)
      },
    })
  }

  const closeDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', { drawerName }, false)
  }

  const cropperModalToggle = (): void => {
    setCropperOpened(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: File): void => {
    uploadImage(croppedFile, `module/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: 'png',
      }),
    )
  }

  const handleBadgeEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('badgeEnabled', e.target.checked)
    generateBadgeDesign().then(
      (fetchResult: FetchResult<IBadgeDesignResponse>) => {
        const badgeDesignResponse = fetchResult
        if (badgeDesignResponse.data) {
          const { id, url } = badgeDesignResponse.data.generateBadgeDesign
          setFieldValue('badgeData', {
            id,
            url,
          })
        }
      },
    )
  }

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ): void => {
    const { files, name } = e.target

    if (!files || !files.length) return
    const reader: FileReader = new FileReader()

    reader.onload = (): void => {
      const img: HTMLImageElement = new Image()
      img.src = reader.result as string
      setFile({ name, file: reader.result })
    }
    reader.readAsDataURL(files[0])
    if (field !== 'certificateImage') {
      setCropperOpened(true)
    }
    setImageType(field)
  }

  const deleteImage = (field: string): void => {
    setFieldValue(field, '')
  }

  const handleGenerateUploadLink = (): void => {
    const videos = [{ fileName: introVideo?.name, type: introVideo?.type }]
    generateUploadVideoLinks(
      videos,
      'modules',
      uploadVideoService,
      (args: IVideoUploadResponse) => {
        setFieldValue('video', args[0])
        setFieldValue('video.title', args[0].name)
        setProgress(0)
      },
    )
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addModule',
        values,
        compareTo: {},
      },
    })
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (newModule) {
      closeDrawer()
      dispatchData({ type: CLEAR_FORMDATA })
      data.onSuccess()
    }
  }, [newModule])

  useEffect(() => {
    if (introVideo) {
      handleGenerateUploadLink()
    }
  }, [introVideo])

  const disabledButton = !!(
    (progress > 0 && progress < 100) ||
    imageLoading ||
    addModuleLoading
  )

  const defaultOverview = `Knowing what you know about ${values.name}, it's your turn to become the teacher! Please create a video, no longer than 3 minutes, teaching back the key learning.`

  const handleBadgeEditorOpen = (): void => {
    setDrawer({ badgeEditor: true, args: { id: values?.badgeData?.id } })
  }

  const handleBadgeEditorClose = (badgeData: {
    id: number
    url: string
  }): void => {
    setFieldValue('badgeData', badgeData)
    setDrawer({ badgeEditor: false, args: {} })
    setFieldValue('badgeEnabled', false)
    setTimeout(() => {
      setFieldValue('badgeEnabled', true)
    }, 500)
  }

  const renderImage = useCallback(() => {
    return (
      <Badge
        src={values.badgeData?.url || ''}
        onEditClick={handleBadgeEditorOpen}
      />
    )
  }, [values.badgeData?.url])

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    drawer,
    setDrawer,
    cropperOpen,
    setCropperOpened,
    file,
    setFile,
    introVideo,
    setIntroVideo,
    imageType,
    setImageType,
    progress,
    setProgress,
    generatingBadge,
    imageLoading,
    addModuleLoading,
    newModule,
    uploadVideoService,
    handleCropSave,
    handleBadgeEnabled,
    handleImageChange,
    deleteImage,
    handleGenerateUploadLink,
    handleCloseDrawer,
    closeDrawer,
    handleBadgeEditorOpen,
    handleBadgeEditorClose,
    renderImage,
    disabledButton,
    defaultOverview,
    defaultTips,
    formData,
    cropperModalToggle,
    variables,
  }
}

export default useAddModule
