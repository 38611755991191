import { useState } from 'react'
import addTestService from 'services/tests/addTestService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { formDataInterface } from 'pages/tests/addTestDrawer/addTestDrawer.interface'

const useAddTestService = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const { addTestMutaiton } = addTestService()

  const addTest = async (input: formDataInterface, companyId: string) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await addTestMutaiton({
      variables: {
        input,
        companyId,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
      setError(message)
    } else {
      message = 'Test added successfully'
      variant = 'success'
      setData(data.createTestAssessment)
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    addTest,
    addTestLoading: loading,
    data,
    error,
  }
}

export default useAddTestService
