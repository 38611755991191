import { useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import {
  EDIT_COURSE,
  COURSE_PUBLISHED_UN_PUBLISHED,
  DELETE_COURSE_MODULE,
  CHANGE_COURSE_STEP,
  TOGGLE_AI_ASSISTANT,
} from 'gql/course/course.query'

import { ADD_COURSE_MODULE, GET_MODULES } from 'gql/modules.query'
import { GET_QUIZ_BY_TYPE } from 'gql/quiz/quiz.query'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'

interface useEditCourseParams {
  courseId: string
}

export const coursePublishedService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [coursePublishedMutation] = useMutation(COURSE_PUBLISHED_UN_PUBLISHED)
  return {
    coursePublishedMutation,
  }
}

export const editCourseService = (params: useEditCourseParams) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editCourseMutation, { loading }] = useMutation(EDIT_COURSE, {
    refetchQueries: [
      {
        query: GET_QUIZ_BY_TYPE,
        variables: {
          actionId: params.courseId,
          type: 'course',
          currentPage: 1,
          perPage: 12,
          filter: {
            IQTestType: {
              type: UpperCaseFilterableFieldType.EXACT,
              value: 'initial',
            },
          },
        },
      },
    ],
  })
  return {
    editCourseMutation,
    loading,
  }
}

export const addCourseModulesService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addCourseModulesMutation, { loading }] = useMutation(
    ADD_COURSE_MODULE,
    {
      update(cache, { data: { addModulesToCourse: document } }) {
        updateCache({
          objectName: 'allModules',
          query: GET_MODULES,
          cache,
          document,
          action: 'addMany',
          params,
        })
      },
    },
  )

  return {
    addCourseModulesMutation,
    loading,
  }
}

export const deleteCourseModuleService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteCourseModuleMutation, { loading }] = useMutation(
    DELETE_COURSE_MODULE,
    {
      update(cache, { data: { deleteModuleInCourse: document } }) {
        updateCache({
          objectName: 'allModules',
          query: GET_MODULES,
          cache,
          document,
          action: 'deleteMany',
          params,
        })
      },
    },
  )

  return {
    deleteCourseModuleMutation,
    loading,
  }
}

export const changeCourseStepService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [changeCourseStepMutation, { loading }] =
    useMutation(CHANGE_COURSE_STEP)
  return {
    changeCourseStepMutation,
    loading,
  }
}

export const toggleAIAssistantService = () => {
  const [toggleAIAssistantMutation, { loading }] =
    useMutation(TOGGLE_AI_ASSISTANT)
  return {
    toggleAIAssistantMutation,
    loading,
  }
}
