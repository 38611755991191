import { useMutation } from '@apollo/client'

import { ADD_REMOVE_COURSE_FROM_COMPANY } from 'gql/companies.query'

export const addRemoveCourseFromCompanyService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addRemoveCourseFromCompanyMutation, { loading, error }] = useMutation(
    ADD_REMOVE_COURSE_FROM_COMPANY,
  )

  return {
    addRemoveCourseFromCompanyMutation,
    loading,
    error,
  }
}
