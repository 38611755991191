import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import React, { useCallback, useEffect, useRef } from 'react'
import { Container } from './styled-components'

interface Props {
  active: number | undefined
  steps: string[]
  setStep?: React.Dispatch<React.SetStateAction<number>>
  completedSteps?: number[]
  StepIconComponent?: any
  ColorlibConnector?: any
}

const UiStepper = ({
  active,
  steps,
  setStep,
  completedSteps,
  StepIconComponent,
  ColorlibConnector,
}: Props) => {
  const currentSteps = useRef(completedSteps)
  const onStepClick = useCallback(
    (index: number) => {
      if (
        completedSteps &&
        currentSteps?.current &&
        completedSteps.indexOf(0) > -1
      ) {
        if (setStep) setStep(index)
      }
    },
    [completedSteps, currentSteps?.current],
  )

  useEffect(() => {
    if (currentSteps?.current) currentSteps.current = completedSteps
  }, [completedSteps])

  return (
    <Container>
      <Stepper
        activeStep={active}
        alternativeLabel
        connector={ColorlibConnector ? <ColorlibConnector /> : undefined}
      >
        {steps.map((label: string, index: number) => (
          <Step key={label} onClick={() => onStepClick(index)}>
            <StepLabel StepIconComponent={StepIconComponent}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  )
}

export default UiStepper
