import React from 'react'
import { ReactComponent as DropDownIcon } from 'assets/caret-down.svg'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import { StyledLabel } from '../TextInput/styled-components'

export const customStyles: any = {
  option: (provided: any, state: any) => ({
    ...provided,
    '&:hover': {
      backgroundColor: 'rgba(6, 198, 144, 0.1)',
    },
    backgroundColor: state.isSelected
      ? 'rgba(6, 198, 144, 0.3)'
      : 'transparent',
    color: '#000',
    fontFamily: "'Helvetica neue', sans-serif",
    borderRadius: '6px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '6px',
    transition:
      'border 0.3s ease-in, outline 0.6s ease-in, box-shadow 0.6s ease-in',
    boxShadow: '0 1px 9px 5px rgb(0 0 0 / 3%)',
    outline: 'none',
    borderColor: state.isFocused ? '#06c68f !important' : 'rgba(0, 0, 0, 0.1)',
    fontFamily: "'Helvetica neue', sans-serif",
    border: state.isFocused
      ? '1px solid #06c68f'
      : '1px solid rgba(0, 0, 0, 0.1)',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: '99',
    transition:
      'border 0.3s ease-in, outline 0.6s ease-in, box-shadow 0.6s ease-in',
    boxShadow: '0 1px 9px 5px rgb(0 0 0 / 3%)',
    fontFamily: "'Helvetica neue', sans-serif",
    outline: 'none',
    border: state.isFocused
      ? '1px solid #06c68f'
      : '1px solid rgba(0, 0, 0, 0.1)',
    borderColor: state.isFocused ? '#06c68f !important' : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    minHeight: '40px',
  }),
  svg: {},
}

const SelectField = (props: any) => {
  const DropdownIndicator = (props: any) => {
    return <DropDownIcon />
  }

  return (
    <Container error={props.error && props.touched}>
      {props.label && <StyledLabel>{props.label}</StyledLabel>}
      <ReactSelect
        isMulti={props.isMulti}
        onChange={props.onChange}
        options={props.options}
        value={props.value}
        placeholder={props.placeholder}
        styles={props.styles || customStyles}
        isClearable={props.isClearable}
        onFocus={props.onFocus}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        components={{ DropdownIndicator }}
      />

      {props.touched && props.error && props.errorMessage && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </Container>
  )
}

export default SelectField

const Container = styled.div<{ error: boolean }>`
  width: 100%;
  position: relative;
  .css-yk16xz-control {
    border-color: ${({ error }) => (error ? '#f44336' : 'rgba(0, 0, 0, 0.23)')};
    outline: none;
  }
  .css-1wa3eu0-placeholder {
    color: ${({ error }) => (error ? '#f44336' : 'rgba(0, 0, 0, 0.54) ')};
  }

  .css-1hb7zxy-IndicatorsContainer {
    padding-right: 10px;
    cursor: pointer;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`

const ErrorMessage = styled.div`
  position: absolute;
  color: #f44336;
  bottom: -20px;
  left: 6px;
  font-size: 0.75rem;
`
