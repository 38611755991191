import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { ImportStudentsWithGroupsInput } from 'interfaces/users'
import { useTranslation } from 'react-i18next'

import importStudentsWithGroupsService from 'services/users/importStudentsWithGroupsService'

const useImportStudentsWithGroups = () => {
  const { t } = useTranslation()
  const { insertStudentsWithGroups, loading } =
    importStudentsWithGroupsService()
  const { setSnackbar } = useSnackbarAlert()

  const importStudentsWithGroups = async (
    values: ImportStudentsWithGroupsInput[],
    companyId: string,
    callBack?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { errors, data } = await insertStudentsWithGroups({
      variables: {
        students: values,
        companyId,
      },
    })

    let usersData = null

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `${t('general.user')} ${t('messages.edited')}`
      variant = 'success'
      usersData = data.insertStudentsWithGroups
    }

    callBack(usersData)

    setSnackbar({ message, variant })
  }

  return {
    importStudentsWithGroups,
    loading,
  }
}

export default useImportStudentsWithGroups
