import deleteUserService from 'services/users/deleteUserService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import formatRoleForTranslation, { RoleFormats } from 'helpers/formatRole'

const useDeleteUserService = (params?: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { deleteUserMutation, loading, error } = deleteUserService(params)

  const deleteUser = async (
    userIds: string[],
    role: string,
    onSuccess: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { errors } = await deleteUserMutation({
      variables: {
        ids: userIds,
        role,
      },
    })

    if (errors) {
      console.log(errors)
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      onSuccess()

      if (userIds.length > 1) {
        message = `${t(formatRoleForTranslation(role, RoleFormats.PLURAL))} ${t(
          'messages.deleted',
        )}`
      } else {
        message = `${t(formatRoleForTranslation(role))} ${t(
          'messages.deleted',
        )}`
      }

      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteUser,
    deleteUserLoading: loading,
    error,
  }
}

export default useDeleteUserService
