import { useMutation } from '@apollo/client'

import { CREATE_QUESTION } from 'gql/questions.query'

const createQuestionService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [createQuestionMutation, { loading, error }] = useMutation(
    CREATE_QUESTION,
    {},
  )

  return {
    createQuestionMutation,
    loading,
    error,
  }
}

export default createQuestionService
