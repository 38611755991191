import styled from 'styled-components'

export const Wrapper = styled.div<{ align?: string; justify?: string }>`
  display: flex;
  align-items: ${({ align }) => (align ? align : 'initial')};
  justify-content: ${({ justify }) =>
    justify ? `space-${justify}` : 'initial'};
`

export const NoValueWrapper = styled.span`
  opacity: 0.5;
`
export const WhiteBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  border-radius: 20px;
  top: 120px;
  box-shadow: 0 -5px 25px 20px rgba(0, 0, 0, 0.2);
  left: 0;
  z-index: -1;
`
export const Header = styled.div<{ color: string }>`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: ${({ color }) => (color ? color : '#06C68F')};
    width: 100%;
    height: 150px;
    z-index: -1;
    opacity: 0.9;
  }
`

export const CompanyImage = styled.div`
  width: 250px;
  height: 250px;
  background: #fff;
  border-radius: 50%;
  border: 0.5px solid rgb(6, 198, 143);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  img {
    padding: 5px;
    border-radius: 50%;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
  }
`
export const CompanyContent = styled.section`
  margin-left: 35px;
`

export const CompanyTitle = styled.h1`
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`

export const ContinueBtn = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #d2364b;
  color: #fff;
  cursor: pointer;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 600;
  transition: background 0.3s ease, border 0.3s ease;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 38%);

  /* &:hover {
    background: #ff3a52;
    border: 1px solid transparent;
  } */
`
export const PlatformServiceTitle = styled.h4`
  font-size: 17px;
  margin-left: 10px;
`

export const PlatformServiceSwitcher = styled.div`
  margin-top: 10px;
  font-size: 17px;

  span.disabled {
    color: red;
  }

  span.enabled {
    color: #06c68f;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #06c68f;
  }
`
export const Container = styled.div`
  margin-top: 30px;
`
export const Content = styled.div`
  background: white;
  border-radius: 16px !important;
  padding: 1px 25px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 7%), 0px 4px 5px 0px rgb(0 0 0 / 7%),
    0px 1px 10px 0px rgb(0 0 0 / 7%);
  margin-top: 40px;
`
export const ContainerHeader = styled.header`
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  padding-bottom: 8px;
  font-size: 20px;
  color: rgba(112, 112, 112, 0.72);
`
export const ContainerContent = styled.div`
  padding: 30px;
`
export const ContenGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 14px;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-gap: 20px;
`
export const ContenGridItem = styled.div`
  display: flex;

  strong {
    margin: 0 8px;
  }
`

// Chart Section

export const ChartContainer = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 5px;
`

//  Company Information

export const StyledWrapper = styled.div`
  position: relative;
  height: 175px;
`

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 85px;
  span {
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 3px;
  }
`

export const SetupsContainer = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
`

export const StyledActionsContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
`
