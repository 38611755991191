import React from 'react'

// translation
import { useTranslation } from 'react-i18next'

// interfaces
import {
  SkillTestVideoCriteria,
  SkillTestVideoCriteriasProps,
} from './skillTestVideoCriteria.interface'

// hooks
import { useFetchSkillTestVideoCriterias } from 'hooks/modules/useModules'

// helpers
import { DrawerEventEmitter } from 'helpers/drawer'

// components
import {
  CriteriaItem,
  CriteriasContainer,
  SkillTestVideoCriteriasContainer,
  SkillTestVideoCriteriasGrid,
  SkillTestVideoCriteriasGridHeader,
  Wrapper,
} from './styled-components'
import { Button } from 'components/common/Button'

// icons
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'

const SkillTestVideoCriterias = ({
  moduleId,
  canModify,
}: SkillTestVideoCriteriasProps) => {
  // translation
  const { t } = useTranslation()

  // hooks
  const { skillTestVideoCriterias, loading } =
    useFetchSkillTestVideoCriterias(moduleId)

  const criterias = skillTestVideoCriterias && skillTestVideoCriterias.criterias

  // const {
  //   deleteSkillTestVideoCriterias,
  // } = useDeleteSkillTestVideoCriteriasService(moduleId)

  const handleAddEditCriteriasClick = () =>
    DrawerEventEmitter.emit(
      'openDrawer',
      'addEditSkillTestVideoCriteria',
      true,
      {
        data: {
          moduleId,
          criterias,
        },
      },
    )

  if (loading) {
    return <div>{t('general.loading')}...</div>
  }

  return (
    <SkillTestVideoCriteriasContainer>
      {canModify && (
        <Wrapper>
          <Button
            text={t('skillTestVideoCriteria_layout.addEdit')}
            size="small"
            color="secondary"
            onClick={() => handleAddEditCriteriasClick()}
            icon={<AddCircleRoundedIcon />}
            background="#06C68F"
          />
        </Wrapper>
      )}
      <SkillTestVideoCriteriasGrid>
        <SkillTestVideoCriteriasGridHeader>
          {t('skillTestVideoCriteria_layout.covered')}
        </SkillTestVideoCriteriasGridHeader>
        <CriteriasContainer>
          {(criterias.length &&
            criterias.map((skillTestVideoCriteria: SkillTestVideoCriteria) => (
              <CriteriaItem key={skillTestVideoCriteria.id}>
                <img src="/img/check.svg" /> {skillTestVideoCriteria.criteria}
              </CriteriaItem>
            ))) || <p>{t('skillTestVideoCriteria_layout.no_data')}</p>}
        </CriteriasContainer>
      </SkillTestVideoCriteriasGrid>
    </SkillTestVideoCriteriasContainer>
  )
}

export default SkillTestVideoCriterias
