import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 1rem;
  padding: 48px;
`

export const StyledInnerWrapper = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 1rem;
  padding: 48px;
`

export const StyledResponseText = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 1rem;
  background-color: #fff;
  border-radius: 4px;
  max-width: 1000px;
  overflow: auto;
  color: #000;
  font-family: 'Open Sans', sans-serif;
`

export const StyledPlaceholder = styled.div`
  text-align: center;
  color: #aaa;
  font-style: italic;
  margin: 20px 0;

  p {
    margin: 10px 0;
  }
`
