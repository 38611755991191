import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useUserValue } from 'context/UserContext'
import { useLazyQuery } from '@apollo/client'
import {
  GET_GROUP,
  GET_GROUPS_BY_TEST_ASSESSMENT_ID,
} from 'gql/group/group.query'
import { useTestAssessmentsSearch } from 'hooks/tests/TestAssessment/useTestAssessmentData'
import {
  AnalyticsClearableRouterState,
  AnalyticsCourseOption,
  AnalyticsGroupOption,
  AnalyticsRouterState,
  AnalyticsTestOption,
  CoursesFilter,
  GroupsFilter,
  ICourse,
} from './useAnalyticsData.interface'
import { useFetchCoursesOptions } from 'services/analytics/coursesOptionsService'
import { User } from 'interfaces/users'
import {
  LowerCaseFilterableFieldType,
  UpperCaseFilterableFieldType,
} from 'enums/filterEnum'

const allGroupsOption = { label: 'All Groups', value: 'all' }

const useAnalyticsData = () => {
  const { id: companyIdFromUrl } = useParams()
  const location = useLocation()
  const locationState = location.state as AnalyticsRouterState

  const [routerState, setRouterState] =
    useState<AnalyticsClearableRouterState>(locationState)

  const [state] = useUserValue()
  const companyId = companyIdFromUrl
    ? companyIdFromUrl
    : state.selectedCompany?.id
    ? state.selectedCompany.id
    : locationState?.companyId
    ? locationState.companyId
    : null

  const [currentTab, setCurrentTab] = useState<number>(0)
  const [locationUpdated, setLocationUpdated] = useState<boolean>(false)

  const [groupsFilter, setGroupsFilter] = useState<GroupsFilter>({
    status: { type: LowerCaseFilterableFieldType.EXACT, value: 'ACTIVE' },
  })
  const [coursesFilter, setCoursesFilter] = useState<CoursesFilter>({
    originalId: { type: UpperCaseFilterableFieldType.ARRAY_IN, value: [null] },
  })

  useEffect(() => {
    if (companyId) {
      setGroupsFilter({
        ...groupsFilter,
        company: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: companyId,
        },
      })
      setCoursesFilter({
        companyId: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: companyId,
        },
      })
    }
  }, [companyId])

  const [courseOptions, setCourseOptions] = useState<AnalyticsCourseOption[]>(
    [],
  )
  const [groupOptions, setGroupOptions] = useState<AnalyticsGroupOption[]>([])
  const [testOptions, setTestOptions] = useState<AnalyticsTestOption[]>([])
  const [testGroupOptions, setTestGroupOptions] = useState<
    AnalyticsGroupOption[]
  >([])

  const [selectedCourse, setSelectedCourse] =
    useState<AnalyticsCourseOption | null>(null)
  const [selectedGroup, setSelectedGroup] =
    useState<AnalyticsGroupOption | null>(allGroupsOption)
  const [selectedTestGroup, setSelectedTestGroup] =
    useState<AnalyticsTestOption | null>(null)
  const [selectedTest, setSelectedTest] = useState<AnalyticsTestOption | null>(
    null,
  )
  const [tabValue, setTabValue] = useState(0)

  const { courses, loading: coursesLoading } =
    useFetchCoursesOptions(coursesFilter)

  const { loading: testsLoading, testAssessments } =
    useTestAssessmentsSearch(companyId)
  const [
    fetchGroupsByTest,
    { data: groupsDataByTest, loading: testGroupsLoading },
  ] = useLazyQuery(GET_GROUPS_BY_TEST_ASSESSMENT_ID)
  const [fetchGroups, { data, loading: groupsLoading }] = useLazyQuery(
    GET_GROUP,
    {
      variables: { filter: groupsFilter, currentPage: 1, perPage: 0 },
    },
  )

  // update course and tests options when courses are fetched
  useEffect(() => {
    if (courses && courses?.data.length > 0) {
      const filterOptionsMapped = courses.data.map((course: ICourse) => {
        return {
          label: course.name,
          value: course.id,
          originalId: course.originalId,
        }
      })

      setCourseOptions(filterOptionsMapped)
    }
  }, [courses])

  useEffect(() => {
    if (testAssessments && testAssessments.length > 0) {
      const options = testAssessments.map((test) => ({
        label: test.name,
        value: test.id,
      }))
      setTestOptions(options)
    }
  }, [testAssessments])

  // refetch groups  when course changes to get groups of that course only
  useEffect(() => {
    setSelectedGroup(allGroupsOption)
    setGroupOptions([])
    if (selectedCourse) {
      const updatedGroupsFilter = {
        ...groupsFilter,
        courses: {
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: [selectedCourse?.value],
          nestedField: 'courseId',
        },
      }
      fetchGroups({
        variables: {
          filter: updatedGroupsFilter,
          currentPage: 1,
          perPage: 0,
        },
      })
      setGroupsFilter(updatedGroupsFilter)
    }
  }, [selectedCourse])

  // refetch groups  when tests changes to get groups of that course only

  useEffect(() => {
    setSelectedGroup(allGroupsOption)
    if (selectedTest) {
      fetchGroupsByTest({
        variables: {
          testAssessmentId: selectedTest.value,
        },
      })
    }
  }, [selectedTest, fetchGroupsByTest])

  // update test  group options when test groups are fetched
  useEffect(() => {
    if (
      groupsDataByTest &&
      groupsDataByTest.getGroupsByTestAssessmentId?.data.length > 0
    ) {
      const newGroupOptions =
        groupsDataByTest.getGroupsByTestAssessmentId.data.map(
          (group: { name: string; id: string }) => ({
            label: group.name,
            value: group.id,
          }),
        )
      setTestGroupOptions(newGroupOptions)
    } else {
      setTestGroupOptions([])
    }
  }, [groupsDataByTest, selectedTest])

  // update group options when groups are fetched

  useEffect(() => {
    if (data && data.getAllGroups?.data.length > 0) {
      const filterOptionsMapped = data.getAllGroups.data.map(
        (group: { name: string; id: string }) => {
          return {
            label: group.name,
            value: group.id,
          }
        },
      )

      setGroupOptions([allGroupsOption, ...filterOptionsMapped])
    } else {
      setGroupOptions([])
    }
  }, [data, selectedCourse])

  useEffect(() => {
    // getting location state as react states to be able to clear them
    const { courseId, groupId, user, testAssessmentId } = routerState || {}

    // if page just loaded, set current tab based on location state
    // setting locationUpdated state to prevent changing tabs after one of the dependencies change
    if (!locationUpdated) {
      if (user || groupId) {
        if (testAssessmentId) setCurrentTab(3)
        else setCurrentTab(2)
      } else if (groupId) setCurrentTab(1)
      setLocationUpdated(true)
      return
    }

    // after tabs are set, checking for course and group options to be able to set selected course and group
    if (courseId && courseOptions.length) {
      const currentCourse = courseOptions.find(
        (course) => course.value === courseId,
      )
      if (currentCourse) setSelectedCourse(currentCourse)

      // if groupId is provided in location state, set selected group
      if (groupOptions.length) {
        if (groupId) {
          const currentGroup = groupOptions.find(
            (group) => group.value === groupId,
          )
          if (currentGroup) setSelectedGroup(currentGroup)
        }

        // if user is provided in location state, set selected group based on user's groups
        if (user && selectedCourse) {
          const userGroup = groupOptions.find((group) =>
            user.groups.includes(group.value),
          )
          if (userGroup) setSelectedGroup(userGroup)
        }

        // finally, clear location state to prevent setting selected course and group on every change of dependencies
        setRouterState({
          ...routerState,
          courseId: null,
          groupId: null,
        })
      }
    } else if (testAssessmentId && testOptions.length) {
      const currentTest = testOptions.find(
        (test) => test.value === testAssessmentId,
      )
      if (currentTest) setSelectedTest(currentTest)

      if (testGroupOptions.length) {
        if (groupId) {
          const currentGroup = testGroupOptions.find(
            (group) => group.value === groupId,
          )
          if (currentGroup) setSelectedTestGroup(currentGroup)
        }
        if (user) {
          const userObj = user as User
          const userGroupIds =
            userObj.group?.map((group) => group.groupId || group) || []

          const userGroup = testGroupOptions.find((group) =>
            userGroupIds.includes(group.value),
          )

          if (userGroup) setSelectedTestGroup(userGroup)
        }

        setRouterState({
          ...routerState,
          testAssessmentId: null,
        })
      }
    }
  }, [
    locationState,
    courseOptions,
    groupOptions,
    testGroupOptions,
    testOptions,
  ])

  const handleTabChange = (value: string): void => {
    setCurrentTab(parseInt(value))
  }
  const handleCourseChange = (e: AnalyticsCourseOption): void => {
    setSelectedCourse(e)
  }
  const handleGroupChange = (e: AnalyticsGroupOption): void => {
    setSelectedGroup(e)
  }
  const handleTestGroupChange = (e: AnalyticsGroupOption): void => {
    setSelectedTestGroup(e)
  }
  const handleTestChange = (selectedOption: AnalyticsTestOption): void => {
    setSelectedTestGroup(null)
    setSelectedTest(selectedOption)
  }

  const studentsGroupOptions = groupOptions?.slice(1) || null
  const studentsSelectedGroup =
    selectedGroup && selectedGroup?.value === 'all' ? null : selectedGroup

  const handleGroupTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: number,
  ): void => {
    setTabValue(newValue)
  }

  return {
    currentTab,
    setCurrentTab,
    courseOptions,
    groupOptions,
    studentsGroupOptions,
    testOptions,
    selectedCourse,
    setSelectedCourse,
    selectedGroup,
    studentsSelectedGroup,
    setSelectedGroup,
    selectedTest,
    setSelectedTest,
    coursesLoading,
    groupsLoading,
    testsLoading,
    testGroupsLoading,
    handleTabChange,
    handleCourseChange,
    handleGroupChange,
    handleTestChange,
    locationState,
    testGroupOptions,
    handleTestGroupChange,
    selectedTestGroup,
    handleGroupTabChange,
    tabValue,
  }
}

export default useAnalyticsData
