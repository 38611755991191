import { useState, useEffect } from 'react'
import { CustomCardStyleConfig } from './useCustomCardStyles.interface'

const useCustomCardStyles = ({
  smallWidth,
  mediumWidth,
  largeWidth,
  smallBreakpoint,
  mediumBreakpoint,
}: CustomCardStyleConfig): React.CSSProperties => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (windowWidth <= smallBreakpoint) {
    return { width: smallWidth }
  } else if (windowWidth <= mediumBreakpoint) {
    return { width: mediumWidth }
  } else {
    return { width: largeWidth }
  }
}

export default useCustomCardStyles
