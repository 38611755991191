import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { filterOptions } from 'pages/quizzes/staticData'
import { GET_TAGS } from 'gql/skilss.query'

import { IQuiz } from 'pages/quizzes/quiz.interface'
import {
  FilterDropdownProps,
  FilterTestDrawerProps,
  ITag,
} from 'pages/tests/filterTestDrawer/filter.interface'

import {
  UpperCaseFilterableFieldType,
  LowerCaseFilterableFieldType,
} from 'enums/filterEnum'

const useFilterTestLogic = ({
  onClose,
  setFilterData,
  filterData,
  companyId,
  setCurrentPage,
  t,
}: FilterTestDrawerProps) => {
  const [filterDataValues, setFilterDataValues] = useState<IQuiz>(filterOptions)
  const [passRate, setPassRate] = useState([0, 100])

  const { data: tagsData } = useQuery(GET_TAGS, {
    variables: {
      companyId,
    },
  })

  const tagList =
    (tagsData &&
      tagsData.getAllTags.map((tag: any) => ({
        label: tag.label,
        value: tag.id,
      }))) ||
    []

  useEffect(() => {
    if (filterData?.values) {
      setFilterDataValues(filterData.values)
      setPassRate(filterData.values.passRate)
    }
  }, [filterData])

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | FilterDropdownProps,
    field: string,
    action?: string,
  ) => {
    if (action && action === LowerCaseFilterableFieldType.DROPDOWN) {
      setFilterDataValues({
        ...filterDataValues,
        [field]: e,
      })
    } else {
      const event = e as React.ChangeEvent<HTMLInputElement>
      setFilterDataValues({
        ...filterDataValues,
        [field]: event.target.value,
      })
    }
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault()

    const { name, quizParticipationType, timeType, tags } = filterDataValues
    const values: any = {}

    if (name)
      values.name = {
        type: UpperCaseFilterableFieldType.MATCH,
        value: name,
      }
    else delete values.name

    if (
      quizParticipationType.value.toUpperCase() !==
      UpperCaseFilterableFieldType.ALL
    ) {
      values.quizParticipationType = {
        type: UpperCaseFilterableFieldType.EXACT,
        value: quizParticipationType.value.toUpperCase(),
      }
    } else delete values.quizParticipationType

    if (timeType.value.toUpperCase() !== UpperCaseFilterableFieldType.ALL)
      values.timeType = {
        type: UpperCaseFilterableFieldType.EXACT,
        value: timeType.value.toUpperCase(),
      }
    else delete values.timeType

    if (tags)
      values.tags = {
        type: UpperCaseFilterableFieldType.NESTED_ARRAY_IN,
        value: tags.map((tag: ITag) => tag.label),
        nestedField: UpperCaseFilterableFieldType.LABEL,
      }
    else delete values.tags

    if (passRate[0] === 0 && passRate[1] === 300) delete values.passRate
    else
      values.passRate = {
        type: UpperCaseFilterableFieldType.RANGE,
        min: passRate[0],
        max: passRate[1],
      }

    setFilterData({
      filterOptions: {
        ...values,
      },
      values: { ...filterDataValues, passRate },
    })

    setCurrentPage(1)

    onClose()
  }
  return {
    filterDataValues,
    passRate,
    tagList,
    handleFieldChange,
    onFormSubmit,
    t,
    setFilterData,
    onClose,
    setPassRate,
  }
}
export default useFilterTestLogic
