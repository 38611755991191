import Tooltip from '@mui/material/Tooltip'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Checkbox from 'components/common/Checkbox'
import React from 'react'
import { List, ListItem, ErrorContainer } from './styled-components'
import { itemProps } from './importStudentsInGroups.interface'
import { TFunction } from 'interfaces/TFunction'
import { GridFirstName } from '../ImportGroups/styled-components'

export const columnConfig = (
  selectItem: (id: string) => void,
  isImported: boolean,
  t: TFunction<'translation', undefined>,
) => {
  const grid = [
    {
      label: '',
      id: 'isChecked',
      render: (item: itemProps) => (
        <Checkbox
          checked={item.isChecked}
          onChange={() => selectItem(item.id)}
          disabled={isImported}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: `${t('user_details.first_name')}*`,
      render: (item: itemProps) => (
        <GridFirstName>{item.firstName}</GridFirstName>
      ),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.last_name')}*`,
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.email')}*`,
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'groups',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.groups')}*`,
      render: (item: itemProps) => (
        <div>
          {/*
          when groups are extracted from excel file it's just string array (groups)
          when groups are inserted from the backend its array of group objects (group)
          */}
          {item.groups
            ? item.groups.join(', ')
            : item.group
            ? item.group.map((groupObj) => groupObj.name).join(', ')
            : ''}
        </div>
      ),
    },
  ]

  if (isImported) {
    grid.push({
      id: 'error',
      numeric: false,
      disablePadding: false,
      label: 'Error',
      render: (item: itemProps) => {
        const messages = (item && item.message) || []

        if (!item.error) return <></>

        return (
          <ErrorContainer error={item.error}>
            <Tooltip
              title={
                <List>
                  {messages.map((i: string, index: number) => (
                    <ListItem key={index}>* {i}</ListItem>
                  ))}
                </List>
              }
            >
              <ErrorOutlineIcon color="secondary" />
            </Tooltip>
          </ErrorContainer>
        )
      },
    })
  }

  return grid
}
