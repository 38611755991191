import { useState } from 'react'
import addModuleService from 'services/modules/addModuleService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useAddModuleService = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [newModule, setNewModule] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { addModuleMutation } = addModuleService(params)

  const addModule = async (
    input: any,
    courseData: { courseId: string; name: string },
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await addModuleMutation({
      variables: {
        input,
        courseData,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return false
      }
      variant = 'error'
    } else {
      setNewModule(data.addModule)
      message = courseData.name
        ? `${t('general.module')} ${t('messages.saved_and_added_to_course')}`
        : `${t('general.module')} ${t('messages.saved')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    addModule,
    addModuleLoading: loading,
    newModule,
    error,
  }
}

export default useAddModuleService
