import { useMutation } from '@apollo/client'

import { EDIT_FLASHCARD } from 'gql/flashcards.query'

const editFlashCardService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editFlashCardMutation, { loading, error }] = useMutation(
    EDIT_FLASHCARD,
    // {
    //   update(cache, { data: { editFlashCard: document } }) {
    //     updateCache({
    //       objectName: 'flashCards',
    //       query: GET_FLASHCARDS,
    //       cache,
    //       document,
    //       action: 'update',
    //       params,
    //     })
    //   },
    // },
  )

  return {
    editFlashCardMutation,
    loading,
    error,
  }
}

export default editFlashCardService
