import { useState } from 'react'
import editStudentService from 'services/users/editStudentService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { User } from 'interfaces/users'
import formatRoleForTranslation from 'helpers/formatRole'

const useEditStudentService = (): {
  updateStudent: (
    id: string,
    input: User,
    callback?: () => void,
  ) => Promise<void>
  user: User | string
  editStudentLoading: boolean
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [user, setUser] = useState('')
  const [error, setError] = useState('')
  const { updateStudentMutation, loading } = editStudentService()

  const updateStudent = async (
    id: string,
    input: User,
    callback?: () => void,
  ): Promise<void> => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await updateStudentMutation({
      variables: {
        id,
        input,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
      setError(errors[0]?.message)
    } else {
      setUser(data.updateStudent)
      const role = data.updateStudent.role.toLowerCase()
      message = `${t(formatRoleForTranslation(role))} ${t('messages.edited')}`
      variant = 'success'
      if (callback) {
        callback()
      }
    }

    setSnackbar({ message, variant })
  }

  return {
    updateStudent,
    user,
    editStudentLoading: loading,
    error,
  }
}

export default useEditStudentService
