import React, { FormEvent } from 'react'

import { FormField, FormFields } from 'components/common/Form'
import { Input } from 'components/common/TextInput'

// import { AddAdmin } from 'pages/admins'
// import Drawer from 'components/common/Drawer'
import { AsyncSelect } from 'components/common/SelectField'
import { generateFullName } from 'utils/utils'
import { Title } from 'components/DrawerLayout/styled-components'

import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { Button } from 'components/common/Button'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { DataOptionFieldEnums } from './addGroupDrawer.interface'
import useGroupDrawer, {
  AddGroupDrawerProps,
} from 'hooks/group/useGroupDrawers/useGroupDrawer'
import { ISelectOption } from 'pages/customNotifications/customNotification.interface'
import useAddGroupDrawer from 'hooks/group/useGroupDrawers/useAddGroupDrawer'
import MyLoader from 'loading/loading'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'

const AddGroupDrawer = ({
  companyId,
  courseData,
  onClose,
  afterSave,
}: AddGroupDrawerProps): React.JSX.Element => {
  const {
    t,
    handleSelectChange,
    courses,
    coursesLoading,
    adminData,
    adminLoading,
  } = useGroupDrawer({
    companyId,
  })

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    loading,
    handleCloseDrawer,
  } = useAddGroupDrawer({
    companyId,
    courseData,
    onClose,
    afterSave,
  })

  return (
    <>
      {loading && <MyLoader width={250} />}
      <DrawerHeader>
        <DrawerTitle>{t('groups_layout.create_group')}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer
          onSubmit={(e: FormEvent<HTMLFormElement>): void => e.preventDefault()}
        >
          <FormGroupWrapper>
            <FormFields>
              <FormField width="100%">
                <Input
                  title={`${t('groups_layout.group_name')}*`}
                  error={!!errors.name}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  placeholder={`${t('groups_layout.group_name')}`}
                  name="name"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                />
              </FormField>

              <FormField width="100%">
                <Title>{`${t('tasks_layout.select_admin')}*`}</Title>
                <AsyncSelect
                  isMulti
                  onInputChange={(e): void =>
                    handleSelectChange(DataOptionFieldEnums.ADMINS, e)
                  }
                  onChange={(e: ISelectOption) => setFieldValue('admin', e)}
                  data={(adminData && adminData.usersByRole.data) || []}
                  value={values.admin}
                  label={t('form_fields.type_to_search_admin')}
                  loading={adminLoading}
                  labelDataName={generateFullName}
                  valueDataKey="id"
                  error={!!errors.admin}
                  touched={!!touched.admin}
                  errorMessage={errors.admin ? errors.admin : ''}
                />
              </FormField>

              {!courseData && (
                <FormField>
                  <Title>{t('tasks_layout.select_courses')} </Title>
                  <AsyncSelect
                    isMulti
                    onInputChange={(e): void =>
                      handleSelectChange(DataOptionFieldEnums.COURSES, e)
                    }
                    onChange={(e: ISelectOption) => setFieldValue('courses', e)}
                    data={courses?.getAllCourseOptions?.data || []}
                    value={values.courses}
                    label={t('form_fields.type_to_search_courses')}
                    loading={coursesLoading}
                    valueDataKey="id"
                    labelDataKey="name"
                    error={!!errors.courses}
                    touched={!!touched.courses}
                    errorMessage={errors.courses || ''}
                  />
                </FormField>
              )}
              <FormField width="100%">
                <Input
                  title={`${t('form_fields.location')}`}
                  placeholder={`${t('form_fields.location')}`}
                  name="location"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <Input
                  title={`${t('user_details.note')}`}
                  placeholder={`${t('user_details.note')}`}
                  name="note"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows="4"
                  value={values.note}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <CustomSwitcher
                  onChange={(e): void => {
                    setFieldValue('isNewsFeedAvailable', e.target.checked)
                  }}
                  label="Is News Feed Available for this Group?"
                  name="isNewsFeedAvailable"
                  value={values.isNewsFeedAvailable}
                />
              </FormField>
            </FormFields>
            {/* <Drawer
              opened={drawer.addAdmin}
              toggleDrawer={handleInnerDrawerClose}
              totalWidth="600px"
            >
              <AddAdmin
                drawerData={drawer.args}
                onClose={(): void => setDrawer({ addAdmin: false, args: {} })}
              />
            </Drawer> */}
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                color="secondary"
                type="small"
                onClick={handleSubmit}
                isDisabled={loading}
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddGroupDrawer
