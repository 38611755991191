import { TFunction } from 'interfaces/TFunction'
import { Company } from './types'

export const generateCharData = (company: Company, t: TFunction) => {
  return [
    {
      quantity: company.suadaCoursesCount,
      label: t('general.purchased_courses'),
      fill: 'rgba(210, 54, 75, .5)',
    },
    {
      quantity: company.assignedCoachesCount,
      label: t('general.assigned_coaches'),
      fill: 'rgba(144, 7, 7, .5)',
    },
    {
      quantity: company.internalCoursesCount,
      label: t('general.company_courses'),
      fill: 'rgba(115, 120, 122, .5)',
    },
    {
      quantity: company.internalCoachesCount,
      label: t('general.company_coaches'),
      fill: 'rgba(255, 27, 170, .5)',
    },
    {
      quantity: company.superAdminsCount,
      label: `${t('general.company')} ${t('general.super_admins')}`,
      fill: 'rgba(6, 198, 143, .5)',
    },
    {
      quantity: company.groupsCount,
      label: t('general.groups'),
      fill: 'rgba(251, 222, 75, .5)',
    },
    {
      quantity: company.studentsCount,
      label: t('general.students'),
      fill: 'rgba(17, 161, 242, .5)',
    },
    {
      quantity: company.groupAdminsCount,
      label: t('company_wizard_layout.group_admins'),
      fill: 'rgba(249, 147, 29, .5)',
    },
  ]
}
