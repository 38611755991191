import React, { FormEvent, ReactElement } from 'react'
import 'cropperjs/dist/cropper.css'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import { FormContainer } from 'components/common/Form'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import Tab1 from './Tab1'
import Loader from 'components/common/Loader'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import { EditCourseDrawerProps } from 'hooks/course/editCourseDrawer/editCourseDrawer.interface'
import useEditCourseDrawer from 'hooks/course/editCourseDrawer/useEditCourseDrawer'

const EditCourseDrawer = ({ id }: EditCourseDrawerProps): ReactElement => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleVideoChange,
    videoLinks,
    setVideoLinks,
    progress,
    setProgress,
    introVideo,
    setIntroVideo,
    setFieldValue,
    isCompanyMode,
    skillList,
    uploadImage,
    uploadFile,
    imageLoading,
    handleUploadFile,
    fileLoading,
    courseData,
    handleButtonClick,
    handleCloseDrawer,
    courseCompanyId,
    loading,
    cropperModalToggle,
    handleCropSave,
    editCourseLoading,
    t,
    cropperOpen,
  } = useEditCourseDrawer({
    id,
  })

  // const getStepContent = (stepIndex: number): ReactElement | string => {
  //   switch (stepIndex) {
  //     case 0:
  //       return (
  //         <Tab1
  //           values={values}
  //           errors={errors}
  //           touched={touched}
  //           handleChange={handleChange}
  //           handleVideoChange={handleVideoChange}
  //           videoLinks={videoLinks}
  //           setVideoLinks={setVideoLinks}
  //           videoUploadProgress={progress}
  //           setProgress={setProgress}
  //           introVideo={introVideo}
  //           setIntroVideo={setIntroVideo}
  //           setFieldValue={setFieldValue}
  //           isCompanyMode={isCompanyMode}
  //           skillList={skillList}
  //           uploadImage={uploadImage}
  //           uploadFile={uploadFile}
  //           imageLoading={imageLoading}
  //           handleUploadFile={handleUploadFile}
  //           fileLoading={fileLoading}
  //           courseData={courseData}
  //           handleButtonClick={handleButtonClick}
  //           handleDeleteCourse={handleCloseDrawer}
  //           manualCompanyId={courseCompanyId}
  //           t={t}
  //         />
  //       )
  // case 1:
  //   return (
  //     <CourseGroups
  //       selectedGroup={selectedGroup}
  //       setSelectedGroup={setSelectedGroup}
  //       handleDeleteCourse={handleCloseDrawer}
  //       handleButtonClick={handleButtonClick}
  //       step={(courseData && courseData.finished) || 0}
  //       handleChangeStep={(num: number): void =>
  //         handleChangeStep(step - num)
  //       }
  //       manualCompanyId={courseCompanyId}
  //       courseData={{ courseId: id, name: courseData.name || '' }}
  //       editMode={!!defaultStep}
  //       hasSearchByCompany={false}
  //       hasPurchasedCourses={hasPurchasedCourses}
  //     />
  //   )

  // case 2:
  //   return (
  //     <AssignCoach
  //       values={courseWithCoach}
  //       selectedGroup={selectedGroup}
  //       courseId={courseData?.id}
  //       handleDeleteCourse={handleCloseDrawer}
  //       handleButtonClick={handleButtonClick}
  //       handleChangeStep={(num: number): void =>
  //         handleChangeStep(step - num)
  //       }
  //     />
  //   )

  // case 3:
  //   return (
  //     <SuperAdminTab
  //       values={values}
  //       errors={errors}
  //       touched={touched}
  //       handleChange={handleChange}
  //       handleVideoChange={handleVideoChange}
  //       videoLinks={videoLinks}
  //       setVideoLinks={setVideoLinks}
  //       videoUploadProgress={progress}
  //       setProgress={setProgress}
  //       introVideo={introVideo}
  //       setIntroVideo={setIntroVideo}
  //       setFieldValue={setFieldValue}
  //       skillList={skillList}
  //       uploadImage={uploadImage}
  //       imageLoading={imageLoading}
  //       handleUploadFile={handleUploadFile}
  //       fileLoading={fileLoading}
  //       handleButtonClick={handleButtonClick}
  //       handleDeleteCourse={handleCloseDrawer}
  //       t={t}
  //     />
  //   )
  //     default:
  //       return 'Error...!'
  //   }
  // }

  // const stepTitles = [
  //   t('course_details.course_details'),
  //   t('general.groups'),
  //   t('general.assign'),
  // ]

  return (
    <>
      {loading || editCourseLoading ? (
        <Loader withBackground={Boolean(loading)} />
      ) : (
        courseData && (
          <>
            <div>
              <DrawerHeader>
                <DrawerTitle>{`${t('actions.edit')} ${t(
                  'general.course',
                )}`}</DrawerTitle>
                <IconButton onClick={handleCloseDrawer}>
                  <HighlightOffRoundedIcon />
                </IconButton>
              </DrawerHeader>

              {/* {!isSuperAdmin && <Stepper active={step} steps={stepTitles} />} */}
            </div>
            <DrawerContent headerHeight={67}>
              <FormContainer
                onSubmit={(e: FormEvent<HTMLFormElement>): void =>
                  e.preventDefault()
                }
              >
                <Tab1
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleVideoChange={handleVideoChange}
                  videoLinks={videoLinks}
                  setVideoLinks={setVideoLinks}
                  videoUploadProgress={progress}
                  setProgress={setProgress}
                  introVideo={introVideo}
                  setIntroVideo={setIntroVideo}
                  setFieldValue={setFieldValue}
                  isCompanyMode={isCompanyMode}
                  skillList={skillList}
                  uploadImage={uploadImage}
                  uploadFile={uploadFile}
                  imageLoading={imageLoading}
                  handleUploadFile={handleUploadFile}
                  fileLoading={fileLoading}
                  courseData={courseData}
                  handleButtonClick={handleButtonClick}
                  handleDeleteCourse={handleCloseDrawer}
                  manualCompanyId={courseCompanyId}
                  t={t}
                />
              </FormContainer>
            </DrawerContent>
            <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
              <ImageCropper
                file={''}
                hideCropper={cropperModalToggle}
                getCroppedFile={handleCropSave}
              />
            </Modal>
          </>
        )
      )}
    </>
  )
}

export default EditCourseDrawer
