import { useMutation } from '@apollo/client'

import {
  GET_QUESTIONS,
  EDIT_QUESTION,
  CHANGE_QUESTION_ORDER,
} from 'gql/questions.query'
import { updateCache } from 'services/updateCache'

export const editQuestionService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editQuestionMutation, { loading, error }] = useMutation(
    EDIT_QUESTION,
    {},
  )

  return {
    editQuestionMutation,
    loading,
    error,
  }
}

export const changeQuestionsOrderService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [changeQuestionsOrderMutation, { loading, error }] = useMutation(
    CHANGE_QUESTION_ORDER,
    {
      update(cache, { data: { changeQuestionsOrder: document } }) {
        updateCache({
          objectName: 'questions',
          query: GET_QUESTIONS,
          cache,
          document,
          action: 'update',
          params,
        })
      },
    },
  )

  return {
    changeQuestionsOrderMutation,
    loading,
    error,
  }
}
