import { useState } from 'react'
import {
  editTopicService,
  changeTopicsOrderService,
  changeLessonOrderService,
} from 'services/topics/editTopicService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export const useEditTopic = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [newTopic, setNewTopic] = useState('')
  const [error, setError] = useState('')
  const { editTopicMutation, loading } = editTopicService(params)

  const editTopic = async (topicId: string, input: any, callBack?: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await editTopicMutation({
      variables: {
        topicId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return false
      }
      variant = 'error'
    } else {
      setNewTopic(data.editTopic)
      message = t('messages.changes_saved')
      variant = 'success'
      if (callBack) callBack()
    }

    setSnackbar({ message, variant })
  }

  return {
    editTopic,
    editTopicLoading: loading,
    newTopic,
    error,
  }
}

export const useChangeTopicsOrder = (params: any) => {
  const [error, setError] = useState('')
  const { changeTopicsOrderMutation, loading } =
    changeTopicsOrderService(params)

  const changeTopicsOrder = async (
    draggedId: string,
    droppedId: string,
    refetch: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await changeTopicsOrderMutation({
      variables: {
        draggedId,
        droppedId,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        setError(message)
        return false
      }
      variant = 'error'
    } else {
      message = `The changes have successfully been saved`
      variant = 'success'
    }

    // setSnackbar({ message, variant })
  }

  return {
    changeTopicsOrder,
    changeTopicsOrderLoading: loading,
    error,
  }
}

export const useChangeLessonsOrder = (params: any) => {
  const [error, setError] = useState('')
  const { changeLessonOrderMutation, loading } =
    changeLessonOrderService(params)

  const changeLessonsOrder = async (
    draggedId: string,
    droppedId: string,
    refetch: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await changeLessonOrderMutation({
      variables: {
        draggedId,
        droppedId,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        setError(message)
        return false
      }
      variant = 'error'
    } else {
      message = `The changes have successfully been saved`
      variant = 'success'
    }
  }

  return {
    changeLessonsOrder,
    changeLessonsOrderLoading: loading,
    error,
  }
}
