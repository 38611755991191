import { useEffect, useState } from 'react'

import { actionConfig } from '../testLayout/gridConfig'
import { useUserValue } from 'context/UserContext'
import { t } from 'i18next'

import { useParams } from 'react-router-dom'

import useTestDetailsServices from 'hooks/tests/TestDetails/useTestDetailsServices'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import React from 'react'
import { convertToRaw, ContentState } from 'draft-js'

const useTestDetails = () => {
  const [state] = useUserValue()
  const [tab, setTab] = useState(0)
  const { id: testId } = useParams()

  const {
    handleTabChange,
    fetchTestAssessmentGroups,
    fetchTestAssessmentById,
    testAssessment,
    testAssessmentLoading,
    testAssessmentError,
    testAssessmentGroups,
    testAssessmentGroupsLoading,
    testAssessmentGroupsRefetch,
    actionHandler,
  } = useTestDetailsServices({
    testId,
    setTab,
  })

  useEffect(() => {
    if (testId) {
      fetchTestAssessmentById({
        variables: {
          testId,
        },
      })
    }
  }, [testId])

  useEffect(() => {
    if (testAssessment) {
      fetchTestAssessmentGroups({
        variables: {
          testAssessmentId: testId,
          filter: {},
          currentPage: 1,
          perPage: 10,
        },
      })
    }
  }, [testAssessment])

  const actions = actionConfig(actionHandler, state.userPermission, t)

  const breadCrumbData = [
    {
      link: '/Tests',
      label: 'Tests',
      icon: <Learning />,
    },
    {
      label: testAssessment?.name || '',
    },
  ]

  const tabs = [
    { label: t('navigation.groups'), id: '1' },
    { label: t('navigation.students'), id: '2' },
    { label: t('navigation.questions'), id: '3' },
  ]

  const convertTextToEditorState = (text: string): string => {
    const contentState = ContentState.createFromText(text)
    const rawContentState = convertToRaw(contentState)
    return JSON.stringify(rawContentState)
  }

  return {
    state,
    tab,
    groups: testAssessmentGroups?.getGroupsByTestAssessmentId?.data || [],
    totalGroupCount:
      testAssessmentGroups?.getGroupsByTestAssessmentId?.totalCount,
    groupCurrentPage:
      testAssessmentGroups?.getGroupsByTestAssessmentId?.currentPage,
    setTab,
    testId,
    handleTabChange,
    fetchTestAssessmentGroups,
    fetchTestAssessmentById,
    testAssessment,
    testAssessmentLoading,
    testAssessmentError,
    testAssessmentGroups,
    testAssessmentGroupsLoading,
    testAssessmentGroupsRefetch,
    actionHandler,
    actions,
    breadCrumbData,
    tabs,
    convertTextToEditorState,
  }
}

export default useTestDetails
