import React, { useState, useEffect } from 'react'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import { FormGroupWrapper } from 'components/common/Form/Form'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/Close'
import { Button } from 'components/common/Button'
import Step2 from '../AddQuizDrawer/Step2'
import { useAddRemoveQuestions } from 'hooks/quizzes/useEditQuizService'
import { GET_QUESTIONS, GET_QUESTIONS_BY_COURSE } from 'gql/questions.query'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import Pagination from 'components/common/Pagination'
import { PER_PAGE } from 'hooks/questions/useQuestions'
import {
  FormButtons,
  FormFooter,
} from 'pages/questions/LinkQuestionsToModuleDrawer/styled-components'
import { Container } from './styled-components'

const AddQuestionsToQuiz = (props: any) => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [searchValue, setSearchValue] = useState('')

  const [fetchModuleQuestions, { data: moduleQuestions }] =
    useLazyQuery(GET_QUESTIONS)
  const [fetchCourseQuestions, { data: courseQuestions }] = useLazyQuery(
    GET_QUESTIONS_BY_COURSE,
  )

  const defaultFilter = props.data.questionIds?.length
    ? {
        _id: {
          type: LowerCaseFilterableFieldType.ARRAY_NOT_IN,
          value: props.data.questionIds,
        },
      }
    : {}
  const refetchQuestions = (filter?: any): void => {
    if (props.type === 'course') {
      fetchCourseQuestions({
        variables: {
          courseIds: [props.id],
        },
      })
    } else {
      fetchModuleQuestions({
        variables: {
          type: props.type,
          id: props.id,
          filter: {
            ...defaultFilter,
            ...filter,
          },
          currentPage,
          perPage,
        },
      })
    }
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    refetchQuestions({
      question: {
        type: LowerCaseFilterableFieldType.MATCH,
        value: searchValue,
      },
    })
  }

  const handleSearchChange = (e: any) => {
    const { value } = e.target

    setSearchValue(value)
    if (e.target.value === '') {
      refetchQuestions({
        question: {
          type: LowerCaseFilterableFieldType.MATCH,
          value: searchValue,
        },
      })
    }
  }

  useEffect(() => {
    refetchQuestions()
  }, [props.id, props.type, props.data, currentPage, perPage])

  const handlePaginationClick = (event: any, value: number) => {
    if (value !== currentPage) {
      setCurrentPage(value)
      setSelectAll(false)
      setSelectedItem([])
    }
  }

  const handlePerPageChange = (event: React.ChangeEvent<any>) => {
    const eventNum = parseInt(event.target.value)
    setPerPage(eventNum)
    setCurrentPage(1)
    setSelectedItem([])
    setSelectAll(false)
  }

  const questionList =
    props.type === 'course'
      ? (courseQuestions && courseQuestions.questionsByCourseIds) || []
      : (moduleQuestions && moduleQuestions.getQuestions.data) || []

  const totalPages =
    props.type === 'course'
      ? (courseQuestions && courseQuestions.questionsByCourseIds.totalPages) ||
        0
      : (moduleQuestions && moduleQuestions.getQuestions.totalPages) || 0

  const { addRemoveQuestions } = useAddRemoveQuestions()

  const addQuestions = () => {
    if (selectedItem.length > 0)
      addRemoveQuestions('add', selectedItem, props.data.quizId, () => {
        setSelectedItem([])
        props.onClose()
      })
  }

  const handleCloseDrawer = () => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'AddQuestionsToQuiz',
        values: selectedItem,
        compareTo: {},
      },
    })
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const handleFilterClick = (filterOptions: any) => {
    setSelectedItem([])
    setSelectAll(false)
    setCurrentPage(1)
    refetchQuestions(filterOptions)
  }

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          {t('actions.add')} {t('general.questions')}
        </DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <DrawerContent>
        <Container>
          <FormGroupWrapper>
            <Step2
              id={props.id}
              type={props.type}
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              filter={props.data.questionIds}
              questionList={questionList || []}
              searchValue={searchValue}
              handleSearchChange={handleSearchChange}
              handleSearchSubmit={handleSearchSubmit}
              handleFilterClick={handleFilterClick}
              t={t}
            />
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />

              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                onClick={addQuestions}
                background="#06C68F"
              />
            </FormButtons>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                handleChange={handlePaginationClick}
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
              />
            </div>
          </FormFooter>
        </Container>
      </DrawerContent>
    </>
  )
}

export default AddQuestionsToQuiz
