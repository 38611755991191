import { useMutation } from '@apollo/client'
import { PUBLISH_TEST } from 'gql/tests/tests.query'

const publishUnpublishTestService = () => {
  const [publishUnpublishTestMutation, { loading, error }] =
    useMutation(PUBLISH_TEST)

  return {
    publishUnpublishTestMutation,
    loading,
    error,
  }
}

export default publishUnpublishTestService
