import React from 'react'
import {
  Card,
  Header,
  Tag,
  Tags,
  TagsGrid,
  Title,
  Subtitle,
} from './styled-components'
import { Props } from './QuestionsItem.interface'
import CheckBox from 'components/common/Checkbox'
import { t } from 'i18next'
import { ITag } from 'pages/tests/filterTestDrawer/filter.interface'

const QuestionItem = ({ isChecked, selectItem, item }: Props) => {
  const { question, type, tags } = item
  return (
    <Card isChecked={isChecked}>
      <Header>
        <Subtitle>
          {t('general.type')}: {type.label}
        </Subtitle>
        <CheckBox onChange={() => selectItem(item.id)} checked={isChecked} />
      </Header>
      <Title>{question}</Title>
      <Tags>
        <TagsGrid>
          {(tags?.length &&
            tags.map((tag: ITag) => <Tag key={tag.id}>{tag.label}</Tag>)) || (
            <Tag>{t('general.no_tags')}</Tag>
          )}
        </TagsGrid>
      </Tags>
    </Card>
  )
}

export default QuestionItem
