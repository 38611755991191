import { useMutation } from '@apollo/client'

import { EDIT_GROUP, ASSIGN_TUTOR, GET_GROUP } from 'gql/group/group.query'
import { updateCache } from 'services/updateCache'

const editGroupService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editGroupMutation] = useMutation(EDIT_GROUP, {
    update(cache, { data: { editGroup: document } }) {
      updateCache({
        objectName: 'getAllGroups',
        query: GET_GROUP,
        cache,
        document,
        action: 'add',
        params,
      })
    },
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [assignTutorMutation] = useMutation(ASSIGN_TUTOR)

  return {
    editGroupMutation,
    assignTutorMutation,
  }
}

export default editGroupService
