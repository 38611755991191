import React from 'react'

import { Action } from 'components/common/GridV2'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { set } from 'lodash'
import { TFunction } from 'interfaces/TFunction'

export const actionConfig = (
  t: TFunction,
  actionHandler: any,
  permissions: any,
  setIsSearchType?: any,
  mode?: boolean,
) => {
  let action = [
    {
      hide: !permissions.editQuiz,
      render: (item: any) => (
        <Action className="edit" onClick={() => actionHandler('edit', item)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteQuiz,
      render: (item: any) => (
        <Action
          className="delete"
          onClick={() => {
            setIsSearchType && setIsSearchType(false)
            actionHandler('delete', item)
          }}
        >
          <DeleteIcon />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ].filter((i: any) => !i.hide)

  // if (!mode) {
  //   action = [view, ...action]
  // }

  return action
}
