import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import {
  editQuizService,
  addRemoveQuestionsService,
} from 'services/quizzes/editQuizService'

export const useEditQuizService = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  const { editQuizMutation, loading } = editQuizService()

  const editQuiz = async (quizId: string, input: any, fun: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await editQuizMutation({
      variables: { quizId, input },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = t('messages.changes_saved')
      variant = 'success'
      fun()
    }

    setSnackbar({ message, variant })
  }
  return {
    editQuiz,
    loading,
  }
}

export const useAddRemoveQuestions = () => {
  const { setSnackbar } = useSnackbarAlert()

  const { questionsActionMutation } = addRemoveQuestionsService()

  const addRemoveQuestions = async (
    action: string,
    ids: string[],
    quizId: string,
    fun?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await questionsActionMutation({
      variables: {
        quizId,
        action,
        questionIds: ids,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `The Changes have been saved`
      variant = 'success'
      if (fun) fun()
    }

    setSnackbar({ message, variant })
  }

  return {
    addRemoveQuestions,
  }
}
