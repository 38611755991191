import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'

import { default as styledComponent } from 'styled-components'
import { FormikTouched } from 'formik'

const PREFIX = 'SelectField'

const classes = {
  helperText: `${PREFIX}-helperText`,
}

interface Props {
  label?: string
  name?: string
  items?: {
    id?: number
    value?: string | number
    label?: string
  }[]
  value?: string | string[] | number | undefined
  error?: boolean
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
  errorMessage?: string
  isMulti?: boolean
  onChange?: any
  onBlur?: (event: React.ChangeEvent<{ value: unknown }>) => void
}

const SelectField = ({
  label = '',
  name = '',
  items = [],
  value,
  error = false,
  touched = false,
  errorMessage = '',
  isMulti = false,
  onChange,
  onBlur,
}: Props) => {
  return (
    <UiFormControl variant="outlined" error={error} isMulti={isMulti}>
      {label && (
        <UiLabel
          id={
            error
              ? 'demo-simple-select-error-label'
              : 'demo-simple-select-outlined-label'
          }
        >
          {label}
        </UiLabel>
      )}

      <Select
        variant="standard"
        labelId={
          error
            ? 'demo-simple-select-error-label'
            : 'demo-simple-select-outlined-label'
        }
        id={error ? 'demo-simple-select-error' : 'demo-simple-select-outlined'}
        name={name}
        multiple={isMulti}
        value={value || []}
        onChange={onChange}
        onBlur={onBlur}
      >
        {!label && <MenuItem value="all">All</MenuItem>}
        {items.map((item) => (
          <MenuItem key={item.id} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>

      {touched && (
        <FormHelperText className={classes.helperText}>
          {errorMessage}
        </FormHelperText>
      )}
    </UiFormControl>
  )
}

export default SelectField

const UiLabel = styledComponent(InputLabel)``

const UiFormControl = styledComponent(FormControl)<{ isMulti: boolean }>`
  width: 100%;
  .MuiOutlinedInput-input {
    padding: 10.5px 14px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
    background: #fff !important;
  }
  .demo-simple-select-error-label {
    border-color: red !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ error }) =>
      error ? '#f44336' : 'rgba(0, 0, 0, 0.23)'} !important;
  }
  .MuiFormHelperText-root.Mui-error {
    bottom: ${({ isMulti }) => (isMulti ? '-19px' : '-7px')} !important;
  }
`
