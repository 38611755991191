import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { FilterCompanySchema } from 'helpers/validationSchemas'
import { useTranslation } from 'react-i18next'

import {
  DrawerHeader,
  DrawerContent,
  DrawerTitle,
} from 'components/DrawerLayout/styled-components'

import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { SelectField } from 'components/common/SelectField'
import {
  platformServiceOptions,
  statusOptions,
  filterOptions,
} from 'pages/companies/staticData'
import DateRangePicker from 'components/common/DateRangePicker'
import {
  LowerCaseFilterableFieldType,
  UpperCaseFilterableFieldType,
} from 'enums/filterEnum'
import { IFilterCompanies, IFormValues } from './filterCompanies.interface'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'

const FilterCompanies = ({
  onClose,
  filterLoading,
  filter,
  setFilterOptions,
  setCurrentPage,
}: IFilterCompanies) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const { createDate, setCreateDate } = useFilterContext()
  const { filterRole, setFilterRole } = useFilterRoleContext()

  setFilterRole('COMPANIES')

  const { handleSubmit, handleChange, values, setValues, setFieldValue } =
    useFormik({
      initialValues: filterOptions,
      validationSchema: FilterCompanySchema,
      onSubmit(values) {
        const formValues: IFormValues = {}

        if (values.name) {
          formValues.name = {
            type: UpperCaseFilterableFieldType.MATCH,
            value: values.name,
          }
        }

        if (values.platformService.value !== 'all') {
          formValues.platformService = {
            type: UpperCaseFilterableFieldType.EXACT,
            value: values.platformService.value,
          }
        }

        if (values.status.value !== 'all') {
          formValues.status = {
            type: UpperCaseFilterableFieldType.EXACT,
            value: values.status.value,
          }
        }

        createDate[0] = createDate[0] && new Date(createDate[0])
        createDate[1] = createDate[1] && new Date(createDate[1])

        const isSameDate =
          createDate[0] === createDate[1] && createDate[0] !== undefined

        if (!createDate[1] && createDate[0] !== undefined)
          createDate[1] = new Date()

        const formattedData = isSameDate
          ? createDate[0]
          : `${createDate[0] ? createDate[0].toISOString() : ''}${'-'}${
              createDate[1] ? createDate[1].toISOString() : ''
            }`

        if (createDate[0] !== undefined || createDate[1] !== undefined) {
          formValues.createDate = {
            type: isSameDate
              ? LowerCaseFilterableFieldType.EXACT
              : UpperCaseFilterableFieldType.DATE_RANGE,
            value: formattedData,
          }
        }

        setCurrentPage(1)
        setFilterOptions(values)
        filter(formValues)
      },
    })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'COMPANIES') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  useEffect(() => {
    if (filterOptions) {
      setValues(filterOptions)
    }
  }, [])

  return (
    <DrawerContent>
      {filterLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>
          {t('general.advanced_filters')} -{' '}
          {t('companies_layout.filter_companies')}
        </DrawerTitle>
        <IconButton onClick={(): void => onClose('reset')}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <FormContainer onSubmit={handleSubmit}>
        <FormGroupWrapper>
          <FormFields>
            <FormField>
              <TextInput
                label={t('form_fields.name')}
                name="name"
                size="small"
                type="text"
                value={values.name}
                onChange={handleChange}
              />
            </FormField>

            <FormField style={{ position: 'relative' }}>
              {/* <Label>{t('companies_layout.platform_service')}</Label> */}
              <SelectField
                label={t('companies_layout.platform_service')}
                options={platformServiceOptions}
                value={values.platformService}
                onChange={(e: string): Promise<void> =>
                  setFieldValue('platformService', e) as Promise<void>
                }
              />
            </FormField>

            <FormField style={{ position: 'relative' }}>
              {/* <Label>{`${t('general.company')} ${t('general.status')}`}</Label> */}
              <SelectField
                label={`${t('general.company')} ${t('general.status')}`}
                options={statusOptions}
                value={values.status}
                onChange={(e: string): Promise<void> =>
                  setFieldValue('status', e) as Promise<void>
                }
              />
            </FormField>
            <FormField style={{ position: 'relative' }}>
              <DateRangePicker
                placeholder={t('general.create_date')}
                values={createDate}
                setCreateDate={setCreateDate}
              />
            </FormField>
          </FormFields>
        </FormGroupWrapper>

        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.cancel')}
              type="small"
              onClick={(): void => onClose('reset')}
              background="#E0E1E2"
              textColor="#414141"
            />
            <Button
              text={t('actions.filter')}
              type="small"
              color="secondary"
              btnType="submit"
              background="#06C68F"
              isDisabled={!!error}
            />
          </FormButtons>
        </FormFooter>
      </FormContainer>
    </DrawerContent>
  )
}

export default FilterCompanies
