import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { t } from 'i18next'
import { useUserValue } from 'context/UserContext'
import useUpdateTestService from 'hooks/tests/useUpdateTestService'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'
import { useSwal } from 'hooks/useSwal'
import { GET_TAGS } from 'gql/skilss.query'
import { GET_TEST } from 'gql/tests/tests.query'
import { formDataInterface } from 'pages/tests/addTestDrawer/addTestDrawer.interface'
import { TagsInterface } from 'interfaces/common'
import { ITag } from 'pages/tests/filterTestDrawer/filter.interface'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import { TestSchema } from 'helpers/validationSchemas'

interface EditTestDrawerLogicProps {
  onClose: () => void
  id: string // Adjust the type based on your actual ID type
}

export const useEditTestDrawerLogic = ({
  onClose,
  id,
}: EditTestDrawerLogicProps) => {
  const [state] = useUserValue()

  const {
    handleImageChange,
    handleCropSave,
    cropperOpened,
    setCropperOpened,
    file,
    imageLoading,
  } = useUploadImage()

  const formData: formDataInterface = {
    name: '',
    description: '',
    time: 0,
    quizParticipationType: '',
    passRate: 0,
    tags: [],
    randomization: false,
    randomizationNumber: 0,
    attachment: null,
  }

  const { data, loading } = useQuery(GET_TEST, {
    variables: {
      testId: id,
      updateTestAssessmentId: state.selectedCompany?.id || null,
    },
  })

  const { updateTest } = useUpdateTestService({
    testId: id,
    updateTestAssessmentId: state.selectedCompany?.id || null,
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const name = e.target.name
    let value = e.target.value

    if (name === 'time') value = parseFloat(value)
    else if (name === 'quizParticipationType')
      value = value === 'mandatory' ? value : 'optional'
    else if (name === 'passRate') value = parseInt(value)
    else if (name === 'randomizationNumber') value = parseInt(value)

    setFieldValue(name, value)
  }

  const { data: tags } = useQuery(GET_TAGS, {
    variables: {
      companyId: state.selectedCompany?.id,
    },
  })

  const { fireSwal } = useSwal()

  const { handleSubmit, values, errors, touched, setFieldValue, setValues } =
    useFormik({
      initialValues: formData,
      validationSchema: TestSchema,
      onSubmit(values) {
        const data = removeTypeNameFromObject({ ...values })

        if (data.tags && data.tags.length > 0) {
          data.tags = data.tags.map(({ label }: TagsInterface) => ({ label }))
        }
        const companyId = state.selectedCompany?.id || null
        updateTest(id, data, companyId)
        onClose()
      },
    })

  const handleRandomizationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let value
    if (e.target.value === 'true') {
      value = true
      setFieldValue('randomizationNumber', questions?.length)
    } else {
      value = false
      delete values.randomizationNumber
    }

    setFieldValue('randomization', value)
  }

  useEffect(() => {
    if (data && data.getTestAssessmentById) {
      const dataClone: any = {}
      for (const [key] of Object.entries(formData)) {
        dataClone[key] = data.getTestAssessmentById[key]
      }
      dataClone.tags = data.getTestAssessmentById.tags.map(
        ({ label }: ITag) => ({
          label,
          value: label,
        }),
      )

      setValues(dataClone)
    }
  }, [data])

  const handleCloseDrawer = () => {
    if (tagList?.length === 0) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        confirmText: 'Yes, Cancel!',
        onConfirm: () => onClose(),
      })
    }
  }

  const tagList =
    (tags &&
      tags.getAllTags.map(({ label }: TagsInterface) => ({
        label,
        value: label,
      }))) ||
    []
  const questions = data?.getTestAssessmentById?.questions || []

  return {
    questions,
    handleSubmit,
    handleChange,
    values,
    errors,
    handleRandomizationChange,
    touched,
    setFieldValue,
    setValues,
    loading,
    tagList,
    handleImageChange,
    cropperOpened,
    setCropperOpened,
    file,
    imageLoading,
    handleCropSave,
    handleCloseDrawer,
  }
}
