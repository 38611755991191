import { FormField } from 'components/common/Form'
import styled from 'styled-components'
import TextareaAutosize from '@mui/material/TextareaAutosize'

// AddModuleToQuestionDrawer

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  position: sticky;
  z-index: 9999;
  top: 0;
  background: #fff;
`

export const StyledFormField = styled(FormField)`
  .MuiRadio-colorSecondary.Mui-checked {
    color: #06c68f;
  }
`

export const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectedWrapper = styled.div`
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  &.disabled {
    opacity: 0.5;
  }
`

export const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`

export const FormFooter = styled.footer`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
`

export const FormFieldsWrapper = styled.div`
  flex: 2;
  overflow-y: scroll;
`

export const ModulesList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const FormButtons = styled.div`
  align-self: flex-end;
  display: flex;

  div:not(:last-child) {
    margin-right: 10px;
  }
`

// CreateQuestionDrawer

export const ModuleWrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  svg {
    color: #06c68f;
    cursor: pointer;
  }
`

export const HelpButtonContainer = styled.div`
  width: 43px;
  display: flex;
  align-items: center;
  padding: 0 0px 0 14px;
  svg {
    cursor: pointer;
  }
`

// Questionitem

export const FormLabel = styled.label`
  color: #333;
  font-weight: 500;
  font-size: 14px;

  &.full {
    width: 100%;
  }
`

export const FormRules = styled.ul`
  padding-left: 0;
`

export const FormRulesItem = styled.li`
  color: red;

  &.valid {
    color: green;
  }
`
export const FormGroupWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  width: 100%;

  svg {
    color: #06c68f;
    cursor: pointer;
  }
`

export const FormGroup = styled.div`
  width: 100%;
`

export const FormGroupItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  span {
    padding: 0;
  }

  input {
    padding: 10px;
    margin: 0 5px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`
export const QuestionContainer = styled.div`
  .MuiRadio-colorSecondary.Mui-checked {
    color: #06c68f;
  }
`

// UploadContent

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const defs = ({ full }: any) => {
  const styles: any = {}
  if (full) {
    styles.gridTemplateColumns = 'repeat(2, 1fr)'
    styles.gridGap = '22px'
  } else {
    styles.gridTemplateColumns = 'repeat(1, 1fr)'
  }

  return styles
}

export const UploadedContent = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledTextareaAutosize = styled(TextareaAutosize)`
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  max-height: 40px;
  outline: none;
  padding: 10px;
  font-family: sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: border 0.3s ease-in;
  resize: none;

  &:focus {
    border: 1px solid #06c68f;
  }
`
