import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'

import { Wrapper } from '../styled-components'

import Drawer from 'components/common/Drawer'
import Grid from 'components/common/GridV2'
import { columnConfig } from './config'
import TextInput from 'components/common/TextInput/TextInput'
import TablePagination from 'components/common/Pagination/TablePagination'
import { FilterCoaches } from 'pages/coaches'
import { useFetchCompanyAssignedTutors } from 'hooks/users/useCompanyAssignedTutors'
import { TFunction } from 'interfaces/TFunction'
interface Params {
  variables: any
  currentTab: number
  t: TFunction
}

const ROLE = 'COACH'

const CompanyAssignedTutors = ({ variables, currentTab, t }: Params) => {
  const { companyId, filter } = variables
  const defaultFilter = { ...filter }

  const { users, loading, refetch }: any = useFetchCompanyAssignedTutors(
    companyId,
    defaultFilter,
  )

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [filterQuery, setFilterQuery] = useState<any>(filter)
  const [filterOptions, setFilterOptions] = useState<any>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [resetVisible, setResetVisible] = useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    refetch({
      companyId,
      filter: filterQuery,
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      companyId,
      filter: filterQuery,
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
  }

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    let formData = { ...filterQuery }
    if (searchValue !== '') {
      formData = { ...filterQuery, ...formValue }
    } else {
      const { search, ...filters } = filterQuery
      formData = { ...filters }
    }
    refetch({
      companyId,
      filter: formData,
      currentPage: 1,
      perPage,
    })
    setFilterQuery(formData)
    setResetVisible(true)
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    if (currentTab) {
      setSearchValue('')
      setFilterQuery(defaultFilter)
    }
  }, [currentTab])

  if (loading) return <div>Loading...</div>

  const emptyFunc = () => {}

  const handleResetClick = () => {
    refetch({
      companyId,
      filter: defaultFilter,
      currentPage,
      perPage,
    })
    setResetVisible(false)
    setSearchValue('')
    setFilterQuery(defaultFilter)
    setFilterOptions(null)
  }

  const filterData = (filters: any) => {
    let formValue = {}
    if (searchValue !== '') {
      formValue = {
        search: {
          type: 'search',
          value: searchValue,
          fields: ['firstName', 'lastName', 'email', 'phone'],
        },
      }
    }
    refetch({
      companyId,
      filter: { ...filters, ...formValue, ...defaultFilter },
      currentPage: 1,
      perPage,
    })
    setResetVisible(true)

    if (users) {
      setFilterDrawerOpened(false)
      setFilterQuery({ ...filters, ...formValue, ...defaultFilter })
    }
  }

  const config = columnConfig(ROLE, emptyFunc, emptyFunc, emptyFunc, t, true)

  return (
    <div style={{ paddingTop: 20 }}>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => {}}
        totalWidth="600px"
      >
        <FilterCoaches
          role="COACH"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          useCompanyId
          forAssignCoaches={currentTab === 1 ? true : false}
        />
      </Drawer>
      <div style={{ marginBottom: 20 }}>
        <Wrapper justify="between" align="center">
          <Wrapper align="center"></Wrapper>
          <form onSubmit={handleSearchSubmit}>
            <TextInput
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              disabled={loading}
              icon={
                <IconButton type="submit">
                  <SearchRoundedIcon onClick={handleSearchSubmit} />
                </IconButton>
              }
            />
          </form>
        </Wrapper>
      </div>
      <div>
        <Grid
          title={t('general.users')}
          onFiltersClick={() => setFilterDrawerOpened(true)}
          // resetVisible={resetVisible}
          resetVisible={true}
          resetFilters={handleResetClick}
          config={config}
          data={users.data}
        />

        {users && users.totalCount > 0 && (
          <TablePagination
            currentPage={users ? users.currentPage : 0}
            rowsPerPage={perPage}
            count={users ? users.totalCount : 0}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
    </div>
  )
}

export default CompanyAssignedTutors
