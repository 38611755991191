import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import AppBar from '@mui/material/AppBar'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Button } from 'components/common/Button'
import {
  activeTabClassName,
  TabNavigation,
  TabPanel,
} from 'components/common/Tabs/Tabs'
import styled from 'styled-components'
import { CHECK_USER_EMAIL } from 'gql/users.query'
import { useMutation } from '@apollo/client'
import { UserSchema } from 'helpers/validationSchemas'
import useCompaniesService from 'hooks/useCompaniesService'
import { useFetchUsers } from 'hooks/users/useUsers'

import {
  ButtonWrapper,
  Container,
  FooterWrapper,
  Wrapper,
} from '../styled-components'
import CompanySuperAdminList from './CompanySuperAdminList'
import CreateCompanySuperAdmin from './CreateCompanySuperAdmin'

interface Manager {
  id?: string
  firstName: string
  lastName: string
  email: string
  phone: string
  location: string
  gender: any
  jobTitle: string
  phoneFields: any
  phoneFieldValue: any
  biography: string
  note: string
}

const formData: Manager = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  location: '',
  biography: '',
  note: '',
  gender: null,
  jobTitle: '',
  phoneFields: null,
  phoneFieldValue: null,
}
const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const CompanySuperAdmins = (props: any) => {
  const { companyData, setData } = props
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(0)
  const [createAdmin, setCreateAdmin] = useState('')

  const [checkEmail] = useMutation(CHECK_USER_EMAIL)
  const { useAddManager, useEditManager, setCompanyState } =
    useCompaniesService()
  const { users, refetch }: any = useFetchUsers('SUPER_ADMIN', {
    companyId: { type: 'exact', value: companyData.companyData.id },
  })

  useEffect(() => {
    if (users && users.data) {
      setData((prev: any) => ({
        ...prev,
        managers: users.data || [],
      }))
    }
  }, [users])

  const companyManagers = (companyData && companyData.managers) || []

  const {
    handleSubmit,
    handleChange,
    resetForm,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: UserSchema,
    async onSubmit(values) {
      const data = { ...values }

      delete data.phoneFieldValue

      if (data.gender) data.gender = data.gender.value
      if (data.phoneFields && data.phoneFields.label) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null
      }
      const res = await checkEmail({
        variables: { email: values.email },
      })

      if (res.errors) {
        errors.email = res.errors[0].message
        return false
      }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useAddManager(data, companyData.companyData.id, () => {
        refetch()
        if (createAdmin === 'reset') {
          resetForm()
        } else {
          resetForm()
          setTabValue(1)
        }
      })
    },
  })

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const onFormSubmit = (action: string) => {
    setCreateAdmin(action)
    handleSubmit()
  }

  return (
    <>
      <Container>
        <Wrapper width="74%">
          <ContentWrapper>
            <TabNavigation withBorder={true}>
              <AppBar position="static" style={styles.bar}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label={props.t(
                      'company_wizard_layout.create_company_super_admin',
                    )}
                    {...activeTabClassName(0)}
                    style={styles.btn}
                  />
                  <Tab
                    label={props.t(
                      'company_wizard_layout.company_super_admin_list',
                    )}
                    {...activeTabClassName(1)}
                    style={styles.btn}
                  />
                </Tabs>
              </AppBar>

              <TabPanel value={tabValue} index={0}>
                <CreateCompanySuperAdmin
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleSubmit={onFormSubmit}
                  addUserLoading={false}
                  setFieldValue={setFieldValue}
                  t={props.t}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <CompanySuperAdminList
                  superAdminList={companyManagers}
                  companyId={companyData.companyData.id}
                  refetch={refetch}
                  t={props.t}
                />
              </TabPanel>
            </TabNavigation>
          </ContentWrapper>
        </Wrapper>

        <Wrapper width="calc(26% - 20px)">
          <TextContainer>
            {props.t(
              'company_wizard_layout.invite_company_super_admins_description',
            )}
          </TextContainer>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={props.t('actions.cancel')}
            type="small"
            onClick={props.onCancel}
            background="#E0E1E2"
            textColor="#414141"
          />
        </ButtonWrapper>

        <ButtonWrapper>
          <div style={{ marginRight: '15px' }}>
            <Button
              text={props.t('actions.previous')}
              type="small"
              onClick={() => props.changeStep(0)}
              background="#E0E1E2"
              textColor="#414141"
            />
          </div>

          <div style={{ marginRight: '15px', height: '31px' }}>
            <Button
              text={props.t('actions.save_as_draft')}
              type="small"
              color="secondary"
              onClick={() => {
                setCompanyState(companyData.companyData.id, 2, 'Success', () =>
                  navigate('/Companies'),
                )
              }}
              textColor="#06C68F"
              background="#f5f5f5"
              outline="1px solid #06C68F"
            />
          </div>

          <Button
            text={props.t('actions.save_and_next')}
            type="small"
            color="secondary"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 2, 'Success', () =>
                props.changeStep(2),
              )
            }}
            background="#06C68F"
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default CompanySuperAdmins

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 0px 50px;
`
const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`
