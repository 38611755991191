import { ReactElement } from 'react'

export interface LoadingProps {
  type: FileTypes
  loading: boolean | number
}

export interface UploaderProps {
  placeHolder?: string
  type: FileTypes
  label: ReactElement
  inputValue: string
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFileChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  loading?: boolean
  uploadedFile: any
  id?: string
  accept?: string
  multiple?: boolean
  name?: string
  showFullPreview?: boolean
  deleteFile: () => void
  inputClassName?: string
  nameClassName?: string
}

export enum FileTypes {
  DOCUMENT = 'document',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  FILE = 'file',
}

export interface UploaderButtonProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  id: string
  accept: string
  loading: boolean
  label: ReactElement
}
