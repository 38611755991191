import { useMutation } from '@apollo/client'

import { ADD_QUESTIONS_TO_MODULE } from 'gql/questions.query'

const addQuestionsToModuleService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addQuestionsToModuleMutation, { loading, error }] = useMutation(
    ADD_QUESTIONS_TO_MODULE,
  )

  return {
    addQuestionsToModuleMutation,
    loading,
    error,
  }
}

export default addQuestionsToModuleService
