import React from 'react'
import {
  SelectContainer,
  Label,
  StyledSelectField,
  Entries,
  StyledSelectFieldContainer,
  IconContainer,
} from './styled-component'
import { SelectFieldProps } from './selectDropDown.interface'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const SelectField: React.FC<SelectFieldProps> = ({
  value,
  onChange,
  options,
}) => {
  return (
    <SelectContainer>
      <Label>Show</Label>
      <StyledSelectFieldContainer>
        <StyledSelectField value={value} onChange={onChange}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSelectField>
        <IconContainer>
          <ArrowDropDownIcon />
        </IconContainer>
      </StyledSelectFieldContainer>
      <Entries>Entries</Entries>
    </SelectContainer>
  )
}

export default SelectField
