import { useMutation } from '@apollo/client'
import { DELETE_TEST } from 'gql/tests/tests.query'

const deleteTestService = (id: string[]) => {
  const [deleteTestMutation, { loading, error }] = useMutation(DELETE_TEST)

  return {
    deleteTestMutation,
    loading,
    error,
  }
}

export default deleteTestService
