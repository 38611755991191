import React, { useState } from 'react'
import {
  Wrapper,
  DocumentContainer,
  DetailsTitle,
  DetailsList,
  DetailsListItem,
  NoValueWrapper,
  StyledBottomBorder,
  StyledDetailsContainer,
  StyledOverviewContainer,
  StyledConditionsContainer,
  RightCorner,
} from './styled-components'
import { ModuleItemWrapper } from '../styled-components'
import HeadsetIcon from '@mui/icons-material/Headset'
import FileWrapper from 'components/common/FileWrapper'
import Modal from 'components/common/Modal'
import AudioPlayer from 'components/common/AudioPlayer'
import { StyledTaskDetailContainer } from 'pages/tasks/TaskLayout/styled-component'
import { QuestionType } from 'hooks/tests/TestDetails/QuestionsLayout/styled-components'
import { TFunction } from 'interfaces/TFunction'

interface ITags {
  id: string
  label: string
}

interface IModule {
  moduleId: string
  name: string
}

interface Document {
  name: string
  fileType: string
  size: string
  link: string
}

interface Audio {
  name: string
  fileType: string
  link: string
}

interface Question {
  id: string
  title: string
  companyId: string
}

interface Params {
  type: string
  tags: ITags[]
  modules: IModule[]
  documents: Document[]
  audios: Audio[]
  currentModule: any
  question: Question
  t: TFunction
}

const QuestionInfo = ({
  type,
  tags,
  // question,
  modules,
  // currentModule,
  documents,
  audios,
  t,
}: Params): React.JSX.Element => {
  const [audioModalOpened, setAudioModalOpened] = useState<boolean>(false)
  const [currentAudio, setCurrentAudio] = useState<string>('')

  const handleDocumentClick = (link: string): Window | null => window.open(link)

  const handleAudioClick = (audio: Audio): void => {
    setAudioModalOpened(true)
    setCurrentAudio(audio.link)
  }
  const renderAudios = (): React.JSX.Element => {
    return (
      <Wrapper>
        {audios.map((audio: Audio, i: number) => (
          <DocumentContainer key="">
            <FileWrapper
              key={i}
              icon={<HeadsetIcon color="secondary" />}
              file={audio}
              onClick={(): void => handleAudioClick(audio)}
            />
          </DocumentContainer>
        ))}
        <Modal
          isOpened={audioModalOpened}
          onClose={(): void => setAudioModalOpened(false)}
        >
          <AudioPlayer url={currentAudio && currentAudio} />
        </Modal>
      </Wrapper>
    )
  }

  const renderDocuments = (): React.JSX.Element => {
    return (
      <Wrapper>
        {documents.map((document: Document, i: number) => (
          <DocumentContainer key={i}>
            <FileWrapper file={document} onClick={handleDocumentClick} />
          </DocumentContainer>
        ))}
      </Wrapper>
    )
  }

  return (
    <StyledTaskDetailContainer>
      <StyledBottomBorder>
        <DetailsTitle>{t('question_details.question_details')}</DetailsTitle>
        <RightCorner>
          <QuestionType style={{ fontSize: '16px' }}>{type}</QuestionType>
        </RightCorner>
      </StyledBottomBorder>
      <StyledDetailsContainer>
        <StyledOverviewContainer>
          <DetailsListItem>
            <strong>{t('general.attached_documents')}:</strong>
            {documents && renderDocuments()}
          </DetailsListItem>
          <DetailsListItem>
            <strong>{t('general.uploaded_audios')}:</strong>
            {audios && renderAudios()}
          </DetailsListItem>
        </StyledOverviewContainer>
        <StyledOverviewContainer>
          <DetailsListItem>
            <strong>{t('general.tags')}:</strong>{' '}
            {tags.map((tag: ITags) => (
              <ModuleItemWrapper key={tag.id}>{tag.label}</ModuleItemWrapper>
            ))}
            {tags.length === 0 && (
              <NoValueWrapper>{t('general.no_tags')}</NoValueWrapper>
            )}
          </DetailsListItem>
        </StyledOverviewContainer>
        <StyledConditionsContainer>
          <DetailsListItem>
            <strong>{t('question_details.linked_to_modules')}:</strong>
            {modules.map((moduleItem: IModule) => (
              <ModuleItemWrapper key={moduleItem.moduleId}>
                #{moduleItem.name}
              </ModuleItemWrapper>
            ))}
            {modules.length === 0 && (
              <NoValueWrapper>{t('general.no_modules')}</NoValueWrapper>
            )}
          </DetailsListItem>
        </StyledConditionsContainer>
      </StyledDetailsContainer>
      <DetailsList></DetailsList>
    </StyledTaskDetailContainer>
  )
}

export default QuestionInfo
