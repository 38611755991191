import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  LoginTitle,
  LoginSubText,
  CheckboxContainer,
  LoginContent,
  ServerErorBox,
  StyledLink,
  LoginContentRight,
  LoginButton,
} from './styled-components'
import { useFormik } from 'formik'
import { Alert } from '@mui/lab'
import useAuth from 'hooks/useAuth'
import { LoginSchema } from 'helpers/validationSchemas'
import AuthLayout from 'components/AuthLayout/'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'

interface InitialValue {
  email: string
  password: string
  remember: boolean
}

const initialValues: InitialValue = {
  email: '',
  password: '',
  remember: false,
}

const Login = () => {
  const { t } = useTranslation()
  const { loading, login, error } = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const { handleSubmit, handleChange, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: LoginSchema,

      // eslint-disable-next-line no-shadow
      async onSubmit(values) {
        const data: any = { ...values }
        delete data.remember
        login(data)
      },
    })

  const onSignInClick = async (e: React.FormEvent) => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <AuthLayout>
      <Container>
        <LoginTitle>{t('login.welcome')}</LoginTitle>
        <LoginSubText>{t('login.sign_in_description')}</LoginSubText>

        {/* <Logo>
          <img src="./package-lock.png" alt="package lock" />
        </Logo> */}
        <LoginContent>
          <ServerErorBox className={`${error ? 'show' : ''}`}>
            <Alert severity="error">{error}</Alert>
          </ServerErorBox>
          <form autoComplete="off" onSubmit={onSignInClick}>
            <Input
              label={t('form_fields.email')}
              name="email"
              type="text"
              value={values.email}
              onChange={handleChange}
              error={!!errors.email}
              errorMessage={errors.email}
              touched={touched.email}
            />
            <Input
              label={t('form_fields.password')}
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange}
              error={!!errors.password}
              errorMessage={errors.password}
              touched={touched.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <CheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.remember}
                    icon={
                      <CheckBoxOutlineBlankIcon style={{ fontSize: '1em' }} />
                    }
                    checkedIcon={<CheckBoxIcon style={{ fontSize: '1em' }} />}
                    name="checkedI"
                    onChange={() => setFieldValue('remember', !values.remember)}
                  />
                }
                label={
                  <span style={{ color: 'rgb(65 65 65 / 67%)' }}>
                    {t('login.remember_me')}
                  </span>
                }
              />
            </CheckboxContainer>

            <LoginButton>
              <Button
                btnType="submit"
                text={t('login.sign_in')}
                loading={loading}
                size="large"
                background="#06C68F"
              />
            </LoginButton>
            <LoginContentRight>
              <StyledLink>
                <Link to="/forgot-password">{t('login.forgot_password')}?</Link>
              </StyledLink>
            </LoginContentRight>
          </form>
        </LoginContent>
      </Container>
    </AuthLayout>
  )
}

export default Login
