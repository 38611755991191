import React, { useState, useEffect, ReactElement } from 'react'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { StyledH1, Container, ColumnContainer } from './styled-components'
import { useUserValue } from 'context/UserContext'
import { useEditProfile } from 'hooks/users/useEditProfileService'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { TOGGLE_COMPANY_SETTINGS } from 'gql/companies.query'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import {
  HandleCompanySettingsFields,
  HandleCompanySettingsFieldsType,
  HandleSwitchChange,
  CompanyPolicyEnums,
} from './main.interface'
import { useFetchCompany } from 'hooks/companies/useCompanies'

const Settings = (): ReactElement => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const {
    currentUser: { settings },
    selectedCompany,
  } = state

  const companyId = selectedCompany?.id || state.currentUser.companyId || null
  const { company } = useFetchCompany(companyId)

  const [values, setValues] = useState({
    askPassword: false,
    exploreCourses: false,
    showLeaderboardByGroup: false,
    showNewsFeedByGroup: true,
  })

  const { editProfile } = useEditProfile(state.currentUser)
  const [toggleCompanySettings] = useMutation(TOGGLE_COMPANY_SETTINGS)

  const handleSwitchChange = (setting: HandleSwitchChange): void => {
    if (setting === HandleSwitchChange.askPassword) {
      editProfile({
        settings: {
          askPassword: !values.askPassword,
        },
      })
    } else {
      let variables: HandleCompanySettingsFieldsType = {
        companyId,
      }
      switch (setting) {
        case HandleSwitchChange.exploreCourses:
          variables = {
            ...variables,
            field: HandleCompanySettingsFields.exploreCourses,
            boolValue: !values.exploreCourses,
          }
          break
        case HandleSwitchChange.showLeaderboardByGroup:
          variables = {
            ...variables,
            field: HandleCompanySettingsFields.showLeaderboardBy,
            value: !values.showLeaderboardByGroup
              ? CompanyPolicyEnums.GROUP
              : CompanyPolicyEnums.COMPANY,
          }
          break
        case HandleSwitchChange.showNewsFeedByGroup:
          variables = {
            ...variables,
            field: HandleCompanySettingsFields.showNewsFeedBy,
            value: !values.showNewsFeedByGroup
              ? CompanyPolicyEnums.GROUP
              : CompanyPolicyEnums.COMPANY,
          }
          break
      }

      toggleCompanySettings({
        variables,
      })
    }

    setValues({
      ...values,
      [setting]: !values[setting],
    })
  }

  useEffect(() => {
    if (settings) {
      setValues({
        ...values,
        askPassword: settings.askPassword,
      })
    }
  }, [settings])

  useEffect(() => {
    if (company) {
      setValues({
        ...values,
        exploreCourses: company.exploreCourses,
        showLeaderboardByGroup:
          company.showLeaderboardBy === CompanyPolicyEnums.GROUP,
        showNewsFeedByGroup:
          company.showNewsFeedBy === CompanyPolicyEnums.GROUP,
      })
    }
  }, [company])

  return (
    <Container>
      <StyledH1>{t('settings_layout.title')}</StyledH1>
      <ColumnContainer>
        {companyId ? (
          <>
            <CustomSwitcher
              onChange={(): void =>
                handleSwitchChange(HandleSwitchChange.exploreCourses)
              }
              label={t('settings_layout.explore_courses')}
              name="exploreCourses"
              value={values.exploreCourses}
              on={t('general.on')}
              off={t('general.off')}
              tooltip={t('company_details.explore_courses_helper')}
            />
            <CustomSwitcher
              onChange={(): void =>
                handleSwitchChange(HandleSwitchChange.showLeaderboardByGroup)
              }
              label={t('settings.show_leaderboard_by')}
              name="showLeaderboardBy"
              value={values.showLeaderboardByGroup}
              on={t('navigation.groups')}
              off={t('navigation.company')}
              tooltip={t('settings.show_leaderboard_by_helper')}
            />
            <CustomSwitcher
              onChange={(): void =>
                handleSwitchChange(HandleSwitchChange.showNewsFeedByGroup)
              }
              label={t('settings.news_feed_scope')}
              name="showNewsFeedBy"
              value={values.showNewsFeedByGroup}
              on={t('navigation.groups')}
              off={t('navigation.company')}
              tooltip={t('settings.news_feed_scope_helper')}
            />
          </>
        ) : (
          <FormControlLabel
            control={
              <Switch
                checked={values.askPassword}
                onChange={(): void =>
                  handleSwitchChange(HandleSwitchChange.askPassword)
                }
                value="checkedB"
                style={{ color: '#06C68F' }}
              />
            }
            label={t('settings_layout.aks_new_user_for_password_change')}
            labelPlacement="start"
          />
        )}
      </ColumnContainer>
    </Container>
  )
}

export default Settings
