import { useMutation } from '@apollo/client'
import { UPDATE_STUDENT } from 'gql/users.query'

const updateStudentService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateStudentMutation, { loading, error }] = useMutation(
    UPDATE_STUDENT,
    {},
  )

  return {
    updateStudentMutation,
    loading,
    error,
  }
}

export default updateStudentService
