import { CSSProperties } from 'react'
import styled from 'styled-components'

// gridConfig

export const Action = styled.div<{
  hoverColor?: string
  hoverBackground?: string
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  gap: 6px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    /* background: ${({ hoverBackground }) =>
      hoverBackground || 'rgba(0, 0, 0, 0.1)'}; */
    ${({ hoverColor }) =>
      hoverColor &&
      `svg path {
        fill: ${hoverColor};
         }`}
  }
  svg {
    font-size: 1rem;
    transition: 0.3s ease;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`

// QuizLayout

export const Header = styled.header`
  margin-bottom: 20px;
  width: 100%;

  &.withBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
  }

  button.makeStyles-button-247 {
    padding: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
`

export const StyledQuizOverview = styled.div`
  font-size: 16px;
  font-weight: 550;
  padding-bottom: 5px;
  padding-top: 0px;
`
export const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
export const Text = styled.div`
  text-align: right;
  font-size: 14px;
  color: #ff3a52;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const toolTipStyles: CSSProperties = {
  fontSize: 14,
  width: '180px',
  textAlign: 'center',
  display: 'inline-block',
}
