import { useState } from 'react'
import { useFetchQuestions } from 'hooks/questions/useQuestions'
import { useRemoveQuestionsFromTestAssessment } from 'hooks/questions/useRemoveQuestionsFromTestAssessment'
import { IUseTestQuestionsServices } from './testQuestions.interface'
import { IQuestion, QuestionFilterOptions } from 'interfaces/questions'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import { columnConfig } from './columnConfig'
import { actionConfig } from '../GroupDetails/gridConfig'
import { useUserValue } from 'context/UserContext'
import {
  ISelectAll,
  ISelectedItem,
} from 'pages/tests/testLayout/testLayout.interface'
import { useNavigate } from 'react-router-dom'

const useTestQuestionsServices = ({
  testAssessmentId,
  testAssessmentQuestions,
}: IUseTestQuestionsServices) => {
  const navigate = useNavigate()
  const { fireSwal } = useSwal()
  const { t } = useTranslation()

  const [addQuestionsDrawer, setAddQuestionsDrawer] = useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)

  const [state] = useUserValue()
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>([])
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [searchValue, setSearchValue] = useState<string>('')
  const [filterData, setFilterData] = useState<QuestionFilterOptions>({})
  const [filtered, setFiltered] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)

  const defaultFilters = {
    _id: {
      type: 'arrayIn',
      value: testAssessmentQuestions || [],
    },
  }

  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectedItemsOnPage = selectedItem[currentPage] || []

  const { questions, loading, refetch } = useFetchQuestions(
    '',
    '',
    defaultFilters,
    currentPage,
    perPage,
  )

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] =
        questions?.data.map((question: IQuestion) => question.id) || []
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const { removeQuestionsFromTestAssessment } =
    useRemoveQuestionsFromTestAssessment(testAssessmentId)

  const handleAddQuestionsToTestAssessment = () => {
    setAddQuestionsDrawer(false)
    setSelectedItem({})
  }

  const handleRemoveQuestionsFromTestAssessment = (questions: string[]) => {
    fireSwal({
      title: t('popups.delete_question_many_in_test'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        setSelectedItem({})
        removeQuestionsFromTestAssessment(testAssessmentId, questions)
      },
    })
  }

  const handleFilterClick = (filterOptions: QuestionFilterOptions) => {
    setSelectedItem({})
    const searchQuery = {
      question: { type: 'match', value: searchValue },
    }
    refetch({
      filter: {
        ...defaultFilters,
        ...searchQuery,
        ...filterOptions,
      },
      currentPage: 1,
    })
    resetSelectedItems(perPage)
  }

  const handleSearchSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    refetch({
      type: '',
      id: '',
      filter: {
        ...defaultFilters,
        question: { type: 'match', value: searchValue },
      },
    })
    setFiltered(true)
    resetSelectedItems(perPage)
  }

  const handleResetClick = () => {
    setFilterData({})
    setFiltered(false)
    refetch({
      type: '',
      id: '',
      filter: defaultFilters,
      perPage,
      currentPage,
    })
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setSearchValue(value)
    if (e.target.value === '') {
      refetch({
        type: '',
        id: '',
        filter: defaultFilters,
        perPage,
        currentPage,
      })
    }
  }

  const redirectToQuestion = (question: IQuestion) => {
    navigate(`/questions/${question.id}`)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPerPage = parseInt(event.target.value, 10)
    refetch({
      filter: {
        ...defaultFilters,
        question: { type: 'match', value: searchValue },
      },
      currentPage,
      perPage: newPerPage,
    })
    setPerPage(newPerPage)
    resetSelectedItems(newPerPage)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setCurrentPage(newPage + 1)
    refetch({
      filter: {
        ...defaultFilters,
        question: { type: 'match', value: searchValue },
      },
      currentPage: newPage + 1,
      perPage,
    })
    setSelectedItem({})
    setSelectAll({})
  }

  const resetSelectedItems = (perPage = 10) => {
    setSelectedItem({})
    setSelectAll({})
    setCurrentPage(1)
    setPerPage(perPage)
  }

  const config = columnConfig(selectItem, t)
  const actions = actionConfig(handleRemoveQuestionsFromTestAssessment, t)

  return {
    state,
    config,
    actions,
    selectItem,
    selectAll,
    selectAllItem,
    currentPage,
    selectedItem,
    redirectToQuestion,
    selectedItemsOnPage,
    questions,
    questionsLoading: loading,
    questionsRefetch: refetch,
    handleAddQuestionsToTestAssessment,
    handleRemoveQuestionsFromTestAssessment,
    handleSearchChange,
    handleResetClick,
    handleSearchSubmit,
    handleFilterClick,
    filtered,
    setFiltered,
    filterData,
    perPage,
    setFilterData,
    setAddQuestionsDrawer,
    addQuestionsDrawer,
    filterDrawerOpened,
    searchValue,
    setSearchValue,
    setFilterDrawerOpened,
    handleChangePage,
    handleChangeRowsPerPage,
    setCurrentPage,
  }
}

export default useTestQuestionsServices
