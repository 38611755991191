import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useState } from 'react'
import publishUnpublishTestService from 'services/tests/publishUnpublishTestService'

const usePublishUnpublishTestService = () => {
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { publishUnpublishTestMutation } = publishUnpublishTestService()
  const [data, setData] = useState<any>(null)

  const publishUnpublishTestAssessment = async (
    testAssessmentId: string,
    published: Boolean,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await publishUnpublishTestMutation({
      variables: {
        testAssessmentId,
        published,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
      setError(message)
    } else {
      message = `Test ${published ? 'published' : 'unpublished'} successfully`
      variant = 'success'
      setData(data.updateTestAssessment)
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return { publishUnpublishTestAssessment, loading, error, data }
}

export default usePublishUnpublishTestService
