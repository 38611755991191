import i18n from 'i18n/index'
import { CourseCompleteCriteriaEnums } from './courseList.interface'
interface Option {
  id?: number
  label?: string
  value?: string | boolean | number
}

interface CurrencyOption {
  id: number
  value: string
  label: string
}

export let courseState: Option[] = []
export let courseLevels: Option[] = []
export let completeCriterias: Option[] = []
export let currencyList: CurrencyOption[] = []
export let coursePrivacy: Option[] = []
export let selectOptions: Option[] = []
export let videosOptions: Option[] = []
export let filterOptions = {}

function translate(): void {
  courseState = [
    { label: i18n.t('general.all'), value: 'all' },
    { id: 1, value: 'published', label: i18n.t('courses_layout.published') },
    {
      id: 2,
      value: 'unpublished',
      label: i18n.t('courses_layout.unpublished'),
    },
  ]

  completeCriterias = [
    {
      id: 1,
      label: i18n.t('course_complete_criterias.watch'),
      value: CourseCompleteCriteriaEnums.WATCH,
    },
    {
      id: 2,
      label: i18n.t('course_complete_criterias.watchAndPassQuizzes'),
      value: CourseCompleteCriteriaEnums.WATCH_AND_PASS_QUIZZES,
    },
    {
      id: 3,
      label: i18n.t('course_complete_criterias.watchAndPassQuizzesAndSTV'),
      value: CourseCompleteCriteriaEnums.WATCH_AND_PASS_QUIZZES_AND_STV,
    },
  ]

  courseLevels = [
    { value: 'beginner', label: i18n.t('courses_layout.beginner') },
    { value: 'intermediate', label: i18n.t('courses_layout.intermediate') },
    { value: 'advanced', label: i18n.t('courses_layout.advanced') },
  ]

  currencyList = [
    { id: 1, value: 'USD', label: i18n.t('currency.usd') },
    { id: 2, value: 'EUR', label: i18n.t('currency.eur') },
    { id: 2, value: 'GBP', label: i18n.t('currency.gbp') },
  ]

  coursePrivacy = [
    { label: i18n.t('general.all'), value: 'all' },
    { id: 1, value: 'private', label: i18n.t('course_details.private') },
    { id: 2, value: 'public', label: i18n.t('course_details.public') },
  ]

  selectOptions = [
    { label: i18n.t('general.all'), value: 'all' },
    { label: i18n.t('general.yes'), value: 'yes' },
    { label: i18n.t('general.no'), value: 'no' },
  ]

  videosOptions = [
    { label: i18n.t('general.all'), value: 'all' },
    { label: i18n.t('general.yes'), value: true },
    { label: i18n.t('general.no'), value: false },
  ]

  filterOptions = {
    name: '',
    state: { label: i18n.t('general.all'), value: 'all' },
    level: { label: i18n.t('general.all'), value: 'all' },
    coursePrivacy: { label: i18n.t('general.all'), value: 'all' },
    coaches: [],
    group: [],
    skills: [],
    video: { label: i18n.t('general.all'), value: 'all' },
    company: [],
  }
}

i18n.on('languageChanged init', () => {
  translate()
})
