import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { useData } from 'context/DataContext'

import { useFormik } from 'formik'
import { ISelectOption } from 'pages/customNotifications/customNotification.interface'
import _ from 'lodash'
import { GroupSchema } from 'helpers/validationSchemas'
import { checkValues } from 'pages/group/checkGroupsValues'
import { EditGroupDrawerProps, initialFormData } from './useGroupDrawer'
import { SET_CREATED_USER } from 'store/types'
import useEditGroup from '../useEditGroup'
import { GET_GROUP_BY_ID } from 'gql/group/group.query'
import { useQuery } from '@apollo/client'
import { generateFullName } from 'utils/utils'
import { User } from '@sentry/react'

const useEditGroupDrawer = ({
  id,
  companyId,
  courseData,
  onClose,
  afterSave,
}: EditGroupDrawerProps) => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()

  const { editGroup, loading } = useEditGroup({
    filter: {
      company: {
        type: LowerCaseFilterableFieldType.EXACT,
        value: companyId,
      },
    },
    currentPage: 1,
    perPage: 10,
  })

  const [dataState, dataDispatch] = useData()

  useEffect(() => {
    if (
      dataState.createdObject.obj &&
      dataState.createdObject.type === 'admin'
    ) {
      const adminOption = {
        value: dataState.createdObject.obj.id,
        label: `${dataState.createdObject.obj.firstName} ${dataState.createdObject.obj.lastName}`,
      }

      setFieldValue(
        'admin',
        values.admin ? [...values.admin, adminOption] : [adminOption],
      )

      dataDispatch({
        type: SET_CREATED_USER,
        payload: { obj: null, type: '' },
      })
    }
  }, [dataState.createdObject.obj])

  const { data } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      id,
    },
  })

  useEffect(() => {
    if (data && data.getGroupById) {
      setValues({
        name: data.getGroupById.name,
        location: data.getGroupById.location,
        note: data.getGroupById.note,
        isNewsFeedAvailable: data.getGroupById.isNewsFeedAvailable,
        admin: data.getGroupById.admin
          ? data.getGroupById.admin.map((i: User) => ({
              label: generateFullName(i),
              value: i.id,
            }))
          : null,
        courses:
          data.getGroupById.courses?.map((i: any) => ({
            label: i.name,
            value: i.courseId,
          })) || [],
      })
    }
  }, [data])

  const groupData = (data && data.getGroupById) || null

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialFormData,
    validationSchema: GroupSchema,
    onSubmit: async (values: any) => {
      const data = { ...values }

      if (data.admin) {
        data.admin = data.admin.map((i: ISelectOption) => i.value)
      }

      if (courseData) data.courses = courseData
      else
        data.courses =
          values.courses?.map((i: ISelectOption) => ({
            courseId: i.value,
            name: i.label,
          })) || []

      await editGroup(data, groupData.id, () => {
        if (afterSave) afterSave()
      })

      onClose()
    },
  })

  const handleCloseDrawer = () => {
    const isEmpty = checkValues(values)

    if (isEmpty) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          onClose()
        },
        onClose: () => {},
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }
  return {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    loading,
    handleCloseDrawer,
  }
}

export default useEditGroupDrawer
