import React, { useEffect, useState } from 'react'
import { Container, ErrorMessage } from './styled-components'
import Select, { components } from 'react-select'

interface Props {
  loading: boolean
  error: any
  isMulti: boolean
  onChange: any
  label: string
  onInputChange: any
  data: any
  disabled: any
  touched: any
  value: any
  isClearable: boolean
  errorMessage: string
  labelDataName: any
  labelDataKey: string
}

const customStyles: any = {
  // menuPortal: (provided: any, state: any) => ({
  //   ...provided,
  //   background: 'red',
  //   zIndex: '99',
  // }),
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: '99',
  }),
}

const CoursesAsyncSelect = (props: any) => {
  const [options, setOptions] = useState([] as any)
  useEffect(() => {
    const localOptions = props.data.map((i: any) => {
      const customId = i.isBought && !!i.originalId ? 'originalId' : 'id'
      return {
        label: props.labelDataKey
          ? i[props.labelDataKey]
          : props.labelDataName(i),
        value: i[customId],
        icon: i[props.iconDataKey],
      }
    })
    setOptions(localOptions)
  }, [props.data])

  const { Option } = components
  const CustomSelectOption = (props: any) => (
    <Option {...props}>
      {props.data.label}
      {props.data.icon}
    </Option>
  )

  const CustomSelectValue = (props: any) => (
    <div>
      {props.data.label}
      {props.data.icon}
    </div>
  )

  return (
    <Container error={props.error && props.touched}>
      <Select
        isLoading={props.loading}
        isMulti={props.isMulti}
        options={options}
        value={props.value}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        placeholder={props.label}
        isDisabled={props.disabled}
        isClearable={props.isClearable}
        styles={customStyles}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
        }}
      />

      {props.touched && props.error && props.errorMessage && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </Container>
  )
}

export default CoursesAsyncSelect
