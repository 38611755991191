import { styled } from 'styled-components'

export const StyledFlex = styled.div`
  display: flex;
  gap: 30px;
`

export const StyledBorder = styled.div`
  border-right: 1px solid #e7e9ed;
  height: 200px;
`

export const ProfileBackground = styled.div`
  width: 30%;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export const AvatarContainer = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75%;
`

export const ActionsGrid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 25%;
`

export const ActionsItem = styled.div`
  display: flex;
  font-size: 12px;
  width: 100%;
  justify-content: center;
  color: #68707a;

  .action-content {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }

  :active {
    opacity: 0.8;
  }
`

export const InformationBackground = styled.div`
  width: 70%;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  min-height: 300px;
`

export const CenteredText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  color: #68707a;
  font-size: 18px;
`

export const CustomBorder = styled.div`
  border-right: 1px solid #e0e0e0;
  height: 15px;
`

export const DetailsHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  padding: 16px 16px;
`
export const Subtitle = styled.div`
  width: 140px;
  padding: 0px 16px 16px 16px;
  font-size: 16px;
  color: #68707a;
  font-weight: 400;
  line-height: 20px;
`
export const InfoWrapper = styled.div`
  padding-left: 32px;
  font-weight: 600;
  font-size: 16px;
`
export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  flex-direction: row;
  padding-bottom: 5px;
  padding-top: 5px;
`
export const Half = styled.div`
  width: 50%;
  padding-top: 10px;
`
export const DetailsFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TextWrapper = styled.div`
  padding: 16px;
  color: #020210;
  font-size: 16px;
  font-weight: 400;
`
export const ActionsWrapper = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
  padding: 10px;

  :hover {
    background-color: #e7e9ed;
    border-radius: 4px;
  }
`

export const InputWrapper = styled.div`
  display: none;
`
export const ActionCustomFlex = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const UserNameText = styled.div`
  font-size: 20px;
  color: #020210;
  font-weight: 700;
`

export const UserRoleText = styled.div`
  font-size: 12px;
  color: #020210;
`

export const UserShortInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
`

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 80px;
`
