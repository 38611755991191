import { useMutation } from '@apollo/client'

import { CREATE_QUIZ } from 'gql/quiz/quiz.query'

const createQuizService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [createQuizMutation, { loading, error }] = useMutation(CREATE_QUIZ, {})

  return {
    createQuizMutation,
    loading,
    error,
  }
}

export default createQuizService
