import styled from 'styled-components'

// CourseLayout

export const ModalRef = styled.span``

export const Title = styled.div`
  font-size: 16px;
  font-weight: 550;
  padding-bottom: 5px;
  padding-top: 0px;
`
export const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
// export const ContentWrapper = styled.div<{ isGrid: boolean }>`
//   margin-top: 30px;

//   ${({ isGrid }) =>
//     isGrid &&
//     `

//   `}
// `

export const ContentWrapper = styled.div<{ isGrid: boolean }>`
  margin-top: 30px;

  ${({ isGrid }) =>
    !isGrid
      ? `
      .card {
          grid-template-columns:none
      }
    `
      : `
      .card {
        display: grid;
        grid-template-columns: repeat(4, 1fr);


        @media (max-width: 1450px) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    `}
`

// CourseList

const defs = (args: any) => {
  const styles: any = {}
  if (args.switched) {
    styles.top = '0px'
    styles.left = '0px'
    styles.gridTemplateColumns = '1fr 35px'
  } else {
    styles.bottom = '0px'
    styles.right = '0px'
    styles.justifyContent = 'end'
    styles.gridTemplateColumns = '35px 35px'
  }
  return styles
}

export const CardActionBar = styled.div<{ switched: boolean }>`
  width: 100%;
  position: absolute;
  z-index: 99;
  box-sizing: border-box;
  padding: 6px;
  ${defs}
  .action_block {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`

export const StylesCardWrapper = styled.div<{ canOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: ${({ canOpen }) => (canOpen ? 'pointer' : 'default')};
  height: 100%;
  border-radius: 12px !important;
  &:hover {
    .action_block {
      background: rgba(255, 255, 255, 0.59) !important;
    }
  }
`

export const StyledChecked = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgb(0 0 0 / 23%);
`

export const VideoProcessingContainer = styled.div`
  position: relative;
  cursor: pointer;
  .icon_button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const CourseStatusContainer = styled.div<{ status: boolean }>`
  color: #fff;
  height: 32px;
  padding: 4px 12px;
  text-transform: capitalize;
  border-radius: 4px;
  background: ${({ status }) => (!status ? '#EA382A' : '#00905E')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 13px;
  }
`

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > :first-child {
    font-size: 14px;
    margin-bottom: 5px;
  }
  div {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .tooltip-text {
      font-size: 12px;
      margin-top: 5px;
      text-align: center;
    }
  }
`
export const StyledCardBottomContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column !important;
  align-self: flex-end;
  gap: 10px;
  padding-bottom: 10px;
`

export const StyledActionsContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  width: 100%;
`

export const StyledActionItem = styled.span<{
  disabled?: boolean
  color?: string
}>`
  display: flex;
  align-items: center;
  height: 14px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ disabled }) => (disabled ? '#ccc' : '#68707a')};
  font-size: 14px;
  padding-bottom: 10px;
  width: 100%;
  justify-content: center;
  gap: 8px;
  padding: 12px 4px;
  svg path {
    fill: ${({ disabled }) => (disabled ? '#ccc' : '#68707a')};
    width: 16px;
    height: 16px;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  &:hover {
    color: ${({ disabled, color }) => (disabled ? '#ccc' : color)};
    svg path {
      fill: ${({ disabled, color }) => (disabled ? '#ccc' : color)};
    }
  }
`

export const StyledCourseContent = styled.span`
  color: #20a8c6;
  font-size: 14px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  background-color: #edf8fa;
`

export const ItemBlock = styled.div`
  text-transform: capitalize;
`
export const StyledItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`

// gridConfig

export const StyledCourseCardContent = styled.span`
  color: #20a8c6;
  font-size: 14px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  background-color: #edf8fa;
`

export const GridItemLessons = styled.div<{ checked?: boolean }>`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: flex-end;
  flex-direction: row !important;
  padding-left: 16px;

  // when card is checked
  span {
    background: ${(props): string => (props.checked ? 'none' : '#edf8fa')};
    color: ${(props): string => (props.checked ? '#333333' : '#20a8c6')};
  }
  > div {
    font-size: 12px;
    position: relative;
    padding-left: 12px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);
    }

    :not(:last-child) {
      margin-right: 10px;
    }
  }
`
export const StyledToolTipContainer = styled.div`
  width: 100%;
`

export const Action = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  opacity: ${({ disabled }: any) => (disabled ? '0.5' : '1')};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }
  svg path {
    width: 20px;
    height: 20px;
    fill: #717882;
  }
  /* .MuiTypography-body1 {
    font-size: 0.875rem;
  } */
`
export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0 20px 0 0;
  text-transform: capitalize;
`

export const GridTableWrapper = styled.div`
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  margin-top: 20px;
  padding: 16px;

  th:last-child {
    width: 10px;
  }
`
