import { useMutation } from '@apollo/client'
import IconButton from '@mui/material/IconButton'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { Grid } from 'components/common/Cards'
import CheckBox from 'components/common/Checkbox'
import DndItem from 'components/common/Grid/DndGrid/DndItem'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { formatDuration } from 'helpers/formatDuration'
import update from 'immutability-helper'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DefaultText } from '../TextEditor/styled-components'
import {
  Container,
  GridItem,
  GridItemContent,
  GridItemContentHeader,
  GridItemImage,
  GridItemLessons,
  GridItemTitle,
  ListContainer,
  StylesCardWrapper,
} from './styled-components'
import strCutter from 'utils/strCutter'
import { Tooltip } from '@mui/material'
import {
  CardActionBar,
  GridItemCards,
  GridItemContentHeaderCards,
  GridItemLessonsCards,
  ListItemCards,
  ListItemContentCards,
  ListItemImageCards,
  StyledCheckboxContainer,
} from './cardsStyled-component'
import { ICard, ICardsLayoutParams } from './cardsLayout.interface'
import { CHANGE_ORDER } from 'gql/modules.query'

const CardsLayout = ({
  gridStyle = 'card',
  data,
  noRecordsText = 'No records',
  actions,
  handleVideoModal,
  selectItem = (): void => {},
  selectedModuleIds = [''],
  selectedItem = [''],
  cols = 4,
  refetch,
  variant = 'grid',
  isDraggable = false,
  t,
  openInExternalTab,
  routerState,
  checkboxShown = true,
}: ICardsLayoutParams): React.JSX.Element => {
  const [changeModulesOrder] = useMutation(CHANGE_ORDER)
  const navigate = useNavigate()

  const [cards, setCards] = useState(data)
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const handleChangeRequest = async (
    draggedId: string,
    droppedId: string | undefined,
  ): Promise<void> => {
    const { data } = await changeModulesOrder({
      variables: { draggedId, droppedId },
    })

    if (data) {
      refetch()
    }
  }

  useEffect(() => {
    setCards(data)
  }, [data])

  const redirectToModuleDetails = (moduleId: string): void => {
    if (openInExternalTab) {
      window.open(`/modules/${moduleId}`, '_blank')
    } else {
      navigate(`/modules/${moduleId}`, {
        state: routerState,
      })
    }
  }

  if (variant === 'grid') {
    return (
      <Container>
        <Grid className={gridStyle} cols={cols}>
          {data.length === 0 && (
            <DefaultText style={{ width: '50vw' }}>{noRecordsText}</DefaultText>
          )}
          {cards.map((item: ICard, index: number) => {
            return isDraggable ? (
              <DndItem
                key={item.id}
                id={item.id}
                index={index}
                moveCard={moveCard}
                onEnd={handleChangeRequest}
                cardIds={cards.map((card: { id: string }) => card.id)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    cursor: 'pointer',
                  }}
                  onClick={(): void => redirectToModuleDetails(item.id)}
                >
                  <CheckBox
                    onChange={(e: React.SyntheticEvent<EventTarget>): void =>
                      selectItem(item.id, e)
                    }
                    checked={
                      selectedItem.includes(item.id) ||
                      selectedModuleIds.includes(item.id)
                    }
                    disabled={selectedModuleIds.includes(item.id)}
                  />
                  <StylesCardWrapper className="wrapper" key={item.id}>
                    <CardActionBar switched={!!(gridStyle === 'card')}>
                      <div className="action_block">
                        <IconButton
                          onClick={(e): void => {
                            e.stopPropagation()
                            if (!item.attachment) return
                            window.open(item.attachment, '_blank')
                          }}
                        >
                          <FileCopyIcon
                            color={item.attachment ? 'secondary' : 'disabled'}
                            style={{ color: item.attachment ? '#06C68F' : '' }}
                          />
                        </IconButton>
                      </div>
                      {actions && (
                        <div className="action_block">
                          <GridActionMenu actionConfig={actions} row={item} />
                        </div>
                      )}
                    </CardActionBar>
                    <GridItem>
                      <GridItemImage>
                        <img
                          src={
                            item.coverImage
                              ? item.coverImage.link
                              : 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSHw6OeiQMdYX_iXu8otmzMnlbD07xEWr87EMnJ5r4X9vYC-kNr&usqp=CAU'
                          }
                        />
                      </GridItemImage>
                      <GridItemContent>
                        <GridItemContentHeader>
                          <Tooltip title={item.name} placement="bottom">
                            <GridItemTitle>
                              <Link to={`/modules/${item.id}`}>
                                {strCutter(item.name, 50)}{' '}
                              </Link>
                            </GridItemTitle>
                          </Tooltip>
                          <span
                            onClick={(): void => handleVideoModal(item)}
                            style={item.video ? { pointerEvents: 'none' } : {}}
                          >
                            <PlayCircleOutlineIcon
                              style={{ cursor: 'pointer', fontSize: '1.7rem' }}
                              color={item.video ? 'secondary' : 'disabled'}
                            />
                          </span>
                        </GridItemContentHeader>
                        <GridItemLessons>
                          <div>{item.numberOfTopics} Topics</div>
                          <div>{item.numberOfLessons} Lessons</div>
                          <div>{formatDuration(item.duration)}</div>
                        </GridItemLessons>
                        {item.description && (
                          <article>
                            {gridStyle === 'card'
                              ? item.description.length > 80
                                ? item.description.substring(0, 80) + '...'
                                : item.description
                              : item.description.length > 300
                              ? item.description.substring(0, 300) + '...'
                              : item.description}
                          </article>
                        )}
                      </GridItemContent>
                    </GridItem>
                  </StylesCardWrapper>
                </div>
              </DndItem>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  cursor: 'pointer',
                }}
                onClick={(): void => redirectToModuleDetails(item.id)}
              >
                <CheckBox
                  onChange={(e: React.SyntheticEvent<EventTarget>): void =>
                    selectItem(item.id, e)
                  }
                  checked={
                    selectedItem.includes(item.id) ||
                    selectedModuleIds.includes(item.id)
                  }
                  disabled={selectedModuleIds.includes(item.id)}
                />
                <StylesCardWrapper className="wrapper" key={item.id}>
                  <CardActionBar switched={!!(gridStyle === 'card')}>
                    <div className="action_block">
                      <IconButton
                        onClick={(e): void => {
                          e.stopPropagation()
                          if (!item.attachment) return
                          window.open(item.attachment, '_blank')
                        }}
                      >
                        <FileCopyIcon
                          color={item.attachment ? 'secondary' : 'disabled'}
                        />
                      </IconButton>
                    </div>
                    {actions && (
                      <div className="action_block">
                        <GridActionMenu actionConfig={actions} row={item} />
                      </div>
                    )}
                  </CardActionBar>
                  <GridItem>
                    <GridItemImage>
                      <img
                        src={
                          item.coverImage
                            ? item.coverImage.link
                            : 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSHw6OeiQMdYX_iXu8otmzMnlbD07xEWr87EMnJ5r4X9vYC-kNr&usqp=CAU'
                        }
                      />
                    </GridItemImage>
                    <GridItemContent>
                      <GridItemContentHeader>
                        <Tooltip title={item.name} placement="bottom">
                          <GridItemTitle>
                            <Link to={`/modules/${item.id}`}>
                              {strCutter(item.name, 50)}{' '}
                            </Link>
                          </GridItemTitle>
                        </Tooltip>
                        <span
                          onClick={(): void => handleVideoModal(item)}
                          style={item.video ? { pointerEvents: 'none' } : {}}
                        >
                          <PlayCircleOutlineIcon
                            style={{ cursor: 'pointer', fontSize: '1.7rem' }}
                            color={item.video ? 'secondary' : 'disabled'}
                          />
                        </span>
                      </GridItemContentHeader>
                      <GridItemLessons>
                        <div>
                          {item.numberOfTopics} {t('general.topics')}
                        </div>
                        <div>
                          {item.numberOfLessons} {t('general.lessons')}
                        </div>
                        <div>{formatDuration(item.duration)}</div>
                      </GridItemLessons>
                      {item.description && (
                        <article>
                          {gridStyle === 'card'
                            ? item.description.length > 80
                              ? item.description.substring(0, 80) + '...'
                              : item.description
                            : item.description.length > 300
                            ? item.description.substring(0, 300) + '...'
                            : item.description}
                        </article>
                      )}
                    </GridItemContent>
                  </GridItem>
                </StylesCardWrapper>
              </div>
            )
          })}
        </Grid>
      </Container>
    )
  } else {
    return (
      <Container style={{ overflowY: 'scroll' }}>
        <ListContainer>
          {data.length === 0 && (
            <DefaultText style={{ width: '50vw' }}>{noRecordsText}</DefaultText>
          )}
          {cards.map((item: ICard, index: number) => {
            return isDraggable ? (
              <DndItem
                key={item.id}
                id={item.id}
                index={index}
                moveCard={moveCard}
                className="dnd_item"
                onEnd={handleChangeRequest}
                cardIds={cards.map((card: { id: string }) => card.id)}
              >
                <ListItemCards
                  className="module-card"
                  onClick={(): void => redirectToModuleDetails(item.id)}
                  checked={
                    selectedItem.includes(item.id) ||
                    selectedModuleIds.includes(item.id)
                  }
                >
                  <StylesCardWrapper
                    className="wrapper"
                    style={{ width: '100%' }}
                    key={item.id}
                  >
                    <GridItemCards>
                      <ListItemImageCards
                        variant={data.length > 4 ? 'small' : 'large'}
                      >
                        <img
                          src={
                            item.coverImage
                              ? item.coverImage.link
                              : 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSHw6OeiQMdYX_iXu8otmzMnlbD07xEWr87EMnJ5r4X9vYC-kNr&usqp=CAU'
                          }
                        />
                      </ListItemImageCards>
                      <ListItemContentCards>
                        <GridItemContentHeaderCards>
                          {item.name.length > 50 ? (
                            <Tooltip title={item.name} placement="bottom">
                              <GridItemTitle>
                                <Link
                                  to={`/modules/${item.id}`}
                                  state={routerState}
                                >
                                  {strCutter(item.name, 50)}
                                </Link>
                              </GridItemTitle>
                            </Tooltip>
                          ) : (
                            <GridItemTitle>
                              <Link
                                to={`/modules/${item.id}`}
                                state={routerState}
                              >
                                {item.name}
                              </Link>
                            </GridItemTitle>
                          )}
                        </GridItemContentHeaderCards>
                        <GridItemLessonsCards>
                          <div>{item.numberOfTopics} Topics</div>
                          <div>{item.numberOfLessons} Lessons</div>
                          <div>{formatDuration(item.duration)}</div>
                        </GridItemLessonsCards>
                      </ListItemContentCards>
                    </GridItemCards>
                  </StylesCardWrapper>
                  {/* <DragHandle style={{ marginRight: '20px' }} /> */}
                  {checkboxShown && (
                    <StyledCheckboxContainer>
                      <CheckBox
                        onChange={(
                          e: React.SyntheticEvent<EventTarget>,
                        ): void => {
                          e.stopPropagation()
                          selectItem(item.id, e)
                        }}
                        onClick={(e: React.MouseEvent): void => {
                          e.stopPropagation()
                        }}
                        checked={
                          selectedItem.includes(item.id) ||
                          selectedModuleIds.includes(item.id)
                        }
                        disabled={selectedModuleIds.includes(item.id)}
                      />
                    </StyledCheckboxContainer>
                  )}
                </ListItemCards>
              </DndItem>
            ) : (
              <ListItemCards
                className="module-card"
                onClick={(): void => redirectToModuleDetails(item.id)}
                checked={
                  selectedItem.includes(item.id) ||
                  selectedModuleIds.includes(item.id)
                }
              >
                <StylesCardWrapper
                  className="wrapper"
                  style={{ width: '100%' }}
                  key={item.id}
                >
                  <GridItemCards>
                    <ListItemImageCards
                      variant={data.length > 4 ? 'small' : 'large'}
                    >
                      <img
                        src={
                          item.coverImage
                            ? item.coverImage.link
                            : 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSHw6OeiQMdYX_iXu8otmzMnlbD07xEWr87EMnJ5r4X9vYC-kNr&usqp=CAU'
                        }
                      />
                    </ListItemImageCards>
                    <ListItemContentCards>
                      <GridItemContentHeaderCards>
                        <Tooltip title={item.name} placement="bottom">
                          <GridItemTitle>
                            <Link to={`/modules/${item.id}`}>
                              {strCutter(item.name, 50)}{' '}
                            </Link>
                          </GridItemTitle>
                        </Tooltip>
                      </GridItemContentHeaderCards>
                      <GridItemLessonsCards>
                        <div>{item.numberOfTopics} Topics</div>
                        <div>{item.numberOfLessons} Lessons</div>
                        <div>{formatDuration(item.duration)}</div>
                      </GridItemLessonsCards>
                    </ListItemContentCards>
                  </GridItemCards>
                </StylesCardWrapper>
                {/* <DragHandle style={{ marginRight: '20px' }} /> */}
                {checkboxShown && (
                  <StyledCheckboxContainer>
                    <CheckBox
                      onChange={(
                        e: React.SyntheticEvent<EventTarget>,
                      ): void => {
                        e.stopPropagation()
                        selectItem(item.id, e)
                      }}
                      onClick={(e: React.MouseEvent): void => {
                        e.stopPropagation()
                      }}
                      checked={
                        selectedItem.includes(item.id) ||
                        selectedModuleIds.includes(item.id)
                      }
                      disabled={selectedModuleIds.includes(item.id)}
                    />
                  </StyledCheckboxContainer>
                )}
              </ListItemCards>
            )
          })}
        </ListContainer>
      </Container>
    )
  }
}

export default CardsLayout
