import React, { useCallback, useEffect, useState } from 'react'
import { t } from 'i18next'
import { columnConfig } from './columnConfig'
import _, { debounce } from 'lodash'
import { AnalyticsGroupOption } from 'pages/analytics/analytics.interface'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { ITutorsLayout } from './tutorsLayout.interface'
import { useNavigate } from 'react-router-dom'
import { User } from 'interfaces/users'
import { useFetchTutorsByCourseId } from 'hooks/users/useFetchTutorsByCourseId'

const useTutorsLayout = ({ courseId, groups, companyId }: ITutorsLayout) => {
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState<string>('')
  const [groupOptions, setGroupOptions] = useState<AnalyticsGroupOption[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [selectedGroup, setSelectedGroup] =
    useState<AnalyticsGroupOption | null>(null)

  const groupIds = groups?.map((group) => group.groupId) || []
  const defaultFilter = {
    group: {
      type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
      value: groupIds,
      nestedField: 'groupId',
    },
  }

  const [filterOptions, setFilterOptions] = useState(defaultFilter)

  const { tutors, loading, refetch } = useFetchTutorsByCourseId(
    searchText,
    courseId,
    selectedGroup?.value || null,
    currentPage,
    perPage,
  )

  const handleResetClick = (resetGroups = true) => {
    if (resetGroups) setSelectedGroup(null)
    setSearchText('')
    setFilterOptions(defaultFilter)
    refetch({
      filter: defaultFilter,
      currentPage: 1,
      perPage,
      companyId,
    })
  }

  const debouncedRefetch = useCallback(
    debounce((newSearchValue: string) => {
      const searchQuery = {
        search: {
          type: LowerCaseFilterableFieldType.SEARCH,
          value: newSearchValue,
          fields: ['firstName', 'lastName', 'email', 'phone'],
        },
      }

      const updatedFilterOptions = {
        ...filterOptions,
        ...searchQuery,
      }
      setFilterOptions(updatedFilterOptions)
      refetch({
        filter: updatedFilterOptions,
        currentPage: 1,
        perPage,
      })
    }, 500),
    [currentPage, perPage, refetch],
  )

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newSearchValue = e.currentTarget.value
      setSearchText(newSearchValue)
      if (newSearchValue === '') handleResetClick(false)
      else debouncedRefetch(newSearchValue)
    },
    [debouncedRefetch],
  )

  useEffect(() => {
    if (!groups) return
    const currGroupOptions =
      groups.map((group) => ({
        label: group.name,
        value: group.groupId,
      })) || []

    setGroupOptions(currGroupOptions)
  }, [groups])

  const handleClick = (student: User) => {
    navigate(`/profile/${student.id}`)
  }

  useEffect(() => {
    if (!selectedGroup) return
    const groupFilter = {
      group: {
        type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
        value: [selectedGroup?.value],
        nestedField: 'groupId',
      },
    }

    const updatedFilterOptions = {
      ...filterOptions,
      ...groupFilter,
    }
    setFilterOptions(updatedFilterOptions)
    refetch({
      filter: updatedFilterOptions,
      currentPage,
      perPage,
    })
  }, [selectedGroup])

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
    refetch({
      filter: filterOptions,
      currentPage,
      perPage: newPerPage,
    })
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const newCurrentPage = newPage + 1
    setCurrentPage(newCurrentPage)
    refetch({
      filter: filterOptions,
      currentPage: newCurrentPage,
      perPage,
    })
  }

  const config = columnConfig(t)

  return {
    setSelectedGroup,
    setSearchText,
    handleClick,
    selectedGroup,
    searchText,
    currentPage,
    handleResetClick,
    perPage,
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleSearchChange,
    groupOptions,
    tutors,
    loading,
    config,
  }
}

export default useTutorsLayout
