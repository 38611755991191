import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import updateUserStatusService from 'services/users/updateUserStatusService'
import { useTranslation } from 'react-i18next'
import formatRoleForTranslation, { RoleFormats } from 'helpers/formatRole'

const useUpateStatusUserService = (params?: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [user, setUser] = useState('')
  const [error, setError] = useState('')

  const { updateUserStatusMutation, loading } = updateUserStatusService(params)

  const updateUserStatus = async (ids: string[], status: string) => {
    let message = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined
    const { data, errors } = await updateUserStatusMutation({
      variables: {
        ids,
        status,
      },
    })

    if (errors) {
      // eslint-disable-next-line no-console
      console.log(errors)
      message = t('messages.something_wrong')
      variant = 'error'
      setError(JSON.stringify(errors))
    } else if (data && data.updateUserStatus.length) {
      const { role, status } = data.updateUserStatus[0]
      const single = data.updateUserStatus.length === 1

      let message = single
        ? t(formatRoleForTranslation(role))
        : t(formatRoleForTranslation(role, RoleFormats.PLURAL))

      if (status === 'ACTIVE') message += ` ${t('messages.activated')}`
      else message += ` ${t('messages.suspended')}`

      variant = 'success'

      setUser(data.updateUserStatus.id)
      setSnackbar({ message, variant })
    }
  }

  return {
    updateUserStatus,
    updateUserStatusLoaing: loading,
    user,
    error,
  }
}

export default useUpateStatusUserService
