export interface LowerCaseFilterableFieldEnumType {
  MATCH: 'match'
  EXACT: 'exact'
  NOT_EQUAL: 'not_equal'
  BOOL: 'bool'
  RANGE: 'range'
  ARRAY_IN: 'arrayIn'
  ARRAY_ALL: 'arrayAll'
  ARRAY_NOT_IN: 'arrayNotIn'
  NESTED_ARRAY_IN: 'nestedArrayIn'
  NESTED_ARRAY_ALL: 'nestedArrayAll'
  NESTED_ARRAY_NOT_IN: 'nestedArrayNotIn'
  SEARCH: 'search'
  EXISTS: 'exists'
  ALL: 'all'
  LABEL: 'label'
  DROPDOWN: 'dropdown'
  QUERY: 'query'
  DATE_RANGE: 'dateRange'
}

export interface UpperCaseFilterableFieldEnumType {
  MATCH: 'MATCH'
  EXACT: 'EXACT'
  NOT_EQUAL: 'NOT_EQUAL'
  BOOL: 'BOOL'
  RANGE: 'RANGE'
  ARRAY_IN: 'ARRAY_IN'
  ARRAY_ALL: 'ARRAY_ALL'
  ARRAY_NOT_IN: 'ARRAY_NOT_IN'
  NESTED_ARRAY_IN: 'NESTED_ARRAY_IN'
  NESTED_ARRAY_ALL: 'NESTED_ARRAY_ALL'
  NESTED_ARRAY_NOT_IN: 'NESTED_ARRAY_NOT_IN'
  SEARCH: 'SEARCH'
  EXISTS: 'EXISTS'
  ALL: 'ALL'
  LABEL: 'LABEL'
  DROPDOWN: 'DROPDOWN'
  QUERY: 'QUERY'
  DATE_RANGE: 'DATE_RANGE'
}
export const LowerCaseFilterableFieldType: LowerCaseFilterableFieldEnumType = {
  MATCH: 'match',
  EXACT: 'exact',
  ALL: 'all',
  NOT_EQUAL: 'not_equal',
  BOOL: 'bool',
  RANGE: 'range',
  ARRAY_IN: 'arrayIn',
  ARRAY_ALL: 'arrayAll',
  ARRAY_NOT_IN: 'arrayNotIn',
  NESTED_ARRAY_IN: 'nestedArrayIn',
  NESTED_ARRAY_ALL: 'nestedArrayAll',
  NESTED_ARRAY_NOT_IN: 'nestedArrayNotIn',
  SEARCH: 'search',
  EXISTS: 'exists',
  LABEL: 'label',
  DROPDOWN: 'dropdown',
  QUERY: 'query',
  DATE_RANGE: 'dateRange',
}

export const UpperCaseFilterableFieldType: UpperCaseFilterableFieldEnumType = {
  MATCH: 'MATCH',
  EXACT: 'EXACT',
  ALL: 'ALL',
  NOT_EQUAL: 'NOT_EQUAL',
  BOOL: 'BOOL',
  RANGE: 'RANGE',
  ARRAY_IN: 'ARRAY_IN',
  ARRAY_ALL: 'ARRAY_ALL',
  ARRAY_NOT_IN: 'ARRAY_NOT_IN',
  NESTED_ARRAY_IN: 'NESTED_ARRAY_IN',
  NESTED_ARRAY_ALL: 'NESTED_ARRAY_ALL',
  NESTED_ARRAY_NOT_IN: 'NESTED_ARRAY_NOT_IN',
  SEARCH: 'SEARCH',
  EXISTS: 'EXISTS',
  LABEL: 'LABEL',
  DROPDOWN: 'DROPDOWN',
  QUERY: 'QUERY',
  DATE_RANGE: 'DATE_RANGE',
}
