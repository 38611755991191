import React, { ChangeEvent, useEffect } from 'react'
import { useUserValue } from 'context/UserContext'
import { TabNavigation, activeTabClassName } from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { Container } from '../styled-components'

import CompanyCourses from './CompanyCourses'
import CompanyGroups from './CompanyGroups'
import CompanyUsers from './CompanyUsers'
import { CourseFilterDrawerContext } from 'pages/courses/FilterDrawer/FilterDrawer'
import CompanyTutors from './CompanyTutors'
import CompanyAssignedTutors from './CompanyAssignedTutors'
import CompanyStudents from './CompanyStudents'
import { ICompanyTabsProps } from '../types'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
  btnDisabled: {
    color: '#00000050',
  },
}

const CompanyTabs = ({
  currentTab,
  handleTabChange,
  variables,
  addCoachIds,
  defCurrentPage,
  company,
  t,
}: ICompanyTabsProps): JSX.Element => {
  const [state] = useUserValue()
  let isNotGroupAdmin = false
  if (state?.currentUser) {
    isNotGroupAdmin = state?.currentUser?.role !== 'ADMIN'
  }

  useEffect(() => {
    if (!isNotGroupAdmin) handleTabChange(6 as number)
  }, [isNotGroupAdmin])

  return (
    <Container>
      {isNotGroupAdmin ? (
        <>
          <TabNavigation withBorder={true} transparent>
            <AppBar position="static" style={styles.bar}>
              <Tabs
                value={currentTab}
                onChange={(event: ChangeEvent<{}>, value: string): void =>
                  handleTabChange(value)
                }
                aria-label="simple tabs example"
              >
                <Tab
                  label={t('general.purchased_courses')}
                  {...activeTabClassName(0)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.assigned_coaches')}
                  {...activeTabClassName(1)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.company_courses')}
                  {...activeTabClassName(2)}
                  style={
                    !company.platformService ? styles.btnDisabled : styles.btn
                  }
                  disabled={!company.platformService}
                />
                <Tab
                  label={t('general.company_coaches')}
                  {...activeTabClassName(3)}
                  style={
                    !company.platformService ? styles.btnDisabled : styles.btn
                  }
                  disabled={!company.platformService}
                />
                <Tab
                  label={`${t('general.company')} ${t('general.super_admins')}`}
                  {...activeTabClassName(4)}
                  style={styles.btn}
                />
                <Tab
                  label={t('company_wizard_layout.group_admins')}
                  {...activeTabClassName(5)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.groups')}
                  {...activeTabClassName(6)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.students')}
                  {...activeTabClassName(7)}
                  style={styles.btn}
                />
              </Tabs>
            </AppBar>
          </TabNavigation>
          {(currentTab === 0 || currentTab === 2) && (
            <CourseFilterDrawerContext.Provider
              value={{ companyId: company?.id }}
            >
              <CompanyCourses
                variables={variables}
                createBtn={currentTab === 2 ? company?.platformService : false}
                addCoachIds={addCoachIds}
                defCurrentPage={defCurrentPage}
                currentTab={currentTab}
                company={company}
                t={t}
              />
            </CourseFilterDrawerContext.Provider>
          )}

          {currentTab === 1 && (
            <CompanyAssignedTutors
              variables={variables}
              currentTab={currentTab}
              t={t}
            />
          )}

          {currentTab === 3 && (
            <CompanyTutors
              variables={variables}
              currentTab={currentTab}
              t={t}
            />
          )}

          {(currentTab === 4 || currentTab === 5) && (
            <CompanyUsers variables={variables} currentTab={currentTab} t={t} />
          )}

          {currentTab === 6 && (
            <CompanyGroups variables={variables} t={t} companyId={company.id} />
          )}

          {currentTab === 7 && (
            <CompanyStudents
              variables={variables}
              currentTab={currentTab}
              t={t}
            />
          )}
        </>
      ) : (
        <>
          <TabNavigation withBorder={true} transparent>
            <AppBar position="static" style={styles.bar}>
              <Tabs
                value={currentTab}
                onChange={(event: any, value: string) => handleTabChange(value)}
                aria-label="simple tabs example"
              >
                <Tab
                  label={t('general.groups')}
                  {...activeTabClassName(6)}
                  value={6}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.students')}
                  {...activeTabClassName(7)}
                  value={7}
                  style={styles.btn}
                />
              </Tabs>
            </AppBar>
          </TabNavigation>
          {currentTab === 6 && (
            <CompanyGroups variables={variables} t={t} companyId={company.id} />
          )}

          {currentTab === 7 && (
            <CompanyStudents
              variables={variables}
              currentTab={currentTab}
              t={t}
            />
          )}
        </>
      )}
    </Container>
  )
}

export default CompanyTabs
