import { gql } from 'graphql.macro'

export const ADD_TEST = gql`
  mutation CreateTestAssessment(
    $companyId: String!
    $input: TestAssessmentInput
  ) {
    createTestAssessment(companyId: $companyId, input: $input) {
      id
      attachment {
        link
        name
        fileType
      }
      name
      passRate

      quizParticipationType
      passRate
      tags {
        id
        label
      }
      questionsCount
      published
      randomizationNumber
      randomization
      timeType
      time
    }
  }
`

export const ADD_TEST_ASSESSMENT_TO_GROUPS = gql`
  mutation AddTestAssessmentToGroupsById(
    $groupIds: [String]!
    $testAssessmentId: String!
  ) {
    addTestAssessmentToGroupsById(
      groupIds: $groupIds
      testAssessmentId: $testAssessmentId
    )
  }
`

export const GET_TEST_ANALYTICS = gql`
  query GetTestAnalytics($testId: String!, $userId: String!) {
    getTestAnalytics(testId: $testId, userId: $userId) {
      test {
        id
        name
        score
        passed
        questionList {
          question
          answers {
            value
            id
            isCorrect
          }
          userAnswer {
            isCorrect
            answers
            boolAnswer
          }
        }
      }
      user {
        id
        avatar
        firstName
        lastName
      }
    }
  }
`

export const DELETE_TEST_ASSESSMENT_FROM_GROUPS = gql`
  mutation DeleteTestAssessmentFromGroupsById(
    $testAssessmentId: String!
    $groupIds: [String]!
  ) {
    deleteTestAssessmentFromGroupsById(
      testAssessmentId: $testAssessmentId
      groupIds: $groupIds
    )
  }
`

export const GET_TESTS_BY_USER = gql`
  query GetTestAssessmentsByUserForAdmin(
    $userId: String
    $limit: Int
    $currentPage: Int
    $searchValue: String
  ) {
    getTestAssessmentsByUserForAdmin(
      userId: $userId
      limit: $limit
      currentPage: $currentPage
      searchValue: $searchValue
    ) {
      currentPage
      totalPages
      data {
        id
        name
        companyId
        description
        passRate
        passed
        published
        questions
        questionsCount
        quizParticipationType
        randomization
        randomizationNumber
        score
        tags {
          label
          id
        }
        time
        timeType
        attachment {
          fileType
          link
          name
          size
          thumbnail
        }
      }
    }
  }
`

export const GET_TESTS = gql`
  query GetTestAssessmentsByCompanyId(
    $companyId: String!
    $currentPage: Int
    $perPage: Int
    $filter: QuizesFilter
  ) {
    getTestAssessmentsByCompanyId(
      companyId: $companyId
      currentPage: $currentPage
      perPage: $perPage
      filter: $filter
    ) {
      currentPage
      totalPages
      data {
        attachment {
          fileType
          link
          name
        }
        companyId
        quizParticipationType
        passRate
        description
        id
        name
        questions
        published
        passRate
        questionsCount
        randomization
        randomizationNumber
        tags {
          label
          id
        }
        time
        timeType
      }
    }
  }
`
export const GET_TESTS_NAME = gql`
  query GetTestAssessmentsByCompanyId(
    $companyId: String!
    $currentPage: Int
    $perPage: Int
    $filter: QuizesFilter
  ) {
    getTestAssessmentsByCompanyId(
      companyId: $companyId
      currentPage: $currentPage
      perPage: $perPage
      filter: $filter
    ) {
      currentPage
      totalPages
      data {
        id
        name
      }
    }
  }
`

export const GET_TEST = gql`
  query GetTestAssessmentById($testId: String!) {
    getTestAssessmentById(testId: $testId) {
      attachment {
        fileType
        link
        name
      }
      companyId
      questions
      passRate
      published
      timeType
      description
      id
      name
      randomization
      quizParticipationType
      randomizationNumber
      tags {
        id
        label
      }
      time
      timeType
    }
  }
`

export const UPDATE_TEST = gql`
  mutation UpdateTestAssessment($testId: String!, $input: TestAssessmentInput) {
    updateTestAssessment(id: $testId, input: $input) {
      attachment {
        fileType
        link
        name
      }
      description
      name
      passRate
      quizParticipationType
      randomization
      published
      questions
      randomizationNumber
      tags {
        label
        id
      }
      id
      time
      timeType
      companyId
    }
  }
`

export const DELETE_TEST = gql`
  mutation DeleteTestAssessments($ids: [String]!) {
    deleteTestAssessments(ids: $ids) {
      ids
    }
  }
`

export const PUBLISH_TEST = gql`
  mutation PublishUnpublishTestAssessment(
    $testAssessmentId: String!
    $published: Boolean!
  ) {
    publishUnpublishTestAssessment(
      id: $testAssessmentId
      published: $published
    ) {
      id
    }
  }
`
