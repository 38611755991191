import React from 'react'
import { FileUploadButton, ButtonInputLabel } from './styled-components'
import { UploaderButtonProps } from './uploaderButton.interface'

const UploaderButton = (props: UploaderButtonProps): React.JSX.Element => {
  return (
    <FileUploadButton>
      <input
        type="file"
        onChange={props.onChange}
        id={props.id || 'id'}
        accept={props.accept}
      />
      {props.loading ? (
        'Loading...'
      ) : (
        <ButtonInputLabel htmlFor={props.id}>
          <span>{props.label}</span>
        </ButtonInputLabel>
      )}
    </FileUploadButton>
  )
}

export default UploaderButton
