import { useMutation } from '@apollo/client'

import { ADD_COMPANY } from 'gql/companies.query'

const addCompanyService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addCompanyMutation, { loading, error }] = useMutation(ADD_COMPANY, {})

  return {
    addCompanyMutation,
    loading,
    error,
  }
}

export default addCompanyService
