import styled from 'styled-components'

export const Container = styled.div``

export const Action = styled.div<{
  hoverColor?: string
  hoverBackground?: string
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  gap: 6px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    /* background: ${({ hoverBackground }) =>
      hoverBackground || 'rgba(0, 0, 0, 0.1)'}; */
    ${({ hoverColor }) =>
      hoverColor &&
      `svg path {
        fill: ${hoverColor};
         }`}
  }
  svg {
    font-size: 1rem;
    transition: 0.3s ease;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`

export const GroupsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const GroupItem = styled.div`
  position: relative;
  &:hover > span {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`

export const GroupTitle = styled.span`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`
export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 92px;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 4px;
  display: inline-block;

  &.active {
    background: #e5f4ef;
    color: #00905e;
  }

  &.suspended {
    background: #fdebea;
    color: #ea382a;
  }

  &.pending {
    color: rgb(207, 207, 9);
    background: rgba(207, 207, 9, 0.1);
  }

  &.draft {
    color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.1);
  }
`

export const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`

export const ClickableDiv = styled.div`
  cursor: pointer;
`

export const ImportNote = styled.p`
  font-size: 16px;
  color: #333;
  margin: 10px 20px 0 20px;
  font-style: italic;
`
export const Title = styled.div`
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
  font-weight: 700;
`
export const LayoutWrapper = styled.div`
  border-radius: 4px;
  background: #fff;
  padding-inline: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
`
export const StyledPagination = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-right: 40px;
  margin-left: 16px;
  margin-bottom: 16px;
  margin-top: 10px;
`
export const FlexComponent = styled.div`
  display: flex;
  gap: 10px;
`
