import React, { createRef } from 'react'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'

import { FormButtons, ImageCropperContainer } from './styled-components'

import { Button } from 'components/common/Button'

interface ImageCropperParams {
  file: any
  hideCropper: () => void
  getCroppedFile: (field: string, croppedFile: File) => void
}

const ImageCropper = ({
  file,
  hideCropper,
  getCroppedFile,
}: ImageCropperParams): React.JSX.Element => {
  const { t } = useTranslation()
  const cropper: any = createRef()

  const finishCrop = (): void => {
    let croppedFile = null
    croppedFile = cropper.current.getCroppedCanvas().toDataURL()
    getCroppedFile(file.name, croppedFile)
    hideCropper()
  }

  return (
    <ImageCropperContainer>
      <Cropper
        ref={cropper}
        src={file.file}
        style={{ height: 500, width: 600 }}
        guides={false}
      />
      <FormButtons>
        <Button
          text={t('actions.cancel')}
          textColor="#414141"
          background="#e0e1e2"
          type="small"
          onClick={hideCropper}
        />
        <Button
          className="photo-save"
          text={t('actions.save')}
          color="secondary"
          background="#06c68f"
          type="small"
          onClick={(): void => finishCrop()}
        />
      </FormButtons>
    </ImageCropperContainer>
  )
}

export default ImageCropper
