import React, { useState, useEffect, useRef } from 'react'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createToolbarPlugin from 'draft-js-static-toolbar-plugin'
import { useTranslation } from 'react-i18next'

import {
  Container,
  ContainerHeader,
  EditorContainer,
  ScrollView,
  EditorActions,
  EditorActionsEditButton,
  EditorActionsEditText,
  EditorEditActions,
  DefaultText,
  ContentContainer,
} from './styled-components'
import { Button } from 'components/common/Button'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'

import Controls from './Controls'
import { linkPlugin, linkifyPlugin } from './Plugins'

// const { Toolbar } = staticToolbarPlugin
// const plugins = [staticToolbarPlugin, linkPlugin, linkifyPlugin]

const TextEditor = ({
  text = '',
  isVisible = true,
  hasControls = false,
  editorTitle = '',
  closeEditMode = () => {},
  openEditMode = () => {},
  onSave = (editorText, plainText) => {},
  canEdit = false,
  defaultText = '',
  handleChange,
  contentHeight = 'auto',
}) => {
  const { t } = useTranslation()
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  )
  const [scrollViewVisible, setScrollViewVisible] = useState(false)

  const [{ plugins, Toolbar }] = useState(() => {
    const createToolbar = createToolbarPlugin()
    const { Toolbar } = createToolbar
    const plugins = [createToolbar, linkPlugin, linkifyPlugin]
    return {
      plugins,
      Toolbar,
    }
  })

  const editorRef = useRef(null)

  const convertToString = (currentState) => {
    const convertedRaw = convertToRaw(currentState)
    const stringified = JSON.stringify(convertedRaw)
    return stringified
  }

  const convertToEditorState = (jsonState) => {
    const rawContent = JSON.parse(jsonState)
    const contentState = convertFromRaw(rawContent)
    const newState = EditorState.createWithContent(contentState)
    return newState
  }

  const scrollToTop = () => {
    const editorDiv = document.querySelector('.DraftEditor-root')
    editorDiv.scrollTo(0, 0)
  }

  const handleCancelClick = () => {
    const newState = convertToEditorState(text)
    setEditorState(newState)
    scrollToTop()
    closeEditMode()
  }

  const handleSaveClick = async () => {
    const editorText = convertToString(editorState.getCurrentContent())
    const plainText = editorState.getCurrentContent().getPlainText('\u0001')
    onSave(editorText, plainText)
  }

  const handleEditorScroll = () => {
    const editorDiv = document.querySelector('.DraftEditor-root')
    const editorHeight = editorDiv.scrollHeight
    const extraHeight = editorHeight - 250
    const editorScrollTop = editorDiv.scrollTop
    if (editorScrollTop > extraHeight / 2) {
      setScrollViewVisible(false)
    } else {
      setScrollViewVisible(true)
    }
  }

  useEffect(() => {
    const editorDiv = document.querySelector('.DraftEditor-root')

    const editorHeight = editorDiv.scrollHeight
    const editorScrollTop = editorDiv.scrollTop

    if (editorHeight > 250 && editorScrollTop < 20) {
      setScrollViewVisible(true)
    } else {
      setScrollViewVisible(false)
    }

    editorDiv.addEventListener('scroll', handleEditorScroll)

    return () => editorDiv.removeEventListener('scroll', handleEditorScroll)
  }, [editorState])

  useEffect(() => {
    if (text) {
      const newState = convertToEditorState(text)
      setEditorState(newState)
    }
  }, [text])

  const isEmpty = !editorState.getCurrentContent().hasText()

  return (
    <Container>
      {hasControls && (
        <ContainerHeader>
          <div>{editorTitle && editorTitle}</div>
          <EditorActions>
            <EditorEditActions className={isVisible ? 'show' : ''}>
              <Button
                text={t('actions.cancel')}
                size="small"
                onClick={handleCancelClick}
                background="#E0E1E2"
                textColor="#414141"
              />
              <Button
                text={t('actions.save')}
                size="small"
                color="secondary"
                onClick={handleSaveClick}
                background="#06C68F"
              />
            </EditorEditActions>
            {canEdit && (
              <EditorActionsEditButton>
                <IconButton
                  disabled={isVisible}
                  onClick={() => {
                    openEditMode()
                    editorRef.current && editorRef.current.focus()
                  }}
                >
                  <EditIcon />
                </IconButton>

                <EditorActionsEditText>
                  {t('actions.edit')}
                </EditorActionsEditText>
              </EditorActionsEditButton>
            )}
          </EditorActions>
        </ContainerHeader>
      )}
      <EditorContainer
        className={isVisible ? 'show' : ''}
        hasControls={hasControls}
      >
        {isVisible && editorRef.current && (
          <Toolbar>
            {(externalProps) => {
              return <Controls externalProps={externalProps} />
            }}
          </Toolbar>
        )}
        <ContentContainer
          contentHeight={contentHeight}
          onClick={() => {
            editorRef.current && editorRef.current.focus()
          }}
        >
          {isEmpty && !isVisible && (
            <DefaultText style={{ position: 'absolute', top: 10, left: 10 }}>
              {defaultText || t('general.nothing_display')}
            </DefaultText>
          )}
          <Editor
            editorState={editorState}
            onChange={(value) => {
              setEditorState(value)
              if (handleChange) {
                const currentState = value.getCurrentContent()
                const currentValue = convertToString(currentState)
                const plainText = currentState.getPlainText('\u0001')
                handleChange(currentValue, plainText)
              }
            }}
            readOnly={!isVisible}
            plugins={plugins}
            ref={(editor) => (editorRef.current = editor)}
          />
        </ContentContainer>
      </EditorContainer>
      {scrollViewVisible && !isVisible && (
        <ScrollView>
          <p style={{ color: '#06c68f' }}>{t('general.scroll_down_to_view')}</p>
        </ScrollView>
      )}
    </Container>
  )
}

export default TextEditor
