import React, { useState, useEffect, useCallback } from 'react'
import update from 'immutability-helper'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { DrawerEventEmitter } from 'helpers/drawer'
import { columnConfig, actionConfig } from './gridConfig'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import { Button } from 'components/common/Button'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Input } from 'components/common/TextInput'
import Grid from 'components/common/Grid'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import IconButton from '@mui/material/IconButton'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import DeleteIcon from '@mui/icons-material/Delete'
import useDeleteLessonService from 'hooks/lessons/useDeleteLessonService'
import Modal from 'components/common/Modal'
import AudioPlayer from 'components/common/AudioPlayer'
import Player from 'components/common/VideoPlayer/Player'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import Drawer from 'components/common/Drawer'

import { getLessonsByTopicIdService } from 'services/lessons/getLessonsByTopicId/getLessonsByTopicIdService'
import {
  IFilterData,
  ILesson,
  ILessonLayoutProps,
  IMediaData,
} from './lessonLayout.interface'
import { changeLessonsOrderService } from 'services/lessons/changeLessonsOrder/changeLessonsOrderService'

const modal = { modal: false, url: '', event: '', subtitle: '' }

const LessonLayout = ({
  topic,
  moduleId,
  refetch,
  lessons,
  isDraggable,
  canModify,
}: ILessonLayoutProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { fireSwal } = useSwal()
  const [state] = useUserValue()

  const [mediaModal, setMediaModal] = useState(modal)
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [filterData, setFilterData] = useState<IFilterData | {}>({})
  const [searchText, setSearchText] = useState('')

  const { deleteLesson } = useDeleteLessonService({
    moduleId,
  })

  const { lessons: lessonsData } = getLessonsByTopicIdService(topic.id)

  const { changeOrder } = changeLessonsOrderService()
  const [cards, setCards] = useState<ILesson[]>([])
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const selectItem = (id: string): void => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = (): void => {
    if (!selectAll) {
      const newArr: string[] = lessonsData.map((n: { id: string }) => n.id)
      setSelectedItem(newArr)
      setSelectAll(true)
      return
    }
    setSelectedItem([])
    setSelectAll(false)
  }

  const handleChangeRequest = async (
    draggedId: string,
    droppedId: string,
  ): Promise<void> => {
    const { data } = await changeOrder({ variables: { draggedId, droppedId } })
    if (data) {
      refetch()
    }
  }

  const handleClick = (item: { id: string }): void =>
    navigate(`/lessons/${item.id}`)

  const handleEditClick = (id: string, event?: string): void => {
    if (event && event === 'edit') {
      DrawerEventEmitter.emit('openDrawer', 'editLesson', true, {
        id: moduleId,
        data: { fromTopic: true, id, topic: { ...topic } },
      })
    }
  }

  const createLessonDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'addLesson', true, {
      id: moduleId,
      data: { name: topic.name, id: topic.id },
    })
  }

  const handleDeleteClick = (ids: string[]): void => {
    const title =
      ids.length === 1
        ? t('popups.delete_lesson_single')
        : t('popups.delete_lesson_many')

    const params = {
      title,
      onConfirm: (): void => {
        setSelectedItem([])
        deleteLesson(ids, () => refetch())
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleOpenMediaModal = (data: IMediaData, event: string): void => {
    if (mediaModal.modal) {
      setMediaModal(modal)
      return
    }

    setMediaModal({
      modal: true,
      url: event === 'audio' ? data?.audio?.link : data?.video?.links[0].url,
      subtitle: event === 'audio' ? '' : data.subtitle,
      event,
    })
  }

  const searchFilter = (
    i: { name: string },
    filed: string,
  ): boolean | { name: string } =>
    filed ? i.name.toLowerCase().includes(filed ? filed.toLowerCase() : '') : i

  const filterList = (data: ILesson[]) => {
    const filtered = data.filter((i: ILesson) => {
      let item = { ...i }

      const { name, range, ...args } = filterData as IFilterData

      for (const key in args) {
        if (
          (args as Record<string, boolean>)[key] === true &&
          i[key as keyof ILesson]
        ) {
          item = { ...i }
        } else if (
          (args as Record<string, boolean>)[key] === false &&
          !i[key as keyof ILesson]
        ) {
          item = { ...i }
        } else {
          return null
        }
      }

      if (range) {
        if (range[0] < i.duration / 60 && range[1] > i.duration / 60) {
          return (item = { ...i })
        } else {
          return null
        }
      }

      const searchItem = searchFilter(item, name)

      return searchItem
    })

    return filtered
  }

  const handleFiltersClick = (): void => {
    setFilterDrawer(true)
  }

  useEffect(() => {
    if (lessons) {
      const items = filterList(lessons)
      setCards(items)
    }
  }, [lessons, filterData])

  const config = columnConfig(t, selectItem, handleClick, handleOpenMediaModal)
  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    t,
    'list',
    state.userPermission,
    canModify,
  )

  return (
    <>
      <Toolbar styles={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <ToolbarItem>
            {state.userPermission.createLesson && canModify && (
              <Button
                text={t('lessons_layout.create_lesson')}
                color="secondary"
                onClick={createLessonDrawer}
                icon={<AddCircleRoundedIcon />}
                background="#06C68F"
              />
            )}
          </ToolbarItem>

          <ToolbarItem>
            <GridMultipleActions
              selectedItems={selectedItem.length}
              actions={
                canModify
                  ? [
                      {
                        id: 0,
                        color: 'secondary',
                        hide: !state.userPermission.createLesson,
                        tooltipText: t('actions.delete'),
                        disabled: selectedItem.length < 1,
                        onClick: () => handleDeleteClick(selectedItem),
                        component: <DeleteIcon fontSize="small" />,
                      },
                    ].filter((i: any) => !i.hide)
                  : []
              }
            />
          </ToolbarItem>
        </div>

        <ToolbarItem>
          <Input
            label={t('general.search_placeholder')}
            type="text"
            size="small"
            value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)}
            icon={
              <IconButton>
                <SearchRoundedIcon />
              </IconButton>
            }
          />
        </ToolbarItem>
      </Toolbar>

      <Grid
        title={t('lessons_layout.title')}
        resetVisible={!!(Object.keys(filterData).length > 0)}
        onFiltersClick={handleFiltersClick}
        resetFilters={() => setFilterData({})}
        isLessons={true}
        data={cards.filter((i: any) => searchFilter(i, searchText))}
        config={config}
        actionConfig={actions}
        selected={_.size(selectedItem)}
        selectAllItem={selectAllItem}
        selectedItems={selectedItem}
        isDraggable={
          isDraggable && _.isEmpty(filterData) && !searchText ? true : false
        }
        changeOrder={handleChangeRequest}
        // loading={loading}
        moveCard={moveCard}
      />

      <Modal isOpened={mediaModal.modal} onClose={handleOpenMediaModal}>
        <div>
          {mediaModal.event === 'audio' && <AudioPlayer url={mediaModal.url} />}
          {mediaModal.event === 'video' && (
            <Player url={mediaModal.url} subtitle={mediaModal.subtitle} />
          )}
        </div>
      </Modal>

      <Drawer opened={filterDrawer} toggleDrawer={() => {}} totalWidth="700px">
        <FilterDrawer onClose={(): void => setFilterDrawer(false)} />
      </Drawer>
    </>
  )
}

export default LessonLayout
