import React from 'react'
// import clsx from 'clsx'
// import StepConnector from '@mui/material/StepConnector'
// import { StepIconProps } from '@mui/material/StepIcon'
// import { makeStyles, withStyles } from '@mui/material/styles'
import DomainIcon from '@mui/icons-material/Domain'
import PersonIcon from '@mui/icons-material/Person'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import WidgetsIcon from '@mui/icons-material/Widgets'
import CustomStepper from 'components/common/Stepper/Custom/Stepper'
import { Container } from './styled-components'
import { TFunction } from 'interfaces/TFunction'

interface Props {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  completedSteps: number[]
  t: TFunction
}

// const ColorlibConnector = withStyles({
//   alternativeLabel: {
//     top: 22,
//   },
//   active: {
//     '& $line': {
//       backgroundPosition: 'left',
//     },
//   },
//   completed: {
//     '& $line': {
//       background: '#06C68F !important',
//       transition: '0 !important',
//     },
//   },
//   line: {
//     height: 4,
//     border: 0,
//     backgroundColor: '#06C68F',
//     background: 'linear-gradient(to left, #eaeaf0 50%, #06C68F 50%) right',
//     backgroundSize: '200%',
//     borderRadius: 1,
//     transition: 'all 1s',
//   },
// })(StepConnector)

const WizardStepper = ({ step, setStep, completedSteps, t }: Props) => {
  // const hasCursor = completedSteps ? completedSteps.indexOf(0) > -1 : false

  // const useColorlibStepIconStyles = makeStyles({
  //   root: {
  //     backgroundColor: '#ccc',
  //     zIndex: 1,
  //     color: '#fff',
  //     width: 50,
  //     height: 50,
  //     display: 'flex',
  //     borderRadius: '50%',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     cursor: hasCursor ? 'pointer' : 'inherit',
  //     transform: 'scale(0.8)',
  //   },
  //   active: {
  //     background: '#06C68F',
  //     transform: 'scale(1.1) !important',
  //     boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  //   },
  //   completed: {
  //     background: '#06C68F',
  //   },
  // })

  // function ColorlibStepIcon(props: StepIconProps) {
  //   const classes = useColorlibStepIconStyles()
  //   const { active, completed } = props

  //   const icons: { [index: string]: React.ReactElement } = {
  //     1: <DomainIcon />,
  //     2: <span className="material-icons">groups</span>,
  //     3: <WidgetsIcon />,
  //     4: <PersonIcon />,
  //     5: <span className="material-icons">groups</span>,
  //     6: <PersonIcon />,
  //     7: <RecordVoiceOverIcon />,
  //   }

  //   return (
  //     <div
  //       className={clsx(classes.root, {
  //         [classes.active]: active,
  //         [classes.completed]:
  //           (props.icon &&
  //             (completedSteps.indexOf(+`${props.icon}` - 1) > -1 ||
  //               +`${props.icon}` - 2 === Math.max(...completedSteps))) ||
  //           completed,
  //       })}
  //     >
  //       {icons[String(props.icon)]}
  //     </div>
  //   )
  // }
  // const stepTitles = [
  //   t('company_wizard_layout.company_details'),
  //   t('company_wizard_layout.invite_company_super_admins'),
  //   t('general.purchased_courses'),
  //   t('company_wizard_layout.group_admins'),
  //   t('company_wizard_layout.group_of_students'),
  //   t('general.students'),
  //   t('company_wizard_layout.assign_coaches'),
  // ]

  const steps = [
    { label: t('company_wizard_layout.company_details'), icon: <DomainIcon /> },
    {
      label: t('company_wizard_layout.invite_company_super_admins'),
      icon: <span className="material-icons">groups</span>,
    },
    { label: t('general.purchased_courses'), icon: <WidgetsIcon /> },
    {
      label: t('company_wizard_layout.group_admins'),
      icon: <PersonIcon />,
    },
    {
      label: t('company_wizard_layout.group_of_students'),
      icon: <span className="material-icons">groups</span>,
    },
    { label: t('general.students'), icon: <PersonIcon /> },
    {
      label: t('company_wizard_layout.assign_coaches'),
      icon: <RecordVoiceOverIcon />,
    },
  ]

  return (
    <Container>
      {/* <Stepper
        active={step}
        steps={stepTitles}
        setStep={setStep}
        completedSteps={completedSteps}
        StepIconComponent={ColorlibStepIcon}
        ColorlibConnector={ColorlibConnector}
      /> */}
      <CustomStepper
        activeStep={step}
        setStep={setStep}
        steps={steps}
        completedSteps={completedSteps}
      />
    </Container>
  )
}

export default WizardStepper
