import React, { useState } from 'react'

// translation
import { useTranslation } from 'react-i18next'

// interfaces
import {
  AddSkillTestVideoCriteriaDrawerProps,
  SkillTestVideoCriteriaDto,
} from '../skillTestVideoCriteria.interface'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'

// components
import {
  AddSkillTestVideoCriteriaContainer,
  ButtonStyles,
  CriteriasContainer,
  CriteriasHeader,
  FormContainerStyles,
} from './styled-components'
import { useData } from 'context/DataContext'
import { useFormik } from 'formik'
import useSetSkillTestVideoCriteriaService from 'hooks/modules/useSetSkillTestVideoCriteria'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'

// icons
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { Button } from 'components/common/Button'
import CriteriaItem, { multiAnswerItem } from './CriteriaItem'

const formData: SkillTestVideoCriteriaDto = {
  name: '',
  criteria: '',
  moduleId: '',
}

const AddEditSkillTestVideoCriteriaDrawer = ({
  title,
  data,
  onClose,
}: AddSkillTestVideoCriteriaDrawerProps) => {
  // props
  const { moduleId, criterias } = data

  // translation
  const { t } = useTranslation()

  // state
  const [multiAnswers, setMultiAnswers] = useState<multiAnswerItem[]>(
    criterias.map((i): multiAnswerItem => ({ value: i.criteria, id: i.id })),
  )

  // hooks
  const [dataState, dispatchData] = useData()

  const {
    setSkillTestVideoCriteria,
    setSkillTestVideoCriteriaLoading,
  } = useSetSkillTestVideoCriteriaService({
    moduleId,
  })

  // formik
  const { handleSubmit } = useFormik({
    initialValues: formData,
    onSubmit() {
      const highestOrderIndex = criterias.length
        ? Math.max(...criterias.map(i => i.orderIndex))
        : 0
      const filteredCriterias = multiAnswers.reduce(
        (
          acc: {
            criterias: {
              criteria: string
              orderIndex: number
            }[]
            idsToRemain: string[]
          },
          curr: multiAnswerItem,
          index: number,
        ) => {
          if (curr.value) {
            if (curr.id) acc.idsToRemain.push(curr.id)
            else
              acc.criterias.push({
                criteria: curr.value,
                orderIndex: index + highestOrderIndex,
              })
          }
          return acc
        },
        {
          criterias: [],
          idsToRemain: [],
        },
      )

      setSkillTestVideoCriteria(
        moduleId,
        filteredCriterias.criterias,
        filteredCriterias.idsToRemain,
        () => onSuccess(),
      )
    },
  })

  // handlers
  const onSuccess = () => {
    onClose()
    dispatchData({ type: CLEAR_FORMDATA })
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addEditSkillTestVideoCriteria',
        values: {},
        compareTo: {},
      },
    })
  }

  const handleAddAnswerClick = () => {
    const updatedMultiAnswers: multiAnswerItem[] = [
      ...multiAnswers,
      { value: '', id: '' },
    ]
    setMultiAnswers(updatedMultiAnswers)
  }

  const handleRemoveAnswerClick = (index: number) => {
    const updatedMultiAnswers: multiAnswerItem[] = [...multiAnswers]
    updatedMultiAnswers.splice(index, 1)
    setMultiAnswers(updatedMultiAnswers)
  }

  const handleAnswerChange = (e: any, index: number) => {
    const { id, value } = e.target

    const updatedMultiAnswers: multiAnswerItem[] = [...multiAnswers]
    updatedMultiAnswers[index] = { value, id }
    setMultiAnswers(updatedMultiAnswers)
  }

  return (
    <AddSkillTestVideoCriteriaContainer>
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68} style={FormContainerStyles}>
        <FormContainer onSubmit={handleSubmit} styles>
          <CriteriasHeader>
            <p>{t('skillTestVideoCriteria_layout.covered')}</p>
            <AddCircleIcon
              onClick={() => handleAddAnswerClick()}
              style={ButtonStyles}
            />
          </CriteriasHeader>
          <FormGroupWrapper>
            <CriteriasContainer>
              <CriteriaItem
                handleRemoveAnswerClick={handleRemoveAnswerClick}
                multiAnswers={multiAnswers}
                handleAnswerChange={handleAnswerChange}
              />
            </CriteriasContainer>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
              <Button
                text={t('actions.save')}
                type="small"
                btnType="submit"
                isDisabled={setSkillTestVideoCriteriaLoading}
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </AddSkillTestVideoCriteriaContainer>
  )
}

export default AddEditSkillTestVideoCriteriaDrawer
