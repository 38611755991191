import React from 'react'
import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as CancelIcon } from 'assets/close.svg'
import { ReactComponent as CheckCircleOutlineIcon } from 'assets/checkmark.svg'

import {
  Action,
  CollapseIcon,
  Empty,
  StyledEditTopicContainer,
  StyledEditTopicContainerInput,
  StyledGridComponentContainer,
  StyledGridComponentContainerInput,
  StyledLessonVideoContainer,
  StyledNestedComponentContainer,
  StyledTooltipNestedSpan,
  StyledTooltipSpan,
  styles,
} from './styled-components'
import Checkbox from 'components/common/Checkbox'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ReactComponent as AddCircleIcon } from 'assets/add-alt.svg'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { ReactComponent as DeleteForeverRoundedIcon } from 'assets/trash-can.svg'
import GetAppIcon from '@mui/icons-material/GetApp'
import { formatDuration } from 'helpers/formatDuration'
import config from 'config'
import CircularProgress from '@mui/material/CircularProgress'
import FilePdf from 'assets/pdf-lesson.png'
import FileVideo from 'assets/audio-lesson.png'
import { ReactComponent as PlayButton } from 'assets/play-button.svg'
import {
  IColumnItem,
  IEditTopic,
  ILink,
  INestedActionConfigItem,
  NestedEventFunction,
  TranslationFunction,
} from './ModuleTopic.interface'
import { Tooltip } from '@mui/material'
import { getLessonDownloadLink } from 'utils/getLessonDownloadLink'
import { Input } from 'components/common/TextInput'
import useConditionalTooltip from 'hooks/tooltip/useConditionalTooltip'

const ImageWithFallback = ({ src, alt }: any) => {
  const [hasError, setHasError] = useState(false)

  if (hasError) {
    return <CircularProgress />
  }

  return (
    <img
      src={src}
      alt={alt}
      style={styles.thumbnail}
      onError={() => setHasError(true)}
    />
  )
}

const EditableNameField = ({
  item,
  index,
  onTopicEdit,
  t,
  canModify,
}: IEditTopic) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [fieldName, setFieldName] = useState(item.name)
  const { showTooltip, contentRef, title } = useConditionalTooltip({
    title: fieldName,
    maxWidth: 20,
  })

  const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (!fieldName) {
      setIsEditMode(false)
      return
    }
    onTopicEdit && onTopicEdit(item.id, fieldName, () => setIsEditMode(false))
  }

  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setIsEditMode(false)
  }

  const handleEditIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsEditMode(true)
  }

  return canModify ? (
    <StyledGridComponentContainerInput ref={contentRef}>
      {!isEditMode ? (
        <StyledEditTopicContainer>
          <StyledTooltipSpan onClick={handleEditIconClick}>
            {showTooltip ? (
              <Tooltip title={title} arrow>
                <span style={styles.topicName}> {`${fieldName}`}</span>
              </Tooltip>
            ) : (
              <span>{fieldName}</span>
            )}
          </StyledTooltipSpan>
          <IconButton onClick={handleEditIconClick}>
            <EditRoundedIcon />
          </IconButton>
        </StyledEditTopicContainer>
      ) : (
        <StyledEditTopicContainerInput
          onClick={(e: any) => {
            e.stopPropagation()
          }}
        >
          <Input
            label="Name*"
            name="name"
            size="small"
            type="text"
            value={fieldName}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
              setFieldName(target.value)
            }
          />
          <IconButton onClick={handleCancelClick}>
            <CancelIcon />
          </IconButton>
          <IconButton onClick={handleEditClick}>
            <CheckCircleOutlineIcon />
          </IconButton>
        </StyledEditTopicContainerInput>
      )}
    </StyledGridComponentContainerInput>
  ) : (
    <div> {item.name}</div>
  )
}

export const columnConfig = (
  t: (key: string) => string | undefined,
  onTopicEdit: (id: string, name: string, callback: () => void) => void,
  canModify?: boolean,
) => {
  const grid = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.topic'),
      render: (item: IColumnItem, index: number): JSX.Element => {
        return (
          <EditableNameField
            item={item}
            index={index}
            onTopicEdit={onTopicEdit}
            t={t}
            canModify={canModify}
          />
        )
      },
    },
    {
      id: 'lessons',
      numeric: false,
      disablePadding: true,
      label: t('general.lessons'),
      render: (item: IColumnItem): JSX.Element => (
        <StyledGridComponentContainer>
          <span>{item.lessons.length}</span>
        </StyledGridComponentContainer>
      ),
    },
    {
      numeric: false,
      disablePadding: true,
      label: t('general.duration'),
      id: 'duration',
      render: (item: IColumnItem): JSX.Element => {
        const duration = item.lessons.reduce(
          (duration: number, lesson: { duration: number }) => {
            duration += lesson.duration

            return duration
          },
          0,
        )

        return (
          <StyledGridComponentContainer>
            <span> {formatDuration(duration)} </span>
          </StyledGridComponentContainer>
        )
      },
    },
  ]

  return grid
}

export const nestedColumConfig = (
  nestedEvent: NestedEventFunction,
  t: TranslationFunction,
  lessonSelect: (id: string) => void,
  canModify?: boolean,
) => {
  let grid = [
    {
      numeric: false,
      disablePadding: true,
      id: 'video',
      render: (item: IColumnItem): JSX.Element | null => {
        if (item.video && item.video.name && item.video.links) {
          const hasConverted = item.video.links.find((link) => link.converted)
          return hasConverted ||
            (config.ENV === 'development' && !hasConverted) ? (
            item?.video?.thumbnail ? (
              <StyledLessonVideoContainer
                onClick={(e): void => {
                  e.stopPropagation()
                  nestedEvent(item, 'playVideo')
                }}
              >
                <ImageWithFallback
                  src={item.video.thumbnail}
                  alt="Video Thumbnail"
                />
                <PlayButton style={styles.playButton} />
              </StyledLessonVideoContainer>
            ) : (
              <div>Processing...</div>
            )
          ) : (
            <CircularProgress size={20} color="primary" />
          )
        }

        if (item.audio && item.audio.link) {
          return (
            <StyledLessonVideoContainer
              onClick={(e): void => {
                e.stopPropagation()
                nestedEvent(item, 'playAudio')
              }}
            >
              <ImageWithFallback src={FileVideo} alt="Video Thumbnail" />
              <PlayButton style={styles.playButton} />
            </StyledLessonVideoContainer>
          )
        }

        if (item.contentDocument && item.contentDocument.link) {
          return (
            <StyledLessonVideoContainer
              style={{ cursor: 'pointer' }}
              onClick={(e): void => {
                e.stopPropagation()
                window.open(item.contentDocument.link, '_blank')
              }}
            >
              <ImageWithFallback src={FilePdf} alt="Video Thumbnail" />
            </StyledLessonVideoContainer>
          )
        }
        return null
      },
    },

    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.topic'),
      render: (item: IColumnItem, index: number): JSX.Element => {
        const topicNumber = index + 1
        return (
          <StyledNestedComponentContainer>
            <span>{`${t('general.lesson')} ${topicNumber}`}</span>
            <StyledTooltipNestedSpan>{item.name}</StyledTooltipNestedSpan>
          </StyledNestedComponentContainer>
        )
      },
    },
    {
      numeric: true,
      disablePadding: true,
      id: 'empty',
      render: (item: IColumnItem) => <Empty></Empty>,
    },
    {
      numeric: false,
      disablePadding: true,
      label: t('general.duration'),
      id: 'duration',
      render: (item: IColumnItem): JSX.Element => (
        <StyledNestedComponentContainer style={{ cursor: 'pointer' }}>
          <span>{formatDuration(item.duration)}</span>
        </StyledNestedComponentContainer>
      ),
    },
  ]

  if (canModify)
    grid = [
      {
        id: 'checkbox',
        numeric: false,
        disablePadding: true,
        render: (item: IColumnItem): JSX.Element => (
          <Checkbox
            checked={item.isChecked}
            onChange={(): void => lessonSelect(item.id)}
          />
        ),
      },
      ...grid,
    ]

  return grid
}

export const actionConfig = (
  handleAddLessonClick: (item: IColumnItem) => void,
  handleDeleteClick: (topicId: string) => void,
  permissions?: {
    createLesson?: boolean
    deleteTopic?: boolean
  },
  onCollapseClick?: (id: string) => void,
  collapsedItems?: string[],
  t: TranslationFunction = (key: string) => key,
) => {
  const action = [
    {
      hide: !(permissions?.createLesson ?? false),
      render: (item: IColumnItem): JSX.Element => (
        // item.lessons && (

        <Tooltip title={t('actions.add_lesson')} arrow>
          <Action
            onClick={(e) => {
              handleAddLessonClick(item)
              e.stopPropagation()
            }}
          >
            <AddCircleIcon />
          </Action>
        </Tooltip>
      ),
      // ),
    },

    {
      hide: !(permissions?.deleteTopic ?? false),
      render: (item: { id: string }): JSX.Element => (
        <Tooltip title={t('actions.delete')} arrow>
          <Action
            onClick={(e) => {
              handleDeleteClick(item.id)
              e.stopPropagation()
            }}
          >
            <DeleteForeverRoundedIcon />
          </Action>
        </Tooltip>
      ),
    },

    {
      render: (item: { id: string }): JSX.Element => (
        <CollapseIcon
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation()
            if (onCollapseClick) {
              onCollapseClick(item.id)
            }
          }}
          className={`${collapsedItems?.includes(item.id) ? 'collapsed' : ''}`}
        >
          <ChevronRightIcon fontSize="small" />
        </CollapseIcon>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}

export const nestedActions = (
  nestedActionClick: any,
  permission?: {
    editLesson?: boolean
    deleteLesson?: boolean
  },
  canModify?: boolean,
  t: TranslationFunction = (key: string) => key,
) => {
  const action = [
    {
      hide: !(permission?.editLesson ?? false),
      render: (item: INestedActionConfigItem): JSX.Element => (
        <Tooltip title={t('actions.edit')} arrow>
          <Action onClick={(): void => nestedActionClick(item, 'edit')}>
            <EditRoundedIcon />
          </Action>
        </Tooltip>
      ),
    },
    {
      hide: !(permission?.deleteLesson ?? false) || !canModify,
      render: (item: INestedActionConfigItem): JSX.Element => (
        <Tooltip title={t('actions.delete')} arrow>
          <Action
            className="delete_lesson"
            onClick={(): void => {
              nestedActionClick(item.id, 'delete')
            }}
          >
            <DeleteForeverRoundedIcon />
          </Action>
        </Tooltip>
      ),
    },

    {
      render: (item: INestedActionConfigItem): JSX.Element => {
        const downloadUrl = getLessonDownloadLink(
          item.video?.links as ILink[] | undefined,
        )

        return (
          <Tooltip title={t('actions.download_video')} arrow>
            <Action
              onClick={(): void => {
                if (downloadUrl) {
                  window.open(downloadUrl, '_blank')
                }
              }}
              className={downloadUrl ? '' : 'disabled'}
            >
              <GetAppIcon />
            </Action>
          </Tooltip>
        )
      },
    },
  ]

  return action.filter((i: any) => !i.hide)
}
