import { useMutation } from '@apollo/client'
import { INSERT_GROUPS_WITH_COURSES } from 'gql/group/group.query'

const importGroupsWithCoursesService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [insertGroupsWithCourses, { loading }] = useMutation(
    INSERT_GROUPS_WITH_COURSES,
  )

  return {
    insertGroupsWithCourses,
    loading,
  }
}

export default importGroupsWithCoursesService
