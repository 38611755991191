import useSnackbarAlert from 'hooks/useSnackbar'
import { InsertGroupsWithCoursesInput } from 'interfaces/groups'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import importGroupsWithCoursesService from 'services/group/importGroupsWithCoursesService'

const useImportGroupsWithCourses = () => {
  const { t } = useTranslation()
  const { insertGroupsWithCourses, loading } = importGroupsWithCoursesService()
  const { setSnackbar } = useSnackbarAlert()

  const importGroupsWithCourses = async (
    values: InsertGroupsWithCoursesInput[],
    companyId: string,
    callBack?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { errors, data } = await insertGroupsWithCourses({
      variables: {
        groups: values,
        companyId,
      },
    })

    let groupsData = null

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `${t('general.groups')} ${t('messages.edited')}`
      variant = 'success'
      groupsData = data.insertGroupsWithCourses
    }

    callBack(groupsData)

    setSnackbar({ message, variant })
  }

  return {
    importGroupsWithCourses,
    loading,
  }
}

export default useImportGroupsWithCourses
