import React from 'react'
import strCutter from 'utils/strCutter'
import moment from 'moment'

import {
  GroupsWrapper,
  GroupItem,
  CheckBoxAndAvatarContainer,
  GroupTitle,
} from 'components/UsersLayout/styled-components'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import SchoolIcon from '@mui/icons-material/School'

import { NoValueWrapper } from 'components/common/Grid/styled-components'
import AvatarIcon from 'assets/profile.svg'
import {
  StudentDetailsContainer,
  StudentDetailsTextContainer,
} from './styled-components'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { TFunction } from 'interfaces/TFunction'
interface itemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  status: GridStatusEnums
  phone: string
  phoneFields: {
    dialCode: string
    code: string
  }
  gender: string
  birthDate: string
  age: string
  biography: string
  note: string
  group: any
  isQualified: boolean
  companyId: any
  courses: any
  avatar: string
  avatarThumbnail: string
  createDate: any
}

export const columnConfig = (t: TFunction) => {
  let grid = [
    {
      id: 'Avatar',
      label: `${t('user_details.student_details')}`,
      render: (item: itemProps) => (
        <StudentDetailsContainer>
          <CheckBoxAndAvatarContainer style={{ marginLeft: 10 }}>
            <img src={item.avatarThumbnail || item.avatar || AvatarIcon} />
          </CheckBoxAndAvatarContainer>
          <StudentDetailsTextContainer>
            <div>
              {item.firstName} {item.lastName}
            </div>
            <div>{item.email}</div>
          </StudentDetailsTextContainer>
        </StudentDetailsContainer>
      ),
      numeric: false,
      disablePadding: true,
      width: '40%',
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.phone')}`,
      render: (item: itemProps) => {
        return (
          <div>
            {item.phoneFields &&
              item.phoneFields.code &&
              `(${item.phoneFields.code}) `}
            {item.phone}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: itemProps) => <StatusIndicator status={item.status} />,
    },
    {
      id: 'group',
      numeric: false,
      disablePadding: false,
      label: `${t('general.groups')}`,
      render: (item: itemProps) => {
        const groups = item.group || []
        const firstTwoGroups = groups.slice(0, 2)
        const exGroups = groups.slice(2, groups.length)
        return (
          <div>
            {firstTwoGroups.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {firstTwoGroups.map(
                (group: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={group.groupId}>
                      <IconButton>
                        <span className="material-icons">groups</span>{' '}
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {group.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exGroups.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exGroups.map((exGroup: any) => (
                        <div key={exGroup.groupId}>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}`,
      render: (item: itemProps) => {
        const courses = item.courses || []
        const firstTwoCourses = courses.slice(0, 2)
        const exCourse = courses.slice(3, courses.length)
        return (
          <div>
            {firstTwoCourses.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {courses.map(
                (course: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton>
                        <SchoolIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {course.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourse.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourse.map((exGroup: any) => (
                        <div key={exGroup.courseId}> {exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps) => (
        <div>
          {item.companyId ? strCutter(item.companyId.name, 25) : <>N/A</>}
        </div>
      ),
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: itemProps) => (
        <>{moment(parseInt(item.createDate)).format('DD-MM-YYYY')}</>
      ),
    },
  ]

  return grid
}
