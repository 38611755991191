import React from 'react'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import {
  GridITemWrapper,
  ProgressBarContainer,
  ProgressFill,
  StyledProgressContainer,
} from './styled-components'

import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'

import defaultAvatar from 'assets/default-avatar.svg'
import { IConfigItem, IDataItem } from './CourseStudents.interface'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import {
  GroupItem,
  GroupTitle,
  GroupsWrapper,
} from 'components/UsersLayout/styled-components'
import { ITFunction } from 'components/common/Tfunction/Tfunction.interface'

export const getGridConfig = (t: (key: string) => string): IConfigItem[] => [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: `${t('user_details.first_name')}`,
    render: (item: IDataItem) => (
      <GridITemWrapper style={{ cursor: 'pointer' }}>
        <img src={item.avatarThumbnail || item.avatar || defaultAvatar} />{' '}
        {item.firstName}
      </GridITemWrapper>
    ),
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: `${t('user_details.last_name')}`,
    render: (item: IDataItem) => (
      <GridITemWrapper>{item.lastName}</GridITemWrapper>
    ),
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: `${t('user_details.email')}`,
    render: (item: IDataItem) => (
      <GridITemWrapper>{item.email}</GridITemWrapper>
    ),
  },

  {
    id: 'groups',
    label: t('general.groups'),
    render: (item: IDataItem): JSX.Element => {
      const firstTwoGroups = item?.group?.slice(0, 2)
      const exGroups = item?.group?.slice(2, item.group.length)
      return (
        <div>
          {firstTwoGroups.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
          <GroupsWrapper>
            {firstTwoGroups.map(
              (groupMap: any, index: number) =>
                index < 3 && (
                  <GroupItem key={groupMap.groupId}>
                    <IconButton>
                      <span className="material-icons">groups</span>{' '}
                    </IconButton>
                    <GroupTitle className="group-item-title">
                      {groupMap.name}
                    </GroupTitle>
                  </GroupItem>
                ),
            )}

            {exGroups.length > 0 && (
              <Tooltip
                title={
                  <>
                    {exGroups.map((exGroup: { id: string; name: string }) => (
                      <div key={exGroup.id}>{exGroup.name}</div>
                    ))}
                  </>
                }
              >
                <span
                  style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    marginLeft: 5,
                  }}
                >
                  ...
                </span>
              </Tooltip>
            )}
          </GroupsWrapper>
        </div>
      )
    },
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: `${t('general.status')}`,
    render: (item: IDataItem) => <StatusIndicator status={item.status} />,
  },
  {
    id: 'progress',
    numeric: false,
    disablePadding: false,
    label: `${t('general.progress')}`,
    render: (item: IDataItem) => (
      <StyledProgressContainer>
        <ProgressBarContainer>
          <ProgressFill style={{ width: `${item.progress || 0}%` }} />
        </ProgressBarContainer>

        <p>{item.progress || 0}%</p>
      </StyledProgressContainer>
    ),
  },
]

export const collapseColumnConfig = (t: ITFunction): IConfigItem[] => [
  {
    id: 'module',
    numeric: false,
    disablePadding: false,
    label: `${t('general.module')}`,
    render: (item: any): JSX.Element => (
      <GridITemWrapper>
        {item.moduleName || <NoValueWrapper>N/A</NoValueWrapper>}
      </GridITemWrapper>
    ),
  },
  {
    id: 'progress',
    numeric: false,
    disablePadding: false,
    label: `${t('general.progress')}`,
    render: (item: any) => (
      <StyledProgressContainer>
        <ProgressBarContainer>
          <ProgressFill style={{ width: `${item.progress || 0}%` }} />
        </ProgressBarContainer>

        <p>{item.progress || 0}%</p>
      </StyledProgressContainer>
    ),
  },
]

export const nestedCollapseColumnConfig = (t: ITFunction): IConfigItem[] => [
  {
    id: 'Lesson',
    numeric: false,
    disablePadding: false,
    label: `${t('general.lesson')}`,
    render: (item: any): JSX.Element => (
      <GridITemWrapper>
        {item.lessonName || <NoValueWrapper>N/A</NoValueWrapper>}
      </GridITemWrapper>
    ),
  },
  {
    id: 'progress',
    numeric: false,
    disablePadding: false,
    label: `${t('general.progress')}`,
    render: (item: any) => (
      <StyledProgressContainer>
        <ProgressBarContainer>
          <ProgressFill style={{ width: `${item.progress || 0}%` }} />
        </ProgressBarContainer>

        <p>{item.progress || 0}%</p>
      </StyledProgressContainer>
    ),
  },
]
