import React from 'react'
import strCutter from 'utils/strCutter'
import Checkbox from 'components/common/Checkbox'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { Action } from './styled-components'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { GroupsWrapper } from 'components/common/DetailsLayout'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { NoValueWrapper } from 'pages/companies/CompanyDetails/styled-components'
import {
  GroupItem,
  GroupTitle,
} from 'pages/companyWizard/CompanyStudents/styled-components'
import SchoolIcon from '@mui/icons-material/School'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { IActionItem, itemProps } from './groupGridConfig.interface'

import { ColumnConfig } from './columnConfig.interface'
import { TFunction } from 'interfaces/TFunction'

export const columnConfig = (
  handelClick: any,
  selectItem: any,
  t: TFunction,
  onGroupClick?: any,
): ColumnConfig[] => {
  const grid: ColumnConfig[] = [
    {
      id: 'isChecked',
      render: (item: itemProps): JSX.Element => (
        <Checkbox
          checked={item.isChecked}
          onChange={(): void => selectItem(item.id)}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: itemProps): JSX.Element => (
        <div
          onClick={(): void => handelClick(item.id)}
          style={{ cursor: 'pointer' }}
        >
          <Tooltip
            title={
              <>
                <div>{item.name}</div>
              </>
            }
          >
            <span
              style={{
                display: 'inline-block',
                cursor: 'pointer',
                marginLeft: 5,
              }}
            >
              {strCutter(item.name, 5)}
            </span>
          </Tooltip>{' '}
        </div>
      ),
    },
    {
      id: 'admin',
      numeric: true,
      disablePadding: false,
      label: t('general.admin'),
      render: (item: itemProps): JSX.Element => {
        return (
          <div>
            {item.admin
              ? item.admin
                  .map((i: { firstName: string }) => i.firstName)
                  .join(', ')
              : 'N/A'}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: itemProps): JSX.Element => (
        <StatusIndicator status={item.status} />
      ),
    },
    {
      id: 'numberOfStudents',
      numeric: true,
      disablePadding: false,
      label: t('general.students'),
      render: (item: itemProps): JSX.Element => (
        <div>{item.numberOfStudents || 0}</div>
      ),
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}`,
      render: (item: itemProps): JSX.Element => {
        const courses = item.courses || []
        const firstTwoCourses = courses.slice(0, 2)
        const exCourse = courses.slice(2, courses.length)
        return (
          <div>
            {firstTwoCourses.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {courses.map(
                (course: { courseId: string; name: string }, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton
                        onClick={(): void => onGroupClick(course.courseId)}
                      >
                        <SchoolIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {course.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourse.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourse.map(
                        (exGroup: { courseId: string; name: string }) => (
                          <div key={exGroup.courseId}>{exGroup.name}</div>
                        ),
                      )}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps): JSX.Element => (
        <div>
          {item.company && item.company.name ? (
            strCutter(item.company.name, 25)
          ) : (
            <>N/A</>
          )}
        </div>
      ),
    },
    {
      id: 'created',
      numeric: true,
      disablePadding: false,
      label: t('general.create_date'),
      render: (item: itemProps): JSX.Element => (
        <div>{moment(item.created).format('DD-MM-YYYY')}</div>
      ),
    },
  ]

  return grid
}

type HandleClickType = (id: string | string[], action: string) => void

export const actionConfig = (
  handleClick: HandleClickType,
): { render: (item: IActionItem) => JSX.Element }[] => {
  const action = [
    {
      render: (item: IActionItem): JSX.Element => (
        <Action onClick={(): void => handleClick(item.id, 'edit')}>
          <EditRoundedIcon />
          <Typography>Edit</Typography>
        </Action>
      ),
    },
    {
      render: (item: IActionItem): JSX.Element => (
        <Action onClick={(): void => handleClick([item.id], 'delete')}>
          <DeleteForeverRoundedIcon />
          <Typography>Delete</Typography>
        </Action>
      ),
    },
  ]
  return action
}

export const groupActionConfig = (
  handleClick: (action: string) => void,
  t: TFunction,
): { render: () => JSX.Element }[] => {
  const action = [
    {
      render: (): JSX.Element => (
        <Action onClick={(): void => handleClick('createGroup')}>
          <AddCircleRoundedIcon />
          <span>
            {t('actions.create')} {t('general.group')}
          </span>
        </Action>
      ),
    },
    {
      render: (): JSX.Element => (
        <Action onClick={(): void => handleClick('addGroup')}>
          <PlaylistAddIcon />
          <span>{t('actions.add_groups_to_course')}</span>
        </Action>
      ),
    },
  ]

  return action
}
