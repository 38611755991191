import { useQuery } from '@apollo/client'
import { GET_TASK_COMMENT_BY_TASK_ID } from 'gql/tasks.query'

export const useFetchTaskComments = (
  taskId: string,
  skip: number = 0,
  count: number = 10,
) => {
  const filter = taskId ? { taskId: taskId } : null

  const { data, loading, error, refetch } = useQuery(
    GET_TASK_COMMENT_BY_TASK_ID,
    {
      variables: {
        filter,
        skip,
        count,
      },
      fetchPolicy: 'cache-first',
      skip: !taskId,
    },
  )

  return {
    taskComments: data && data.getTaskComments,
    loading,
    error,
    taskCommentsRefetch: refetch,
  }
}
