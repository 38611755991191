import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { snackbarProps } from 'interfaces/snackbar'
import { SnackbarVariants } from 'interfaces/common'

import useSnackbarAlert from 'hooks/useSnackbar'

import { DELETE_FILE } from 'gql/assistant/file/file.mutation'
import { GET_ASSISTANT_FILES } from 'gql/assistant/file/file.query'

import {
  DeleteFileResponse,
  IDeleteFileServiceProps,
} from './fileService.interface'

export const useDeleteFileService = ({
  assistantId,
}: IDeleteFileServiceProps): DeleteFileResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [deleteFile, { data, error, loading }] = useMutation(DELETE_FILE, {
    refetchQueries: [
      {
        query: GET_ASSISTANT_FILES,
        variables: { assistantId },
      },
    ],
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  // mutationUsage
  const handleDeleteFile = async (fileId: string): Promise<void> => {
    try {
      let { message, variant }: snackbarProps = {
        message: '',
        variant: undefined,
      }

      const { errors } = await deleteFile({
        variables: { assistantId, fileId },
      })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message = t('AI_assistant.file_deleted')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }

  return {
    deleteFile: (fileId: string) => handleDeleteFile(fileId),
    files: (data && data.deleteFile) || [],
    loading,
    error: errorMessage,
  }
}
