import React, { ReactElement } from 'react'
import styled from 'styled-components'
import Drawer from '@mui/material/Drawer'

const PREFIX = 'CustomDrawer'

const classes = {
  drawer: `${PREFIX}-drawer`,
}

const StyledDrawer = styled(Drawer)<{
  totalWidth: string
}>`
  & > .${classes.drawer} {
    width: ${({ totalWidth }) => totalWidth};
    flex-shrink: 0;
  }
`

interface Props {
  opened: boolean
  toggleDrawer?: (e: KeyboardEvent) => void
  children: React.ReactNode
  totalWidth?: string
}

const CustomDrawer = ({
  opened,
  children,
  toggleDrawer,
  totalWidth = '630px',
}: Props): ReactElement => {
  return (
    <div>
      <StyledDrawer
        anchor="right"
        open={opened}
        onClose={(e: KeyboardEvent): void => toggleDrawer && toggleDrawer(e)}
        classes={{
          paper: classes.drawer,
        }}
        totalWidth={totalWidth}
      >
        {children}
      </StyledDrawer>
    </div>
  )
}

export default CustomDrawer
