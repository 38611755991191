import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import companiesService from '../services/companies/companiesService'
import useSnackbarAlert from '../hooks/useSnackbar'
import { CreateCompanyDto } from 'pages/companies/CompaniesAdd/addCompanyDrawer.interface'

type SnackbarVariant =
  | 'default'
  | 'error'
  | 'warning'
  | 'success'
  | 'info'
  | undefined

const useCompaniesService = (params?: any) => {
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState('')
  const { setSnackbar } = useSnackbarAlert()
  const {
    addCompanyMutation,
    changeCompanyPlatformServiceMutation,
    changeCompanyStatusMutation,
    updateCompanyMutation,
    deleteCompanyMutation,
    addManagerMutation,
    editManagerMutation,
    setCompanyStateMutation,
    checkCompanyEmailMutation,
    removeCompanyAvatarMutation,
  } = companiesService(params)

  const removeCompanyAvatar = async (avatar: string): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { errors } = await removeCompanyAvatarMutation({
      variables: { avatar },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.draft_company_image_deleted')
      variant = 'success'
    }

    setLoading(false)
    setSnackbar({ message, variant })
  }

  const addCompany = async (
    input: CreateCompanyDto,
    callback?: any,
  ): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { errors } = await addCompanyMutation({
      variables: { input },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t('messages.added')}`
      variant = 'success'

      if (callback) callback()
    }
    setLoading(false)
    setSnackbar({ message, variant })
  }

  const checkCompanyEmail = async (email: string): Promise<string | null> => {
    const res = await checkCompanyEmailMutation({
      variables: { email },
    })

    return res.errors ? res.errors[0].message : null
  }

  const changeCompanyStatus = async (
    companyId: string,
    action: string,
  ): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { errors } = await changeCompanyStatusMutation({
      variables: { id: companyId, action },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t('general.status')} ${t(
        'messages.changed',
      )}`
      variant = 'success'
    }
    setLoading(false)
    setSnackbar({ message, variant })
  }

  const changeCompanyPlatformService = async (
    id: string,
    platformService: boolean,
  ): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { errors } = await changeCompanyPlatformServiceMutation({
      variables: { id, platformService },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t(
        'companies_layout.platform_service',
      )} ${t('messages.changed')}`
      variant = 'success'
    }

    setLoading(false)
    setSnackbar({ message, variant })
  }

  const updateCompany = async (
    companyId: string,
    company: any,
    callback?: any,
  ): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { data, errors } = await updateCompanyMutation({
      variables: { id: companyId, input: company },
    })
    if (errors) {
      const error = errors[0]?.message
      message = error?.includes('already registered')
        ? error
        : t('messages.something_wrong')
      variant = 'error'
    } else {
      setCompany(data.updateCompany)
      message = `${t('general.company')} ${t('messages.edited')}`
      variant = 'success'
      if (callback) callback(data.updateCompany)
    }

    setLoading(false)
    setSnackbar({ message, variant })
  }

  const deleteCompany = async (ids: string[]): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { errors } = await deleteCompanyMutation({
      variables: { ids },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t('messages.deleted')}`
      variant = 'success'
    }
    setLoading(false)
    setSnackbar({ message, variant })
  }

  const useAddManager = async (
    input: any,
    companyId: string,
    callback: any,
  ): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { errors }: any = await addManagerMutation({
      variables: {
        input,
        companyId,
      },
    })

    if (errors) {
      message = errors[0].message.message
      variant = 'error'
    } else {
      message = t('messages.success')
      variant = 'success'
      callback()
    }

    setLoading(false)
    setSnackbar({ message, variant })
  }

  const useEditManager = async (
    companyId: string,
    userId: string,
    input: any,
    callback: any,
  ): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    setError('')

    const { errors }: any = await editManagerMutation({
      variables: {
        companyId,
        userId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message.message
      variant = 'error'
      // setError(errors[0].message.message)
    } else {
      message = t('messages.success')
      variant = 'success'
      callback()
    }

    setLoading(false)
    setSnackbar({ message, variant })
  }

  const setCompanyState = async (
    companyId: string,
    state: number,
    successMessage?: any,
    callBack?: any,
  ): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    setError('')

    const { errors } = await setCompanyStateMutation({
      variables: {
        companyId,
        state,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
      setSnackbar({ message, variant })
    } else {
      if (successMessage) {
        message = successMessage
        variant = 'success'
        setSnackbar({ message, variant })
      }
      if (callBack) callBack()
    }

    setLoading(false)
  }

  return {
    deleteCompany,
    addCompany,
    changeCompanyStatus,
    changeCompanyPlatformService,
    updateCompany,
    company,
    error,
    useAddManager,
    useEditManager,
    setCompanyState,
    checkCompanyEmail,
    removeCompanyAvatar,
    loading,
  }
}

export default useCompaniesService
