import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import {
  updateProfileAvatarService,
  editProfileService,
  changePasswordService,
} from 'services/users/editProfileService'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export const useUpdateProfileAvatar = () => {
  const { t } = useTranslation()
  const { updateProfileAvatar } = updateProfileAvatarService()
  const { setSnackbar } = useSnackbarAlert()
  const [hasResponseReceived, setHasResponseReceived] = useState(false)

  const updateAvatar = async (
    avatar: any,
    userId: string,
    action: string,
    crudFunction: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await updateProfileAvatar({
      variables: {
        avatar,
        userId,
        action,
      },
    })

    if (errors) {
      setHasResponseReceived(true)
      message = errors[0].message
      variant = 'error'
    } else {
      setHasResponseReceived(true)
      if (action === 'update') {
        message = t('messages.changes_saved')
      } else {
        message = `${t('general.avatar')} ${t('messages.successfully')} ${t(
          'general.deleted',
        )}`
      }
      variant = 'success'
    }

    setSnackbar({ message, variant })

    crudFunction(hasResponseReceived)
  }

  return {
    updateAvatar,
    hasResponseReceived,
  }
}

export const useEditProfile = (params: any) => {
  const { setSnackbar } = useSnackbarAlert()
  const { editUserProfile } = editProfileService(params)

  const editProfile = async (data: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await editUserProfile({
      variables: {
        input: data,
      },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `profile successful updated`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    editProfile,
  }
}

export const useChangePasswordService = () => {
  const { changePassword } = changePasswordService()
  const { setSnackbar } = useSnackbarAlert()
  const useChangePassword = async (formData: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { oldPassword, newPassword, confirmPassword } = formData
    const { errors } = await changePassword({
      variables: {
        oldPassword,
        newPassword,
        confirmPassword,
      },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `profile successful updated`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    useChangePassword,
  }
}

export const useChangePasswordServiceWithErrorMEssage = () => {
  const { changePassword } = changePasswordService()
  const { setSnackbar } = useSnackbarAlert()
  const [errorMessage, setErrorMessage] = useState('')
  const useChangePassword = async (formData: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { oldPassword, newPassword, confirmPassword } = formData
    const { errors } = await changePassword({
      variables: {
        oldPassword,
        newPassword,
        confirmPassword,
      },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
      setErrorMessage(message)
    } else {
      message = `profile successful updated`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    useChangePassword,
    message: errorMessage,
  }
}
