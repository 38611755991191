import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { GET_ALL_QUIZ } from 'gql/quiz/quiz.query'
import { useTranslation } from 'react-i18next'
import { DrawerEventEmitter } from 'helpers/drawer'
import { actionConfig, columnConfig } from 'pages/questions/gridConfig'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useDeleteQuestion } from 'hooks/questions/useDeleteQuestion'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import { useFetchQuestions, PER_PAGE } from 'hooks/questions/useQuestions'
import { Button } from 'components/common/Button'
import { roles } from 'utils/permission'
import {
  CenteredText,
  Whiteboard,
} from 'pages/tests/testLayout/styled-components'
import Presentation from 'components/common/PresentationIcon/Presentation'
import { ReactComponent as Favorite } from 'components/common/Button/icons/favorite.svg'
import AddCircleIcon from '@mui/icons-material/Add'
import { IQuestion } from 'interfaces/questions'
import { debounce } from 'lodash'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'

interface CurrentModuleParams {
  moduleId: string
  name: string
  courses: {
    courseId: string
    name: string
  }[]
}
interface IRouterState {
  path: string
  search: string
  hash: string
}
interface Params {
  fromModule?: boolean
  topics?: unknown[]
  currentModule?: CurrentModuleParams | undefined
  cols?: number
  isDraggable?: boolean
  refetchModule?: () => void
  manualCompanyId?: string
  canModify?: boolean
  handleTabChange?: (newTab: string | number) => void
  setOpenCreateQuizDrawer?: (open: boolean) => void
  routerState?: IRouterState
}

interface SelectedItem {
  [key: number]: string[]
}

interface SelectAll {
  [key: number]: boolean
}

interface FilterData {
  [key: string]:
    | string
    | number
    | boolean
    | { type: string; value: string }
    | undefined
  companyId?: { type: string; value: string }
}

interface IFilterOption {
  [key: string]: string | number | boolean
}

interface IField {
  id: string
  [key: string]: any
}

interface IQuiz {
  IQTestType: string
  questions: string[]
}

interface IQuizInterface {
  parentName: string
  name: string
}

// const CHANGE_ORDER = gql`
//   mutation ($draggedId: String!, $droppedId: String!) {
//     changeQuestionsOrder(draggedId: $draggedId, droppedId: $droppedId)
//   }
// `

const useQuestionsLayout = ({
  topics,
  currentModule,
  refetchModule,
  manualCompanyId,
  canModify = true,
}: // handleTabChange,

// setOpenCreateQuizDrawer = () => {},
Params): any => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const companyId = state.selectedCompany?.id || null
  const defaultFilters = {
    companyId: {
      type: 'exact',
      value: manualCompanyId || state.selectedCompany?.id,
    },
  }
  const { questions, loading, refetch }: any = useFetchQuestions(
    currentModule ? 'module' : '',
    currentModule ? currentModule.moduleId : '',
    defaultFilters,
  )

  let moduleId: string | null = null
  let courseIds: string[] = []
  const topicIds: string[] = []
  const lessonIds: string[] = []

  if (topics) {
    topics.forEach((topic: any) => {
      topicIds.push(topic.id)
      topic.lessons.forEach((lesson: any) => {
        lessonIds.push(lesson.id)
      })
    })
  }

  if (currentModule) {
    if (currentModule.moduleId) moduleId = currentModule.moduleId
    if (currentModule.courses)
      courseIds = currentModule.courses.map((course) => course.courseId)
  }

  const ids = [moduleId, ...topicIds, ...lessonIds, ...courseIds]

  const { data: quizList, refetch: refetchQuizzes } = useQuery(GET_ALL_QUIZ, {
    variables: {
      query: JSON.stringify({ actionId: { $in: ids } }),
    },
  })

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(PER_PAGE)
  const stateRef = useRef(state)
  const navigate = useNavigate()
  const { deleteQuestion } = useDeleteQuestion()
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<SelectedItem>({})
  const [selectAll, setSelectAll] = useState<SelectAll>({})
  const [LinkQuestionDrawerOpened, setLinkQuestionDrawerOpened] =
    useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<FilterData>({})
  const [isFiltered, setIsFiltered] = useState(false)
  const [filterOptions, setFilterOptions] = useState<any>({})
  const [filtered, setFiltered] = useState<boolean>(false)

  const { fireSwal } = useSwal()

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] =
        questions?.data.map((question: IQuestion) => question.id) || []
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  useEffect(() => {
    stateRef.current = state
  }, [state])

  const debouncedSearch = useRef(
    debounce((newSearchValue: string) => {
      const currentSelectedCompanyId = stateRef.current.selectedCompany?.id

      const newFilterData: FilterData = {
        ...defaultFilters,
        question: {
          type: LowerCaseFilterableFieldType.MATCH,
          value: newSearchValue,
        },
        companyId: currentSelectedCompanyId
          ? {
              type: LowerCaseFilterableFieldType.EXACT,
              value: currentSelectedCompanyId,
            }
          : undefined,
      }

      setFilterData(newFilterData)

      refetch({
        filter: newFilterData,
        currentPage: 1,
        perPage,
      })
    }, 500),
  ).current

  const handleSearchSubmit = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    const filter = {
      ...defaultFilters,
      ...filterOptions,
      question: { type: 'match', value: searchValue },
    }
    // Ensure companyId is not duplicated by removing it from filterOptions if it exists
    if (filterOptions.companyId) {
      delete filter.companyId
    }
    refetch({
      type: '',
      id: '',
      filter,
      currentPage: 1,
    })
    setFiltered(true)
  }

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newSearchValue = e.target.value
      setSearchValue(newSearchValue)
      debouncedSearch(newSearchValue)
      setIsSearchType(true)
    },
    [debouncedSearch, state.selectedCompany?.id],
  )

  const handleCreateQuestionClick = (moduleId: string, name: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'createQuestion', true, {
      data: {
        moduleId,
        name,
        manualCompanyId,
        refetch: () => {
          if (refetchModule) {
            refetchModule()
          }
          setSelectAll({})
          setSelectedItem({})
          refetch()
        },
      },
    })
  }

  const handleViewClick = (questionId: string): void => {
    navigate(`/questions/${questionId}`)
  }

  const handleEditClick = (id: string): void => {
    const title: string = currentModule
      ? t('popups.edit_question_in_module')
      : t('popups.edit_question_gerenal')
    fireSwal({
      title,
      confirmText: t('popups.confirm_edit'),
      onConfirm: () => {
        DrawerEventEmitter.emit('openDrawer', 'editQuestion', true, {
          id,
          data: {
            moduleId: currentModule ? currentModule.moduleId : '',
            name: currentModule ? currentModule.name : '',
            manualCompanyId,
          },
        })
      },
    })
  }

  const handleDuplicateClick = (id: string): void => {
    fireSwal({
      title: t('popups.edit_duplicate_question'),
      confirmText: t('popups.confirm_edit_duplicate'),
      onConfirm: () => {
        DrawerEventEmitter.emit('openDrawer', 'editQuestion', true, {
          id,
          data: {
            moduleId: currentModule ? currentModule.moduleId : '',
            name: currentModule ? currentModule.name : '',
            duplicate: true,
            manualCompanyId,
            refetch: () => refetch(),
          },
        })
      },
    })
  }

  const fireConfirmDeleteQuestionsPopup = (
    ids: string[],
    edited: boolean,
    isMultiple: boolean,
    callback?: () => void,
  ): void => {
    const moduleId: string = currentModule ? currentModule.moduleId : ''
    let mainText = ''
    if (!moduleId) {
      mainText = isMultiple
        ? t('popups.delete_question_many')
        : t('popups.delete_question_single')
    } else {
      mainText = edited
        ? t('popups.delete_question_edited')
        : t('popups.delete_question_many_in_module')
    }

    fireSwal({
      title: `${mainText}`,
      confirmText: t('popups.confirm_remove'),
      onConfirm: async () => {
        try {
          await deleteQuestion(moduleId, ids, () => {
            if (refetchModule) refetchModule()
            if (callback) callback()
            refetch()
          })
        } catch (error) {
          console.error('Failed to delete question', error)
        }
        setSelectedItem([])
      },
    })
  }

  const fireMultipleQuestionsIsUsedInQuizPopup = (): void => {
    fireSwal({
      title: `${t('popups.questions_is_used_in_quizzes')} ${t(
        'general.questions',
      )}`,
      confirmText: t('actions.close'),
      onConfirm: () => {},
    })
  }
  const fireQuestionIsUsedInQuizPopup = (
    quizzes: any,
    ids: string[],
    callback?: () => void,
  ): void => {
    const moduleId: string = currentModule ? currentModule.moduleId : ''
    const quizNames = quizzes
      .map((quiz: IQuizInterface) => `• ${quiz.parentName} - ${quiz.name}`)
      .join('\n')
    const alertText =
      quizNames.length > 1
        ? `${t('popups.question_is_used_in_quizzes')} \n${quizNames}.\n`
        : `${t('popups.question_is_used_in_quiz')} \n${quizNames}.\n`
    fireSwal({
      title: `${alertText} ${t('popups.confirm_deletion')}`,
      confirmText: t('popups.confirm_remove'),
      customClass: {
        title: 'fireQuestionIsUsedInQuizPopup',
      },
      onConfirm: () => {
        deleteQuestion(moduleId, ids, () => {
          if (refetchModule) {
            refetchModule()
          }
          refetch()
        })
        if (callback) callback()
        setSelectedItem([])
      },
      width: `50%`,
    })
  }

  const handleDeleteClick = (
    ids: string[],
    edited: boolean,
    callback?: () => void,
  ): void => {
    refetchQuizzes()

    const isMultiple = ids.length > 1

    const quizzesWhereQuestionIsUsed = quizList?.getAllQuizzes.filter(
      (quiz: IQuiz) =>
        quiz.IQTestType !== 'final' &&
        quiz.questions.some((id: string) => ids.includes(id)),
    )

    if (quizzesWhereQuestionIsUsed.length) {
      isMultiple
        ? fireMultipleQuestionsIsUsedInQuizPopup()
        : fireQuestionIsUsedInQuizPopup(quizzesWhereQuestionIsUsed, ids)
    } else {
      fireConfirmDeleteQuestionsPopup(ids, edited, isMultiple, callback)
    }
  }

  // const questionActions = questionActionsConfig(
  //   handleCreateQuestionClick,
  //   handleLinkQuestionToModuleClick,
  //   t,
  // )
  const actionHandler = (event: string, field: IField): void | (() => void) => {
    switch (event) {
      case 'view':
        return handleViewClick(field.id)
      case 'edit':
        return handleEditClick(field.id)
      case 'duplicate':
        return handleDuplicateClick(field.id)
      case 'delete':
        return handleDeleteClick([field.id], field.edited)
      case 'pdf':
        return () => {}
      default:
    }
  }

  const handleCloseDrawer = (): void => {
    const { text, confirm } = state.closeDrawer
    if (!text && !confirm) {
      setLinkQuestionDrawerOpened(false)
    } else {
      fireSwal({
        title: text,
        text: t('popups.sure'),
        onConfirm: () => {
          setLinkQuestionDrawerOpened(false)
          dispatch({
            type: TOGGLE_CLOSE_DRAWER,
            payload: {
              text: '',
              confirm: false,
            },
          })
        },
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  const handlePaginationClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      if (value !== currentPage) {
        refetch({
          type: '',
          id: '',
          filter: {
            ...defaultFilters,
            ...filterOptions,
            companyId: {
              type: 'exact',
              value: manualCompanyId || state.selectedCompany?.id,
            },
            question: {
              type: 'match',
              value:
                searchValue.length > 0
                  ? searchValue
                  : filterOptions?.question?.value || '',
            },
          },
          currentPage: value,
          perPage,
        })
        setCurrentPage(value)
      }
    },
    [currentPage, filterOptions, defaultFilters],
  )

  const handleFilterClick = (filterOptions: IFilterOption): void => {
    setSelectedItem({})
    setSelectAll({})
    refetch({
      filter: {
        ...defaultFilters,
        ...filterOptions,
      },
      currentPage: 1,
    })
    setFilterOptions(filterOptions)
  }
  const actions = actionConfig(
    state.userPermission,
    currentModule,
    actionHandler,
    canModify,
    t,
    setIsSearchType,
  )
  const config = columnConfig(selectItem, t)

  const redirectToQuestion = (question: IQuestion): void => {
    navigate(`/questions/${question.id}`)
  }

  // for SUADA consultants it is necessary to select a company to use the questions page
  if (
    state.currentUser.role === roles.consultant &&
    !state.selectedCompany?.id
  ) {
    return (
      <Whiteboard>
        <CenteredText>
          <Presentation />
          <h4>{t('questions_layout.switch_company')} </h4>
        </CenteredText>
      </Whiteboard>
    )
  }
  const breadCrumbData = [
    {
      label: 'Evaluation',
      icon: <Favorite />,
    },
    {
      label: 'Questions',
    },
  ]

  const createButton = (): React.JSX.Element => (
    <Button
      text={t('quiz_details.add_question')}
      color="secondary"
      onClick={(): void => handleCreateQuestionClick('', '')}
      icon={<AddCircleIcon />}
      background="#06C68F"
    />
  )

  return {
    breadCrumbData,
    selectedItem,
    createButton,
    currentPage,
    handleEditClick,
    t,
    handleDeleteClick,
    setFilterDrawerOpened,
    isFiltered,
    handleSearchSubmit,
    searchValue,
    handleSearchChange,
    loading,
    actions,
    config,
    cards: questions?.data || [],
    selectAllItem,
    selectItem,
    redirectToQuestion,
    questions,
    handlePaginationClick,
    LinkQuestionDrawerOpened,
    handleCloseDrawer,
    setLinkQuestionDrawerOpened,
    refetch,
    filterDrawerOpened,
    handleFilterClick,
    setIsFiltered,
    filterData,
    setFilterData,
    setFiltered,
    setCurrentPage,
    companyId,
    isSearchType,
  }
}

export default useQuestionsLayout
