import i18n from 'i18n/index'
interface FilterOptions {
  name: string
  platformService: {
    value: string
    label: string
  }
  status: {
    value: string
    label: string
  }
  createDate: any
}
export let filterOptions: FilterOptions = {
  name: '',
  platformService: { value: 'all', label: 'All' },
  status: { value: 'all', label: 'All' },
  createDate: [null, null],
}
export let platformServiceOptions: any = []
export let statusOptions: any = []
function translate() {
  statusOptions = [
    { value: 'all', label: i18n.t('general.all') },
    { value: 'DRAFT', label: i18n.t('general.draft') },
    { value: 'SUSPENDED', label: i18n.t('general.suspended') },
    { value: 'ACTIVE', label: i18n.t('general.active') },
  ]
  platformServiceOptions = [
    { value: 'all', label: i18n.t('general.all') },
    { value: false, label: i18n.t('general.disabled') },
    { value: true, label: i18n.t('general.enabled') },
  ]

  filterOptions = {
    name: '',
    platformService: { value: 'all', label: i18n.t('general.all') },
    status: { value: 'all', label: i18n.t('general.all') },
    createDate: [null, null],
  }
}

i18n.on('languageChanged init', () => {
  translate()
})
