import { useLazyQuery } from '@apollo/client'
import IconButton from '@mui/material/IconButton'
import { Button } from 'components/common/Button'
import DateRangePicker from 'components/common/DateRangePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
  Label,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import RangeSlider from 'components/common/RangeSlider'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  filterGenderOptions,
  statuses,
  studentsFilterOptions,
} from 'components/UsersLayout/staticData'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
// import SelectField from 'components/common/Select/Select'
import { GET_COMPANIES } from 'gql/companies.query'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { GET_GROUP } from 'gql/group/group.query'
import { FilterStudentsSchema } from 'helpers/validationSchemas'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CoursesAsyncSelect from 'components/common/SelectField/CoursesAsyncSelect'
import { useLocation } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { roles } from 'utils/permission'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'

const FilterStudents = (props: any) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const ref: any = useRef()
  const { createDate, setCreateDate } = useFilterContext()
  const { filterRole, setFilterRole } = useFilterRoleContext()
  // const { data, loading } = useQuery(GET_GROUP, {
  //   variables: {
  //     filter: {
  //       company: {
  //         type: 'exact',
  //         value: state.selectedCompanyId
  //       }
  //     }
  //   }
  // })
  const [fetchGroup, { data: groupData, loading: groupLoading }] =
    useLazyQuery(GET_GROUP)
  const [fetchCompany, { data: companyData, loading: companyLoading }] =
    useLazyQuery(GET_COMPANIES)

  const [fetchCourse, { data: courseData, loading: courseLoading }] =
    useLazyQuery(GET_ALL_COURSE)

  // const { data: companyData } = useQuery(GET_COMPANIES, {
  //   variables: {
  //     filter: {},
  //   },
  // })

  const [error, setError] = useState(false)
  setFilterRole('STUDENTS')

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: studentsFilterOptions,
    validationSchema: FilterStudentsSchema,
    onSubmit(values) {
      const formValues: any = {}
      // when we submit filter. clear the createDate
      if (createDate) {
        setCreateDate([undefined, undefined])
      }
      if (values.firstName) {
        formValues.firstName = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.firstName,
        }
      }
      if (values.lastName) {
        formValues.lastName = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.lastName,
        }
      }
      if (values.note) {
        formValues.note = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.note,
        }
      }
      if (values.location) {
        formValues.location = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.location,
        }
      }

      if (values.gender.value !== 'all') {
        formValues.gender = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: values.gender.value,
        }
      }

      if (values.group) {
        const searchedGroupIds = [...values.group.map((gr: any) => gr.value)]

        const groups =
          searchedGroupIds && props?.defaultFilter?.group?.value
            ? [...searchedGroupIds, ...props.defaultFilter.group.value]
            : searchedGroupIds

        formValues.group = {
          nestedField: 'groupId',
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_ALL,
          value: groups,
        }
      }

      // queryString['course.courseId'] = {
      //   $in: values.course.map((i: any) => i.value),
      // }
      if (values.course) {
        formValues.courses = {
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: values.course.map((i: any) => {
            return i.value
          }),
          nestedField: 'courseId',
        }
      }

      if (values.status.value !== 'all') {
        formValues.status = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: values.status.value,
        }
      }

      if (values.company) {
        const companyIds = values.company.map((i: any) =>
          i.value === '-1' ? (i.value = null) : i.value,
        )
        const query = { companyId: { $in: companyIds } }
        // formValues.companyId = {
        //   type: 'arrayIn',
        //   value: companyIds,
        // }
        formValues.query = {
          type: LowerCaseFilterableFieldType.QUERY,
          value: JSON.stringify(query),
        }
      }

      const isAllZero = values.age.every((item) => item === 0)

      if (!isAllZero) {
        formValues.age = {
          type: LowerCaseFilterableFieldType.RANGE,
          value: `${values.age[0]}-${values.age[1]}`,
        }
      }

      // formValues.query = {
      //   type: 'query',
      //   value: JSON.stringify(queryString),
      // }

      if (values.gender.value !== 'all') {
        formValues.gender = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: values.gender.value,
        }
      }

      createDate[0] = createDate[0] && new Date(createDate[0])
      createDate[1] = createDate[1] && new Date(createDate[1])

      const isSameDate =
        createDate[0] === createDate[1] && createDate[0] !== undefined

      if (!createDate[1] && createDate[0] !== undefined)
        createDate[1] = new Date()

      const formattedData = isSameDate
        ? createDate[0]
        : `${createDate[0] ? createDate[0].toISOString() : ''}${'-'}${
            createDate[1] ? createDate[1].toISOString() : ''
          }`

      if (createDate[0] !== undefined || createDate[1] !== undefined) {
        formValues.createDate = {
          type: isSameDate
            ? LowerCaseFilterableFieldType.EXACT
            : LowerCaseFilterableFieldType.DATE_RANGE,
          value: formattedData as string,
        }
      }
      if (props.refetchStudents && props.defaultFilter) {
        props.refetchStudents({
          role: roles.student,
          filter: { ...props.defaultFilter, ...formValues },
          currentPage: 1,
          perPage: 10,
        })
        props.closeDrawer()
      }

      props.filter(formValues)
      props.setFilterOptions(values)
    },
  })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'STUDENTS') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  useEffect(() => {
    if (props.filterOptions) {
      setValues(props.filterOptions)
    }
  }, [])

  useEffect(() => {
    props.setFilterOptions(values)
  }, [values])

  const handleRangeChange = (range: any) => {
    setFieldValue('age', range)
  }

  const currentLocation = useLocation()
  const inOnCompanyPage = currentLocation.pathname.indexOf('companies') > -1

  const loadDataOptions = (e: any, field: string) =>
    setTimeout(() => {
      const value = e
      if (field === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: 'MATCH',
                value,
              },
            },
          },
        })
      } else if (field === 'group') {
        let filter: any = {
          name: {
            type: 'match',
            value,
          },
        }
        if (props.useCompanyId) {
          filter = {
            ...filter,
            company: {
              type: 'exact',
              value: props.manualCompanyId
                ? props.manualCompanyId
                : state.selectedCompany?.id,
            },
          }
        }
        fetchGroup({
          variables: {
            filter,
            perPage: 0,
          },
        })
      } else {
        let filter: any = {
          name: { type: 'MATCH', value },
        }

        if (!inOnCompanyPage && !props.useCompanyId) {
          filter = {
            ...filter,
            originalId: { type: 'ARRAY_IN', value: [null] },
          }
        }
        if (props.useCompanyId) {
          filter = {
            ...filter,
            companyId: {
              type: 'EXACT',
              value: props.manualCompanyId
                ? props.manualCompanyId
                : state.selectedCompany?.id,
            },
          }
        }
        fetchCourse({
          variables: {
            filter,
            perPage: 0,
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, field: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }

  const [companyOptions, setCompanyOptions] = useState([] as any)

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])

  return (
    <>
      {props.filterLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{t('actions.filters')}</DrawerTitle>
        <IconButton onClick={props.closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  title={t('user_details.first_name')}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  placeholder={t('user_details.first_name')}
                  size="small"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={t('user_details.last_name')}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  placeholder={t('user_details.last_name')}
                  size="small"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>

              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('general.status')}</Title>
                <SelectField
                  name="status"
                  options={statuses}
                  value={values.status}
                  onChange={(e: any) => setFieldValue('status', e)}
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('groups_layout.select_group')}</Title>
                {values.group && values.group.length > 0 ? (
                  <Label>{t('form_fields.search_groups')}</Label>
                ) : null}
                <AsyncSelect
                  isMulti
                  onInputChange={(e: any) => handleSelectChange(e, 'group')}
                  onChange={(e: any) => setFieldValue('group', e)}
                  data={(groupData && groupData.getAllGroups.data) || []}
                  value={values.group}
                  label={t('form_fields.type_to_search_groups')}
                  loading={groupLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('groups_layout.select_course')}</Title>
                {values.course && values.course.length > 0 ? (
                  <Label>{t('form_fields.courses')}</Label>
                ) : null}
                <CoursesAsyncSelect
                  isMulti
                  onInputChange={(e: any) => handleSelectChange(e, 'course')}
                  onChange={(e: any) => setFieldValue('course', e)}
                  data={(courseData && courseData.getAllCourse.data) || []}
                  value={values.course}
                  label={t('form_fields.type_to_search_courses')}
                  loading={courseLoading}
                  labelDataKey="name"
                />
              </FormField>
              {/* {props.hasSearchByCompany && !state.selectedCompany?.id && ( */}
              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('groups_layout.select_company')}</Title>
                {values.company && values.company.length > 0 ? (
                  <Label>{t('form_fields.search_companies')}</Label>
                ) : null}
                <AsyncSelect
                  isMulti
                  onInputChange={(e: any) => handleSelectChange(e, 'company')}
                  onChange={(e: any) => setFieldValue('company', e)}
                  data={companyOptions}
                  value={values.company}
                  label={t('form_fields.type_to_search_companies')}
                  loading={companyLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                  iconDataKey="icon"
                />
              </FormField>
              {/* )} */}

              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('user_details.gender')}</Title>
                <SelectField
                  placeholder={t('user_details.gender')}
                  options={filterGenderOptions}
                  value={values.gender}
                  onChange={(e: any) => setFieldValue('gender', e)}
                />
              </FormField>

              <FormField width="48%">
                <TextInput
                  title={t('form_fields.location')}
                  placeholder={t('form_fields.location')}
                  size="small"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={t('user_details.note')}
                  placeholder={t('user_details.note')}
                  size="small"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('general.create_date')}</Title>
                <DateRangePicker
                  values={createDate}
                  setCreateDate={setCreateDate}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('form_fields.age_range')}</Title>
                <RangeSlider
                  value={values.age}
                  onRangeChange={handleRangeChange}
                  style={{ color: '#08C694' }}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
                isDisabled={!!error}
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={props.closeDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterStudents
