import { useQuery } from '@apollo/client'

import { GET_AVAILABLE_GROUPS_FOR_TEST } from 'gql/group/group.query'

export const PER_PAGE = 10

export const useAvailableGroupsForTestAssessment = (
  testAssessmentId: string,
  currentPage = 1,
  perPage: number = PER_PAGE,
  filter: any = {},
) => {
  const { data, loading, error, refetch } = useQuery(
    GET_AVAILABLE_GROUPS_FOR_TEST,
    {
      variables: {
        testAssessmentId,
        filter,
        currentPage,
        perPage,
      },
    },
  )

  const errorMessage = error && error?.message.split(':')[1]

  return {
    groups: data && data.getAvailableGroupsForTestAssessment,
    loading,
    error: errorMessage,
    refetch,
  }
}
