import React, { useEffect } from 'react'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useFormik } from 'formik'
import { useUserValue } from 'context/UserContext'
import {
  useUploadFile,
  useExtractTextService,
} from 'hooks/helpers/useHelpersService'
import { useAddTopic } from 'hooks/topics/useAddTopic'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'
import { AddTopicSchema } from 'helpers/validationSchemas'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import {
  Props,
  formData,
} from 'pages/modules/AddTopicDrawer/AddTopicDrawer.interface'
import { UseAddTopicDrawerReturn } from './useAddTopicDrawer.interface'

const useAddTopicDrawer = ({ id, data }: Props): UseAddTopicDrawerReturn => {
  const { addTopic, newTopic, addTopicLoading } = useAddTopic({
    moduleId: id,
    filter: {},
  })
  const { extractText, loading: extractTextLoadingString } =
    useExtractTextService()
  const { uploadFile, fileLoading: fileLoadingString } = useUploadFile()

  const fileLoading = fileLoadingString === 'attachment'
  const extractTextLoading = extractTextLoadingString === 'extract'

  const { handleSubmit, handleChange, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: formData,
      validationSchema: AddTopicSchema,
      onSubmit(values) {
        if (addTopicLoading) return

        addTopic(id, values)
      },
    })
  const [dispatch] = useUserValue()
  const [dataState, dispatchData] = useData()
  const closeDrawer = (): boolean =>
    DrawerEventEmitter.emit('openDrawer', 'addTopic', false)

  const handleCloseDrawer = (): void => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addTopic',
        values,
        compareTo: {},
      },
    })
  }

  const handleAttachmentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { files } = e.target
    if (!files || files.length === 0) return
    const file = files[0]
    uploadFile(file, 'topic/attachment', (link: string) =>
      setFieldValue('attachment', {
        name: file.name.split('.')[0],
        fileType: file.name.split('.').slice(-1)[0],
        link,
      }),
    )
  }

  const deleteAttachment = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setFieldValue('attachment', null)
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (newTopic) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
      closeDrawer()
      data.refetch()
    }
  }, [newTopic])

  const handleExtractText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e || !e.target || !e.target.files) return
    const { files } = e.target
    const file = files[0]
    uploadFile(file, `uploads/extract`, (link: string) => {
      extractText(link, (text: string) => setFieldValue('description', text))
    })
  }

  return {
    addTopicLoading,
    handleSubmit,
    handleCloseDrawer,
    fileLoading,
    errors,
    touched,
    values,
    handleChange,
    handleExtractText,
    handleAttachmentChange,
    deleteAttachment,
    setFieldValue,
    extractTextLoading,
  }
}

export default useAddTopicDrawer
