import useDeleteUserService from 'hooks/users/useDeleteUserService'
import {
  IUseHelperDeleteUserProps,
  IUseHelperDeleteUserReturn,
} from './useHelperDeleteUser.interface'

export const useHelperDeleteUser = ({
  ROLE,
  defaultFilter,
  refetch,
  perPage,
  currentPage,
  filteredByCompany,
  selectedItem,
  setSelectedItem,
}: IUseHelperDeleteUserProps): IUseHelperDeleteUserReturn => {
  const { deleteUser } = useDeleteUserService({
    ROLE,
    defaultFilter,
  })

  // role is from function & ROLE is from props

  const confirmDeleteMany = (role?: string): void => {
    deleteUser(selectedItem[currentPage], role || ROLE || '', () =>
      refetch({
        filter: {
          ...defaultFilter,
        },
        companyId: filteredByCompany,
        currentPage,
        perPage,
      }),
    )
    setSelectedItem([])
  }

  const confirmDelete = async (userIds: string[]): Promise<void> => {
    deleteUser(userIds, ROLE || '', () =>
      refetch({
        ROLE,
        filter: {
          ...defaultFilter,
        },
        companyId: filteredByCompany,
        currentPage,
        perPage,
      }),
    )
  }

  return { confirmDelete, confirmDeleteMany }
}
