import { useQuery } from '@apollo/client'
import { GET_AVAILABLE_GROUP_IDS_FOR_TEST } from 'gql/group/group.query'

export const useAvailableGroupIdsForTestAssessment = (
  testAssessmentId: string,
  filter: any = {},
) => {
  const { data, loading, error, refetch } = useQuery(
    GET_AVAILABLE_GROUP_IDS_FOR_TEST,
    {
      variables: {
        testAssessmentId,
        filter,
      },
    },
  )

  const errorMessage = error && error?.message.split(':')[1]

  return {
    groupIds: (data && data.getAvailableGroupIdsForTestAssessment) || [],
    loading,
    error: errorMessage,
    refetch,
  }
}
