import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { FormItemContainer, Label } from './styled-components'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import ReactSelect from 'react-select'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'

import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { filterDefault, filterOptions } from 'pages/quizzes/staticData'
import { GET_TAGS } from 'gql/skilss.query'

type valueType = {
  value: string
  label: string
}

type quizzesFilterType = {
  name: string
  quizType: valueType
  quizParticipationType: valueType
  timeType: valueType
  tags: valueType[]
  range: any
  passRate: any
}

const FilterDrawer = (props: any) => {
  const [filterData, setFilterData] = useState<quizzesFilterType>(filterOptions)
  const [range, setRange] = useState([0, 300])
  const [passRate, setPassRate] = useState([0, 100])

  const { data: tagsData } = useQuery(GET_TAGS, {
    variables: {
      companyId: props.companyId,
    },
  })

  const tagList =
    (tagsData &&
      tagsData.getAllTags.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  useEffect(() => {
    if (props?.filterData?.values) {
      setFilterData(props.filterData.values)
      setRange(props.filterData.values.range)
      setPassRate(props.filterData.values.passRate)
    }
  }, [props.filterData])

  const handleFieldChange = (field: string, action?: string) => {
    if (action && action === 'dropdown') {
      return (e: any) => {
        setFilterData({
          ...filterData,
          [field]: e,
        })
      }
    }

    return (e: any) => {
      setFilterData({
        ...filterData,
        [field]: e.target.value,
      })
    }
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault()

    const { name, quizType, quizParticipationType, timeType, tags } = filterData
    const values: any = {}

    if (name)
      values.name = {
        type: 'MATCH',
        value: name,
      }
    else delete values.name

    if (quizType.value.toUpperCase() !== 'ALL') {
      values.quizType = {
        type: 'EXACT',
        value: quizType.value.toUpperCase(),
      }
    } else delete values.quizType

    if (quizParticipationType.value.toUpperCase() !== 'ALL') {
      values.quizParticipationType = {
        type: 'EXACT',
        value: quizParticipationType.value.toUpperCase(),
      }
    } else delete values.quizParticipationType

    if (timeType.value.toUpperCase() !== 'ALL')
      values.timeType = {
        type: 'EXACT',
        value: timeType.value.toUpperCase(),
      }
    else delete values.timeType

    if (tags)
      values.tags = {
        type: 'NESTED_ARRAY_IN',
        value: tags.map((tag) => tag.label),
        nestedField: 'LABEL',
      }
    else delete values.tags

    if (
      (range[0] === 0 && range[1] === 0) ||
      (range[0] === 0 && range[1] === 300)
    )
      delete values.time
    else
      values.time = {
        type: 'RANGE',
        min: range[0],
        max: range[1],
      }

    values.passRate = {
      type: 'RANGE',
      min: passRate[0],
      max: passRate[1],
    }

    props.setFilterData({
      filterOptions: {
        ...values,
      },
      values: { ...filterData, range, passRate },
    })

    props.setCurrentPage(1)

    props.onClose()
  }

  const { t } = props

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('general.advanced_filters')}</DrawerTitle>
        <IconButton onClick={props.onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <br />

      <DrawerContent>
        <FormContainer onSubmit={onFormSubmit}>
          <FormGroupWrapper>
            <FormGroupItem>
              <Input
                label={t('form_fields.name')}
                name="name"
                size="small"
                type="text"
                fullWidth
                value={filterData.name}
                onChange={handleFieldChange('name')}
              />
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('quiz_details.entity_type')}</Label>
                <ReactSelect
                  onChange={handleFieldChange('quizType', 'dropdown')}
                  options={filterDefault.quizType}
                  value={filterData.quizType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>
                  {t('general.quiz')} {t('quiz_details.participation')}{' '}
                  {t('general.type')}
                </Label>
                <ReactSelect
                  onChange={handleFieldChange(
                    'quizParticipationType',
                    'dropdown',
                  )}
                  options={filterDefault.quizParticipationType}
                  value={filterData.quizParticipationType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('quiz_details.time_type')}</Label>
                <ReactSelect
                  onChange={handleFieldChange('timeType', 'dropdown')}
                  options={filterDefault.timeType}
                  value={filterData.timeType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.tags')}</Label>
                <ReactSelect
                  isMulti
                  onChange={handleFieldChange('tags', 'dropdown')}
                  options={tagList}
                  value={filterData.tags}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <Typography id="range-slider" gutterBottom>
                {t('quiz_details.time_in_minutes')}
              </Typography>
              <Slider
                value={range}
                onChange={(e: any, i: any) => setRange(i)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={300}
              />
            </FormGroupItem>

            <FormGroupItem>
              <Typography id="range-slider" gutterBottom>
                {t('quiz_details.pass_rate')}
              </Typography>
              <Slider
                value={passRate}
                onChange={(e: any, i: any) => setPassRate(i)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={100}
              />
            </FormGroupItem>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={() => {
                  props.setFilterData({
                    filterOptions: null,
                    values: null,
                  })
                  props.onClose()
                }}
                background="#E0E1E2"
                textColor="#414141"
              />
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
