import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { User } from 'interfaces/users'

export enum ActionTypes {
  EDIT = 'edit',
  MODIFY_STUDENTS = 'modifyStudents',
  ASSIGN_TUTOR = 'assignTutor',
  DELETE = 'delete',
}

export interface itemProps {
  name: string
  description: string
  location: string
  isChecked?: boolean
  id: string
  last?: string
  numberOfStudents?: number | string
  admin: User[]
  created: string
  status: GridStatusEnums
  companyId: string
  courses: any
  company: any
}
