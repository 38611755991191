import React, { FormEvent } from 'react'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import { Button } from 'components/common/Button'

import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import Grid from 'components/common/Grid'
import GetAppIcon from '@mui/icons-material/GetApp'
import PublishIcon from '@mui/icons-material/Publish'
import {
  ErrorMessage,
  FormGroupWrapperStyles,
  StyledInput,
  ToolbarItemsWrapper,
  ToolbarStyles,
} from './styled-components'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import useImportStudentsInGroupsServices from 'hooks/group/importStudents/useImportStudentsInGroupsServices'
import { ImportStudentsInGroupsProps } from './importStudentsInGroups.interface'
import { default as configs } from 'config'
import { ImportNote } from '../styled-components'
const ImportStudentsInGroupsDrawer = ({
  refetch,
  onClose,
}: ImportStudentsInGroupsProps) => {
  const {
    t,
    handleFileChange,
    selectedStudents,
    toolbarAction,
    imported,
    studentList,
    studentsImportLoading,
    atLeastOneSelectedError,
    config,
    selectAllItem,
    handleFormSubmit,
  } = useImportStudentsInGroupsServices({
    refetch,
    onClose,
  })

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          {t('actions.import')} {t('general.students_in_groups')}
        </DrawerTitle>
        <IconButton onClick={onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <DrawerContent headerHeight={142}>
        <ImportNote>
          {t('import_drawers.import_students_with_groups_note')}
        </ImportNote>
        <FormContainer
          onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()}
        >
          <FormGroupWrapper style={FormGroupWrapperStyles}>
            <Toolbar styles={ToolbarStyles}>
              <ToolbarItemsWrapper>
                <ToolbarItem>
                  <Button
                    text={t('actions.download_template')}
                    color="primary"
                    type="small"
                    icon={<GetAppIcon fontSize="small" />}
                    onClick={() =>
                      (window.location.href =
                        configs.IMPORT_STUDENTS_WITH_GROUPS_URL)
                    }
                    background="#06C68F"
                  />
                </ToolbarItem>
                <ToolbarItem>
                  <Button
                    text={t('actions.upload_file')}
                    color="primary"
                    type="small"
                    icon={<PublishIcon fontSize="small" />}
                    component={
                      <StyledInput
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                        value=""
                      />
                    }
                    background="#06C68F"
                  />
                </ToolbarItem>

                <GridMultipleActions
                  selectedItems={selectedStudents.length}
                  actions={toolbarAction}
                />
              </ToolbarItemsWrapper>
            </Toolbar>

            {imported && studentList.find((i: any) => i.error) && (
              <ErrorMessage>{t('errors.import_correct_row')}</ErrorMessage>
            )}

            {atLeastOneSelectedError && (
              <ErrorMessage>
                {t('errors.select_at_least_one_group')}
              </ErrorMessage>
            )}

            <Grid
              data={studentList}
              config={config}
              loading={studentsImportLoading}
              selectAllItem={selectAllItem}
              selected={selectedStudents.length}
              selectedItems={selectedStudents}
              error={
                imported
                  ? {
                      error: 'error',
                      errorColor: 'rgba(255, 0, 0, 0.13)',
                      successColor: 'rgba(0, 128, 0, 0.13)',
                    }
                  : false
              }
            />
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons multiCols={true}>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={onClose}
                background="#E0E1E2"
                textColor="#414141"
              />

              <div />

              <Button
                text={t('actions.import')}
                type="small"
                color="secondary"
                btnType="submit"
                onClick={handleFormSubmit}
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default ImportStudentsInGroupsDrawer
