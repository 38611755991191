import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import CourseList from 'pages/courses/CourseList'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import Drawer from 'components/common/Drawer'
import Loader from 'components/common/Loader'
import { Input } from 'components/common/TextInput'
import { ToolbarItem } from 'components/common/Toolbar'
import { Grid } from 'components/common/Cards/styled-components'
import { useFetchCourses } from 'hooks/course/useCourses'
import FilterDrawer from 'pages/courses/FilterDrawer/FilterDrawer'
import { Wrapper, ToolBarWrapper } from './styled-components'
import { SplashScreen } from 'components/common'
import PaginationV2 from 'components/common/PaginationV2'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { IFilterQuery, IFilterData } from './group.interface'
import { ICourseFilter } from 'interfaces/courses/courses.interface'

const GroupCourses: React.FC<{ groupId: string }> = ({ groupId }) => {
  const defaultFilter = {
    groups: {
      type: UpperCaseFilterableFieldType.NESTED_ARRAY_IN,
      value: [groupId],
      nestedField: 'GROUP_ID',
    },
  }
  const { t } = useTranslation()
  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [filterData, setFilterData] = useState<IFilterData>({
    values: defaultFilter,
    filterOptions: {},
  })
  const [filterQuery, setFilterQuery] = useState<IFilterQuery | {}>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(10)

  const { courses, loading, refetch, error } = useFetchCourses(
    {
      ...defaultFilter,
      ...filterQuery,
      ...filterData.values,
    } as ICourseFilter,
    currentPage,
    perPage,
  )

  const resetFilter = (): void => {
    setFilterQuery({})
    setFilterData({} as IFilterData)
    setFilterIsOpen(false)
    setSearchText('')
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const formValue = {
      name: { type: UpperCaseFilterableFieldType.MATCH, value: searchText },
    }
    setCurrentPage(1)
    setFilterQuery({
      ...filterQuery,
      ...formValue,
      ...filterData.filterOptions,
    })
    refetch({
      currentPage: 1,
      perPage,
      filter: filterQuery,
    })
  }
  const handlePaginationClick = (value: number): void => {
    if (value !== currentPage) {
      setCurrentPage(value)
    }
  }

  if (courses && courses.data.length === 0) {
    return <SplashScreen subTitle={t('general.no_courses')} />
  }

  return (
    <Wrapper>
      <>
        <ToolBarWrapper>
          <ToolbarItem>
            <form onSubmit={onSearchSubmit}>
              <Input
                label={t('general.search_placeholder')}
                type="text"
                size="small"
                value={searchText}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setSearchText(e.target.value)
                }}
                icon={
                  <IconButton onClick={onSearchSubmit}>
                    <SearchRoundedIcon />
                  </IconButton>
                }
              />
            </form>
          </ToolbarItem>
        </ToolBarWrapper>
        <div>
          <Grid className={'card'} style={{ position: 'relative' }}>
            {error && error}
            {loading && <Loader withBackground />}
            {courses && (
              <CourseList
                data={courses.data}
                gridStyle={'card'}
                groupId={groupId}
                canOpen
                isGridView
              />
            )}
          </Grid>
          {courses && courses.data.length > 0 && (
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <PaginationV2
                currentPage={courses ? courses.currentPage : 1}
                totalPages={courses ? courses.totalPages : 0}
                handleChange={handlePaginationClick}
              />
            </div>
          )}
        </div>

        <Drawer
          opened={filterIsOpen}
          toggleDrawer={(): void => {}}
          totalWidth="700px"
        >
          <FilterDrawer
            filterData={filterData}
            setFilterData={setFilterData}
            perPage={perPage}
            refetchCourse={refetch}
            resetFilter={resetFilter}
            onClose={(): void => setFilterIsOpen(false)}
            isCooursePrivacyHidden={true}
            setCurrentPage={setCurrentPage}
            t={t}
          />
        </Drawer>
      </>
    </Wrapper>
  )
}

export default GroupCourses
