import { ApolloError, useQuery } from '@apollo/client'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { GET_GROUP } from 'gql/group/group.query'
import {
  ICompanyCoursesServiceReturn,
  IVariables,
} from './companyCourses.interface'

export const companyCoursesService = ({
  variables,
}: {
  variables: IVariables
}): ICompanyCoursesServiceReturn => {
  const { data, loading, error } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        company: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: variables.filter.companyId.value,
        },
      },
      perPage: 0,
    },
  })

  return {
    groupsData: data || [],
    loading,
    error,
  }
}
