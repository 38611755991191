import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { ExpansionItemProps } from './ExpansionItem.interface'
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
  StyledTypography,
} from './styled-components'

const ExpansionItem = ({ item, index, isOpened }: ExpansionItemProps) => {
  const { t } = useTranslation()

  if (!item.canView) return

  return (
    <StyledExpansionPanel
      className="expansionPanel"
      isOpened={isOpened}
      key={index}
    >
      {item.openInNewTab ? (
        <a href={item.path} target="_blank">
          <StyledExpansionPanelSummary>
            <StyledTypography>
              {item.icon}
              {isOpened && <span>{t(`navigation.${item.title}`)}</span>}
            </StyledTypography>
          </StyledExpansionPanelSummary>
        </a>
      ) : (
        <NavLink to={item.path}>
          <StyledExpansionPanelSummary>
            <StyledTypography>
              {item.icon}
              {isOpened && <span>{t(`navigation.${item.title}`)}</span>}
            </StyledTypography>
          </StyledExpansionPanelSummary>
        </NavLink>
      )}
    </StyledExpansionPanel>
  )
}

export default ExpansionItem
