import React from 'react'

import { t } from 'i18next'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'

import IconButton from '@mui/material/IconButton'
import Grid from 'components/common/GridV2'
import {
  Header,
  LayoutWrapper,
  Wrapper,
  SelectFieldContainer,
} from 'pages/tests/testDetails/studentsLayout/styled-components'
import SyncIcon from '@mui/icons-material/Sync'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import SelectField from 'components/common/SelectField/SelectField'
import { AnalyticsGroupOption } from 'pages/analytics/analytics.interface'
import { Input } from 'components/common/TextInput'
import useStudentsLayout from './useStudentsLayout'
import { IStudentsLayout } from './students.interface'
import TablePagination from 'components/common/Pagination/TablePagination'
import { SplashScreen } from 'components/common'

const StudentsLayout = ({ groups, companyId }: IStudentsLayout) => {
  const {
    handleSearchChange,
    setSelectedGroup,
    selectedGroup,
    searchText,
    groupOptions,
    handleClick,
    users,
    loading,
    currentPage,
    perPage,
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleResetClick,
    config,
  } = useStudentsLayout({
    groups,
    companyId,
  })

  return (
    <LayoutWrapper>
      <Header>
        <Wrapper>
          <MultipleAction>
            <IconButton
              disabled={!selectedGroup && !searchText}
              onClick={(): void => handleResetClick(true)}
            >
              <SyncIcon fontSize="small" />
            </IconButton>
            <MultipleActionTooltip>{t('actions.reset')}</MultipleActionTooltip>
          </MultipleAction>
          <SelectFieldContainer>
            <SelectField
              placeholder={'Select Group'}
              options={groupOptions}
              onChange={(e: AnalyticsGroupOption): void => setSelectedGroup(e)}
              value={selectedGroup}
            />
          </SelectFieldContainer>
          <Input
            label={t('general.search_placeholder')}
            type="text"
            size="small"
            value={searchText}
            onChange={handleSearchChange}
            icon={
              <IconButton>
                <SearchRoundedIcon />
              </IconButton>
            }
            iconInStart
          />
        </Wrapper>
      </Header>
      {users && users.data.length ? (
        <div>
          <Grid
            config={config}
            data={users.data}
            redirectToDetailsHandler={handleClick}
          />
          <TablePagination
            currentPage={currentPage}
            rowsPerPage={perPage}
            count={users.totalCount}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      ) : (
        !loading && <SplashScreen subTitle={t('courses_layout.no_students')} />
      )}
    </LayoutWrapper>
  )
}

export default StudentsLayout
