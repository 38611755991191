import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import { useFetchGroups } from 'hooks/group/useGroups'
import {
  IAddGroupInCourseDrawerProps,
  IGroupsFilterOptions,
  IGroup,
  IGroupData,
  IUseAddGroupInCourseDrawer,
  IGroupsFilterDrawer,
} from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'
import { columnConfig } from 'pages/courses/AddCourseDrawer/groupGridConfig'
import {
  IFilter,
  ISelectedAll,
  ISelectedItem,
} from 'components/common/FilterInterface/filter.interface'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'

const useAddGroupInCourseDrawer = (
  props: IAddGroupInCourseDrawerProps,
): IUseAddGroupInCourseDrawer => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [searchText, setSearchText] = useState('')
  const filter = {
    company: {
      type: LowerCaseFilterableFieldType.EXACT,
      value: props.companyId,
    },
    _id: {
      type: LowerCaseFilterableFieldType.ARRAY_NOT_IN,
      value: props.selectedGroup.map((group: IGroup) => group.groupId),
    },
  }

  const [filterOptions, setFilterOptions] = useState<
    IGroupsFilterOptions | null | IFilter
  >(filter)
  const [filterDrawer, setFilterDrawer] = useState<IGroupsFilterDrawer>({
    filterOptions: filter,
    filterValues: null,
    opened: false,
  })
  const { groups, refetch } = useFetchGroups(
    currentPage,
    perPage,
    filterDrawer?.filterOptions,
  )

  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const [selectAll, setSelectAll] = useState<ISelectedAll>({})

  const handleClick = (): void => {}

  const selectItem = (id: never): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = groups.data.map((n: IGroupData) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const onFormSubmit = (): void => {
    let updatedGroups = []
    if (selectedItem[currentPage]) {
      const selectedGroups = groups.data.filter(
        (i: IGroupData) =>
          selectedItem[currentPage].findIndex((v: string) => i.id === v) !== -1,
      )

      updatedGroups = selectedGroups.map((i: IGroupData) => ({
        groupId: i.id,
        name: i.name,
      }))
    }

    props.selectGroup(updatedGroups)
    props.onClose(updatedGroups)
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const filters = {
      ...filterOptions,
      name: { type: LowerCaseFilterableFieldType.MATCH, value: searchText },
    }
    refetch({
      filter: {
        ...filters,
      },
      currentPage: 1,
      perPage,
    })
    if (filters) setFilterOptions(filters as IFilter)
    setCurrentPage(1)
  }

  const handleCloseDrawer = (): void => {
    if (!selectedItem[currentPage] || selectedItem[currentPage].length === 0) {
      props.onClose()
    } else if (selectedItem[currentPage]) {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          props.onClose()
        },
        onClose: () => {},
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  const config = columnConfig(handleClick, selectItem, t)

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    refetch({
      filter: {
        company: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: props.companyId,
        },
        name: { type: LowerCaseFilterableFieldType.MATCH, value: searchText },
      },
      currentPage,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    props.refetch({
      filter: {
        company: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: props.companyId,
        },
      },
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  // const selectedGroups =
  //   groups &&
  //   groups.data &&
  //   groups.data.filter(
  //     (item: any) =>
  //       props.selectedGroup.findIndex((val: any) => item.id === val.groupId) ===
  //       -1,
  //   )

  const filterData = (filters: IGroupsFilterOptions): void => {
    refetch({
      filter: { ...filter, ...filters },
      currentPage,
      perPage: 0,
    })
    if (groups?.groups?.data) {
      setFilterDrawer({ ...filterDrawer, opened: false })
    }
  }

  return {
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleSearchSubmit,
    handleCloseDrawer,
    onFormSubmit,
    selectAllItem,
    selectedItem,
    config,
    currentPage,
    perPage,
    filter,
    filterData,
    refetch,
    t,
    searchText,
    filterDrawer,
    setFilterDrawer,
    setSearchText,
    setSelectAll,
    groups,
    selectAll,
  }
}

export default useAddGroupInCourseDrawer
