import { useState } from 'react'
import deleteSkillTestVideoService from 'services/modules/deleteSkillTestVideoTemplateService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'

const useDeleteSkillTestVideoTemplateService = (params: any) => {
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { deleteSkillTestVideoTemplateMutation } = deleteSkillTestVideoService(
    params,
  )

  const deleteSkillTestVideoTemplate = async (
    ids: string[],
    onSuccess?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    try {
      const { data, errors } = await deleteSkillTestVideoTemplateMutation({
        variables: {
          ids,
        },
      })
      if (errors) {
        message = errors[0].message
        if (message === 'User already registered') {
          message = message
          setError(message)
          return false
        }
      } else {
        message = 'Skill Test Video has been deleted'
        variant = 'success'

        if (onSuccess) {
          onSuccess()
        }
      }
      setSnackbar({ message, variant })
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  return {
    deleteSkillTestVideoTemplate,
    deleteSkillTestVideoTemplateLoading: loading,
    error,
  }
}

export default useDeleteSkillTestVideoTemplateService
