import styled from 'styled-components'

export const Container = styled.article<{ isFromUser?: boolean }>`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d6dae0;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;

  & > * {
    font-family: 'Open Sans', sans-serif;
  }

  figure {
    position: relative;
    &:after {
      content: 'View Analytics';
      position: absolute;
      top: 0;
      opacity: 0;
      color: #06c68f;
      left: 0;
      font-size: 24px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.9);
      width: 100%;
      height: 100%;
      transition: opacity 0.3s ease;
      z-index: 10;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 3px #d6dae0;

    img {
      transform: scale(1.2);
    }

    h3 {
      color: #06c68f;
    }
  }

  ${({ isFromUser }) =>
    isFromUser &&
    `
  figure {
    position: relative;
    &:after {
      content: 'View Analytics';
      position: absolute;
      top: 0;
      opacity: 0;
      color: #06c68f;
      left: 0;
      font-size: 24px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.9);
      width: 100%;
      height: 100%;
      transition: opacity 0.3s ease;
      z-index: 10;
    }
  }

  &:hover {
    figure {
      &:after {
        opacity: 1;
      }
    }
  }
  `}
`
export const GridItemContainer = styled.div`
  display: flex;
`

export const Attachment = styled.img`
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    transition: transform 0.3s ease;
  }
`

export const GridItemContent = styled.section`
  position: relative;
  z-index: 100;
  font-size: 16px;
  color: #333;
  padding: 16px;
`

export const GridItemContentHeaderStyle = styled.header`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0;
`

export const MoreButton = styled.div`
  display: flex;
  position: relative;

  &:hover {
    cursor: pointer;
    background: #f2f2f2;
    opacity: 0.8;
    border-radius: 30px;
    height: 40px;
    transition: all 0.5s ease;
  }
`

export const PaddedContainer = styled.div`
  margin-top: auto;
  padding: 0px 10px 10px 10px;
`

export const PublishedIndicator = styled.div<{ published: boolean }>`
  background-color: ${({ published }) => (published ? '#06c68f' : '#ea382a')};
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  height: 27px;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  top: 7px;
  padding: 6px 8px;
`

export const About = styled.div`
  text-transform: capitalize;
  font-size: 14px;
  background: #f2f7fb;
  font-weight: 400;
  padding: 5px;
  color: #55bcd3;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  justify-content: space-between;
  margin: 5px;
`
export const Row = styled.div`
  width: 100%;
  display: flex;
`
