import { useMutation } from '@apollo/client'
import { CHANGE_LESSONS_ORDER } from 'gql/lesson/lesson.query'
import {
  ChangeOrderVariables,
  IChangeOrder,
} from './changeLessonOrder.interface'

export const changeLessonsOrderService = (): IChangeOrder => {
  const [changeOrder] = useMutation<unknown, ChangeOrderVariables>(
    CHANGE_LESSONS_ORDER,
  )

  return { changeOrder }
}
