import styled from 'styled-components'

// gridConfig

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  &.approved {
    color: green;

    div {
      background: green;
    }
  }

  &.declined {
    color: red;

    div {
      background: red;
    }
  }

  &.pending {
    color: rgb(207, 207, 9);

    div {
      background: rgb(207, 207, 9);
    }
  }

  &.unassigned {
    color: rgba(0, 0, 0, 0.5);

    div {
      background: rgba(0, 0, 0, 0.5);
    }
  }
`

export const CriteriaButton = styled.button`
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 12px;
  cursor: pointer;
`

export const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`

export const GridITemWrapper = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  & > div {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  & > span {
    width: calc(100% - 60px);
  }
`
export const SentForReview = styled.div`
  min-height: 60px;
  display: inline-block;
  padding: 10px 0px;
  & > div {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
  }
  & > span {
    display: flex;
  }
`

export const StyledTableContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
`

const PREFIX = 'SkillTestVideos'

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
}

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.paper}`]: {
    width: '100%',
    marginBottom: '20px',
    padding: '0px 15px',
  },

  [`& .${classes.table}`]: {
    minWidth: 750,
    position: 'relative',
  },

  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))
