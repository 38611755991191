import { useState, useCallback, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { GET_ALL_QUIZ } from 'gql/quiz/quiz.query'
import { useDeleteQuestion } from 'hooks/questions/useDeleteQuestion'
import { useFetchQuestions, PER_PAGE } from 'hooks/questions/useQuestions'
import { IQuestion } from 'interfaces/questions'
import { useTranslation } from 'react-i18next'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { DrawerEventEmitter } from 'helpers/drawer'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import {
  actionConfig,
  columnConfig,
  questionActionsConfig,
} from 'pages/questions/gridConfig'
import {
  FilterData,
  FilterOptions,
  SelectAllMap,
  SelectedItemsMap,
  Topic,
  UseQuestionsListParams,
} from 'pages/modules/ModuleDetail/QuestionList/questionList.interface'
import { IQuiz } from 'pages/quizzes/quiz.interface'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'

const useQuestionsGrid = ({
  currentModule,
  manualCompanyId,
  topics,
  refetchModule,
  canModify,
  handleTabChange,
  setOpenCreateQuizDrawer,
}: UseQuestionsListParams) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const { questionsType = '', actionId = '' } = currentModule
    ? { questionsType: 'module', actionId: currentModule.moduleId }
    : {}

  const { questions, loading, refetch } = useFetchQuestions(
    questionsType,
    actionId,
  )

  let moduleId: string | null = null
  let courseIds: string[] = []
  const topicIds: string[] = []
  const lessonIds: string[] = []

  if (topics) {
    topics.forEach((topic: Topic) => {
      topicIds.push(topic.id)
      topic.lessons.forEach((lesson: { id: string }) => {
        lessonIds.push(lesson.id)
      })
    })
  }

  if (currentModule) {
    if (currentModule.moduleId) moduleId = currentModule.moduleId
    if (currentModule.courses)
      courseIds = currentModule.courses.map((course) => course.courseId)
  }

  const ids = [moduleId, ...topicIds, ...lessonIds, ...courseIds]

  const { data: quizList, refetch: refetchQuizzes } = useQuery(GET_ALL_QUIZ, {
    variables: {
      query: JSON.stringify({ actionId: { $in: ids } }),
    },
  })

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const navigate = useNavigate()
  const { deleteQuestion } = useDeleteQuestion()
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<SelectedItemsMap>([])
  const [selectAll, setSelectAll] = useState<SelectAllMap>({})
  const [LinkQuestionDrawerOpened, setLinkQuestionDrawerOpened] =
    useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<FilterData>({})
  const [isFiltered, setIsFiltered] = useState(false)
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({})
  const [filtered, setFiltered] = useState<boolean>(false)

  const { fireSwal } = useSwal()

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] =
        questions?.data.map((question: IQuestion) => question.id) || []
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const updatedFilterOptions = {
      ...filterOptions,
      question: {
        type: LowerCaseFilterableFieldType.MATCH,
        value: searchValue,
      },
    }
    refetch({
      type: questionsType,
      id: actionId,
      filter: updatedFilterOptions,
      currentPage: 1,
    })
    setFilterOptions(updatedFilterOptions)
    setFiltered(true)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target

    setSearchValue(value)
    if (e.target.value === '') {
      const { question, ...updatedFilterOptions } = filterOptions
      refetch({
        type: questionsType,
        id: actionId,
        filter: updatedFilterOptions,
      })
      setFilterOptions(updatedFilterOptions)
    }
  }

  const handleCreateQuestionClick = (moduleId?: string, name?: string) => {
    DrawerEventEmitter.emit('openDrawer', 'createQuestion', true, {
      data: {
        moduleId,
        name,
        manualCompanyId,
        refetch: () => {
          if (refetchModule) {
            refetchModule()
          }
          setSelectAll({})
          setSelectedItem({})
          refetch()
        },
      },
    })
  }

  const handleLinkQuestionToModuleClick = () => {
    setLinkQuestionDrawerOpened(true)
  }

  const handleViewClick = (questionId: string): void => {
    navigate(`/questions/${questionId}`)
  }

  const handleEditClick = (id: string): void => {
    const title: string = currentModule
      ? t('popups.edit_question_in_module')
      : t('popups.edit_question_gerenal')
    fireSwal({
      title,
      confirmText: t('popups.confirm_edit'),
      onConfirm: () => {
        DrawerEventEmitter.emit('openDrawer', 'editQuestion', true, {
          id,
          data: {
            moduleId: currentModule ? currentModule.moduleId : '',
            name: currentModule ? currentModule.name : '',
            manualCompanyId,
          },
        })
      },
    })
  }

  const handleDuplicateClick = (id: string): void => {
    fireSwal({
      title: t('popups.edit_duplicate_question'),
      confirmText: t('popups.confirm_edit_duplicate'),
      onConfirm: () => {
        DrawerEventEmitter.emit('openDrawer', 'editQuestion', true, {
          id,
          data: {
            moduleId: currentModule ? currentModule.moduleId : '',
            name: currentModule ? currentModule.name : '',
            duplicate: true,
            manualCompanyId,
            refetch: () => refetch(),
          },
        })
      },
    })
  }

  const fireConfirmDeleteQuestionsPopup = (
    ids: string[],
    edited: boolean,
    isMultiple: boolean,
  ): void => {
    const moduleId: string = currentModule ? currentModule.moduleId : ''
    let mainText = ''
    if (!moduleId) {
      mainText = isMultiple
        ? t('popups.delete_question_many')
        : t('popups.delete_question_single')
    } else {
      mainText = edited
        ? t('popups.delete_question_edited')
        : t('popups.delete_question_many_in_module')
    }

    fireSwal({
      title: `${mainText}`,
      confirmText: t('popups.confirm_remove'),
      onConfirm: () => {
        deleteQuestion(moduleId, ids, () => {
          if (refetchModule) {
            refetchModule()
          }
          refetch()
        })
        setSelectedItem([])
      },
    })
  }

  const fireMultipleQuestionsIsUsedInQuizPopup = (): void => {
    fireSwal({
      title: `${t('popups.questions_is_used_in_quizzes')} ${t(
        'general.questions',
      )}`,
      confirmText: t('actions.close'),
      onConfirm: () => {},
    })
  }
  const fireQuestionIsUsedInQuizPopup = (
    quizzes: IQuiz[],
    ids: string[],
  ): void => {
    const moduleId: string = currentModule ? currentModule.moduleId : ''
    const quizNames = quizzes
      .map((quiz: IQuiz) => `• ${quiz.parentName} - ${quiz.name}`)
      .join('\n')
    const alertText =
      quizNames.length > 1
        ? `${t('popups.question_is_used_in_quizzes')} \n${quizNames}.\n`
        : `${t('popups.question_is_used_in_quiz')} \n${quizNames}.\n`
    fireSwal({
      title: `${alertText} ${t('popups.confirm_deletion')}`,
      confirmText: t('popups.confirm_remove'),
      customClass: {
        title: 'fireQuestionIsUsedInQuizPopup',
      },
      onConfirm: () => {
        deleteQuestion(moduleId, ids, () => {
          if (refetchModule) {
            refetchModule()
          }
          refetch()
        })
        setSelectedItem([])
      },
      width: `50%`,
    })
  }

  const handleDeleteClick = (ids: string[], edited: boolean): void => {
    refetchQuizzes()

    const isMultiple = ids.length > 1

    const quizzesWhereQuestionIsUsed = quizList?.getAllQuizzes.filter(
      (quiz: IQuiz) =>
        quiz.IQTestType !== 'final' &&
        quiz.questions.some((id: string) => ids.includes(id)),
    )

    if (quizzesWhereQuestionIsUsed.length) {
      isMultiple
        ? fireMultipleQuestionsIsUsedInQuizPopup()
        : fireQuestionIsUsedInQuizPopup(quizzesWhereQuestionIsUsed, ids)
    } else {
      fireConfirmDeleteQuestionsPopup(ids, edited, isMultiple)
    }
  }

  // const questionActions = questionActionsConfig(
  //   handleCreateQuestionClick,
  //   handleLinkQuestionToModuleClick,
  //   t,
  // )
  const actionHandler = (
    event: string,
    field: { id: string; edited: boolean },
  ) => {
    switch (event) {
      case 'view':
        return handleViewClick(field.id)
      case 'edit':
        return handleEditClick(field.id)
      case 'duplicate':
        return handleDuplicateClick(field.id)
      case 'delete':
        return handleDeleteClick([field.id], field.edited)
      case 'pdf':
        return () => {}
      default:
    }
  }

  const handleCloseDrawer = (): void => {
    const { text, confirm } = state.closeDrawer
    if (!text && !confirm) {
      setLinkQuestionDrawerOpened(false)
    } else {
      fireSwal({
        title: text,
        text: t('popups.sure'),
        onConfirm: () => {
          setLinkQuestionDrawerOpened(false)
          dispatch({
            type: TOGGLE_CLOSE_DRAWER,
            payload: {
              text: '',
              confirm: false,
            },
          })
        },
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  const handlePaginationClick = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    if (value !== currentPage) {
      refetch({
        type: questionsType,
        id: actionId,
        filter: filterOptions,
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  const handleFilterClick = (filter: FilterOptions): void => {
    setSelectedItem({})
    setSelectAll({})
    const updatedFilterOptions = {
      ...filterOptions,
      ...filter,
    }
    refetch({
      filter: updatedFilterOptions,
      currentPage: 1,
    })
    setFilterOptions(updatedFilterOptions)
  }

  const handleCreateQuizClick = (e: any) => {
    if (handleTabChange) {
      handleTabChange(e, 2)
    }
    if (setOpenCreateQuizDrawer) {
      setOpenCreateQuizDrawer(true)
    }
  }

  const actions = actionConfig(
    state.userPermission,
    currentModule,
    actionHandler,
    canModify,
    t,
  )
  const config = columnConfig(selectItem, t)

  const redirectToQuestion = (question: IQuestion): void => {
    navigate(`/questions/${question.id}`)
  }

  const questionActions = questionActionsConfig(
    handleCreateQuestionClick,
    handleLinkQuestionToModuleClick,
    t,
  )

  return {
    questions,
    loading,
    currentPage,
    perPage,
    searchValue,
    selectedItem,
    selectAll,
    setSearchValue,
    setSelectedItem,
    setSelectAll,
    handleViewClick,
    handleEditClick,
    handleDuplicateClick,
    handleDeleteClick,
    handleSearchSubmit,
    handleSearchChange,
    handlePaginationClick,
    handleFilterClick,
    handleCloseDrawer,
    LinkQuestionDrawerOpened,
    setLinkQuestionDrawerOpened,
    filterDrawerOpened,
    setFilterDrawerOpened,
    filterData,
    setFilterData,
    isFiltered,
    setIsFiltered,
    filterOptions,
    setFilterOptions,
    filtered,
    setFiltered,
    actions,
    config,
    redirectToQuestion,
    state,
    t,
    handleCreateQuestionClick,
    selectAllItem,
    selectItem,
    cards: questions?.data || [],
    refetch,
    setCurrentPage,
    questionActions,
    handleCreateQuizClick,
  }
}
export default useQuestionsGrid
