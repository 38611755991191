import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { getToken } from 'helpers/localStorage'
import { useUserValue } from 'context/UserContext'
import { checkRole } from 'helpers/checkRole'

interface PrivateRouteProps {
  component: React.ComponentType<any>
  exact?: Boolean
  protectedFromConsultants?: boolean
  roleAccess?: string[]
  protectedWithCompanyId?: boolean
  path?: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  roleAccess = [],
  exact = false,
  protectedWithCompanyId = false,
  protectedFromConsultants = false,
  ...rest
}) => {
  const [state] = useUserValue()
  const token = getToken()
  const isAuthenticated = token ? true : false
  const { id: paramId } = useParams()

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  if (roleAccess.length === 0) {
    return <Component {...rest} />
  }

  if (state.currentUser && state.currentUser.role) {
    const currentUserCompanyId =
      state.currentUser &&
      state.currentUser.companyId &&
      state.currentUser.companyId.id

    const role = checkRole(state.currentUser.role, state.currentUser.companyId)

    if (roleAccess.includes(role)) {
      if (!protectedWithCompanyId || !currentUserCompanyId) {
        return <Component {...rest} />
      }

      if (currentUserCompanyId === paramId) {
        return <Component {...rest} />
      }

      return <Navigate to="/" />
    } else if (currentUserCompanyId) {
      return <Navigate to={`/Companies/${currentUserCompanyId}`} />
    }
  }

  return <Navigate to="/" />
}

export default PrivateRoute
