import React, { ChangeEvent } from 'react'
import {
  DrawerHeader,
  DrawerTitle,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { Button } from 'components/common/Button'
import GeneralInformation from './GeneralInformation'
import { useAddCompany } from 'hooks/companies/useAddCompany'
import { ImageCropper, Modal } from 'components/common'

const AddCompanyDrawer = (): JSX.Element => {
  const {
    removeAvatarAndCloseDrawer,
    handleSubmit,
    handleValueChange,
    values,
    setFieldValue,
    errors,
    generalError,
    t,
    handleImageChange,
    deleteImage,
    imageLoading,
    touched,
    cropperOpen,
    handleCropSave,
    file,
    imageType,
    cropperModalToggle,
    loading,
  } = useAddCompany()
  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('companies_layout.create_company')}</DrawerTitle>
        <IconButton onClick={removeAvatarAndCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <FormContainer
        onSubmit={(e: ChangeEvent<HTMLInputElement>): void =>
          e.preventDefault()
        }
        headerHeight="65px"
      >
        <FormGroupWrapper>
          <GeneralInformation
            values={values}
            errors={errors}
            generalError={generalError}
            touched={touched}
            handleChange={handleValueChange}
            setFieldValue={setFieldValue}
            handleImageChange={handleImageChange}
            deleteImage={deleteImage}
            imageLoading={imageLoading}
            t={t}
          />
        </FormGroupWrapper>
        <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
          <ImageCropper
            file={file}
            hideCropper={cropperModalToggle}
            getCroppedFile={(_: unknown, file: File): void =>
              handleCropSave(imageType, file)
            }
          />
        </Modal>
        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.cancel')}
              type="small"
              onClick={removeAvatarAndCloseDrawer}
              background="#E0E1E2"
              textColor="#414141"
            />
            <Button
              text={t('actions.save')}
              type="small"
              color="secondary"
              isDisabled={loading}
              onClick={handleSubmit}
              background="#06C68F"
            />
          </FormButtons>
        </FormFooter>
      </FormContainer>
    </>
  )
}

export default AddCompanyDrawer
