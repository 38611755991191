import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { columnConfig } from './columnConfig'
import { useFetchStudentsByTestAssessmentId } from 'hooks/users/useFetchStudentsByTestAssessmentId'
import _ from 'lodash'
import { AnalyticsGroupOption } from 'pages/analytics/analytics.interface'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { IStudentsLayout } from './students.interface'
import { useNavigate } from 'react-router-dom'
import { User } from 'interfaces/users'
import { useQuery } from '@apollo/client'
import { GET_GROUP_OPTIONS_BY_TEST_ASSESSMENT_ID } from 'gql/group/group.query'
import { Group } from 'interfaces/groups'
import { TFunction } from 'interfaces/TFunction'

const useStudentsLayout = ({ testAssessmentId }: IStudentsLayout) => {
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState<string>('')
  const [groupOptions, setGroupOptions] = useState<AnalyticsGroupOption[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [selectedGroup, setSelectedGroup] =
    useState<AnalyticsGroupOption | null>(null)
  const [filterOptions, setFilterOptions] = useState({})

  const { users, loading, refetch } = useFetchStudentsByTestAssessmentId(
    testAssessmentId,
    {},
    currentPage,
    perPage,
  )

  const handleResetClick = () => {
    setSelectedGroup(null)
    setSearchText('')
    setFilterOptions({})
    refetch({
      filter: {},
      currentPage: 1,
      perPage,
      testAssessmentId,
    })
  }

  const onSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const searchQuery = {
      search: {
        type: LowerCaseFilterableFieldType.SEARCH,
        value: searchText,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }

    const updatedFilterOptions = {
      ...filterOptions,
      ...searchQuery,
    }
    setFilterOptions(updatedFilterOptions)
    refetch({
      filter: updatedFilterOptions,
      currentPage: 1,
      perPage,
    })
  }

  const { data: groups } = useQuery(GET_GROUP_OPTIONS_BY_TEST_ASSESSMENT_ID, {
    variables: {
      testAssessmentId,
      filter: {},
      currentPage: 1,
      perPage: 0,
    },
  })

  useEffect(() => {
    let currGroupOptions = []
    if (groups && groups.getGroupsByTestAssessmentId) {
      currGroupOptions =
        groups.getGroupsByTestAssessmentId.data?.map((group: Group) => ({
          label: group.name,
          value: group.id,
        })) || []
    }
    setGroupOptions(currGroupOptions)
  }, [groups])

  const handleClick = (student: User) => {
    navigate(`/profile/${student.id}`)
  }

  useEffect(() => {
    const groupFilter = {
      group: selectedGroup?.value
        ? {
            type: 'nestedArrayIn',
            value: selectedGroup?.value,
            nestedField: 'groupId',
          }
        : null,
    }
    const updatedFilterOptions = {
      ...filterOptions,
      ...groupFilter,
    }
    setFilterOptions(updatedFilterOptions)
    refetch({
      filter: updatedFilterOptions,
      currentPage,
      perPage,
    })
  }, [selectedGroup])

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
    refetch({
      filter: filterOptions,
      currentPage,
      perPage: newPerPage,
    })
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const newCurrentPage = newPage + 1
    setCurrentPage(newCurrentPage)
    refetch({
      filter: filterOptions,
      currentPage: newCurrentPage,
      perPage,
    })
  }

  const config = columnConfig(
    t as unknown as TFunction<'translation', undefined>,
  )

  return {
    onSearchSubmit,
    setSelectedGroup,
    setSearchText,
    handleClick,
    selectedGroup,
    searchText,
    currentPage,
    handleResetClick,
    perPage,
    handlePaginationClick,
    handleChangeRowsPerPage,
    groupOptions,
    users,
    loading,
    config,
  }
}

export default useStudentsLayout
