import React from 'react'
import {
  Label,
  ActivityHeader,
  UserDetailsContainer,
  Tracker,
  LoaderContainer,
  ActivitiesContainer,
  ActivityWrapper,
  UserName,
  LeaderboardContainer,
  LeaderboardHeader,
  LeaderboardItem,
} from './styled-components'
import { useParams } from 'react-router-dom'
import { ReactComponent as CourseLevelSvg } from 'pages/courses/CourseDetail/Svg/CourseLevel.svg'
import { GET_USER_ACTIVITIES } from 'gql/activity/activity.query'
import { useQuery } from '@apollo/client'
import TimeLine from './Timeline'
import MyLoader from 'loading/loading'
import Breadcrumb from 'components/common/Breadcrumb'
import { EditableAvatar } from 'components/common/EditableAvatar/EditableAvatar'
import { useUpdateProfileAvatar } from 'hooks/users/useEditProfileService'

const ActivityTracker = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useQuery(GET_USER_ACTIVITIES, {
    variables: {
      userId: id,
    },
  })
  const userActivities = (data && data.getUserActivities) || []
  const user = userActivities && userActivities.user

  const breadCrumbData = [
    {
      label: `${user ? user.firstName + "'s Profile" : 'User Profile'}`,
      link: `/profile/${id}`,
    },
    {
      label: 'Activity Tracker',
      link: '',
    },
  ]

  const { updateAvatar, hasResponseReceived } = useUpdateProfileAvatar()

  const finishCrop = (
    action: any,
    crudFunction: (isFinished: boolean) => void,
    cropper: any,
  ) => {
    let cropdFile = null
    if (action === 'update') {
      cropdFile = cropper.current.getCroppedCanvas().toDataURL()
    }
    if (id) updateAvatar(cropdFile, id, action, crudFunction)
  }

  const renderIndicator = (indicator: string) => {
    switch (indicator) {
      case 'PROGRESS':
        return <span className="progress">Progress ↑</span>
      case 'STABLE':
        return <span className="stable">Stable - </span>
      case 'REGRESS':
        return <span className="regress">Regress ↓</span>
      default:
        return <span className="stable">Stable - </span>
    }
  }

  if (loading) {
    return (
      <LoaderContainer>
        <MyLoader speed={0.8} />
      </LoaderContainer>
    )
  }

  return (
    <>
      <Breadcrumb data={breadCrumbData} backBtn={false} />
      <ActivitiesContainer>
        <UserDetailsContainer>
          <EditableAvatar
            img={user.avatar}
            finishCrop={finishCrop}
            hasResponseReceived={hasResponseReceived}
          />
          <UserName>
            {user.firstName} {user.lastName}
          </UserName>
          <LeaderboardContainer>
            <LeaderboardHeader>
              Leaderboard {<CourseLevelSvg />}
            </LeaderboardHeader>
            <LeaderboardItem>
              <span>Position in Company Leaderboard:</span>
              <span>{user.leaderboard.companyPosition}</span>
            </LeaderboardItem>
            <LeaderboardItem>
              <span>Position in Global Leaderboard:</span>
              <span>{user.leaderboard.globalPosition}</span>
            </LeaderboardItem>
            <LeaderboardItem>
              <span>Current Leaderboard Status:</span>
              {renderIndicator(user.leaderboard.indicator)}
            </LeaderboardItem>
          </LeaderboardContainer>
        </UserDetailsContainer>
        <ActivityWrapper>
          <ActivityHeader>
            <Label>Activity Tracker</Label>
          </ActivityHeader>
          <Tracker>
            <TimeLine list={userActivities.activity} />
          </Tracker>
        </ActivityWrapper>
      </ActivitiesContainer>
    </>
  )
}
export default ActivityTracker
