import React from 'react'
import Grid from 'components/common/Grid'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import TablePagination from 'components/common/Pagination/TablePagination'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import IconButton from '@mui/material/IconButton'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import Drawer from 'components/common/Drawer'
import { IAddGroupInCourseDrawerProps } from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'
import useAddGroupInCourseDrawer from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/useAddGroupInCourseDrawer'

const AddGroupInCourseDrawer = (
  props: IAddGroupInCourseDrawerProps,
): React.JSX.Element => {
  const {
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleSearchSubmit,
    handleCloseDrawer,
    onFormSubmit,
    selectAllItem,
    selectedItem,
    config,
    currentPage,
    perPage,
    filter,
    refetch,
    t,
    searchText,
    filterDrawer,
    setFilterDrawer,
    setSearchText,
    setSelectAll,
    groups,
    selectAll,
  } = useAddGroupInCourseDrawer(props)

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          {t('actions.add')} {t('general.groups')}
        </DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper padding={20}>
            <Toolbar styles={{ justifyContent: 'space-between' }}>
              <div />
              <ToolbarItem>
                <form onSubmit={handleSearchSubmit}>
                  <Input
                    label={t('general.search_placeholder')}
                    type="text"
                    size="small"
                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      setSearchText(e.target.value)
                    }
                    icon={
                      <IconButton>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                  />
                </form>
              </ToolbarItem>
            </Toolbar>

            {groups && groups.data && (
              <div>
                <Grid
                  title={t('general.groups')}
                  data={groups.data}
                  config={config}
                  selectAllItem={selectAllItem}
                  selected={
                    selectedItem[currentPage]
                      ? selectedItem[currentPage].length
                      : 0
                  }
                  selectedItems={selectedItem[currentPage] || []}
                  onFiltersClick={(): void => {
                    setFilterDrawer({
                      ...filterDrawer,
                      opened: !filterDrawer.opened,
                    })
                    setSelectAll({
                      ...selectAll,
                      [currentPage]: false,
                    })
                  }}
                  resetVisible={!!searchText || !!filterDrawer?.filterValues}
                  resetFilters={(): void => {
                    setFilterDrawer({
                      filterOptions: filterDrawer.filterOptions,
                      filterValues: null,
                      opened: false,
                    })
                    setSelectAll({})
                    setSearchText('')
                    refetch({
                      filter: { ...filter },
                      perPage: 0,
                    })
                  }}
                />
                {groups.totalCount > 0 && (
                  <TablePagination
                    currentPage={groups ? currentPage : 0}
                    rowsPerPage={perPage}
                    count={groups ? groups.totalCount : 0}
                    handleChangePage={handlePaginationClick}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </div>
            )}

            <Drawer
              opened={filterDrawer.opened}
              toggleDrawer={(): void => {}}
              totalWidth="600px"
            >
              <FilterDrawer
                closeDrawer={(): void =>
                  setFilterDrawer({ ...filterDrawer, opened: false })
                }
                setFilterOptions={setFilterDrawer}
                refetchGroup={refetch}
                setCurrentPage={(): void => {}}
                defaultFilter={filter}
                companyId={props.companyId}
                useCompanyId={true}
              />
            </Drawer>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.close')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />

              <Button
                text={t('actions.add')}
                type="small"
                color="secondary"
                onClick={onFormSubmit}
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddGroupInCourseDrawer
