import { useState } from 'react'
import editFlashCardService from 'services/modules/editFlashCardService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useEditFlashCardService = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { editFlashCardMutation } = editFlashCardService(params)

  const editFlashCard = async (id: string, input: any, onSuccess: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await editFlashCardMutation({
      variables: {
        id,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return false
      }
      variant = 'error'
    } else {
      console.log('data', data)
      onSuccess()
      message = `${t('general.flash_card')} ${t('messages.edited')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    editFlashCard,
    editFlashCardLoading: loading,
    error,
  }
}

export default useEditFlashCardService
