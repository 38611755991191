import { useQuery } from '@apollo/client'
import { GET_LESSONS } from 'gql/lesson/lesson.query'
import { IGetLessonsByTopicIdServiceReturn } from './getLessonByTopicId.interface'

export const getLessonsByTopicIdService = (
  topicId?: string,
): IGetLessonsByTopicIdServiceReturn => {
  const { data, loading } = useQuery(GET_LESSONS, {
    variables: {
      topicId,
    },
  })

  return {
    lessons: data && data.getLessons,
    loading,
  }
}
