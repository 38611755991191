import React, { memo } from 'react'

import CustomInput from 'components/common/CustomInput'
import UIBallLoading from 'components/common/UIBallLoading'
import useAIAssistantChat from 'hooks/assistant/useAIAssistantChat'

import {
  StyledInnerWrapper,
  StyledPlaceholder,
  StyledResponseText,
  StyledWrapper,
} from './AIAssistantChat.styles'

const AIAssistantChat = (): React.JSX.Element => {
  const {
    response,
    responseLoading,
    message,
    messagesLoading,
    setMessage,
    messages,
    userMessageStyles,
    assistantMessageStyles,
  } = useAIAssistantChat()

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        {messagesLoading ? (
          <UIBallLoading loaderName="grid" />
        ) : (
          messages
            .slice(0, response ? messages?.length - 1 : messages?.length)
            .map(({ _id, role, content }) => (
              <StyledResponseText
                key={_id}
                style={
                  role === 'user' ? userMessageStyles : assistantMessageStyles
                }
              >
                {content}
              </StyledResponseText>
            ))
        )}
        {response && (
          <StyledResponseText style={assistantMessageStyles}>
            {response}
          </StyledResponseText>
        )}
        {responseLoading && <UIBallLoading loaderName="grid" />}
        {!responseLoading && !response && (
          <StyledPlaceholder>
            <p>Type a prompt to start the conversation.</p>
            <p>Press Shift + Enter to add a new line.</p>
            <p>Press Enter to send the prompt.</p>
          </StyledPlaceholder>
        )}
        <br />
        <CustomInput
          value={message}
          onChange={(e: any): void => setMessage(e.target.value)}
          placeholder="Type your prompt..."
          rows={4}
          type="textarea"
          customStyles={{ width: '1200px', maxWidth: '100%' }}
        />
      </StyledInnerWrapper>
    </StyledWrapper>
  )
}

export default memo(AIAssistantChat)
