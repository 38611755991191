import React, { ReactElement } from 'react'
import { IBookItemExtended } from 'services/books/bookServices.interface'
import {
  ImageCheckboxContainer,
  KebabMenuContainer,
  PositionedCheckBox,
  StyledNoImage,
  Wrapper,
} from './styled-components'
import GridActionMenu from 'components/common/GridV2/GridActionMenu/GridActionMenu'
import { actionConfig } from './actionConfig'
import { useTranslation } from 'react-i18next'
import useBookDetails from 'hooks/books/useBookDetails/useBookDetails'
import { ReactComponent as BookSVG } from 'assets/book.svg'
import CheckBox from 'components/common/Checkbox'

const BookItem = ({
  book,
  selected = false,
  onSelect,
  showCheckbox = false,
  enableActions = false,
  enableGroupActions = false,
  groupId,
}: IBookItemExtended): ReactElement => {
  const { t } = useTranslation()
  const { id, title, coverImage, companyId, contentDocument } = book
  const { handleEditBook, handleDeleteBook, handleDeleteBookFromGroup } =
    useBookDetails(companyId, groupId)

  const actions = actionConfig(
    handleEditBook,
    handleDeleteBook,
    handleDeleteBookFromGroup,
    t,
    enableGroupActions,
  )

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    e.stopPropagation()
    onSelect && onSelect(id)
  }

  return (
    <Wrapper>
      {enableActions && (
        <KebabMenuContainer className="kebab-menu">
          <GridActionMenu actionConfig={actions} row={book} />
        </KebabMenuContainer>
      )}
      {enableGroupActions && (
        <KebabMenuContainer className="kebab-menu">
          <GridActionMenu actionConfig={actions} row={book} />
        </KebabMenuContainer>
      )}
      <ImageCheckboxContainer>
        {showCheckbox && (
          <PositionedCheckBox>
            <CheckBox onChange={handleCheckboxChange} checked={selected} />
          </PositionedCheckBox>
        )}
        {coverImage.link ? (
          <img src={coverImage.link} alt="book cover" />
        ) : (
          <StyledNoImage>
            <BookSVG />
          </StyledNoImage>
        )}
      </ImageCheckboxContainer>
      <a href={contentDocument?.link} target="_blank">
        <h3>{title}</h3>
      </a>
    </Wrapper>
  )
}

export default BookItem
