import { useMutation } from '@apollo/client'
import { ADD_USER } from 'gql/users.query'

const addUserService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addUserMutation, { loading, error }] = useMutation(ADD_USER, {
    // update(cache, { data: { addUser: document } }) {
    //   updateCache({
    //     objectName: "usersByRole",
    //     query: GET_USERS,
    //     cache,
    //     document,
    //     action: "add",
    //     params
    //   });
    // }
  })

  return {
    addUserMutation,
    loading,
    error,
  }
}

export default addUserService
