import React, { useCallback } from 'react'
import {
  Step,
  StepContainer,
  StepIcon,
  StepLabel,
  Connector,
} from './styled-components'

interface Props {
  activeStep: number
  accentColor?: string
  setStep: React.Dispatch<React.SetStateAction<number>>
  completedSteps: number[]
  aligment?: 'vertical' | 'horizontal'
  tileSize?: 'small' | 'medium' | 'large'
  steps: {
    label: string
    icon: React.ReactElement
  }[]
}

const CustomStepper = ({
  activeStep,
  setStep,
  steps,
  aligment = 'horizontal',
  completedSteps,
  tileSize = 'medium',
  accentColor = '#06C68F',
}: Props) => {
  const onStepClick = useCallback(
    (index: number) => {
      if (completedSteps?.indexOf(0) > -1) {
        if (setStep) setStep(index)
      }
    },
    [completedSteps, activeStep],
  )

  return (
    <StepContainer aligment={aligment}>
      {steps.map((step, index) => {
        const hasCursor = completedSteps.includes(0)
        return (
          <Step
            key={index}
            onClick={() => onStepClick(index)}
            stepCount={steps.length}
          >
            <StepIcon
              accentColor={accentColor}
              tileSize={tileSize}
              active={activeStep === index}
              hasCursor={hasCursor}
              completed={
                completedSteps?.indexOf(index) > -1 &&
                completedSteps?.indexOf(index) < activeStep
              }
            >
              {step.icon}
            </StepIcon>
            <StepLabel active={activeStep === index}>{step.label}</StepLabel>
          </Step>
        )
      })}
      <Connector
        activeStep={activeStep}
        stepCount={steps.length}
        accentColor={accentColor}
      />
    </StepContainer>
  )
}

export default CustomStepper
