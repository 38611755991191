import {
  ApolloQueryResult,
  OperationVariables,
  QueryResult,
} from '@apollo/client'
import { IAttachment } from 'interfaces/common'
import { Dispatch, ReactNode, SetStateAction } from 'react'

export interface IFetchMoreVariables {
  searchValue?: string
  currentPage?: number
  perPage?: number
}
export interface IBookItem {
  setIsSearchType: Dispatch<SetStateAction<boolean>>
  book: IBook
}

export interface IBook {
  _id: string
  id: string
  title: string
  description: string
  coverImage: IAttachment
  contentDocument: IAttachment
  companyId: string
  groupId: string
  setIsSearchType: Dispatch<SetStateAction<boolean>>
}

export interface BookDto {
  title: string
  description: string
  coverImage: IAttachment | null
  contentDocument: IAttachment | null
  companyId: string | null
}

export interface AddBookProps {
  companyId?: string
}
export interface FetchBookProps {
  bookId?: string
}
export interface FetchBooksByCompanyIdProps {
  companyId?: string
  groupId?: string
}

export interface DeleteBookResponse {
  deleteBook: (bookId: string) => void
  books: IBook[] | null
  loading: boolean
  error: string
}

export interface FetchBookResponse {
  getBookById: IBook
}

export interface UseLazyFetchBookResponse {
  getBookById: (
    bookId: string,
  ) => Promise<QueryResult<FetchBookResponse, OperationVariables>>
  book: IBook | null
  loading: boolean
  error: string
}

export interface UseFetchBookResponse {
  book: IBook | null
  loading: boolean
  error: string
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<IBook>>
}

export interface AddBookResponse {
  addBook: (variables: BookDto) => void
  books: IBook[] | null
  loading: boolean
  error: string
}
export interface EditBookResponse {
  editBook: (variables: HandleEditBookProps) => void
  books: IBook[] | null
  loading: boolean
  error: string
}

export interface HandleEditBookProps {
  bookId?: string
  updateBookDto: BookDto
}

export interface UseFetchBooksProps {
  searchValue?: string
  currentPage?: number
  perPage?: number
  companyId?: string
}

export interface BooksResponse {
  data: IBook[] | null
  totalPages: number
}

export interface UseFetchBooksResponse {
  books: BooksResponse
  loading: boolean
  error: string
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<IBook>>
  fetchMore: (variables?: IFetchMoreVariables) => void
}

export enum GetBooksByGroupFiltersByEnums {
  IN_GROUP = 'IN_GROUP',
  NOT_IN_GROUP = 'NOT_IN_GROUP',
}

export interface UseFetchBooksByGroupProps {
  searchValue?: string
  currentPage?: number
  filterBy: GetBooksByGroupFiltersByEnums
  perPage?: number
  groupId?: string
}

export interface BookToGroupDto {
  bookIds?: string[]
  groupId?: string
}

export interface AddBookToGroupResponse {
  addBook: (variables: BookToGroupDto) => void
  books: IBook[] | null
  loading: boolean
  error: string
}

export interface RemoveBookFromGroupResponse {
  removeBooksFromGroup: (removeBookDto: BookToGroupDto) => Promise<void>
  books: IBook[] | null
  loading: boolean
  error: string
}

export interface IActionConfig {
  render: (item: IBook) => ReactNode
  hide?: boolean
}

export interface IBookItemExtended extends IBookItem {
  selected?: boolean
  onSelect?: (id: string) => void
  showCheckbox?: boolean
  enableActions?: boolean
  enableGroupActions?: boolean
  groupId?: string
}
