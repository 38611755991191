import Swal, { SweetAlertCustomClass } from 'sweetalert2'
import { useTranslation } from 'react-i18next'

interface Props {
  title?: string
  titleSize?: string
  text?: string
  input?: any
  inputConfirmValue?: string
  inputPlaceholder?: string
  inputValidationMessage?: string
  type?: string
  icon?: 'success' | 'error' | 'warning' | 'info' | 'question' | null
  onConfirm?: any
  onClose?: any
  customClass?: string | SweetAlertCustomClass | undefined
  confirmText?: string
  cancelText?: string
  width?: string | number
  showCancelButton?: boolean
  showConfirmButton?: boolean
}

export const useSwal = () => {
  const { t } = useTranslation()
  const fireSwal = ({
    icon = 'warning',
    title,
    titleSize = '17px',
    text,
    input,
    inputConfirmValue,
    inputValidationMessage,
    onConfirm = {},
    onClose,
    customClass,
    inputPlaceholder,
    confirmText,
    cancelText = t('actions.cancel'),
    width = '20%',
    showCancelButton = true,
    showConfirmButton = true,
  }: Props) => {
    Swal.fire({
      title: `<div style="font-size: ${titleSize};">${title}</div>`,
      text,
      input,
      // Note: when "null" is proved it wont show icon, undefined will still show default icon of warning
      icon: icon || undefined,
      showCancelButton: showCancelButton,
      showConfirmButton: showConfirmButton,
      confirmButtonColor: '#06C68F',
      cancelButtonColor: '#E0E1E2',
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      inputPlaceholder,
      customClass,
      width,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === inputConfirmValue) {
            resolve(null)
          } else {
            resolve(inputValidationMessage as string)
          }
        })
      },
    }).then((result) => {
      if (result.value) {
        onConfirm()
      } else if (onClose && result.dismiss === Swal.DismissReason.cancel) {
        onClose()
      }
    })
  }

  return {
    fireSwal,
  }
}
