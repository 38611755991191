import { useMutation } from '@apollo/client'

import { SEND_PUSH_NOTIFICATIONS } from 'gql/group/group.query'

const sendPushNotifications = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [sendPushNotificationsMutation, { error, data, loading }] = useMutation(
    SEND_PUSH_NOTIFICATIONS,
  )

  return {
    sendPushNotificationsMutation,
    error,
    data,
    loading,
  }
}

export default sendPushNotifications
