import React from 'react'
import { FormikErrors } from 'formik'
import Loader from 'components/common/Loader'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import Uploader from 'components/common/FileUploader/Uploader'
import UploaderButton from 'components/common/FileUploader/UploaderButton'
import { Props, formInterface } from './AddTopicDrawer.interface'
import useAddTopicDrawer from 'hooks/modules/TopicDrawer/useAddTopicDrawer'

const AddTopicDrawer = ({ title, id, data }: Props): React.JSX.Element => {
  const {
    addTopicLoading,
    handleSubmit,
    handleCloseDrawer,
    fileLoading,
    errors,
    touched,
    values,
    handleChange,
    handleExtractText,
    handleAttachmentChange,
    deleteAttachment,
    setFieldValue,
    extractTextLoading,
  } = useAddTopicDrawer({ title, id, data })

  return (
    <>
      {addTopicLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            isDisabled={!!(fileLoading || addTopicLoading)}
            <FormFields>
              <FormField>
                <TextInput
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  label="Name*"
                  size="small"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  label="Topic Overview"
                  size="small"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="4"
                  component={
                    <UploaderButton
                      onChange={handleExtractText}
                      label="Upload"
                      accept="text/plain"
                      loading={extractTextLoading}
                    />
                  }
                />
              </FormField>
              <FormField>
                <Uploader
                  label="Add attachment"
                  accept="*"
                  onFileChange={handleAttachmentChange}
                  deleteFile={deleteAttachment}
                  type="file"
                  inputValue={values.attachment ? values.attachment.name : ''}
                  onInputChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ): Promise<void | FormikErrors<formInterface>> =>
                    setFieldValue('attachment.name', e.target.value)
                  }
                  disabled={!values.attachment}
                  uploadedFile={values.attachment}
                  fileExtension={
                    values.attachment ? `(${values.attachment.fileType})` : ''
                  }
                  loading={fileLoading}
                  name="attachment"
                  id="attachment"
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button text="Cancel" type="small" onClick={handleCloseDrawer} />
              <Button
                text="Save"
                type="small"
                color="secondary"
                btnType="submit"
              ></Button>
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddTopicDrawer
