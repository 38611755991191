import { useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import {
  GET_SKILL_TEST_VIDEOS_TEMPLATES,
  DELETE_SKILL_TEST_VIDEO_TEMPLATE,
} from 'gql/skillTestVideos.query'

const deleteSkillTestVideoTemplateService = (params: any) => {
  const [deleteSkillTestVideoTemplateMutation, { loading, error }] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation(DELETE_SKILL_TEST_VIDEO_TEMPLATE, {
      update(cache, { data: { deleteSkillTestVideoTemplate: document } }) {
        updateCache({
          objectName: 'getSkillTestVideosTemplates',
          query: GET_SKILL_TEST_VIDEOS_TEMPLATES,
          cache,
          document,
          action: 'deleteMany',
          params,
        })
      },
    })

  return {
    deleteSkillTestVideoTemplateMutation,
    loading,
    error,
  }
}

export default deleteSkillTestVideoTemplateService
