import React, { ReactElement } from 'react'
import {
  Information,
  InformationContent,
  TextWrappers,
} from './styled-components'
import { useTranslation } from 'react-i18next'
import { StyledLabel } from '../styled-components'

const DrawerInformation = ({
  groupName,
  courseName,
}: {
  groupName: string
  courseName: string
}): ReactElement => {
  const { t } = useTranslation()
  return (
    <Information>
      <StyledLabel>{t('general.details')}</StyledLabel>
      <InformationContent>
        <TextWrappers>
          <p>{t('general.course_name')}</p>
          <p>{t('groups_layout.group_name')}</p>
        </TextWrappers>
        <TextWrappers>
          <span>{courseName}</span>
          <span>{groupName}</span>
        </TextWrappers>
      </InformationContent>
    </Information>
  )
}

export default DrawerInformation
