import { useQuery } from '@apollo/client'
import { GET_GROUPS_DETAILS } from 'gql/group/group.query'
import { GET_AVAILABLE_TUTORS } from 'gql/users.query'
import useSnackbarAlert from 'hooks/useSnackbar'
import { SnackbarVariants } from 'interfaces/common'
import { ServiceResponse } from './assignCoach.interface'

export const useGroupsDetailsService = (courseId: string): ServiceResponse => {
  const { setSnackbar } = useSnackbarAlert()

  const { data, loading, error } = useQuery(GET_GROUPS_DETAILS, {
    variables: { courseId: courseId || '' },
  })

  const errorMessage = error ? error?.message.split(':')[1] : ''

  if (error) {
    setSnackbar({
      message: errorMessage,
      variant: SnackbarVariants.ERROR,
    })
  }

  return { data, loading, error: errorMessage }
}

export const useAvailableTutorsService = (
  courseId: string,
): ServiceResponse => {
  const { setSnackbar } = useSnackbarAlert()

  const { data, loading, error } = useQuery(GET_AVAILABLE_TUTORS, {
    variables: { courseId: courseId || '' },
  })

  const errorMessage = error ? error?.message.split(':')[1] : ''

  if (error) {
    setSnackbar({
      message: errorMessage,
      variant: SnackbarVariants.ERROR,
    })
  }

  return { data, loading, error: errorMessage }
}
