import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { GET_GROUP } from 'gql/group/group.query'
import { CHECK_USER_EMAIL, GET_USER } from 'gql/users.query'
import { DrawerEventEmitter } from 'helpers/drawer'
import { StudentSchema } from 'helpers/validationSchemas'
import useEditStudentService from 'hooks/users/useEditStudentService'
import { SET_FORMDATA } from 'store/types'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import IconButton from '@mui/material/IconButton'
import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker/DatePicker'
import { AsyncSelect } from 'components/common/SelectField'
import CloseIcon from '@mui/icons-material/Close'

import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Title,
} from 'components/DrawerLayout/styled-components'
import { genderOptions } from 'components/UsersLayout/staticData'
import { AsyncSelectOption } from 'components/common/SelectField/select.interface'

interface FormData {
  firstName: string
  lastName: string
  email: string
  phone: any
  gender: any
  birthDate: any
  biography: string
  group: any
  note: string
}

const EditStudent = (props: any) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const [dataState, dispatchData] = useData()
  const ref: any = useRef()
  const [currentStudent, setCurrentStudent] = useState<any>('')

  const [fetchGroup, { data: groupData, loading: groupLoading }] =
    useLazyQuery(GET_GROUP)

  const { data, loading } = useQuery(GET_USER, {
    variables: { id: props.id },
  })

  const { updateStudent, user, error, editStudentLoading } =
    useEditStudentService()
  const [generalError, setGeneralError] = useState('')
  const [checkEmail] = useMutation(CHECK_USER_EMAIL)

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: null,
      birthDate: null as any,
      biography: '',
      group: null,
      note: '',
      jobTitle: '',
      location: '',
      phoneFields: null as any,
      phoneFieldValue: null as any,
      avatar: '',
    },
    validationSchema: StudentSchema,
    async onSubmit(values) {
      if (values.email !== currentStudent.email) {
        const res = await checkEmail({
          variables: { email: values.email },
        })

        if (res.errors) {
          errors.email = res.errors[0].message
          return false
        }
      }

      const formValues: any = { ...values }

      delete formValues.phoneFieldValue

      formValues.group = formValues.group
        ? formValues.group.map((group: any) => {
            return { name: group.label, groupId: group.value }
          })
        : []

      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }

      formValues.role = data.user.role
      formValues.gender = formValues.gender ? formValues.gender.value : null
      updateStudent(props.id, formValues)
    },
  })

  const handleDateChange = (date: Date | null) => {
    setFieldValue('birthDate', date)
  }

  const closeDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'editStudent', false)
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editStudent',
        values,
        compareTo: currentStudent,
      },
    })
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (user) {
      const updatedUser: any = user
      const group = updatedUser.group
      const values = group.map((item: any) => item.name)

      closeDrawer()
    }
  }, [user])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  useEffect(() => {
    loadDataOptions('')
  }, [])

  useEffect(() => {
    if (data) {
      const {
        firstName,
        lastName,
        email,
        phone,
        gender,
        birthDate,
        biography,
        group,
        note,
        jobTitle,
        location,
        phoneFields,
        avatar,
      } = data.user
      const userGender = genderOptions.find(
        (option: { value: string; label: string }) => option.value === gender,
      )
      const formData: any = {
        firstName,
        lastName,
        email,
        phone,
        gender: gender
          ? {
              label: userGender?.label,
              value: gender,
            }
          : null,
        birthDate: birthDate ? moment(parseInt(birthDate)).format() : null,
        biography,
        jobTitle,
        location,
        group: group.map((group: any) => {
          return { label: group.name, value: group.groupId }
        }),
        note,
        phoneFields: phoneFields.dialCode
          ? { label: phoneFields.code, value: phoneFields.dialCode }
          : null,
        phoneFieldValue: phoneFields?.dialCode || null,
        avatar,
      }
      setValues({
        ...formData,
      })
      setCurrentStudent(formData)
    }
  }, [data])

  const loadDataOptions = (e: any) =>
    setTimeout(() => {
      const value = e
      fetchGroup({
        variables: {
          filter: {
            name: {
              type: 'match',
              value,
            },
            company: {
              type: 'exact',
              value: state.selectedCompany?.id,
            },
          },
        },
      })
    }, 200)

  const handleSelectChange = (e: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const onDateChange = (date: Date | null) => {
    // setCurrentDate(date)
    const birthDate = date
    setFieldValue('birthDate', date)
    // const isValid = moment(birthDate).isValid()
    // if (!isValid) {
    //   setBirthDateError('Invalid Date')
    // } else {
    //   setBirthDateError('')
    // }

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  return (
    <>
      {(loading || editStudentLoading) && <Loader />}
      <DrawerHeader>
        <DrawerTitle>
          {t('actions.edit')} {t('general.student')}
        </DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.first_name')}*`}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  size="small"
                  placeholder={`${t('user_details.first_name')}`}
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.last_name')}*`}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  size="small"
                  placeholder={`${t('user_details.last_name')}`}
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  title={`${t('user_details.email')}*`}
                  size="small"
                  placeholder={`${t('user_details.email')}`}
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <Title>{t('user_details.phone')}</Title>
                <PhoneCountryCode
                  // label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  // title={t('user_details.phone')}
                  selectValue={null}
                  selectChange={(e: any) => setFieldValue('phoneFields', e)}
                  inputLabel="Phone"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={errors.phone || errors.phoneFields ? true : false}
                  errorMessage={
                    errors.phone || errors.phoneFields
                      ? errors.phone || errors.phoneFields
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('user_details.select_gender')}</Title>
                <SelectField
                  placeholder={t('user_details.select_gender')}
                  options={genderOptions}
                  error={errors.gender && touched.gender ? true : false}
                  errorMessage={errors.gender ? errors.gender : ''}
                  touched={touched.gender}
                  value={values.gender}
                  onChange={(e: any) => setFieldValue('gender', e)}
                  isClearable
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('user_details.birthday')}</Title>
                <DatePicker
                  // title={t('user_details.birthday')}
                  // label={t('user_details.birthday')}
                  touched={!!touched.birthDate}
                  error={errors.birthDate ? true : false}
                  errorMessage={errors.birthDate as string | undefined}
                  name="birthDate"
                  value={values.birthDate}
                  onChange={onDateChange}
                />
              </FormField>

              <FormField style={{ position: 'relative' }}>
                <Title>{t('groups_layout.select_group')}</Title>
                <AsyncSelect
                  isMulti
                  onInputChange={handleSelectChange}
                  onChange={(e: AsyncSelectOption) => setFieldValue('group', e)}
                  data={groupData?.getAllGroups?.data || []}
                  value={values.group}
                  label={t('groups_layout.select_group')}
                  loading={groupLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                  error={errors.group}
                  errorMessage={errors.group}
                  touched={touched.group}
                />
              </FormField>

              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.job_title')}`}
                  size="small"
                  placeholder={`${t('form_fields.job_title')}`}
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>

              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size="small"
                  placeholder={`${t('form_fields.location')}`}
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>

              <FormField>
                <TextInput
                  title={`${t('user_details.biography')}`}
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  placeholder={`${t('quiz_details.type_here')}`}
                  size="small"
                  name="biography"
                  type="text"
                  multiline
                  rows="3"
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField>
                <TextInput
                  title={`${t('user_details.note')}`}
                  error={errors.note ? true : false}
                  errorMessage={errors.note ? errors.note : ''}
                  touched={touched.note}
                  placeholder={`${t('user_details.note')}`}
                  size="small"
                  name="note"
                  type="text"
                  multiline
                  rows="3"
                  value={values.note}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditStudent
