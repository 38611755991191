import { useQuery } from '@apollo/client'

import { GET_COMPANIES, GET_COMPANY } from 'gql/companies.query'

export const PER_PAGE = 12

export const useFetchCompanies = (
  currentPage = 1,
  perPage: number = PER_PAGE,
  filter: any,
) => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_COMPANIES, {
    variables: { currentPage, perPage, filter },
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    companies: data && data.companies,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}

export const useFetchCompany = (companyId?: string | null) => {
  const { data, loading, error, refetch }: any = useQuery(GET_COMPANY, {
    variables: { id: companyId },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    company: data && data.company,
    loading,
    error: errorMessage,
    refetch,
  }
}
