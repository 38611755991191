import React, { useState, useEffect } from 'react'
import ReactCountryFlag from 'react-country-flag'
import {
  Container,
  InputContainer,
  Input,
  Label,
  ErrorMessage,
  Flex,
} from './styled-components'
import countryList from './countryCode'
import ReactSelect, { components } from 'react-select'

const PhoneCountryCode = (props) => {
  const [value, setValue] = useState(null)

  const {
    label,
    selectLabel,
    selectValue,
    selectChange,
    inputLabel,
    inputValue,
    inputChange,
    touched,
    error,
    errorMessage,
  } = props

  const { Option } = components
  const CustomSelectOption = (props) => (
    <Option {...props}>
      <ReactCountryFlag countryCode={props.data.value} /> {props.data.label}
    </Option>
  )

  const country = countryList().map((i) => ({
    label: i.dial_code,
    value: i.code,
  }))

  const Component = ({ dialCode, code }) => {
    return (
      <div>
        <ReactCountryFlag countryCode={code} /> {dialCode}
      </div>
    )
  }

  useEffect(() => {
    if (selectValue && selectValue.value) {
      setValue({
        label: (
          <Component dialCode={selectValue.label} code={selectValue.value} />
        ),
        value: selectValue.value,
      })
    }
  }, [selectValue])

  const handleChange = (e) => {
    if (!e) {
      setValue(null)
      selectChange(null)
      return
    }
    setValue({
      label: <Component dialCode={e.label} code={e.value} />,
      value: e.value,
    })
    selectChange(e)
  }

  const errorField = touched && error && errorMessage

  return (
    <Flex>
      <InputContainer>
        <ReactSelect
          placeholder={selectLabel}
          options={country}
          value={value}
          onChange={handleChange}
          components={{ Option: CustomSelectOption }}
          isClearable
        />
      </InputContainer>
      <Container error={errorField}>
        <Label error={errorField}>{label}</Label>
        <Input
          name="phone"
          placeholder={inputLabel}
          onChange={inputChange}
          value={inputValue}
        />
        {touched && error && errorMessage && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
      </Container>
    </Flex>
  )
}

export default PhoneCountryCode
