import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as HorizontalSVG } from 'assets/horizontal-view.svg'
import { ReactComponent as GridSVG } from 'assets/grid-view.svg'
import { styled } from '@mui/material/styles'
import _, { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useUserValue } from 'context/UserContext'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Button } from 'components/common/Button'
import { Input } from 'components/common/TextInput'
import Drawer from 'components/common/Drawer'
import { TabNavigation, activeTabClassName } from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import FilterDrawer from './FilterDrawer/FilterDrawer'
import Player from 'components/common/VideoPlayer/Player'

import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  ModalRef,
  ToolbarItemWrapper,
  ContentWrapper,
} from './styled-components'
import { Grid } from 'components/common/Cards/styled-components'
import SyncIcon from '@mui/icons-material/Sync'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'
import useDeleteCourse from 'hooks/course/useDeleteCourse'
import { usePublishedCourse } from 'hooks/course/useEditCourse'
import { actionConfig, courseListActions } from './gridConfig'
import CourseList from './CourseList'
import { FilterQuery } from './courseList.interface'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import PaginationV2 from 'components/common/PaginationV2'
import { SplashScreen } from 'components/common'
import MyLoader from 'loading/loading'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import Breadcrumb from 'components/common/BreadCrumbV2'

const PREFIX = 'CourseLayout'

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
}

const stylesOut = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',

  '&.noBorder': {
    borderBottom: '1px solid transparent',
  },
}

const rangeOptions = {
  groupRange: [0, 50],
  studentRange: [0, 300],
}

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

export type filterData = {
  values: {}
  filterOptions: {}
}

export const initialValues = {
  name: '',
  state: { value: 'all', label: 'All' },
  level: { value: 'all', label: 'All' },
  coaches: null,
  group: null,
  skills: null,
  video: { value: 'all', label: 'All' },
  studentRange: [0, 300],
}

const CourseLayout = (): React.JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isGridView, setIsGridView] = useState(true)

  const [state] = useUserValue()
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [videoModal, setVideoModal] = useState({
    modal: false,
    data: '',
    subtitle: '',
  })

  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const { deleteCourse } = useDeleteCourse()
  const { fireSwal } = useSwal()
  const [searchText, setSearchText] = useState<string>('')
  const [filterData, setFilterData] = useState<filterData>({
    values: initialValues,
    filterOptions: {},
  })
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [value, setValue] = useState(0)

  const companyMode = state.selectedCompany?.id || state.currentUser.companyId
  const defaultFilter = companyMode
    ? {
        companyId: {
          type: 'EXACT',
          value: companyMode,
        },
        isBought: {
          type: 'EXACT',
          value: value === 0,
        },
      }
    : value === 0
    ? {
        companyId: {
          type: 'EXACT',
          value: null,
        },
      }
    : {
        companyId: {
          type: 'NOT_EQUAL',
          value: null,
        },
        originalId: {
          type: 'ARRAY_IN',
          value: [null],
        },
        isBought: {
          type: 'EXACT',
          value: false,
        },
      }

  const { courses, loading, refetch, error, fetchMore }: any = useFetchCourses(
    { ...defaultFilter, ...filterQuery, ...filterData.filterOptions },
    currentPage,
    perPage,
  )

  const { publishedCourse } = usePublishedCourse()

  const handleClick = (courseId: string): void => {
    navigate(`/courses/${courseId}`)
  }

  const handleEditClick = (field: any): void =>
    DrawerEventEmitter.emit('openDrawer', 'editCourse', true, {
      id: field.id,
    })

  const handleNewCourse = (): void => {
    const companyId =
      state.selectedCompany?.id || state.currentUser.companyId || null
    const isBought = companyId
      ? {
          type: 'EXACT',
          value: value === 0,
        }
      : {}
    DrawerEventEmitter.emit('openDrawer', 'addCourses', true, {
      data: {
        hasSearchByCompany: false,
        companyId,
        onSuccess: () => {
          refetch({
            filter: {
              companyId: {
                type: 'EXACT',
                value: companyId,
              },
              isBought,
            },
            currentPage: 1,
            perPage,
          })
          setSelectedItem({})
          setSelectAll({})
        },
        pagination: {
          currentPage,
          perPage,
        },
      },
    })
  }

  // we dont have checkboxes on courseList anymore

  // const selectItem = (id: string) => {
  //   const selectedItemsOnPage = selectedItem[currentPage] || []
  //   const selectedIndex = selectedItemsOnPage.indexOf(id)
  //   let newSelected: any = []

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selectedItemsOnPage, id)
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
  //     setSelectAll({
  //       ...selectAll,
  //       [currentPage]: false,
  //     })
  //   } else if (selectedIndex === selectedItemsOnPage.length - 1) {
  //     newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
  //     setSelectAll({
  //       ...selectAll,
  //       [currentPage]: false,
  //     })
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selectedItemsOnPage.slice(0, selectedIndex),
  //       selectedItemsOnPage.slice(selectedIndex + 1),
  //     )
  //     setSelectAll({
  //       ...selectAll,
  //       [currentPage]: false,
  //     })
  //   }

  //   setSelectedItem({
  //     ...selectedItem,
  //     [currentPage]: newSelected,
  //   })
  // }

  // const selectAllItem = () => {
  //   if (!selectAll) {
  //     const newArr: string[] = courses.map((n: any) => n.id)
  //     setSelectedItem(newArr)
  //     setSelectAll(true)
  //     return
  //   }
  //   setSelectedItem([])
  //   setSelectAll(false)
  // }

  const handleDeleteCourse = (ids: string[]): void => {
    if (ids.length <= 0) return

    const params = {
      title: `${
        ids.length > 1
          ? t('popups.delete_course_many')
          : t('popups.delete_course_single')
      }`,
      onConfirm: () => {
        deleteCourse(ids, refetch)
        setSelectedItem([])
        setSelectAll({})
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const filterDrawer = (): void => {
    setFilterIsOpen(!filterIsOpen)
  }

  const resetFilter = (): void => {
    setFilterQuery({})
    setFilterData({} as filterData)
    setFilterIsOpen(false)
    setSearchText('')
    // setTimeout(() => {
    //   runQuery()
    // }, 100)
    // refetch({ filter: {}, currentPage, perPage })
  }

  //reset to tab 0, when we dont have companyId
  useEffect(() => {
    if (!state.currentUser.companyId && !state.selectedCompany?.id) {
      setValue(0)
    }
  }, [state.currentUser.companyId, state.selectedCompany?.id])

  const debouncedRefetch = useRef(
    debounce((newSearchText: string) => {
      const formValue = {
        name: {
          type: UpperCaseFilterableFieldType.MATCH,
          value: newSearchText,
        },
      }
      setFilterQuery((prevFilterQuery: FilterQuery) => ({
        ...prevFilterQuery,
        ...formValue,
      }))
      refetch({
        currentPage: 1,
        perPage,
        filter: {
          ...defaultFilter,
          ...formValue,
        },
      })
    }, 500),
  ).current

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearchText = e.target.value
    setSearchText(newSearchText)
    debouncedRefetch(newSearchText)
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    debouncedRefetch(searchText)
  }

  useEffect(() => {
    return () => {
      debouncedRefetch.cancel()
    }
  }, [debouncedRefetch])

  const handlePublishedCourse = (courseId: string[], action: string): void => {
    let title = ''
    let confirmText = ''

    if (action === 'unpublished') {
      title = `<span style="color:red">${t('popups.unpublish_course')}</span>`
      confirmText = t('popups.confirm_unpublish')
    } else {
      title = `<span style="color: green">${t('popups.publish_course')}</span>`
      confirmText = t('popups.confirm_publish')
    }

    setSelectedItem([])

    fireSwal({
      title,
      onConfirm: () => publishedCourse(courseId, action),
      confirmText,
    })
    //
  }

  const handleVideoModal = (data: any): void => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? data.video.links[0].url : '',
      subtitle: !videoModal.modal ? data.subtitle : '',
    })
  }

  const handlePaginationClick = (event: any, value: number): void => {
    if (value !== currentPage) {
      setCurrentPage(value)
    }
  }

  const handleTabChange = (
    event: React.ChangeEvent<{}> | undefined,
    newValue: number,
  ): void => {
    setFilterData({} as filterData)
    setValue(newValue)
    setSelectedItem([])
  }

  const actionHandler = (
    event: string,
    field: any,
    action: string = '',
  ): void | (() => void) => {
    switch (event) {
      case 'viewCourse':
        return handleClick(field)
      case 'deleteCourse':
        return handleDeleteCourse([field])
      case 'publishedUnPublished':
        return handlePublishedCourse([field], action)
      case 'editCourse':
        return handleEditClick(field)
      case 'playIntro':
        return handleVideoModal(field)
      default:
        return () => console.log('')
    }
  }

  const actions = actionConfig(
    actionHandler,
    state.userPermission,
    // state.currentUser,
    // state.currentUser.companyId || state.selectedCompany?.id || null,
    // value,
    t,
  )

  const listActions = courseListActions(
    actionHandler,
    t,
    state.userPermission,
    value,
  )
  const { currentCompany, currentUser } = state

  const createButton = (): React.JSX.Element => (
    <>
      {state.userPermission.createCourse && (
        <Button
          text={`${
            state.selectedCompany?.id
              ? t('courses_layout.create_course')
              : t('courses_layout.create_suada_course')
          }`}
          color="secondary"
          icon={<AddCircleRoundedIcon />}
          onClick={handleNewCourse}
          isDisabled={currentUser.companyId && !currentCompany.platformService}
          background="#06C68F"
        />
      )}
    </>
  )

  const breadCrumbData = [
    {
      label: 'Courses',
      icon: <Learning />,
    },
  ]

  return (
    <Root>
      <ToolbarItem>
        <Breadcrumb data={breadCrumbData} />
      </ToolbarItem>
      <Toolbar styles={toolbarStyle} l>
        <ToolbarItemWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {state.userPermission.createCourse && (
              <Button
                text={`${
                  state.selectedCompany?.id
                    ? t('courses_layout.create_course')
                    : t('courses_layout.create_suada_course')
                }`}
                color="secondary"
                icon={<AddCircleRoundedIcon />}
                onClick={handleNewCourse}
                isDisabled={
                  currentUser.companyId && !currentCompany.platformService
                }
                background="#06C68F"
              />
            )}
          </div>

          <div className="wrapper">
            <ToolbarItem>
              {isGridView ? (
                <IconButton onClick={(): void => setIsGridView(false)}>
                  <GridSVG />
                </IconButton>
              ) : (
                <IconButton onClick={(): void => setIsGridView(true)}>
                  <HorizontalSVG />
                </IconButton>
              )}

              <IconButton
                disabled={_.isEmpty(filterData.filterOptions) && !searchText}
                onClick={resetFilter}
              >
                <SyncIcon fontSize="small" />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <IconButton onClick={filterDrawer}>
                <FilterListIcon
                  fontSize="medium"
                  style={{ color: '#06C68F' }}
                />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={handleSearchChange}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>
      </Toolbar>
      <div>
        <TabNavigation transparent>
          <AppBar position="static" style={styles.bar}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab
                label={
                  state.currentUser.companyId || state.selectedCompany?.id
                    ? t('general.purchased_courses')
                    : t('general.suada_courses')
                }
                {...activeTabClassName(0)}
                style={styles.btn}
              />
              {(state.currentUser.companyId || state.selectedCompany?.id) && (
                <Tab
                  label={t('general.created_courses')}
                  {...activeTabClassName(1)}
                  style={styles.btn}
                />
              )}
            </Tabs>
          </AppBar>
        </TabNavigation>
      </div>
      {/* )} */}
      <ContentWrapper isGrid={isGridView}>
        {error && error}
        {loading ? (
          <MyLoader />
        ) : (
          <>
            {courses?.data.length === 0 ? (
              value === 0 ? (
                <SplashScreen
                  subTitle={t('courses_layout.no_purchased_courses')}
                />
              ) : (
                <SplashScreen
                  title={t('courses_layout.no_courses')}
                  subTitle={t('courses_layout.create_courses')}
                  createButton={createButton}
                />
              )
            ) : (
              <Grid className={'card'} style={{ position: 'relative' }}>
                <CourseList
                  isGridView={isGridView}
                  companyMode={companyMode}
                  activeTab={value}
                  state={state}
                  actionHandler={actionHandler}
                  data={courses?.data}
                  gridStyle={'card'}
                  withTooltips={false}
                  actions={actions}
                  courseListActions={listActions}
                />
              </Grid>
            )}
          </>
        )}
        {courses && courses.data.length > 0 && (
          <div
            style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}
          >
            <PaginationV2
              currentPage={courses ? courses.currentPage : 1}
              totalPages={courses ? courses.totalPages : 0}
              handleChange={handlePaginationClick}
            />
          </div>
        )}
        {/* {courses && courses.data.length === 0 && (
          <div>{t('general.no_courses')}</div>
        )} */}
      </ContentWrapper>
      <Drawer
        opened={filterIsOpen}
        toggleDrawer={(): void => {}}
        totalWidth="700px"
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          refetchCourse={refetch}
          resetFilter={resetFilter}
          onClose={(): void => setFilterIsOpen(false)}
          tabValue={value}
          isCooursePrivacyHidden={true}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={stylesOut.modal}
        open={videoModal.modal}
        onClose={(): void => {
          setVideoModal({
            ...videoModal,
            modal: false,
          })
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <ModalRef>
          <Player url={videoModal.data} subtitle={videoModal.subtitle} />
        </ModalRef>
      </Modal>
    </Root>
  )
}

export default CourseLayout
