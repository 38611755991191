import styled from 'styled-components'

export const ModuleWrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  svg {
    color: #f50057;
    cursor: pointer;
  }
`
export const HelpButtonContainer = styled.div`
  width: 43px;
  display: flex;
  align-items: center;
  padding: 0 0px 0 14px;
  svg {
    cursor: pointer;
  }
`
