import styled from 'styled-components'

export const Container = styled.div<{ error: boolean }>`
  height: 40px;
  border: 1px solid ${({ error }) => (error ? 'red' : 'rgba(0, 0, 0, 0.23)')};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 150px auto;
  position: relative;
  .css-1pahdxg-control {
    border-color: transparent !important;
    box-shadow: 0 0 0 1px transparent !important;
  }
  .css-yk16xz-control {
    border-color: transparent !important;
    box-shadow: 0px !important;
    &:hover {
      border-color: transparent !important;
    }
    &:focus {
      border-color: transparent !important;
    }
    &:active {
      border-color: transparent !important;
    }
  }
`
export const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`
export const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  border-left: 1px solid hsl(0, 0%, 80%);
  padding: 2.5px 15px;
  color: black;
  font-size: 15px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
export const Label = styled.div<{ error: boolean }>`
  position: absolute;
  left: 12px;
  top: -11px;
  background: #fff;
  z-index: 1;
  font-size: 13px;
  color: ${({ error }) => (error ? 'red' : 'rgba(0, 0, 0, 0.54)')};
`
export const ErrorMessage = styled.div`
  position: absolute;
  color: #f44336;
  bottom: -20px;
  left: 6px;
  font-size: 0.75rem;
`
