import { useMutation } from '@apollo/client'
import { UPDATE_USER } from 'gql/users.query'

const updateUserService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateUserMutation, { loading, error }] = useMutation(UPDATE_USER, {})

  return {
    updateUserMutation,
    loading,
    error,
  }
}

export default updateUserService
