import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import addRemoveGroupFromCourseService from 'services/group/addRemoveGroupFromCourseService'
import { useTranslation } from 'react-i18next'

const useAddRemoveGroupFromCourse = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const {
    addRemoveGroupFromCourseMutation,
    addGroupsToCourseMutation,
    removeGroupsToCourseMutation,
  } = addRemoveGroupFromCourseService()

  const addRemoveGroupFromCourse = async (
    courseId: string,
    groupIds: string[],
    callback: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors, data } = await addRemoveGroupFromCourseMutation({
      variables: {
        courseId,
        groupIds,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.group')} ${t('messages.edited')}`
      variant = 'success'
      if (callback) callback()
    }

    setLoading(false)

    setSnackbar({ message, variant })
  }

  const addGroupsToCourse = async (
    courseId: string,
    groupIds: string[],
    callback?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors, data } = await addGroupsToCourseMutation({
      variables: {
        courseId,
        groupIds,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.group')} ${t('messages.edited')}`
      variant = 'success'
      if (callback) callback()
    }

    setLoading(false)

    setSnackbar({ message, variant })
  }

  const removeGroupsFromCourse = async (
    courseId: string,
    groupIds: string[],
    callback?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors, data } = await removeGroupsToCourseMutation({
      variables: {
        courseId,
        groupIds,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.group')} ${t('messages.edited')}`
      variant = 'success'
      if (callback) callback()
    }

    setLoading(false)

    setSnackbar({ message, variant })
  }

  return {
    loading,
    addRemoveGroupFromCourse,
    addGroupsToCourse,
    removeGroupsFromCourse,
  }
}

export default useAddRemoveGroupFromCourse
