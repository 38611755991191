import React from 'react'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts'
import { CompanyChartParams } from './types'
import { generateCharData } from './generateChartData'

import {
  Content,
  Container,
  ContainerHeader,
  ContainerContent,
  ChartContainer,
} from './styled-components'

const CompanyChart = ({ company, handleTabChange, t }: CompanyChartParams) => {
  const data = generateCharData(company, t)

  const handleBarChartClick = (event: any) => {
    handleTabChange(event.activeTooltipIndex)
  }

  return (
    <Content>
      <Container>
        <ContainerHeader>
          {t('company_details.company_diagram')}
        </ContainerHeader>
        <ContainerContent>
          <ChartContainer>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                onClick={handleBarChartClick}
              >
                <CartesianGrid />
                <XAxis dataKey="label" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Bar dataKey="quantity" />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>
        </ContainerContent>
      </Container>
    </Content>
  )
}

export default CompanyChart
