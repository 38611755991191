import React from 'react'
import { FormItemContainer, Label } from './styled-components'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { Button } from 'components/common/Button'
import Slider from '@mui/material/Slider'
import SelectAsync from 'components/common/Select/SelectAsync'
import ReactSelect from 'react-select'
import Typography from '@mui/material/Typography'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormGroup,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { existData } from 'pages/modules/staticData'
import { FilterDrawerProps, OptionType } from './filterDrawer.interface'
import useFilterDrawer from 'hooks/modules/FilterDrawer/useFilterDrawer'

const FilterDrawer = (props: FilterDrawerProps): React.JSX.Element => {
  const {
    onFormSubmit,
    handleSelectChange,
    handleFieldChange,
    data,
    filterData,
    loading,
    t,
    range,
    setRange,
  } = useFilterDrawer(props)

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          {t('general.advanced_filters')} - {t('actions.to_filter')}{' '}
          {t('general.modules')}
        </DrawerTitle>
        <IconButton onClick={props.onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <DrawerContent>
        <FormContainer onSubmit={onFormSubmit}>
          <FormGroupWrapper>
            <FormGroup>
              {!props.courseId && (
                <FormGroupItem>
                  <SelectAsync
                    isMulti
                    onInputChange={handleSelectChange}
                    onChange={handleFieldChange('courses', 'dropdown')}
                    data={data?.getAllCourse?.data || []}
                    value={filterData.courses || []}
                    label={t('general.course')}
                    loading={loading}
                  />
                </FormGroupItem>
              )}
              <FormGroupItem>
                <FormItemContainer>
                  <Label>{t('general.attachment')}</Label>
                  <ReactSelect
                    onChange={handleFieldChange('attachment', 'dropdown')}
                    options={existData}
                    value={filterData.attachment as OptionType}
                  />
                </FormItemContainer>
              </FormGroupItem>

              <FormGroupItem>
                <Typography id="range-slider" gutterBottom>
                  {t('general.duration')}
                </Typography>
                <Slider
                  value={range}
                  onChange={(e: Event, i: number | number[]): void =>
                    setRange(i as number[])
                  }
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  min={0}
                  max={500}
                />
              </FormGroupItem>
            </FormGroup>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={(): void => props.onClose()}
                background="#E0E1E2"
                textColor="#414141"
              />
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
