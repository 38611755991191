import config from '../config/index.ts'
import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from '@apollo/client/utilities'

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }, index) => {
      if (message.statusCode === 401) {
        // // * Logout user
        // logout();
        // window.location.href = "/sign-in";
      }
      // eslint-disable-next-line no-console
      return console.log(
        `Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    })
    // eslint-disable-next-line no-console
    console.log('graphQLErrors::', graphQLErrors)
  }
  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: `Bearer ${localStorage.getItem('token')}` || undefined,
  },
}))

// console.log("GRAPHQL_URI", apiConfig.GRAPHQL_URI);

const uploadLink = createUploadLink({
  uri: config.GRAPHQL_URI,
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.WS_GRAPHQL_URI,
    connectionParams: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }),
)

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  uploadLink,
)

const apolloLink = ApolloLink.from([errorLink, authLink, link])

const inMemoryCache = new InMemoryCache({})

export const client = new ApolloClient({
  link: apolloLink,
  cache: inMemoryCache,
  defaultOptions,
})
