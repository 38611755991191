import React, { FormEvent } from 'react'
import {
  Field,
  ValidationIcon,
  StyledComponentContainer,
  CharactersContainer,
  StyledInput,
  StyledTextArea,
  StyledLabel,
  ErrorMessage,
} from './styled-components'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import './TextInput.scss'
import { FormikErrors, FormikTouched } from 'formik'

export interface Props {
  label: string
  size?: 'small' | 'medium' | undefined
  icon?: React.ReactElement
  name?: string
  type?: string
  value?: string | number
  error?: boolean
  errorMessage?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
  fullWidth?: boolean
  multiline?: boolean
  rows?: number | string
  validate?: boolean
  disabled?: boolean
  readOnly?: boolean
  min?: number
  max?: number
  onChange?: (
    eventOrPath: string | FormEvent<any>,
  ) => void | ((eventOrTextValue: string | FormEvent<any>) => void)
  onBlur?: (
    eventOrPath: string | FormEvent<any>,
  ) => void | ((eventOrTextValue: string | FormEvent<any>) => void)
  onFocus?: (
    eventOrPath: string | FormEvent<any>,
  ) => void | ((eventOrTextValue: string | FormEvent<any>) => void)
  endAdornment?: any
  component?: any
  inputStyle?: React.CSSProperties
  maxCharacter?: number
  placeholder?: string
}

const TextInputV2 = ({
  label,
  size,
  icon,
  name,
  type,
  value,
  error,
  errorMessage,
  touched,
  fullWidth,
  onChange,
  onBlur,
  onFocus,
  multiline,
  rows,
  validate = true,
  disabled = false,
  readOnly = false,
  endAdornment,
  component,
  inputStyle,
  maxCharacter,
  placeholder = '',
}: Props) => {
  const [showPassword, setShowPassword] = React.useState('password')

  const toggleShowPassword = (newShowPassword: string) =>
    setShowPassword(newShowPassword)

  return (
    <Field className={fullWidth ? 'full' : ''}>
      <StyledLabel>{label}</StyledLabel>
      {rows ? (
        <>
          <StyledTextArea
            error={touched && error ? true : false}
            helperText={touched && error ? (errorMessage as string) : ''}
            id="standard-basic"
            label={label}
            size={size}
            name={name}
            value={value}
            rows={parseInt(rows as string)}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            style={{ width: '100%' }}
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
          />
          {touched && error && errorMessage && (
            <ErrorMessage>
              <>{errorMessage}</>
            </ErrorMessage>
          )}
        </>
      ) : (
        <>
          <StyledInput
            error={touched && error ? true : false}
            helperText={touched && error ? (errorMessage as string) : ''}
            id="standard-basic"
            label={label}
            size={size}
            name={name}
            type={type === 'password' ? showPassword : type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            style={{ width: '100%' }}
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
          />
          {touched && error && errorMessage && (
            <ErrorMessage>
              <>{errorMessage}</>
            </ErrorMessage>
          )}
        </>
      )}
      {maxCharacter && typeof value === 'string' && (
        <CharactersContainer>
          {value?.length || 0}/{maxCharacter}
        </CharactersContainer>
      )}
      {touched && validate && (
        <ValidationIcon className={`${error ? 'error' : 'valid'}`}>
          {error ? (
            <ErrorOutlineRoundedIcon />
          ) : (
            <CheckCircleOutlineRoundedIcon />
          )}
        </ValidationIcon>
      )}
      {icon && icon}

      {component ? (
        <StyledComponentContainer>{component}</StyledComponentContainer>
      ) : null}

      {}
    </Field>
  )
}

export default TextInputV2
