import React from 'react'
import { Action } from 'components/common/GridV2'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { DeleteSpan } from 'components/common/Styled/styled-components'

export const actionConfig = (
  handleEditClick: () => void,
  handleDeleteClick: () => void,
  permissions?: Record<string, unknown>,
  t: (key: string) => string = (key) => key,
) => {
  const action = [
    {
      render: (): JSX.Element => (
        <Action onClick={handleEditClick}>
          <EditRoundedIcon /> <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      render: (): JSX.Element => (
        <Action onClick={handleDeleteClick}>
          <DeleteIcon /> <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
    // {
    //   render: (item: any) => (
    //     <Action
    //       onClick={() => handleDownloadClick(item.attachment)}
    //       className={item.attachment ? '' : 'disabled'}
    //     >
    //       <CloudDownloadIcon />
    //       <span>{t('actions.download_attachment')}</span>
    //     </Action>
    //   ),
    // },
  ]

  return action.filter((i: any) => !i.hide)
}
