import { useMutation } from '@apollo/client'

import { DELETE_COMPANY } from 'gql/companies.query'

const deleteCompanyService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteCompanyMutation, { loading, error }] = useMutation(
    DELETE_COMPANY,
    {},
  )

  return {
    deleteCompanyMutation,
    loading,
    error,
  }
}

export default deleteCompanyService
