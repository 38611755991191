import { useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import {
  GET_SKILL_TEST_VIDEOS_TEMPLATES,
  ADD_SKILL_TEST_VIDEO_TEMPLATE,
} from 'gql/skillTestVideos.query'

const addSkillTestVideoTemplateService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addSkillTestVideoTemplateMutation, { loading, error }] = useMutation(
    ADD_SKILL_TEST_VIDEO_TEMPLATE,
    {
      update(cache, { data: { addSkillTestVideoTemplate: document } }) {
        updateCache({
          objectName: 'getSkillTestVideosTemplates',
          query: GET_SKILL_TEST_VIDEOS_TEMPLATES,
          cache,
          document,
          action: 'add',
          params,
        })
      },
    },
  )

  return {
    addSkillTestVideoTemplateMutation,
    loading,
    error,
  }
}

export default addSkillTestVideoTemplateService
