import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  padding: 0 24px;
  z-index: 999;
  background: white;
  height: 60px;
  justify-content: flex-end;
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
export const NotificationContainer = styled.div`
  margin-right: 14px;
  position: relative;
  .MuiPopover-paper {
    overflow-y: unset !important;
    overflow-x: unset !important;
  }
`
export const NotificationMenuWrapper = styled.div`
  position: relative;
  width: 336px;
  height: 455px;
  &::before {
    top: -8px;
    right: 18px;
    position: absolute;
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }
`
export const CompanyButton = styled.div`
  width: 34px;
  height: 34px;
  background: #06c68f;
  border-radius: 50%;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

// Sidebar
export const StyledLogo = styled.img<{ open: boolean }>`
  width: 150px;
  top: 7px;
  height: auto;
  object-fit: initial;
  ${(props): string => (props.open ? 'right: 25px;' : 'left: 11px;')}
`

export const IconWrapper = styled.div`
  cursor: pointer;
`

export const StyledSwitchSize = styled.div`
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: 36px;
  background-color: rgb(6, 198, 143);
  transition: 0.3s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 6px;
  cursor: pointer;

  &:hover {
    background-color: #4a93a3;
  }
`

export const StyledToolbar = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  margin: 12px;
  width: 50px;
`

export const StyledDrawer = styled.div<{ open: boolean }>`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: 0.2s ease-in-out;
  background-color: #ffffff !important;
  justify-content: space-between;
  color: #989898;
  height: 100vh;
  width: ${({ open }) => (open ? '240px' : '70px')};
`
