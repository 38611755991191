import { DrawerEventEmitter } from 'helpers/drawer'
import { useLazyQuery } from '@apollo/client'
import { GET_GROUPS_BY_TEST_ASSESSMENT_ID } from 'gql/group/group.query'
import useDeleteTestService from '../TestLayout/useDeleteTestService'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GET_TEST } from 'gql/tests/tests.query'
import { IUseTestDetailsServices } from './testDetails.interface'
import { TestAssessment } from 'pages/tests/testLayout/testLayout.interface'
import usePublishUnpublishTestService from '../TestLayout/usePublishUnpublishTestService'

const useTestDetailsServices = ({
  testId,
  setTab,
}: IUseTestDetailsServices) => {
  const { deleteTest } = useDeleteTestService()
  const { fireSwal } = useSwal()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleDeleteClick = (ids: string[]) => {
    fireSwal({
      title: t('popups.delete_test'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        navigate('/tests')
        deleteTest(ids)
        testAssessmentGroupsRefetch({
          testId,
        })
      },
    })
  }
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }
  const handelOpenEditDrawer = (id: string) => {
    DrawerEventEmitter.emit('openDrawer', 'editTest', true, {
      id,
    })
  }

  const [
    fetchTestAssessmentById,
    {
      data: testAssessment,
      loading: testAssessmentLoading,
      error: testAssessmentError,
      refetch: testAssessmentRefetch,
    },
  ] = useLazyQuery(GET_TEST)

  const { publishUnpublishTestAssessment } = usePublishUnpublishTestService()
  const handlePublish = (testAssessment: TestAssessment) => {
    fireSwal({
      title: testAssessment.published
        ? t('tests_layout.unpublish_test')
        : t('tests_layout.publish_test'),
      confirmText: testAssessment.published
        ? t('popups.confirm_unpublish')
        : t('popups.confirm_publish'),
      onConfirm: () => {
        publishUnpublishTestAssessment(
          testAssessment.id,
          !testAssessment.published,
        ).then(() => {
          testAssessmentRefetch({
            testId,
          })
        })
      },
    })
  }

  const [
    fetchTestAssessmentGroups,
    {
      data: testAssessmentGroups,
      loading: testAssessmentGroupsLoading,
      refetch: testAssessmentGroupsRefetch,
    },
  ] = useLazyQuery(GET_GROUPS_BY_TEST_ASSESSMENT_ID)

  const actionHandler = (event: string, field: any) => {
    switch (event) {
      case 'publish':
        handlePublish(field)
        break
      case 'edit':
        handelOpenEditDrawer(field.id)
        break
      case 'delete':
        handleDeleteClick(field.id)
        break
    }
  }

  return {
    actionHandler,
    fetchTestAssessmentGroups,
    fetchTestAssessmentById,
    testAssessment: testAssessment?.getTestAssessmentById,
    testAssessmentLoading,
    testAssessmentError,
    testAssessmentGroups,
    testAssessmentGroupsLoading,
    testAssessmentGroupsRefetch,
    handleTabChange,
  }
}

export default useTestDetailsServices
