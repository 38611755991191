import { useState } from 'react'
import addGroupService from 'services/group/addGroupService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useData } from 'context/DataContext'
import { SET_CREATED_USER } from 'store/types'
import { useTranslation } from 'react-i18next'

const useAddGroup = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const [group, setGroup] = useState<any>(null)
  const { addGroupMutation } = addGroupService(params)
  const [state, dispatch] = useData()

  const addGroup = async (payload: any, students?: any, refetch?: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)
    setGroup(null)

    const { errors, data } = await addGroupMutation({
      variables: { input: payload, students },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.group')} ${t('messages.added')}`
      variant = 'success'
      setGroup(data.addGroup)
      dispatch({
        type: SET_CREATED_USER,
        payload: {
          obj: data.addGroup,
          type: 'group',
        },
      })
    }

    setSnackbar({ message, variant })
    if (refetch) refetch()
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    loading,
    addGroup,
    group,
  }
}

export default useAddGroup
