import useUpateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import { IUseHelperChangeUserStatusProps } from './useHelperUserChangeStatus.interface'

export const useHelperChangeUserStatus = ({
  defaultFilter,
  ROLE,
  selectedItem,
  setSelectedItem,
  currentPage,
}: IUseHelperChangeUserStatusProps) => {
  const { updateUserStatus } = useUpateStatusUserService({
    ROLE,
    defaultFilter,
  })
  const confirmChangeStatus = (
    userId: string,
    status: 'ACTIVE' | 'SUSPENDED',
  ): void => {
    updateUserStatus([userId], status)
  }

  const confirmChangeStatusMany = (newStatus: string): void => {
    updateUserStatus(selectedItem[currentPage], newStatus)
    setSelectedItem([])
  }

  return { confirmChangeStatus, confirmChangeStatusMany }
}
