import React from 'react'
import ReactSelect from 'react-select'
import { useQuery } from '@apollo/client'
import { filterQuestions } from './filterQuestions'
import IconButton from '@mui/material/IconButton'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import { useUserValue } from 'context/UserContext'
import { useFetchModules } from 'hooks/modules/useModules'
import { filterQuestionTypes, editedOptions } from 'pages/questions/staticData'
import { GET_TAGS } from 'gql/skilss.query'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import CloseIcon from '@mui/icons-material/Close'
import { TFunction } from 'interfaces/TFunction'

interface Params {
  setIsFiltered?: any
  courseId?: string | null
  closeDrawer: () => void
  handleFilterClick?: any
  filterData: any
  quizModel: 'general' | 'course' | 'module' | 'topic' | 'lesson'
  setFilterData: any
  currentPage: number
  refetch?: any
  setFiltered: () => void
  questions: any
  setFilteredQuestionList?: any
  companyId?: string
  setCurrentPage?: any
  setCards?: any
  t: TFunction
}

const FilterDrawer = ({
  courseId,
  closeDrawer,
  handleFilterClick,
  filterData,
  quizModel,
  setFilterData,
  companyId,
  setFiltered,
  setIsFiltered,
  setCurrentPage,
  t,
}: Params): React.JSX.Element => {
  const [state] = useUserValue()

  const { data } = useQuery(GET_TAGS, {
    variables: {
      companyId,
    },
  })

  const filters: any = {
    companyId: {
      type: LowerCaseFilterableFieldType.EXACT,
      value: state.selectedCompany?.id,
    },
  }

  if (quizModel === 'course' && courseId) {
    filters.course = { type: 'nestedArrayIn', value: courseId }
  }

  const { modules }: any = useFetchModules(filters, 1, 0)

  const tagList =
    (data &&
      data.getAllTags.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  const moduleList: any =
    (modules &&
      modules.data.map((i: any) => ({ label: i.name, value: i.id }))) ||
    []

  const handleChange = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (e === null || !e.target) {
      setFilterData({
        ...filterData,
        [field]: e,
      })
    } else {
      setFilterData({
        ...filterData,
        [field]: e.target.value,
      })
    }
  }

  const handleSubmit = (e: any): void => {
    e.preventDefault()

    const filterOptions: any = {}
    if (filterData.type && filterData.type.value !== 'all') {
      filterOptions.type = {
        type: LowerCaseFilterableFieldType.EXACT,
        value: filterData.type.value,
        nestedField: 'value',
      }
    }

    if (filterData.tags) {
      filterOptions.tags = {
        type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
        value: filterData.tags.map((tag: any) => tag.label),
        nestedField: 'label',
      }
    }

    if (filterData.modules) {
      filterOptions.modules = {
        type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
        value: filterData.modules.map((moduleItem: any) => moduleItem.value),
        nestedField: 'moduleId',
      }
    }

    if (filterData.edited && filterData.edited.value !== 'all') {
      filterOptions.edited = {
        type: LowerCaseFilterableFieldType.BOOL,
        value: filterData.edited.value,
      }
    }

    handleFilterClick(filterOptions)

    closeDrawer()
    if (setCurrentPage) setCurrentPage(1)
    if (setFiltered && setIsFiltered) {
      setFiltered()
      setIsFiltered(JSON.stringify(filterOptions) !== '{}')
    }
  }
  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('actions.filters')}</DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <Title>{t('general.type')}</Title>
                <ReactSelect
                  onChange={(e: any) => handleChange('type', e)}
                  options={filterQuestionTypes}
                  value={filterData.type || { value: 'all', label: 'All' }}
                  placeholder={t('form_fields.select')}
                />
              </FormField>

              <FormField>
                <Title>{t('general.tags')}</Title>
                <ReactSelect
                  onChange={(e: any): void => handleChange('tags', e)}
                  options={tagList}
                  value={filterData.tags}
                  isMulti
                  placeholder={t('form_fields.select')}
                />
              </FormField>
              {(quizModel === 'general' || quizModel === 'course') && (
                <FormField>
                  <Title>{t('general.modules')}</Title>
                  <ReactSelect
                    onChange={(e: any): void => handleChange('modules', e)}
                    options={moduleList}
                    value={filterData.modules}
                    isMulti
                  />
                </FormField>
              )}
              <FormField>
                <Title>{t('general.status')}</Title>
                <ReactSelect
                  onChange={(e: any): void => handleChange('edited', e)}
                  options={editedOptions}
                  value={filterData.edited || { value: 'all', label: 'All' }}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={closeDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
