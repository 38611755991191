import React from 'react'
import { CheckboxContainer, ErrorMessage } from './styled-components'

import Checkbox from '@mui/material/Checkbox'

export default ({
  indeterminate,
  checked,
  onChange,
  inputProps,
  name,
  color,
  readOnly,
  error,
}: any) => (
  <CheckboxContainer onClick={e => e.stopPropagation()}>
    <Checkbox
      indeterminate={indeterminate}
      checked={checked}
      onChange={onChange}
      inputProps={inputProps}
      name={name}
      style={
        readOnly && {
          pointerEvents: 'none',
          cursor: 'default',
        }
      }
      color={color}
    />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </CheckboxContainer>
)
