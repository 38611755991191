import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Button } from 'components/common/Button'
import Grid from 'components/common/Grid'
import { Input } from 'components/common/TextInput'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import { useSwal } from 'hooks/useSwal'
import React, { useState } from 'react'
import styled from 'styled-components'
import { generateFullName } from 'utils/utils'
import EditSuperAdmin from './EditSuperAdmin'
import { actionConfig, columnConfig } from './gridConfig'
import { User } from 'interfaces/users'
import {
  CompanyField,
  CompanySuperAdminListProps,
  EditField,
  Field,
} from '../CompanyWizard.interface'

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
}

const CompanySuperAdminList = ({
  superAdminList,
  companyId,
  refetch,
  t,
}: CompanySuperAdminListProps): React.JSX.Element => {
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [searchText, setSearchText] = useState('')
  const { fireSwal } = useSwal()
  const [checkbox, setCheckbox] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editField, setEditField] = useState<EditField | null>(null)

  const { deleteUser } = useUserDeleteService({
    role: 'SUPER_ADMIN',
    companyId,
  })

  const handleSelect = (id: never): void => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = (): void => {
    if (!selectAll) {
      const newArr: string[] = superAdminList.map((n: { id: string }) => n.id)
      setSelectedItem(newArr)
      setSelectAll(true)
      return
    }
    setSelectedItem([])
    setSelectAll(false)
  }

  const handleDelete = (ids: string[]): void => {
    if (ids.length <= 0) return
    const title: string =
      ids.length === 1
        ? `${t('popups.delete_single')} ${t('general.super_admin')}`
        : `${t('popups.delete_many')} ${t('general.super_admins')}`

    const params = {
      title,
      onConfirm: (): void => {
        deleteUser(ids, 'SUPER_ADMIN', () => {
          refetch()
          setSelectedItem([])
          setCheckbox(false)
        })
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleEdit = (field: Field): void => {
    setEditMode(true)
    setEditField({
      ...field,
      phoneFields: {
        label: field.phoneFields.code,
        value: field.phoneFields.dialCode,
      },
    })
  }

  const actionClick = (e: string, field: CompanyField): void => {
    switch (e) {
      case 'edit':
        return handleEdit(field)

      case 'delete':
        return handleDelete([field.id])

      default:
        break
    }
  }

  const config = columnConfig(handleSelect, t, !checkbox)
  const actions = actionConfig(actionClick, t)

  const filtered = superAdminList.filter((i: User) =>
    generateFullName(i).toLowerCase().includes(searchText.toLowerCase()),
  )

  return (
    <>
      {editMode && (
        <EditSuperAdmin
          onCancel={(): void => {
            setEditField(null)
            setEditMode(false)
          }}
          item={editField}
          t={t}
        />
      )}

      <Toolbar styles={toolbarStyle}>
        <ToolbarItemWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title>{t('company_wizard_layout.super_admin_list')}</Title>

            <div style={{ marginLeft: '10px' }}>
              {filtered && filtered.length > 0 && (
                <Button
                  text={
                    !checkbox ? t('actions.delete_rows') : t('actions.cancel')
                  }
                  type="small"
                  onClick={(): void => {
                    if (checkbox) {
                      setSelectedItem([])
                    }
                    setCheckbox(!checkbox)
                  }}
                  background="#06C68F"
                />
              )}
            </div>

            {checkbox && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip title={t('actions.delete')}>
                  <IconButton onClick={(): void => handleDelete(selectedItem)}>
                    <DeleteIcon
                      fontSize="small"
                      color={
                        selectedItem.length === 0 ? 'disabled' : 'secondary'
                      }
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>

          <div className="wrapper">
            <ToolbarItem>
              <form
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                  e.preventDefault()
                }
              >
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    setSearchText(e.target.value)
                  }
                  icon={
                    <IconButton>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>
      </Toolbar>

      <Grid
        config={config}
        data={filtered}
        actionConfig={actions}
        selected={selectedItem.length}
        selectAllItem={selectAllItem}
        selectedItems={selectedItem}
        disabledSelect={!checkbox}
        openInExternalTab={true}
      />
    </>
  )
}

export default CompanySuperAdminList

const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
`
