import React, { useEffect, useState } from 'react'
import { gql } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router'
import { DrawerEventEmitter } from 'helpers/drawer'
import { GET_TOPIC } from 'gql/topics.query'
import { useTranslation } from 'react-i18next'
import { actionConfig } from './actionConfig'
import { useSwal } from 'hooks/useSwal'
import { useDeleteTopic } from 'hooks/topics/useDeleteTopic'
import { useUserValue } from 'context/UserContext'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  Row,
  DetailsContentLeft,
  DetailsContentRight,
  DetailsContentHeader,
  DetailsContentHeaderSection,
  DetailsTitle,
  Column,
} from 'components/common/DetailsLayout'
import Thumbnail from 'components/common/Thumbnail/Thumbnail'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { Button } from 'components/common/Button'
import LessonLayout from 'pages/lessons/LessonLayout'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { QuizLayout } from 'pages/quizzes'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { formatDuration } from 'helpers/formatDuration'
import { useLocation, useParams } from 'react-router-dom'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'

import {
  ModuleContentDetailsContainer,
  ModuleContentDetailsHeader,
  ModuleContentDetails,
  ModuleContentDetail,
} from 'pages/modules/ModuleDetail/ModuleDetailContent/styled-components'

interface IBreadcrumbItem {
  label: string
  link: string
  state?: Record<string, unknown>
  icon?: JSX.Element
}

interface ILesson {
  duration: number
}

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const GET_MODULE_IMAGE = gql`
  query ($moduleId: String!) {
    getModule(moduleId: $moduleId) {
      name
      coverImage {
        link
      }
    }
  }
`

const TopicDetails = (): React.JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [value, setValue] = useState(0)
  const [state] = useUserValue()
  const { topicId } = useParams()
  const { data, refetch } = useQuery(GET_TOPIC, { variables: { topicId } })
  const moduleId = (data && data.topic.currentModule.moduleId) || null
  const { state: routerState } = useLocation()

  const { data: moduleData } = useQuery(GET_MODULE_IMAGE, {
    variables: { moduleId },
  })
  const { deleteTopic, deletedTopic } = useDeleteTopic({
    moduleId,
    filter: {},
  })

  const { fireSwal } = useSwal()

  const confirmDelete = (ids: string[]): void => {
    deleteTopic(moduleId, ids, () => navigate(`/modules/${moduleId}`))
  }

  const handleAddLesonClick = (id: string, name: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'addLesson', true, {
      id: moduleId,
      data: { name, id },
    })
  }

  const handleEditClick = (id: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'editTopic', true, {
      id,
    })
  }

  const handleDeleteClick = (id: string): void => {
    fireSwal({
      title: t('popups.delete_topic_single'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => confirmDelete([id]),
    })
  }
  const openAttachment = (attachment: string): void => {
    window.open(attachment, '_blank')
  }

  useEffect(() => {
    if (deletedTopic.length !== 0) {
      // navigate(`/modules/${data.topic.module}`)
    }
  }, [deletedTopic])

  const topic = (data && data.topic) || {}
  const moduleImage = moduleData && moduleData.getModule.coverImage
  const actions = actionConfig(
    t,
    handleAddLesonClick,
    handleEditClick,
    handleDeleteClick,
    openAttachment,
    state.userPermission,
  )

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: number,
  ): void => {
    setValue(newValue)
  }

  let breadCrumbData: IBreadcrumbItem[] = []

  if (moduleData && data) {
    breadCrumbData = [
      {
        label:
          (routerState?.from &&
            `${t('general.course')} ${t('general.modules')}`) ||
          t('general.modules'),
        link: routerState?.from || '/modules',
        state: routerState?.state || {},
        icon: <Learning />,
      },
      {
        label: moduleData.getModule.name,
        link: `/modules/${moduleId}`,
      },
      { label: data.topic.name, link: '' },
    ]
  }

  const duration = topic.lessons
    ? topic.lessons.reduce((duration: number, lesson: ILesson) => {
        duration += lesson.duration

        return duration
      }, 0)
    : 0

  const currentModule = (moduleData && moduleData.getModule) || {}

  const canModify = !(!currentModule.companyId && state.currentUser.companyId)

  return (
    <div>
      <Breadcrumb data={breadCrumbData} />
      {topic && (
        <Row>
          <DetailsContentLeft>
            <DetailsContentHeader>
              <Thumbnail
                width={100}
                height={100}
                imageSrc={moduleImage && moduleImage.link}
              />
              <DetailsContentHeaderSection>
                <DetailsTitle>{topic.name}</DetailsTitle>
                {/* <article>{course.description}</article> */}
              </DetailsContentHeaderSection>
            </DetailsContentHeader>
            {/* <DetailsContentDescription>
              {editTopicLoading && <Loader withBackground={true} />}
              <TextEditor
                text={topic.editorText}
                isVisible={editMode}
                hasControls={true}
                editorTitle="Topic overview"
                closeEditMode={() => setEditMode(false)}
                openEditMode={() => setEditMode(true)}
                onSave={editTopicDescription}
                canEdit={state.userPermission.editTopic}
              />
            </DetailsContentDescription> */}
          </DetailsContentLeft>
          <DetailsContentRight className="noVideo">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginBottom: '20px',
              }}
            >
              {canModify && (
                <GridActionMenu
                  actionConfig={actions}
                  row={topic}
                  icon={
                    <Button
                      text={t('general.actions')}
                      size="small"
                      onClick={(): void => {}}
                      icon={<ArrowDropDownIcon />}
                      iconPosition="right"
                      background="#06c68f"
                    />
                  }
                />
              )}
            </div>
          </DetailsContentRight>
        </Row>
      )}
      <ModuleContentDetailsContainer>
        <ModuleContentDetailsHeader>
          {t('topic_details.topic_details')}
        </ModuleContentDetailsHeader>
        <Row>
          <Column>
            <ModuleContentDetails>
              <ModuleContentDetail>
                <strong>{t('general.duration')}:</strong>{' '}
                {formatDuration(duration)}
              </ModuleContentDetail>
              {/* <ModuleContentDetail>
                <strong>Topics:</strong>
              </ModuleContentDetail> */}
              <ModuleContentDetail>
                <strong>{t('general.lessons')}:</strong>{' '}
                {(topic.lessons && topic.lessons.length) || 0}
              </ModuleContentDetail>
            </ModuleContentDetails>
          </Column>
          {/* <Column>
            <div style={{ textAlign: 'right' }}>
              {moduleItem.certificateImage ? (
                <CertificateContainer>
                  <span style={{ color: '#f50057' }}>Certificate Included</span>
                  <CertificateContent>
                    {renderCertificate(moduleItem.certificateImage.link)}
                  </CertificateContent>
                </CertificateContainer>
              ) : (
                <span
                  style={{
                    color: '#f50057',
                    display: 'inline-block',
                    padding: 10,
                  }}
                >
                  Certificate not Included
                </span>
              )}
            </div>
          </Column> */}
        </Row>
      </ModuleContentDetailsContainer>
      <br /> <br />
      <TabNavigation withBorder={true}>
        <AppBar position="static" style={styles.bar}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            <Tab
              label={t('general.lessons')}
              {...activeTabClassName(0)}
              style={styles.btn}
            />
            <Tab
              label={t('general.quizzes')}
              {...activeTabClassName(1)}
              style={styles.btn}
            />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <>
            {topic.lessons && (
              <LessonLayout
                topic={{ name: topic.name, id: topic.id }}
                moduleId={topic.module}
                refetch={refetch}
                lessons={topic.lessons}
                isDraggable
                canModify={canModify}
              />
            )}
          </>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <QuizLayout
            type="topic"
            id={topic.id}
            canCreate={topic.numberOfModuleQuestions}
            name={topic.name}
            isDraggable
            companyId={topic.companyId}
            companyCourse={!canModify}
          />
        </TabPanel>
      </TabNavigation>
    </div>
  )
}

export default TopicDetails
