import React from 'react'

import { Action } from 'pages/group/styled-components'

import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg'
import { TFunction } from 'interfaces/TFunction'

export const actionConfig = (
  handleDeleteClick: (questions: string[]) => void,
  t: TFunction<'translation', undefined>,
) => {
  const action = [
    {
      render: (item: any) => (
        <Action
          onClick={() => handleDeleteClick([item.id])}
          hoverColor="rgb(231, 40, 21)"
          hoverBackground="rgb(253, 237, 236)"
        >
          <Tooltip title={t('actions.delete')} arrow>
            <DeleteIcon />
          </Tooltip>
        </Action>
      ),
    },
  ]
  return action
}
