import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { Container, ActionsContent } from './styled-components'

const PREFIX = 'GridActionMenu'

const classes = {
  button: `${PREFIX}-button`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.button}`]: {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

const GridActionMenu = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [drawerOpened, setDrawerOpened] = useState(false)

  const handleClick = (event: React.BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <Root>
      {props.actionConfig.length > 0 && (
        <Container onClick={(e) => e.stopPropagation()}>
          <div onClick={handleClick}>
            {props.button ? (
              props.button
            ) : (
              <IconButton className={`${props.icon && classes.button} Grid`}>
                {props.icon ? props.icon : <MoreVertRoundedIcon />}
              </IconButton>
            )}
          </div>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ActionsContent>
              {props.actionConfig.map((i: any, index: any) => (
                <div key={index} onClick={() => setAnchorEl(null)}>
                  {i.render(props.row)}
                </div>
              ))}
            </ActionsContent>
          </Popover>
        </Container>
      )}
    </Root>
  )
}

export default GridActionMenu
