import React from 'react'
import { TabNavigation, activeTabClassName } from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Container, Grid, ImageContainer } from './styled.components'

interface Tabs {
  label: string
  value: string | number
}

interface Params {
  images: string[]
  selectedImage: string
  setSelectedImage: (image: string) => void
  tabs?:
    | {
        withTabs: boolean
        currentTab: string
        handleTabChange: (event: any, value: any) => void | undefined
        tabsList: Tabs[]
      }
    | undefined
}

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const ImagePicker = ({
  images,
  selectedImage,
  setSelectedImage,
  tabs = {
    withTabs: false,
    currentTab: '',
    handleTabChange: () => {},
    tabsList: [],
  },
}: Params) => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {tabs.withTabs && (
        <div
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 130,
            background: 'white',
          }}
        >
          <TabNavigation withBorder={true} transparent>
            <AppBar position="static" style={styles.bar}>
              <Tabs
                value={tabs.currentTab}
                onChange={(event: any, value: string) =>
                  tabs.handleTabChange(event, value)
                }
                aria-label="simple tabs example"
              >
                {tabs?.tabsList.map((tab, i) => (
                  <Tab
                    label={tab.label}
                    value={tab.value}
                    {...activeTabClassName(tab.label)}
                    style={styles.btn}
                    key={i}
                  />
                ))}
              </Tabs>
            </AppBar>
          </TabNavigation>
        </div>
      )}
      <Container>
        <Grid>
          {images.map((image, i) => (
            <ImageContainer
              onClick={() => setSelectedImage(image)}
              className={`${
                selectedImage && selectedImage === image ? 'selected' : ''
              }`}
              data-cy={`image-container-${i}`}
              key={i}
            >
              <img src={image} />
            </ImageContainer>
          ))}
        </Grid>
      </Container>
    </div>
  )
}

export default ImagePicker
