import styled from 'styled-components'

export const Card = styled.div<{ width?: any }>`
  cursor: pointer;
  background: #fff;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ width }) => width && `width: ${width}`}
`
export const Header = styled.header`
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 500;
`

export const Content = styled.section`
  font-size: 13px;
  padding: 20px 0;
  width: 100% !important;
`

export const Footer = styled.footer`
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`

export const VideosCountContainer = styled.div`
  font-size: 14px;
  font-style: italic;
  opacity: 0.6;
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`
export const TipsWrapper = styled.div`
  padding-top: 20px;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  margin-top: 20px;
`

export const TipsList = styled.ul`
  list-style: disc;
`

export const TipsItem = styled.li``

export const TipsDescription = styled.article`
  padding: 10px 20px;
`
