import { OperationVariables, useLazyQuery, useQuery } from '@apollo/client'

import { GET_STUDENTS } from 'gql/users.query'
import { User } from 'interfaces/users'

export const PER_PAGE = 10

interface IGetStudentsResponse {
  data: User[]
  totalPages: number
}

export interface IUseLazyFetchStudents {
  users: IGetStudentsResponse
  loading: boolean
  error: string | undefined
  fetchStudents: (variables: OperationVariables) => void
}

export const useFetchStudents = (
  filter: any = {},
  currentPage = 1,
  perPage: number = PER_PAGE,
  companyId?: string,
) => {
  const { data, loading, error, refetch } = useQuery(GET_STUDENTS, {
    variables: {
      filter: { ...filter },
      currentPage,
      perPage,
      companyId,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.getStudentsForAdmin,
    loading,
    error: errorMessage,
    refetch,
  }
}

export const useLazyFetchStudents = (): IUseLazyFetchStudents => {
  const [fetchStudents, { data, loading, error }] = useLazyQuery(GET_STUDENTS)

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.getStudentsForAdmin,
    loading,
    error: errorMessage,
    fetchStudents: (variables) => fetchStudents({ variables }),
  }
}
