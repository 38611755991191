import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
// for time
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import createLessonService from 'services/lessons/createLessonService'

const useCreateLessonService = (params: any) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const { createLessonMutation } = createLessonService(params)
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)

  const createLesson = async (input: any, fun: any, onSuccess?: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const topicId = input.topic
    delete input.topic

    const { errors } = await createLessonMutation({
      variables: {
        topicId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      onSuccess && onSuccess()

      message = `${t('general.lesson')} ${t('messages.successfully')} ${t(
        'general.created',
      )}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    fun()
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }
  return {
    createLesson,
    loading,
  }
}

export default useCreateLessonService
