import React from 'react'
import { Action } from './styled-components'

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import ViewCompactIcon from '@mui/icons-material/ViewCompact'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import { TFunction } from 'interfaces/TFunction'

export const actionConfig = (
  t: TFunction,
  actionHandler?: any,
  canModify?: boolean,
) => {
  const action = [
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('edit', item)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}

export const questionActionsConfig = (
  handleCreateQuestionClick: (moduleId: string, name: string) => void,
  handleLinkQuestionToModuleClick: (moduleId: string, name: string) => void,
  t: TFunction,
) => {
  const action = [
    {
      render: (item: any) => (
        <Action
          onClick={() => handleCreateQuestionClick(item.moduleId, item.name)}
        >
          <AddCircleRoundedIcon />
          <span>{t('questions_layout.create_question')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action
          onClick={() =>
            handleLinkQuestionToModuleClick(item.moduleId, item.name)
          }
        >
          <PlaylistAddIcon />
          <span>{t('questions_layout.link_question_to_module')}</span>
        </Action>
      ),
    },
  ]

  return action
}

export const quizQuestionActionsConfig = (
  t: TFunction,
  canModify?: boolean,
  actionHandler?: any,
) => {
  const action = [
    {
      hide: false,
      render: (item: any) => (
        <Action onClick={() => actionHandler('view', item)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('deleteQuestion', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.remove_question_from_quiz')}</span>
        </Action>
      ),
    },
  ].filter((i: any) => !i.hide)

  return action
}
