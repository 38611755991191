import React from 'react'
import { SplashScreen, TextInput } from 'components/common'
import { BooksGrid, ToolbarContainer } from './styled-components'
import BookItem from 'pages/books/BookItem/BookItem'
import { ToolbarItem } from 'components/common/Toolbar'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import { Group } from './GroupBooks.interface'
import useGroupBooks from 'hooks/books/useGroupBooks/useGroupBooks'

const GroupBooks = ({ group }: { group: Group }): JSX.Element => {
  const {
    t,
    books,
    createButton,
    searchValue,
    onSearchSubmit,
    handleSearchChange,
    groupId,
    loading,
  } = useGroupBooks({ group })

  if (books?.data?.length === 0 && !searchValue) {
    return (
      <SplashScreen
        title={t('books_layout.no_books')}
        marginTop={20}
        subTitle={t('books_layout.create_books')}
        createButton={createButton}
      />
    )
  }

  return (
    <>
      <>
        <ToolbarContainer>
          <ToolbarItem>
            <div>{createButton()}</div>
          </ToolbarItem>
          <ToolbarItem>
            <form onSubmit={onSearchSubmit}>
              <TextInput
                label={t('general.search_placeholder')}
                type="text"
                size="small"
                value={searchValue}
                onChange={handleSearchChange}
                icon={
                  <IconButton type="submit">
                    <SearchRoundedIcon />
                  </IconButton>
                }
                iconInStart
              />
            </form>
          </ToolbarItem>
        </ToolbarContainer>
      </>
      <>
        {books?.data?.length ? (
          <BooksGrid>
            {books?.data?.map((book) => (
              <BookItem
                key={book.id}
                groupId={groupId}
                book={book}
                setIsSearchType={() => {}}
                enableGroupActions={true}
              />
            ))}
          </BooksGrid>
        ) : (
          !loading && <SplashScreen subTitle={t('books_layout.no_book')} />
        )}
      </>
    </>
  )
}

export default GroupBooks
