import React, { useState, useEffect } from 'react'
import {
  CenterParagraph,
  SelectorContainer,
  StudentProfile,
  StudentsAnalyticsProfile,
  Wrapper,
} from './styled-components'
import { SelectField } from 'components/common/SelectField'
import { useTranslation } from 'react-i18next'
import { StudentsAnalyticsProps } from './StudentsAnalytics.interface'
import { useFetchStudents } from 'hooks/users/useFetchStudents'
import { User } from 'interfaces/users'
import UserTabs from 'pages/profile/AllUserProfile/UserTabs'
import { GET_COURSE } from 'gql/course/course.query'
import ProfileIcon from 'pages/profile/AdminProfile/icons/profile-picture.svg'
import { useQuery } from '@apollo/client'
import { AvatarContainer } from 'pages/notifications/styled-components'
import { AnalyticsStudentOption } from '../analytics.interface'
import { InputContainer, SelectFieldWrapper } from '../Groups/styled-components'

const StudentsAnalytics = ({
  companyId,
  groupsLoading,
  coursesLoading,
  onCourseChange,
  onGroupChange,
  coursesOptions,
  groupsOptions,
  selectedCourse,
  selectedGroup,
  user,
}: StudentsAnalyticsProps) => {
  const { t } = useTranslation()
  const [studentsFilter, setStudentsFilter] = useState<{}>({})
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [userData, setUserData] = useState<User | null>(null)
  const [userProgress, setUserProgress] = useState<any>(null)
  const [selectedStudent, setSelectedStudent] =
    useState<AnalyticsStudentOption | null>(null)

  const [studentsOptions, setStudentsOptions] = useState<
    AnalyticsStudentOption[]
  >([])

  const onStudentChange = (student: { value: string; label: string }) => {
    setSelectedStudent(student)
  }

  const { users, loading } = useFetchStudents(studentsFilter, 1, 0, companyId)

  useEffect(() => {
    if (users) {
      const options = users.data.map((user: User) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }))
      setStudentsOptions(options)
    }
  }, [users, selectedGroup])

  useEffect(() => {
    setSelectedStudent(null)
    if (selectedGroup && selectedGroup.value) {
      setStudentsFilter({
        group: {
          type: 'nestedArrayIn',
          value: [selectedGroup.value],
          nestedField: 'groupId',
        },
      })
    } else {
      setStudentsOptions([])
    }
  }, [selectedGroup])

  const { data: courseData } = useQuery(GET_COURSE, {
    variables: {
      courseId: selectedCourse?.value,
    },
    skip: !selectedCourse,
  })

  const course = courseData && courseData.getCourseById

  useEffect(() => {
    if (!(selectedGroup && studentsOptions)) return
    if (user) {
      const student = studentsOptions.find(
        (student) => student.value === user.id,
      )
      if (student) setSelectedStudent(student)
      else setSelectedStudent(null)
    }
  }, [selectedGroup, studentsOptions, user])

  return (
    <Wrapper>
      <InputContainer>
        <SelectorContainer>
          <SelectFieldWrapper>
            <SelectField
              placeholder={t('analytics.searchByCourse')}
              value={selectedCourse}
              options={coursesOptions}
              onChange={onCourseChange}
              isLoading={coursesLoading}
              isClearable
            />
          </SelectFieldWrapper>
          <SelectFieldWrapper>
            <SelectField
              placeholder={t('analytics.searchByGroup')}
              value={selectedGroup}
              options={groupsOptions}
              onChange={onGroupChange}
              isClearable
              isDisabled={!selectedCourse?.value}
              isLoading={groupsLoading}
            />
          </SelectFieldWrapper>
          <SelectFieldWrapper>
            <SelectField
              placeholder={t('analytics.searchByStudent')}
              value={selectedStudent}
              options={studentsOptions}
              onChange={onStudentChange}
              isClearable
              isDisabled={!selectedGroup}
              isLoading={loading}
            />
          </SelectFieldWrapper>
        </SelectorContainer>
      </InputContainer>
      {selectedCourse?.value &&
      selectedGroup?.value &&
      selectedStudent?.value &&
      course ? (
        <>
          {userData && userProgress && (
            <StudentsAnalyticsProfile>
              <StudentProfile>
                <AvatarContainer>
                  <img
                    src={
                      userData.avatarThumbnail
                        ? userData.avatarThumbnail
                        : ProfileIcon
                    }
                  />
                </AvatarContainer>
                {userData.firstName + ' ' + userData.lastName}
              </StudentProfile>
            </StudentsAnalyticsProfile>
          )}
          <UserTabs
            courseInfo={course}
            currentTab={currentTab}
            handleTabChange={setCurrentTab}
            userId={selectedStudent.value}
            setUserData={setUserData}
            setUserProgress={setUserProgress}
          />
        </>
      ) : (
        <CenterParagraph>{t('analytics.selectStudent')}</CenterParagraph>
      )}
    </Wrapper>
  )
}

export default StudentsAnalytics
