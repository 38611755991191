import { useTranslation } from 'react-i18next'
import useEditGroup from 'hooks/group/useEditGroup'
import {
  AssignCoachReturnType,
  ITutor,
  ValuesType,
} from 'hooks/course/addCourseDrawer/assignCoach/AssignCoach.interface'
import {
  useAvailableTutorsService,
  useGroupsDetailsService,
} from 'services/course/assigncoach/assignCoachService'

const useAssignCoach = (
  courseId: string,
  values: ValuesType,
): AssignCoachReturnType => {
  const { t } = useTranslation()
  const { assignCoach } = useEditGroup()

  const { data: groupsData, loading: groupsLoading } =
    useGroupsDetailsService(courseId)
  const { data: tutorsData, loading: tutorsLoading } =
    useAvailableTutorsService(courseId)

  const groups = (groupsData && groupsData.getGroupsDetails) || []
  const courseCoachesMapped = tutorsData?.getAvailableTutors?.map(
    (i: ITutor) =>
      ({
        label: `${i.firstName} ${i.lastName}`,
        value: i.id,
      } || []),
  )

  const coaches = courseCoachesMapped || values.coaches

  return {
    t,
    assignCoach,
    loading: groupsLoading,
    tutorsLoading,
    groups,
    coaches,
  }
}

export default useAssignCoach
