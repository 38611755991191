import React from 'react'
import styled from 'styled-components'

export const Grid = (props: any) => (
  <StyledGrid {...props}>{props.children}</StyledGrid>
)

export const Container = styled.div`
  margin-top: 20px;
`
export const test = styled.div``

export const GridSwitchButtons = styled.div`
  display: flex;
  align-items: center;

  button {
    cursor: pointer;
    background: #eee;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 5px;
    transition: background 0.2s ease, color 0.2s ease;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover,
    &.active {
      background: #06c68f;
      color: #fff;
    }
  }
`

const StyledGrid = styled.div<{
  cols: number
  responsiveCols: boolean
  noPadding?: boolean
}>`
  display: grid;
  grid-gap: 20px;

  .wrapper {
    width: 100%;
    height: 100%;
    box-shadow: 0 10px 20px -15px rgba(0, 0, 0, 0.5);
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
  }

  &.card {
    ${({ cols, responsiveCols }) => {
      const colsNumber = cols || 4
      return responsiveCols
        ? ` grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));`
        : `grid-template-columns: repeat(${colsNumber}, 1fr); @media (max-width: 1450px) {
          grid-template-columns: repeat(3, 1fr);
        }`
    }}

    a > div {
      flex-direction: column;
    }

    .wrapper > div {
      flex-direction: column;
    }
  }

  &.list {
    grid-template-columns: 1fr;
    a > div {
      flex-direction: row;
    }
    .wrapper > div {
      flex-direction: row;
    }

    figure {
      width: 200px;
      max-height: 130px;
    }

    section {
      width: calc(100% - 200px);
      ${({ noPadding }) => !noPadding && 'padding: 10px 20px;'}
    }
  }
`
export const GridItem = styled.div<{ isFromUser?: boolean }>`
  display: flex;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.2);
    }

    h3 {
      color: #06c68f;
    }
  }

  ${({ isFromUser }) =>
    isFromUser &&
    `
  figure {
    position: relative;
    &:after {
      content: 'View Analytics';
      position: absolute;
      top: 0;
      opacity: 0;
      color: #06c68f;
      left: 0;
      font-size: 24px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.9);
      width: 100%;
      height: 100%;
      transition: opacity 0.3s ease;
      z-index: 10;
    }
  }

  &:hover {
    figure {
      &:after {
        opacity: 1;
      }
    }
  }
  `}
`
export const GridItemImage = styled.figure`
  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    transition: transform 0.3s ease;
  }
`
export const GridItemContent = styled.section`
  position: relative;
  z-index: 100;
  font-size: 16px;
  color: #333;
  padding: 16px;
`
export const GridItemContentHeader = styled.header`
  display: flex;
  justify-content: space-between;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const GridItemTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;

  transition: color 0.3s ease;
`
export const GridItemDate = styled.span`
  font-size: 12px;
  font-style: italic;
`

export const GridItemLessons = styled.div`
  margin-top: auto;
  display: flex;
  gap: 10px;
  padding-bottom: 16px;
  align-self: flex-end;
  width: 100%;
  flex-direction: row !important;
  padding-left: 16px;

  > div {
    font-size: 12px;
    position: relative;
    padding-left: 12px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);
    }

    :not(:last-child) {
      margin-right: 10px;
    }
  }
`
export const StylesCardWrapper = styled.div`
  position: relative;
  &:hover {
    .action_block {
      background: rgba(255, 255, 255, 0.59) !important;
    }
  }
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`

export const ListItem = styled.div<{ variant: 'small' | 'large' }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  background: #f9f9f9;
  border-left: 5px solid rgba(2, 191, 131, 0.5);
  border-right: 5px solid rgba(2, 191, 131, 0.5);
  height: ${({ variant }) => (variant === 'small' ? '60px' : '100px')};
  font-weight: 300;
  box-shadow: 0px 0px 7px 2px rgb(0 0 0 / 7%);
`

export const ListItemImage = styled.figure<{ variant: 'small' | 'large' }>`
  width: ${({ variant }) => (variant === 'small' ? '150px' : '200px')};
  height: ${({ variant }) => (variant === 'small' ? '60px' : '100px')};
  overflow: hidden;
  border-radius: 16px;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    transition: transform 0.3s ease;
  }
`

export const ListItemContent = styled.section`
  position: relative;
  z-index: 100;
  font-size: 16px;
  color: #333;
  padding: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 30px;
`
