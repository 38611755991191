import { useMutation } from '@apollo/client'

import { DELETE_FLASHCARDS } from 'gql/flashcards.query'

const deleteFlashCardsService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteFlashCardsMutation, { loading, error }] = useMutation(
    DELETE_FLASHCARDS,
    // {
    //   update(cache, { data: { addFlashCard: document } }) {
    //     updateCache({
    //       objectName: 'flashCards',
    //       query: GET_FLASHCARDS,
    //       cache,
    //       document,
    //       action: 'add',
    //       params,
    //     })
    //   },
    // },
  )

  return {
    deleteFlashCardsMutation,
    loading,
    error,
  }
}

export default deleteFlashCardsService
