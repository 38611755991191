import { useMutation } from '@apollo/client'
import { EDIT_LESSON, GET_LESSON_BY_ID } from 'gql/lesson/lesson.query'

const editLessonService = (variables: { lessonId: string }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editLessonMutation, { loading }] = useMutation(EDIT_LESSON, {
    refetchQueries: [
      {
        query: GET_LESSON_BY_ID,
        variables,
      },
    ],
  })

  return {
    editLessonMutation,
    loading,
  }
}

export default editLessonService
