import styled, { createGlobalStyle } from 'styled-components'

export const Label = styled.h3`
  font-weight: 700;
  padding-bottom: 10px;
`

export const SubTitle = styled.p`
  margin-bottom: 5px;
`

export const SubLabel = styled.p`
  font-weight: 700;
  text-transform: capitalize;
  margin: 0px;
`

export const CoverImageContainer = styled.div`
  width: 100%;
  height: 300px;
  left: 0;
  position: absolute;
  top: 80px;
`

export const ContainerContent = styled.div`
  padding: 30px;
  position: relative;
  z-index: 2;
  padding: 2px;
  border-radius: 4px;
`
export const TestName = styled.h1`
  font-size: 24px;
  word-wrap: break-word;
  width: 70%;
  overflow-wrap: break-word;
  font-weight: 600;
  margin: 10px;
`

export const WhiteBg = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  top: 120px;
  background-color: #fff;
  left: 0;
  margin-right: 20px;
  z-index: -1;
  transition: all 0.5s ease;
`

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 0;
  padding: 7px;
  border-bottom: 1px solid #e0e0e0;
  align-items: middle;
`
export const DetailsHeaderRight = styled.div`
  display: flex;
  align-items: middle;
`

export const MoreButton = styled.div`
  margin-left: auto;
  display: flex;
  position: relative;
  transition: all 0.5s ease;

  &:hover {
    cursor: pointer;
    background: #f2f2f2;
    opacity: 0.8;
    border-radius: 30px;
    height: 40px;
  }
`

export const DetailsContentGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`
export const Overview = styled.div`
  border-right: 1px solid #e0e0e0;
  margin-top: 15px;
  width: 33%;
  padding: 20px;
  overflow: hidden;
  margin-bottom: 15px;
  padding-left: 15px;
  margin-left: 3px;

  p {
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #353543;
    font-family: 'Open Sans', sans-serif;
  }
`

export const DetailsContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
`
export const DetailsContainerItem = styled.div`
  position: relative;
`

export const Details = styled.div`
  border-right: 1px solid #e0e0e0;
  margin-top: 15px;
  width: 33%;
  padding: 20px;
  margin-bottom: 15px;
`
export const Tags = styled.div`
  width: 33%;
  margin-top: 15px;
  padding: 20px;
  margin-bottom: 15px;
`
export const TagsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const TabsContainer = styled.div`
  background-color: #ffffff;
  margin-block: 20px 10px;
  border-radius: 5px;
`
export const PublishIndicator = styled.div<{
  published: boolean
}>`
  ${({ published }) =>
    published ? 'background-color: #06c68f;' : 'background-color: #ea382a;'}
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  height: 37px;
  border-radius: 5px;
  position: relative;
  top: 2px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
`
