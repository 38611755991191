import { GET_TASK_BY_ID } from 'gql/tasks.query'
import { useQuery } from '@apollo/client'

export const useFetchTaskById = (taskId?: string) => {
  const { data, loading, error, refetch } = useQuery(GET_TASK_BY_ID, {
    variables: {
      taskId,
    },
    fetchPolicy: 'cache-first',
    skip: !taskId,
  })

  return {
    task: data && data.getTaskById,
    loading,
    error,
    taskRefetch: refetch,
  }
}
