import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

export const ReRunHlsConversion = gql`
  mutation ReRunHlsConversion($lessonId: String) {
    reRunHlsConversion(lessonId: $lessonId)
  }
`

interface IResponse {
  handleReConversion: (lessonId: string) => Promise<void>
}

export const useReConversion = (): IResponse => {
  const [mutate] = useMutation(ReRunHlsConversion)

  const handleReConversion = async (lessonId: string): Promise<void> => {
    await mutate({
      variables: {
        lessonId,
      },
    })
  }

  return { handleReConversion }
}
