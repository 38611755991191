import React from 'react'
import { Container, ErrorMessage } from './styled-components'
import Select from 'react-select'

const SelectAsync = (props: any) => {
  const newData =
    props?.data &&
    props.data.length > 0 &&
    props.data.map((i: any) => ({ label: i.name, value: i.id }))

  return (
    <Container error={props.error && props.touched}>
      <Select
        isLoading={props.loading}
        isMulti={props.isMulti}
        options={newData || []}
        value={props.value}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        placeholder={props.label}
        isDisabled={props.disabled}
        isClearable={props.isClearable}
      />

      {props.touched && props.error && props.errorMessage && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </Container>
  )
}

export default SelectAsync
