import React from 'react'
import { Action } from './styledComponent'
import Tooltip from '@mui/material/Tooltip'
import { useHelperChangeUserStatus } from '../useHelperChangeUserStatus/useHelperChangeUserStatus'
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg'
import { ReactComponent as CloseIcon } from 'assets/close-outline.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/checkmark-outline.svg'
import { ReactComponent as DownloadIcon } from 'assets/download.svg'
import CircleNotificationsIcon from '@mui/icons-material/Notifications'
import { useTranslation } from 'react-i18next'
import formatRoleForTranslation, { RoleFormats } from 'helpers/formatRole'
import { useSwal } from 'hooks/useSwal'
import {
  IAction,
  IMultiActionsReturn,
  IUseHelperUsersActionsParams,
  IUseHelperUsersActionsReturn,
} from './useHelperUsersActions.interface'

export const useHelperUsersActions = ({
  handleDeleteAllClick,
  currentPage,
  selectedItem,
  field,
  state,
  ROLE,
  defaultFilter,
  setSelectedItem,
  handleExportData,
  setPushNotificationModal,
}: IUseHelperUsersActionsParams): IUseHelperUsersActionsReturn => {
  const { confirmChangeStatusMany } = useHelperChangeUserStatus({
    ROLE,
    selectedItem,
    currentPage,
    defaultFilter,
    setSelectedItem,
  })
  const { t } = useTranslation()
  const { fireSwal } = useSwal()

  const handleChangeStatusAllClick = (action: string): void => {
    let title = ''
    let newStatus = ''
    let confirmText = ''

    if (action === 'suspend') {
      title = `${t('popups.suspend_many')} ${t(
        formatRoleForTranslation(ROLE, RoleFormats.PLURAL),
      )}?`
      newStatus = 'SUSPENDED'
      confirmText = t('popups.confirm_suspend')
    } else {
      title = `${t('popups.activate_many')} ${t(
        formatRoleForTranslation(ROLE, RoleFormats.PLURAL),
      )}?`
      newStatus = 'ACTIVE'
      confirmText = t('popups.confirm_activate')
    }

    fireSwal({
      title,
      onConfirm: () => confirmChangeStatusMany(newStatus),
      confirmText,
    })
  }

  const multiActions = (): IMultiActionsReturn[] => {
    const disabledAction = selectedItem[currentPage]
      ? selectedItem[currentPage].length < 1
      : true
    const actions: IAction[] = [
      {
        id: 0,
        color: 'secondary',
        // tooltipText: `${t('actions.delete')}`,
        hide: !state.userPermission[`delete${field}`],
        disabled: disabledAction,
        onClick: () => handleDeleteAllClick(),
        component: (
          <Action hoverColor="#EA382A">
            <Tooltip title={t('actions.delete')} arrow>
              <DeleteIcon />
            </Tooltip>
          </Action>
        ),
      },
      {
        id: 1,
        color: 'secondary',
        // tooltipText: `${t('actions.suspend')}`,
        hide: !state.userPermission[`suspend${field}`],
        disabled: disabledAction,
        onClick: () => handleChangeStatusAllClick('suspend'),
        component: (
          <Action hoverColor="#EA382A">
            <Tooltip title={t('actions.suspend')} arrow>
              <CloseIcon />
            </Tooltip>
          </Action>
        ),
      },
      {
        id: 2,
        color: 'primary',
        // tooltipText: `${t('checkmark')}`,
        disabled: disabledAction,
        hide: !state.userPermission[`suspend${field}`],
        onClick: () => handleChangeStatusAllClick('activate'),
        component: (
          <Action hoverColor="#00905E">
            <Tooltip title={t('actions.activate')} arrow>
              <CheckmarkIcon />
            </Tooltip>
          </Action>
        ),
      },
      {
        id: 10,
        color: 'primary',
        // tooltipText: `${t('actions.download')}`,
        disabled: disabledAction,
        onClick: () => handleExportData(),
        component: (
          <Action hoverColor="#3892F4">
            <Tooltip title={t('actions.download')} arrow>
              <DownloadIcon />
            </Tooltip>
          </Action>
        ),
      },

      {
        hide: ROLE !== 'COACH',
        id: 3,
        color: 'primary',
        tooltipText: `${t('actions.send_push_notification')}`,
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => setPushNotificationModal(true),
        component: (
          <Action hoverColor="#1976d2">
            <CircleNotificationsIcon
              style={{
                color: 'rgb(179,179, 184)',
              }}
              fontSize="small"
            />
          </Action>
        ),
      },
    ].filter((i: any) => !i.admin)

    return actions.filter((i: IAction) => !i.hide)
  }
  return { multiActions }
}
