import styled, { css } from 'styled-components'

export interface StyledInputProps {
  $paddingLeft?: boolean
  $paddingRight?: boolean
  $valueFilled?: boolean
}

const baseInputStyles = css<StyledInputProps>`
  color: #020210;
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  transition: border 0.3s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  border: 1px solid var(--level-dark-200);
  height: 100%;
  outline: 1px solid transparent;

  font-family: 'Open Sans', sans-serif;

  &:hover {
    border: 1px solid var(--neutral-colors-dark);
  }

  &:focus {
    outline: 1px solid var(--system-colors-informative);
    border: 1px solid var(--system-colors-informative);
  }
`

const baseInputLabelStyles = css`
  position: relative;
  color: var(--neutral-colors-dark);
  flex-direction: column;
  display: flex;
  gap: 8px;
  height: 100%;
  text-transform: capitalize;
`

export const StyledInputWrapper = styled.div`
  height: fit-content;
  width: 100%;
`

export const StyledInput = styled.input<StyledInputProps>`
  ${baseInputStyles}
  padding-left: ${({ $paddingLeft }): string =>
    $paddingLeft ? '56px' : '16px'};
  padding-right: ${({ $paddingRight, $valueFilled }): string =>
    $valueFilled && $paddingRight
      ? '88px'
      : $valueFilled
      ? '48px'
      : $paddingRight
      ? '56px'
      : '16px'};
  height: 48px;
`

export const StyledTextarea = styled.textarea<StyledInputProps>`
  ${baseInputStyles}
  resize: none;
`

export const StyledInputLabel = styled.label`
  ${baseInputLabelStyles}

  & > svg {
    width: 24px;
    height: 24px;
    bottom: 11px;
    flex-shrink: 0;
  }

  & > svg:first-child {
    position: absolute;
    left: 16px;
  }

  & > svg:last-child {
    position: absolute;
    right: 16px;
  }

  .showPasswordIcon {
    transition: 0.3s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    cursor: pointer;
  }

  .showPasswordIcon:hover {
    opacity: 0.9;
  }

  .clearTextIcon {
    position: absolute;
    right: 56px;
    width: 16px;
    height: 16px;
    bottom: 15px;
    transition: 0.3s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    cursor: pointer;
  }
`

export const StyledTextareaLabel = styled.label`
  ${baseInputLabelStyles}
`
