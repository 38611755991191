import { useQuery } from '@apollo/client'
import { GET_CURRENT_USER } from 'gql/users.query'

export const useGetCurrentUser = () => {
  const { data, loading, error, refetch } = useQuery(GET_CURRENT_USER)
  return {
    currentUser: data?.currentUserForAdmin || {},
    loading,
    error,
    refetch,
  }
}
