import { EventEmitter } from 'fbemitter'

export const DrawerEventEmitter = new EventEmitter()

export function showDrawer(event, arg) {
  DrawerEventEmitter.emit(event, arg)
}

export function dismissDrawer(event) {
  DrawerEventEmitter.emit(event)
}
