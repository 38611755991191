import React from 'react'
import moment from 'moment'
import {
  Container,
  HeaderContainer,
  ItemContainer,
  ContentContainer,
  FooterContainer,
  NotificationList,
  NotificationListItem,
  AvatarContainer,
  ItemListContainer,
  ItemContent,
  NameContainer,
  EventContainer,
  DateContainer,
  TitleContainer,
  UserRole,
  NoDataWrapper,
  MarkAllAsReadContainer,
  ClearAllContainer,
  ViewAllContainer,
} from './styled-components'

import strCutter from 'utils/strCutter'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import ProfileIcon from 'pages/profile/AdminProfile/icons/profile-picture.svg'

import { NotificationMenuProps } from './notificationMenu.interface'
import useNotificationMenuLogic from 'hooks/notifications/useNotificationMenuServices'
import { formatDateAgo, generateFullName } from 'utils/utils'

const NotificationMenu = ({
  onClose,
  notificationBadgeRefetch,
}: NotificationMenuProps) => {
  const {
    readNotification,
    fetchNotificationData,
    deleteNotifications,
    notificationList,
    renderTitle,
    handleOpenNotification,
    t,
  } = useNotificationMenuLogic({ onClose, notificationBadgeRefetch })
  return (
    <Container>
      <HeaderContainer>
        <MarkAllAsReadContainer
          onClick={() => readNotification([], 'all', fetchNotificationData)}
          disabled={!notificationList.length}
        >
          {t('notifications.mark_all_as_read')}
        </MarkAllAsReadContainer>

        <ClearAllContainer
          onClick={() => deleteNotifications([], 'all', fetchNotificationData)}
          disabled={!notificationList.length}
        >
          {t('notifications.clear_all')}
        </ClearAllContainer>
      </HeaderContainer>

      <ContentContainer>
        <NotificationList>
          {notificationList.map((notification) => (
            <NotificationListItem
              key={notification.id}
              state={notification.state}
            >
              <ItemListContainer>
                <AvatarContainer>
                  {notification.objectName === 'Rejection' ? (
                    <ReportProblemIcon />
                  ) : (
                    <img src={notification.owner?.avatar || ProfileIcon} />
                  )}
                </AvatarContainer>

                <ItemContent>
                  <TitleContainer>
                    <UserRole>{renderTitle(notification)}</UserRole>
                    <NameContainer>
                      {generateFullName(notification.owner)}
                    </NameContainer>
                  </TitleContainer>
                  <EventContainer>
                    <span>{notification.message}</span>
                    {notification.objectName !== 'Rejection' && (
                      <NameContainer>
                        {strCutter(notification.actionName, 10)}
                      </NameContainer>
                    )}
                    {notification.additionalText && (
                      <span>{notification.additionalText}</span>
                    )}
                  </EventContainer>
                </ItemContent>

                <DateContainer>
                  {formatDateAgo(
                    moment(parseInt(notification.createDate)).format(
                      'DD MMM YYYY h:mm A',
                    ),
                  )}
                </DateContainer>
              </ItemListContainer>
            </NotificationListItem>
          ))}
        </NotificationList>
        {notificationList.length === 0 && (
          <NoDataWrapper>No Notifications...</NoDataWrapper>
        )}
      </ContentContainer>

      <FooterContainer>
        <ViewAllContainer
          onClick={handleOpenNotification}
          disabled={!notificationList.length}
        >
          {t('notifications.view_all')}
        </ViewAllContainer>
      </FooterContainer>
    </Container>
  )
}

export default NotificationMenu
