import { useQuery } from '@apollo/client'

import { GET_COMPANY_TUTORS } from 'gql/users.query'

export const PER_PAGE = 10

export const useFetchCompanyTutors = (
  filter: any = {},
  currentPage = 1,
  perPage: number = PER_PAGE,
  companyId?: string,
) => {
  const { data, loading, error, refetch } = useQuery(GET_COMPANY_TUTORS, {
    variables: {
      companyId,
      filter,
      currentPage,
      perPage,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.getCompanyTutors,
    loading,
    error: errorMessage,
    refetch,
  }
}
