import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import { DndItemProps, DragItem } from './types'
import TableRow from '@mui/material/TableRow'

const DndItemV2: React.FC<DndItemProps> = ({
  id,
  index,
  moveCard,
  cardIds = [],
  isTable = false,
  isNested = false,
  className = '',
  children,
  type = 'CARD',
  onEnd = () => {},
}) => {
  const ref = useRef<any>(null)

  const [, drop] = useDrop({
    accept: type,
    hover: (item: DragItem, monitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
      item.hoverIndex = dragIndex
    },
  })

  const [{ isDragging }, drag] = useDrag<any, void, { isDragging: boolean }>({
    type: type,
    item: { id, index, type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: async (item: any) => {
      const diff: number = item.hoverIndex - item.index
      let keyIndex: number | null = null

      if (Math.abs(diff) === 1) keyIndex = item.hoverIndex
      else if (diff > 0) keyIndex = item.index + 1
      else if (diff < 0) keyIndex = item.index - 1

      const droppedId =
        cardIds.find((i: string, ind: number) => ind === keyIndex) || item.id

      if (droppedId !== item.id) {
        isNested
          ? onEnd(item.id, droppedId)
          : onEnd(item.id, droppedId || item.id)
      }
    },
  })
  const styles = {
    opacity: isDragging ? 0.4 : 1,
  }

  drag(drop(ref))

  return isTable ? (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={id}
      ref={ref}
      style={styles}
      className={className}
    >
      {children}
    </TableRow>
  ) : (
    <div
      style={styles}
      onDragStart={(e) => {
        console.log('drag start')
      }}
      key={id}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default DndItemV2
