import React from 'react'

import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import AddCircleIcon from '@mui/icons-material/Add'
import {
  DetailsItemContainer,
  DetailsItemWrapper,
  DetailsItem,
  Header,
  Wrapper,
  LayoutWrapper,
  RightContainer,
  CourseContentDetailsContainerFlex,
  QuizContentDetailsContainer,
  QuizContentHeader,
  StyledCourseDetailsBetweenParent,
  StyledCourseDetailsLeft,
  StyledCourseDetailsLeftParent,
  styles,
  TopLayoutWrapper,
} from './styled-components'
import { CourseTitle } from 'components/courseDetail/styled-component'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { Button } from 'components/common/Button'
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg'
import _ from 'lodash'
import TextInput from 'components/common/TextInput/TextInput'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Drawer from 'components/common/Drawer'
import FilterDrawer from 'pages/questions/FilterDrawer'
import Grid from 'components/common/GridV2'
import Breadcrumb from 'components/common/BreadCrumbV2'
import {
  GridLayoutActions,
  StudentsActionsContainer,
} from 'components/common/GridV2/styled-components'
import {} from '../styled-components'
import useQuizDetails from 'hooks/quizzes/QuizDetails/useQuizDetails'
import { capitalizeFirstLetter } from 'helpers/capitalLetter'
import TextEditor from 'components/common/TextEditor/TextEditor'

// const CHANGE_ORDER = gql`
//   mutation ($draggedId: String!, $droppedId: String!) {
//     changeQuestionsOrder(draggedId: $draggedId, droppedId: $droppedId)
//   }
// `
interface CurrentModuleParams {
  moduleId: string
  name: string
  courses: {
    courseId: string
    name: string
  }[]
}

export interface SelectedItemsMap {
  [page: number]: string[]
}

interface Params {
  manualCompanyId?: string
  currentModule?: CurrentModuleParams | undefined
}

interface IActionItem {
  hide?: boolean
  id: number
  color: string
  tooltipText: string
  disabled: boolean
  onClick: () => void
  component: React.ReactNode
}

const QuizDetails = ({
  currentModule,
  manualCompanyId,
}: Params): React.JSX.Element => {
  const {
    breadCrumbData,
    quizData,
    state,
    actions,
    t,
    addQuestions,
    selectedItem,
    handleDeleteQuestions,
    isFiltered,
    setFilterDrawerOpened,
    onSearchSubmit,
    searchValue,
    handleSearchChange,
    questionActions,
    config,
    filteredQuestionList,
    cards,
    currentPage,
    selectAllItem,
    selectItem,
    redirectToQuestion,
    filterDrawerOpened,
    setIsFiltered,
    filterData,
    setFilterData,
    setFilteredQuestionList,
    setCurrentPage,
    canModify,
    setFiltered,
  } = useQuizDetails({ currentModule, manualCompanyId })

  return (
    <>
      <TopLayoutWrapper style={{ marginBottom: '24px' }}>
        <Breadcrumb data={breadCrumbData} />
        <QuizContentDetailsContainer>
          <QuizContentHeader>
            <CourseTitle>{quizData.name}</CourseTitle>
            {/* <div>
              {quizData.tags &&
                quizData.tags.map((i: any) => <span key="">{i.label}, </span>)}
            </div> */}
            <div
              style={{
                textAlign: 'right',
                display: 'inline-flex',
              }}
            >
              {canModify && (
                <GridActionMenu actionConfig={actions} row={quizData} />
              )}
            </div>
          </QuizContentHeader>
          <CourseContentDetailsContainerFlex>
            <StyledCourseDetailsLeftParent>
              <h4 style={styles.detailHeader}>{t('general.overview')}</h4>

              <StyledCourseDetailsLeft>
                <TextEditor
                  isVisible={false}
                  text={quizData.description}
                  hasControls={false}
                  handleChange={null}
                  defaultText={t('default_texts.transcript')}
                />
              </StyledCourseDetailsLeft>
              <StyledCourseDetailsLeft>
                {/* <span>{quizData.description}</span> */}
              </StyledCourseDetailsLeft>
            </StyledCourseDetailsLeftParent>
            <StyledCourseDetailsBetweenParent>
              <h4 style={styles.detailHeader}>{t('general.details')}</h4>

              <DetailsItemContainer>
                <DetailsItemWrapper>
                  <DetailsItem>
                    <p style={styles.detailTitle}>
                      {t('quiz_details.pass_rate')}
                    </p>{' '}
                    {quizData.passRate}%
                  </DetailsItem>
                  <DetailsItem>
                    <p style={styles.detailTitle}>
                      {t('quiz_details.randomize')} {t('general.questions')}:{' '}
                    </p>
                    {quizData.randomization
                      ? t('general.yes')
                      : t('general.no')}
                  </DetailsItem>
                </DetailsItemWrapper>

                <DetailsItemWrapper>
                  <DetailsItem>
                    <p style={styles.detailTitle}>
                      {t('quiz_details.participation')}:{' '}
                    </p>{' '}
                    {capitalizeFirstLetter(quizData.quizParticipationType)}
                  </DetailsItem>
                  <DetailsItem>
                    <p style={styles.detailTitle}>
                      {t('quiz_details.time_type')}
                    </p>
                    {capitalizeFirstLetter(quizData.timeType)}
                  </DetailsItem>
                </DetailsItemWrapper>

                <DetailsItemWrapper>
                  <DetailsItem>
                    <p style={styles.detailTitle}>
                      {t('quiz_details.time_in_minutes')}
                    </p>{' '}
                    {quizData.time}
                  </DetailsItem>
                  <DetailsItem>
                    <p style={styles.detailTitle}> Quiz</p>
                    {capitalizeFirstLetter(quizData.quizType)}
                  </DetailsItem>
                </DetailsItemWrapper>

                <DetailsItemWrapper>
                  <DetailsItem>
                    <p style={styles.detailTitle}>
                      {t('quiz_details.quiz_questions')}:{' '}
                    </p>{' '}
                    {(quizData.questions && quizData.questions.length) || 0}
                  </DetailsItem>
                </DetailsItemWrapper>
              </DetailsItemContainer>
            </StyledCourseDetailsBetweenParent>
          </CourseContentDetailsContainerFlex>
        </QuizContentDetailsContainer>
      </TopLayoutWrapper>

      <LayoutWrapper>
        <Header className={'withBorder'}>
          <Wrapper>
            <Wrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {canModify && state.userPermission.addQuestion && (
                  <Button
                    text={t('quiz_details.add_question')}
                    color="secondary"
                    onClick={addQuestions}
                    icon={<AddCircleIcon />}
                    background="#06C68F"
                  />
                )}
              </div>

              <GridLayoutActions>
                {canModify && (
                  <GridMultipleActions
                    selectedItems={Object.values(
                      selectedItem as Record<number, string[]>,
                    ).reduce((total, current) => total + current.length, 0)}
                    actions={[
                      {
                        hide: !state.userPermission.deleteQuiz,
                        id: 0,
                        color: 'secondary',
                        tooltipText: t('actions.delete'),
                        disabled:
                          Object.values(
                            selectedItem as Record<number, string[]>,
                          ).reduce(
                            (total, current) => total + current.length,
                            0,
                          ) < 1,
                        onClick: (): void => {
                          const allSelectedItems =
                            Object.values(selectedItem).flat()
                          handleDeleteQuestions(allSelectedItems)
                        },
                        component: <DeleteIcon fontSize="small" />,
                      },
                    ].filter((i: IActionItem) => !i.hide)}
                  />
                )}
              </GridLayoutActions>
            </Wrapper>
            <RightContainer>
              <MultipleAction>
                <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                  {isFiltered ? (
                    <RedFilterIcon color="#06C68F" />
                  ) : (
                    <FilterIcon />
                  )}
                </IconButton>
                <MultipleActionTooltip>
                  {t('actions.filters')}
                </MultipleActionTooltip>
              </MultipleAction>
              <form onSubmit={onSearchSubmit}>
                <TextInput
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchValue}
                  onChange={handleSearchChange}
                  icon={
                    <IconButton type="submit">
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                  iconInStart
                />
              </form>
            </RightContainer>
          </Wrapper>
        </Header>
        <Grid
          actionConfig={canModify && questionActions}
          actionTilesLength={4}
          config={config}
          data={filteredQuestionList || cards}
          selectedItems={selectedItem[currentPage] || []}
          selectAllItem={selectAllItem}
          selectItem={selectItem}
          selected={_.size(selectedItem[currentPage] || [])}
          redirectToDetailsHandler={redirectToQuestion}
          width={40}
          indexOfSpecificWidth={0}
          ActionsContainer={StudentsActionsContainer}
        />
        {/* <Pagination
          currentPage={questions ? questions.currentPage : 1}
          totalPages={questions ? questions.totalPages : 0}
          handleChange={handlePaginationClick}
        /> */}
      </LayoutWrapper>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={(): void => setFilterDrawerOpened(false)}
        totalWidth="600px"
      >
        <FilterDrawer
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          setIsFiltered={setIsFiltered}
          filterData={filterData}
          quizModel={currentModule ? 'module' : 'general'}
          setFilterData={setFilterData}
          // refetch={refetch}
          setFiltered={(): void => setFiltered(true)}
          questions={cards}
          setFilteredQuestionList={setFilteredQuestionList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>
    </>
  )
}
export default QuizDetails
