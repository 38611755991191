import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { useCreateQuestion } from 'hooks/questions/useCreateQuestion'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import { useUserValue } from 'context/UserContext'
import { QuestionSchema } from 'helpers/validationSchemas'
import { QuestionTypeEnums } from 'pages/questions/CreateQuestionDrawer/CreateQuestion.interface'
import { GET_TAGS } from 'gql/skilss.query'

interface Params {
  title: string
  currentModule: any
}

interface FileObject {
  type: 'document' | 'video' | 'audio' | 'image'
  link: string
  uploading: boolean
}

const useCreateQuestionDrawer = ({ title, currentModule }: Params): any => {
  const { t } = useTranslation()
  const [userState] = useUserValue()
  const { createQuestion, createQuestionLoading } = useCreateQuestion({
    type: currentModule.moduleId ? 'module' : '',
    id: currentModule.moduleId,
    filter: {},
  })

  const { data } = useQuery(GET_TAGS, {
    variables: {
      companyId: currentModule.manualCompanyId || userState.selectedCompany?.id,
    },
  })

  const tagList =
    (data &&
      data.getAllTags.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  // const { toggleDrawerConfirm } = useAction()
  const [state, dispatch] = useData()
  // const { fireSwal } = useSwal()
  const [linkModuleDrawerOpened, setLinkModuleDrawerOpened] =
    useState<boolean>(false)

  const [boolAnswer, setBoolAnswer] = useState<boolean>(true)
  const [choiceAnswers, setChoiceAnswers] = useState<string[]>([])
  const [multiAnswers, setMultiAnswers] = useState<string[]>([])
  const [selectedChoiceAnswer, setSelectedChoiceAnswer] = useState<any>(null)
  const [selectedMultiAnswers, setSelectedMultiAnswers] = useState<any>([])
  const [selectedModules, setSelectedModules] = useState<string[]>([])
  const [cropperOpen, setCropperOpen] = useState<boolean>(false)
  const [cropperImage, setCropperImage] = useState<string>('')
  const [uploadedFiles, setUploadedFiles] = useState<FileObject[]>([])
  // const [uploadVideoProgress, setUploadVideoProgress] = useState(0)
  const [file, setFile] = useState<any>(null)

  const { uploadImage } = useUploadImage()

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        question: '',
        type: {
          label: t('question_details.boolean'),
          value: QuestionTypeEnums.BOOLEAN,
        },
        tags: [],
        reason: '',
        isShuffleEnabled: false,
      },
      validationSchema: QuestionSchema,
      onSubmit(values) {
        let formValues: any = { ...values }

        if (createQuestionLoading) return

        if (selectedModules.length > 0) {
          formValues.modules = selectedModules
        }

        switch (values.type.value) {
          case QuestionTypeEnums.BOOLEAN:
            formValues.boolAnswer = boolAnswer
            break
          case QuestionTypeEnums.SINGLE:
            formValues.answers = choiceAnswers.map(
              (answer: any, index: number) => {
                return {
                  isCorrect: selectedChoiceAnswer == index ? true : false,
                  value: answer.value,
                }
              },
            )
            break
          case QuestionTypeEnums.MULTIPLE:
            formValues.answers = multiAnswers.map(
              (answer: any, index: number) => {
                return {
                  isCorrect: !!selectedMultiAnswers.includes(index),
                  value: answer.value,
                }
              },
            )
            break
        }

        formValues.tags = values.tags.map((tag: any) => {
          return { label: tag.label }
        })

        if (uploadedFiles.length > 0) {
          const obj: any = { documents: [], videos: [], audios: [], images: [] }

          uploadedFiles.map((item: any) => {
            const { type, ...args } = item

            if (type) {
              obj[type] = [...obj[type], args]
            }
          })

          formValues = {
            ...formValues,
            ...obj,
          }
        }
        formValues.videos = formValues.videos
          ? formValues.videos.map((video: { id: string; title: any }) => {
              return { id: video.id, title: video.title }
            })
          : []
        createQuestion(
          formValues,
          currentModule.manualCompanyId || userState.selectedCompany?.id,
          () => {
            closeDrawer()
            if (currentModule.refetch) {
              currentModule.refetch()
            }
          },
        )
      },
    })

  const closeDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'createQuestion', false)
  }

  const checkAnswers = (type: QuestionTypeEnums): any => {
    switch (type) {
      case QuestionTypeEnums.BOOLEAN:
        return true
      case QuestionTypeEnums.SINGLE:
        const emptyChoices = choiceAnswers.filter(
          (answer: any) => answer.value === '',
        )
        return choiceAnswers.length && !emptyChoices.length
      case QuestionTypeEnums.MULTIPLE:
        const emptyValues = multiAnswers.filter(
          (answer: any) => answer.value === '',
        )

        return multiAnswers.length && !emptyValues.length
    }
  }

  const checkSelectedAnswers = (type: QuestionTypeEnums): any => {
    switch (type) {
      case QuestionTypeEnums.BOOLEAN:
        return true
      case QuestionTypeEnums.SINGLE:
        return selectedChoiceAnswer !== null
      case QuestionTypeEnums.MULTIPLE:
        return selectedMultiAnswers.length > 1
    }
  }

  const handleTypeChange = (e: any): void => {
    setFieldValue('type', e)
  }

  const handleBoolChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    const convertedValue = value === 'true' ? true : false
    setBoolAnswer(convertedValue)
  }

  const handleAddAnswerClick = (type: QuestionTypeEnums): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers: any = [...choiceAnswers, { value: '' }]
        setChoiceAnswers(updatedChoiceAnswers)
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers: any = [...multiAnswers, { value: '' }]
        setMultiAnswers(updatedMultiAnswers)
    }
  }

  const handleRemoveAnswerClick = (
    type: QuestionTypeEnums,
    index: number,
  ): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers: any = [...choiceAnswers]
        updatedChoiceAnswers.splice(index, 1)
        setChoiceAnswers(updatedChoiceAnswers)

        if (index == selectedChoiceAnswer) {
          setSelectedChoiceAnswer(null)
        }
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers: any = [...multiAnswers]
        updatedMultiAnswers.splice(index, 1)
        setMultiAnswers(updatedMultiAnswers)

        if (selectedMultiAnswers.includes(index.toString())) {
          const ind = selectedMultiAnswers.indexOf(index.toString())
          const updatedMultiAnswers = [...selectedMultiAnswers]
          updatedMultiAnswers.splice(ind, 1)
          setSelectedMultiAnswers(updatedMultiAnswers)
        }
    }
  }

  const handleAnswerChange = (
    e: any,
    type: QuestionTypeEnums,
    index: number,
  ): void => {
    const { value } = e.target

    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers: any = [...choiceAnswers]
        updatedChoiceAnswers[index].value = value
        setChoiceAnswers(updatedChoiceAnswers)
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers: any = [...multiAnswers]
        updatedMultiAnswers[index].value = value
        setMultiAnswers(updatedMultiAnswers)
        return
    }
  }

  const handleSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: QuestionTypeEnums,
  ): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const { value } = e.target
        setSelectedChoiceAnswer(value)
        return
      case QuestionTypeEnums.MULTIPLE:
        const eventValue = e
        const index = selectedMultiAnswers.indexOf(eventValue)
        if (index > -1) {
          const updatedMultiAnswers = [...selectedMultiAnswers]
          updatedMultiAnswers.splice(index, 1)
          setSelectedMultiAnswers(updatedMultiAnswers)
        } else {
          setSelectedMultiAnswers([...selectedMultiAnswers, eventValue])
        }
    }
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: File): void => {
    setCropperImage('')
    const index = uploadedFiles.length
    setUploadedFiles([
      ...uploadedFiles,
      {
        type: 'image',
        link: '',
        uploading: true,
      },
    ])
    uploadImage(croppedFile, (field = 'questions/images'), (link: string) => {
      const updatedFiles = [...uploadedFiles]
      updatedFiles[index] = {
        type: 'image',
        link,
        uploading: false,
      }
      setUploadedFiles(updatedFiles)
    })
  }

  useEffect(() => {
    if (currentModule.moduleId) {
      const { manualCompanyId, ...moduleData } = currentModule
      setSelectedModules([moduleData])
    }
  }, [currentModule])

  useEffect(() => {
    if (file) {
      setUploadedFiles([...uploadedFiles, file])
    }
  }, [file])

  const handleCloseDrawer = (): void => {
    const { type, ...formData }: any = values
    formData.multiAnswers = multiAnswers
    formData.choiceAnswers = choiceAnswers
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'createQuestion',
        values: formData,
        compareTo: {},
      },
    })
  }
  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const fieldsValid = checkAnswers(values.type.value)
  const selectedValid = checkSelectedAnswers(values.type.value)

  const submitBtnDisabled = !values.question || !fieldsValid || !selectedValid

  return {
    createQuestionLoading,
    linkModuleDrawerOpened,
    setLinkModuleDrawerOpened,
    selectedModules,
    setSelectedModules,
    title,
    handleCloseDrawer,
    t,
    handleTypeChange,
    values,
    setFieldValue,
    tagList,
    setUploadedFiles,
    uploadedFiles,
    currentModule,
    errors,
    touched,
    handleChange,
    boolAnswer,
    choiceAnswers,
    multiAnswers,
    selectedChoiceAnswer,
    selectedMultiAnswers,
    handleSelect,
    handleBoolChange,
    handleAddAnswerClick,
    handleRemoveAnswerClick,
    handleAnswerChange,
    fieldsValid,
    selectedValid,
    submitBtnDisabled,
    cropperOpen,
    cropperModalToggle,
    cropperImage,
    handleCropSave,
    handleSubmit,
  }
}

export default useCreateQuestionDrawer
