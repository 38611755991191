import { useQuery } from '@apollo/client'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import {
  getCoursesByUserIdResponse,
  IFilterInterface,
  IUseCourseByUserIdResponse,
} from 'interfaces/courses/courses.interface'

export const PER_PAGE = 12

export const useCourseByUserId: getCoursesByUserIdResponse = (
  filter,
  currentPage = 1,
  perPage = PER_PAGE,
) => {
  const variables: IFilterInterface = { filter, currentPage, perPage }

  const { data, loading, error, refetch, fetchMore } = useQuery<
    IUseCourseByUserIdResponse,
    IFilterInterface
  >(GET_ALL_COURSE, {
    variables,
  })

  return {
    data: (data && data.getAllCourse) || [],
    loading,
    error: error?.message,
    refetch,
    fetchMore,
  }
}
