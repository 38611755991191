import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { TFunction, useTranslation } from 'react-i18next'

import AddCircleRoundedIcon from '@mui/icons-material/Add'

import { Button } from 'components/common'
import { useSwal } from 'hooks/useSwal'
import { useDeleteFileService } from 'services/assistant/file/deleteFileService'
import { getCourseById } from 'services/course/course/getCourseById'
import { getAssistantFiles } from 'services/assistant/file/getAssistantFiles'

interface AIAssistantReturnType {
  files: any[]
  createButton: () => React.JSX.Element
  t: TFunction<'translation', undefined>
  setAddFileDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>
  addFileDrawerOpened: boolean
  assistantId: string
  handleDelete: (_id: string) => void
}

const useAIAssistant = (): AIAssistantReturnType => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const { id: courseId } = useParams()
  const { course } = getCourseById(courseId as string)
  const assistantId = course?.assistantId
  const { files } = getAssistantFiles(assistantId as string)
  const { deleteFile } = useDeleteFileService({ assistantId })
  const [addFileDrawerOpened, setAddFileDrawerOpened] = useState<boolean>(false)

  const createButton = (): React.JSX.Element => (
    <Button
      text={t('AI_assistant.add_file')}
      size="small"
      color="secondary"
      onClick={() => {
        setAddFileDrawerOpened(true)
      }}
      icon={<AddCircleRoundedIcon />}
      background="#06C68F"
    />
  )

  const confirmDelete = (_id: string) => {
    deleteFile(_id)
  }

  const handleDelete = (_id: string) => {
    fireSwal({
      title: t('popups.file_delete_confirmation'),
      onConfirm: () => confirmDelete(_id),
      confirmText: t('popups.confirm_delete'),
    })
  }

  return {
    files,
    createButton,
    t,
    setAddFileDrawerOpened,
    addFileDrawerOpened,
    assistantId,
    handleDelete,
  }
}

export default useAIAssistant
