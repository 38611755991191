import { useState } from 'react'
import addCompanyService from 'services/companies/addCompanyService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'

interface Params {
  filter: object
}

interface InputType {
  fieldA: string
  fieldB: number
}

interface Company {
  id: string
  name: string
}

interface ReturnType {
  addCompany: (
    input: InputType,
    coursesIds: string[],
    callBack: (company: Company) => void,
  ) => Promise<void>
  data: string
  loading: boolean
  error: ApolloError | undefined
}

const useAddCompanyService = (params: Params): ReturnType => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { addCompanyMutation, loading, error } = addCompanyService(params)
  const [data, setData] = useState('')

  const addCompany = async (
    input: InputType,
    coursesIds: string[],
    callBack: (company: Company) => void,
  ): Promise<void> => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    try {
      const { data, errors }: any = await addCompanyMutation({
        variables: { input, coursesIds },
      })
      if (errors) {
        message = errors[0].message.message
        variant = 'error'
      } else {
        data && setData(data)
        message = `${t('general.company')} ${t('messages.added')}`
        variant = 'success'
        callBack(data.addCompany)
      }
    } catch (err) {
      console.log('ERR', err)
      message = t('messages.something_wrong')
      variant = 'error'
    } finally {
      setSnackbar({ message, variant })
    }
  }

  return {
    addCompany,
    data,
    loading,
    error,
  }
}

export default useAddCompanyService
