import { useMutation } from '@apollo/client'
import {
  GET_SKILL_TEST_VIDEOS_CRITERIAS,
  SET_SKILL_TEST_VIDEO_CRITERIA,
} from 'gql/skillTestVideos.query'

import { updateCache } from 'services/updateCache'

const setSkillTestVideoCriteriaService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [setSkillTestVideoCriteriaMutation, { loading, error }] = useMutation(
    SET_SKILL_TEST_VIDEO_CRITERIA,
    {
      update(
        cache,
        { data: { setSkillTestVideoCriteriasOnModule: document } },
      ) {
        updateCache({
          objectName: 'getSkillTestVideoCriteriasByModuleId',
          query: GET_SKILL_TEST_VIDEOS_CRITERIAS,
          cache,
          document,
          action: 'addToTop',
          params,
          nestedFindObjectName: 'criterias',
        })
      },
    },
  )

  return {
    setSkillTestVideoCriteriaMutation,
    loading,
    error,
  }
}

export default setSkillTestVideoCriteriaService
