import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { filterOption } from 'pages/modules/staticData'
import {
  FilterDrawerProps,
  IFilterData,
  QueryType,
} from 'pages/modules/FilterDrawer/filterDrawer.interface'
import { UseFilterDrawerReturnType } from './useFilterDrawer.interface'
import {
  LowerCaseFilterableFieldType,
  UpperCaseFilterableFieldType,
} from 'enums/filterEnum'

const useFilterDrawer = (
  props: FilterDrawerProps,
): UseFilterDrawerReturnType => {
  const ref = useRef<NodeJS.Timeout | null>(null)
  const [range, setRange] = useState([0, 500])
  const [filterData, setFilterData] = useState<IFilterData>(filterOption)

  const [loadData, { loading, data }] = useLazyQuery(GET_ALL_COURSE)

  useEffect(() => {
    if (!_.isEmpty(props.filterData)) {
      const { range } = props.filterData

      setFilterData(props.filterData)

      if (range) setRange(range)
    }
  }, [])

  const handleFieldChange = (
    field: string,
    action?: string,
  ): ((e: any, val?: any) => void) => {
    if (action && action === 'dropdown') {
      return (e: any) => {
        setFilterData({
          ...filterData,
          [field]: e,
        })
      }
    }

    return (e: any, val?: any) => {
      setFilterData({
        ...filterData,
        [field]: field === 'range' ? val : e.target.value,
      })
    }
  }
  const loadDataOptions = (e: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e
      loadData({
        variables: {
          filter: {
            name: {
              type: UpperCaseFilterableFieldType.MATCH,
              value,
            },
            originalId: {
              type: UpperCaseFilterableFieldType.ARRAY_IN,
              value: [null],
            },
          },
        },
      })
    }, 300)

  const handleSelectChange = (e: string): void => {
    if (ref.current) {
      clearTimeout(ref.current as NodeJS.Timeout)
    }
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const query: QueryType = {}

    const { courses, ...args } = filterData

    for (const key in args) {
      const arg = args[key]
      if (
        arg &&
        typeof arg === 'object' &&
        'value' in arg &&
        arg.value !== 'all'
      ) {
        query[key] = arg.value === 'no' ? null : { $ne: null }
      }
    }

    if (range[0] > 0 || range[1] < 500) {
      query.duration = { $gte: range[0] * 60, $lte: range[1] * 60 }
    }

    if (courses && Array.isArray(courses) && courses.length > 0) {
      query['courses.courseId'] = {
        $in: courses.map((i: { value: string }) => i.value),
      }
    }

    props.setFilterQuery(query)
    props.refetchData(
      props.companyId
        ? {
            filter: {
              query: {
                type: LowerCaseFilterableFieldType.QUERY,
                value: JSON.stringify(query),
              },
              companyId: {
                type: LowerCaseFilterableFieldType.EXACT,
                value: props.companyId,
              },
              course: {
                type:
                  props.type || LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
                value: props.courseId || null,
              },
            },
            currentPage: 1,
            perPage: props.perPage,
          }
        : {
            filter: {
              course: {
                type:
                  props.type || LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
                value: props.courseId || null,
              },
              query: {
                type: LowerCaseFilterableFieldType.QUERY,
                value: JSON.stringify(query),
              },
            },
            currentPage: 1,
            perPage: props.perPage,
          },
    )

    filterData.range = { value: range }

    props.setFilterData(filterData)
    if (props.setFiltered) props.setFiltered(true)
    props.onClose()
  }

  const { t } = props

  return {
    onFormSubmit,
    handleSelectChange,
    handleFieldChange,
    data,
    filterData,
    loading,
    t,
    range,
    setRange,
  }
}

export default useFilterDrawer
