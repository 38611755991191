export interface Props {
  title: string
  id: string
  data?: any
}
interface Attachment {
  fileType: string
  link: string
  name: string
  size: string
  thumbnail: string
}

export interface formInterface {
  name: string
  description: string
  attachment: Attachment | null
}

export const formData: formInterface = {
  name: '',
  description: '',
  attachment: null,
}
