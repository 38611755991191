import { useMutation } from '@apollo/client'

import { INSERT_STUDENTS_WITH_GROUPS } from 'gql/users.query'

const importStudentsWithGroupsService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [insertStudentsWithGroups, { loading }] = useMutation(
    INSERT_STUDENTS_WITH_GROUPS,
  )

  return {
    insertStudentsWithGroups,
    loading,
  }
}

export default importStudentsWithGroupsService
