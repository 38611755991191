import React, { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useData } from 'context/DataContext'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { genderOptions } from 'components/UsersLayout/staticData'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  Title,
} from 'components/DrawerLayout/styled-components'

import {
  FormButtons,
  FormContainer,
  FormGroupWrapper,
  FormField,
  FormFields,
  FormFooter,
} from 'components/common/Form/Form'

import { GET_GROUP } from 'gql/group/group.query'
import IconButton from '@mui/material/IconButton'
import { useUserValue } from 'context/UserContext'

import Loader from 'components/common/Loader'
import { StudentSchema, UserSchema } from 'helpers/validationSchemas'
import useAddStudentService from 'hooks/users/useAddStudentService'

import TextInput from 'components/common/TextInput/TextInput'
import { SelectField, AsyncSelect } from 'components/common/SelectField'
import DatePicker from 'components/common/DatePicker'
import { Button } from 'components/common/Button'
import { PhoneCountryCode } from 'components/common/PhoneCountyCodeV2'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { ILoadDataOptionsFilter } from './addStudent.interface'
import { CHECK_USER_EMAIL } from 'gql/users.query'

interface Props {
  title: string
  role: string
  drawerName?: string
  closeDrawer?: () => void
  drawerData: any
  onClose?: any
  setFormData?: any
  group?: {
    groupId: string
    name: string
    status: string
    id?: string
  }
  setSelectedStudents?: any
}

interface IGroupOption {
  label: string
  value: string
}

const AddStudent = ({
  title,
  role,
  drawerData,
  onClose,
  setFormData,
  group,
  setSelectedStudents,
}: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const ref: any = useRef()
  const [dataState, dispatchData] = useData()
  const [generalError, setGeneralError] = useState('')
  const companyId = drawerData.companyId || state.selectedCompany?.id

  const { addStudent, error, addStudentLoading } =
    useAddStudentService(companyId)
  const [checkEmail] = useMutation(CHECK_USER_EMAIL)

  const [fetchGroup, { data: groupData, loading: groupLoading }] =
    useLazyQuery(GET_GROUP)

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        gender: null,
        birthDate: '',
        biography: '',
        requestPasswordChange: state.currentUser.settings.askPassword,
        note: '',
        group: [],
        role: '',
        jobTitle: '',
        location: '',
        phoneFields: null as any,
        phoneFieldValue: null as any,
        avatar: '',
      },
      validationSchema: group ? UserSchema : StudentSchema,
      async onSubmit(values) {
        const res = await checkEmail({
          variables: { email: values.email },
        })

        if (res.errors) {
          errors.email = res.errors[0].message
          return false
        }

        const formValues: any = { ...values }

        delete formValues.phoneFieldValue

        if (addStudentLoading) return

        formValues.role = role
        formValues.group = []

        if (values.group.length !== 0) {
          formValues.group = values.group.map((group: IGroupOption) => {
            return { name: group.label, groupId: group.value }
          })
        } else if (group) {
          formValues.group.push({
            name: group.name,
            groupId: group.id || group.groupId,
            status: group.status,
          })
        }

        if (formValues.phoneFields && formValues.phoneFields.value) {
          formValues.phoneFields = {
            dialCode: formValues.phoneFields.value,
            code: formValues.phoneFields.label,
          }
        } else {
          formValues.phoneFields = null
        }

        formValues.gender = formValues.gender ? formValues.gender.value : null
        addStudent(formValues, role, companyId, onSuccess)
      },
    })

  const onSuccess = (student: any): void => {
    if (setSelectedStudents) {
      setSelectedStudents(student)
    }
    closeDrawer()
    dispatchData({ type: CLEAR_FORMDATA })
    drawerData.onSuccess()
  }

  const handleCloseDrawer = (): void => {
    if (setFormData) {
      setFormData(values)
    } else {
      dispatchData({
        type: SET_FORMDATA,
        payload: {
          type: 'add',
          drawer: 'addStudent',
          values,
          compareTo: {},
        },
      })
    }
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  const loadDataOptions = (e: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e
      const filter: ILoadDataOptionsFilter = {
        name: {
          type: LowerCaseFilterableFieldType.MATCH,
          value,
        },
      }

      if (state.selectedCompany?.id) {
        filter['company'] = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: state.selectedCompany?.id,
        }
      }
      fetchGroup({
        variables: {
          filter,
        },
      })
    }, 200)

  const handleSelectChange = (e: string): void => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  useEffect(() => {
    loadDataOptions('')
  }, [])

  const [currentDate, setCurrentDate] = useState<Date | null>(null)

  const onDateChange = (date: Date | null): void => {
    // setCurrentDate(date)
    // const birthDate = date
    setFieldValue('birthDate', date)
  }

  useEffect(() => {
    if (values.birthDate) {
      setCurrentDate(
        new Date(
          typeof (values.birthDate === 'string')
            ? values.birthDate
            : +values.birthDate,
        ),
      )
    }
  }, [values.birthDate])

  const closeDrawer = (): void => onClose()

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  return (
    <>
      {addStudentLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              {/* <FormField>
                <AvatarInput
                  setFieldValue={setFieldValue}
                  initialAvatar={values.avatar}
                />
              </FormField> */}
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.first_name')}*`}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  size="small"
                  placeholder={`${t('user_details.first_name')}`}
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.last_name')}*`}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  size="small"
                  placeholder={`${t('user_details.last_name')}`}
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  title={`${t('user_details.email')}*`}
                  size="small"
                  placeholder={`${t('user_details.email')}`}
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <Title>{t('user_details.phone')}</Title>
                <PhoneCountryCode
                  // label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  // title={t('user_details.phone')}
                  selectValue={null}
                  selectChange={(e: IGroupOption) =>
                    setFieldValue('phoneFields', e)
                  }
                  inputLabel="Phone"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={errors.phone || errors.phoneFields ? true : false}
                  errorMessage={
                    errors.phone || errors.phoneFields
                      ? errors.phone || errors.phoneFields
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('user_details.select_gender')}</Title>
                <SelectField
                  placeholder={t('user_details.select_gender')}
                  options={genderOptions}
                  error={errors.gender && touched.gender ? true : false}
                  errorMessage={errors.gender ? errors.gender : ''}
                  touched={touched.gender}
                  value={values.gender}
                  onChange={(e: IGroupOption) => setFieldValue('gender', e)}
                  isClearable
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('user_details.birthday')}</Title>
                <DatePicker
                  // title={t('user_details.birthday')}
                  // label={t('user_details.birthday')}
                  touched={!!touched.birthDate}
                  error={errors.birthDate ? true : false}
                  errorMessage={errors.birthDate ? errors.birthDate : ''}
                  name="birthDate"
                  value={values.birthDate}
                  onChange={onDateChange}
                />
              </FormField>
              {!drawerData.groupField && (
                <FormField width="100%">
                  <Title>{t('groups_layout.select_group')}</Title>
                  <AsyncSelect
                    isMulti
                    onInputChange={handleSelectChange}
                    onChange={(e: IGroupOption) => setFieldValue('group', e)}
                    data={(groupData && groupData.getAllGroups.data) || []}
                    value={values.group}
                    label={t('groups_layout.select_group')}
                    loading={groupLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                    error={errors.group}
                    errorMessage={errors.group}
                    touched={touched.group}
                  />
                </FormField>
              )}
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.job_title')}`}
                  size="small"
                  placeholder={`${t('form_fields.job_title')}`}
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size="small"
                  placeholder={`${t('form_fields.location')}`}
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              {/* {!drawerData.groupField && (
                <FormField width="100%">
                  <AsyncSelect
                    isMulti
                    onInputChange={handleSelectChange}
                    onChange={(e: any) => setFieldValue('group', e)}
                    data={(groupData && groupData.getAllGroups.data) || []}
                    value={values.group}
                    label={t('form_fields.type_to_search_group')}
                    loading={groupLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                    error={errors.group}
                    errorMessage={errors.group}
                    touched={touched.group}
                  />
                </FormField>
              )} */}
              <FormField>
                <TextInput
                  title={`${t('user_details.biography')}`}
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  size="small"
                  placeholder={`${t('quiz_details.type_here')}`}
                  name="biography"
                  type="text"
                  multiline
                  rows="3"
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField>
                <TextInput
                  title={`${t('user_details.note')}`}
                  error={errors.note ? true : false}
                  errorMessage={errors.note ? errors.note : ''}
                  touched={touched.note}
                  size="small"
                  placeholder={`${t('user_details.note')}`}
                  name="note"
                  type="text"
                  multiline
                  rows="3"
                  value={values.note}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddStudent
