import { useUserValue } from 'context/UserContext'
import { createRef, useEffect, useState } from 'react'
import { roles } from 'utils/permission'
import { CourseOption } from 'hooks/tasks/useTaskDrawer.interface'
import { useQuery } from '@apollo/client'
import { GET_ALL_SKILLS } from 'gql/skilss.query'
import { IAddCourseTemplateResponse } from './addCourseTemplate.interface'

const cropper: any = createRef()

const useAddCourseTemplate = ({
  setFieldValue,
  handleImageChange,
}: {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => any
  handleImageChange: (file: any, field: string, fileName?: string) => void
}): IAddCourseTemplateResponse => {
  const [state] = useUserValue()

  const [file, setFile] = useState<string>('')
  const [imageType, setImageType] = useState('')
  const [cropperOpen, setCropperOpen] = useState(false)

  const { role } = state.currentUser

  const rolePermission =
    !state.selectedCompany?.id && role === roles.super_admin

  useEffect(() => {
    if (rolePermission) {
      setFieldValue('coursePrivacy', 'public')
    }
  }, [])

  const { data: skillsData } = useQuery(GET_ALL_SKILLS)

  const cropperModalToggle = () => {
    setCropperOpen(!cropperOpen)
  }

  const finishCrop = (): void => {
    let croppedFile = null
    croppedFile = cropper.current.getCroppedCanvas().toDataURL()

    handleImageChange(croppedFile, imageType, file.match(/[^:/]\w+(?=;|,)/)![0])
    setFile('')
    setCropperOpen(false)
  }

  const imageChangeHandler = (e: any, field: string) => {
    const { files } = e.target
    const reader: any = new FileReader()
    reader.onload = () => {
      const img: any = new Image()
      img.src = reader.result
      setFile(reader.result)
    }
    reader.readAsDataURL(files[0])
    setCropperOpen(true)
    setImageType(field)
  }

  const deleteImage = (field: string) => {
    handleImageChange(null, field)
  }

  const skillList =
    skillsData &&
    skillsData.getAllSkills.map((i: CourseOption) => ({
      label: i.label,
      value: i.label,
    }))

  const handleIsValidNewOption = (inputValue: any, selectOptions: any) => {
    // Check for the same value --> ASD === ASD ?
    const exactValueExists = selectOptions.find(
      (el: any) => el.value === inputValue,
    )
    // Check if the value has a valid length.
    // Without this, it will show create option for empty values.
    const valueIsNotEmpty = inputValue.trim().length
    // If true show create option.
    return !exactValueExists && valueIsNotEmpty
  }

  return {
    imageChangeHandler,
    deleteImage,
    skillList,
    handleIsValidNewOption,
    cropper,
    cropperModalToggle,
    finishCrop,
    cropperOpen,
    file,
  }
}

export default useAddCourseTemplate
