import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'hoc/ProtectedRoute'
import Activate from '../pages/auth/Activate'
import ErrorPage from 'pages/ErrorPage/ErrorPage'
import Login from 'pages/auth/Login'
import ForgotPassword from 'pages/auth/ForgotPassword'
import ResetPassword from 'pages/auth/ResetPassword'
import Main from 'pages'
import Analytics from 'pages/analytics/Analytics'
import { CompaniesLayout, CompanyDetails } from 'pages/companies'
import { StudentsLayout } from 'pages/students'
import { CoachesLayout } from 'pages/coaches'
import { AdminsLayout } from 'pages/admins'
import { ModeratorsLayout } from 'pages/moderators'
import { GroupLayout } from 'pages/group'
import GroupDashboard from 'pages/group/GroupDashboard/GroupDashboard'
import Users from 'pages/main/Users'
import ModuleDetail from 'pages/modules/ModuleDetail'
import { ModulesLayout } from 'pages/modules'
import { CourseDetail, CourseLayout } from 'pages/courses'
import { QuizDetails, QuizLayout } from 'pages/quizzes'
import { QuestionDetails, QuestionsLayout } from 'pages/questions'
import CustomNotifications from 'pages/customNotifications/CustomNotifications'
import Settings from 'pages/main/Settings'
import { getToken } from 'helpers/localStorage'
import EditProfile from 'pages/profile'
import { TopicDetails } from 'pages/topics'
import { CertificateBuilder } from 'pages/certificateBuilder'
import AccredibleIframe from 'pages/modules/BadgeEditor/builderIframe/AccredibleIframe'
import { CompanyWizardLayout } from 'pages/companyWizard'
import { LessonDetails } from 'pages/lessons'
import AllUserProfile from 'pages/profile/AllUserProfile/UsersProfile/AllUserProfile'
import ActivityTracker from 'pages/profile/AllUserProfile/activity/Activity'
import Leaderboard from 'pages/leaderboard/leaderboard'
import { TestsLayout, TestDetails } from 'pages/tests'
import { TasksLayout, TaskDetails } from 'pages/tasks'
import { BooksLayout, BookDetails } from 'pages/books'

const MainRoutes = () => {
  const token = getToken()

  return (
    <Routes>
      <Route
        path="/forgot-password"
        element={token ? <Navigate to="/" /> : <ForgotPassword />}
      />
      <Route path="/login" element={token ? <Navigate to="/" /> : <Login />} />
      <Route
        path="/reset-password/:token"
        element={token ? <Navigate to="/" /> : <ResetPassword />}
      />

      <Route
        path="/activate/:token"
        element={
          token ? <Navigate to="/" /> : <ProtectedRoute component={Activate} />
        }
      />
      <Route path="/" element={<ProtectedRoute component={Main} />}>
        {/* <Route
          index
          element={
            <ProtectedRoute
              roleAccess={['super_admin', 'admin', 'consultant']}
              component={DashboardLayout}
            />
          }
        /> */}
        <Route
          index
          path="/"
          element={<ProtectedRoute component={Analytics} />}
        />

        <Route
          path="/analytics/:id"
          element={<ProtectedRoute component={Analytics} />}
        />
        <Route
          path="/leaderboard"
          element={<ProtectedRoute component={Leaderboard} />}
        />
        <Route
          path="/topics/:topicId"
          element={<ProtectedRoute component={TopicDetails} />}
        />
        <Route
          path="/certificate-builder"
          element={<ProtectedRoute component={CertificateBuilder} />}
        />
        <Route
          path="/certificate-builder/:courseId"
          element={<ProtectedRoute component={AccredibleIframe} />}
        />
        <Route
          path="/companies"
          element={
            <ProtectedRoute
              roleAccess={['user', 'super_admin', 'admin', 'consultant']}
              component={CompaniesLayout}
            />
          }
        />
        <Route
          path="/companies/:id"
          element={
            <ProtectedRoute
              roleAccess={[
                'user',
                'super_admin',
                'admin',
                'consultant',
                'companySuper_admin',
                'companyAdmin',
              ]}
              component={CompanyDetails}
              protectedWithCompanyId
            />
          }
        />
        <Route
          path="/create-company"
          element={
            <ProtectedRoute
              roleAccess={[
                'user',
                'super_admin',
                'admin',
                'consultant',
                // 'companySuper_admin'
              ]}
              component={CompanyWizardLayout}
            />
          }
        />
        <Route
          path="/create-company/:id"
          element={
            <ProtectedRoute
              roleAccess={[
                'user',
                'super_admin',
                'admin',
                'consultant',
                // 'companySuper_admin'
              ]}
              component={CompanyWizardLayout}
            />
          }
        />
        <Route path="/users">
          <Route
            index
            element={
              <ProtectedRoute
                component={Users}
                roleAccess={[
                  'user',
                  'super_admin',
                  'admin',
                  'consultant',
                  // 'companySuper_admin',
                  // 'companyAdmin',
                ]}
              />
            }
          />
          <Route
            path="students"
            element={
              <ProtectedRoute
                component={StudentsLayout}
                roleAccess={[
                  'user',
                  'super_admin',
                  'admin',
                  'consultant',
                  'companySuper_admin',
                  'companyAdmin',
                ]}
              />
            }
          />
          <Route
            path="tutors"
            element={
              <ProtectedRoute
                component={CoachesLayout}
                roleAccess={[
                  'user',
                  'super_admin',
                  'admin',
                  'consultant',
                  'companySuper_admin',
                  'companyAdmin',
                ]}
              />
            }
          />
          <Route
            path="admins"
            element={
              <ProtectedRoute
                component={AdminsLayout}
                roleAccess={[
                  'user',
                  'super_admin',
                  'admin',
                  'consultant',
                  'companySuper_admin',
                  // 'companyAdmin',
                ]}
              />
            }
          />
          <Route
            path="super-admins"
            element={
              <ProtectedRoute
                component={ModeratorsLayout}
                roleAccess={[
                  'user',
                  'super_admin',
                  'admin',
                  'consultant',
                  'companySuper_admin',
                  // 'companyAdmin',
                ]}
              />
            }
          />
        </Route>
        <Route
          path="/groups"
          element={
            <ProtectedRoute
              component={GroupLayout}
              roleAccess={[
                'user',
                'super_admin',
                'admin',
                'consultant',
                'companyAdmin',
                'companySuper_admin',
              ]}
            />
          }
        />
        <Route
          path="/group/:id"
          element={
            <ProtectedRoute
              component={GroupDashboard}
              roleAccess={[
                'user',
                'super_admin',
                'consultant',
                'admin',
                'companyAdmin',
                'companySuper_admin',
              ]}
            />
          }
        />
        <Route
          path="/modules"
          element={
            <ProtectedRoute
              component={ModulesLayout}
              roleAccess={[
                'user',
                'super_admin',
                'admin',
                // 'companyAdmin',
                'companySuper_admin',
              ]}
            />
          }
        />
        <Route
          path="/tests"
          element={
            <ProtectedRoute
              component={TestsLayout}
              roleAccess={[
                'user',
                'super_admin',
                'admin',
                'consultant',
                // 'companyAdmin',
                'companySuper_admin',
              ]}
            />
          }
        />
        <Route
          path="/modules/:id"
          element={<ProtectedRoute component={ModuleDetail} />}
        />
        <Route
          path="/lessons/:id"
          element={<ProtectedRoute component={LessonDetails} />}
        />
        <Route
          path="/tests/:id"
          element={<ProtectedRoute component={TestDetails} />}
        />
        <Route
          path="/books"
          element={
            <ProtectedRoute
              component={BooksLayout}
              roleAccess={['super_admin', 'companySuper_admin']}
            />
          }
        />

        <Route
          path="books/:bookId"
          element={
            <ProtectedRoute
              component={BookDetails}
              roleAccess={['super_admin', 'companySuper_admin']}
            />
          }
        />
        <Route
          path="/tasks"
          element={
            <ProtectedRoute
              component={TasksLayout}
              roleAccess={['super_admin', 'companySuper_admin', 'consultant']}
            />
          }
        />

        <Route
          path="tasks/:taskId"
          element={
            <ProtectedRoute
              component={TaskDetails}
              roleAccess={['super_admin', 'companySuper_admin', 'consultant']}
            />
          }
        />
        <Route
          path="/courses"
          element={
            <ProtectedRoute
              component={CourseLayout}
              roleAccess={[
                'user',
                'super_admin',
                'admin',
                // 'companyAdmin',
                'companySuper_admin',
              ]}
            />
          }
        />
        <Route
          path="/courses/:id"
          element={
            <ProtectedRoute
              component={CourseDetail}
              roleAccess={[
                'super_admin',
                'consultant',
                'admin',
                'companySuper_admin',
                'companyAdmin',
              ]}
            />
          }
        />
        <Route
          path="/quizzes"
          element={
            <ProtectedRoute
              component={QuizLayout}
              roleAccess={[
                'super_admin',
                'admin',
                'consultant',
                // 'companySuper_admin',
                // 'companyAdmin',
              ]}
            />
          }
        />
        <Route
          path="/quizzes/:id"
          element={<ProtectedRoute component={QuizDetails} />}
        />
        <Route
          path="/questions"
          element={
            <ProtectedRoute
              component={QuestionsLayout}
              roleAccess={[
                'super_admin',
                'admin',
                'companySuper_admin',
                'consultant',
                // 'companyAdmin',
              ]}
            />
          }
        />
        <Route
          path="/questions/:id"
          element={<ProtectedRoute component={QuestionDetails} />}
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute
              exact
              path="/notifications"
              roleAccess={['super_admin', 'admin', 'consultant']}
              component={CustomNotifications}
            />
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute exact path="/settings" component={Settings} />
          }
        />

        <Route
          path="/profile/:id"
          element={<ProtectedRoute component={AllUserProfile} />}
        />
        <Route
          path="/profile/:id/activity"
          element={<ProtectedRoute component={ActivityTracker} />}
        />
        <Route
          path="/user-profile/:id"
          element={<ProtectedRoute component={EditProfile} />}
        />
      </Route>
      <Route path="/error" element={<ErrorPage />} />
    </Routes>
  )
}

export default MainRoutes
