import React, { ChangeEvent, ReactElement } from 'react'
import {
  StyledFromGroupContainer,
  CourseDetailsContainer,
  ImageCropperContainer,
  PriceContainer,
  StyledTextContainer,
  InputFieldContainer,
  FieldItemContainer,
  FormGroupWrapperStyles,
  StyledFlexColumn,
  Root,
  styles,
} from './styled-components'
import FormControlLabel from '@mui/material/FormControlLabel'
import CreatableSelect from 'react-select/creatable'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import {
  FormGroup,
  FormGroupItem,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { Input } from 'components/common/TextInput'
import Select from 'components/common/Select'
import Uploader from 'components/common/FileUploader/Uploader'
import { SelectField } from 'components/common/SelectField'
import { Button } from 'components/common/Button'
import Checkbox from 'components/common/Checkbox'
import {
  completeCriterias,
  courseLevels,
  currencyList,
} from 'pages/courses/staticData'
import { Step1Props } from './interfaces/step.interface'
import { customStyles } from 'components/common/SelectField/SelectField'
import useAddCourseTemplate from 'hooks/course/addCourseDrawer/addCourseTemplate/useAddCourseTemplate'
import { CourseOption } from 'pages/tasks/TasksDrawer/tasksDrawer.interface'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { Title } from 'pages/tests/editTestDrawer/styled-components'

const AddCourseTemplateInnerComponent = ({
  t,
  setFieldValue,
  handleImageChange,
  handleVideoChange,
  errors,
  touched,
  values,
  handleChange,
  imageUploading,
  videoUploadProgress,
  handleUploadFile,
  handleCloseDrawer,
  fileLoading,
  handleButtonClick,
}: Step1Props): ReactElement => {
  const {
    imageChangeHandler,
    deleteImage,
    skillList,
    handleIsValidNewOption,
    cropper,
    cropperModalToggle,
    finishCrop,
    cropperOpen,
    file,
  } = useAddCourseTemplate({
    setFieldValue,
    handleImageChange,
  })

  return (
    <Root>
      <FormGroupWrapper style={FormGroupWrapperStyles}>
        <FormGroup>
          <FormGroupItem>
            <Input
              error={!!errors.name}
              errorMessage={errors.name ? errors.name : ''}
              touched={touched.name}
              title={`${t('form_fields.name')}*`}
              name="name"
              size="small"
              type="text"
              fullWidth
              value={values.name}
              onChange={handleChange}
            />
          </FormGroupItem>
          <FormGroupItem>
            <Title>{t('form_fields.course_overview')}</Title>
            <TextEditor
              isVisible={true}
              hasControls={false}
              contentHeight="200px"
              handleChange={(editorState: string, plainText: string): void => {
                setFieldValue('editorText', editorState)
                setFieldValue('description', plainText)
              }}
              canEdit={true}
              defaultText={t('form_fields.course_overview')}
            />
          </FormGroupItem>

          <FormGroupItem>
            <SelectField
              label={t('course_details.level')}
              error={!!errors.level}
              errorMessage={errors.level ? errors.level : ''}
              touched={touched.level}
              placeholder={t('course_details.level')}
              options={courseLevels}
              value={values.level}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                setFieldValue('level', e)
              }
              isClearable
            />
          </FormGroupItem>
          {values.coursePrivacy === 'public' && (
            <FormGroupItem>
              <PriceContainer>
                <StyledTextContainer>
                  {t('form_fields.price_description')}
                </StyledTextContainer>
                <InputFieldContainer>
                  <FieldItemContainer width="160px">
                    <Input
                      error={!!errors.price}
                      errorMessage={errors.price ? errors.price : ''}
                      touched={touched.price}
                      label={t('form_fields.price')}
                      name="price"
                      size="small"
                      type="number"
                      fullWidth
                      value={values.price || ''}
                      onChange={handleChange}
                    />
                  </FieldItemContainer>
                  <FieldItemContainer width="120px">
                    <Select
                      error={!!errors.currency}
                      touched={touched.currency}
                      label={t('form_fields.currency')}
                      name="currency"
                      items={currencyList}
                      value={values.currency}
                      onChange={handleChange}
                    />
                  </FieldItemContainer>
                </InputFieldContainer>
              </PriceContainer>
            </FormGroupItem>
          )}

          <FormGroupItem>
            <Title>{t('general.skills')}</Title>
            <CreatableSelect
              isMulti
              onChange={(e): void => {
                setFieldValue('skills', e)
              }}
              options={
                (skillList &&
                  skillList.sort((a: CourseOption, b: CourseOption) => {
                    const textA = a.label.toUpperCase()
                    const textB = b.label.toUpperCase()
                    return textA < textB ? -1 : textA > textB ? 1 : 0
                  })) ||
                []
              }
              value={values.skills}
              placeholder={t('general.skills')}
              styles={customStyles}
              isValidNewOption={handleIsValidNewOption}
            />
          </FormGroupItem>

          <FormGroupItem>
            <StyledFromGroupContainer>
              <CourseDetailsContainer>
                <StyledFlexColumn>
                  <Uploader
                    label={t('actions.upload_cover_image')}
                    accept="image/x-png,image/gif,image/jpeg"
                    onFileChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      imageChangeHandler(e, 'avatar')
                    }
                    deleteFile={() => deleteImage('avatar')}
                    type="image"
                    inputValue={values.avatar ? values.avatar.name : ''}
                    onInputChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      setFieldValue('avatar.name', e.target.value)
                    }
                    disabled={!values.avatar}
                    uploadedFile={values.avatar}
                    fileExtension={
                      (values.avatar &&
                        `(${values.avatar.link.split('.').pop()})`) ||
                      ''
                    }
                    loading={imageUploading === 'avatar'}
                    id="avatar"
                  />
                  {errors.avatar && touched.avatar && (
                    <span style={{ color: '#f44336' }}>{errors.avatar}</span>
                  )}
                </StyledFlexColumn>
              </CourseDetailsContainer>
              <CourseDetailsContainer>
                <Uploader
                  label={t('actions.upload_intro_video')}
                  accept="video/mp4,video/x-m4v,video/*"
                  onFileChange={handleVideoChange}
                  deleteFile={() => handleVideoChange()}
                  type="video"
                  inputValue={values.video ? values.video.title : ''}
                  onInputChange={(e: ChangeEvent<HTMLInputElement>): void =>
                    setFieldValue('video.title', e.target.value)
                  }
                  disabled={!values.video}
                  uploadedFile={values.video}
                  fileExtension={
                    (values.video && `(${values.video.links[2].type})`) || ''
                  }
                  loading={
                    videoUploadProgress > 0 ? videoUploadProgress : false
                  }
                  id="video"
                />
              </CourseDetailsContainer>
            </StyledFromGroupContainer>
          </FormGroupItem>

          {values.video && (
            <FormGroupItem>
              <StyledFromGroupContainer>
                <CourseDetailsContainer>
                  <Uploader
                    label="Upload video subtitle"
                    accept=".vtt, .srt"
                    onFileChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      handleUploadFile(e, 'subtitle')
                    }
                    deleteFile={(): void => deleteImage('subtitle')}
                    type="subtitle"
                    inputValue={values.subtitle ? values.subtitle.name : ''}
                    onInputChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      setFieldValue('subtitle.name', e.target.value)
                    }
                    disabled={!values.subtitle}
                    uploadedFile={values.subtitle}
                    fileExtension={values.subtitle ? 'vtt' : ''}
                    loading={fileLoading === 'subtitle'}
                    id="subtitle"
                  />
                </CourseDetailsContainer>
              </StyledFromGroupContainer>
            </FormGroupItem>
          )}
          <FormGroupItem>
            <SelectField
              label={t('course_details.complete_criteria')}
              error={!!errors.completeCriteria}
              errorMessage={
                errors.completeCriteria ? errors.completeCriteria : ''
              }
              touched={touched.completeCriteria}
              placeholder={t('course_details.complete_criteria')}
              options={completeCriterias}
              value={values.completeCriteria}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                setFieldValue('completeCriteria', e)
              }
            />
          </FormGroupItem>
          <FormGroupItem>
            <StyledFromGroupContainer>
              <div style={{ height: '125px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.certificateIncluded}
                      onChange={handleChange}
                      name="certificateIncluded"
                    />
                  }
                  label={t('general.certificate_included')}
                />
              </div>
            </StyledFromGroupContainer>
          </FormGroupItem>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={styles.modal}
            open={cropperOpen}
            onClose={cropperModalToggle}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <ImageCropperContainer>
              <Cropper
                ref={cropper}
                src={file}
                style={{ height: 500, width: 600 }}
                // aspectRatio={16 / 9}
                guides={false}
              />
              <div className="actions">
                <Button
                  text={t('actions.cancel')}
                  type="small"
                  color="secondary"
                  onClick={cropperModalToggle}
                />
                <Button
                  text={t('actions.save')}
                  type="small"
                  color="secondary"
                  onClick={(): void => finishCrop()}
                  background="#06C68F"
                  className="save"
                />
              </div>
            </ImageCropperContainer>
          </Modal>
        </FormGroup>
      </FormGroupWrapper>
      <FormFooter>
        <FormButtons multiCols={true}>
          <Button
            text={t('actions.cancel')}
            type="small"
            onClick={handleCloseDrawer}
            background="#E0E1E2"
            textColor="#414141"
          />
          <Button
            text={t('actions.save')}
            type="small"
            color="secondary"
            onClick={() => handleButtonClick('save')}
            background="#06C68F"
          />
        </FormButtons>
      </FormFooter>
    </Root>
  )
}

export default AddCourseTemplateInnerComponent
