import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import useUpdateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'
import useSendPushNotifications from 'hooks/group/useSendPushNotifications'

import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArchiveIcon from '@mui/icons-material/Archive'

import { Wrapper } from '../styled-components'

import Drawer from 'components/common/Drawer'
import Grid from 'components/common/Grid'
import { userActionConfig, columnConfig } from './config'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import TablePagination from 'components/common/Pagination/TablePagination'
import { FilterCoaches } from 'pages/coaches'
import ResetUsersPassword from 'pages/profile/AllUserProfile/ResetPassword'
import Modal from 'components/common/Modal'
import SendPushNotification from 'components/SendPushNotification/SendPushNotification'
import CircleNotificationsIcon from '@mui/icons-material/Notifications'
import { useFetchCompanyTutors } from 'hooks/users/useCompanyTutors'
import { PER_PAGE } from 'hooks/companies/useCompanies'
import { TFunction } from 'interfaces/TFunction'
interface Params {
  variables: any
  currentTab: number
  t: TFunction
}

const ROLE = 'COACH'

const CompanyTutors = ({ variables, currentTab, t }: Params) => {
  const { companyId, filter } = variables
  const defaultFilter = { ...filter }
  const navigate = useNavigate()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const { users, loading, refetch }: any = useFetchCompanyTutors(
    defaultFilter,
    1,
    PER_PAGE,
    companyId,
  )
  const [pushNotificationModal, setPushNotificationModal] = useState(false)
  const [messageData, setMessageData] = useState<{
    heading: string
    body: string
  }>({
    heading: '',
    body: '',
  })

  const { sendPushNotifications } = useSendPushNotifications()
  const handleModalClose = () => {
    setPushNotificationModal(false)
    setTimeout(() => {
      setMessageData({
        heading: '',
        body: '',
      })
    }, 500)
  }

  const handlePushNotificationSend = async () => {
    const ids = [...selectedItem[currentPage]]
    if (ids.length <= 0) return false
    const data = await sendPushNotifications(ids, messageData)
    if (data) handleModalClose()
  }

  const { deleteUser } = useUserDeleteService()

  const { updateUserStatus, updateUserStatusLoaing } =
    useUpdateStatusUserService()

  const { updateUserPassword } = useResendUserService()

  const { exportData } = useExportDataService()

  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [filterQuery, setFilterQuery] = useState<any>(filter)
  const [filterOptions, setFilterOptions] = useState<any>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [resetVisible, setResetVisible] = useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false)
  const [userId, setUserId] = useState('')

  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = users.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const confirmDeleteMany = (ids: string[]) => {
    deleteUser(ids, ROLE, () =>
      refetch({
        filter: filterQuery,
        currentPage,
        perPage,
        companyId,
      }),
    )
    setSelectedItem([])
  }

  const confirmChangeStatusMany = (ids: string[], newStatus: string) => {
    updateUserStatus(
      ids.length > 0 ? ids : selectedItem[currentPage] || [],
      newStatus,
    )
  }

  const handleEditClick = (userId: string) => {
    DrawerEventEmitter.emit('openDrawer', 'editCoach', true, {
      id: userId,
    })
  }

  const handleDeleteAllClick = () => {
    const roleText = 'Tutors'

    const selectedUsers = selectedItem[currentPage].map(
      (selectedItem: string) => {
        const usersToDelete = users.data.filter(
          (user: any) => user.id === selectedItem,
        )
        return usersToDelete[0]
      },
    )
    fireSwal({
      title: `Are you sure you want to delete selected ${roleText}?`,
      onConfirm: () => confirmDeleteMany(selectedItem[currentPage]),
      confirmText: 'Yes, delete!',
    })
  }

  const handleDeleteClick = (user: any) => {
    const params = {
      title: `Are you sure you want to delete this Tutor?`,
      onConfirm: () => {
        confirmDeleteMany([user.id])
      },
      confirmText: 'Yes, delete!',
    }

    fireSwal(params)
  }

  const handleChangeStatusAllClick = (
    ids: string[],
    action: string,
    hasMany?: boolean,
  ) => {
    let title = ''
    let newStatus = ''
    let confirmText = ''

    if (action === 'suspend') {
      title = hasMany
        ? `Are you sure you want to Suspend selected Tutors?`
        : `Are you sure you want to Suspend this Tutor?`
      newStatus = 'SUSPENDED'
      confirmText = 'Yes, Suspend!'
    } else {
      title = hasMany
        ? `Are you sure you want to Activate selected Tutors?`
        : `Are you sure you want to Activate this Tutor?`
      newStatus = 'ACTIVE'
      confirmText = 'Yes, Activate!'
    }

    fireSwal({
      title,
      onConfirm: () => confirmChangeStatusMany(ids, newStatus),
      confirmText,
    })
  }

  const handleExportData = () => {
    if (selectedItem[currentPage].length > 0) {
      exportData(ROLE, selectedItem[currentPage], (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const handleResendPasswordClick = (userId: string) => {
    fireSwal({
      title: `Are you sure you want to resend password for this Tutor?`,
      onConfirm: () => {
        updateUserPassword(userId)
      },
      confirmText: 'Yes, Resend Password!',
    })
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    refetch({
      filter: { ...filterQuery, ...defaultFilter },
      currentPage: newPage + 1,
      perPage,
      companyId,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      filter: { ...filterQuery, ...defaultFilter },
      currentPage,
      perPage: parseInt(event.target.value),
      companyId,
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    let formData = { ...filterQuery }
    if (searchValue !== '') {
      formData = { ...filterQuery, ...formValue }
    } else {
      const { search, ...filters } = filterQuery
      formData = { ...filters }
    }
    refetch({
      filter: { ...formData, ...defaultFilter },
      currentPage: 1,
      perPage,
      companyId,
    })
    setFilterQuery(formData)
    setResetVisible(true)
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    if (currentTab) {
      setSelectedItem({})
      setSelectAll({})
      setSearchValue('')
      setFilterQuery(defaultFilter)
    }
  }, [currentTab])

  const multiActions = () => {
    const actions: any = [
      {
        id: 0,
        color: 'secondary',
        tooltipText: t('actions.delete'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleDeleteAllClick(),
        component: <DeleteIcon fontSize="small" />,
      },
      {
        id: 1,
        color: 'secondary',
        tooltipText: t('actions.suspend'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleChangeStatusAllClick([], 'suspend', true),
        component: <BackspaceRoundedIcon fontSize="small" />,
      },
      {
        id: 2,
        color: 'primary',
        tooltipText: t('actions.activate'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleChangeStatusAllClick([], 'activate', true),
        component: <CheckCircleIcon fontSize="small" />,
      },
      {
        id: 10,
        color: 'primary',
        tooltipText: t('actions.export'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleExportData(),
        component: <ArchiveIcon fontSize="small" />,
      },
      {
        id: 3,
        color: 'primary',
        tooltipText: `${t('actions.send_push_notification')}`,
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => setPushNotificationModal(true),
        component: <CircleNotificationsIcon fontSize="small" />,
      },
    ].filter((i: any) => !i.admin)

    return actions.filter((i: any) => !i.hide)
  }

  if (loading) return <div>Loading...</div>

  const handleClick = () => {}
  const onGroupClick = () => {}

  const openAddDrawer = () => {
    const drawer = 'addCoach'
    const drawerTitle = 'Add Tutor'

    DrawerEventEmitter.emit('openDrawer', drawer, true, {
      data: {
        title: drawerTitle,
        onClose: () => {
          DrawerEventEmitter.emit('openDrawer', drawer, false)
        },
        onSuccess: () => {
          refetch({
            filter: filterQuery,
            currentPage: 1,
            perPage,
            companyId,
          })
          setSelectedItem({})
        },
      },
    })
  }
  const handleResetClick = () => {
    refetch({
      filter: defaultFilter,
      currentPage,
      perPage,
      companyId,
    })
    setResetVisible(false)
    setSearchValue('')
    setFilterQuery(defaultFilter)
    setFilterOptions(null)
  }

  const filterData = (filters: any) => {
    let formValue = {}
    if (searchValue !== '') {
      formValue = {
        search: {
          type: 'search',
          value: searchValue,
          fields: ['firstName', 'lastName', 'email', 'phone'],
        },
      }
    }
    refetch({
      filter: { ...filters, ...formValue, ...defaultFilter },
      currentPage: 1,
      perPage,
      companyId,
    })
    setResetVisible(true)

    if (users) {
      setFilterDrawerOpened(false)
      setFilterQuery({ ...filters, ...formValue, ...defaultFilter })
      setSelectedItem({})
    }
  }

  const redirectToProfile = (id: any) => {
    navigate(`/profile/${id}`)
  }

  const handleActivityClick = (id: string[]) => {
    const url = `/profile/${id}/activity`
    window.open(url, '_blank')
  }

  const groupAdminPolicy =
    state.currentUser.role !== 'ADMIN' ||
    (state.currentUser.role === 'ADMIN' && [6, 7].includes(currentTab))

  const config = columnConfig(
    ROLE,
    handleClick,
    selectItem,
    onGroupClick,
    t,
    !groupAdminPolicy,
    redirectToProfile,
  )
  const actions = userActionConfig(
    handleEditClick,
    handleDeleteClick,
    handleActivityClick,
    handleResendPasswordClick,
    handleChangeStatusAllClick,
    state.userPermission,
    t,
    ROLE,
    state.currentUser.role,
    setResetPasswordOpen,
    setUserId,
  )

  return (
    <div style={{ paddingTop: 20 }}>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => {}}
        totalWidth="600px"
      >
        <FilterCoaches
          role="COACH"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          useCompanyId
          forAssignCoaches={currentTab === 1 ? true : false}
        />
      </Drawer>
      <div style={{ marginBottom: 20 }}>
        <Wrapper justify="between" align="center">
          <Wrapper align="center">
            {groupAdminPolicy && (
              <>
                <Button
                  text={`Create Tutor`}
                  color="secondary"
                  onClick={openAddDrawer}
                  icon={<AddCircleRoundedIcon />}
                  background="#06C68F"
                />
                <GridMultipleActions
                  selectedItems={
                    selectedItem[currentPage]
                      ? selectedItem[currentPage].length
                      : 0
                  }
                  actions={multiActions()}
                />
              </>
            )}
          </Wrapper>
          <form onSubmit={handleSearchSubmit}>
            <TextInput
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              disabled={loading}
              icon={
                <IconButton type="submit">
                  <SearchRoundedIcon onClick={handleSearchSubmit} />
                </IconButton>
              }
            />
          </form>
        </Wrapper>
      </div>
      <div>
        <Grid
          title={t('general.users')}
          onFiltersClick={() => setFilterDrawerOpened(true)}
          // resetVisible={resetVisible}
          resetVisible={true}
          resetFilters={handleResetClick}
          config={config}
          data={users.data}
          actionConfig={!groupAdminPolicy ? null : actions}
          selected={_.size(selectedItem[currentPage])}
          selectAllItem={selectAllItem}
          selectedItems={selectedItem[currentPage] || []}
          redirectToProfile={redirectToProfile}
        />

        {users && users.totalCount > 0 && (
          <TablePagination
            currentPage={users ? users.currentPage : 0}
            rowsPerPage={perPage}
            count={users ? users.totalCount : 0}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
      {isResetPasswordOpen && (
        <ResetUsersPassword
          isModalOpen={isResetPasswordOpen}
          setModalOpen={setResetPasswordOpen}
          userId={userId}
        />
      )}
      <Modal isOpened={pushNotificationModal} onClose={handleModalClose}>
        <SendPushNotification
          messageData={messageData}
          setMessageData={setMessageData}
          onClose={handleModalClose}
          onSend={handlePushNotificationSend}
        />
      </Modal>
    </div>
  )
}

export default CompanyTutors
