import { useMutation } from '@apollo/client'
import { UPDATE_TEST } from 'gql/tests/tests.query'

export const updateTestService = () => {
  const [updateTestMutaiton, { loading, error }] = useMutation(UPDATE_TEST, {})

  return {
    updateTestMutaiton,
    loading,
    error,
  }
}
