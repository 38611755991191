import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { formDataInterface } from 'pages/tests/addTestDrawer/addTestDrawer.interface'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateTestService } from 'services/tests/updateTestService'

const useEditTestService = (params: any) => {
  const { t } = useTranslation()
  const { updateTestMutaiton, loading } = updateTestService()
  const [error, setError] = useState('')
  const { setSnackbar } = useSnackbarAlert()

  const updateTest = async (
    testId: string,
    input: formDataInterface,
    updateTestAssessmentId?: string,
    companyId?: string,
    action?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await updateTestMutaiton({
      variables: {
        testId,
        input,
      },
    })

    if (errors && errors.length) {
      message = errors[0]?.message
        ? errors[0].message
        : t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.changes_saved')
      variant = 'success'
    }

    setSnackbar({ message, variant })
    if (action) action()
  }

  return {
    updateTest,
    error,
    loading,
  }
}

export default useEditTestService
