import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Action } from './styled-components'
import Checkbox from '@mui/material/Checkbox'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import IconButton from '@mui/material/IconButton'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import GetAppIcon from '@mui/icons-material/GetApp'
import HeadsetIcon from '@mui/icons-material/Headset'
import ViewCompactIcon from '@mui/icons-material/ViewCompact'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { formatDuration } from 'helpers/formatDuration'
import config from 'config'
import CircularProgress from '@mui/material/CircularProgress'
import { getLessonDownloadLink } from 'utils/getLessonDownloadLink'

interface IRenderItem {
  id: string
  isChecked: boolean
  name: string
  attachment: any
  duration: number
  audio?: any
  video?: any
  contentDocument?: any
  transcriptFile?: any
  subtitle?: any
}

interface IAction {
  render: (item: IRenderItem) => React.JSX.Element
  hide: boolean
}

interface ILink {
  converted: boolean
  fileType: string
  url: string
  __typename: string
}

export const columnConfig = (
  t: (key: string) => string,
  selectItem?: (id: string, isSelected?: boolean) => void,
  handleClick?: (item: IRenderItem) => void,
  handleOpenMediaModal?: any,
) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: IRenderItem): React.JSX.Element => (
        <div style={{ width: '10px' }}>
          <Checkbox
            checked={item.isChecked}
            onChange={(): false | void => !!selectItem && selectItem(item.id)}
          />
        </div>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      numeric: false,
      disablePadding: true,
      id: 'video',
      label: t('general.content'),
      render: (item: IRenderItem): React.JSX.Element | undefined => {
        if (item.audio && item.audio.link) {
          return (
            <IconButton
              onClick={(): void => handleOpenMediaModal(item, 'audio')}
            >
              <HeadsetIcon fontSize="small" style={{ color: '#06c68f' }} />
            </IconButton>
          )
        }

        if (item.video && item.video.links) {
          const hasConverted =
            item.video &&
            item.video.links.find(
              (link: { converted: boolean }) => link.converted,
            )
          return hasConverted ||
            (config.ENV === 'development' && !hasConverted) ? (
            <IconButton onClick={() => handleOpenMediaModal(item, 'video')}>
              <PlayCircleOutlineIcon
                fontSize="small"
                style={{ color: '#06c68f' }}
              />
            </IconButton>
          ) : (
            <CircularProgress size={20} color="primary" />
          )
        }

        if (item.contentDocument && item.contentDocument.link) {
          return (
            <IconButton
              onClick={(): Window | null =>
                window.open(item.contentDocument.link, '_blank')
              }
            >
              <InsertDriveFileIcon
                fontSize="small"
                style={{ color: '#06c68f' }}
              />
            </IconButton>
          )
        }
      },
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: IRenderItem): React.JSX.Element => (
        <div
          onClick={(): void => handleClick && handleClick(item)}
          style={{ cursor: 'pointer' }}
        >
          {item.name}
        </div>
      ),
    },
    {
      id: 'duration',
      numeric: false,
      disablePadding: false,
      label: t('general.duration'),
      render: (item: IRenderItem): React.JSX.Element => (
        <div>{formatDuration(item.duration)}</div>
      ),
    },
    {
      id: 'attachment',
      numeric: false,
      disablePadding: false,
      label: t('general.attachment'),
      render: (item: IRenderItem): React.JSX.Element => {
        if (!item.attachment) {
          return (
            <IconButton>
              <InsertDriveFileIcon fontSize="small" color="disabled" />
            </IconButton>
          )
        }

        return (
          <IconButton
            onClick={(): Window | null =>
              window.open(item.attachment.link, '_blank')
            }
          >
            <InsertDriveFileIcon
              fontSize="small"
              style={{ color: '#06c68f' }}
            />
          </IconButton>
        )
      },
      // <div>{item.attachment && item.attachment.name || 'No Attachment'}</div>,
    },
  ]

  return grid
}

export const downloadActionConfig = (
  handleClick: (id: string, event?: string) => void,
  t: (key: string) => string,
): IAction[] => {
  const action = [
    // {
    //   render: (item: any) => (
    //     <Action
    //       onClick={() => handleClick(item)}
    //       className={(item.videos && item.videos.length) > 0 ? '' : 'disabled'}
    //     >
    //       <GetAppIcon />
    //       <span>Video</span>
    //     </Action>
    //   ),
    // },
    // {
    //   render: (item: any) => (
    //     <Action
    //       onClick={() => handleClick(item)}
    //       className={item.audio && item.audio.id ? '' : 'disabled'}
    //     >
    //       <GetAppIcon />
    //       <span>Audio</span>
    //     </Action>
    //   ),
    // },
    // {
    //   render: (item: any) => (
    //     <Action
    //       onClick={() => console.log('item', item)}
    //       className={item.document && item.document.link ? '' : 'disabled'}
    //     >
    //       <GetAppIcon />
    //       <span>Text File</span>
    //     </Action>
    //   ),
    // },
    // {
    //   render: (item: any) => {
    //     return (
    //       <Action
    //         onClick={() =>
    //           item.transcriptFile &&
    //           window.open(item.transcriptFile.link, '_blank')
    //         }
    //         className={item.transcriptFile ? '' : 'disabled'}
    //       >
    //         <GetAppIcon />
    //         <span>{t('actions.download_transcript')}</span>
    //       </Action>
    //     )
    //   },
    // },

    // {
    //   render: (item: any) => (
    //     <Action
    //       onClick={() => {
    //         item.attachment && window.open(item.attachment.link, '_blank')
    //       }}
    //       className={item.attachment ? '' : 'disabled'}
    //     >
    //       <GetAppIcon />
    //       <span>{t('actions.download_attachment')}</span>
    //     </Action>
    //   ),
    // },

    {
      hide: false,
      render: (item: IRenderItem): React.JSX.Element => (
        <Action
          onClick={(): void =>
            item.subtitle && window.open(item.subtitle.link, '_blank')
          }
          className={item.subtitle ? '' : 'disabled'}
        >
          <GetAppIcon />
          <span>{t('actions.download_subtitle')}</span>
        </Action>
      ),
    },
    {
      hide: false,
      render: (item: IRenderItem): React.JSX.Element => {
        const downloadUrl = getLessonDownloadLink(
          item.video?.links as ILink[] | undefined,
        )

        return (
          <Action
            onClick={(): void => {
              if (downloadUrl) {
                window.open(downloadUrl, '_blank')
              }
            }}
            className={downloadUrl ? '' : 'disabled'}
          >
            <GetAppIcon />
            <span>{t('actions.download_video')}</span>
          </Action>
        )
      },
    },
  ]

  return action
}

export const actionConfig = (
  handleEditClick: (id: string, event?: string) => void,
  handleDeleteClick: (ids: string[]) => void,
  t: (key: string) => string,
  event?: 'list' | '',
  permissions?: any,
  canModify?: boolean,
) => {
  const download: IAction[] = downloadActionConfig(handleEditClick, t)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate()

  let action = [
    {
      hide: !permissions.editLesson || !canModify,
      render: (item: IRenderItem): React.JSX.Element => (
        <Action onClick={(): void => handleEditClick(item.id, 'edit')}>
          <EditRoundedIcon />
          {/* {console.log("item", item)} */}
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteLesson || !canModify,
      render: (item: IRenderItem): React.JSX.Element => (
        <Action onClick={() => handleDeleteClick([item.id])}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
  ]

  const viewAction = [
    {
      hide: !permissions.viewLesson,
      render: (item: IRenderItem): React.JSX.Element => (
        <Action onClick={(): void => navigate(`/lessons/${item.id}`)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
  ]

  if (event) {
    action = [...viewAction, ...action, ...download]
  }

  return action.filter((i: IAction) => !i.hide)
}
