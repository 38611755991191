import React, { Dispatch, RefObject } from 'react'
import { Action } from 'components/UsersLayout/styled-components'
import { ReactComponent as DeleteIconRed } from 'assets/trash-can-red.svg'
import { ReactComponent as Resend } from 'assets/send-alt.svg'
import { ReactComponent as Reset } from 'assets/reset.svg'
import { ReactComponent as Upload } from 'components/common/Button/icons/upload.svg'
import { t } from 'i18next'
import { ActionCustomFlex } from 'pages/profile/AllUserProfile/UsersProfile/styled-components'
import { IPersonalInformation } from 'pages/profile/AdminProfile/PersonalInformation.interface'

export const actionConfig = (
  handleAvatarChangeRef: RefObject<HTMLInputElement>,
  handleAvatarDelete: () => void,
  setUserId: Dispatch<React.SetStateAction<string>>,
  setResetPasswordOpen: React.Dispatch<React.SetStateAction<boolean>>,
  handleResendPasswordClick: (userId: string) => void,
  profileData: IPersonalInformation,
) => {
  let action = [
    {
      render: () => (
        <>
          <Action color="#06C68F">
            <label htmlFor="file">
              <ActionCustomFlex>
                <Upload />
                <span> {t('actions.upload_avatar')}</span>
              </ActionCustomFlex>
            </label>
          </Action>
        </>
      ),
    },
    profileData.avatar && {
      render: () => (
        <Action color="#FF0000" onClick={() => handleAvatarDelete()}>
          <DeleteIconRed />
          <span>{t('actions.delete_avatar')}</span>
        </Action>
      ),
    },
    {
      render: () => (
        <Action
          onClick={() => {
            setUserId && setUserId(profileData.id || '')
            setResetPasswordOpen && setResetPasswordOpen(true)
          }}
        >
          <Reset />
          <span>{t('actions.reset_password')}</span>
        </Action>
      ),
    },
    {
      render: (item: IPersonalInformation) => (
        <Action
          onClick={() => {
            handleResendPasswordClick(item.id)
          }}
        >
          <Resend />
          <span>{t('actions.resend_password')}</span>
        </Action>
      ),
    },
  ].filter(Boolean)
  return action.filter((i: any) => !i.hide && !i.admin)
}
