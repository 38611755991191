import { useQuery } from '@apollo/client'
import { GET_COURSE } from 'gql/course/course.query'

export const getCourseById = (courseId: string) => {
  const {
    data: courseData,
    loading,
    refetch,
    error,
  } = useQuery(GET_COURSE, {
    variables: { courseId },
  })

  return {
    course: courseData?.getCourseById || {},
    loading,
    refetch,
    error,
  }
}
