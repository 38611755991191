import React from 'react'
import { StyledTabNavigationContainer } from './styled-components'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  padding?: number
  color?: string
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, padding = 3, color, ...other } = props

  return (
    <Typography
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box bgcolor="#f5f6fa" p={padding}>
          {children}
        </Box>
      )}
    </Typography>
  )
}

export const activeTabClassName = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const TabNavigation = (props: any) => {
  return (
    <StyledTabNavigationContainer isTransparent={props.transparent}>
      {props.children}
    </StyledTabNavigationContainer>
  )
}
