import { useMutation } from '@apollo/client'
import { INSERT_GROUPS_WITH_TESTS } from 'gql/group/group.query'

const importGroupsWithTestsService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [insertGroupsWithTests, { loading }] = useMutation(
    INSERT_GROUPS_WITH_TESTS,
  )

  return {
    insertGroupsWithTests,
    loading,
  }
}

export default importGroupsWithTestsService
