import { CSSProperties } from 'react'
import styled from 'styled-components'

export const Container = styled.div<{ items: string }>`
  margin-top: 20px;
  ${({ items }) =>
    items === 'card-extended'
      ? `
    display: flex;
  `
      : `
  display: grid;
  grid-gap: 20px;

  @media (min-width: 1800px) {
    grid-template-columns: repeat(auto-fill, minmax(318px, 1fr));
  }
  @media (max-width: 1800px) {
    grid-template-columns: repeat(auto-fill, minmax(318px, 1fr));
  }
  @media (max-width: 1450px) {
    grid-template-columns: repeat(auto-fill, minmax(318px, 1fr));
  }
  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, minmax(318px, 1fr));
  }
  `}
`

export const ItemContainer = styled.div`
  border: 1px solid #d6dae0;
  background: #fff;
  cursor: pointer;
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  min-width: 318px;
  margin: 0;
`

export const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -9px;
  margin-right: -8px;
  align-items: center;
`

export const ContentContainer = styled.div`
  display: flex;
  position: relative;
  .content_block {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-left: 19px;
    .quizParticipationType {
      font-size: 15px;
      color: #f50057;
      text-transform: capitalize;
    }
    .time {
      margin-top: 3px;
    }
  }
  .type_block {
    position: absolute;
    right: 0;
    bottom: 0;
    text-transform: capitalize;
  }
`
export const StyledQuizTypeSpan = styled.span`
  background-color: #20a8c6;
  padding: 4px 12px;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
`

export const FooterContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
`
export const FooterItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-bottom: 8px;
  gap: 100px;
  span {
    font-size: 11px;
    width: 55px;
    text-align: flex-start;
  }
`

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const StyledWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
  font-family: Open Sans;
  font-size: 18px;
  margin-block: 16px;
  font-weight: 600;
  line-height: 20px;
`

export const capitalizeStyleElipsis: CSSProperties = {
  textTransform: 'capitalize',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
}

export const capitalizeStyle: CSSProperties = {
  textTransform: 'capitalize',
  fontSize: 14,
}

export const locationIconStyles: CSSProperties = {
  color: '#06C68F',
  marginRight: 10,
}

export const styles: {
  [key: string]: CSSProperties
} = {
  cardTitleSpan: {
    fontSize: '14px',
    color: '#68707A',
    whiteSpace: 'nowrap' as 'nowrap',
  },
  cardContentSpan: {
    fontSize: '14px',
    color: '#020210',
    fontWeight: 600,
    whiteSpace: 'nowrap' as 'nowrap',
  },
}
