import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import { GET_SKILL_TEST_PERFORMANCE } from 'gql/users.query'
import { columnConfig } from './gridConfig'
// helpers
import { DrawerEventEmitter } from 'helpers/drawer'
import { SkillTestVideoReviewCriteria } from 'pages/modules/SkillTestVideoCriterias/skillTestVideoCriteria.interface'
import MyLoader from 'loading/loading'
import { SkillTestVideo } from 'pages/modules/ModuleDetail/SkillTestVideos/SkillTestVideos.interface'
import GridV2 from 'components/common/GridV2'
import { Root, StyledTableContainer } from './styled-components'

interface IVideoLink {
  url: string
  converted: boolean
  fileType: string
}

const SkillTestVideos: React.FC<{
  courseId: string[]
  firstName?: string
  userId?: string
  setUserProgress?: React.Dispatch<React.SetStateAction<any>>
}> = ({ courseId, firstName, userId: currentUserId, setUserProgress }) => {
  const { userId: userIdParam } = useParams<{ userId: string }>()
  const userId = currentUserId || userIdParam

  const { data, loading } = useQuery(GET_SKILL_TEST_PERFORMANCE, {
    variables: {
      courseId,
      userId,
    },
  })

  useEffect(() => {
    if (data && setUserProgress) {
      setUserProgress((prev: any) => ({
        ...prev,
        numberOfSkillTestVideos: data.getSkillTestVideos.data.length,
      }))
    }
  }, [data])

  const { t } = useTranslation()
  const [videoModal, setVideoModal] = useState(false)
  const [video, setVideo] = useState(null as any)

  const handleCriteriaClick = (criterias: SkillTestVideoReviewCriteria[]) => {
    DrawerEventEmitter.emit('openDrawer', 'viewSkillTestVideoCriterias', true, {
      data: criterias,
    })
  }

  const config = columnConfig(
    t,
    setVideoModal,
    setVideo,
    handleCriteriaClick,
    data?.getSkillTestVideos?.data?.some(
      (stv: SkillTestVideo) => stv.criterias && stv.criterias.length,
    ),
  )

  if (loading)
    return (
      <Root style={{ position: 'relative', height: 400 }}>
        <MyLoader width={250} speed={0.8} top={100} />
      </Root>
    )

  return (
    <div>
      <StyledTableContainer>
        {data?.getSkillTestVideos?.data && (
          <GridV2
            title={firstName && `${firstName}'s Skill Tests Result`}
            data={data.getSkillTestVideos?.data}
            width={260}
            config={config}
          />
        )}
      </StyledTableContainer>

      {video?.links && (
        <Modal isOpened={videoModal} onClose={() => setVideoModal(false)}>
          <Player
            url={video.links.find((l: IVideoLink) => l.fileType === 'hls')?.url}
            downloadLink={video.links.find((l: IVideoLink) => l.converted)?.url}
            subtitle={'subtitle'}
          />
        </Modal>
      )}
    </div>
  )
}

export default SkillTestVideos
