import IconButton from '@mui/material/IconButton'
import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import { PhoneCountryCode } from 'components/common/PhoneCountyCodeV2'
import { SelectField } from 'components/common/SelectField'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import TextInput from 'components/common/TextInput/TextInput'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Title,
} from 'components/DrawerLayout/styled-components'
import { genderOptions } from 'components/UsersLayout/staticData'
import React, { ChangeEvent } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { IAddAdminProps } from './addAdmin.interface'
import { useAddAdmin } from 'hooks/admins/useAddAdmin'
import useAdminDrawer from 'hooks/admins/useAdminDrawer'
import { roles } from 'utils/permission'
import { promises } from 'dns'

const AddAdmin = ({
  drawerData,
  onClose,
}: IAddAdminProps): React.JSX.Element => {
  const {
    t,
    addUserLoading,
    handleChange,
    handleCloseDrawer,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
    onDateChange,
    currentDate,
    generalError,
  } = useAdminDrawer({ drawerData, onClose, role: roles.admin })

  return (
    <>
      {addUserLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{t('users_layout.add_admin')}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.first_name')}*`}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  size="small"
                  placeholder={`${t('user_details.first_name')}`}
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.last_name')}*`}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  size="small"
                  placeholder={`${t('user_details.last_name')}`}
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  title={`${t('user_details.email')}*`}
                  size="small"
                  placeholder={`${t('user_details.email')}`}
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <Title>{t('user_details.phone')}</Title>
                <PhoneCountryCode
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={null}
                  selectChange={(e: ChangeEvent<HTMLInputElement>): void =>
                    setFieldValue('phoneFields', e)
                  }
                  inputLabel="Phone"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={errors.phone || errors.phoneFields ? true : false}
                  errorMessage={
                    errors.phone || errors.phoneFields
                      ? errors.phone || errors.phoneFields
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('user_details.select_gender')}</Title>
                <SelectField
                  placeholder={t('user_details.select_gender')}
                  options={genderOptions}
                  value={values.gender}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('gender', e)
                  }
                  isClearable
                />
              </FormField>

              <FormField width="100%">
                <Title>{t('user_details.birthday')}</Title>
                <DatePicker
                  name="birthDate"
                  value={currentDate}
                  onChange={onDateChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.job_title')}`}
                  size="small"
                  placeholder={`${t('form_fields.job_title')}`}
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size="small"
                  placeholder={`${t('form_fields.location')}`}
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('user_details.biography')}`}
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  size="small"
                  placeholder={`${t('quiz_details.type_here')}`}
                  name="biography"
                  type="text"
                  multiline
                  rows="3"
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('user_details.note')}`}
                  placeholder={`${t('user_details.note')}`}
                  size="small"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              <FormField width="100%">
                <CustomSwitcher
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue('isTutor', e?.target?.checked)
                  }}
                  label={t('users_layout.is_tutor')}
                  name="isTutor"
                  value={values.isTutor}
                  on={t('general.yes')}
                  off={t('general.no')}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddAdmin
