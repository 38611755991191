import { useMutation } from '@apollo/client'

import { INSERT_STUDENTS } from 'gql/users.query'

const importStudentsService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [insertStudents] = useMutation(INSERT_STUDENTS)

  return {
    insertStudents,
  }
}

export default importStudentsService
