import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'

import {
  setNotificationViewStatusService,
  readNotificationService,
  deleteNotificationsService,
  sendCustomNotificationService,
} from 'services/notifications/notificationService'

export const useSetNotificationStats = () => {
  const {
    setStatusMutation,
    error,
    loading,
  } = setNotificationViewStatusService()

  const setNotificationViewStatus = async (
    ids: string[],
    notificationBadgeRefetch: any,
  ) => {
    const { errors } = await setStatusMutation({
      variables: {
        ids,
      },
    })

    if (errors && errors.length) {
    } else {
      notificationBadgeRefetch()
    }
  }

  return {
    setNotificationViewStatus,
    error,
    loading,
  }
}

export interface CustomNotification {
  body: string
  subject: string
  companyIds?: string[]
  sendToAllCompanies?: boolean
  sendToAllRoles?: boolean
  sendAsEmail?: boolean
  sendAsPushNotification?: boolean
  roles?: string[]
  template?: string
}

export const useSendCustomNotification = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setSnackbar } = useSnackbarAlert()
  const {
    error,
    loading,
    sendCustomNotificationMutation,
  } = sendCustomNotificationService()

  const sendCustomNotification = async (input: CustomNotification) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await sendCustomNotificationMutation({
      variables: { input },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `Success`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    error,
    loading,
    sendCustomNotification,
  }
}

export const userReadNotification = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setSnackbar } = useSnackbarAlert()
  const { error, loading, readNotificationMutation } = readNotificationService()

  const readNotification = async (
    ids: string[],
    action: string,
    fetchNotificationData: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await readNotificationMutation({
      variables: { ids, action },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `Success`
      variant = 'success'

      fetchNotificationData()
    }

    setSnackbar({ message, variant })
  }

  return {
    error,
    loading,
    readNotification,
  }
}

export const useDeleteNotifications = () => {
  const { setSnackbar } = useSnackbarAlert()
  const {
    deleteNotificationsMutation,
    error,
    loading,
  } = deleteNotificationsService()

  const deleteNotifications = async (
    ids: string[],
    action: string,
    fetchNotificationData: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await deleteNotificationsMutation({
      variables: {
        ids,
        action,
      },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `Success`
      variant = 'success'

      fetchNotificationData()
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteNotifications,
    error,
    loading,
  }
}
