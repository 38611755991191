import { useQuery } from '@apollo/client'
import { GET_COMPANY_ASSIGNED_TUTORS } from 'gql/users.query'
import {
  FilterType,
  UseFetchCompanyAssignedTutorsReturnType,
} from 'interfaces/companyAssignedTutors/companyAssignedTutors.interface'

export const PER_PAGE = 10

export const useFetchCompanyAssignedTutors = (
  companyId: string,
  filter: FilterType = {},
  currentPage = 1,
  perPage: number = PER_PAGE,
): UseFetchCompanyAssignedTutorsReturnType => {
  const { data, loading, error, refetch } = useQuery(
    GET_COMPANY_ASSIGNED_TUTORS,
    {
      variables: {
        companyId,
        filter,
        currentPage,
        perPage,
      },
    },
  )

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.getAssignedSuadaTutors,
    loading,
    error: errorMessage,
    refetch,
  }
}
