import { useQuery } from '@apollo/client'
import { GET_ASSISTANT_MESSAGES } from 'gql/assistant/assistant.query'

interface IAssistantMessagesProps {
  threadId: string
  filter?: {
    limit?: number
  }
}

export const useAssistantMessages = (variables: IAssistantMessagesProps) => {
  const { data, error, loading, refetch } = useQuery(GET_ASSISTANT_MESSAGES, {
    variables,
  })
  return {
    messages: data?.getAssistantMessages || [],
    loading,
    error,
    refetch,
  }
}
