import React, { ReactElement } from 'react'
import { Container } from './styled-components'

import Loader from 'components/common/Loader'

import {
  DrawerHeaderSteps,
  DrawerStepsTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import { FormContainer } from 'components/common/Form'

import SuperAdminDrawer from './SuperAdminDrawer'
import AddCourseTemplateInnerComponent from './AddCourseTemplateInnerComponent'

import { AddCourseDrawerProps } from 'hooks/course/addCourseDrawer/addCourseDrawer.interface'
import useAddCourseDrawer from 'hooks/course/addCourseDrawer/useAddCourseDrawer'

const AddCourseDrawer = ({
  onClose,
  data,
}: AddCourseDrawerProps): ReactElement => {
  const {
    values,
    handleChange,
    errors,
    touched,
    handleImageChange,
    setFieldValue,
    progress,
    imageLoading,
    handleVideoChange,
    courseVideo,
    handleUploadFile,
    fileLoading,
    handleCloseDrawer,
    handleChangeStep,
    handleButtonClick,
    step,
    t,
    userData,
    createCourseLoading,
    isSuperAdmin,
  } = useAddCourseDrawer({ onClose, data })

  const getStepContent = (stepIndex: number): ReactElement | string => {
    switch (stepIndex) {
      case 0:
        return (
          <AddCourseTemplateInnerComponent
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleImageChange={handleImageChange}
            setFieldValue={setFieldValue}
            videoUploadProgress={progress}
            imageUploading={imageLoading}
            handleVideoChange={handleVideoChange}
            courseVideo={courseVideo}
            handleUploadFile={handleUploadFile}
            fileLoading={fileLoading}
            company={data}
            handleCloseDrawer={handleCloseDrawer}
            handleChangeStep={handleChangeStep}
            handleButtonClick={handleButtonClick}
            step={step}
            t={t}
          />
        )

      case 3:
        return (
          <SuperAdminDrawer
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleImageChange={handleImageChange}
            setFieldValue={setFieldValue}
            videoUploadProgress={progress}
            imageUploading={imageLoading}
            handleVideoChange={handleVideoChange}
            courseVideo={courseVideo}
            handleUploadFile={handleUploadFile}
            fileLoading={fileLoading}
            company={data}
            handleCloseDrawer={handleCloseDrawer}
            handleButtonClick={handleButtonClick}
            t={t}
          />
        )
      default:
        return 'Error'
    }
  }

  return (
    <Container>
      <DrawerHeaderSteps>
        <DrawerStepsTitle>
          {`${t('actions.add')}${
            userData.selectedCompany?.id ? '' : ` ${t('general.template')}`
          } ${t('general.course')}`}
        </DrawerStepsTitle>

        {createCourseLoading && <Loader />}
      </DrawerHeaderSteps>

      <DrawerContent headerHeight={141}>
        <FormContainer>{getStepContent(isSuperAdmin ? 3 : step)}</FormContainer>
      </DrawerContent>
    </Container>
  )
}

export default AddCourseDrawer
