import { useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import { GET_USERS, UPDATE_USER_PASSWORD } from 'gql/users.query'

const updateUserPasswordService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateUserPasswordMutation, { loading, error }] = useMutation(
    UPDATE_USER_PASSWORD,
    {
      update(cache, { data: { updateUser: document } }) {
        updateCache({
          objectName: 'usersByRole',
          query: GET_USERS,
          cache,
          document,
          action: 'update',
          params,
        })
      },
    },
  )

  return {
    updateUserPasswordMutation,
    loading,
    error,
  }
}

export default updateUserPasswordService
