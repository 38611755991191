import { SVGProps } from 'react'

export interface PhoneFields {
  code: string
  dialCode: string
}

export type T = (key: string) => string

export interface IFilterDropDownOption {
  value: string
}

export interface IBreadCrumbItem {
  label: string
  icon?: JSX.Element
}

export enum SnackbarVariants {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export interface IAttachment {
  _id: string
  link: string
  name: string
  size: number
  type: string
}

export interface FilterableField {
  type: string
  value: string
}

export interface FilterableNestedField {
  type: string
  value: string | string[]
  nestedField?: string
}
export interface FilterableNestedArrayField {
  type: string
  value: string[]
  nestedField: string
}

export interface FilterableRangeField {
  type: string
  min: number
  max: number
}

export interface TagsInterface {
  label: string
  id: string
}

export interface SelectFilterOption {
  value: string
  label: string
}

export type CropperResult = { name: string; file: string } | ''

export interface SvgProps extends SVGProps<SVGSVGElement> {
  className?: string
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void
}
