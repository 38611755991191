import Tooltip from '@mui/material/Tooltip'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList'
import Checkbox from 'components/common/Checkbox'
import React from 'react'
import { List, ListItem, ErrorContainer } from './styled-components'
import { TFunction } from 'interfaces/TFunction'

interface itemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  phone: string
  phoneFields: {
    dialCode: string
    code: string
  }
  message: string[]
  error: boolean
  gender: string
  biography: string
  note: string
}

export const columnConfig = (
  selectItem: any,
  isImported: boolean,
  t: TFunction,
  disabledCheck?: boolean,
) => {
  const grid = [
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: `${t('user_details.first_name')}*`,
      render: (item: itemProps) => (
        <div
          style={{ cursor: 'pointer', padding: disabledCheck ? 10 : 'auto' }}
        >
          {item.firstName}
        </div>
      ),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.last_name')}*`,
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.email')}*`,
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: itemProps) => {
        return (
          <div>
            {item.phoneFields && `(${item.phoneFields.dialCode})`}
            {item.phone}
          </div>
        )
      },
    },
    {
      id: 'gender',
      numeric: false,
      disablePadding: false,
      label: t('user_details.gender'),
      render: (item: itemProps) => <div>{item.gender}</div>,
    },
    {
      id: 'biography',
      numeric: false,
      disablePadding: false,
      label: t('user_details.biography'),
      render: (item: itemProps) => (
        <Tooltip
          title={<span style={{ fontSize: '12px' }}>{item.biography}</span>}
        >
          <FeaturedPlayListIcon />
        </Tooltip>
      ),
    },
    {
      id: 'note',
      numeric: false,
      disablePadding: false,
      label: t('user_details.note'),
      render: (item: itemProps) => (
        <Tooltip title={<span style={{ fontSize: '12px' }}>{item.note}</span>}>
          <FeaturedPlayListIcon />
        </Tooltip>
      ),
    },
  ]

  if (!disabledCheck) {
    grid.unshift({
      label: '',
      id: 'isChecked',
      render: (item: itemProps) => (
        <Checkbox
          checked={item.isChecked}
          onChange={() => selectItem(item.id)}
          disabled={isImported}
        />
      ),
      numeric: false,
      disablePadding: true,
    })
  }

  if (isImported || disabledCheck) {
    grid.push({
      id: 'error',
      numeric: false,
      disablePadding: false,
      label: 'Error',
      render: (item: itemProps) => {
        const messages = (item && item.message) || []

        if (!item.error) return <></>

        return (
          <ErrorContainer error={item.error}>
            <Tooltip
              title={
                <List>
                  {messages.map((i: string, index: number) => (
                    <ListItem key={index}>* {i}</ListItem>
                  ))}
                </List>
              }
            >
              <ErrorOutlineIcon color="secondary" />
            </Tooltip>
          </ErrorContainer>
        )
      },
    })
  }

  return grid
}
