import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { CenteredText } from '../../testLayout/styled-components'
import { Button } from 'components/common/Button'
import AddCircleIcon from '@mui/icons-material/Add'
import { t } from 'i18next'
import IconButton from '@mui/material/IconButton'

import { Header, LayoutWrapper, Wrapper, Whiteboard } from './styled-components'
import SyncIcon from '@mui/icons-material/Sync'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import Drawer from 'components/common/Drawer'

import Grid from 'components/common/GridV2'

import _ from 'lodash'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import { IFilterOptions, IGroupsLayout } from './groupsLayout.interface'
import { useTestGroupDetailsServices } from 'hooks/tests'
import { ToolbarItem } from 'components/common/Toolbar'
import { Input } from 'components/common/TextInput'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import MyLoader from 'loading/loading'
import { defaultStyles } from 'pages/courses/CourseQuizzes/styled.components'
import AddGroupsDrawer from '../addGroupDrawer/AddGroupsDrawer'
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import TablePagination from 'components/common/Pagination/TablePagination'
import Presentation from 'components/common/PresentationIcon/Presentation'

const GroupsLayout = ({
  testAssessmentId,
  groups,
  totalGroupCount = 0,
  groupsLoading,
  refetchGroups,
  companyId,
}: IGroupsLayout) => {
  const {
    state,
    onSearchSubmit,
    handleAddGroupsToCourse,
    handleDeleteGroups,
    config,
    actions,
    handleResetClick,
    selectItem,
    selectAllItem,
    handleClick,
    setAddGroupDrawer,
    selectedItemsOnPage,
    filterDrawer,
    selectedItem,
    setSearchText,
    handleChangeGroupRowsPerPage,
    handleGroupsPaginationClick,
    searchText,
    setFilterDrawer,
    perPage,
    currentPage,
    setCurrentPage,
    addGroupDrawer,
  } = useTestGroupDetailsServices({
    testAssessmentId,
    refetchGroups,
    groups,
  })

  return (
    <>
      <LayoutWrapper>
        <Header>
          <Wrapper>
            <Wrapper>
              <Button
                text={t('tests_layout.add_group')}
                color="secondary"
                onClick={(): void => setAddGroupDrawer(true)}
                icon={<AddCircleIcon />}
                background="#06C68F"
              />
              <GridMultipleActions
                selectedItems={selectedItemsOnPage.length}
                actions={[
                  {
                    id: 0,
                    color: 'secondary',
                    hide: !state.userPermission.deleteModule,
                    tooltipText: `${t('actions.delete')}`,
                    disabled: selectedItemsOnPage.length < 1,
                    onClick: (): void =>
                      handleDeleteGroups(selectedItemsOnPage),
                    component: <DeleteIcon />,
                  },
                ].filter((i: { hide: boolean }) => !i.hide)}
              />
            </Wrapper>
            <Wrapper>
              <ToolbarItem>
                <IconButton
                  disabled={
                    (!filterDrawer.filterOptions ||
                      !Object.values(filterDrawer.filterOptions).length) &&
                    !searchText
                  }
                  onClick={handleResetClick}
                >
                  <SyncIcon fontSize="small" />
                </IconButton>
                <IconButton>
                  <FilterIcon
                    onClick={() =>
                      setFilterDrawer((prev: IFilterOptions) => ({
                        ...prev,
                        opened: true,
                      }))
                    }
                  />
                </IconButton>
              </ToolbarItem>
              <ToolbarItem>
                <form onSubmit={onSearchSubmit}>
                  <Input
                    label={t('general.search_placeholder')}
                    type="text"
                    size="small"
                    value={searchText}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setSearchText(e.target.value)
                    }
                    icon={
                      <IconButton onClick={onSearchSubmit}>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                    iconInStart
                  />
                </form>
              </ToolbarItem>
            </Wrapper>
          </Wrapper>
        </Header>
        {groupsLoading && !groups.length ? (
          <MyLoader width={250} style={defaultStyles} />
        ) : groups.length ? (
          <div>
            <Grid
              data={groups}
              width={40}
              indexOfSpecificWidth={0}
              config={config}
              redirectToDetailsHandler={handleClick}
              actionConfig={actions}
              selectItem={selectItem}
              loading={groupsLoading}
              selectAllItem={selectAllItem}
              selected={_.size(selectedItem[currentPage] || [])}
              selectedItems={selectedItem[currentPage] || []}
              resetVisible={false}
            />
            <TablePagination
              currentPage={currentPage}
              rowsPerPage={perPage}
              count={totalGroupCount}
              handleChangePage={handleGroupsPaginationClick}
              handleChangeRowsPerPage={handleChangeGroupRowsPerPage}
            />
          </div>
        ) : (
          <Whiteboard>
            <Presentation />
            <CenteredText>
              <h1> {t('tests_layout.no_groups')} </h1>
              <h4>{t('tests_layout.add_groups')} </h4>
            </CenteredText>
            <Button
              text={t('tests_layout.add_group')}
              color="secondary"
              onClick={(): void => setAddGroupDrawer(true)}
              icon={<AddCircleIcon />}
              background="#06C68F"
            />
          </Whiteboard>
        )}
      </LayoutWrapper>
      <Drawer
        opened={addGroupDrawer}
        toggleDrawer={(): void => {
          // No operation because we don't want to perform any action
        }}
        totalWidth="600px"
      >
        <AddGroupsDrawer
          onClose={(groupIds?: string[]) => {
            groupIds
              ? handleAddGroupsToCourse(groupIds)
              : setAddGroupDrawer(false)
          }}
          testAssessmentId={testAssessmentId}
          companyId={state.selectedCompany?.id}
          hasSearchByCompany={false}
        />
      </Drawer>
      <Drawer
        opened={filterDrawer.opened}
        toggleDrawer={() => {}}
        totalWidth="700px"
      >
        <FilterDrawer
          closeDrawer={() =>
            setFilterDrawer({ ...filterDrawer, opened: false })
          }
          isTestGroupsFilter
          setFilterOptions={setFilterDrawer}
          refetchGroup={refetchGroups}
          setCurrentPage={setCurrentPage}
          defaultFilter={{}}
          companyId={companyId}
        />
      </Drawer>
    </>
  )
}

export default GroupsLayout
