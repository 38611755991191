import { useQuery } from '@apollo/client'
import { GET_LESSON_BY_ID } from 'gql/lesson/lesson.query'
import { IGetLessonByIdServiceReturn } from './getLessonById.interface'

export const getLessonByIdService = (lesson: {
  id: string
}): IGetLessonByIdServiceReturn => {
  const { data, loading } = useQuery(GET_LESSON_BY_ID, {
    variables: {
      lessonId: lesson.id,
    },
  })

  const lessonData = data?.getLessonById

  return {
    lessonData,
    loading,
  }
}
