import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'
import { SET_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import axios, { AxiosResponse } from 'axios'
import * as Yup from 'yup'
import { LessonSchema } from 'helpers/validationSchemas'
import useEditLessonService from 'hooks/lessons/useEditLessonService'
import {
  useGenerateUploadVideoLinks,
  useUploadImage,
  useUploadFile,
  useUploadAudioService,
  useUploadCaptions,
} from 'hooks/helpers/useHelpersService'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'
import { useReConversion } from 'hooks/lessons/useReConversion'
import { useUserValue } from 'context/UserContext'
import useSnackbarAlert from 'hooks/useSnackbar'
import {
  IAudioFile,
  IFormData,
  IUploadResponse,
} from 'hooks/lessons/useAddLessonDrawer/useAddLesson.interface'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import {
  ILink,
  IUseEditDrawerProps,
  UseEditLessonDrawerReturnType,
} from './useEditLesson.interface'
import { getLessonByIdService } from 'services/lessons/getLessonById/getLessonByIdService'
import { getTopicsService } from 'services/topics/getTopicsService/getTopicsService'

const formData: IFormData = {
  name: '',
  topic: null,
  video: null,
  attachment: null,
  description: '',
  contentDocument: null,
  audio: null,
  duration: undefined,
  subtitle: null,
  transcript: '',
}

const constants = {
  video:
    'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
  audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
  file: 'application/pdf|application/msword|application/plain|text/plain|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel',
}
const combinedConstants = Object.values(constants).join().split('|').join(',')

export const useEditLessonDrawer = ({
  data,
  onClose,
  moduleId,
  lesson,
}: IUseEditDrawerProps): UseEditLessonDrawerReturnType => {
  const { t } = useTranslation()

  const [state, dispatch] = useData()
  const [userState] = useUserValue()
  const { toggleDrawerConfirm } = useAction()
  const ref = useRef<NodeJS.Timeout | undefined>()
  const [file, setFile] = useState<File | undefined>()

  const [videoDuration, setVideoDuration] = useState(0)
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0)
  const [fileTypes, setFileTypes] = useState('video')

  const { editLesson } = useEditLessonService({
    lessonId: lesson.id,
  })

  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()
  const { uploadFile, fileLoading } = useUploadFile()
  const { uploadCaptions } = useUploadCaptions()
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadAudio } = useUploadAudioService()
  const { topicsList, loadTopics, getTopicsLoading } = getTopicsService()
  const [currentLesson, setCurrentLesson] = useState<IFormData>()
  const { lessonData } = getLessonByIdService({ id: lesson.id })

  const { handleReConversion } = useReConversion()
  const { setSnackbar } = useSnackbarAlert()
  const [conversionLoading, setConversionLoading] = useState(false)
  const [isSubtitlesUpdated, setIsSubtitlesUpdated] = useState(false)
  const [isVideoUpdated, setIsVideoUpdated] = useState(false)
  const videoNameWithoutExtension = lessonData?.video?.name

  // // we need this service in case when user uploading description or transcript
  // const { extractText, loading: extractTextLoading } = useExtractTextService()
  const uploadVideoService = (
    url: string,
  ): void | Promise<AxiosResponse<unknown>> => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': file?.type,
      },
      onUploadProgress: ({ total, loaded }) => {
        setUploadVideoProgress((loaded / total) * 100)
        if ((loaded / total) * 100 === 100) {
          setUploadVideoProgress(0)
        }
      },
    })
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: formData,
    validationSchema: Yup.object(LessonSchema),
    onSubmit: (values: IFormData) => {
      const data = removeTypeNameFromObject({ ...values })

      if (videoDuration) {
        data.duration = videoDuration
      } else {
        delete data.duration
      }

      const topicId = lesson.topic ? lesson.topic.id : data.topic.value

      delete data.topic

      if (data.video) {
        const { __typename, ...args } = data.video
        data.video = {
          id: args.id,
          title: args.title,
        }
      }

      if (data.thumbnail) {
        data.thumbnail = data.thumbnail.link
      }

      const input = {
        ...data,
        isSubtitlesUpdated,
        isVideoUpdated,
      }

      if (fileTypes === 'video' && data.video) {
        const splittedLessonPath =
          values?.video &&
          values?.video?.links
            ?.find((linkObj: ILink) => linkObj.fileType === 'Original')
            .url.split('/')

        const lessonNameWithExtension = splittedLessonPath[4]

        input.video = {
          ...data.video,
          name: lessonNameWithExtension,
        }
      }

      editLesson(topicId, lesson.id, input, onSuccess)
    },
  })

  useEffect(() => {
    if (lessonData) {
      const lesson = lessonData
      const dataClone: IFormData = {}

      const { topic, ...args } = lesson

      for (const key in formData) {
        if (
          ['video', 'audio', 'contentDocument'].includes(key) &&
          args[key as keyof typeof args]
        ) {
          setFileTypes(key)
        }
        dataClone[key as keyof IFormData] = args[key as keyof typeof args]
      }

      dataClone.topic = {
        label: lesson.topicName || lesson.topic.name,
        value: lesson.topicId || lesson.topic.id,
      }

      if (dataClone.video || dataClone.contentDocument) {
        dataClone.thumbnail = {
          name: '',
          fileType: '',
          link: dataClone.video
            ? dataClone.video.thumbnail
            : dataClone?.contentDocument?.thumbnail,
        }
      }
      setValues(dataClone)
      setCurrentLesson(dataClone)
    }
  }, [lessonData])

  const handleGenerateUploadLink = (): void => {
    const videos = [{ fileName: file?.name, type: file?.type }]

    generateUploadVideoLinks(
      videos,
      'lesson',
      (arg: string) => uploadVideoService(arg),
      (args: IUploadResponse) => {
        setFieldValue('video', args.video)
        setFieldValue('video.title', args.video.name)
        setUploadVideoProgress(0)
      },
      true,
    )
  }

  const generateUploadAudioLink = (): void => {
    const audioFile: IAudioFile = { fileName: file?.name, type: file?.type }

    uploadAudio(
      audioFile,
      (arg: string) => uploadVideoService(arg),
      (fileId: string) => {
        setFieldValue('audio', {
          id: fileId,
          name: file?.name,
          fileType: file?.name.split('.').pop(),
        })

        setUploadVideoProgress(0)
      },
    )
  }

  useEffect(() => {
    if (file && file.type && constants.audio.includes(file.type)) {
      generateUploadAudioLink()
      setFileTypes('audio')
    }

    if (file && file.type && constants.video.includes(file.type)) {
      handleGenerateUploadLink()
      setFileTypes('video')
    }

    if (file && file.type && constants.file.includes(file.type)) {
      uploadFile(file, 'lesson/content', (link: string) =>
        setFieldValue('contentDocument', {
          name: file.name,
          fileType: file.name.split('.').slice(-1)[0],
          link,
        }),
      )
      setFileTypes('contentDocument')
    }
  }, [file])

  const handleContentChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsVideoUpdated(true)
    const file = e?.target?.files?.[0]
    if (file?.type && constants.file.includes(file.type)) {
      setFile(file)
      return
    }
    const video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = function (): void {
      window.URL.revokeObjectURL(video.src)
      const duration = video.duration
      setVideoDuration(Math.round(duration))
    }
    if (file) {
      video.src = URL.createObjectURL(file)

      setUploadVideoProgress(1)
      setFile(file)
    }
  }

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: string,
  ): void => {
    const { files } = e.target
    const file = files?.[0]
    const fileType = file?.name.split('.').slice(-1)[0]

    if (field === 'subtitle' && file) {
      setIsSubtitlesUpdated(true)
      uploadCaptions(file, `${videoNameWithoutExtension}`, (link: string) =>
        setFieldValue(field, {
          name: file.name,
          fileType,
          link,
        }),
      )
    } else {
      uploadFile(file!, `lesson/${field}`, (link: string) =>
        setFieldValue(field, {
          name: file?.name,
          fileType,
          link,
        }),
      )
    }
  }

  const deleteContentFile = (): void => {
    setFile(undefined)
    setUploadVideoProgress(0)
    setFieldValue('video', null)
    setFieldValue('audio', null)
    setFieldValue('contentDocument', null)
    setFieldValue('subtitle', null)
    setFieldValue('thumbnail', null)
    setVideoDuration(0)
  }

  const loadDataOptions = (e: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e
      loadTopics({
        variables: {
          moduleId: moduleId,
          filter: {
            name: {
              type: LowerCaseFilterableFieldType.MATCH,
              value,
            },
          },
        },
      })
    }, 300)

  const handleSelectChange = (e: string): void => {
    clearTimeout(ref.current as NodeJS.Timeout)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  const onSuccess = (): void => {
    onClose()
    toggleDrawerConfirm(false, '')
  }

  const handleCloseDrawer = (): void => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editLesson',
        values,
        compareTo: currentLesson,
        isVideoUpdated,
      },
    })
  }

  const handleImageChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: string,
  ): void => {
    const { files } = e.target
    if (!files) return
    const file = files[0]
    const reader = new FileReader()
    reader.onload = (): void => {
      const img = new Image()
      img.src = reader.result as string
      uploadImage(reader.result as string, `lesson/${field}`, (link: string) =>
        setFieldValue(field, {
          name: file.name,
          link,
          fileType: file?.name.split('.').slice(-1)[0],
        }),
      )
    }
    reader.readAsDataURL(files?.[0])
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const submitDisabled = !(
    (values.video || values.audio || values.contentDocument) &&
    !fileLoading
  )

  const topicDisabled = !(data && data.fromTopic)

  const fileExtension =
    fileTypes === 'video'
      ? (values.video && `(${values?.video?.links?.[2]?.type})`) || ''
      : (values[fileTypes as keyof IFormData] &&
          `(${values[fileTypes as keyof IFormData].fileType})`) ||
        ''

  const handleReHLSConversion = async (
    e: MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault()
    setConversionLoading(true)
    await handleReConversion(lessonData.id)
    setSnackbar({ message: 'Conversion Started', variant: 'success' })
    setConversionLoading(false)
  }

  const hasVideo: boolean = !!(
    fileTypes in values && values[fileTypes as keyof IFormData]?.name
  )
  const hasSubtitle: boolean = !!(values.subtitle && values.subtitle.name)

  // display re-run HLS conversion button only for bekatomashvili@gmail.com if lesson has subtitle and video
  const displayReConversionButton =
    userState.currentUser.email === 'bekatomashvili@gmail.com' &&
    hasVideo &&
    hasSubtitle

  return {
    handleChange,
    handleSubmit,
    handleContentChange,
    handleFileChange,
    handleImageChange,
    handleSelectChange,
    values,
    errors,
    touched,
    getTopicsLoading,
    t,
    topicDisabled,
    submitDisabled,
    fileExtension,
    handleReHLSConversion,
    displayReConversionButton,
    formData,
    uploadVideoProgress,
    fileLoading,
    setFieldValue,
    imageLoading,
    handleCloseDrawer,
    deleteContentFile,
    generateUploadAudioLink,
    fileTypes,
    conversionLoading,
    currentLesson,
    topicsList,
    combinedConstants,
  }
}
