import { useQuery } from '@apollo/client'
import { GET_QUESTION_IDS } from 'gql/questions.query'

const getQuestionIdsService = (filter: any) => {
  const { data, loading, error } = useQuery(GET_QUESTION_IDS, {
    variables: {
      filter,
    },
  })

  return {
    questionIds: data?.getQuestionIds || [],
    loading,
    error,
  }
}

export default getQuestionIdsService
