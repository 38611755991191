import React, { createContext, useContext, useState, ReactNode } from 'react'

interface FilterContextType {
  createDate: [Date | undefined, Date | undefined]
  setCreateDate: React.Dispatch<
    React.SetStateAction<[Date | undefined, Date | undefined]>
  >
}

const FilterContext = createContext<FilterContextType | undefined>(undefined)

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [createDate, setCreateDate] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined])

  return (
    <FilterContext.Provider value={{ createDate, setCreateDate }}>
      {children}
    </FilterContext.Provider>
  )
}

export const useFilterContext = () => {
  const context = useContext(FilterContext)
  if (context === undefined) {
    throw new Error('useFilterContext must be used within a FilterProvider')
  }
  return context
}
