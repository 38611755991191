import styled from 'styled-components'

export const Connector = styled.div<{
  activeStep: number
  stepCount: number
  accentColor: string
}>`
  position: absolute;
  top: 27%;
  z-index: 1;
  left: 7%;
  right: 7%;
  height: 4px;
  background-color: rgba(204, 204, 204, 0.5);

  &:after {
    transition: 0.5s ease-in-out;
    content: '';
    border-radius: 25px;
    position: absolute;
    height: 4px;
    width: ${props => `${(props.activeStep / props.stepCount) * 115}%`};
    background-color: ${props => props.accentColor};
  }
`

export const StepContainer = styled.div<{
  aligment: 'vertical' | 'horizontal'
}>`
  display: flex;
  position: relative;
  flex-direction: ${props =>
    props.aligment === 'vertical' ? 'column' : 'row'};
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Step = styled.div<{ stepCount: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  flex-direction: column;
  transition: 0.5s;
  position: relative;
  width: calc(100% / ${props => props.stepCount});
`

export const StepLabel = styled.h6<{ active: boolean }>`
  font-size: 14px;
  transition: 0.5s;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  top: 78%;
`

export const StepIcon = styled.div<{
  accentColor: string
  tileSize: 'small' | 'medium' | 'large'
  active: boolean
  completed: boolean
  hasCursor: boolean
}>`
  cursor: ${props => (props.hasCursor ? 'pointer' : 'default')};
  background-color: ${props =>
    props.active || props.completed ? props.accentColor : '#ccc'};
  border-radius: 50%;
  margin-bottom: 30px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: 0.3s;
  transition-delay: ${props => (props.active ? '0.4s' : '0s')};
  transform: ${props => (props.active ? 'scale(1.2)' : 'scale(1)')};
  box-shadow: ${props => (props.active ? '0 4px 10px 0 rgba(0,0,0,.25)' : '')};
  width: ${props =>
    props.tileSize === 'small'
      ? '30px'
      : props.tileSize === 'medium'
      ? '45px'
      : '70px'};
  height: ${props =>
    props.tileSize === 'small'
      ? '30px'
      : props.tileSize === 'medium'
      ? '45px'
      : '70px'};
`
