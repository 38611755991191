import { useMutation } from '@apollo/client'

import { ADD_MODULE } from 'gql/modules.query'

const addModuleService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addModuleMutation, { loading, error }] = useMutation(ADD_MODULE, {})

  return {
    addModuleMutation,
    loading,
    error,
  }
}

export default addModuleService
