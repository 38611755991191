import React, { useEffect, useState } from 'react'
import { Container, ErrorMessage } from './styled-components'
import Select, { OptionProps, SingleValueProps, components } from 'react-select'
import { customStyles } from './SelectField'
import {
  AsyncSelectProps,
  AsyncSelectOptions,
  AsyncSelectOption,
} from './select.interface'

const SelectAsync = (props: AsyncSelectProps) => {
  const [options, setOptions] = useState<AsyncSelectOptions>([])
  useEffect(() => {
    const localOptions = props.data.map((i: any) => ({
      label: props.labelDataKey
        ? i[props.labelDataKey]
        : props.labelDataName
        ? props.labelDataName(i)
        : i,
      value:
        props.valueDataKeyPrimary && i[props.valueDataKeyPrimary]
          ? i[props.valueDataKeyPrimary]
          : props.valueDataKey
          ? i[props.valueDataKey]
          : i,
      icon: props.iconDataKey && i[props.iconDataKey],
    }))
    setOptions(localOptions)
  }, [props.data])

  const { Option } = components
  const CustomSelectOption = (
    props: OptionProps<AsyncSelectOption, boolean>,
  ) => (
    <Option {...props}>
      {props.data.label}
      {props.data.icon}
    </Option>
  )

  const CustomSelectValue = (props: SingleValueProps<AsyncSelectOption>) => (
    <div>
      {props.data.label}
      {props.data.icon}
    </div>
  )

  return (
    <Container error={!!(props.error && props.touched)}>
      <Select
        isLoading={props.loading}
        isMulti={props.isMulti}
        options={options}
        value={props.value}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        placeholder={props.label}
        isDisabled={props.disabled}
        isClearable={props.isClearable}
        styles={customStyles}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
        }}
      />

      {props.error && props.errorMessage && props.touched && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </Container>
  )
}

export default SelectAsync
