import { useMutation } from '@apollo/client'

import { REMOVE_QUESTIONS_FROM_TEST } from 'gql/questions.query'
import { GET_TEST } from 'gql/tests/tests.query'
const removeQuestionsFromTestAssessmentService = (testId: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [removeQuestionsFromTestAssessmentMutation, { loading, error }] =
    useMutation(REMOVE_QUESTIONS_FROM_TEST, {
      refetchQueries: [
        {
          query: GET_TEST,
          variables: {
            testId,
          },
        },
      ],
    })

  return {
    removeQuestionsFromTestAssessmentMutation,
    loading,
    error,
  }
}

export default removeQuestionsFromTestAssessmentService
