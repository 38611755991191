import { useMutation } from '@apollo/client'

import {
  DELETE_STUDENTS_FROM_GROUP,
  GET_GROUP_BY_ID,
} from 'gql/group/group.query'

const deleteStudentsService = (groupId: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteStudents] = useMutation(DELETE_STUDENTS_FROM_GROUP, {
    refetchQueries: [
      {
        query: GET_GROUP_BY_ID,
        variables: { id: groupId },
      },
    ],
  })

  return {
    deleteStudents,
  }
}

export default deleteStudentsService
