import useSnackbarAlert from 'hooks/useSnackbar'
import { InsertGroupsWithTestsInput } from 'interfaces/groups'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import importGroupsWithTestsService from 'services/group/importGroupsWithTestsService'

const useImportGroupsWithTests = () => {
  const { t } = useTranslation()
  const { insertGroupsWithTests, loading } = importGroupsWithTestsService()
  const { setSnackbar } = useSnackbarAlert()

  const importGroupsWithTests = async (
    values: InsertGroupsWithTestsInput[],
    companyId: string,
    callBack?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { errors, data } = await insertGroupsWithTests({
      variables: {
        groups: values,
        companyId,
      },
    })

    let groupsData = null

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `${t('general.groups')} ${t('messages.edited')}`
      variant = 'success'
      groupsData = data.insertGroupsWithTests
    }

    callBack(groupsData)

    setSnackbar({ message, variant })
  }

  return {
    importGroupsWithTests,
    loading,
  }
}

export default useImportGroupsWithTests
