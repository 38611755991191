import React, { useRef } from 'react'
import { useState } from 'react'
import { useFetchTasks } from 'services/tasks/getTasksService'
import { useDeleteTask } from 'services/tasks/deleteTaskService'
import { useSwal } from 'hooks/useSwal'
import { useNavigate } from 'react-router-dom'
import AddCircleIcon from '@mui/icons-material/Add'
import { Button } from 'components/common/Button'
import { columnConfig, actionConfig } from 'pages/tasks/gridConfig'
import {
  LowerCaseFilterableFieldType,
  UpperCaseFilterableFieldType,
} from 'enums/filterEnum'
import { useFetchCoursesOptions } from 'services/analytics/coursesOptionsService'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'

import { useFetchGroups } from 'hooks/group/useGroups'
import { IGroup } from './useTaskLayout.interface'
import { IFilterType, IMappedOptions } from './useTaskDrawer.interface'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import { debounce } from 'lodash'

const useTaskLayout = (
  companyId: string | null,
  t: (key: string) => string = (key): string => key,
) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [filter, setFilter] = useState<IFilterType>({})
  const [isTypeSearch, setIsTypeSearch] = useState<boolean>(false)
  const [taskDrawerOpened, setTaskDrawerOpened] = useState<boolean>(false)
  const [currentTaskId, setCurrentTaskId] = useState<string | null>(null)
  const [selectedCourse, setSelectedCourse] = useState<IGroup | null>(null)
  const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null)
  const [selectedTasks, setSelectedTasks] = useState<string[]>([])

  const { fireSwal } = useSwal()
  const navigate = useNavigate()

  const createButton = (): React.ReactElement => (
    <Button
      text={t('tasks_layout.create_task')}
      color="secondary"
      onClick={(): void => setTaskDrawerOpened(true)}
      icon={<AddCircleIcon />}
      background="#06C68F"
    />
  )
  const currentPage = 1
  const perPage = 10

  const {
    tasks,
    loading: tasksLoading,
    error,
    refetch,
    fetchMore,
  } = useFetchTasks(companyId || '', filter, currentPage, perPage)

  const { deleteTask } = useDeleteTask()
  const { exportData } = useExportDataService()
  const exportDataAction = 'task'

  const handleDeleteClick = async (taskId: string): Promise<void> => {
    fireSwal({
      title: t('tasks_layout.sure_delete_task'),
      confirmText: t('tasks_layout.yes_delete'),
      onConfirm: async () => {
        try {
          await deleteTask([taskId])
          refetch()
        } catch (error) {
          console.error('Failed to delete task:', error)
        }
      },
    })
  }

  const handleMultipleDeleteClick = (selectedTaskIds: string[]): void => {
    fireSwal({
      title: t('tasks_layout.sure_delete_tasks'),
      confirmText: t('tasks_layout.yes_delete'),
      onConfirm: async () => {
        try {
          await deleteTask(selectedTaskIds)
          refetch()
        } catch (error) {
          console.error('Failed to delete task:', error)
        }
      },
    })
  }

  const handleEditClick = (taskId: string): void => {
    setCurrentTaskId(taskId)
    setTaskDrawerOpened(true)
  }

  const handleTaskSelection = (taskId: string): void => {
    if (selectedTasks.includes(taskId)) {
      setSelectedTasks(selectedTasks.filter((id) => id !== taskId))
    } else {
      setSelectedTasks([...selectedTasks, taskId])
    }
  }

  const actions = actionConfig(
    handleDeleteClick,
    handleEditClick,
    t || ((key: string): string => key),
    setIsTypeSearch,
  )

  const gridConfig = columnConfig(handleTaskSelection, selectedTasks, t)

  const filterGroups = {
    company: { type: LowerCaseFilterableFieldType.EXACT, value: companyId },
  }
  const courseFilter = {
    companyId: {
      type: UpperCaseFilterableFieldType.ARRAY_IN,
      value: companyId,
    },
  }

  const { groups, loading: groupsLoading } = useFetchGroups(1, 0, filterGroups)
  const { courses, loading: loadingCourses } =
    useFetchCoursesOptions(courseFilter)

  const selectAll = (): void => {
    if (tasks && selectedTasks.length === tasks.length) {
      setSelectedTasks([])
    } else {
      setSelectedTasks(tasks?.map((task) => task._id) || [])
    }
  }

  const isAllSelected = (): boolean => {
    return !!tasks && tasks.length > 0 && selectedTasks.length === tasks.length
  }

  const debouncedSearch = useRef(
    debounce((newSearchValue: string) => {
      setFilter((prevFilter: IFilterType) => ({
        ...prevFilter,
        name: newSearchValue,
      }))
      refetch()
    }, 500),
  ).current

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setFilter((prevFilter: IFilterType) => ({
      ...prevFilter,
      name: searchValue,
    }))
    refetch()
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearchValue = e.target.value
    setIsTypeSearch(true)
    setSearchValue(newSearchValue)
    debouncedSearch(newSearchValue)
  }

  const handleExportData = () => {
    if (selectedTasks.length > 0) {
      exportData(exportDataAction, selectedTasks, (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const onCourseChange = (course: IGroup): void => {
    if (course) {
      setSelectedCourse(course)
      setFilter((prevFilter: IFilterType) => ({
        ...prevFilter,
        courseIds: course.value,
      }))
    } else {
      setSelectedCourse(null)
      setFilter((prevFilter: IFilterType) => ({
        ...prevFilter,
        courseIds: null,
      }))
    }
  }

  const onGroupChange = (group: IGroup): void => {
    if (group) {
      setSelectedGroup(group)
      setFilter((prevFilter: IFilterType) => ({
        ...prevFilter,
        groupIds: group.value,
      }))
    } else {
      setSelectedGroup(null)
      setFilter((prevFilter: IFilterType) => ({
        ...prevFilter,
        groupIds: null,
      }))
    }
  }

  const mappedCourses = courses?.data?.map((course: IMappedOptions) => ({
    label: course.name,
    value: course.id,
  }))

  const mappedGroups = groups?.data?.map((group: IMappedOptions) => ({
    label: group.name,
    value: group.id,
  }))

  const breadCrumbData = [
    {
      label: 'Tasks',
      icon: <Learning />,
    },
  ]

  const handleTaskNavigation = (taskId: { _id: string }): void => {
    navigate(`/tasks/${taskId._id}`)
  }

  const staticData = tasks?.map((task: any) => ({ ...task, id: task._id }))

  return {
    taskDrawerOpened,
    setTaskDrawerOpened,
    currentTaskId,
    setCurrentTaskId,
    tasks,
    refetch,
    handleDeleteClick,
    handleEditClick,
    handleSearchChange,
    handleSearchSubmit,
    onCourseChange,
    onGroupChange,
    mappedCourses,
    mappedGroups,
    handleTaskNavigation,
    staticData,
    gridConfig,
    actions,
    createButton,
    breadCrumbData,
    searchValue,
    selectedCourse,
    selectedGroup,
    tasksLoading,
    groupsLoading,
    loadingCourses,
    selectAll,
    selectedTasks,
    setSelectedTasks,
    isAllSelected,
    handleTaskSelection,
    handleMultipleDeleteClick,
    handleExportData,
    isTypeSearch,
  }
}

export default useTaskLayout
