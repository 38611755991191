import React from 'react'

interface AudioPlayerProps {
  url: string
}

const AudioPlayer = (props: AudioPlayerProps): React.JSX.Element => {
  return (
    <audio controls autoPlay>
      <source src={props.url} />
    </audio>
  )
}

export default AudioPlayer
