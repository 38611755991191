import React from 'react'
import { styled } from '@mui/material/styles'
import { Pagination as MUIPagination } from '@mui/material'
import { Select } from '@mui/material'

const PREFIX = 'Pagination'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))

interface PaginationProps {
  currentPage: number
  totalPages: number
  perPage?: number
  handleChange: any
  handlePerPageChange?: any
}

const Pagination = ({
  currentPage,
  totalPages,
  handleChange,
  perPage,
  handlePerPageChange,
}: PaginationProps) => {
  return (
    <Root className={classes.root}>
      {perPage && (
        <Select
          variant="standard"
          native
          value={perPage}
          onChange={handlePerPageChange}
          inputProps={{
            name: 'perPage',
            id: 'perPage',
          }}
        >
          <option value={12}>12</option>
          <option value={24}>24</option>
          <option value={48}>48</option>
        </Select>
      )}
      <MUIPagination
        count={totalPages}
        shape="rounded"
        page={currentPage}
        onChange={handleChange}
      />
    </Root>
  )
}

export default Pagination
