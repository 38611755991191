import styled from 'styled-components'

export const ViewSkillTestVideoCriteriasContainer = styled.div``

export const HeaderContainer = styled.h6`
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  margin: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`
export const ContentContainer = styled.div`
  background: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
  display: grid;
  font-size: 16px;
  margin-inline: 10px;
  margin-top: 20px;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
`
export const CriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const CheckedAt = styled.div`
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 12px;
  cursor: pointer;
`
export const CriteriaTitle = styled.p<{
  checked: boolean
}>`
  font-weight: ${({ checked }) => (checked ? 'bold' : 'normal')};
  font-size: 16px;
`
