import { useQuery } from '@apollo/client'

import { GET_REJECTION } from 'gql/rejections/rejections.query'
import { setRejectionStateService } from 'services/rejections/setRejectionStateService'

export const useFetchRejection = (id: string) => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_REJECTION, {
    variables: { id },
  })

  const errorMessage = error ? error?.message.split(':')[1] : ''
  return {
    rejection: data && data.getRejection,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}

export const useSetRejectionState = () => {
  const {
    setRejectionStateMutation,
    error,
    loading,
  } = setRejectionStateService()

  const setRejectionState = async (
    id: string,
    action: string,
    onSuccess: any,
  ) => {
    const { data, errors } = await setRejectionStateMutation({
      variables: { id, action },
    })

    if (errors && errors.length) {
    } else {
      if (data.setRejectionState) {
        onSuccess()
      }
    }
  }

  return {
    setRejectionState,
    error,
    loading,
  }
}
