import React, { useState, useEffect } from 'react'
import { ModuleWrapper, HelpButtonContainer } from './styled-components'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useEditQuestion } from 'hooks/questions/useEditQuestion'
import { useCreateQuestion } from 'hooks/questions/useCreateQuestion'
import { SET_FORMDATA } from 'store/types'
import HelpIcon from '@mui/icons-material/Help'
import { QuestionSchema } from 'helpers/validationSchemas'
import { useTranslation } from 'react-i18next'
import { GET_QUESTION } from 'gql/questions.query'
import {
  // useGenerateUploadVideoLinks,
  useUploadImage,
} from 'hooks/helpers/useHelpersService'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import {
  ModuleItemWrapper,
  ModuleItem,
} from 'pages/questions/styled-components'
import { Wrapper, Modules } from '../styled-components'
import Loader from 'components/common/Loader'
import Drawer from 'components/common/Drawer'
import TextInput from 'components/common/TextInput/TextInput'
import QuestionItem from '../CreateQuestionDrawer/QuestionItem'
import AddModuleToQuestionDrawer from '../CreateQuestionDrawer/AddModuleToQuestionDrawer'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import UploadContent from '../CreateQuestionDrawer/UploadContent'
import { useUserValue } from 'context/UserContext'
import { Button } from 'components/common/Button'
import { QuestionTypes } from '../staticData'
import { QuestionTypeEnums } from '../CreateQuestionDrawer/CreateQuestion.interface'
import {
  // FormLabel,
  StyledFormField,
} from '../CreateQuestionDrawer/styled-components'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Radio } from '@mui/material'
import { GET_TAGS } from 'gql/skilss.query'
import { TagsInterface } from 'interfaces/common'
import CloseIcon from '@mui/icons-material/Close'
import MyLoader from 'loading/loading'

interface Params {
  title: string
  currentModule: any
  questionId: string
}
interface SelectedModule {
  moduleId: string
  name: string
}
interface UploadedFile {
  type: string
  link: string
  uploading: boolean
}

const EditQuestionDrawer = ({
  title,
  currentModule,
  questionId,
}: Params): React.JSX.Element => {
  const { t } = useTranslation()
  const [userState] = useUserValue()

  const { data, loading } = useQuery(GET_QUESTION, {
    variables: {
      questionId,
      type: currentModule.moduleId ? 'module' : '',
    },
  })

  const { data: tags } = useQuery(GET_TAGS, {
    variables: {
      companyId: currentModule.manualCompanyId || userState.selectedCompany?.id,
    },
  })

  const { editQuestion, editQuestionLoading } = useEditQuestion({
    questionId,
    moduleId: currentModule ? currentModule.moduleId : '',
  })
  const { createQuestion, createQuestionLoading }: any = useCreateQuestion({
    type: currentModule.moduleId ? 'module' : '',
    id: currentModule.moduleId ? currentModule.moduleId : '',
    filter: {
      companyId: {
        type: 'exact',
        value: currentModule.manualCompanyId || userState.selectedCompany?.id,
      },
    },
  })

  const tagList =
    (tags &&
      tags.getAllTags.map(({ label }: TagsInterface) => ({
        label,
        value: label,
      }))) ||
    []

  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()

  const [linkModuleDrawerOpened, setLinkModuleDrawerOpened] =
    useState<boolean>(false)
  const [changed, setChanged] = useState<boolean>(false)
  const [boolAnswer, setBoolAnswer] = useState<boolean>(true)
  const [choiceAnswers, setChoiceAnswers] = useState<string[]>([])
  const [multiAnswers, setMultiAnswers] = useState<string[]>([])
  const [selectedChoiceAnswer, setSelectedChoiceAnswer] = useState<any>(null)
  const [selectedMultiAnswers, setSelectedMultiAnswers] = useState<any>([])
  const [selectedModules, setSelectedModules] = useState<SelectedModule[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  const [cropperOpen, setCropperOpen] = useState<boolean>(false)
  const [cropperImage, setCropperImage] = useState<string>('')
  const { uploadImage, imageLoading } = useUploadImage()
  const [currentQuestion, setcurrentQuestion] = useState<string>('')

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      question: '',
      type: {
        label: 'True/False',
        value: QuestionTypeEnums.BOOLEAN,
      },
      tags: [],
      reason: '',
      isShuffleEnabled: false,
    },
    validationSchema: QuestionSchema,
    onSubmit(values) {
      const {
        choiceAnswers: choiceAnswersFromValues,
        multiAnswers: multiAnswersFromValues,
        ...data
      }: any = values
      let formValues: any = data
      // if (selectedModules.length > 0) {
      //   formValues.modules = selectedModules
      // }

      if (values.type.value === 'boolean') {
        formValues.boolAnswer = boolAnswer
      }

      if (values.type.value === 'single') {
        formValues.answers = choiceAnswers.map((answer: any, index: number) => {
          return {
            isCorrect: selectedChoiceAnswer == index ? true : false,
            value: answer.value,
          }
        })
      }

      if (values.type.value === 'multiple') {
        formValues.answers = multiAnswers.map((answer: any, index: number) => {
          return {
            isCorrect: !!selectedMultiAnswers.includes(index),
            value: answer.value,
          }
        })
      }

      formValues.tags = values.tags.map(({ label }: TagsInterface) => {
        return { label }
      })

      const obj: any = { documents: [], videos: [], audios: [], images: [] }
      if (uploadedFiles.length > 0) {
        uploadedFiles.map((item: any) => {
          const { type, ...args } = item

          if (type === 'videos') {
            obj[type] = [
              ...obj[type],
              {
                ...args,
                links: args.links.map(({ __typename, ...n }: any) => n),
              },
            ]
          } else {
            obj[type] = [...obj[type], args]
          }
        })

        formValues = {
          ...formValues,
          ...obj,
        }
      } else {
        formValues = {
          ...formValues,
          ...obj,
        }
      }

      const moduleId: string = currentModule ? currentModule.moduleId : ''
      const duplicate: boolean = currentModule.duplicate ? true : false

      formValues.videos = formValues.videos
        ? formValues.videos.map((video: any) => {
            return { id: video.id, title: video.title }
          })
        : []
      if (duplicate) {
        if (currentModule.moduleId) {
          formValues.modules = [
            {
              moduleId: currentModule.moduleId,
              name: currentModule.name,
            },
          ]
        } else {
          // formValues.modules = []
        }
        createQuestion(
          formValues,
          currentModule.manualCompanyId || userState.selectedCompany?.id,
          () => {
            closeDrawer()
            currentModule.refetch()
          },
        )
      } else {
        editQuestion(moduleId, questionId, formValues, closeDrawer)
      }
      toggleDrawerConfirm(false, '')
    },
  })

  const closeDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'createQuestion', false)
  }

  const handleSelectChange = (field: string, e: any): void => {
    setFieldValue(field, e)
    setChanged(true)
  }

  const checkAnswers = (type: QuestionTypeEnums): any => {
    switch (type) {
      case QuestionTypeEnums.BOOLEAN:
        return true
      case QuestionTypeEnums.SINGLE:
        const emptyChoices = choiceAnswers.filter(
          (answer: any) => answer.value === '',
        )
        return choiceAnswers.length && !emptyChoices.length
      case QuestionTypeEnums.MULTIPLE:
        const emptyValues = multiAnswers.filter(
          (answer: any) => answer.value === '',
        )

        return multiAnswers.length && !emptyValues.length
    }
  }

  const checkSelectedAnswers = (type: QuestionTypeEnums): boolean => {
    switch (type) {
      case QuestionTypeEnums.BOOLEAN:
        return true
      case QuestionTypeEnums.SINGLE:
        return selectedChoiceAnswer !== null
      case QuestionTypeEnums.MULTIPLE:
        return selectedMultiAnswers.length > 1
    }
  }

  const handleBoolChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    const convertedValue = value === 'true' ? true : false
    setBoolAnswer(convertedValue)
  }

  const handleAddAnswerClick = (type: QuestionTypeEnums): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers: any = [...choiceAnswers, { value: '' }]
        setChoiceAnswers(updatedChoiceAnswers)
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers: any = [...multiAnswers, { value: '' }]
        setMultiAnswers(updatedMultiAnswers)
    }
  }

  const handleRemoveAnswerClick = (
    type: QuestionTypeEnums,
    index: number,
  ): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers: any = [...choiceAnswers]
        updatedChoiceAnswers.splice(index, 1)
        setChoiceAnswers(updatedChoiceAnswers)

        if (index == selectedChoiceAnswer) {
          setSelectedChoiceAnswer(null)
        }
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers: any = [...multiAnswers]
        updatedMultiAnswers.splice(index, 1)
        setMultiAnswers(updatedMultiAnswers)

        if (selectedMultiAnswers.includes(index.toString())) {
          const ind = selectedMultiAnswers.indexOf(index.toString())
          const updatedMultiAnswers = [...selectedMultiAnswers]
          updatedMultiAnswers.splice(ind, 1)
          setSelectedMultiAnswers(updatedMultiAnswers)
        }
    }
  }

  const handleAnswerChange = (
    e: any,
    type: QuestionTypeEnums,
    index: number,
  ): void => {
    const { value } = e.target

    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers: any = [...choiceAnswers]
        updatedChoiceAnswers[index].value = value
        setChoiceAnswers(updatedChoiceAnswers)
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers: any = [...multiAnswers]
        updatedMultiAnswers[index].value = value
        setMultiAnswers(updatedMultiAnswers)
        return
    }
  }

  const handleSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: QuestionTypeEnums,
  ): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const { value } = e.target
        setSelectedChoiceAnswer(value)
        return
      case QuestionTypeEnums.MULTIPLE:
        const eventValue = e
        const index = selectedMultiAnswers.indexOf(eventValue)
        if (index > -1) {
          const updatedMultiAnswers = [...selectedMultiAnswers]
          updatedMultiAnswers.splice(index, 1)
          setSelectedMultiAnswers(updatedMultiAnswers)
        } else {
          setSelectedMultiAnswers([...selectedMultiAnswers, eventValue])
        }
    }
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: File): void => {
    setCropperImage('')
    const index = uploadedFiles.length
    setUploadedFiles([
      ...uploadedFiles,
      {
        type: 'image',
        link: '',
        uploading: true,
      },
    ])
    uploadImage(croppedFile, (field = 'questions/images'), (link: string) => {
      const updatedFiles = [...uploadedFiles]
      updatedFiles[index] = {
        type: 'image',
        link,
        uploading: false,
      }
      setUploadedFiles(updatedFiles)
    })
  }

  useEffect(() => {
    if (data) {
      const {
        question,
        type,
        tags,
        reason,
        boolAnswer,
        answers,
        modules,
        isShuffleEnabled,
      } = data.question

      const formData: any = {
        question,
        type: { label: type.label, value: type.value },
        tags:
          tags.map(({ label }: TagsInterface) => ({
            label,
            value: label,
          })) || [],
        reason,
        isShuffleEnabled: isShuffleEnabled || false,
      }
      setValues(formData)

      if (type.value === 'boolean') {
        setBoolAnswer(boolAnswer)
        formData.boolAnswer = boolAnswer
      }

      if (type.value === 'single') {
        const choiceAnswers = answers.map((answer: any) => ({
          value: answer.value,
        }))
        const correct = answers.findIndex((answer: any) => answer.isCorrect)
        setChoiceAnswers(choiceAnswers)
        setSelectedChoiceAnswer(correct)
        formData.choiceAnswers = choiceAnswers
      }

      if (type.value === 'multiple') {
        const multiAnswers = answers.map((answer: any) => ({
          value: answer.value,
        }))

        const correctAnswers = answers.reduce(
          (ids: any, answer: any, index: number) => {
            if (answer.isCorrect) {
              ids.push(index)
            }

            return ids
          },
          [],
        )

        setMultiAnswers(multiAnswers)
        setSelectedMultiAnswers(correctAnswers)
        formData.multiAnswers = multiAnswers
      }

      setSelectedModules(
        modules.map((moduleItem: any) => ({
          moduleId: moduleItem.moduleId,
          name: moduleItem.name,
        })),
      )

      setcurrentQuestion(formData)

      let files: any = []
      // question.images.map((image: string) =>
      //   files.push({ type: 'image', link: image, uploading: false }),
      // )
      // question.documents.map((document: any) =>
      //   files.push({ type: 'document', ...document, uploading: false }),
      // )
      // question.videos.map((video: any) =>
      //   files.push({ type: 'video', ...video, uploading: false }),
      // )
      // question.audios.map((audio: any) =>
      //   files.push({ type: 'audio', ...audio, uploading: false }),
      // )

      for (const key in data.question) {
        if (
          ['videos', 'audios', 'images', 'documents'].includes(key) &&
          data.question[key].length > 0
        ) {
          files = [
            ...files,
            ...data.question[key].map(({ __typename, ...args }: any) => ({
              ...args,
              type: key,
            })),
          ]
        }
      }

      setUploadedFiles(files)
    }
  }, [data])

  const handleCloseDrawer = (): void => {
    const { type, ...formData }: any = values
    const { type: questionType, ...currentQuestionData }: any = currentQuestion
    if (multiAnswers.length > 0) {
      formData.multiAnswers = multiAnswers
    }
    if (choiceAnswers.length > 0) {
      formData.choiceAnswers = choiceAnswers
    }
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editQuestion',
        values: formData,
        compareTo: currentQuestionData,
      },
    })
  }
  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const fieldsValid = checkAnswers(values.type.value)
  const selectedValid = checkSelectedAnswers(values.type.value)

  const submitBtnDisabled = !values.question || !fieldsValid || !selectedValid

  if (loading) return <MyLoader />
  if (!data) return <div>No data available</div>

  return (
    <>
      {(loading || editQuestionLoading || createQuestionLoading) && (
        <Loader withBackground={loading ? true : false} />
      )}
      <Drawer
        opened={linkModuleDrawerOpened}
        toggleDrawer={(): void => setLinkModuleDrawerOpened(false)}
        totalWidth="650px"
      >
        <AddModuleToQuestionDrawer
          title="Link Modules to the Question"
          closeDrawer={(): void => setLinkModuleDrawerOpened(false)}
          selectedModules={selectedModules}
          setSelectedModules={setSelectedModules}
        />
      </Drawer>
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <Title>{t('form_fields.select_question_type')}</Title>
                <ReactSelect
                  onChange={(e): void => handleSelectChange('type', e)}
                  options={QuestionTypes}
                  value={values.type}
                  placeholder={t('form_fields.select')}
                />
              </FormField>
              <FormField>
                <Title>{t('form_fields.select_tags')} </Title>
                <CreatableSelect
                  isMulti
                  onChange={(e: any): void => {
                    handleSelectChange('tags', e)
                  }}
                  options={tagList}
                  value={values.tags}
                  placeholder={t('form_fields.select_tags')}
                />
              </FormField>
              <FormField style={{ display: 'flex' }}>
                <UploadContent
                  setUploadedFiles={setUploadedFiles}
                  uploadedFiles={uploadedFiles}
                />

                <HelpButtonContainer>
                  <Tooltip
                    title={
                      <span style={{ fontSize: '14px' }}>
                        {t('actions.upload_content_helper')}
                      </span>
                    }
                    arrow
                  >
                    <HelpIcon style={{ color: '#06C68F' }} />
                  </Tooltip>
                </HelpButtonContainer>
              </FormField>
              <FormField>
                <Title>{`${t('general.question')}`}</Title>
                <TextInput
                  error={errors.question ? true : false}
                  errorMessage={errors.question ? errors.question : ''}
                  touched={touched.question}
                  placeholder={`${t('form_fields.type_question_here')}`}
                  size="small"
                  name="question"
                  type="text"
                  value={values.question}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="2"
                />
              </FormField>
              <FormField>
                <QuestionItem
                  type={values.type.value}
                  boolAnswer={boolAnswer}
                  choiceAnswers={choiceAnswers}
                  multiAnswers={multiAnswers}
                  selectedChoiceAnswer={selectedChoiceAnswer}
                  selectedMultiAnswers={selectedMultiAnswers}
                  handleSelect={handleSelect}
                  onBoolChange={handleBoolChange}
                  handleAddAnswerClick={handleAddAnswerClick}
                  handleRemoveAnswerClick={handleRemoveAnswerClick}
                  handleAnswerChange={handleAnswerChange}
                  fieldsValid={fieldsValid}
                  selectedValid={selectedValid}
                />
              </FormField>
              <FormField>
                <Title>{t('question_details.reason')}</Title>
                <TextInput
                  placeholder={`${t('form_fields.type_reason_here')}`}
                  size="small"
                  name="reason"
                  type="text"
                  value={values.reason}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="3"
                />
              </FormField>
              <StyledFormField>
                <Title>{t('create_question.shuffle_answers')}:</Title>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue('isShuffleEnabled', e.target.value === 'true')
                  }}
                  value={values.isShuffleEnabled}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="secondary" />}
                    label={t('general.yes')}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="secondary" />}
                    label={t('general.no')}
                  />
                </RadioGroup>
              </StyledFormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            {!currentModule.duplicate && (
              <ModuleWrapper>
                <Wrapper>
                  <article style={{ marginRight: 5 }}>
                    {' '}
                    {t('question_details.linked_to_modules')}:
                  </article>
                  <Modules>
                    {selectedModules.map((selectedModule: any) => (
                      <ModuleItemWrapper key={selectedModule.moduleId}>
                        <ModuleItem>{selectedModule.name}</ModuleItem>
                      </ModuleItemWrapper>
                    ))}
                  </Modules>
                </Wrapper>
              </ModuleWrapper>
            )}

            {currentModule.duplicate && (
              <ModuleWrapper>
                <Wrapper>
                  <article style={{ marginRight: 5 }}>
                    {' '}
                    {t('question_details.linked_to_modules')}: :
                  </article>
                  {currentModule.moduleId && (
                    <Modules>
                      <ModuleItemWrapper>
                        <ModuleItem>{currentModule.name}</ModuleItem>
                      </ModuleItemWrapper>
                    </Modules>
                  )}
                </Wrapper>
              </ModuleWrapper>
            )}
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                onClick={handleSubmit}
                background="#06C68F"
                isDisabled={submitBtnDisabled}
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
      <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
        <ImageCropper
          file={cropperImage}
          hideCropper={cropperModalToggle}
          getCroppedFile={handleCropSave}
        />
      </Modal>
    </>
  )
}

export default EditQuestionDrawer
