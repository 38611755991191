import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Backdrop } from '@mui/material'
import Modal from '@mui/material/Modal'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Grid } from 'components/common/Cards/styled-components'
import Pagination from 'components/common/Pagination'
import { DefaultText } from 'components/common/TextEditor/styled-components'
import { ToolbarItem } from 'components/common/Toolbar'
import Player from 'components/common/VideoPlayer/Player'
import { useCourseByUserId } from 'hooks/course/useCourseByUserId'
import { Wrapper } from 'pages/companies/CompanyDetails/styled-components'
import CourseList from 'pages/courses/CourseList'
import { PersonalInformationHeader } from '../AdminProfile/styled-components'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Input } from 'components/common/TextInput'
import Drawer from 'components/common/Drawer'
import FilterDrawer from 'pages/courses/FilterDrawer/FilterDrawer'

const PREFIX = 'UserCourses'

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const initialValues = {
  name: '',
  state: { value: 'all', label: 'All' },
  level: { value: 'all', label: 'All' },
  coaches: null,
  group: null,
  skills: null,
  video: { value: 'all', label: 'All' },
  studentRange: [0, 300],
}

type filterData = {
  values: {}
  filterOptions: {}
}

export const UserCourses: React.FC<{
  userId: string
  groups: string[]
  companyId?: string | null
}> = ({ userId, groups, companyId }) => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(12)
  const [searchText, setSearchText] = useState<string>('')
  const [filterData, setFilterData] = useState<filterData>({
    values: initialValues,
    filterOptions: {},
  })
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  const defaultFilter = {
    groups: {
      type: 'NESTED_ARRAY_IN',
      value: groups,
      nestedField: 'GROUP_ID',
    },
  }

  const { courses, refetch }: any = useCourseByUserId(
    { ...defaultFilter, ...filterQuery, ...filterData.filterOptions },
    currentPage,
    perPage,
    userId,
  )

  const [isCoursesVisible, setCoursesVisible] = useState(true)

  const [videoModal, setVideoModal] = useState<any>({
    modal: false,
    data: '',
    subtitle: '',
  })

  const handleVideoModal = (data: any) => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? data.video.links[0].url : '',
      subtitle: !videoModal.modal ? data.subtitle : '',
    })
  }

  const handlePaginationClick = (event: any, value: number) => {
    if (value !== courses.currentPage) {
      setCurrentPage(value)
    }
  }

  const filterDrawer = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const resetFilter = () => {
    setFilterQuery({})
    setFilterData({} as filterData)
    setFilterIsOpen(false)
    setSearchText('')
  }

  const onSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValue = { name: { type: 'MATCH', value: searchText } }
    setCurrentPage(1)
    setFilterQuery({
      ...filterQuery,
      ...formValue,
      ...filterData.filterOptions,
    })
    refetch({
      currentPage: 1,
      perPage,
      filter: filterQuery,
    })
  }

  return (
    <Root>
      <PersonalInformationHeader onClick={() => setCoursesVisible((x) => !x)}>
        {!isCoursesVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        {t('general.courses')}
      </PersonalInformationHeader>

      {isCoursesVisible && (
        <>
          <Wrapper style={{ justifyContent: 'flex-end' }}>
            <ToolbarItem>
              <IconButton
                disabled={_.isEmpty(filterData.filterOptions) && !searchText}
                onClick={resetFilter}
              >
                <SyncIcon fontSize="small" />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <IconButton onClick={filterDrawer}>
                <FilterListIcon
                  fontSize="medium"
                  style={{ color: '#06C68F' }}
                />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={(e: any) => {
                    setSearchText(e.target.value)
                  }}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </Wrapper>
          <Grid
            className="card"
            style={{ position: 'relative', marginTop: ' 17px' }}
            cols={4}
          >
            {courses?.data && courses.data.length !== 0 ? (
              <CourseList
                data={courses.data}
                gridStyle="card"
                withTooltips={false}
                isGridView={true}
                // selectedItem={[]}
                // selectItem={() => {}}
                user={{
                  id: userId,
                  groups,
                }}
                canOpen
                withoutChekbox
              />
            ) : (
              <DefaultText style={{ width: '50vw' }}>
                {t('default_texts.courses')}
              </DefaultText>
            )}
          </Grid>
        </>
      )}

      <Drawer opened={filterIsOpen} toggleDrawer={() => {}} totalWidth="700px">
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          refetchCourse={refetch}
          resetFilter={resetFilter}
          onClose={() => setFilterIsOpen(false)}
          isCooursePrivacyHidden={true}
          setCurrentPage={setCurrentPage}
          companyId={companyId}
          isOnStudentProfile={true}
          t={t}
        />
      </Drawer>

      {courses && courses.totalPages > 1 && (
        <div
          style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}
        >
          <Pagination
            currentPage={courses ? courses.currentPage : 1}
            totalPages={courses ? courses.totalPages : 0}
            handleChange={handlePaginationClick}
          />
        </div>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={videoModal.modal}
        onClose={() => {
          setVideoModal({
            ...videoModal,
            modal: false,
          })
        }}
        style={styles.modal}
      >
        <Player url={videoModal.data} subtitle={videoModal.subtitle} />
      </Modal>
    </Root>
  )
}
