import { useState } from 'react'
import addTopicService from 'services/topics/addTopicService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export const useAddTopic = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [newTopic, setNewTopic] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { addTopicMutation } = addTopicService(params)

  const addTopic = async (moduleId: string, input: any, callback?: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await addTopicMutation({
      variables: {
        moduleId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return false
      }
      variant = 'error'
    } else {
      setNewTopic(data.addTopic)
      callback(data.addTopic.createdTopic)
      message = `${t('general.topic')} ${t('messages.saved')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    addTopic,
    addTopicLoading: loading,
    newTopic,
    error,
  }
}
