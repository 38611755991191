import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/common/TextInput'
import CancelIcon from '@mui/icons-material/Cancel'
import CircularProgress from '@mui/material/CircularProgress'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import HeadsetIcon from '@mui/icons-material/Headset'
import {
  Container,
  ItemContainer,
  CancelButton,
  InputFileContainer,
  UploadInput,
  Label,
  ImagePlaceholder,
  InputLabel,
} from './styled-components'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

const Loading = (props: any) => {
  const { type, loading } = props
  if (['video', 'audio'].includes(type)) {
    return (
      <div style={{ marginLeft: '19px', marginTop: '2px' }}>
        {Math.round(loading)} %
      </div>
    )
  }

  return (
    <div style={{ marginLeft: '19px', marginTop: '2px' }}>
      <CircularProgress style={{ width: '32px', height: '32px' }} />
    </div>
  )
}

const Uploader = (props: any) => {
  const { t } = useTranslation()
  const filePreview = () => {
    switch (props.type) {
      case 'image':
        return (
          <ImagePlaceholder>
            <img src={props.uploadedFile.link} alt="" />
          </ImagePlaceholder>
        )
      case 'video':
        return (
          <div style={{ marginLeft: '23px', marginTop: '3px' }}>
            <OndemandVideoIcon color="secondary" />
          </div>
        )
      case 'audio':
        return (
          <div style={{ marginLeft: '23px', marginTop: '3px' }}>
            <HeadsetIcon color="secondary" />
          </div>
        )
      default:
        return (
          <div style={{ marginLeft: '23px', marginTop: '3px' }}>
            <InsertDriveFileIcon color="secondary" />
          </div>
        )
    }
  }

  return (
    <Container>
      <Label>{props.label}</Label>

      <ItemContainer>
        {!props.uploadedFile && !props.loading && (
          <InputFileContainer>
            <UploadInput
              type="file"
              name={props.name}
              id={props.id}
              onChange={props.onFileChange}
              multiple={props.multiple}
              accept={props.accept}
            />

            <InputLabel htmlFor={props.id}>
              {t('actions.choose_file')}
              {/* <Button text="Choose File" type="small" /> */}
            </InputLabel>
          </InputFileContainer>
        )}

        {props.loading && <Loading type={props.type} loading={props.loading} />}

        {props.uploadedFile && filePreview()}
      </ItemContainer>

      <ItemContainer>
        <Input
          label={
            props.inputValue
              ? ''
              : `${t('form_fields.file_name')} ${props.fileExtension}`
          }
          name="file"
          size="small"
          type="text"
          fullWidth
          value={props.inputValue || ''}
          onChange={props.onInputChange}
          disabled={props.disabled}
        />
      </ItemContainer>

      {props.uploadedFile && (
        <CancelButton onClick={props.deleteFile}>
          <CancelIcon style={{ color: '#06C68F' }} />
        </CancelButton>
      )}
    </Container>
  )
}

export default Uploader
