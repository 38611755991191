import { ChangeEvent, useEffect, useState } from 'react'
import {
  AddCourseDrawerProps,
  IAddCourseDrawerDefaultQuery,
  IUseAddCourseDrawerResponse,
} from './addCourseDrawer.interface'
import { useTranslation } from 'react-i18next'
import { useUserValue } from 'context/UserContext'
import { useData } from 'context/DataContext'
import { useMutation } from '@apollo/client'
import { CHECK_COURSE_NAME } from 'gql/course/course.query'
import { CourseSchema } from 'helpers/validationSchemas'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import excludeKeys from 'helpers/excludeKeys'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import axios, { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import useCreateCourse from 'hooks/course/useCreateCourse'
import {
  useGenerateUploadVideoLinks,
  useUploadImage,
  useUploadFile,
  useUploadCaptions,
} from 'hooks/helpers/useHelpersService'
import {
  CompleteCriteriaOption,
  CourseCompleteCriteriaEnums,
  IRenderContentItem,
} from 'pages/courses/courseList.interface'
import { CourseOption } from 'pages/tasks/TasksDrawer/tasksDrawer.interface'

const useAddCourseDrawer = ({
  onClose,
  data,
}: AddCourseDrawerProps): IUseAddCourseDrawerResponse => {
  const { t } = useTranslation()
  const [userData] = useUserValue()
  const isSuperAdmin =
    !Boolean(userData.currentUser.companyId) && !userData.selectedCompany?.id
  const [state, dispatch] = useData()
  const [step, setStep] = useState<number>(0)
  const [checkName] = useMutation(CHECK_COURSE_NAME)
  const [buttonEvent, setButtonEvent] = useState('')
  const [courseVideo, setCourseVideo] = useState<File | null>(null)
  const [progress, setProgress] = useState<number>(0)
  const [validationData, setValidationData] = useState(CourseSchema)
  const pagination = data.pagination || {}

  const query: IAddCourseDrawerDefaultQuery = {
    companyId: {
      type: UpperCaseFilterableFieldType.EXACT,
      value: userData.selectedCompany?.id || null,
    },
  }

  const { useCreateCourseService, createCourseLoading } = useCreateCourse({
    filter: query,
    ...pagination,
  })

  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadFile, fileLoading } = useUploadFile()
  const { uploadCaptions } = useUploadCaptions()
  const [uploadedVideoName, setUploadedVideoName] = useState<string>('')

  const handleChangeStep = (activeStep: number): void => {
    setStep(activeStep)
  }

  const uploadVideoService = (
    url: string,
  ): Promise<AxiosResponse<any>> | void => {
    if (!courseVideo) return
    setUploadedVideoName(url.split('/')[4].split('.')[0])
    return axios.put(url, courseVideo, {
      headers: {
        'Content-Type': courseVideo.type,
      },
      onUploadProgress: ({ total, loaded }) => {
        setProgress((loaded / total) * 100)
      },
    })
  }

  const onCloseDrawer = (): void => {
    onClose()
    dispatch({ type: CLEAR_FORMDATA })
  }

  const formData: IRenderContentItem = {
    name: '',
    description: '',
    level: null,
    coursePrivacy: 'private',
    coachType: '',
    coaches: [],
    skills: [],
    certificateIncluded: false,
    price: '',
    finished: 3,
    groups: [],
    currency: '',
    avatar: null,
    certificate: null,
    completeCriteria: {
      label: t('course_complete_criterias.watchAndPassQuizzes'),
      value: CourseCompleteCriteriaEnums.WATCH_AND_PASS_QUIZZES,
    },
    video: null,
    contentLocked: false,
  }

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: formData,
      async onSubmit(values: IRenderContentItem) {
        // validation imported from validationSchema
        // because Yup is working only first time
        if (!values.name) {
          errors.name = `${t('general.name')} ${t('validations.required')}`
          return
        }
        if (!values.avatar) {
          errors.avatar = `${t('general.cover_image')} ${t(
            'validations.required',
          )}`
          return
        }

        if (step === 0 && buttonEvent === 'save') {
          const exists = await checkName({
            variables: { name: values.name },
          })

          if (exists && exists.errors) {
            errors.name = t('validations.courseNameExists')
            return
          } else {
            onCloseDrawer()
          }
        }

        if (createCourseLoading) return

        const formData = { ...values }

        if (formData.skills) {
          formData.skills = formData.skills.map((e) => ({ label: e.label }))
        }

        if (data.companyId) formData.companyId = data.companyId

        if (formData.level) {
          const level = formData.level as CourseOption
          formData.level = level.value
        }

        if (!formData.price) {
          formData.price = null
          formData.currency = ''
        }

        if (formData.video) {
          formData.video = {
            id: formData.video.id,
            title: formData.video.title,
          }
        }

        const completeCriteriaOption =
          formData.completeCriteria as CompleteCriteriaOption
        formData.completeCriteria = completeCriteriaOption?.value

        useCreateCourseService(formData, [], () => {
          data.onSuccess()
        })
      },
    })

  useEffect(() => {
    if (values.price) {
      setValidationData({
        ...validationData,
        currency: Yup.string().required(
          `${t('form_fields.currency')} ${t('validations.required')}`,
        ),
      })
    } else {
      setValidationData(validationData)
    }
  }, [values.price])

  const handleGenerateUploadLink = (): void => {
    if (!courseVideo) return
    const videos = [{ fileName: courseVideo.name, type: courseVideo.type }]
    generateUploadVideoLinks(
      videos,
      'course',
      uploadVideoService,
      (args) => {
        setFieldValue('video', args.video)
        setFieldValue('video.title', args.video.name)
        setProgress(0)
      },
      true,
    )
  }

  const handleCloseDrawer = (): void => {
    if (step === 2) {
      onClose()
      dispatch({ type: CLEAR_FORMDATA })
    } else {
      const formData = excludeKeys(values, ['coursePrivacy'])
      dispatch({
        type: SET_FORMDATA,
        payload: {
          type: 'add',
          drawer: 'addCourses',
          values: formData,
          compareTo: {},
        },
      })
    }
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (courseVideo && courseVideo.type) {
      handleGenerateUploadLink()
    }
  }, [courseVideo])

  const handleVideoChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e?.target?.files?.length) {
      setCourseVideo(e.target.files[0])
      return
    }

    setCourseVideo(null)
    setFieldValue('video', null)
    setProgress(0)
  }

  const handleImageChange = (
    file: File,
    field: string,
    fileName?: string,
  ): void => {
    if (!file) {
      setFieldValue(field, null)
      return
    }
    uploadImage(file, `course/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: fileName?.split('.').pop(),
      }),
    )
  }

  const handleUploadFile = (
    e: ChangeEvent<HTMLInputElement>,
    field: string,
  ): void => {
    if (!e) return
    const { files } = e.target
    if (!files) return
    const file = files[0]

    if (field === 'subtitle') {
      uploadCaptions(file, `${uploadedVideoName}`, (link: string) => {
        setFieldValue(field, {
          name: file.name,
          link,
          fileType: file.name.split('.').pop(),
        })
      })
    } else {
      uploadFile(file, `course/${field}`, (link: string) => {
        setFieldValue(field, {
          name: file.name,
          link,
          fileType: file.name.split('.').pop(),
        })
      })
    }
  }

  const handleButtonClick = (e: string): void => {
    setButtonEvent(e)
    handleSubmit()
  }

  return {
    values,
    handleChange,
    errors,
    touched,
    handleImageChange,
    setFieldValue,
    progress,
    imageLoading,
    handleVideoChange,
    courseVideo,
    handleUploadFile,
    fileLoading,
    handleCloseDrawer,
    handleChangeStep,
    handleButtonClick,
    step,
    t,
    userData,
    createCourseLoading,
    isSuperAdmin,
  }
}

export default useAddCourseDrawer
