import styled from 'styled-components'

export const ModuleContentDetailsContainer = styled.div`
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  height: 256px;
  display: flex;
  flex-direction: column;
`

export const ModuleContentDetailsHeader = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding: 10px; */
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ModuleContentDetailsContainerFlex = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

export const ModuleContentDetails = styled.ul`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e7e9ed;
  height: 152px;
  width: 100%;
  gap: 20px;
  padding-left: 16px;
`

export const ActionsWrapper = styled.div`
  /* position: absolute; */
  right: 0;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
  }

  svg:hover {
    transition: 0.3s ease;
    background-color: #36cea7;
    border-radius: 4px;
    color: '#fff';
  }
  svg:hover path {
    transition: 0.3s ease;
    fill: #fff !important;
  }
`

export const ModuleContentDetailsRight = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px 16px;
  gap: 20px;
  width: 100%;
`

export const StyledModuleDetailsFlex = styled.div`
  display: flex;
  gap: 120px;
`
export const StyledModuleDetailsFlexBottom = styled.div`
  display: flex;
  gap: 106px;
`

export const ModulesContentWrapper = styled.div`
  margin-bottom: 20px;
`

export const StyledContainer = styled.div`
  width: 100%;
  height: 300px;
  left: 0;
  position: absolute;
  top: 80px;
`

export const ModuleContentDetail = styled.li`
  &:not(:last-child) {
    margin-right: 15px;
  }
  display: flex;
  flex-direction: column;

  svg {
    color: red;
  }
`
export const NoVideoContainer = styled.div`
  width: 100%;
  height: auto;
`

export const CourseList = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`
export const CourseItem = styled.span`
  display: inline-block;
  padding: 0 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 14px;
  &:not(:last-child) {
    margin-right: 5px;
  }
`

// ModuleDetailContent

export const NoValueWrapper = styled.div`
  opacity: 0.5;
  font-style: italic;
`
export const Action = styled.div<{
  hoverColor?: string
  hoverBackground?: string
}>`
  display: flex;

  cursor: pointer;
  transition: 0.3s ease;
  color: #31313d;
  .MuiButtonBase-root {
    border-radius: 0 !important;
    width: 248px;
    display: flex;
    justify-content: flex-start;
    padding: 0 !important;
  }

  &:hover {
    ${({ hoverColor }) =>
      hoverColor &&
      `svg path {
        fill: ${hoverColor};
         }`}
  }
  svg {
    font-size: 1rem;
    transition: 0.3s ease;
    width: 40px;
    height: 40px;
  }
  svg path {
    fill: #31313d;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`

export const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
  detailTitle: {
    color: '#68707A',
    padding: 0,
    margin: 0,
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: '20px',
  },
  detailHeader: {
    fontWeight: 500,
    fontSize: '16px',
    lineWeight: '26px',
  },
  actionSpan: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '18px',
    color: '#31313d',
    marginLeft: '0',
  },
}
