import styled from 'styled-components'

// Activity

export const Label = styled.span`
  font-size: 24px;
`

export const LeaderboardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  gap: 20px;
  padding-bottom: 8px;
  border-bottom: 2px solid rgba(89, 199, 144, 0.3);

  svg {
    width: 24px;
  }
`

export const LeaderboardItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  align-items: center;
  color: #0c0c0c;
  font-family: 'Open Sans Regular', sans-serif;
  span:last-child {
    font-size: 25px;
    color: rgba(89, 199, 144, 1);
  }

  .stable {
    color: #ccc !important;
  }

  .regress {
    color: #ff0000 !important;
  }
`

export const LeaderboardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 6px -1px,
    rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`

export const ActivitiesContainer = styled.div`
  height: calc(100vh - 190px);
  margin-top: 5px;
  background: #fff;
  box-shadow: 0px 0px 29px 5px rgba(0, 0, 0, 0.03);
  padding: 40px 50px;
  border-radius: 28px;
  font-family: 'Open Sans Regular', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
`

export const ActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

export const UserDetailsContainer = styled.div`
  width: 40%;
  display: flex;
  gap: 30px;
  align-items: center;
  flex-direction: column;
`

export const ActivityHeader = styled.div`
  padding-bottom: 8px;
  border-bottom: 2px solid rgba(89, 199, 144, 0.8);
  display: inline-block;
  position: relative;
  left: 0;
`
export const UserName = styled.h2`
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  font-size: 26px;
`
export const Tracker = styled.div`
  margin-top: 15px;
  padding: 10px 30px 60px 30px;
  height: 65vh;
  overflow-y: scroll;
`

// TimeLine

export const Ul = styled.ul`
  border-left: 3px solid rgba(89, 199, 144, 1);
  position: relative;
  top: 20px;
  padding-top: 10px;
`
export const Top = styled.div`
  position: absolute;
  left: -13px;
  top: -19px;
  width: 17px;
  height: auto;
  .MuiSvgIcon-root {
    color: rgba(89, 199, 144, 1);
    circle[Attributes Style] {
      cy: 10;
    }
  }
`

export const Li = styled.li`
  position: relative;
  padding: 10px 20px 10px 10px;
  background-color: white;
  border-radius: 14px;
  margin-top: 10px;
  font-size: 16px;
  min-width: 140px;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px -1px,
    rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  display: inline-block;
  .MuiSvgIcon-root {
    width: 17px;
    vertical-align: middle;
    color: rgba(89, 199, 144, 1);
    position: absolute;
    left: -50px;
    top: 14px;
  }
`

export const Time = styled.span`
  font-size: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: bolder;
`
export const Content = styled.span`
  font-size: 16px;
`
export const TimelineLabel = styled.span`
  font-size: 14px;
  margin-top: 10px;
`
