import React from 'react'
import _ from 'lodash'
import {
  GridToolbar,
  GridLayoutActions,
  StudentsActionsContainer,
} from 'components/common/GridV2/styled-components'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import Loader from 'components/common/Loader'
import Drawer from 'components/common/Drawer'
import TextInput from 'components/common/TextInput/TextInput'
import Grid from 'components/common/GridV2'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import ResetUsersPassword from 'pages/profile/AllUserProfile/ResetPassword'
import Modal from 'components/common/Modal'
import SendPushNotification from 'components/SendPushNotification/SendPushNotification'
import {
  FlexComponent,
  LayoutWrapper,
  StyledPagination,
} from './styled-components'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { ToolbarItem } from 'components/common/Toolbar'
import PaginationV2 from 'components/common/PaginationV2'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import useUsersLayout from 'hooks/usersLayout/useUsersLayout'
import { SplashScreen } from 'components/common'
import { roles } from 'utils/permission'
import { Props } from './usersLayout.interface'

const UsersLayout = ({
  role,
  addText,
  addUserDrawer,
  editUserDrawer,
  showBreadCrumb = true,
  groupId,
}: Props): React.JSX.Element => {
  const {
    breadCrumbData,
    filterDrawerOpened,
    renderFilterComponent,
    createButton,
    selectedItem,
    currentPage,
    multiActions,
    setFilterDrawerOpened,
    isFiltered,
    searchValue,
    handleSearchChange,
    deleteUserLoading,
    updateUserStatusLoaing,
    resetVisible,
    handleClick,
    config,
    users,
    actions,
    selectAllItem,
    selectItem,
    t,
    perPage,
    handleChangeRowsPerPage,
    handlePaginationClick,
    isResetPasswordOpen,
    userId,
    setResetPasswordOpen,
    pushNotificationModal,
    handleModalClose,
    messageData,
    setMessageData,
    handlePushNotificationSend,
    companyId,
    isSearchType,
  } = useUsersLayout({
    role,
    addText,
    addUserDrawer,
    editUserDrawer,
    groupId,
  })

  if (
    users &&
    companyId &&
    !searchValue &&
    !isSearchType &&
    !isFiltered &&
    users?.data.length <= 0
  ) {
    let title, subTitle
    if (role === roles.super_admin) {
      title = t('user_details.no_super_admins')
      subTitle = t('user_details.create_super_admins')
    } else if (role === roles.admin) {
      title = t('user_details.no_admins')
      subTitle = t('user_details.create_admins')
    }
    return (
      <>
        <Breadcrumb data={breadCrumbData} />
        <SplashScreen
          title={title}
          subTitle={subTitle}
          createButton={createButton}
        />
      </>
    )
  }

  return (
    <>
      {showBreadCrumb && <Breadcrumb data={breadCrumbData} />}
      {/* <Title>
        {role === roles.super_admin
          ? t('general.super_admin')
          : role === roles.admin
          ? t('general.admins')
          : ''}
      </Title> */}

      <LayoutWrapper>
        <Drawer
          opened={filterDrawerOpened}
          toggleDrawer={(): void => {}}
          totalWidth="600px"
        >
          {renderFilterComponent()}
        </Drawer>
        <GridToolbar>
          <GridLayoutActions>
            <div>{createButton()}</div>
            <GridMultipleActions
              selectedItems={
                selectedItem[currentPage] ? selectedItem[currentPage].length : 0
              }
              actions={multiActions()}
            />
          </GridLayoutActions>
          <FlexComponent>
            <ToolbarItem>
              <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                {isFiltered ? (
                  <RedFilterIcon color="#06C68F" />
                ) : (
                  <FilterIcon />
                )}
              </IconButton>
            </ToolbarItem>
            <TextInput
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type="submit">
                  <SearchRoundedIcon />
                </IconButton>
              }
              iconInStart
              name="search"
            />
          </FlexComponent>
        </GridToolbar>
        <div
          style={{
            position: 'relative',
          }}
        >
          {(deleteUserLoading || updateUserStatusLoaing) && <Loader />}
          {users && (
            <Grid
              // title={tableTitle}
              // onFiltersClick={handleFiltersClick}
              resetVisible={resetVisible}
              // resetFilters={handleResetClick}
              redirectToDetailsHandler={handleClick}
              config={config}
              actionTilesLength={3}
              data={users.data}
              actionConfig={actions}
              selected={_.size(selectedItem[currentPage])}
              selectAllItem={selectAllItem}
              selectItem={selectItem}
              selectedItems={selectedItem[currentPage] || []}
              ActionsContainer={StudentsActionsContainer}
            />
          )}
          {users && users.data?.length > 0 && (
            <StyledPagination>
              <PerPageDropdown
                value={perPage}
                onChange={(value): void =>
                  handleChangeRowsPerPage({
                    target: { value: value.toString() } as HTMLInputElement,
                  } as React.ChangeEvent<HTMLInputElement>)
                }
                options={[10, 20, 50]}
              />

              <PaginationV2
                currentPage={currentPage}
                totalPages={users?.totalPages}
                handleChange={handlePaginationClick}
              />
            </StyledPagination>
          )}
          {isResetPasswordOpen && (
            <ResetUsersPassword
              isModalOpen={isResetPasswordOpen}
              setModalOpen={setResetPasswordOpen}
              userId={userId}
            />
          )}
        </div>
        <Modal isOpened={pushNotificationModal} onClose={handleModalClose}>
          <SendPushNotification
            messageData={messageData}
            setMessageData={setMessageData}
            onClose={handleModalClose}
            onSend={handlePushNotificationSend}
          />
        </Modal>
      </LayoutWrapper>
    </>
  )
}
export default UsersLayout
