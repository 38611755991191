import { useSwal } from 'hooks/useSwal'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useTranslation } from 'react-i18next'
import { IUseTestLayoutServices } from './testLayout.interface'
import useDeleteTestService from './useDeleteTestService'
import usePublishUnpublishTestService from './usePublishUnpublishTestService'
import { TestAssessment } from 'pages/tests/testLayout/testLayout.interface'
import { useRef, useState } from 'react'
import { debounce } from 'lodash'

const useTestLayoutServices = ({
  companyId,
  selectedItem,
  setSelectedItem,
  currentPage,
  setCurrentPage,
  filterData,
  perPage,
  refetch,
  setIsSearchType,
}: IUseTestLayoutServices) => {
  const { fireSwal } = useSwal()
  const { t } = useTranslation()
  const { deleteTest } = useDeleteTestService()
  const [searchText, setSearchText] = useState<string>('')

  // Select Item handler cross pages
  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }
  const debouncedRefetch = useRef(
    debounce((newSearchText: string) => {
      const searchValue = { type: 'MATCH', value: newSearchText }
      refetch({
        filter: {
          name: searchValue,
        },
        currentPage: 1,
        perPage,
      })
    }, 500),
  ).current

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchText = e.target.value
    setIsSearchType(true)
    setSearchText(newSearchText)
    debouncedRefetch(newSearchText)
  }
  // Search Submit Handler
  const onSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const searchValue = { type: 'MATCH', value: searchText }
    setCurrentPage(1)
    refetch({
      filter: {
        name: searchValue,
      },
      currentPage: 1,
      perPage,
    })
  }

  const handleDeleteManyClick = (ids: string[]) => {
    fireSwal({
      title: t('popups.delete_test_many'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        deleteTest(ids)
        handlePaginationClick(null, currentPage)
        setSelectedItem({})
        refetch({
          companyId,
          currentPage,
          perPage,
        })
      },
    })
  }

  const handleDeleteClick = (id: string) => {
    fireSwal({
      title: t('popups.delete_test'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        handlePaginationClick(null, currentPage)
        deleteTest([id])
        refetch({
          companyId,
          currentPage,
          perPage,
        })
      },
    })
  }

  const { publishUnpublishTestAssessment } = usePublishUnpublishTestService()

  const handlePublish = (testAssessment: TestAssessment) => {
    fireSwal({
      title: testAssessment.published
        ? t('tests_layout.unpublish_test')
        : t('tests_layout.publish_test'),
      confirmText: testAssessment.published
        ? t('popups.confirm_unpublish')
        : t('popups.confirm_publish'),
      onConfirm: () => {
        publishUnpublishTestAssessment(
          testAssessment.id,
          !testAssessment.published,
        ).then(() => {
          refetch({
            companyId,
            currentPage,
            perPage,
            filter: { ...filterData.filterOptions },
          })
        })
      },
    })
  }

  const handleDrawerOpen = () => {
    DrawerEventEmitter.emit('openDrawer', 'addTest', true, {
      onSuccess: () => {
        refetch({
          currentPage: 1,
          perPage,
          companyId,
        })
        setSelectedItem({})
      },
    })
  }

  const handlePaginationClick = (event: any, value: number) => {
    setCurrentPage(value)
  }

  return {
    selectItem,
    onSearchSubmit,
    handleDeleteManyClick,
    handleDeleteClick,
    handlePublish,
    handleDrawerOpen,
    handlePaginationClick,
    onSearchChange,
    searchText,
    setSearchText,
  }
}

export default useTestLayoutServices
