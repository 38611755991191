import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { GET_LESSON_BY_ID } from 'gql/lesson/lesson.query'
import { useQuery } from '@apollo/client'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import config from 'config'
import CircularProgress from '@mui/material/CircularProgress'

import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import Thumbnail from 'components/common/Thumbnail/Thumbnail'
import { Button } from 'components/common/Button'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import TextEditor from 'components/common/TextEditor/TextEditor.jsx'
import { DrawerEventEmitter } from 'helpers/drawer'
import {
  DetailsContentVideoCover,
  VideoProcessingWrapper,
} from 'components/common/DetailsLayout'
import {
  CourseContentWrapper,
  CourseContentLeft,
  CourseContentRight,
  CourseContentHeader,
  CourseContentHeaderSection,
  CourseTitle,
  CourseContentDescription,
  CourseContentReview,
  CourseContentPlayIcon,
  CourseContentDetailsList,
} from 'components/courseDetail/styled-component'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import AudioPlayer from 'components/common/AudioPlayer'
import HeadsetIcon from '@mui/icons-material/Headset'
import useEditLessonService from 'hooks/lessons/useEditLessonService'
import useDeleteLessonService from 'hooks/lessons/useDeleteLessonService'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'

import { actionConfig, downloadActionConfig } from '../gridConfig'
import { Label, ResourceContainer } from './styled-components'

// const modal = { modal: false, url: '', event: '' }

const LessonDetails = (props: any) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const { id: lessonId } = useParams()
  const [state] = useUserValue()
  const navigate = useNavigate()
  const { fireSwal } = useSwal()
  const [mediaModal, setMediaModal] = useState(false)
  const [descriptionEdit, setDescriptionEdit] = useState(false)
  const [transcriptEdit, setTranscriptEdit] = useState(false)

  const { data, loading } = useQuery(GET_LESSON_BY_ID, {
    variables: {
      lessonId,
    },
  })

  // const { data: moduleData } = useQuery(GET_MODULE_IMAGE, {
  //   variables: {
  //     moduleId: null,
  //   },
  // })

  const { state: routerState } = useLocation()

  const lessonData = (data && data.getLessonById) || {}

  const { editLesson } = useEditLessonService({
    lessonId,
  })
  const { deleteLesson } = useDeleteLessonService({
    moduleId: lessonData.moduleId,
  })

  const editEditorText = (editorText: string, event: string) => {
    const newData: any = {}
    newData[event] = editorText
    if (lessonId)
      editLesson(lessonData.topicId, lessonId, newData, () => console.log(''))

    if (descriptionEdit && event === 'description') setDescriptionEdit(false)
    if (transcriptEdit && event === 'transcript') setTranscriptEdit(false)
  }

  const video = (lessonData.video && lessonData.video) || ''
  const audio = (lessonData.audio && lessonData.audio.link) || ''
  const document =
    (lessonData.contentDocument && lessonData.contentDocument.link) || ''

  const handleLessonDelete = (ids: string[]) => {
    const title: string =
      ids.length === 1
        ? t('popups.delete_lesson_single')
        : t('popups.delete_lesson_many')
    const params = {
      title,
      onConfirm: () => deleteLesson(ids, () => navigate(-1)),
      confirmText: t('actions.confirm_delete'),
    }

    fireSwal(params)
  }

  const editLessonDrawer = (lessonId: string) => {
    DrawerEventEmitter.emit('openDrawer', 'editLesson', true, {
      id: null,
      data: {
        id: lessonId,
        fromTopic: true,
      },
    })
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const action = actionConfig(
    editLessonDrawer,
    handleLessonDelete,
    t,
    '',
    state.userPermission,
  )
  const downloadActions = downloadActionConfig(() => console.log(''), t)

  // აერორებდა, დროებით იყოს სტატიკურად და მერე moduleId შეგვილია Url-დან ამოვიღოთ
  // const moduleImage = moduleData && moduleData.getModule.coverImage

  let breadCrumbData: any = ''

  if (data && lessonData) {
    breadCrumbData = [
      {
        label:
          (routerState?.from &&
            `${t('general.course')} ${t('general.modules')}`) ||
          t('general.modules'),
        link: routerState?.from || '/modules',
        state: routerState?.state || {},
        icon: <Learning />,
      },
      {
        label: lessonData.moduleName,
        link: `/modules/${lessonData.moduleId}`,
        state: routerState,
      },
      { label: lessonData.name, link: '' },
    ]
  }

  const canModify = lessonData.companyId === (state.selectedCompany?.id || null)

  // find the link that has converted field true
  const hasConverted =
    video && video.links.find((link: { converted: boolean }) => link.converted)

  // const hasConverted = video && video.links[0].converted

  return (
    <div>
      <Breadcrumb data={breadCrumbData} />
      <CourseContentWrapper>
        <CourseContentLeft>
          <CourseContentHeader>
            <Thumbnail width={100} height={100} imageSrc={''} />

            <CourseContentHeaderSection>
              <CourseTitle>{lessonData.name}</CourseTitle>
            </CourseContentHeaderSection>
          </CourseContentHeader>

          <CourseContentDescription>
            {/* {editModuleLoading && <Loader withBackground={true} />} */}
            {lessonData.description && (
              <TextEditor
                text={lessonData.description}
                isVisible={descriptionEdit}
                handleChange={null}
                hasControls={true}
                editorTitle={t('lesson_details.lesson_overview')}
                closeEditMode={() => setDescriptionEdit(false)}
                openEditMode={() => setDescriptionEdit(true)}
                onSave={(editorText: string) =>
                  editEditorText(editorText, 'description')
                }
                canEdit={state.userPermission.editLesson && canModify}
                defaultText={t('default_texts.lesson')}
              />
            )}
          </CourseContentDescription>
          <CourseContentReview></CourseContentReview>
        </CourseContentLeft>

        <CourseContentRight
          style={{ textAlign: 'right' }}
          // className={!video ? 'noVideo' : ''}
        >
          <div style={{ display: 'inline-flex', marginBottom: '20px' }}>
            <GridActionMenu
              actionConfig={downloadActions}
              row={lessonData}
              icon={
                <Button
                  text={t('actions.download')}
                  size="small"
                  onClick={() => {}}
                  icon={<ArrowDropDownIcon />}
                  iconPosition="right"
                />
              }
            />
          </div>
          <div style={{ display: 'inline-flex', marginBottom: '20px' }}>
            {!(
              !state.userPermission.editLesson &&
              !state.userPermission.deleteLesson
            ) && (
              <GridActionMenu
                actionConfig={action}
                row={lessonData}
                icon={
                  <Button
                    text={t('general.actions')}
                    size="small"
                    onClick={() => {}}
                    icon={<ArrowDropDownIcon />}
                    iconPosition="right"
                  />
                }
              />
            )}
          </div>
          {video || audio ? (
            <DetailsContentVideoCover
              noBefore={
                video && !hasConverted && config.ENV === 'production'
                  ? true
                  : false
              }
            >
              {(video && hasConverted) ||
              (config.ENV === 'development' && !hasConverted) ? (
                <img src={video.thumbnail} />
              ) : null}
              <CourseContentPlayIcon onClick={() => setMediaModal(true)}>
                {(video && hasConverted) ||
                (config.ENV === 'development' && !hasConverted) ? (
                  <PlayCircleOutlineIcon fontSize="large" />
                ) : (
                  <VideoProcessingWrapper>
                    <CircularProgress />
                    <div> {t('general.video_processing_title')}</div>
                    <div> {t('general.video_processing_description')}</div>
                  </VideoProcessingWrapper>
                )}
                {audio && <HeadsetIcon fontSize="large" />}
              </CourseContentPlayIcon>
            </DetailsContentVideoCover>
          ) : null}
          {document && (
            <div
              style={{
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src="https://img.icons8.com/ios/500/document.png"
                alt=""
                style={{ width: '100px', cursor: 'pointer' }}
                onClick={() => window.open(document, '_blank')}
              />
            </div>
          )}
          <CourseContentDetailsList>
            {(video || audio) && (
              <div style={{ fontSize: '13px' }}>
                {t('general.duration')}: {lessonData.duration}
              </div>
            )}
          </CourseContentDetailsList>
        </CourseContentRight>
      </CourseContentWrapper>
      <CourseContentDescription>
        {lessonData.transcript && (
          <TextEditor
            text={lessonData.transcript}
            isVisible={transcriptEdit}
            hasControls={true}
            editorTitle={`${t('general.lesson')} ${t(
              'form_fields.transcript',
            )}`}
            closeEditMode={() => setTranscriptEdit(false)}
            openEditMode={() => setTranscriptEdit(true)}
            handleChange={null}
            onSave={(editorText: string) =>
              editEditorText(editorText, 'transcript')
            }
            canEdit={state.userPermission.editLesson && canModify}
            defaultText={t('default_texts.transcript')}
          />
        )}
      </CourseContentDescription>

      <CourseContentDescription>
        <Label>{t('lesson_details.resource')}</Label>
        <ResourceContainer>
          {(lessonData.attachment && (
            <div>
              <a href={lessonData.attachment.link} target="_blank">
                {lessonData.attachment.name}
              </a>
            </div>
          )) || <span>{t('lesson_details.no_resource')}</span>}
        </ResourceContainer>
      </CourseContentDescription>

      <Modal isOpened={mediaModal} onClose={() => setMediaModal(false)}>
        <div>
          {audio && <AudioPlayer url={audio} />}
          {video && video.links && (
            <Player url={video.links[0].url} subtitle={lessonData.subtitle} />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default LessonDetails
