import { useMutation } from '@apollo/client'

import { DELETE_QUIZ_BY_IDS } from 'gql/quiz/quiz.query'

const deleteQuizService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteQuizMutation, { loading, error }] = useMutation(
    DELETE_QUIZ_BY_IDS,
    {},
  )

  return {
    deleteQuizMutation,
    loading,
    error,
  }
}

export default deleteQuizService
