import { useQuery } from '@apollo/client'

import { GET_COURSES_OPTIONS } from 'gql/course/course.query'

export const useFetchCoursesOptions = (
  filter: any,
  currentPage = 1,
  perPage: number = 0,
) => {
  const { data, loading, error, refetch, fetchMore } = useQuery(
    GET_COURSES_OPTIONS,
    {
      variables: { filter, currentPage, perPage },
    },
  )
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    courses: data && data.getAllCourseOptions,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}
