import { useMutation } from '@apollo/client'
import { DELETE_TOPIC } from 'gql/topics.query'

const deleteTopicService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteTopicMutation, { loading, error }] = useMutation(DELETE_TOPIC)

  return {
    deleteTopicMutation,
    loading,
    error,
  }
}

export default deleteTopicService
