import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker/DatePicker'
import { FormField, FormFields, Label } from 'components/common/Form/Form'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import { genderOptions } from 'components/UsersLayout/staticData'
import { useFormik } from 'formik'
import { StudentSchema } from 'helpers/validationSchemas'
import useEditUserService from 'hooks/users/useEditUserService'
import { ButtonItem, ButtonContainer, Container } from './styled-components'

interface FromData {
  firstName: string
  lastName: string
  email: string
  phone: string
  phoneFields:
    | {
        label: string
        value: string
      }
    | ''
  gender: any
  birthDate: string
  note: string
  role: string
  biography: string
  group: any
  avatar: string
  jobTitle: string
  location: string
  phoneFieldValue: string | null
}

const formData: FromData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  phoneFields: '',
  gender: null,
  birthDate: '',
  note: '',
  role: 'STUDENT',
  biography: '',
  group: null,
  avatar: '',
  jobTitle: '',
  location: '',
  phoneFieldValue: null,
}

const EditStudent = ({ groupList, onCancel, item, t }: any) => {
  const { updateUser, user, error, editUserLoading } = useEditUserService({
    role: 'STUDENT',
    // companyId: state.selectedCompanyId,
    filter: {},
  })

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: formData,
    validationSchema: StudentSchema,
    onSubmit(values) {
      const formValues: any = { ...values }

      delete formValues.phoneFieldValue

      formValues.group = formValues.group
        ? formValues.group.map((group: any) => {
            return { name: group.label, groupId: group.value }
          })
        : []

      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }

      formValues.role = item.role
      formValues.gender = formValues.gender ? formValues.gender.value : null

      updateUser(item.id, formValues, onCancel)
    },
  })

  useEffect(() => {
    const { group, gender, role, ...args } = formData
    const data: any = {}

    for (const key in args) {
      data[key] = item[key]
    }

    data.group =
      item.group &&
      item.group.map((i: any) => ({ label: i.name, value: i.groupId }))

    if (item.gender) {
      const userGender = genderOptions.find(
        (option: { label: string; value: string }) =>
          option.value === item.gender,
      )
      data.gender = { label: userGender.label, value: item.gender }
    } else {
      data.gender = null
    }

    data.phoneFields = item.phoneFields &&
      item.phoneFields.dialCode && {
        label: item.phoneFields.code,
        value: item.phoneFields.dialCode,
      }

    data.birthDate = data.birthDate
      ? moment(parseInt(data.birthDate)).format()
      : null

    data.phoneFieldValue = item.phoneFields?.dialCode || null

    setValues({ ...data })
  }, [])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const groups = groupList.map((i: any) => ({ value: i.id, label: i.name }))

  const [currentDate, setCurrentDate] = useState<Date | null>(null)

  const onDateChange = (date: Date | null) => {
    // setCurrentDate(date)
    const birthDate = date
    setFieldValue('birthDate', date)
  }

  useEffect(() => {
    if (values.birthDate) {
      setCurrentDate(
        new Date(
          typeof (values.birthDate === 'string')
            ? values.birthDate
            : +values.birthDate,
        ),
      )
    }
  }, [values.birthDate])

  return (
    <Container>
      <FormFields>
        <FormField width="48%">
          <TextInput
            error={!!errors.firstName}
            errorMessage={errors.firstName}
            touched={touched.firstName}
            label={`${t('user_details.first_name')}*`}
            name="firstName"
            size="small"
            type="text"
            value={values.firstName}
            onChange={handleChange}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            error={!!errors.lastName}
            errorMessage={errors.lastName}
            touched={touched.lastName}
            label={`${t('user_details.last_name')}*`}
            name="lastName"
            size="small"
            type="text"
            value={values.lastName}
            onChange={handleChange}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            error={!!errors.email}
            errorMessage={errors.email}
            touched={touched.email}
            label={`${t('user_details.email')}*`}
            name="email"
            size="small"
            type="text"
            value={values.email}
            onChange={handleChange}
          />
        </FormField>

        <FormField width="48%">
          <PhoneCountryCode
            label={t('user_details.phone')}
            selectLabel={t('form_fields.dial_code')}
            selectValue={values.phoneFields}
            selectChange={(e: any) => setFieldValue('phoneFields', e)}
            inputLabel={t('user_details.phone')}
            inputValue={values.phone}
            inputChange={handleChange}
            error={errors.phone || errors.phoneFields ? true : false}
            errorMessage={
              errors.phone || errors.phoneFields
                ? errors.phone || errors.phoneFields
                : ''
            }
            touched={touched.phone || touched.phoneFields}
          />
          {/* <TextInput
            error={!!errors.phone}
            errorMessage={errors.phone}
            touched={touched.phone}
            label={`${t('form_fields.phone')}*`}
            name="phone"
            size="small"
            type="text"
            value={values.phone}
            onChange={handleChange}
          /> */}
        </FormField>

        <FormField width="48%" style={{ position: 'relative' }}>
          {values.gender && <Label>Gender</Label>}
          <SelectField
            placeholder={t('user_details.gender')}
            name="gender"
            options={genderOptions}
            value={values.gender}
            onChange={(e: any) => setFieldValue('gender', e)}
            isClearable
          />
        </FormField>

        <FormField width="48%">
          <DatePicker
            label={t('user_details.birthday')}
            touched={touched.birthDate}
            error={errors.birthDate ? true : false}
            errorMessage={errors.birthDate ? errors.birthDate : ''}
            name="birthDate"
            value={values.birthDate}
            onChange={onDateChange}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            label={t('form_fields.job_title')}
            size="small"
            name="jobTitle"
            type="text"
            value={values.jobTitle}
            onChange={handleChange}
          />
        </FormField>
        <FormField width="48%">
          <TextInput
            label={t('form_fields.location')}
            size="small"
            name="location"
            type="text"
            value={values.location}
            onChange={handleChange}
          />
        </FormField>
        {/*
        <FormField style={{ position: 'relative' }}>
          {values.group && values.group.length > 0 && (
            <Label>{t('general.group')}*</Label>
          )}
          <SelectField
            error={!!errors.group}
            errorMessage={errors.group}
            touched={touched.group}
            placeholder={`${t('general.group')}*`}
            name="group"
            options={groups}
            value={values.group}
            onChange={(e: any) => setFieldValue('group', e)}
            isClearable
            isMulti
          />
        </FormField> */}

        <FormField>
          <TextInput
            error={!!errors.biography}
            errorMessage={errors.biography ? errors.biography : ''}
            touched={touched.biography}
            label={t('user_details.biography')}
            size="small"
            name="biography"
            type="text"
            multiline
            rows="3"
            value={values.biography}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <TextInput
            error={!!errors.note}
            errorMessage={errors.note}
            touched={touched.note}
            label={t('user_details.note')}
            size="small"
            name="note"
            type="text"
            multiline
            rows="3"
            value={values.note}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <ButtonContainer>
            <ButtonItem style={{ marginRight: '10px' }}>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={onCancel}
                background="#E0E1E2"
                textColor="#414141"
              />
            </ButtonItem>

            <ButtonItem>
              <Button
                text={t('actions.save')}
                type="small"
                background="#06C68F"
                onClick={handleSubmit}
              />
            </ButtonItem>
          </ButtonContainer>
        </FormField>
      </FormFields>
    </Container>
  )
}

export default EditStudent
