import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react'

interface FilterContextType {
  filterRole: string
  setFilterRole: Dispatch<SetStateAction<string>>
}

const FilterRole = createContext<FilterContextType | undefined>(undefined)

export const FilterRoleProvider = ({ children }: { children: ReactNode }) => {
  const [filterRole, setFilterRole] = useState<string>('')

  return (
    <FilterRole.Provider value={{ filterRole, setFilterRole }}>
      {children}
    </FilterRole.Provider>
  )
}

export const useFilterRoleContext = () => {
  const context = useContext(FilterRole)
  if (context === undefined) {
    throw new Error(
      'useFilterRoleProvider must be used within a FilterRoleProvider',
    )
  }
  return context
}
