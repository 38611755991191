import React from 'react'
import CreatableSelect from 'react-select/creatable'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { t } from 'i18next'
import {
  Container,
  DrawerHeader,
  DrawerContent,
  Title,
  HelperLabel,
  FlexWrapper,
} from './styled-components'
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material'
import { HelpButtonContainer } from 'pages/quizzes/AddQuizDrawer/styled-components'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import Uploader from 'components/common/FileUploaderV2'
import { FormTitle } from 'pages/users/styled-components'
import {
  FormField,
  FormFields,
  FormButtons,
  FormFooter,
} from 'components/common/Form'
import { ItemWrapper } from 'components/styled-components'
import { Props } from './addTestDrawer.interface'
import { StyledUploadLabel } from '../editTestDrawer/styled-components'
import useAddTestLogic from 'hooks/tests/useAddTestLogic'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'

const AddTestDrawer = ({ onClose, onSuccess }: Props) => {
  const {
    handleSubmit,
    values,
    errors,
    cropperOpened,
    setCropperOpened,
    touched,
    file,
    handleCropSave,
    setFieldValue,
    handleImageChange,
    handleCloseDrawer,
    tagList,
    imageLoading,
    handleChange,
  } = useAddTestLogic({ onSuccess, onClose })

  return (
    <>
      <Container>
        <DrawerHeader>
          <FormTitle> {t('tests_layout.new_test')} </FormTitle>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <DrawerContent>
          <FormFields>
            <FormField>
              <Title>
                {t('tests_layout.test')} {t('form_fields.name')}
              </Title>
              <TextInput
                error={errors.name ? true : false}
                errorMessage={errors.name ? errors.name : ''}
                touched={touched.name}
                label={''}
                size="small"
                placeholder={`${t('form_fields.name')}`}
                name="name"
                type="text"
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <Title>
                {t('form_fields.select')} {t('general.tags')}
              </Title>
              <CreatableSelect
                isMulti
                onChange={(tags) => setFieldValue('tags', tags)}
                options={tagList}
                value={values.tags}
                placeholder={t('general.tags')}
              />
            </FormField>
            <FormField>
              <Title>{t('general.overview')}</Title>
              <TextInput
                label=""
                placeholder="Type question here"
                size="small"
                name="description"
                type="text"
                fullWidth={true}
                onChange={handleChange}
                multiline
                rows="4"
              />
            </FormField>
            <FormField>
              <Uploader
                id="attachment"
                type={FileTypes.IMAGE}
                label={
                  <>
                    <StyledUploadLabel>
                      {t('actions.upload_cover_image')}
                    </StyledUploadLabel>
                  </>
                }
                deleteFile={() => setFieldValue('attachment', null)}
                accept="image/x-png,image/gif,image/jpeg"
                inputValue={values.attachment ? values.attachment.name : ''}
                onFileChange={(e) => handleImageChange(e)}
                uploadedFile={values.attachment}
                loading={!!imageLoading}
              />
              {touched.attachment && errors.attachment && (
                <span style={{ color: '#f44336' }}>
                  <>{errors.attachment}</>
                </span>
              )}
            </FormField>
            <FormField>
              <Title>{t('quiz_details.time_in_minutes')} </Title>
              <TextInput
                label=""
                placeholder="e.g 20 minutes"
                size="small"
                name="time"
                type="number"
                onChange={handleChange}
              />
            </FormField>

            <FormField>
              <RadioGroup
                name="quizParticipationType"
                value={values.quizParticipationType}
                onChange={handleChange}
              >
                <HelperLabel>
                  <Title>
                    {t('quiz_details.participation')} {t('general.type')}
                  </Title>
                  <HelpButtonContainer>
                    <Tooltip
                      title={
                        <span style={{ fontSize: '14px' }}>
                          {values.quizParticipationType &&
                          values.quizParticipationType === 'mandatory'
                            ? t('general.mandatory_helper_test')
                            : t('general.optional_helper_test')}
                        </span>
                      }
                      arrow
                    >
                      <HelpOutlineIcon fontSize="small" />
                    </Tooltip>
                  </HelpButtonContainer>
                </HelperLabel>

                <FlexWrapper>
                  <ItemWrapper>
                    <FormControlLabel
                      value="mandatory"
                      control={<Radio color="primary" />}
                      label={t('general.mandatory')}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <FormControlLabel
                      value="optional"
                      control={<Radio color="primary" />}
                      label={t('general.optional')}
                    />
                  </ItemWrapper>
                </FlexWrapper>
              </RadioGroup>
            </FormField>
            <FormField>
              <Title>{t('quiz_details.pass_rate')}</Title>
              <TextInput
                label=""
                size="small"
                name="passRate"
                type="number"
                placeholder="e.g 80%"
                disabled={values.quizParticipationType === 'optional'}
                onChange={handleChange}
              />
            </FormField>
          </FormFields>
        </DrawerContent>
        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.save')}
              type="small"
              onClick={handleSubmit}
              background="#06C68F"
            />
            <Button
              text={t('actions.cancel')}
              type="small"
              onClick={handleCloseDrawer}
              background="#E0E1E2"
              textColor="#414141"
            />
          </FormButtons>
        </FormFooter>
      </Container>
      <Modal
        isOpened={cropperOpened}
        onClose={() => setCropperOpened(!cropperOpened)}
      >
        <ImageCropper
          file={file}
          hideCropper={() => setCropperOpened(!cropperOpened)}
          getCroppedFile={(_, file) =>
            handleCropSave(file, 'tests', 'attachment', setFieldValue)
          }
        />
      </Modal>
    </>
  )
}

export default AddTestDrawer
