import React, { FormEvent } from 'react'
import {
  Field,
  ValidationIcon,
  StyledComponentContainer,
  CharactersContainer,
} from './styled-components'
import TextField from '@mui/material/TextField'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import './TextInput.scss'
import { FormikErrors, FormikTouched } from 'formik'
import { IFilterTypes } from 'pages/admins/FilterAdmins/filterAdmins.interface'
import { Title } from 'components/DrawerLayout/styled-components'

export interface Props {
  title?: string
  label?: string
  size?: 'small' | 'medium' | undefined
  icon?: React.ReactElement
  iconInStart?: boolean
  required?: boolean
  name?: string
  id?: string // Include id prop
  type?: string
  value?: string | number | IFilterTypes | null
  error?: boolean
  errorMessage?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
  fullWidth?: boolean
  multiline?: boolean
  rows?: number | string
  validate?: boolean
  disabled?: boolean
  readOnly?: boolean
  min?: number
  max?: number
  onChange?: any
  onBlur?: (
    eventOrPath: string | FormEvent<any>,
  ) => void | ((eventOrTextValue: string | FormEvent<any>) => void)
  onFocus?: (
    eventOrPath: string | FormEvent<any>,
  ) => void | ((eventOrTextValue: string | FormEvent<any>) => void)
  endAdornment?: any
  component?: any
  inputStyle?: React.CSSProperties
  maxCharacter?: number
  variant?: 'standard' | 'filled' | 'outlined' | undefined
  placeholder?: string
  className?: string
}

const TextInput = ({
  title,
  label,
  size,
  icon,
  name,
  id = 'standard-basic',
  type,
  value,
  error,
  errorMessage,
  touched,
  fullWidth,
  onChange,
  onBlur,
  min,
  max,
  onFocus,
  multiline,
  rows,
  variant = 'outlined',
  validate = true,
  disabled = false,
  readOnly = false,
  endAdornment,
  component,
  inputStyle,
  maxCharacter,
  required = false,
  iconInStart = true,
  placeholder = '',
  className = '',
}: Props) => {
  const [showPassword, setShowPassword] = React.useState('password')

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (maxCharacter && event.target.value.length <= maxCharacter) {
      onChange(event)
    }
  }

  return (
    <Field
      className={fullWidth ? 'full' : ''}
      iconInStart={iconInStart && !!icon}
    >
      {title && (
        <Title>
          {title}
          {required && '*'}
        </Title>
      )}
      <TextField
        error={touched && error ? true : false}
        helperText={touched && error ? (errorMessage as string) : ''}
        id={id}
        label={label}
        variant={variant}
        size={size}
        name={name}
        type={type === 'password' ? showPassword : type}
        value={value}
        onChange={maxCharacter ? handleInputChange : onChange}
        required={required}
        onBlur={onBlur}
        onFocus={onFocus}
        style={{ width: '100%' }}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        InputProps={{
          readOnly,
          endAdornment,
          style: inputStyle,
          inputProps: {
            min,
            max,
          },
        }}
        inputProps={{
          maxLength: maxCharacter,
        }}
        autoComplete="off"
        placeholder={placeholder}
        className={className}
      />
      {maxCharacter && typeof value === 'string' && (
        <CharactersContainer>
          {value?.length ? maxCharacter - value?.length : maxCharacter} -{' '}
          {maxCharacter} symbols remaining
        </CharactersContainer>
      )}
      {touched && validate && (
        <ValidationIcon className={`${error ? 'error' : 'valid'}`}>
          {error ? (
            <ErrorOutlineRoundedIcon />
          ) : (
            <CheckCircleOutlineRoundedIcon />
          )}
        </ValidationIcon>
      )}
      {icon && icon}

      {component ? (
        <StyledComponentContainer>{component}</StyledComponentContainer>
      ) : null}

      {}
    </Field>
  )
}

export default TextInput
