import React, { ReactElement } from 'react'
import { NewContainer } from './styled-components'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/common/Button'

import CloseIcon from '@mui/icons-material/Close'

import IconButton from '@mui/material/IconButton'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/DrawerLayout/styled-components'
import { FormButtons, FormFooter } from 'components/common/Form'
import { Group } from 'interfaces/groups'
import GroupStudents from '../GroupStudents/GroupStudents'

const AddStudentsInNewGroup = ({
  groupData,
  closeDrawer,
}: {
  groupData: Group
  closeDrawer: () => void
}): ReactElement => {
  const { t } = useTranslation()
  const { company } = groupData
  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('groups_layout.modify_students')}</DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <NewContainer>
          <GroupStudents group={groupData} companyId={company?.id} />
        </NewContainer>
        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.done')}
              color="secondary"
              type="small"
              onClick={closeDrawer}
              background="#06C68F"
            />
          </FormButtons>
        </FormFooter>
      </DrawerContent>
    </>
  )
}

export default AddStudentsInNewGroup
