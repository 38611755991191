import { UPDATE_TASK_BY_ID } from 'gql/tasks.query'
import { useMutation } from '@apollo/client'

export const useUpdateTask = () => {
  const [updateTaskMutation, { loading: updating, error: updateError }] =
    useMutation(UPDATE_TASK_BY_ID)

  const updateTask = async (taskDetails: {
    _id: string
    [key: string]: unknown //couldnt find the type of taskDetails
  }) => {
    try {
      const { ...inputDetails } = taskDetails

      const response = await updateTaskMutation({
        variables: {
          input: inputDetails,
        },
      })
      return { data: response.data, loading: updating, error: null }
    } catch (error) {
      console.error('Error updating task:', error)
      return { data: null, loading: false, error: error }
    }
  }

  return { updateTask, updating, updateError }
}
