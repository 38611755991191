import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Details = styled.div`
  width: 300px;
  padding: 10px;
`

export const DetailsTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
`

export const DetailsList = styled.ul`
  padding-left: 0;
  margin: 15px 0;
`

export const DetailsListItem = styled.div`
  strong {
    margin-right: 5px;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const TagItem = styled.div`
  display: inline-block;
  font-style: italic;
  &:not(:last-child) {
    margin-right: 8px;
  }
`

export const Content = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
`

export const FilesContainer = styled.div`
  display: flex;
  /* > div {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  } */
`

export const MediaContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  width: 50%;
  flex-grow: 2;
  /* box-shadow: 0 0 18px rgba(0, 0, 0, 0.1); */
  &:not(:last-child) {
    margin-right: 15px;
  }
`

export const MediaHeader = styled.header`
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  color: #f50057;
  font-weight: 500;
  svg {
    margin-right: 10px;
  }
`

export const MediaContent = styled.section`
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`

export const MediaContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
`

export const MediaTitle = styled.h3`
  padding: 5px;
  display: inline-block;
  max-width: 200px;
  font-size: 14px;
`

export const VideoContainer = styled.div`
  width: 200px;
  height: 120px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    fill: #fff;

    &:hover {
      fill: #f50057;
    }
  }

  .MuiCircularProgress-colorPrimary {
    color: #dbdbdb;

    svg {
      width: 30px;
      height: 30px;
    }
  }
`

export const ImageContainer = styled.figure`
  position: relative;
  width: 200px;
  height: 120px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.2);
    transition: transform 0.2s ease;
  }

  svg {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    fill: #fff;
    opacity: 0;
    transition: opacity 0.2s ease;

    &:hover {
      fill: #f50057;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    svg,
    &:after {
      opacity: 1;
    }

    img {
      transform: scale(1);
    }
  }
`

export const DocumentContainer = styled.div`
  margin-top: 5px;

  > div {
    background: #eee;
    margin-top: 5px;
    border-radius: 5px;
    padding: 10px 15px 10px 10px;
    display: flex;
    align-items: center;
    transition: 0.2s ease color;

    &:hover {
      color: #f50057;
    }
  }

  > div {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`

export const DocumentName = styled.div`
  margin-left: 5px;
`

export const QuestionTitle = styled.h1`
  margin: 20px 0;
  font-size: 30px;
  max-width: 800px;
  text-align: center;
`
export const BoolIcon = styled.span`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 20px;
  }

  svg {
    width: 100px;
    height: 100px;
  }

  &.true svg {
    fill: green;
  }

  &.false svg {
    color: red;
  }
`

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label span {
    color: inherit !important;
  }

  label span.Mui-checked {
    color: #ffffff !important;
  }
`

export const AnswerContainer = styled.div`
  background: #fff;
  border-radius: 5px;
`

export const CorrectAnswerTitle = styled.div<{ isCorrect?: boolean }>`
  color: ${({ isCorrect }): string => (isCorrect ? '#FFFFFF' : 'inherit')};
  font-weight: 500;
  font-size: 16px;
`

export const AnswerReason = styled.article`
  margin-top: 15px;
  font-size: 16px;
  font-style: italic;
`
export const NoValueWrapper = styled.div`
  display: inline-block;
  font-size: 12px;
  opacity: 0.5;
  font-style: italic;
`

export const StyledQuestionDetailContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 16px;
  border-radius: 4px;
`
export const StyledTableContainer = styled.div`
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  margin-top: 20px;
  margin-bottom: 24px;
  padding: 10px;
`
export const StyledBottomBorder = styled.div`
  border-bottom: 1px solid #e7e9ed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`
export const ActionsWrapper = styled.div`
  /* position: absolute; */
  right: 0;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
  }

  svg:hover {
    transition: 0.3s ease;
    background-color: #36cea7;
    border-radius: 4px;
    color: '#fff';
  }
  svg:hover path {
    transition: 0.3s ease;
    fill: #fff !important;
  }
`
export const StyledDetailsContainer = styled.div`
  display: flex;
  gap: 16px;
`
export const StyledOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3);
  padding: 16px 16px 16px 0;
  overflow-wrap: break-word;
  border-right: 1px solid #e7e9ed;
`
export const StyledConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 0;
`

export const BorderStyle = styled.div<{ isCorrect?: boolean }>`
  color: ${({ isCorrect }): string => (isCorrect ? '#FFFFFF' : 'inherit')};
  border: 1px solid #d6dae0;
  margin: 8px;
  padding: 16px;
  width: 600px;
  display: flex;
  padding: 12px;
  border-radius: 8px;
  background-color: ${({ isCorrect }): string =>
    isCorrect ? '#00905E' : 'transparent'};
`
export const RightCorner = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`
export const styles = {
  detailTitle: {
    color: '#68707A',
    padding: 0,
    margin: 0,
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: '20px',
  },
  detailHeader: {
    fontWeight: 500,
    fontSize: '16px',
    lineWeight: '26px',
  },
  actionSpan: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '18px',
    color: '#31313d',
    marginLeft: '0',
  },
}

export const ContextMenu = styled.div`
  display: flex;
  justify-content: flex-end;
`
