import { OperationVariables, useLazyQuery, useQuery } from '@apollo/client'

import { GET_USERS } from 'gql/users.query'
import { User } from 'interfaces/users'

export const PER_PAGE = 10

interface IGetUsersResponse {
  data: User[]
  totalPages: number
}
interface IUseLazyFetchUsersResponse {
  users: IGetUsersResponse
  loading: boolean
  error: string | undefined
  fetchUsers: (variables: OperationVariables) => void
}

export const useFetchUsers = (
  role: string,
  filter: any = {},
  currentPage = 1,
  perPage: number = PER_PAGE,
) => {
  const { data, loading, error, refetch } = useQuery(GET_USERS, {
    variables: {
      role,
      filter: { ...filter },
      currentPage,
      perPage,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.usersByRole,
    loading,
    error: errorMessage,
    refetch,
  }
}

export const useLazyFetchUsers = (role: string): IUseLazyFetchUsersResponse => {
  const [fetchUsers, { data, loading, error }] = useLazyQuery(GET_USERS, {
    variables: {
      role,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.usersByRole,
    loading,
    error: errorMessage,
    fetchUsers: (variables) => fetchUsers({ variables }),
  }
}
