import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useAddGroup from '../useAddGroup'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { useData } from 'context/DataContext'

import { SET_CREATED_USER } from 'store/types'
import { useFormik } from 'formik'
import { ISelectOption } from 'pages/customNotifications/customNotification.interface'
import { GroupSchema } from 'helpers/validationSchemas'
import { checkValues } from 'pages/group/checkGroupsValues'
import { AddGroupDrawerProps, initialFormData } from './useGroupDrawer'

const useAddGroupDrawer = ({
  companyId,
  courseData,
  onClose,
  afterSave,
}: AddGroupDrawerProps) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const { loading, addGroup } = useAddGroup({
    filter: {
      company: {
        type: LowerCaseFilterableFieldType.EXACT,
        value: companyId,
      },
    },
    currentPage: 1,
    perPage: 10,
  })

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: initialFormData,
      validationSchema: GroupSchema,
      onSubmit: async (values: any) => {
        const data = { ...values }

        if (data.admin) {
          data.admin = data.admin.map((i: ISelectOption) => i.value)
        }

        data.courses = []

        if (companyId) data.company = companyId
        else if (state.selectedCompany?.id) {
          data.company = companyId
        }

        if (courseData) data.courses = [courseData]
        else
          data.courses =
            values.courses?.map((i: ISelectOption) => ({
              courseId: i.value,
              name: i.label,
            })) || []

        await addGroup(data)
        if (afterSave) afterSave()
        onClose()
      },
    })

  const handleCloseDrawer = () => {
    const isEmpty = checkValues(values)

    if (isEmpty) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          onClose()
        },
        onClose: () => {},
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }
  return {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    loading,
    handleCloseDrawer,
  }
}

export default useAddGroupDrawer
