import React, { useState, useEffect, useRef } from 'react'
import gql from 'graphql-tag'
import _ from 'lodash'
import { useQuery, useLazyQuery } from '@apollo/client'
import { GET_COACHES_BY_COMPANYID, GET_USERS } from 'gql/users.query'
import { useUserValue } from 'context/UserContext'
import { GET_ALL_SKILLS } from 'gql/skilss.query'
import { FormItemContainer, Label } from './styled-components'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { Input } from 'components/common/TextInput'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import { GET_COMPANIES } from 'gql/companies.query'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'

import {
  FormContainer,
  FormGroupWrapper,
  FormGroup,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { generateFullName } from 'utils/utils'

import { Button } from 'components/common/Button'
// import Select from 'components/common/Select'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import {
  courseState,
  courseLevels,
  coursePrivacy,
  filterOptions,
  videosOptions,
} from 'pages/courses/staticData'
import { useFormik } from 'formik'

const GET_GROUPS = gql`
  query ($filter: GroupFilter) {
    getAllGroups(filter: $filter) {
      data {
        name
        id
      }
    }
  }
`
export const CourseFilterDrawerContext = React.createContext({
  companyId: '',
})

const FilterDrawer = (props: any) => {
  const [state] = useUserValue()
  const ref: any = useRef()
  const [studentRange, setStudentRange] = useState([0, 300])
  const [filterData, setFilterData] = useState<any>(filterOptions)

  const [fetchCoach, { data: coachData, loading: userLoading }] =
    useLazyQuery(GET_USERS)

  const [
    fetchCoachesByCompanyId,
    { data: coachesByCompanyIdData, loading: coachingLoading },
  ] = useLazyQuery(GET_COACHES_BY_COMPANYID)

  const [fetchCompany, { data: companyData, loading: companyLoading }] =
    useLazyQuery(GET_COMPANIES)
  const [fetchGroup, { data: groupData, loading: groupLoading }] =
    useLazyQuery(GET_GROUPS)

  const { data: skillsData } = useQuery(GET_ALL_SKILLS)

  useEffect(() => {
    if (props?.filterData?.values) {
      setFilterData(props.filterData.values)
      setStudentRange(props.filterData.values.studentRange)
    }
  }, [props.filterData])

  const onClearClick = () => {
    props.resetFilter()
    props.onClose()
  }

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    initialValues: props?.filterData?.values || filterData,

    onSubmit(values) {
      const formValues: any = {}
      if (values.name !== '') {
        formValues.name = {
          type: 'MATCH',
          value: values.name,
        }
      }

      if (values.state.value !== 'all') {
        formValues.state = {
          type: 'EXACT',
          value: values.state.value.toUpperCase(),
        }
      }

      if (values.level.value !== 'all') {
        formValues.level = {
          type: 'EXACT',
          value: values.level.value.toUpperCase(),
        }
      }

      if (values.video.value !== 'all') {
        formValues.video = {
          type: 'EXISTS',
          value: values.video.value,
        }
      }

      if (values?.coaches && values.coaches.length > 0) {
        const selectedCoachesIds = values.coaches.map((i: any) => i.value)
        formValues.coaches = {
          type: 'NESTED_ARRAY_IN',
          value: selectedCoachesIds,
          nestedField: 'USER_ID',
        }
      }

      if (values?.company?.value) {
        formValues.companyId = {
          type: 'EXACT',
          value: values.company.value,
        }
      }

      if (values?.skills && values.skills.length > 0) {
        formValues.skills = {
          type: 'NESTED_ARRAY_IN',
          value: values.skills.map((skills: any) => skills.label),
          nestedField: 'LABEL',
        }
      }
      if (values?.group && values.group.length > 0) {
        formValues.groups = {
          type: 'NESTED_ARRAY_IN',
          value: values.group.map((group: any) => group.value),
          nestedField: 'GROUP_ID',
        }
      }

      // if student range is within the default range, don't send it
      // to prevent not fetching courses without student range
      if (studentRange && !(studentRange[0] === 0 && studentRange[1] === 300)) {
        formValues.numberOfStudents = {
          type: 'RANGE',
          min: studentRange[0],
          max: studentRange[1],
        }
      }

      props.setCurrentPage(1)
      props.setFilterData({
        filterOptions: { ...formValues },
        values: { ...values, studentRange },
      })
      props.refetchCourse()
      props.onClose()
    },
  })

  const loadDataOptions = (e: any, field: string) =>
    setTimeout(() => {
      const value = e

      if (field === 'coach') {
        let filter: any = {
          search: {
            type: 'search',
            value,
            fields: ['firstName', 'lastName'],
          },
        }
        if (props.isOnPurchasedCourses && props.coachIds.length > 0) {
          filter = {
            ...filter,
            _id: {
              type: 'arrayIn',
              value: props.coachIds,
            },
            companyId: {
              type: 'exact',
              value: null,
            },
          }
        } else
          filter = {
            ...filter,
            companyId: {
              type: props.tabValue == 1 ? 'notEqual' : 'exact',
              value:
                props.isOnStudentProfile && props.companyId
                  ? props.companyId
                  : props.tabValue == 1
                  ? null
                  : state.selectedCompany?.id,
            },
          }
        if (props.companyId && props.isOnStudentProfile)
          fetchCoachesByCompanyId({ variables: { companyId: props.companyId } })
        else
          fetchCoach({
            variables: {
              role: 'COACH',
              filter: {
                ...filter,
              },
            },
          })
      }

      if (field === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: 'MATCH',
                value,
              },
            },
          },
        })
      }

      if (field === 'group') {
        const filter = {
          name: {
            type: 'match',
            value,
          },
          company: {
            type:
              !!props.companyId && !props.isOnStudentProfile
                ? 'notEqual'
                : props.tabValue == 1
                ? 'notEqual'
                : 'exact',
            value:
              props.companyId ||
              (props.tabValue == 1 ? null : state.selectedCompany?.id),
          },
        }

        fetchGroup({
          variables: {
            filter: {
              ...filter,
            },
            perPage: 0,
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, field: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }

  const skillList =
    skillsData &&
    skillsData.getAllSkills.map((i: any) => ({
      label: i.label,
      id: i.id,
      value: i.id,
    }))

  const { t } = props

  return (
    <DrawerContent>
      <DrawerHeader>
        <DrawerTitle>
          {t('general.advanced_filters')} - {t('actions.to_filter')}{' '}
          {t('general.courses')}
        </DrawerTitle>
        <IconButton onClick={props.onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <FormContainer>
        <FormGroupWrapper>
          <FormGroup onSubmit={handleSubmit}>
            <FormGroupItem>
              <Input
                label={`${t('general.course')} ${t('general.name')}`}
                name="name"
                size="small"
                type="text"
                fullWidth
                value={values.name}
                onChange={handleChange}
              />
            </FormGroupItem>
            {!props.isOnPurchasedCourses && (
              <FormGroupItem>
                <FormItemContainer>
                  <Label>
                    {t('courses_layout.published')}/
                    {t('courses_layout.unpublished')}
                  </Label>
                  <SelectField
                    name="state"
                    options={courseState}
                    value={values.state}
                    onChange={(e: any) => setFieldValue('state', e)}
                  />
                </FormItemContainer>
              </FormGroupItem>
            )}

            <FormGroupItem>
              <FormItemContainer>
                <Label>
                  {t('general.course')} {t('course_details.level')}
                </Label>
                <SelectField
                  name="level"
                  options={courseLevels}
                  value={values.level}
                  onChange={(e: any) => setFieldValue('level', e)}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>Intro Video</Label>
                <SelectField
                  onChange={(e: any) => setFieldValue('video', e)}
                  options={videosOptions}
                  name="video"
                  value={values.video}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.coach')}</Label>
                <AsyncSelect
                  isMulti
                  onInputChange={(e: string) => handleSelectChange(e, 'coach')}
                  onChange={(e: any) => setFieldValue('coaches', e)}
                  data={
                    props.isOnStudentProfile &&
                    coachesByCompanyIdData?.getCoachesByCompany
                      ? coachesByCompanyIdData.getCoachesByCompany
                      : (coachData && coachData.usersByRole.data) || []
                  }
                  value={values.coaches}
                  label={t('form_fields.type_to_search_coaches')}
                  loading={
                    props.isOnStudentProfile ? coachingLoading : userLoading
                  }
                  labelDataName={generateFullName}
                  valueDataKey="id"
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.skills')}</Label>
                <SelectField
                  isMulti
                  value={values.skills}
                  placeholder={t('form_fields.select')}
                  options={skillList}
                  onChange={(e: any) => setFieldValue('skills', e)}
                />
              </FormItemContainer>
            </FormGroupItem>

            {!props.isCooursePrivacyHidden && (
              <FormGroupItem>
                <FormItemContainer>
                  <Label>
                    {t('general.course')} {t('course_details.privacy')}
                  </Label>
                  <SelectField
                    label={t('course_details.privacy')}
                    name="coursePrivacy"
                    options={coursePrivacy}
                    value={values.coursePrivacy}
                    onChange={(e: any) => setFieldValue('coursePrivacy', e)}
                  />
                </FormItemContainer>
              </FormGroupItem>
            )}

            {props.tabValue === 1 && (
              <FormGroupItem>
                <FormItemContainer>
                  <Label>{t('general.company')}</Label>
                  <AsyncSelect
                    onInputChange={(e: string) =>
                      handleSelectChange(e, 'company')
                    }
                    onChange={(e: any) => setFieldValue('company', e)}
                    data={(companyData && companyData.companies.data) || []}
                    value={values.company}
                    label={t('form_fields.type_to_search_companies')}
                    loading={companyLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                  />
                </FormItemContainer>
              </FormGroupItem>
            )}

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.groups')}</Label>
                <AsyncSelect
                  isMulti
                  onInputChange={(e: string) => handleSelectChange(e, 'group')}
                  onChange={(e: any) => setFieldValue('group', e)}
                  data={(groupData && groupData.getAllGroups.data) || []}
                  value={values.group}
                  label={t('form_fields.type_to_search_groups')}
                  loading={groupLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <Typography id="range-slider" gutterBottom>
                {t('course_details.number_of_students')}
              </Typography>
              <Slider
                value={studentRange}
                onChange={(e: any, i: any) => setStudentRange(i)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={300}
              />
            </FormGroupItem>
          </FormGroup>
        </FormGroupWrapper>

        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.clear')}
              type="small"
              onClick={onClearClick}
              background="#E0E1E2"
              textColor="#414141"
            />

            <Button
              text={t('actions.filter')}
              type="small"
              color="secondary"
              background="#06C68F"
              onClick={handleSubmit}
            />
          </FormButtons>
        </FormFooter>
      </FormContainer>
    </DrawerContent>
  )
}

export default FilterDrawer

// const Container = styled.div`
//   box-sizing: border-box;
//   height: 100%;
//   overflow-y: scroll;
//   padding-bottom: 70px;
//   padding-top: 100px;
//   position: relative;
// `
