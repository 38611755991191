import { useState } from 'react'
import removeQuestionsFromTestAssessmentService from 'services/questions/removeQuestionsFromTestAssessmentService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export const useRemoveQuestionsFromTestAssessment = (testId: string) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const { removeQuestionsFromTestAssessmentMutation, loading } =
    removeQuestionsFromTestAssessmentService(testId)

  const removeQuestionsFromTestAssessment = async (
    testAssessmentId: string,
    questionIds: string[],
    successCallback?: () => void,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await removeQuestionsFromTestAssessmentMutation({
      variables: {
        testAssessmentId,
        questionIds,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return false
      }
      variant = 'error'
    }
    if (data) {
      if (successCallback) successCallback()
      message = t('messages.added_questions_to_module')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    removeQuestionsFromTestAssessment,
    removeQuestionsFromTestAssessmentLoading: loading,
    error,
  }
}
