import { useMutation } from '@apollo/client'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { GET_GROUP } from 'gql/group/group.query'
import { ADD_STUDENT } from 'gql/users.query'

const addStudentService = (companyId: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addStudentMutation, { loading, error }] = useMutation(ADD_STUDENT, {
    refetchQueries: [
      {
        query: GET_GROUP,
        variables: {
          filter: {
            company: {
              type: LowerCaseFilterableFieldType.EXACT,
              value: companyId,
            },
          },
        },
      },
    ],
  })

  return {
    addStudentMutation,
    loading,
    error,
  }
}

export default addStudentService
