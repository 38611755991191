import styled, { CSSProperties } from 'styled-components'

// AddTestDrawer
export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;

  & > .MuiRadio-colorSecondary.Mui-checked {
    color: #06c68f !important;
  }
`

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 550;
  padding-bottom: 5px;
  padding-top: 0px;
`

export const FormStyles: CSSProperties = {
  height: 'calc(100% - 68px)',
}

// Header
export const DrawerHeader = styled.header`
  padding: 10px 20px;
  background: #ffffff;
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 13px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`

export const DrawerHeaderSteps = styled.header`
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

export const DrawerTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
`

export const DrawerStepsTitle = styled.h2`
  font-size: 16px
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
`
export const RandomQuestions = styled.p`
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 0;
`

export const FormFooter = styled.footer`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  height: 60px;
`

export const FormButtons = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: space-between;

  div:not(:last-child) {
    margin-right: 20px;
  }
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const DrawerContent = styled.div`
  position: relative;
  overflow-y: auto;
`

export const StyledUploadLabel = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: black;
`
