import React from 'react'
import {
  Header,
  LayoutWrapper,
  RightContainer,
  Text,
  Wrapper,
} from './styled-components'
import Drawer from 'components/common/Drawer'
import LinkQuestionsToModuleDrawer from './LinkQuestionsToModuleDrawer/LinkQuestionsToModuleDrawer'
import FilterDrawer from './FilterDrawer'
import Grid from 'components/common/GridV2'
import Pagination from 'components/common/Pagination'
import Breadcrumb from 'components/common/BreadCrumbV2'
import {
  GridLayoutActions,
  MultipleActionTooltip,
  StudentsActionsContainer,
} from 'components/common/GridV2/styled-components'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg'
import { ToolbarItem } from 'components/common/Toolbar'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import TextInput from 'components/common/TextInput/TextInput'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import _ from 'lodash'
import useQuestionsLayout from 'hooks/questions/useQuestionsLayout'
import { SplashScreen } from 'components/common'

interface CurrentModuleParams {
  moduleId: string
  name: string
  courses: {
    courseId: string
    name: string
  }[]
}

interface Params {
  fromModule?: boolean
  topics: unknown[]
  currentModule?: CurrentModuleParams | undefined
  cols?: number
  isDraggable?: boolean
  refetchModule?: () => void
  manualCompanyId?: string
  canModify?: boolean
  handleTabChange?: any
  setOpenCreateQuizDrawer?: (open: boolean) => void
  routerState?: any
}

const QuestionsLayout = ({
  topics,
  currentModule,
  refetchModule,
  manualCompanyId,
  canModify,
}: Params): React.JSX.Element => {
  canModify = canModify === undefined ? true : canModify
  const {
    breadCrumbData,
    selectedItem,
    createButton,
    currentPage,
    t,
    handleDeleteClick,
    setFilterDrawerOpened,
    isFiltered,
    handleSearchSubmit,
    searchValue,
    handleSearchChange,
    loading,
    actions,
    config,
    cards,
    selectAllItem,
    filteredQuestionList,
    selectItem,
    redirectToQuestion,
    questions,
    handlePaginationClick,
    LinkQuestionDrawerOpened,
    handleCloseDrawer,
    setLinkQuestionDrawerOpened,
    refetch,
    filterDrawerOpened,
    handleFilterClick,
    setIsFiltered,
    filterData,
    setFilterData,
    setFiltered,
    setCurrentPage,
    companyId,
    isSearchType,
  } = useQuestionsLayout({
    topics,
    currentModule,
    refetchModule,
    manualCompanyId,
    canModify,
  })

  if (companyId && !searchValue && !isSearchType && !questions?.data.length) {
    return (
      <>
        <Breadcrumb data={breadCrumbData} />
        <SplashScreen
          title={t('questions_layout.no_questions')}
          subTitle={t('questions_layout.create_questions')}
          createButton={createButton}
        />
      </>
    )
  }

  return (
    <>
      <Breadcrumb data={breadCrumbData} />

      <LayoutWrapper>
        <Header>
          <Wrapper>
            <Wrapper>
              <div>{createButton()}</div>
              <GridLayoutActions>
                <GridMultipleActions
                  selectedItems={
                    (selectedItem[currentPage] &&
                      selectedItem[currentPage].length) ||
                    0
                  }
                  actions={[]}
                />
              </GridLayoutActions>
            </Wrapper>
            <RightContainer>
              <ToolbarItem>
                <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                  {isFiltered ? (
                    <RedFilterIcon color="#06C68F" />
                  ) : (
                    <FilterIcon />
                  )}
                </IconButton>
                <MultipleActionTooltip>
                  {t('actions.filters')}
                </MultipleActionTooltip>
              </ToolbarItem>
              <form onSubmit={handleSearchSubmit}>
                <TextInput
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchValue}
                  onChange={handleSearchChange}
                  icon={
                    <IconButton type="submit">
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                  iconInStart
                />
              </form>
              {currentModule && (
                <Text>{t('questions_layout.questions_recommendation')}</Text>
              )}
              {loading && !cards && <div>{t('general.loading')}...</div>}
            </RightContainer>
          </Wrapper>
        </Header>

        <Grid
          actionConfig={actions}
          actionTilesLength={4}
          config={config}
          data={currentModule ? filteredQuestionList : cards}
          selectedItems={selectedItem[currentPage] || []}
          selectAllItem={selectAllItem}
          selectItem={selectItem}
          selected={_.size(selectedItem[currentPage] || [])}
          redirectToDetailsHandler={redirectToQuestion}
          width={40}
          indexOfSpecificWidth={0}
          ActionsContainer={StudentsActionsContainer}
        />

        <Pagination
          currentPage={questions ? questions.currentPage : 1}
          totalPages={questions ? questions.totalPages : 0}
          handleChange={handlePaginationClick}
        />
      </LayoutWrapper>
      <Drawer
        opened={LinkQuestionDrawerOpened}
        toggleDrawer={(): void => {}}
        totalWidth="800px"
      >
        <LinkQuestionsToModuleDrawer
          title={t('questions_layout.link_questions_to_module')}
          handleCloseDrawer={handleCloseDrawer}
          closeDrawer={(): void => setLinkQuestionDrawerOpened(false)}
          currentModule={currentModule}
          refetch={(): void => {
            refetch()
            if (refetchModule) {
              refetchModule()
            }
          }}
          currentModuleQuestionsLength={cards.length}
          manualCompanyId={manualCompanyId}
        />
      </Drawer>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={(): void => {}}
        totalWidth="600px"
      >
        <FilterDrawer
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          handleFilterClick={handleFilterClick}
          setIsFiltered={setIsFiltered}
          filterData={filterData}
          quizModel={currentModule ? 'module' : 'general'}
          setFilterData={setFilterData}
          refetch={refetch}
          setFiltered={(): void => setFiltered(true)}
          questions={cards}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>
    </>
  )
}

export default QuestionsLayout
