import React, { useState, useEffect } from 'react'
import {
  Header,
  Title,
  Toolbar,
  SelectedWrapper,
  FormTitle,
  FormFooter,
  FormFieldsWrapper,
  ModulesList,
  FormButtons,
} from './styled-components'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'

import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import Checkbox from '@mui/material/Checkbox'
import { useFetchModules } from 'hooks/modules/useModules'
import { useAddCourseModules } from 'hooks/course/useEditCourse'
import IconButton from '@mui/material/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import SyncIcon from '@mui/icons-material/Sync'
import FilterListIcon from '@mui/icons-material/FilterList'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import {
  DrawerHeader,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'

import Drawer from 'components/common/Drawer'
import FilterDrawer from 'pages/modules/FilterDrawer/FilterDrawer'
import { DrawerEventEmitter } from 'helpers/drawer'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import { CardsLayout } from 'components/common/Cards'
import Modal from 'components/common/Modal'
import VideoPlayer from 'components/common/VideoPlayer'
import Pagination from 'components/common/Pagination'

interface Props {
  title: string
  drawerName?: string
  closeDrawer?: () => void
  data: any
}

const filterOption: any = {
  name: '',
  // duration: '',
  // courses: [],
  coverImage: { label: 'All', id: 0, value: 'all' },
  video: { label: 'All', id: 0, value: 'all' },
  attachment: { label: 'All', id: 0, value: 'all' },
  range: [0, 5000],
}

const AddCourseModuleDrawer = ({ title, drawerName, data }: Props) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()

  const company: any = {
    companyId: {
      type: 'exact',
      value: data.manualCompanyId
        ? data.manualCompanyId
        : state.selectedCompany?.id,
    },
  }

  // if (state.selectedCompanyId) {
  //   company.companyId = {
  //     type: 'exact',
  //     value: data.manualCompanyId
  //       ? data.manualCompanyId
  //       : state.selectedCompanyId,
  //   }
  // }
  const id = data.courseId
  const { modules, loading, refetch }: any = useFetchModules({
    name: { type: 'match', value: '' },
    course: { type: 'nestedArrayNotIn', value: id },
    ...company,
  })

  const { addCourseModules, addCourseModulesLoading, newModule } =
    useAddCourseModules({
      filter: {
        name: {
          type: 'match',
          value: '',
        },
        course: {
          type: 'nestedArrayIn',
          value: id,
        },
        ...company,
      },
    })

  const { fireSwal } = useSwal()
  const [dataState, dispatchData] = useData()
  const [filterData, setFilterData] = useState<any>(filterOption)
  const [selectedModules, setSelectedModules] = useState<any>([])
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [videoModal, setVideoModal] = useState({ modal: false, data: '' })
  const [selectedItem, setSelectedItem] = useState<any>([])
  const [selectAll, setSelectAll] = useState(false)
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(12)

  const selectItem = (id: string, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    if (newSelected.length > 0) {
      dispatchData({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    } else {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = modules.data.map((n: any) => n.id)
      setSelectedItem(newArr)
      setSelectAll(true)
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
      return
    }
    dispatch({
      type: TOGGLE_CLOSE_DRAWER,
      payload: {
        text: '',
        confirm: false,
      },
    })
    setSelectedItem([])
    setSelectAll(false)
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addCourseModule',
        values: selectedItem,
        compareTo: {},
      },
    })
    // if (selectedItem.length === 0) {
    // DrawerEventEmitter.emit('openDrawer', { drawerName }, false)
    // } else {
    //   fireSwal({
    //     title:
    //       t('popups.close_popup'),
    //     text: t('popups.sure'),
    //     confirmText: 'Yes, Cancel!',
    //     onConfirm: () =>
    //       DrawerEventEmitter.emit('openDrawer', { drawerName }, false),
    //   })
    // }
  }

  const handleSubmit = () => {
    const moduleIds = selectedItem
    addCourseModules(
      { courseId: data.courseId, name: data.name },
      moduleIds,
      () => {
        DrawerEventEmitter.emit('openDrawer', 'addCourseModule', false)
        data.refetchCourse()
      },
    )
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value)

    if (e.target.value === '') {
      refetch({
        filter: {
          name: {
            type: 'match',
            value: '',
          },
          course: {
            type: 'nestedArrayNotIn',
            value: id,
          },
          ...company,
        },
      })
    }
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    refetch({
      filter: {
        name: {
          type: 'match',
          value: searchValue,
        },
        course: {
          type: 'nestedArrayNotIn',
          value: id,
        },
        ...company,
      },
    })
  }

  const handleResetClick = () => {
    refetch({
      filter: {
        name: { type: 'match', value: '' },
        course: { type: 'nestedArrayNotIn', value: id },
        ...company,
      },
      currentPage: 1,
      perPage,
    })
    setFiltered(false)
    setSearchValue('')
    setFilterQuery({})
  }
  const openPdf = (item: any) => {
    item.attachment && window.open(item.attachment, '_blank')
  }

  const handleVideoModal = (item: any) => {
    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? item.video[0].links[2].url : '',
    })
  }

  const handlePaginationClick = (event: any, value: number) => {
    if (value !== currentPage) {
      refetch({
        filter: {
          course: { type: 'nestedArrayNotIn', value: id },
          query: {
            type: 'query',
            value: JSON.stringify(filterQuery),
          },
          ...company,
        },
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (newModule) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
      refetch({
        filter: {
          name: {
            type: 'match',
            value: '',
          },
          course: {
            type: 'nestedArrayNotIn',
            value: id,
          },
          ...company,
        },
      })
      setSelectedItem([])
    }
  }, [newModule])
  return (
    <>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => setFilterDrawerOpened(false)}
        totalWidth="500px"
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          refetchData={refetch}
          onClose={() => setFilterDrawerOpened(false)}
          setFiltered={setFiltered}
          courseId={id}
          type="nestedArrayNotIn"
          t={t}
        />
      </Drawer>
      <DrawerHeader>
        <FormTitle>{title}</FormTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <ModulesList>
          <FormFieldsWrapper>
            <Header
              style={{
                marginTop: 20,
                borderBottom: '1px solid #ccc',
                paddingBottom: 8,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title>{t('modules_layout.title')}</Title>
                <SelectedWrapper
                  className={selectedItem.length === 0 ? 'disabled' : ''}
                >
                  <strong>{selectedItem.length}</strong>
                  {t('general.items_selected')}
                </SelectedWrapper>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        modules && modules.data.length === selectedItem.length
                          ? true
                          : false
                      }
                      disabled={modules && modules.data.length === 0}
                      onChange={selectAllItem}
                      style={{ marginLeft: 10 }}
                    />
                  }
                  label={
                    modules && modules.data.length === selectedItem.length
                      ? t('general.diselect_all')
                      : t('general.select_all')
                  }
                />
              </div>
              <Toolbar>
                <div style={{ display: 'flex' }}>
                  <MultipleAction>
                    <IconButton
                      disabled={filtered || searchValue ? false : true}
                      onClick={handleResetClick}
                    >
                      <SyncIcon fontSize="small" />
                    </IconButton>
                    <MultipleActionTooltip>
                      {t('actions.reset')}
                    </MultipleActionTooltip>
                  </MultipleAction>
                  <MultipleAction>
                    <IconButton onClick={() => setFilterDrawerOpened(true)}>
                      <FilterListIcon fontSize="small" />
                    </IconButton>
                    <MultipleActionTooltip>
                      {t('actions.filter')}
                    </MultipleActionTooltip>
                  </MultipleAction>
                  <form
                    onSubmit={handleSearchSubmit}
                    style={{ marginRight: 10 }}
                  >
                    <TextInput
                      label={t('general.search_placeholder')}
                      type="text"
                      size="small"
                      value={searchValue}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton type="submit">
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                    />
                  </form>
                </div>
              </Toolbar>
            </Header>
            {loading && <div>Loading...</div>}
            {modules && (
              <div style={{ padding: 10 }}>
                <CardsLayout
                  gridStyle={'card'}
                  data={modules.data}
                  noRecordsText="No Records found"
                  handleVideoModal={handleVideoModal}
                  selectItem={selectItem}
                  selectedItem={selectedItem}
                  cols={2}
                  t={t}
                  refetch={refetch}
                  variant="list"
                  openInExternalTab={true}
                />
              </div>
            )}
          </FormFieldsWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              ></Button>
              <Button
                text={t('actions.add')}
                type="small"
                onClick={handleSubmit}
                isDisabled={selectedItem.length === 0}
                background="#06C68F"
              ></Button>
            </FormButtons>
            {modules && modules.data.length > 0 && (
              <div>
                <Pagination
                  currentPage={modules ? modules.currentPage : 1}
                  totalPages={modules ? modules.totalPages : 0}
                  handleChange={handlePaginationClick}
                />
              </div>
            )}
          </FormFooter>
        </ModulesList>
      </DrawerContent>
      <Modal isOpened={videoModal.modal} onClose={handleVideoModal}>
        <VideoPlayer url={videoModal.data} />
      </Modal>
    </>
  )
}

export default AddCourseModuleDrawer
