import React, { useState, useEffect } from 'react'
import { Container, FormContainer } from './styled-components'
import { useSwal } from 'hooks/useSwal'
import {
  DrawerHeaderSteps,
  DrawerStepsTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import Stepper from 'components/common/Stepper'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_COURSE_BY_ORIGINAL_ID } from 'gql/course/course.query'
import useDeleteCourse from 'hooks/course/useDeleteCourse'
import { useData } from 'context/DataContext'
import { useTranslation } from 'react-i18next'

import Step1 from './Step1'
import Step2 from 'pages/courses/EditCourseDrawer/Tab2'
import AssignCoach from 'pages/courses/AddCourseDrawer/AssignCoach'
import { generateFullName } from 'utils/utils'
import { useAddRemoveCourseFromCompany } from 'hooks/companies/useCompanySettings'
import { useChangeCourseStep } from 'hooks/course/useEditCourse'

interface Props {
  boughtCourses: string[]
  handleCloseDrawer: any
  isEditMode?: string
  refetchCourses: any
  hasPurchasedCourses?: boolean
}

const AddPurchasedCourseDrawer = (props: Props) => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()

  const [step, setStep] = useState<number>(0)
  const [selectedItem, setSelectedItem] = useState('')
  const [selectedGroup, setSelectedGroup] = useState<any>([])
  const params: any = useParams()
  const { deleteCourse } = useDeleteCourse()
  const { fireSwal } = useSwal()

  const { addRemoveCourseFromCompany } = useAddRemoveCourseFromCompany()
  const { changeCourseStep } = useChangeCourseStep()

  const [fetchCourse, { data: courseData, loading: courseLoading }] =
    useLazyQuery(GET_COURSE_BY_ORIGINAL_ID)

  useEffect(() => {
    if (props.isEditMode) {
      setStep(1)
      fetchCourse({
        variables: {
          courseId: props.isEditMode,
          companyId: params.id,
        },
      })
    }
  }, [props.isEditMode])

  const course = courseData && courseData.getCourseByOriginalId

  useEffect(() => {
    if (courseData && courseData.getCourseByOriginalId) {
      const groups = course.groups || []

      const courseGroups = groups.map((group: any) => ({
        groupId: group.groupId,
        name: group.name,
      }))
      setSelectedGroup(courseGroups)
    }
  }, [courseData])

  const handleChangeStep = (activeStep: number) => {
    setStep(activeStep)
  }

  // const editGroupFuc = (callback: any) => {
  // const data: any = {}
  // const { skills, ...args } = formData
  // for (const key in args) {
  //   data[key] = course[key]
  // }

  // if (data.avatar) delete data.avatar.__typename

  // if (data.certificate) delete data.certificate.__typename

  // if (data.subtitle) delete data.subtitle.__typename

  // if (data.video) {
  //   const { __typename, ...args } = data.video
  //   data.video = {
  //     id: args.id,
  //     title: args.title,
  //   }
  // }

  // if (!data.price) {
  //   data.price = null
  //   data.currency = ''
  // } else {
  //   data.price = parseFloat(data.price)
  // }

  // data.groups = selectedGroup
  // data.coaches = course.coaches.map((i: any) => i.userId)

  // const groupIds = selectedGroup.map((i: any) => i.groupId)

  // * Check if action is Edit only call addRemove groups from course service - fixed strange 504 error
  // ეს კომპონენტი არის Edit Purchased Course , Add Purchased Course პეიჯებისთვის ( დრავერებისთვის)
  // როცა  Edit Purchased Course ეშვეა მხოლოდ addRemoveGroupFromCourse

  // addRemoveGroupFromCourse(course.id, groupIds, callback)
  // }

  const handleButtonClick = (e: string) => {
    if (step === 0 && (e === 'next' || e === 'draft') && selectedItem) {
      addRemoveCourseFromCompany(
        params.id,
        // [selectedItem],
        [selectedItem, ...props.boughtCourses],
        () => {
          if (e === 'next') {
            handleChangeStep(1)
          }

          fetchCourse({
            variables: {
              courseId: selectedItem,
              companyId: params.id,
            },
          })

          if (e === 'draft') {
            props.refetchCourses()
            // changeCourseStep(course.id, step, () => { })
            props.handleCloseDrawer()
          }
        },
      )
      return
    }

    if (step === 1 && (e === 'next' || e === 'draft')) {
      changeCourseStep(course.id, e === 'draft' ? 1 : 2, () => {
        if (e === 'draft') {
          props.handleCloseDrawer()
          props.refetchCourses()
        } else {
          setStep(2)
        }
      })
      return
    }

    if (step === 2 && (e === 'next' || e === 'draft')) {
      changeCourseStep(course.id, e === 'draft' ? 2 : 3, () => {
        props.refetchCourses()
        props.handleCloseDrawer()
      })
      return
    }
  }

  const selectItem = (id: string) => {
    setSelectedItem(id)
  }

  const handleDeleteCourse = (fireConfirmationPopup = false) => {
    if (!course || props.isEditMode) {
      if (fireConfirmationPopup) {
        const params = {
          title: t('popups.close_popup'),
          text: t('popups.sure'),

          onConfirm: () => props.handleCloseDrawer(),
          confirmText: t('popups.confirm_cancel'),
          cancelText: t('general.no'),
        }

        return fireSwal(params)
      }
      return props.handleCloseDrawer()
    }

    const params = {
      title: t('popups.cancel_course_create'),
      onConfirm: () => {
        if (course && course.id) {
          deleteCourse([course.id], () => {
            props.refetchCourses()
            props.handleCloseDrawer()
          })
        } else {
          props.handleCloseDrawer()
        }
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const coaches =
    (course &&
      course.coaches.map((i: any) => ({
        label: generateFullName(i),
        value: i.userId,
      }))) ||
    []

  const courseWithCoach = { ...course, ...{ coaches } }

  const getStepContent = (stepIndex: number) => {
    if (stepIndex === 0 && props.isEditMode) setStep(1)
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            boughtCourses={props.boughtCourses}
            selectItem={selectItem}
            selectedItem={selectedItem}
            handleDeleteCourse={handleDeleteCourse}
            handleButtonClick={handleButtonClick}
            t={t}
          />
        )
      case 1:
        return (
          <Step2
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            handleDeleteCourse={handleDeleteCourse}
            handleButtonClick={handleButtonClick}
            step={courseWithCoach.finished || 0}
            editMode={props.isEditMode}
            handleChangeStep={(num: number) => handleChangeStep(step - num)}
            hasPurchasedCourses={props.hasPurchasedCourses}
            courseData={{ courseId: course?.id, name: course?.name }}
          />
        )

      case 2:
        return (
          <AssignCoach
            values={courseWithCoach}
            selectedGroup={selectedGroup}
            courseId={courseWithCoach.id}
            handleDeleteCourse={handleDeleteCourse}
            handleButtonClick={handleButtonClick}
            handleChangeStep={(num: number) => handleChangeStep(step - num)}
          />
        )
      default:
        return 'Error'
    }
  }

  const stepTitles = [
    t('general.suada_courses'),
    t('general.groups'),
    t('general.assign'),
  ]

  return (
    <Container>
      <DrawerHeaderSteps>
        <DrawerStepsTitle>
          {!props.isEditMode ? t('actions.add') : t('actions.edit')}{' '}
          {t('general.purchased_course')}
        </DrawerStepsTitle>

        <Stepper active={step} steps={stepTitles} />
      </DrawerHeaderSteps>

      <DrawerContent headerHeight={168}>
        {' '}
        <FormContainer>{getStepContent(step)}</FormContainer>
      </DrawerContent>
    </Container>
  )
}

export default AddPurchasedCourseDrawer
