import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup'
import { useQuery } from '@apollo/client'
import { GET_QUIZ_BY_ID } from 'gql/quiz/quiz.query'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'
import { SET_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import { QuizSchema, IQTestSchema } from 'helpers/validationSchemas'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { Button } from 'components/common/Button'

import { useEditQuizService } from 'hooks/quizzes/useEditQuizService'

import Step1 from './Step1'

const formData = {
  name: '',
  time: '',
  quizParticipationType: '',
  passRate: '',
  feedback: false,
  tags: [],
  IQTestType: '',
  randomization: false,
  isShowAnswersTurnedOn: true,
  randomizationNumber: '',
  description: '',
}

const EditQuizDrawer = (props: any) => {
  const { t } = useTranslation()
  const validationSchema = props.data.IQTest ? IQTestSchema : QuizSchema
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const [validation, setValidation] = useState<any>(validationSchema)
  const [currentQuiz, setCurrentQuiz] = useState<any>('')

  const { data } = useQuery(GET_QUIZ_BY_ID, {
    variables: {
      quizId: props.data.quizId,
    },
  })

  const { editQuiz } = useEditQuizService()
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: formData,
    validationSchema: Yup.object(validation),
    onSubmit: (values: any) => {
      const data = { ...values }

      if (!data.time || data.time === 0) {
        data.time = 0
      }

      if (data.tags && data.tags.length > 0) {
        data.tags = data.tags.map((i: any) => ({ label: i.label }))
      }
      if (data.description) data.description = values.description

      if (!data.randomization) data.randomizationNumber = 0

      if (data.quizParticipationType === 'optional') delete data.passRate

      if (data.IQTestType) data.IQTestType = values.IQTestType.value

      if (props.data.IQTest) {
        data.IQTestType = 'initial'
      }

      editQuiz(props.data.quizId, data, onSuccess)
    },
  })

  const onSuccess = () => {
    props.onClose()
    toggleDrawerConfirm(false, '')
  }

  const handleCloseDrawer = () => {
    const { quizParticipationType, ...formData } = values
    const { quizParticipationType: estra, ...currentQuizData } = currentQuiz
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editQuiz',
        values: formData,
        compareTo: currentQuizData,
      },
    })
  }

  const handleQuizParticipationTypeChange = (value: string) => {
    if (value === 'mandatory') {
      setValidation((validation: any) => ({
        ...validation,
        passRate: Yup.number().required('Pass Rate is Required'),
      }))
    } else {
      setValidation((validation: any) => ({
        ...validation,
        passRate: null,
      }))
      delete errors.passRate
      delete touched.passRate
    }
    setFieldValue('quizParticipationType', value)
  }

  const handleRandomizationChange = (randomization: boolean) => {
    if (randomization) {
      const maxNumber = data?.getQuizById?.questions?.length
      setFieldValue('randomizationNumber', maxNumber)
      setValidation((validation: any) => ({
        ...validation,
        randomizationNumber: Yup.number()
          .max(
            maxNumber,
            `${t('validations.number_of_questions')} ${maxNumber}`,
          )
          .required(
            `${t('quiz_details.number_of_questions')} ${t(
              'validations.required',
            )}`,
          ),
      }))
    } else {
      // set validation same object without randomziaitonNumber
      setValidation((validation: any) => ({
        ...validation,
        randomizationNumber: null,
      }))
      delete errors.randomizationNumber
      values.randomizationNumber = ''
      delete touched.randomizationNumber
    }
    setFieldValue('randomization', randomization)
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (data && data.getQuizById) {
      const dataClone: any = {}
      for (const [key] of Object.entries(formData)) {
        dataClone[key] =
          key === 'tags' && data.getQuizById[key]
            ? data.getQuizById[key].map((i: any) => ({
                label: i.label,
                value: i.id,
              }))
            : data.getQuizById[key]
      }
      handleRandomizationChange(dataClone.randomization)
      handleQuizParticipationTypeChange(dataClone.quizParticipationType)
      setValues(dataClone)
      setCurrentQuiz(dataClone)
    }
  }, [data])

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          {props.data.IQTest
            ? `${t('quizzes_layout.edit_iq_test')}`
            : `${t('quizzes_layout.edit_quiz')}`}
        </DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <Step1
              errors={errors}
              values={values}
              touched={touched}
              handleChange={handleChange}
              handleQuizParticipationTypeChange={
                handleQuizParticipationTypeChange
              }
              handleRandomizationChange={handleRandomizationChange}
              setFieldValue={setFieldValue}
              IQTest={props.data.IQTest}
              type={props.type}
              companyId={props.data.companyId}
              questionListSize={data && data?.getQuizById?.questions?.length}
              t={t}
            />
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />

              <Button
                text={t('actions.save')}
                type="small"
                // onClick={handleSubmit}
                background="#06C68F"
                btnType="submit"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditQuizDrawer
