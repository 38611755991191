// useAddTestLogic.tsx

import { useEffect } from 'react'
import { useFormik } from 'formik'
import { useQuery } from '@apollo/client'
import { GET_TAGS } from 'gql/skilss.query'
import { useSwal } from 'hooks/useSwal'
import { t } from 'i18next'
import { TestSchema } from 'helpers/validationSchemas'
import useAddTestService from 'hooks/tests/useAddTestService'
import { useUserValue } from 'context/UserContext'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import { ITag } from 'pages/tests/filterTestDrawer/filter.interface'

interface UseAddTestLogicProps {
  onSuccess: () => void
  onClose: () => void
}
interface Attachment {
  name: string
}

const useAddTestLogic = ({ onSuccess, onClose }: UseAddTestLogicProps) => {
  const {
    file,
    cropperOpened,
    setCropperOpened,
    handleCropSave,
    handleImageChange,
    imageLoading,
  } = useUploadImage()

  const formData = {
    name: '',
    attachment: null as Attachment | null,
    randomizationNumber: 0,
    tags: [] as any[],
    quizParticipationType: 'mandatory',
    description: '',
    passRate: 0,
    randomization: false,
    time: 0,
  }

  const [state] = useUserValue()
  const { addTest, data: newTest, addTestLoading } = useAddTestService()
  const { handleSubmit, values, errors, touched, setFieldValue } = useFormik({
    initialValues: formData,
    validationSchema: TestSchema,
    onSubmit(values) {
      const companyId = state.selectedCompany?.id || null
      const formData = { ...values }
      if (values.tags)
        formData.tags = values.tags.map((i: ITag) => ({
          label: i.label,
        }))
      addTest(formData, companyId)
      onSuccess()
    },
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const name = e.target.name
    let value = e.target.value

    if (name === 'time') value = parseFloat(value)
    else if (name === 'quizParticipationType')
      value = value === 'mandatory' ? value : 'optional'
    else if (name === 'passRate') value = parseInt(value)

    setFieldValue(name, value)
  }

  useEffect(() => {
    if (newTest) {
      onClose()
    }
  }, [newTest])

  const { fireSwal } = useSwal()

  const handleCloseDrawer = () => {
    if (tagList?.length === 0) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        confirmText: 'Yes, Cancel!',
        onConfirm: () => onClose(),
      })
    }
  }

  const { data } = useQuery(GET_TAGS, {
    variables: {
      companyId: state.selectedCompany?.id,
    },
  })

  const tagList =
    (data &&
      data.getAllTags.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  return {
    handleSubmit,
    values,
    cropperOpened,
    setCropperOpened,
    errors,
    file,
    touched,
    imageLoading,
    setFieldValue,
    handleImageChange,
    handleCloseDrawer,
    tagList,
    addTestLoading,
    handleCropSave,
    handleChange,
  }
}

export default useAddTestLogic
