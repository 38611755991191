import Drawer from 'components/common/Drawer'
import { CourseParams } from 'components/courseDetail/interfaces'
import React, { useState } from 'react'
import { Action, ModulesTabWrapper } from './styled-components'
import FilterDrawer from 'pages/modules/FilterDrawer/FilterDrawer'
import { filterOption } from 'pages/modules/staticData'
import { Toolbar } from 'components/common/Toolbar'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'

import {
  actionConfig,
  moduleActionsConfig,
} from '../AddCourseModuleDrawer/gridConfig'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useSwal } from 'hooks/useSwal'
import { DrawerEventEmitter } from 'helpers/drawer'
import { Button } from 'components/common/Button'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import { useUserValue } from 'context/UserContext'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-action.svg'
import { useDeleteCourseModule } from 'hooks/course/useEditCourse'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import FilterListIcon from '@mui/icons-material/FilterList'
import SyncIcon from '@mui/icons-material/Sync'
import TextInput from 'components/common/TextInput/TextInput'
import { useFetchModules } from 'hooks/modules/useModules'
import { CardsLayout } from 'components/common/Cards'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SplashScreen } from 'components/common'

interface Props {
  course: CourseParams
  refetchCourse: () => void
}

const toolBarStyles = {
  justifyContent: 'space-between',
  padding: 0,
  marginTop: 20,
}

const ModulesTab = ({ course, refetchCourse }: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const [filterData, setFilterData] = useState<any>(filterOption)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<boolean>(false)
  const [videoModal, setVideoModal] = useState({ modal: false, data: '' })
  const [searchValue, setSearchValue] = useState('')
  const [isDraggable, setIsDraggable] = useState(true)
  const [selectedItem, setSelectedItem] = useState<any>([])
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [perPage, setPerPage] = useState<number>(4)
  const navigate = useNavigate()
  const [selectAll, setSelectAll] = useState(false)

  const closeFilterDrawer = () => {
    setFilterDrawerOpened(false)
  }

  const { modules, refetch }: any = useFetchModules(
    {
      name: { type: 'match', value: '' },
      course: { type: 'nestedArrayIn', value: course.id },
    },
    1,
    0,
  )

  const openCreateModuleDrawer = (id: string, name: string, company: any) => {
    DrawerEventEmitter.emit('openDrawer', 'addModule', true, {
      data: {
        courseId: id,
        name,
        manualCompanyId: company ? company.id : null,
        onSuccess: () =>
          refetch({
            filter: {
              name: { type: 'match', value: '' },
              course: { type: 'nestedArrayIn', value: course.id },
            },
            currentPage: 1,
            perPage: 0,
          }),
      },
    })
  }

  const openAddCourseModuleDrawer = (
    id: string,
    name: string,
    company: any,
  ) => {
    DrawerEventEmitter.emit('openDrawer', 'addCourseModule', true, {
      data: {
        courseId: id,
        name,
        manualCompanyId: company ? company.id : null,
        refetchCourse: () => {
          refetchCourse()
          refetch({
            filter: {
              name: { type: 'match', value: '' },
              course: { type: 'nestedArrayIn', value: course.id },
            },
            currentPage: 1,
            perPage: 0,
          })
        },
      },
    })
  }

  const { deleteCourseModule } = useDeleteCourseModule({
    filter: {
      name: { type: 'match', value: '' },
      course: { type: 'nestedArrayIn', value: course.id },
    },
  })

  const confirmDelete = (ids: string[]) => {
    deleteCourseModule(course.id, ids, () => {
      refetchCourse()
      refetch({
        filter: {
          name: { type: 'match', value: '' },
          course: { type: 'nestedArrayIn', value: course.id },
        },
      })
    })
    setSelectedItem([])
  }

  const handleResetClick = () => {
    refetch({
      filter: {
        name: { type: 'match', value: '' },
        course: { type: 'nestedArrayIn', value: course.id },
      },
      currentPage: 1,
      perPage,
    })
    setFiltered(false)
    setSearchValue('')
    setFilterQuery({})
  }

  const handleViewClick = (id: string) => navigate(`/modules/${id}`)

  const handleEditClick = (id: string) =>
    DrawerEventEmitter.emit('openDrawer', 'editModuleDrawer', true, { id })

  const handleDeleteClick = (field: any) => {
    fireSwal({
      title: t('popups.remove_module_from_course_single'),
      confirmText: t('popups.confirm_remove'),
      onConfirm: () => confirmDelete([field.id]),
    })
  }

  const openPdf = (item: any) => {
    item.attachment && window.open(item.attachment, '_blank')
  }

  const actionHandler = (event: string, field: any, action: string) => {
    switch (event) {
      case 'view':
        handleViewClick(field)
        break
      case 'edit':
        handleEditClick(field)
        break
      case 'delete':
        handleDeleteClick(field)
        break
      case 'pdf':
        openPdf(field)
      default:
        return () => console.log('null')
    }
  }

  const actions = actionConfig(
    actionHandler,
    state.userPermission,
    !!(course?.originalId && state.currentUser.companyId),
    t,
  )

  const handleVideoModal = (item: any) => {
    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? item.introVideo[0].links[0].url : '',
    })
  }

  const selectItem = (id: string, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const handleDeleteAllClick = () => {
    fireSwal({
      title: t('popups.remove_module_from_course_many'),
      confirmText: t('popups.confirm_remove'),
      onConfirm: () => confirmDelete(selectedItem),
    })
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    const search = { type: 'match', value: searchValue }
    refetch({
      filter: {
        name: search,
        course: { type: 'nestedArrayIn', value: course.id },
      },
    })
    setIsDraggable(false)
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value)

    if (e.target.value === '') {
      setIsDraggable(true)
      refetch({
        filter: {
          name: { type: 'match', value: '' },
          course: { type: 'nestedArrayIn', value: course.id },
        },
      })
    }
  }

  const moduleActions = moduleActionsConfig(
    t,
    openCreateModuleDrawer,
    openAddCourseModuleDrawer,
  )

  const hasEditPermission = !course.isBought

  const createButton = (): React.JSX.Element => (
    <>
      {hasEditPermission && (
        <GridActionMenu
          actionConfig={moduleActions}
          row={course}
          icon={
            <Button
              text={t('general.module')}
              size="small"
              color="secondary"
              icon={<ArrowDropDownIcon />}
              iconPosition="right"
              background="#06C68F"
            />
          }
        />
      )}
    </>
  )

  if (!modules?.data?.length) {
    return (
      <>
        <SplashScreen
          title={t('modules_layout.no_modules')}
          subTitle={t('modules_layout.create_modules_information')}
          createButton={createButton}
        />
      </>
    )
  }

  return (
    <ModulesTabWrapper>
      <Toolbar styles={toolBarStyles}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {hasEditPermission && (
            <>
              <GridActionMenu
                actionConfig={moduleActions}
                row={course}
                icon={
                  <Button
                    text={t('general.module')}
                    size="small"
                    color="secondary"
                    icon={<ArrowDropDownIcon />}
                    iconPosition="right"
                    background="#06C68F"
                  />
                }
              />
              <GridMultipleActions
                selectedItems={selectedItem.length}
                actions={[
                  {
                    hide: state.userPermission.deleteModule
                      ? course?.isBought
                      : true,
                    id: 0,
                    color: 'secondary',
                    tooltipText: `${t('actions.delete')}`,
                    disabled: selectedItem.length < 1,
                    onClick: () => handleDeleteAllClick(),
                    component: (
                      <Action hoverColor="#EA382A">
                        <DeleteIcon />
                      </Action>
                    ),
                  },
                ].filter((i: any) => !i.hide)}
              />
            </>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MultipleAction>
            <IconButton
              disabled={filtered || searchValue ? false : true}
              onClick={handleResetClick}
            >
              <SyncIcon fontSize="small" />
            </IconButton>
            <MultipleActionTooltip>{t('actions.reset')}</MultipleActionTooltip>
          </MultipleAction>
          <MultipleAction>
            <IconButton onClick={() => setFilterDrawerOpened(true)}>
              <FilterListIcon fontSize="small" style={{ color: '#06C68F' }} />
            </IconButton>
            <MultipleActionTooltip>
              {t('actions.filters')}
            </MultipleActionTooltip>
          </MultipleAction>
          <form onSubmit={handleSearchSubmit} style={{ marginRight: 10 }}>
            <TextInput
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchValue}
              iconInStart
              onChange={handleSearchChange}
              icon={
                <IconButton type="submit">
                  <SearchRoundedIcon />
                </IconButton>
              }
            />
          </form>
        </div>
      </Toolbar>

      {modules && (
        <CardsLayout
          routerState={{
            from: `/courses/${course.id}`,
            state: { tab: 1 },
          }}
          gridStyle={'card'}
          data={modules.data}
          noRecordsText={t('general.no_modules_in_course')}
          actions={actions}
          handleVideoModal={handleVideoModal}
          variant="list"
          selectItem={selectItem}
          selectedItem={selectedItem}
          refetch={refetch}
          isDraggable={hasEditPermission && (!filtered || isDraggable)}
          checkboxShown={!course.isBought && state.userPermission.deleteModule}
          t={t}
        />
      )}

      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => {}}
        totalWidth="700px"
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          refetchData={refetch}
          onClose={closeFilterDrawer}
          setFiltered={setFiltered}
          courseId={course.id}
          t={t}
        />
      </Drawer>
    </ModulesTabWrapper>
  )
}

export default ModulesTab
