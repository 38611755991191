import { snackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
// import { useStateValue } from "context/StateContext"
import { useTranslation } from 'react-i18next'

import uploadFileService from 'services/group/uploadFileService'

const useUploadFileStream = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { singleUploadStream } = uploadFileService()
  // const [state, dispatch] = useStateValue()

  const uploadFileStream = async (file: File, cb: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors, data } = await singleUploadStream({
      variables: {
        file,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.extracted_users')
      variant = 'success'

      cb(data.singleUploadStream)
    }

    setSnackbar({ message, variant })
  }

  return {
    uploadFileStream,
  }
}

export default useUploadFileStream
