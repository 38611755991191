import { styled } from 'styled-components'

export const Wrapper = styled.div``

export const toolbarStyles = {
  justifyContent: 'space-between',
}

export const BooksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(204px, 1fr));
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const ToolbarContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 10px 0;
  justify-content: space-between;
`

export const FilterHeader = styled.header<{ width?: string }>`
  padding: 10px 20px;
  width: ${(props) => props.width || 'auto'};
`

export const FilterTitle = styled.h2`
  font-size: 20px;
  font-weight: 450;
`

export const BooksHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 20px 0;
`

export const BooksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CheckBoxFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const DrawerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 105px);
  overflow: hidden;
`

export const BooksContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 20px 20px;
`

export const FormFooter = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: white;
  padding: 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
`
