import { useMutation } from '@apollo/client'

import { EXTRACT_USERS_WITH_GROUPS } from 'gql/group/group.query'

const extractUsersWithGroupsService = () => {
  const [singleUploadStream] = useMutation(EXTRACT_USERS_WITH_GROUPS)

  return {
    singleUploadStream,
  }
}

export default extractUsersWithGroupsService
