import React from 'react'
import _ from 'lodash'
import {
  ModuleWrapper,
  HelpButtonContainer,
  FormLabel,
  StyledFormField,
} from './styled-components'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import HelpIcon from '@mui/icons-material/Help'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import {
  ModuleItemWrapper,
  ModuleItem,
} from 'pages/questions/styled-components'
import Loader from 'components/common/Loader'
import { Wrapper, Modules } from '../styled-components'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import Drawer from 'components/common/Drawer'
import QuestionItem from './QuestionItem'
import AddModuleToQuestionDrawer from './AddModuleToQuestionDrawer'
import UploadContent from './UploadContent'
import { QuestionTypes } from '../staticData'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import useCreateQuestionDrawer from 'hooks/questions/QuestionsLayout/useCreateQuestionDrawer'
interface Params {
  title: string
  currentModule: any
}

const CreateQuestionDrawer = ({
  title,
  currentModule,
}: Params): React.JSX.Element => {
  const {
    createQuestionLoading,
    linkModuleDrawerOpened,
    setLinkModuleDrawerOpened,
    selectedModules,
    setSelectedModules,
    handleCloseDrawer,
    t,
    handleTypeChange,
    values,
    setFieldValue,
    tagList,
    setUploadedFiles,
    uploadedFiles,
    errors,
    touched,
    handleChange,
    boolAnswer,
    choiceAnswers,
    multiAnswers,
    selectedChoiceAnswer,
    selectedMultiAnswers,
    handleSelect,
    handleBoolChange,
    handleAddAnswerClick,
    handleRemoveAnswerClick,
    handleAnswerChange,
    fieldsValid,
    selectedValid,
    submitBtnDisabled,
    cropperOpen,
    cropperModalToggle,
    cropperImage,
    handleCropSave,
    handleSubmit,
  } = useCreateQuestionDrawer({ title, currentModule })

  return (
    <>
      {createQuestionLoading && <Loader />}
      <Drawer
        opened={linkModuleDrawerOpened}
        toggleDrawer={(): void => setLinkModuleDrawerOpened(false)}
        totalWidth="650px"
      >
        <AddModuleToQuestionDrawer
          title="Link Modules to the Question"
          closeDrawer={(): void => setLinkModuleDrawerOpened(false)}
          selectedModules={selectedModules}
          setSelectedModules={setSelectedModules}
        />
      </Drawer>
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <Title>{t('form_fields.select_question_type')}</Title>
                <ReactSelect
                  onChange={handleTypeChange}
                  options={QuestionTypes}
                  value={values.type}
                />
              </FormField>
              <FormField>
                <Title>{t('form_fields.select_tags')} </Title>
                <CreatableSelect
                  isMulti
                  onChange={(e: any): void => {
                    setFieldValue('tags', e)
                  }}
                  options={tagList}
                  value={values.tags}
                  placeholder={t('form_fields.select_tags')}
                />
              </FormField>
              <FormField style={{ display: 'flex' }}>
                <UploadContent
                  setUploadedFiles={setUploadedFiles}
                  uploadedFiles={uploadedFiles}
                  currentModule={currentModule.moduleId}
                />

                <HelpButtonContainer>
                  <Tooltip
                    title={
                      <span style={{ fontSize: '14px' }}>
                        {t('actions.upload_content_helper')}
                      </span>
                    }
                    arrow
                    color="#06C68F"
                  >
                    <HelpIcon style={{ color: '#06c68f' }} />
                  </Tooltip>
                </HelpButtonContainer>
              </FormField>
              <FormField>
                <Title>{`${t('general.question')}`}</Title>
                <TextInput
                  error={errors.question ? true : false}
                  errorMessage={errors.question ? errors.question : ''}
                  touched={touched.question}
                  placeholder={`${t('form_fields.type_question_here')}`}
                  size="small"
                  name="question"
                  type="text"
                  value={values.question}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="2"
                />
              </FormField>
              <FormField>
                <QuestionItem
                  type={values.type.value}
                  boolAnswer={boolAnswer}
                  choiceAnswers={choiceAnswers}
                  multiAnswers={multiAnswers}
                  selectedChoiceAnswer={selectedChoiceAnswer}
                  selectedMultiAnswers={selectedMultiAnswers}
                  handleSelect={handleSelect}
                  onBoolChange={handleBoolChange}
                  handleAddAnswerClick={handleAddAnswerClick}
                  handleRemoveAnswerClick={handleRemoveAnswerClick}
                  handleAnswerChange={handleAnswerChange}
                  fieldsValid={fieldsValid}
                  selectedValid={selectedValid}
                />
              </FormField>
              <FormField>
                <Title>{t('question_details.reason')}</Title>
                <TextInput
                  placeholder={`${t('form_fields.type_reason_here')}`}
                  size="small"
                  name="reason"
                  type="text"
                  value={values.reason}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="3"
                />
              </FormField>
              <StyledFormField>
                <Title>{t('create_question.shuffle_answers')}:</Title>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                  onChange={(e): void => {
                    setFieldValue('isShuffleEnabled', e.target.value === 'true')
                  }}
                  value={values.isShuffleEnabled}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="secondary" />}
                    label={t('general.yes')}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="secondary" />}
                    label={t('general.no')}
                  />
                </RadioGroup>
              </StyledFormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            {currentModule.moduleId && (
              <ModuleWrapper>
                <Wrapper>
                  <article style={{ marginRight: 5 }}>
                    {' '}
                    {t('question_details.linked_to_modules')}:
                  </article>
                  {currentModule.moduleId && (
                    <Modules>
                      <ModuleItemWrapper>
                        <ModuleItem>{currentModule.name}</ModuleItem>
                      </ModuleItemWrapper>
                    </Modules>
                  )}
                </Wrapper>
              </ModuleWrapper>
            )}
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                background="#06C68F"
                onClick={handleSubmit}
                isDisabled={submitBtnDisabled}
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
      <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
        <ImageCropper
          file={cropperImage}
          hideCropper={cropperModalToggle}
          getCroppedFile={handleCropSave}
        />
      </Modal>
    </>
  )
}

export default CreateQuestionDrawer
