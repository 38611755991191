import React, { useState, useEffect } from 'react'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'
import { useQuery } from '@apollo/client'
import { GET_GROUP } from 'gql/group/group.query'
import { Button } from 'components/common/Button'
import {
  ContentContainer,
  ItemFieldContainer,
  TitleContainer,
  Title,
  TextContainer,
  ExpandableContainer,
  ExpandableHeader,
  ExpandableContent,
} from './styled-components'
import {
  Container,
  Wrapper,
  FooterWrapper,
  ButtonWrapper,
} from '../styled-components'
import { generateFullName } from 'utils/utils'
import ReactSelect from 'react-select'
import { useNavigate } from 'react-router-dom'
import useEditGroup from 'hooks/group/useEditGroup'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import useCompaniesService from 'hooks/useCompaniesService'

const Expandable = (props: any) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <ExpandableContainer>
      <ExpandableHeader onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        <div style={{ marginLeft: '7px' }}>{props.title}</div>
      </ExpandableHeader>

      {isOpen && <ExpandableContent>{props.children}</ExpandableContent>}
    </ExpandableContainer>
  )
}

const GroupItem = (props: any) => {
  const [firstFetch, setFirstFetch] = useState(true)
  const { item, coaches, courseId } = props

  const [coach, setCoach] = useState<any>(null)
  const course = item.courses.find((i: any) => i.courseId === courseId)
  useEffect(() => {
    if (course.coach) {
      setCoach({
        label: course.coach.name,
        value: course.coach.coachId,
        avatar: course.coach.avatar,
      })
    }
  }, [])

  useEffect(() => {
    if (!firstFetch) {
      props.assignCoach(item.id, coach?.value || null, courseId, () => {})
    }
  }, [coach])

  return (
    <ItemFieldContainer>
      <div
        key={item.id}
        style={{
          border: '1px solid #ccc',
          padding: '8px 7px 0px',
          boxSizing: 'border-box',
          width: '260px',
        }}
      >
        {item.name}
      </div>

      <div style={{ width: '260px' }}>
        <ReactSelect
          placeholder="Tutor"
          name="coach"
          options={coaches.map((c: any) => ({
            label: generateFullName(c),
            value: c.userId,
            avatar: c.avatar,
          }))}
          value={coach}
          onChange={(e: any) => {
            setCoach(e)
            setFirstFetch(false)
          }}
          isClearable
        />
      </div>
    </ItemFieldContainer>
  )
}

const AssignCoach = (props: any) => {
  const { updateCompany, setCompanyState } = useCompaniesService()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const navigate = useNavigate()

  const { assignCoach } = useEditGroup()
  const { companyData } = props

  const query: any = {
    companyId: {
      type: 'EXACT',
      value: companyData.companyData.id,
    },
  }

  const { data } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        company: {
          type: 'exact',
          value: companyData.companyData.id,
        },
      },
      perPage: 0,
    },
  })

  const { courses, loading, refetch, error, fetchMore } = useFetchCourses(
    query,
    currentPage,
    perPage,
  )

  const filterData = (arr: any, field: string) => {
    const data = arr.filter((i: any) => {
      return i.courses.find((n: any) => n.courseId === field)
    })
    return data
  }

  const courseList = (courses && courses.data) || []
  const groupList = (data && data.getAllGroups.data) || []
  const { t } = props

  return (
    <>
      <Container>
        <Wrapper width="74%">
          <ContentContainer>
            {courseList.map((course: any) => (
              <Expandable title={`Course -- ${course.name}`} key={course.id}>
                <>
                  <br />
                  <TitleContainer>
                    <Title>{t('general.groups')}</Title>
                    <Title>{t('general.coaches')}</Title>
                  </TitleContainer>
                  <br />
                  {filterData(groupList, course.id).length > 0 ? (
                    filterData(groupList, course.id).map((i: any) => (
                      <>
                        <GroupItem
                          item={i}
                          coaches={course.coaches}
                          courseId={course.id}
                          assignCoach={assignCoach}
                          t={t}
                        />
                        <br />
                      </>
                    ))
                  ) : (
                    <div>{t('general.no_data')}</div>
                  )}
                </>
              </Expandable>
            ))}
          </ContentContainer>
        </Wrapper>

        <Wrapper width="calc(26% - 20px)">
          <TextContainer>
            {t('company_wizard_layout.assign_coaches_description')}
          </TextContainer>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.cancel')}
            type="small"
            onClick={props.onCancel}
            background="#E0E1E2"
            textColor="#414141"
          />
        </ButtonWrapper>

        <ButtonWrapper>
          <div style={{ marginRight: '15px' }}>
            <Button
              text={t('actions.previous')}
              type="small"
              onClick={() => props.changeStep(5)}
              background="#E0E1E2"
              textColor="#414141"
            />
          </div>

          <div style={{ marginRight: '15px', height: '31px' }}>
            <Button
              text={t('actions.save_as_draft')}
              type="small"
              color="secondary"
              onClick={() => {
                setCompanyState(companyData.companyData.id, 6, 'Success', () =>
                  navigate('/Companies'),
                )
              }}
              textColor="#06C68F"
              background="#f5f5f5"
              outline="1px solid #06C68F"
            />
          </div>

          <Button
            text={t('actions.finish')}
            type="small"
            color="secondary"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 7, 'Success', () =>
                navigate('/Companies'),
              )
            }}
            background="#06C68F"
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default AssignCoach
