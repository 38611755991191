import React, { FormEvent } from 'react'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import { generateFullName } from 'utils/utils'
import Loader from 'components/common/Loader'
import IconButton from '@mui/material/IconButton'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { SelectField, AsyncSelect } from 'components/common/SelectField'
import { statuses } from 'pages/group/staticData'
import DateRangePicker from 'components/common/DateRangePicker'
import CloseIcon from '@mui/icons-material/Close'

import { useGroupsFilterDrawer } from 'hooks/group/useGroupFilterDrawer/useGroupFilterDrawer'
import { IFilterDrawerProps } from './filterDrawer.interface'

const FilterDrawer = ({
  companyId,
  isTestGroupsFilter,
  closeDrawer,
  filterLoading,
  setFilterOptions,
  defaultFilter,
  useCompanyId,
  hasPurchasedCourses,
  setCurrentPage,
  setIsFiltered,
  refetchGroup,
}: IFilterDrawerProps): JSX.Element => {
  const {
    t,
    touched,
    onCancelClick,
    handleChange,
    handleSelectChange,
    handleSubmit,
    error,
    setCreateDate,
    createDate,
    setFieldValue,
    values,
    courseData,
    courseLoading,
    adminData,
    adminLoading,
    errors,
  } = useGroupsFilterDrawer({
    companyId,
    filterLoading,
    setFilterOptions,
    defaultFilter,
    useCompanyId,
    hasPurchasedCourses,
    setCurrentPage,
    setIsFiltered,
    refetchGroup,
  })

  return (
    <>
      {filterLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{t('actions.filters')}</DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer
          onSubmit={(e: FormEvent): void => {
            e.preventDefault()
          }}
        >
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  title={t('groups_layout.group_name')}
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  placeholder={t('groups_layout.group_name')}
                  size="small"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('general.status')}</Title>
                <SelectField
                  name="status"
                  options={statuses}
                  value={values.status}
                  onChange={(e: FormEvent): void => setFieldValue('status', e)}
                />
              </FormField>

              <FormField width="48%">
                <Title>{t('tasks_layout.select_admin')}</Title>
                <AsyncSelect
                  isMulti
                  onInputChange={(e: string): void =>
                    handleSelectChange(e, 'admin')
                  }
                  onChange={(e: FormEvent): void => setFieldValue('admin', e)}
                  data={(adminData && adminData.usersByRole.data) || []}
                  value={values.admin}
                  label={t('form_fields.type_to_search_admin')}
                  loading={adminLoading}
                  labelDataName={generateFullName}
                  valueDataKey="id"
                  isClearable
                />
              </FormField>
              {!isTestGroupsFilter && (
                <FormField width="48%" style={{ position: 'relative' }}>
                  <Title>{t('groups_layout.select_course')}</Title>

                  <AsyncSelect
                    isMulti
                    onInputChange={(e: string): void =>
                      handleSelectChange(e, 'course')
                    }
                    onChange={(e: FormEvent): void =>
                      setFieldValue('course', e)
                    }
                    data={courseData?.getAllCourse?.data || []}
                    value={values.course}
                    label={t('form_fields.type_to_search_courses')}
                    loading={courseLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                  />
                </FormField>
              )}

              <FormField width="48%">
                <TextInput
                  title={t('form_fields.location')}
                  placeholder={t('form_fields.location')}
                  size="small"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={t('user_details.note')}
                  placeholder={t('user_details.note')}
                  size="small"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('general.create_date')}</Title>
                <DateRangePicker
                  values={createDate}
                  setCreateDate={setCreateDate}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text="Filter"
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
                onClick={handleSubmit}
                isDisabled={!!error}
              />
              <Button
                text="Cancel"
                type="small"
                onClick={onCancelClick}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
