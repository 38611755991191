import { useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import {
  GET_SKILL_TEST_VIDEOS_TEMPLATES,
  UPDATE_SKILL_TEST_VIDEO_TEMPLATE,
} from 'gql/skillTestVideos.query'

const updateSkillTestVideoService = (params: any) => {
  const [updateSkillTestVideoMutation, { loading, error }] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation(UPDATE_SKILL_TEST_VIDEO_TEMPLATE, {
      update(cache, { data: { updateSkillTestVideoTemplate: document } }) {
        updateCache({
          objectName: 'getSkillTestVideosTemplates',
          query: GET_SKILL_TEST_VIDEOS_TEMPLATES,
          cache,
          document,
          action: 'update',
          params,
        })
      },
    })

  return {
    updateSkillTestVideoMutation,
    loading,
    error,
  }
}

export default updateSkillTestVideoService
