import React from 'react'
import strCutter from 'utils/strCutter'
import Checkbox from 'components/common/Checkbox'
import moment from 'moment'

import {
  Action,
  GroupsWrapper,
  GroupItem,
  GroupTitle,
  ClickableDiv,
} from './styled-components'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-action.svg'
import { ReactComponent as EditIcon } from 'assets/editRounded.svg'
import { ReactComponent as ModifyStudentsSvg } from 'assets/add-student.svg'
import GetAppIcon from '@mui/icons-material/GetApp'
import { StudentAction } from './GroupStudents/styled-components'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import IconButton from '@mui/material/IconButton'
import SchoolIcon from '@mui/icons-material/School'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { itemProps, ICourseItem, IConfigItem } from './groups.interface'
import { TFunction } from 'interfaces/TFunction'
import { ReactComponent as Library } from 'components/common/Button/icons/list-boxes.svg'

export const columnConfig = (
  handelClick: (e: any, field?: string) => void,
  selectItem: (id: string) => void,
  t: TFunction<'translation', undefined>,
  checkbox?: boolean,
) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps): React.JSX.Element => (
        <div style={{ visibility: !checkbox ? 'hidden' : 'visible' }}>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
        </div>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: `${t('general.name')}`,
      render: (item: itemProps): React.JSX.Element =>
        item.name?.length > 30 ? (
          <Tooltip title={item.name}>
            <ClickableDiv onClick={() => handelClick(item)}>
              {strCutter(item.name, 30)}
            </ClickableDiv>
          </Tooltip>
        ) : (
          <ClickableDiv onClick={() => handelClick(item)}>
            {item.name}
          </ClickableDiv>
        ),
    },
    {
      id: 'admin',
      numeric: true,
      disablePadding: false,
      label: `${t('general.admin')}`,
      render: (item: itemProps): React.JSX.Element => {
        return (
          <div>
            {item.admin
              ? item.admin
                  .map((i: { firstName: string }) => i.firstName)
                  .join(', ')
              : 'N/A'}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: itemProps): React.JSX.Element => (
        <StatusIndicator status={item.status} />
      ),
    },
    {
      id: 'numberOfStudents',
      numeric: true,
      disablePadding: false,
      label: `${t('general.students')}`,
      render: (item: itemProps): React.JSX.Element => (
        <div>{item.numberOfStudents || 0}</div>
      ),
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}`,
      render: (item: itemProps): React.JSX.Element => {
        const courses = item.courses || []
        const firstTwoCourses = courses.slice(0, 2)
        const exCourses = courses.slice(2, courses.length)

        return (
          <div>
            {firstTwoCourses.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {firstTwoCourses.map(
                (course: ICourseItem, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton>
                        <SchoolIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {course.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourses.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourses.map((exCourse: ICourseItem) => (
                        <div key={exCourse.courseId}>{exCourse.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps): React.JSX.Element => (
        <div>
          {item.company && item.company.name ? (
            strCutter(item.company.name, 25)
          ) : (
            <>N/A</>
          )}
        </div>
      ),
    },

    {
      id: 'createDate',
      numeric: true,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: itemProps): React.JSX.Element => (
        <div>{moment(item.createDate).format('DD-MM-YYYY')}</div>
      ),
    },
  ]

  return grid.filter((i: any) => !i.hide)
}

export const actionConfig = (
  handleClick: (e: any, field?: string) => void,
  permission: { [key: string]: boolean },
  t: TFunction<'translation', undefined>,
) => {
  const action = [
    {
      render: (item: IConfigItem): React.JSX.Element => (
        <Action
          onClick={() => handleClick(item, 'info')}
          className="edit-action-icon"
        >
          <Tooltip title={t('actions.edit')} arrow>
            <EditIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      hide: !permission.deleteGroup,
      render: (item: IConfigItem): React.JSX.Element => (
        <Action
          onClick={() => handleClick(item, 'addStudentInGroup')}
          className="add-student-icon"
        >
          <Tooltip title={t('groups_layout.modify_students')} arrow>
            <ModifyStudentsSvg />
          </Tooltip>
        </Action>
      ),
    },
    {
      hide: !permission.deleteGroup,
      render: (item: IConfigItem): React.JSX.Element => (
        <Action
          onClick={() => handleClick(item.id, 'delete')}
          className="delete-action-icon"
        >
          <Tooltip title={t('actions.delete')} arrow>
            <DeleteIcon />
          </Tooltip>
        </Action>
      ),
    },
  ]
  return action.filter((i: any) => !i.hide)
}

export const studentActionConfig = (
  handleClick: (action: string) => void,
  t: TFunction<'translation', undefined>,
) => {
  const action = [
    {
      render: (): React.JSX.Element => (
        <StudentAction onClick={() => handleClick('importGroups')}>
          <GetAppIcon />
          <span>{t('general.groups_to_courses')}</span>
        </StudentAction>
      ),
    },
    {
      render: (): React.JSX.Element => (
        <StudentAction onClick={() => handleClick('importGroupsToTests')}>
          <Library />
          <span>{t('general.groups_to_tests')}</span>
        </StudentAction>
      ),
    },
    {
      render: (): React.JSX.Element => (
        <StudentAction onClick={() => handleClick('importStudentsInGroups')}>
          <PlaylistAddIcon />
          <span>{t('actions.import_students_in_groups')}</span>
        </StudentAction>
      ),
    },
  ]

  return action
}
