import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'
import { useFormik } from 'formik'
import { Container, ButtonItem, ButtonContainer } from './styled-components'
import { FormFields, FormField, Label } from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { generateFullName } from 'utils/utils'
import { SelectField } from 'components/common/SelectField'
import useEditGroup from 'hooks/group/useEditGroup'
import { useTranslation } from 'react-i18next'
import { GroupSchema } from 'helpers/validationSchemas'

interface FormData {
  name: string
  location: string
  admin: any
  courses: any
  note: string
}

const formData: FormData = {
  name: '',
  location: '',
  admin: null,
  courses: null,
  note: '',
}

const EditGroup = ({ item, onCancel, companyId, adminList }: any) => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)

  const query: any = {
    companyId: {
      type: 'exact',
      value: companyId,
    },
  }

  const { courses, loading, refetch, error, fetchMore } = useFetchCourses(
    query,
    currentPage,
    perPage,
  )
  const { editGroup, loading: editGroupLoading } = useEditGroup()

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: formData,
    validationSchema: GroupSchema,
    onSubmit() {
      const data = { ...values }

      if (data.admin) {
        data.admin = data.admin.value
      }

      data.courses = data.courses
        ? data.courses.map((i: any) => ({ name: i.label, courseId: i.value }))
        : []

      editGroup(data, item.id, onCancel)
    },
  })

  useEffect(() => {
    const { admin, courses, ...args } = formData
    const data: any = {}
    for (const key in args) {
      data[key] = item[key]
    }

    data.admin = item.admin ? { label: generateFullName(item.admin) } : null

    data.courses =
      item.courses &&
      item.courses.map((i: any) => ({ label: i.name, value: i.courseId }))

    setValues({ ...data })
  }, [])

  const courseList = (courses && courses.data) || []

  const admins = adminList.map((i: any) => ({
    label: generateFullName(i),
    value: i.id,
  }))

  const courseData = courseList.map((i: any) => ({
    label: i.name,
    value: i.originalId ? i.originalId : i.id,
  }))

  return (
    <Container>
      <FormFields>
        <FormField>
          <TextInput
            error={!!errors.name}
            errorMessage={errors.name}
            touched={touched.name}
            label={`${t('form_fields.name')}*`}
            name="name"
            size="small"
            type="text"
            value={values.name}
            onChange={handleChange}
          />
        </FormField>

        <FormField>
          <TextInput
            label={t('form_fields.location')}
            name="location"
            size="small"
            type="text"
            value={values.location}
            onChange={handleChange}
          />
        </FormField>

        <FormField style={{ position: 'relative' }}>
          {values.admin && <Label>Admin*</Label>}
          <SelectField
            error={!!errors.admin}
            errorMessage={errors.admin}
            touched={touched.admin}
            placeholder={`${t('general.admin')}*`}
            name="admin"
            options={admins}
            value={values.admin}
            onChange={(e: any) => setFieldValue('admin', e)}
            isClearable
          />
        </FormField>

        <FormField style={{ position: 'relative' }}>
          {values.courses && values.courses.length > 0 && (
            <Label>{t('general.courses')}*</Label>
          )}
          <SelectField
            error={!!errors.courses}
            errorMessage={errors.courses}
            touched={touched.courses}
            placeholder={`${t('general.courses')}*`}
            name="courses"
            options={courseData}
            value={values.courses}
            onChange={(e: any) => setFieldValue('courses', e)}
            isClearable
            isMulti
          />
        </FormField>

        <FormField>
          <TextInput
            label={t('form_fields.note')}
            size="small"
            name="note"
            type="text"
            multiline
            rows="3"
            value={values.note}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <ButtonContainer>
            <ButtonItem style={{ marginRight: '10px' }}>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={onCancel}
                background="#E0E1E2"
                textColor="#414141"
              />
            </ButtonItem>

            <ButtonItem>
              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                onClick={handleSubmit}
                isDisabled={editGroupLoading}
                background="#06C68F"
              />
            </ButtonItem>
          </ButtonContainer>
        </FormField>
      </FormFields>
    </Container>
  )
}

export default EditGroup
