import { useState, useEffect, ChangeEvent } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { useFetchCompany } from 'hooks/companies/useCompanies'
import useCompaniesService from 'hooks/useCompaniesService'
import {
  SET_CURRENT_COMPANY,
  SET_FORMDATA,
  TOGGLE_CLOSE_DRAWER,
} from 'store/types'
import { CompanyPolicyEnums } from 'pages/main/main.interface'
import { DrawerEventEmitter } from 'helpers/drawer'
import {
  ICompaniesEditProps,
  IUseCompaniesEditReturnType,
} from 'pages/companies/CompaniesEdit/companiesEdit.interface'
import { CompaniesWizardPage1Schema } from 'helpers/validationSchemas'
import { useQuery } from '@apollo/client'
import { GET_ALL_INDUSTRY } from 'gql/skilss.query'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import { IFileState } from 'components/modules/AddModule.interface'
import { IFormData } from 'pages/companies/CompaniesAdd/addCompanyDrawer.interface'
import { formData } from './useAddCompany'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'

export const useCompaniesEdit = ({
  companyId,
  refetchCompany,
}: ICompaniesEditProps): IUseCompaniesEditReturnType => {
  const { t } = useTranslation()
  const [dataState, dispatchData] = useData()
  const [state, dispatch] = useUserValue()
  const [currentCompany, setCurrentCompany] = useState<IFormData | null>(null)
  const { updateCompany, error, company, loading, removeCompanyAvatar } =
    useCompaniesService()
  const [fetched, setFetched] = useState(false)
  const [cropperOpen, setCropperOpened] = useState(false)
  const [file, setFile] = useState<IFileState>({ name: '', file: null })
  const [imageType, setImageType] = useState('')
  const [imageChanged, setImageChanged] = useState(false)
  const { uploadImage, imageLoading } = useUploadImage()

  const [generalError, setGeneralError] = useState('')
  const { company: companyData } = useFetchCompany(companyId)

  const { data } = useQuery(GET_ALL_INDUSTRY)

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: CompaniesWizardPage1Schema,
    onSubmit(values) {
      const data = { ...values }

      delete data.phoneFieldValue

      if (data.companyActivity) {
        data.companyActivity = [
          {
            label: data.companyActivity.label,
          },
        ]
      } else {
        data.companyActivity = []
      }

      if (values.avatarAttachment) {
        const avatarAttachment = values.avatarAttachment
        data.avatar = avatarAttachment.link
        data.avatarAttachment = removeTypeNameFromObject(avatarAttachment)
      } else {
        data.avatar = null
        data.avatarAttachment = null
      }

      data.showLeaderboardBy = data.showLeaderboardByGroup
        ? CompanyPolicyEnums.GROUP
        : CompanyPolicyEnums.COMPANY
      delete data.showLeaderboardByGroup

      data.showNewsFeedBy = data.showNewsFeedByGroup
        ? CompanyPolicyEnums.GROUP
        : CompanyPolicyEnums.COMPANY
      delete data.showNewsFeedByGroup

      if (data.phoneFields && data.phoneFields.value) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null // it's optional
      }

      if (typeof data.country === 'object' && data.country !== null) {
        data.country = data.country.value
      }

      delete data.managers

      updateUserContext()
      if (companyId && refetchCompany && refetchCompany.refetchCompany) {
        updateCompany(companyId, data, refetchCompany.refetchCompany)
      }
    },
  })

  const getCompany = async (): Promise<void> => {
    const companyValues = {
      name: companyData.name,
      companyEmail: companyData.companyEmail,
      address: companyData.address,
      phone: companyData.phone,
      avatar: companyData.avatar,
      avatarAttachment: companyData.avatarAttachment,
      companyActivity:
        companyData.companyActivity && companyData.companyActivity.length > 0
          ? {
              value: companyData.companyActivity[0].label,
              label: companyData.companyActivity[0].label,
            }
          : null,
      note: companyData.note,
      country: companyData.country
        ? {
            value: companyData.country,
            label: companyData.country,
          }
        : null,
      platformService: companyData.platformService,
      exploreCourses: companyData.exploreCourses,
      showLeaderboardByGroup:
        companyData.showLeaderboardBy === CompanyPolicyEnums.GROUP,
      showNewsFeedByGroup:
        companyData.showNewsFeedBy === CompanyPolicyEnums.GROUP,
      city: companyData.city,
      keyContactName: companyData.keyContactName,
      postalCode: companyData.postalCode,
      numberOfUsers: companyData.numberOfUsers,
      phoneFields:
        companyData.phoneFields && companyData.phoneFields.code
          ? {
              label: companyData.phoneFields.code,
              value: companyData.phoneFields.dialCode,
            }
          : null,
      phoneFieldValue: companyData.phoneFields
        ? companyData.phoneFields.code
        : null,
    }
    setValues(companyValues)
    setCurrentCompany(companyValues)
    setFetched(true)
  }

  const updateUserContext = (): void => {
    dispatch({
      type: SET_CURRENT_COMPANY,
      payload: {
        ...state.currentCompany,
        exploreCourses: values.exploreCourses,
        showLeaderboardBy: values.showLeaderboardByGroup
          ? CompanyPolicyEnums.GROUP
          : CompanyPolicyEnums.COMPANY,
      },
    })
  }

  const handleImageChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: string,
  ): void => {
    const { files, name } = e.target

    if (!files || !files.length) return
    const reader: FileReader = new FileReader()

    reader.onload = (): void => {
      const img: HTMLImageElement = new Image()
      img.src = reader.result as string
      setFile({ name, file: reader.result })
    }
    reader.readAsDataURL(files[0])
    if (field !== 'certificateImage') {
      setCropperOpened(true)
    }
    setImageType(field)
  }

  const handleCloseDrawer = (): void => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'companiesEdit',
        values,
        compareTo: currentCompany,
      },
    })
  }
  const activityList =
    (data &&
      data.getAllIndustry.map((i: { label: string; id: string }) => ({
        label: i.label,
        value: i.id,
      }))) ||
    []

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement> | string,
  ): void => {
    const { confirm } = state.closeDrawer
    if (!confirm) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    }
    handleChange(e)
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (company) {
      closeDrawer()
    }
  }, [company])

  useEffect(() => {
    if (error) setGeneralError(error)
  }, [error])

  useEffect(() => {
    if (companyData) getCompany()
  }, [companyData])
  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const closeDrawer = async (): Promise<void> => {
    if (imageChanged && values.avatarAttachment?.link) {
      await removeCompanyAvatar(values.avatarAttachment.link)
    }
    DrawerEventEmitter.emit('openDrawer', 'companiesEdit', false)
  }

  const handleCropSave = (field: string, croppedFile: File): void => {
    setImageChanged(true)
    uploadImage(croppedFile, `avatar`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: 'png',
      }),
    )
  }

  const cropperModalToggle = (): void => {
    setCropperOpened(!cropperOpen)
  }

  const deleteImage = (field: string): void => {
    setFieldValue(field, '')
  }

  return {
    t,
    fetched,
    file,
    imageType,
    cropperOpen,
    handleCropSave,
    cropperModalToggle,
    generalError,
    deleteImage,
    imageLoading,
    values,
    errors,
    touched,
    handleValueChange,
    setFieldValue,
    handleCloseDrawer,
    handleSubmit,
    activityList,
    data,
    handleImageChange,
    loading,
  }
}
