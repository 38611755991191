import styled from 'styled-components'
import { IClasses } from './courseQuizzes.interface'
import { Container as TableContainer } from '@mui/material'

export const Container = styled.div`
  position: relative;
`

export const SearchInputWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
`

export const GridWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const GridItemWrapper = styled.div`
  padding: 10px;
  text-transform: capitalize;
`

export const loaderStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

export const defaultStyles = {
  position: 'unset',
  top: 'unset',
  left: 'unset',
  transform: 'unset',
}

export const StyledStudentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const StyledDetailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 32px;
  margin-bottom: 10px;
  gap: 8px;
`

export const CustomAvatarStyles = {
  width: 32,
  height: 32,
  borderRadius: 4,
  backgroundColor: '#f3f3f3',
}
export const EmailSpanStyles = {
  width: 32,
  height: 32,
  color: '#68707A',
  fontSize: 12,
}

export const StyledSpan = styled.span<{ passed?: boolean }>`
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  ${({ passed }) =>
    passed
      ? `
  color: #00905E;
  background-color: #E8F5E9;
  `
      : `
  color: #EA382A;
  background-color: #FDEBEA;
  `}
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 5px;
`

export const StyledScoreSpan = styled(StyledSpan)`
  width: 52px;
  height: 32px;
`

export const StyledContainer = styled(TableContainer)<{
  classes: IClasses
}>(({ classes }) => ({
  paddingTop: '24px',
  paddingRight: '0px !important',
  paddingLeft: '0px !important',
  [`& .${classes.root}`]: {
    width: '100%',
  },
  [`& .${classes.paper}`]: {
    width: '100%',
    marginBottom: '0px',
    padding: '0px 15px',
  },
  [`& .${classes.table}`]: {
    minWidth: 750,
    position: 'relative',
  },
  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  width: '100% !important',
  maxWidth: '100% !important',
}))
