import React from 'react'
import strCutter from 'utils/strCutter'
import { Tooltip } from '@mui/material'
import { TagContainer } from '../styled-components'
import { TagProps } from '../tags.interface'

const Tag = ({ tag, tooltip, type, redirectInDetails }: TagProps) => (
  <TagContainer
    onClick={() => redirectInDetails && redirectInDetails(Number(tag.id) || 0)}
    key={tag.id}
  >
    {(tag.label?.length || 0) > 15 ? (
      <Tooltip title={tag.label}>
        {type === 'courses'
          ? strCutter(tag.label, 20)
          : strCutter(tag.label, 7)}
      </Tooltip>
    ) : tooltip ? (
      <Tooltip title={tooltip}>
        <span>{tag.label}</span>
      </Tooltip>
    ) : (
      <>{tag.label}</>
    )}
  </TagContainer>
)

export default Tag
