import { useData } from 'context/DataContext'
import {
  SET_CURRENT_TOPIC,
  REMOVE_CURRENT_TOPIC,
  TOGGLE_DRAWER_CONFIRM,
} from 'store/types'

export const useAction = () => {
  const [state, dispatch] = useData()
  const toggleDrawerConfirm = (confirm: boolean, text: string) => {
    dispatch({
      type: TOGGLE_DRAWER_CONFIRM,
      payload: {
        confirm,
        text,
      },
    })
  }

  const setCurrentTopic = (topic: any) => {
    dispatch({
      type: SET_CURRENT_TOPIC,
      payload: topic,
    })
  }

  const removeCurrentTopic = () => {
    dispatch({
      type: REMOVE_CURRENT_TOPIC,
      payload: null,
    })
  }

  return {
    setCurrentTopic,
    removeCurrentTopic,
    toggleDrawerConfirm,
  }
}
