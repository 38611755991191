import { useLazyQuery, useQuery } from '@apollo/client'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Title,
} from 'components/DrawerLayout/styled-components'
import { genderOptions } from 'components/UsersLayout/staticData'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { GET_USER } from 'gql/users.query'
import { DrawerEventEmitter } from 'helpers/drawer'
import { UserSchema } from 'helpers/validationSchemas'
import useEditUserService from 'hooks/users/useEditUserService'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SET_FORMDATA } from 'store/types'
import { GET_COMPANIES } from 'gql/companies.query'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import CloseIcon from '@mui/icons-material/Close'
import { roles } from 'utils/permission'

const UserEdit = (props: any) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [dataState, dispatchData] = useData()
  const [currentModerator, setCurrentModerator] = useState<any>('')
  const [generalError, setGeneralError] = useState('')
  const { data } = useQuery(GET_USER, {
    variables: { id: props.id },
  })

  const [addingCompanyUser, setAddingCompanyUser] = useState(false)
  const [companyOptions, setCompanyOptions] = useState<any[]>([])

  const [getCompanyOptions, { data: companyData, loading: companyLoading }] =
    useLazyQuery(GET_COMPANIES)

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])
  const { updateUser, user, error, editUserLoading } = useEditUserService({
    role: props.role,
    companyId: state.selectedCompany?.id,
  })

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      consultantOfCompanies: [],
      jobTitle: '',
      location: '',
      gender: null,
      phoneFields: null as any,
      phoneFieldValue: null,
      birthDate: null as any,
      biography: '',
      note: '',
      avatar: '',
    },
    validationSchema: UserSchema,
    onSubmit(values) {
      const formValues: any = { ...values }
      delete formValues.phoneFieldValue
      formValues.gender = formValues.gender ? formValues.gender.value : null
      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }

      if (addingCompanyUser) {
        if (
          !values.consultantOfCompanies ||
          values.consultantOfCompanies.length === 0
        ) {
          setFieldError(
            'consultantOfCompanies',
            'Please select at least one company',
          )
          return
        }

        formValues.consultantOfCompanies = values.consultantOfCompanies.map(
          (item: { value: string }) => item.value,
        )
        formValues.role = roles.consultant
      } else formValues.role = roles.super_admin

      // formValues.group = selectedGroup
      updateUser(props.id, formValues)
    },
  })

  const closeDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'userAdd', false)
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editModerator',
        values,
        compareTo: currentModerator,
      },
    })
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    setGeneralError('')
  }, [values.email])

  useEffect(() => {
    if (user) {
      closeDrawer()
    }
  }, [user])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  useEffect(() => {
    if (data) {
      const {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender,
        phoneFields,
        birthDate,
        biography,
        note,
        companyId,
        avatar,
        consultantOfCompanies,
      } = data.user
      const userGender = genderOptions.find(
        (option: { value: string; label: string }) => option.value === gender,
      )
      const formData: any = {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender: gender
          ? {
              label: userGender.label,
              value: gender,
            }
          : null,
        phoneFields: phoneFields.dialCode
          ? { label: phoneFields.code, value: phoneFields.dialCode }
          : null,
        phoneFieldValue: phoneFields?.dialcode || null,
        biography,
        note,
        birthDate: birthDate ? moment(parseInt(birthDate)).format() : null,
        avatar,
      }
      setValues({
        ...formData,
      })

      if (consultantOfCompanies?.length) {
        handleAddCompanyUserChange(
          consultantOfCompanies.map((company: any) => ({
            label: company.name,
            value: company.id,
          })),
        )
      }
      setCurrentModerator(formData)
    }
  }, [data])

  const handleAddCompanyUserChange = (e: any) => {
    if (addingCompanyUser) {
      setFieldValue('consultantOfCompanies', null)
      setAddingCompanyUser(false)
    } else {
      setAddingCompanyUser((prev) => !prev)
      setFieldValue('consultantOfCompanies', e)
      if (!companyData) {
        getCompanyOptions({
          variables: {
            currentPage: 0,
            perPage: 0,
          },
        })
      }
    }
  }

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const [currentDate, setCurrentDate] = useState<Date | null>(null)

  const onDateChange = (date: Date | null) => {
    // setCurrentDate(date)
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    if (values.birthDate) {
      setCurrentDate(
        new Date(
          typeof (values.birthDate === 'string')
            ? values.birthDate
            : +values.birthDate,
        ),
      )
    }
  }, [values.birthDate])

  return (
    <>
      {editUserLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>
          {t('actions.edit')} {t('general.super_admin')}
        </DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.first_name')}*`}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  size="small"
                  placeholder={`${t('user_details.first_name')}`}
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={`${t('user_details.last_name')}*`}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  size="small"
                  placeholder={`${t('user_details.last_name')}`}
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  title={`${t('user_details.email')}*`}
                  size="small"
                  placeholder={`${t('user_details.email')}`}
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <Title>{t('user_details.phone')}</Title>
                <PhoneCountryCode
                  // label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  // title={t('user_details.phone')}
                  selectValue={null}
                  selectChange={(e: any) => setFieldValue('phoneFields', e)}
                  inputLabel="Phone"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={errors.phone || errors.phoneFields ? true : false}
                  errorMessage={
                    errors.phone || errors.phoneFields
                      ? errors.phone || errors.phoneFields
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('user_details.select_gender')}</Title>
                <SelectField
                  placeholder={t('user_details.select_gender')}
                  options={genderOptions}
                  // error={errors.gender && touched.gender ? true : false}
                  // errorMessage={errors.gender ? errors.gender : ''}
                  // touched={touched.gender}
                  value={values.gender}
                  onChange={(e: any) => setFieldValue('gender', e)}
                  isClearable
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('user_details.birthday')}</Title>
                <DatePicker
                  name="birthDate"
                  value={currentDate}
                  onChange={onDateChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.job_title')}`}
                  size="small"
                  placeholder={`${t('form_fields.job_title')}`}
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size="small"
                  placeholder={`${t('form_fields.location')}`}
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('user_details.biography')}`}
                  size="small"
                  placeholder={`${t('quiz_details.type_here')}`}
                  name="biography"
                  type="text"
                  value={values.biography}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  title={`${t('user_details.note')}`}
                  placeholder={`${t('user_details.note')}`}
                  size="small"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              {state.currentUser.role !== 'CONSULTANT' && (
                <>
                  {!state.currentUser?.companyId &&
                    !state.selectedCompany?.id && (
                      <FormField width="100%">
                        <CustomSwitcher
                          onChange={() => {
                            setAddingCompanyUser(!addingCompanyUser)
                            if (!companyData) {
                              getCompanyOptions({
                                variables: {
                                  currentPage: 0,
                                  perPage: 0,
                                },
                              })
                            }
                            if (values.consultantOfCompanies) {
                              setFieldValue('consultantOfCompanies', [])
                            }
                          }}
                          label="Company Super Admin"
                          name="skillTestVideoEnabled"
                          value={addingCompanyUser}
                        />
                      </FormField>
                    )}
                  {addingCompanyUser && (
                    <FormField
                      width="100%"
                      style={{
                        height: 320,
                      }}
                    >
                      <AsyncSelect
                        touched={addingCompanyUser}
                        error={errors.consultantOfCompanies}
                        errorMessage={errors.consultantOfCompanies}
                        isMulti
                        onChange={(e: any) => {
                          setFieldValue('consultantOfCompanies', e || [])
                        }}
                        loading={companyLoading}
                        data={companyOptions}
                        value={values.consultantOfCompanies}
                        labelDataKey="name"
                        valueDataKey="id"
                        label={t('notifications_layout.companies')}
                      />
                    </FormField>
                  )}
                </>
              )}
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default UserEdit
