import React from 'react'

interface Params {
  images: string[]
  currentImage: string
}

const ImageGallery = ({ currentImage, images }: Params) => {
  return (
    <div>
      <img src={currentImage} />
    </div>
  )
}

export default ImageGallery
