import { useQuery } from '@apollo/client'
import { GET_TESTS_BY_USER } from 'gql/tests/tests.query'

const useGetTestAssessmentsByUser = (
  currentPage = 1,
  limit = 0,
  searchText = '',
  userId = '',
) => {
  const { data, loading, error, refetch } = useQuery(GET_TESTS_BY_USER, {
    variables: { currentPage, limit, searchText, userId },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    tests: data && data.getTestAssessmentsByUserForAdmin,
    loading,
    error: errorMessage,
    refetch,
  }
}

export default useGetTestAssessmentsByUser
