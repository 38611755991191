import { useLazyQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterCoachesSchema } from 'helpers/validationSchemas'
import {
  LowerCaseFilterableFieldType,
  UpperCaseFilterableFieldType,
} from 'enums/filterEnum'
import { roles } from 'utils/permission'
import { useUserValue } from 'context/UserContext'
import {
  ICoachFormValues,
  IFilter,
  IUseFilterCoachesProps,
  IUseFilterCoachesReturn,
} from 'pages/coaches/FilterCoaches/filterCoaches.interface'
import { coachesFilterOptions } from 'components/UsersLayout/staticData'
import { GET_COMPANIES } from 'gql/companies.query'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { GET_GROUP } from 'gql/group/group.query'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'

export const useFilterCoaches = ({
  filterOptions,
  setFilterOptions,
  filter,
  defaultFilter,
  refetchStudents,
  closeDrawer,
  manualCompanyId,
  forAssignCoaches,
  useCompanyId,
}: IUseFilterCoachesProps): IUseFilterCoachesReturn => {
  const { t } = useTranslation()
  const ref = useRef<NodeJS.Timeout | null>(null)
  const [state] = useUserValue()
  const [fetchGroup] = useLazyQuery(GET_GROUP)
  const { filterRole, setFilterRole } = useFilterRoleContext()
  const { createDate, setCreateDate } = useFilterContext()
  const [error, setError] = useState(false)

  const [fetchCompany, { data: companyData, loading: companyLoading }] =
    useLazyQuery(GET_COMPANIES)

  const [fetchCourse, { data: courseData, loading: courseLoading }] =
    useLazyQuery(GET_ALL_COURSE)

  setFilterRole('COACH')
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: coachesFilterOptions,
    validationSchema: FilterCoachesSchema,
    onSubmit(values) {
      const formValues: ICoachFormValues = {}

      if (values.firstName) {
        formValues.firstName = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.firstName,
        }
      }
      if (values.lastName) {
        formValues.lastName = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.lastName,
        }
      }
      if (values.note) {
        formValues.note = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.note,
        }
      }
      if (values.location) {
        formValues.location = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.location,
        }
      }

      if (values.gender.value !== 'all') {
        formValues.gender = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: values.gender.value,
        }
      }

      if (values.group) {
        const searchedGroupIds = [
          ...values.group.map((gr: { value: string }) => gr.value),
        ]

        const groups =
          searchedGroupIds && defaultFilter?.group?.value
            ? [...searchedGroupIds, ...defaultFilter.group.value]
            : searchedGroupIds

        formValues.group = {
          nestedField: 'groupId',
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_ALL,
          value: groups,
        }
      }

      if (values.course) {
        formValues.courses = {
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: values.course.map((i: { value: string }) => {
            return i.value
          }),
          nestedField: 'courseId',
        }
      }

      if (values.status.value !== 'all') {
        formValues.status = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: values.status.value,
        }
      }

      if (values.company) {
        const companyIds = values.company.map((i: { value: string | null }) =>
          i.value === '-1' ? (i.value = null) : i.value,
        )
        const query = { companyId: { $in: companyIds } }

        formValues.query = {
          type: LowerCaseFilterableFieldType.QUERY,
          value: JSON.stringify(query),
        }
      }

      const isAllZero = values.age.every((item: number) => item === 0)

      if (!isAllZero) {
        formValues.age = {
          type: LowerCaseFilterableFieldType.RANGE,
          value: `${values.age[0]}-${values.age[1]}`,
        }
      }

      if (values.gender.value !== 'all') {
        formValues.gender = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: values.gender.value,
        }
      }

      createDate[0] = createDate[0] && new Date(createDate[0])
      createDate[1] = createDate[1] && new Date(createDate[1])

      const isSameDate =
        createDate[0] === createDate[1] && createDate[0] !== undefined

      if (!createDate[1] && createDate[0] !== undefined)
        createDate[1] = new Date()

      const formattedData = isSameDate
        ? createDate[0]
        : `${createDate[0] ? createDate[0].toISOString() : ''}${'-'}${
            createDate[1] ? createDate[1].toISOString() : ''
          }`

      if (createDate[0] !== undefined || createDate[1] !== undefined) {
        formValues.createDate = {
          type: isSameDate
            ? LowerCaseFilterableFieldType.EXACT
            : LowerCaseFilterableFieldType.DATE_RANGE,
          value: formattedData as string,
        }
      }

      if (refetchStudents && defaultFilter) {
        refetchStudents({
          role: roles.student,
          filter: { ...defaultFilter, ...formValues },
          currentPage: 1,
          perPage: 10,
        })
        closeDrawer()
      }

      filter(formValues)
      setFilterOptions(values)
    },
  })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'COACH') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  useEffect(() => {
    if (filterOptions) {
      setValues(filterOptions)
    }
  }, [])

  const handleRangeChange = (range: number | number[]): void => {
    setFieldValue('age', range)
  }

  const loadDataOptions = (e: string, field: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e
      if (field === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: UpperCaseFilterableFieldType.MATCH,
                value,
              },
            },
          },
        })
      }
      if (field === 'group') {
        let filter: IFilter = {
          name: {
            type: LowerCaseFilterableFieldType.MATCH,
            value,
          },
        }
        if (useCompanyId) {
          filter = {
            ...filter,
            company: {
              type: LowerCaseFilterableFieldType.MATCH,
              value: manualCompanyId
                ? manualCompanyId
                : state.selectedCompany?.id,
            },
          }
        }
        fetchGroup({
          variables: {
            filter,
            perPage: 0,
          },
        })
      }
      if (field === 'course') {
        let filter: IFilter = {
          name: { type: UpperCaseFilterableFieldType.MATCH, value },
        }

        if (!useCompanyId) {
          filter = {
            ...filter,
            originalId: {
              type: UpperCaseFilterableFieldType.ARRAY_IN,
              value: [null],
            },
          }
        }

        if (useCompanyId) {
          const assignedCoachesFilter = {
            isBought: {
              type: UpperCaseFilterableFieldType.MATCH,
              value: true,
            },
          }
          const companyCoachesFilter = {
            isBought: {
              type: UpperCaseFilterableFieldType.MATCH,
              value: false,
            },

            originalId: {
              type: UpperCaseFilterableFieldType.ARRAY_IN,
              value: [null],
            },
          }

          const coachFilter = forAssignCoaches
            ? assignedCoachesFilter
            : companyCoachesFilter

          filter = {
            ...filter,
            ...coachFilter,
            companyId: {
              type: UpperCaseFilterableFieldType.EXACT,
              value: manualCompanyId
                ? manualCompanyId
                : state.selectedCompany?.id,
            },
          }
        }
        fetchCourse({
          variables: {
            filter,
            perPage: 0,
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, field: string): void => {
    if (ref.current) {
      clearTimeout(ref.current as NodeJS.Timeout)
    }
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }
  const [companyOptions, setCompanyOptions] = useState([])

  const handleDateChange = (
    processedDates: [string | null, string | null],
  ): void => {
    setFieldValue('createDate', processedDates)
  }

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])

  return {
    t,
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    handleRangeChange,
    handleSelectChange,
    companyOptions,
    companyLoading,
    courseData,
    courseLoading,
    error,
    setError,
    createDate,
    setCreateDate,
    handleDateChange,
  }
}
