import styled from 'styled-components'

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  gap: 6px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
  }
`

// Tab1

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledTextContainer = styled.div``
export const InputFieldContainer = styled.div`
  display: flex;
`

export const FullWidthContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

export const FieldItemContainer = styled.div<{ width: any }>`
  margin-left: 5px;
  margin-right: 5px;
  width: ${({ width }) => width};
`
export const FormGroupItemLabel = styled.label`
  display: inline-block;
  margin-bottom: 5px;
`

export const FormItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
`
// Tab2

export const Container = styled.div`
  padding: 0 20px 20px 20px;
  background-color: #fff;
`
