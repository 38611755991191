import { useMutation } from '@apollo/client'

import { EDIT_MODULE } from 'gql/modules.query'

const editModuleService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editModuleMutation, { loading }] = useMutation(EDIT_MODULE, {})

  return {
    editModuleMutation,
    loading,
  }
}

export default editModuleService
