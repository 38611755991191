import { useMutation } from '@apollo/client'

import { DELETE_GROUP } from 'gql/group/group.query'

const deleteGroupService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteGroup] = useMutation(DELETE_GROUP)

  return {
    deleteGroup,
  }
}

export default deleteGroupService
