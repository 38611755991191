import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useUserValue } from 'context/UserContext'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useSwal } from 'hooks/useSwal'
import { useDeleteModuleService } from 'hooks/modules/useDeleteModuleService'
import { useParams } from 'react-router-dom'
import { actionConfig } from 'pages/modules/ModuleDetail/ModuleDetailContent/actionConfig'
import {
  ModuleDetailsContentProps,
  UseModuleDetailsContentReturn,
} from './useModuleDetailContent.interface'

const useModuleDetailsContent = ({
  moduleItem,
  t,
}: ModuleDetailsContentProps): UseModuleDetailsContentReturn => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [state] = useUserValue()
  const { deleteModule, deletedModule } = useDeleteModuleService()
  const [videoModal, setVideoModal] = useState(false)
  const { fireSwal } = useSwal()

  const handleEditClick = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'editModuleDrawer', true, { id })
  }

  const handleDeleteClick = (): void => {
    fireSwal({
      title: t('popups.delete_module_single'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => deleteModule([id || '']),
    })
  }

  useEffect(() => {
    if (deletedModule.length !== 0) {
      navigate('/modules')
    }
  }, [deletedModule])

  const video = moduleItem.video

  const handleCourseClick = (index: number): void => {
    const courseId = moduleItem.courses?.[index]?.courseId
    console.log(courseId, 'courseId')
    if (courseId) {
      navigate(`/courses/${courseId}`)
    }
  }
  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    state.userPermission,
    t,
  )

  return {
    video,
    setVideoModal,
    state,
    actions,
    handleCourseClick,
    videoModal,
  }
}

export default useModuleDetailsContent
