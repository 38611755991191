import React, { useState } from 'react'
import { Container, UploadedContent } from './styled-components'
import axios from 'axios'
import Uploader from 'components/common/FileUploaderV2/Uploader'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import { useTranslation } from 'react-i18next'
import {
  useGenerateUploadVideoLinks,
  useUploadImage,
  useUploadFile,
  useUploadAudioService,
} from 'hooks/helpers/useHelpersService'
import { StyledUploadLabel } from 'pages/tests/editTestDrawer/styled-components'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
const constants = {
  image: 'image/jpeg|image/jpg|image/png',
  video:
    'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
  audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
  file: 'application/pdf|application/msword|application/plain|text/plain|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

const UploadContent = (props: any): React.JSX.Element => {
  const { t } = useTranslation()
  // const [props.uploadedFiles, props.setUploadedFiles] = useState<any>([])
  const [uploadingProgress, setUploadingProgress] = useState(0)
  const [mediaDuration, setMediaDuration] = useState<any>(0)
  const [fileType, setFileType] = useState<FileTypes>(FileTypes.VIDEO)
  const [cropperOpen, setCropperOpen] = useState(false)
  const [cropperImage, setCropperImage] = useState<any>('')

  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()
  const { uploadFile, fileLoading } = useUploadFile()
  const { uploadAudio } = useUploadAudioService()
  const { uploadImage, imageLoading } = useUploadImage()

  const uploadVideoService = (url: string, file: any) => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: ({ total, loaded }: any) => {
        setUploadingProgress((loaded / total) * 100)
      },
    })
  }

  const uploadContentFile = (file: any): void => {
    uploadFile(file, 'lesson/content', (link: string, fileId: string) => {
      const uploaded = [
        {
          type: 'documents',
          name: file.name,
          fileType: file.name.split('.').slice(-1)[0],
          link,
        },
      ]

      props.setUploadedFiles([...props.uploadedFiles, ...uploaded])
    })
  }

  const uploadVideoFile = (file: any): void => {
    const videos = [{ fileName: file.name, type: file.type }]
    const entity = props.currentModule ? 'module_question' : 'question'
    generateUploadVideoLinks(
      videos,
      entity,
      (arg: any) => uploadVideoService(arg, file),
      (args: any) => {
        const uploaded = [
          {
            ...args.video,
            type: 'videos',
            title: args.video.name,
          },
        ]
        props.setUploadedFiles([...props.uploadedFiles, ...uploaded])
        setUploadingProgress(0)
      },
    )
  }

  const uploadAudioFile = (file: any): void => {
    const audioFile: any = { fileName: file.name, type: file.type }
    uploadAudio(
      audioFile,
      (arg: any) => uploadVideoService(arg, file),
      (fileId: string) => {
        const uploaded = [
          {
            id: fileId,
            type: 'audios',
            name: file.name,
            fileType: file.name.split('.').pop(),
          },
        ]

        props.setUploadedFiles([...props.uploadedFiles, ...uploaded])
        setUploadingProgress(0)
      },
    )
  }

  const uploadImageFile = (field: string, croppedFile: any): void => {
    uploadImage(croppedFile, `questions/${field}`, (link: string) => {
      const uploaded = [
        {
          type: field,
          name: Date.now().toString(),
          fileType: 'png',
          link,
        },
      ]

      props.setUploadedFiles([...props.uploadedFiles, ...uploaded])
    })
  }

  const extractDuration = (file: any): void => {
    // const reader: any = new FileReader()
    // reader.onload = function () {
    //   const media = new Audio(reader.result)
    //   media.onloadedmetadata = function () {
    setMediaDuration(0)
    //   }
    // }
    // reader.readAsDataURL(file)
  }

  const b64Converter = (file: any): void => {
    const reader: any = new FileReader()

    reader.onload = (): void => {
      const img: any = new Image()
      img.src = reader.result
      setCropperImage({ name: 'images', file: reader.result })
    }
    reader.readAsDataURL(file)
  }

  const handleFileChange = (e: any): void => {
    const file = e.target.files[0]
    if (constants.file.includes(file.type)) {
      setFileType(FileTypes.DOCUMENT)
      uploadContentFile(file)
      return
    }

    extractDuration(file)

    if (constants.audio.includes(file.type)) {
      setFileType(FileTypes.AUDIO)
      uploadAudioFile(file)
      return
    }

    if (constants.video.includes(file.type)) {
      setFileType(FileTypes.VIDEO)
      uploadVideoFile(file)
      return
    }

    if (constants.image.includes(file.type)) {
      setFileType(FileTypes.IMAGE)
      b64Converter(file)
      setCropperOpen(true)

      return
    }
  }

  const handleInputChange = (e: any, index: any, fileType: string): void => {
    const { value } = e.target

    const field = fileType === 'videos' ? 'title' : 'name'

    const content = [...props.uploadedFiles]
    const item = {
      ...content[index],
    }

    item[field] = value
    content[index] = item

    props.setUploadedFiles([...content])
  }

  const handleDeleteFile = (i: any): void => {
    const content = [...props.uploadedFiles]
    content.splice(i, 1)
    props.setUploadedFiles(content)
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const loadings: any = {
    image: imageLoading === 'images',
    video: uploadingProgress > 0 ? uploadingProgress : false,
    document: fileLoading === 'content',
    audio: uploadingProgress > 0 ? uploadingProgress : false,
  }

  return (
    <Container>
      <UploadedContent>
        <Uploader
          inputValue={''}
          deleteFile={(): void => {}}
          label={
            <>
              <StyledUploadLabel>
                {t('actions.upload_content')}
              </StyledUploadLabel>
            </>
          }
          onFileChange={handleFileChange}
          disabled={true}
          multiple
          placeHolder={t('actions.file_upload_format2')}
          type={fileType}
          uploadedFile={null}
          // fileExtension={''}
          loading={loadings[fileType]}
          id="content"
          // loading={['video', 'audio'].includes(fileType) ? (uploadingProgress > 0 ? uploadingProgress : false) : fileLoading === 'content'}
        />
        {props.uploadedFiles.map((item: any, index: any) => {
          // const fileExtension =
          //   item.type === 'videos'
          //     ? `(${item.links[2].type})`
          //     : `(${item.fileType})`

          return (
            <>
              <Uploader
                key={index}
                label={t('')}
                // accept="video/*"
                // onFileChange={handleInputChange}
                deleteFile={() => handleDeleteFile(index)}
                type={item.type.slice(0, item.type.length - 1)}
                inputValue={item.type === 'videos' ? item.title : item.name}
                onInputChange={(e: any): void =>
                  handleInputChange(e, index, item.type)
                }
                uploadedFile={item}
                // fileExtension={fileExtension}
              />
            </>
          )
        })}
      </UploadedContent>

      <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
        <ImageCropper
          file={cropperImage}
          hideCropper={cropperModalToggle}
          getCroppedFile={uploadImageFile}
        />
      </Modal>
    </Container>
  )
}

export default UploadContent
